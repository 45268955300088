export enum Carrier {
    LemonadeInsuranceCompany = 'lemonade_insurance_company',
    HomeStateCountyMutual = 'home_state_county_mutual',
    MetromileInsuranceCompany = 'metromile_insurance_company',
}

export const carrierNames: Record<Carrier, string> = {
    [Carrier.LemonadeInsuranceCompany]: 'Lemonade',
    [Carrier.HomeStateCountyMutual]: 'HSCM',
    [Carrier.MetromileInsuranceCompany]: 'Metromile',
};

export const carriers: Record<Carrier, string | undefined> = {
    [Carrier.LemonadeInsuranceCompany]: import.meta.env.VITE_STRIPE_KEY,
    [Carrier.HomeStateCountyMutual]: import.meta.env.VITE_STRIPE_KEY_HSCM,
    [Carrier.MetromileInsuranceCompany]: import.meta.env.VITE_STRIPE_KEY_MM,
};
