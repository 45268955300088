import type {
  ArrayShape,
  ExtractShapeFromPath,
  GenericRecordData,
  Infer,
  NumberShape,
  RecordLikeShape,
  RecordPath,
  Shape,
  StringShape,
} from '@lemonade-hq/maschema-schema';
import { getShapesFromRecordPath, ma } from '@lemonade-hq/maschema-schema';
import get from 'lodash/get';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { inlineFlex } from '../../../../theme/utils.css';
import { Button } from '../../../Button/Button';
import { useForm } from '../../FormContext';
import { generateTypedFormComponents } from '../../typeHelpers';
import type { CommonFormChangePayload, Config } from '../../types';
import { useConnectToForms } from '../common';
import type { SchemaShapeIsNotArrayError } from '../shape-assertion.types';
import { ConnectedDynamicListContainer } from './ConnectedDynamicListContainer';
import { DynamicListProvider, useDynamicListContext } from './DynamicListContext';
import { DynamicListPrimitiveItems } from './DynamicListPrimitiveItems';
import { DynamicListRecordItems } from './DynamicListRecordItems';

interface RenderFormAsProps<TSchema extends RecordLikeShape> {
  readonly components: ReturnType<typeof generateTypedFormComponents<TSchema>>;
  readonly index: number;
  readonly shape: TSchema;
}

export type PrimitiveShape = NumberShape | StringShape;

type PropsWithRenderFrom<TShape extends RecordLikeShape> = {
  readonly renderFormAs: (props: RenderFormAsProps<NoInfer<TShape>>) => ReactNode;
  readonly displayAs: (item: Infer<NoInfer<TShape>>) => string;
};

export type DynamicListProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TArrayItemShape = ExtractShapeFromPath<TSchema, TSchemaKey> extends ArrayShape<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    infer TShapeInferred extends PrimitiveShape | RecordLikeShape
  >
    ? TShapeInferred
    : SchemaShapeIsNotArrayError,
  TItem = TArrayItemShape extends Shape ? Infer<TArrayItemShape> : never,
> = (TArrayItemShape extends RecordLikeShape ? PropsWithRenderFrom<TArrayItemShape> : unknown) & {
  readonly additionalItemContext?: Record<string, unknown>;
  readonly newItemTemplate: NoInfer<TItem>;
  readonly config?: NoInfer<TArrayItemShape> extends RecordLikeShape
    ? Omit<Config<NoInfer<TArrayItemShape>>, 'additionalValidations' | 'schemaKeysRules'>
    : undefined;
  readonly variant?: 'ordered' | 'unordered';
  readonly sortable?: boolean;
  readonly renderItem?: (props: {
    readonly schemaKey: string;
    readonly index: number;
    readonly inputElement: ReactNode;
  }) => ReactNode;
  readonly canAddAbove?: boolean;
  readonly canAddItems?: boolean;
  readonly className?: string;
};

export type AssertedDynamicListProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
> = DynamicListProps<TSchema, TSchemaKey> & {
  readonly schemaKey: TSchemaKey;
};

const AddButton: FC = () => {
  const { addItem, disableAddingItem } = useDynamicListContext();

  return (
    <Button
      className={inlineFlex}
      disabled={disableAddingItem}
      label="Add new"
      onClick={() => addItem()}
      startIcon="plus-solid"
      variant="inline"
    />
  );
};

export const DynamicList = <TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(
  props,
): ReactNode => {
  const {
    schemaKey,
    variant = 'unordered',
    className,
    canAddItems = true,
  } = props as AssertedDynamicListProps<TSchema, TSchemaKey>;
  const { schema, values } = useForm<TSchema, CommonFormChangePayload>();
  const items = (get(values, schemaKey) ?? []) as GenericRecordData[];

  const { disabled, visible } = useConnectToForms({ schemaKey });
  const { ErrorMessage } = generateTypedFormComponents<typeof schema>();
  const listType = variant === 'ordered' ? 'ol' : 'ul';
  const shapes = getShapesFromRecordPath(schema, schemaKey);

  if (shapes.some(s => s.type !== 'array')) {
    throw new Error('DynamicList only supports only array shapes');
  }

  const nestedShapes = (shapes as ArrayShape[]).flatMap(a => a.shape);
  const allShapesAreRecordLike = nestedShapes.every(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    s => s.type === 'record' || (s.type === 'union' && s.shapes.every(ns => ns.type === 'record')),
  );

  const itemsSchema = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => (nestedShapes.length === 1 ? nestedShapes[0] : ma.union(nestedShapes)),
    [nestedShapes],
  ) as RecordLikeShape;

  return visible ? (
    <DynamicListProvider items={items} schemaKey={schemaKey}>
      <ConnectedDynamicListContainer as={listType} className={className}>
        {allShapesAreRecordLike ? (
          <DynamicListRecordItems {...props} items={items} itemsSchema={itemsSchema} nestedShapes={nestedShapes} />
        ) : (
          <DynamicListPrimitiveItems {...props} items={items} />
        )}

        {!disabled && (
          <>
            <ErrorMessage schemaKey={schemaKey} />
            {canAddItems && <AddButton />}
          </>
        )}
      </ConnectedDynamicListContainer>
    </DynamicListProvider>
  ) : null;
};
