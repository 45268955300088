export enum FloatingNoteActions {
    SET_FLOATING_NOTE_OPEN = 'SET_FLOATING_NOTE_OPEN',
}

interface ToggleFloatingNoteActionType {
    type: FloatingNoteActions.SET_FLOATING_NOTE_OPEN;
    payload: { isOpen: boolean };
}

export type FloatingNoteActionsTypes = ToggleFloatingNoteActionType;

export function toggleIsFloatingNoteOpen(isOpen: boolean): FloatingNoteActionsTypes {
    return {
        type: FloatingNoteActions.SET_FLOATING_NOTE_OPEN,
        payload: { isOpen },
    };
}
