import { Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import { formatOccurredAt } from '@lemonade-hq/bluiza';
import { ToolsRevisionStatus } from '@lemonade-hq/persisted-tools';
import type {
    SerializableTool as Tool,
    SerializableToolsRevision as ToolsRevision,
} from '@lemonade-hq/persisted-tools';
import type { FC } from 'react';
import { ToolRevisionPublishNotesTooltip } from '../toolEditor/PlaygroundToolEditorHeaderNotification';

export const PlaygroundComparableToolTopBar: FC<{
    readonly tool?: Tool;
    readonly toolsRevision: ToolsRevision;
}> = ({ tool, toolsRevision }) => {
    return (
        <Flex flexDirection="column" gap={spacing.s04}>
            <Flex alignItems="flex-start" gap={spacing.s04} justifyContent="space-between" role="banner">
                <Text textTransform="none" type="h4">
                    {tool?.name}
                </Text>
                <Text textTransform="none" type="h4">
                    {toolsRevision.publicId}
                </Text>
            </Flex>
            {(toolsRevision.status === ToolsRevisionStatus.Published ||
                toolsRevision.status === ToolsRevisionStatus.Sunsetted) && (
                <Flex alignItems="center" gap={spacing.s06} justifyContent="flex-end">
                    <Text color="secondary" textAlign="right" type="text-md">
                        Published by {toolsRevision.publishedBy}&nbsp;
                        {formatOccurredAt(toolsRevision.publishedAt).toLowerCase()}
                    </Text>
                    <ToolRevisionPublishNotesTooltip toolsRevision={toolsRevision} />
                </Flex>
            )}
        </Flex>
    );
};
