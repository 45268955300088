import type { ActionOption } from '@lemonade-hq/bluis';
import { ActionsMenu, Image, LinkTab, PageWrapper, TabList, Tabs } from '@lemonade-hq/bluis';
import { EntitySummaryHeader, HeaderGeneralDetails, HeaderLocation } from '@lemonade-hq/bluiza';
import { Box } from '@lemonade-hq/cdk';
import type { ReactElement } from 'react';
import { useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HeaderImageSizes, ImageContainer } from '../../LoCoPagesSharedStyles';
import { SimulationDialog } from '../SimulationDialog/SimulationDialog';
import { Editions } from './Editions';
import { ProductGeneral } from './ProductGeneral';
import { ProductSchemaTable } from './ProductSchemaTable';
import { ReleasesHistory } from './ReleasesHistory';
import { ReleasesTable } from './ReleasesTable';
import { HeaderPortal } from 'components/Header';
import { SuspendableContent } from 'components/LoCo/common/components/SuspendableContent';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import { ReleaseStatus } from 'models/LoCo/Insurance/Release';

export const ReleasesTables: React.FC<{ readonly productCode: string }> = ({ productCode }) => {
    return (
        <>
            <SuspendableContent errorProps={{ title: 'Failed fetching draft releases :(' }}>
                <ReleasesTable productCode={productCode} releaseStatus={ReleaseStatus.Draft} />
            </SuspendableContent>
            <SuspendableContent errorProps={{ title: 'Failed fetching cancelled releases :(' }}>
                <ReleasesTable productCode={productCode} releaseStatus={ReleaseStatus.Cancelled} />
            </SuspendableContent>
        </>
    );
};

export const LoCoProductsMissionControl: React.FC = () => {
    const [showDialog, setShowDialog] = useState(false);

    const product = useGetProductData();

    const productCode = product.code;

    const ROUTES: Record<
        string,
        { path: string; match: string; label: string; component: ReactElement; partiallyCurrent?: boolean }
    > = {
        general: {
            path: 'general',
            match: 'general',
            label: 'General',
            component: <ProductGeneral product={product} />,
        },
        editions: {
            path: 'editions',
            match: 'editions/*',
            label: 'Editions',
            component: <Editions />,
            partiallyCurrent: true,
        },
        schema: {
            path: 'schema',
            match: 'schema',
            label: 'Schema',
            component: <ProductSchemaTable productCode={productCode} />,
        },
        releases: {
            path: 'releases',
            match: 'releases',
            label: 'Releases',
            component: <ReleasesTables productCode={productCode} />,
        },
        releasedHistory: {
            path: 'releases-history',
            match: 'releases-history',
            label: 'Releases History',
            component: <ReleasesHistory productCode={productCode} productName={product.name} />,
        },
    };

    const actions: ActionOption[] = useMemo(
        () => [
            {
                label: 'Check Editions Compatibility',
                value: 'checkEditionsCompatibility',
                onClick: () => setShowDialog(true),
            },
        ],
        [setShowDialog]
    );

    return (
        <>
            <HeaderPortal>
                <EntitySummaryHeader>
                    <ImageContainer>
                        <Image
                            img={{
                                lightSrc: 'appraisal.svg',
                                darkSrc: 'appraisal-dark.svg',
                                ...HeaderImageSizes,
                            }}
                        />
                    </ImageContainer>
                    <HeaderGeneralDetails subtitle="Product Mission Control" title={product.name} />
                    <HeaderLocation country={product.country} state={product.state ?? product.country} />
                    <Box ml="auto">
                        <ActionsMenu actions={actions} buttonText="Actions" />
                    </Box>
                </EntitySummaryHeader>
            </HeaderPortal>
            <PageWrapper>
                <Tabs>
                    <HeaderPortal>
                        <TabList pageTabs>
                            {Object.values(ROUTES).map(({ label, path, partiallyCurrent }) => (
                                <LinkTab key={label} partiallyCurrent={partiallyCurrent} to={path}>
                                    {label}
                                </LinkTab>
                            ))}
                        </TabList>
                    </HeaderPortal>
                    <Routes>
                        <Route element={<Navigate replace to="general" />} path="/" />

                        {Object.values(ROUTES).map(({ path, component, match }) => (
                            <Route element={component} key={path} path={match} />
                        ))}
                    </Routes>
                </Tabs>
            </PageWrapper>

            {showDialog && <SimulationDialog onClose={() => setShowDialog(false)} />}
        </>
    );
};
