import type { AxiosError } from 'axios';

export const MSG_JWT_EXPIRED = 'jwt expired';
export const MSG_JWT_INVALID = 'invalid jwt';

const STATUS_UNAUTHORIZED = 401;

export interface ResponseData {
  readonly data?: { readonly message?: string };
  readonly error?: string;
  readonly statusCode?: number;
}

export function shouldAuthenticate(err: AxiosError<ResponseData>): boolean {
  const { data: exceptionObj, statusCode } = err.response?.data ?? {};
  const { message } = exceptionObj ?? {};

  return (
    statusCode === STATUS_UNAUTHORIZED &&
    (message?.toLowerCase() === MSG_JWT_INVALID || message?.toLowerCase() === MSG_JWT_EXPIRED)
  );
}
