import styled from 'styled-components';

export const Fieldset = styled.fieldset`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 22px;
`;

export const Label = styled.label`
    font-weight: normal;
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
`;
