export enum EditionType {
    Rating = 'rating',
    Coverages = 'coverages',
    DigitalAgent = 'digitalAgent',
    UnderwritingFilters = 'underwritingFilters',
}

export enum EditionStatus {
    Draft = 'draft',
    Approved = 'approved',
    Archived = 'archived',
}

export interface Edition {
    readonly code: string;
    readonly addedBy: string;
    readonly addedAt: string;
    readonly productCode: string;
    readonly description: string;
    readonly approvedBy?: string;
    readonly approvedAt?: string;
    readonly archivedBy?: string;
    readonly archivedAt?: string;
    readonly versionMajor?: number;
    readonly versionMinor?: number;
    readonly versionType?: VersionType;
    readonly baseEditionCode?: string;
    readonly version?: string;
    readonly addedByName: string;
    readonly friendlyName: string;
    readonly archivedByName?: string;
    readonly approvedByName?: string;
}

export interface ApprovedEdition extends Edition {
    readonly approvedBy: string;
    readonly approvedAt: string;
    readonly approvedByName: string;

    readonly version: string;
    readonly versionMajor: number;
    readonly versionMinor: number;
    readonly versionType: VersionType;
}

export interface BaseEditionFilter {
    readonly approved?: boolean;
    readonly archived?: boolean;
    readonly maxDaysSinceArchived?: number;
}

export interface CreateEditionParams {
    readonly productCode: string;
    readonly description: string;
    readonly baseEditionCode?: string;
}

export type EditionWithType = Edition & { readonly type: EditionType };

export interface ArchiveEditionParams {
    readonly editionCode: string;
}

export enum VersionType {
    Major = 'major',
    Minor = 'minor',
    BugFix = 'bugfix',
}

export enum RuleLifecycleContext {
    NewBusiness = 'new_business',
    Renewal = 'renewal',
    PolicyEdits = 'policy_edits',
    Moving = 'moving',
}

export enum VersionTypeBlockReason {
    EditionApproved = 'edition_approved',
    BaseEditionNotApproved = 'base_edition_not_approved',
    BaseNotLatestVersion = 'base_not_latest_version',
    BaseNotLatestVersionForMajor = 'base_not_latest_version_for_major',
    MissingBaseEdition = 'missing_base_edition',
    MissingPriorMajor = 'missing_prior_major',
}

export interface VersionTypeInfo {
    readonly type: VersionType;
    readonly allowed: boolean;
    readonly blockReasons: VersionTypeBlockReason[];
}

export interface ApprovalEligibility {
    readonly versionTypes: VersionTypeInfo[];
    readonly hasAvailableVersionType: boolean;
    readonly editionAlerts: EditionAlert[];
}

export enum EditionAlertLevel {
    Warning = 'warning',
    Info = 'info',
}

export enum EditionAlertType {
    EligibleToAllVersionTypes = 'eligible_to_all_version_types',
    AllVersionTypesBlocked = 'all_version_types_blocked',
    MajorVersionBlocked = 'major_version_blocked',
}

export interface EditionAlert {
    readonly type: EditionAlertType;
    readonly level: EditionAlertLevel;
}
