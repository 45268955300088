import type { DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import type { SortableData } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import type { Transform } from '@dnd-kit/utilities';
import { CSS } from '@dnd-kit/utilities';

interface UseSortableDndProps {
  readonly id: string;
  readonly disabled?: boolean;
}

export interface UseSortableDndResult {
  readonly attributes: DraggableAttributes;
  readonly listeners?: SyntheticListenerMap;
  readonly setDragNodeRef: (node: HTMLElement | null) => void;
  readonly style: React.CSSProperties;
  readonly isDragging: boolean;
  readonly data: SortableData & {
    readonly [x: string]: unknown;
  };
}

function getStyle(transform: Transform | null): { readonly transform?: string } {
  if (transform == null) return {};

  return {
    transform: CSS.Transform.toString({
      ...transform,
      // the library default scale looks bad on text
      scaleX: 1,
      scaleY: 1,
    }),
  };
}

// This hook is used to make an custom element draggable and sortable in a list.
export const useSortableDnd = ({ id, disabled }: UseSortableDndProps): UseSortableDndResult => {
  const { attributes, listeners, setNodeRef, transform, isDragging, data } = useSortable({ id, disabled });

  return {
    attributes,
    data,
    listeners,
    setDragNodeRef: setNodeRef,
    style: getStyle(transform),
    isDragging,
  };
};
