import type { AxiosError } from 'axios';

import { EnvName } from '../config';

export function shouldFallbackToMaster(env: EnvName, err: AxiosError): boolean {
  if (env !== EnvName.Staging) return false;
  if (err.isAxiosError && typeof err.response !== 'undefined') return false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const url = err.config.baseURL ?? err.config.url;
  return url != null && /t[0-9]+/.test(url);
}
