import { Alert, AlertMode } from '@lemonade-hq/bluis';
import type { FC } from 'react';
import * as styles from './toolEditorAlert.css';

// TODO: Alert should be replaced with the new Zest Banner component
export const DeletedToolAlert: FC = () => (
    <Alert
        className={styles.toolEditorAlert}
        mode={AlertMode.Attention}
        title="This tool does not exist in this tools revision (deleted here or earlier)"
    />
);
