import { getTypeFromContentType } from '@lemonade-hq/bluis';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { useEntityPageData } from '@lemonade-hq/boutique';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import type { Attachment, AttachmentToUpload, AttachmentType } from '../../../models/Attachment';
import { AttachmentEntityType } from '../../../models/Attachment';
import AddFilesFooter from '../AddFilesFooter';
import type { LightBoxOptions } from '../Lightbox';
import LightBox from '../Lightbox';
import type { AdditionalInputs } from './AddAttachmentsTable';
import Table, { rowItemTypeToWidth } from './AddAttachmentsTable';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { DuplicateAttachments } from 'components/Attachments/AttachmentHub/DuplicateAttachments';
import { getAttachments } from 'components/Attachments/AttachmentsQueries';
import type { AttachmentDTO } from 'components/Attachments/types';
import DragAndDropFileInput from 'components/Bluis/DragAndDropFileInput';

export const AUDIO_PLACEHOLDER = `${__assets_url}blender_assets/backoffice/claims/audio-placeholder-new.png`;
export const VIDEO_PLACEHOLDER = `${__assets_url}blender_assets/backoffice/claims/video-placeholder.png`;
export const DOC_PLACEHOLDER = `${__assets_url}blender_assets/backoffice/claims/pdf-placeholder.png`;

interface AddAttachmentsProps {
    readonly addAttachments: (entityId: string, toUpload: AttachmentToUpload[]) => Promise<Attachment[] | null | void>;
    readonly addAttachmentsLoading: boolean;
    readonly getAttachmentTypes?: () => Promise<AttachmentType[] | string[]>;
    readonly close: () => void;
    readonly entityId: string;
    readonly lightBoxTitle?: string;
    readonly formatAttachmentsToUpload?: (attachment: AttachmentToAdd[]) => AttachmentToUpload[];
    readonly additionalInputs?: AdditionalInputs;
    readonly onAttachmentsWillUpdate?: (attachments: AttachmentToAdd[]) => void;
    readonly defaultDescription?: string;
    readonly checkForDuplicates?: boolean;
    readonly uploadFile?: (file: File) => Promise<{ readonly publicId: string }>;
}

export const AddAttachmentsWrapper = styled.div<{
    readonly isLoading: boolean;
    readonly isEmpty: boolean;
    readonly inputsScheme?: number[];
}>`
    width: calc(788px + ${({ inputsScheme }) => inputsScheme?.reduce((sum, curr) => sum + curr, 0) ?? 0}px);
    height: 394px;
    overflow: auto;
    padding: 32px 24px 0 24px;

    ${({ isEmpty }) =>
        isEmpty &&
        css`
            padding: 24px 32px 20px;
            height: 440px;
        `}

    ${({ isLoading }) =>
        isLoading &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;

const LightBoxWrapper = styled.div`
    .lightbox-body {
        padding: 0 !important;
    }
`;

const StyledDragAndDropFileInput = styled(DragAndDropFileInput)`
    width: 100%;
    height: 362px;
`;

export interface AttachmentToAdd {
    readonly id: number;
    readonly filename: string;
    readonly file: File;
    readonly entityId: string;
    readonly type?: AttachmentType;
    readonly description?: string;
    readonly src?: string;
    readonly filePublicId?: string;
}

export type UpdateAttachment = (id: number, toUpdate: Pick<AttachmentToAdd, 'description' | 'src' | 'type'>) => void;

const AddAttachments: React.FC<React.PropsWithChildren<AddAttachmentsProps>> = ({
    close,
    getAttachmentTypes,
    addAttachments,
    addAttachmentsLoading,
    entityId,
    lightBoxTitle,
    formatAttachmentsToUpload,
    additionalInputs,
    onAttachmentsWillUpdate,
    defaultDescription,
    checkForDuplicates = false,
    uploadFile,
}) => {
    const { entityType = '', publicId: entityPublicId } = useEntityPageData() ?? {};
    const [error, setError] = useState('');
    const [attachments, setAttachmentsState] = useState<AttachmentToAdd[]>([]);
    const [duplicateAttachments, setDuplicateAttachments] = useState<AttachmentDTO[]>([]);
    const [isCheckingForDups, setIsCheckingForDups] = useState(false);
    const [attachmentTypes, setAttachmentTypes] = useState<string[]>([]);
    const attachmentCounter = useRef(0);

    const setAttachments = useCallback(
        (computeNewState: (attachments: AttachmentToAdd[]) => AttachmentToAdd[]): void => {
            setAttachmentsState(attachments => {
                const newAttachments = computeNewState(attachments);

                onAttachmentsWillUpdate?.(newAttachments);

                return newAttachments;
            });
        },
        [onAttachmentsWillUpdate]
    );

    useEffect(() => {
        async function fetchAttachmentTypes() {
            try {
                const types = await getAttachmentTypes?.();

                setAttachmentTypes(types ?? []);
            } catch (e) {
                setError(GENERAL_ERROR_MSG);
            }
        }
        if (getAttachmentTypes) {
            fetchAttachmentTypes();
        }
    }, [getAttachmentTypes]);

    const isEmpty = attachments.length === 0;

    const inputsScheme = useMemo(
        () => additionalInputs?.scheme.map(input => rowItemTypeToWidth(input.type)),
        [additionalInputs]
    );

    function updateAttachment(id: number, toUpdate: Pick<AttachmentToAdd, 'description' | 'src' | 'type'>) {
        setAttachments(attachments => {
            const newAttachments = [...attachments];
            const updatedAttachmentIndex = attachments.findIndex(a => a.id === id);

            if (updatedAttachmentIndex === -1) return attachments;

            const updatedAttachment = attachments[updatedAttachmentIndex];

            newAttachments[updatedAttachmentIndex] = {
                ...updatedAttachment,
                ...toUpdate,
                ...(attachmentTypes.length === 1 ? { type: attachmentTypes[0] as AttachmentType } : undefined),
                ...(toUpdate.description == null && defaultDescription != null
                    ? { description: defaultDescription }
                    : undefined),
            };
            return newAttachments;
        });
    }

    function isValid() {
        if (attachments.length === 0) return false;
        if (getAttachmentTypes == null) return true;

        for (let i = 0; i < attachments.length; i++) {
            if (!attachments[i].type) return false;
        }

        return true;
    }

    function deleteAttachment(id: number) {
        setAttachments(attachments => attachments.filter(a => a.id !== id));
    }

    async function onSave() {
        const attachmentsToUpload = (formatAttachmentsToUpload?.(attachments) ?? attachments) as AttachmentToUpload[];

        try {
            await addAttachments(entityId, attachmentsToUpload);
            close();
        } catch (e) {
            setError(GENERAL_ERROR_MSG);
        }
    }

    function onFilesChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) return;

        handleFiles(e.target.files);
    }

    async function handleFiles(files: FileList) {
        const newAttachments: AttachmentToAdd[] = Array.from(files).map(file => {
            const id = attachmentCounter.current++;
            const reader = new FileReader();
            let src = '';

            const contentType = getTypeFromContentType(file.type);

            switch (contentType) {
                case 'audio':
                    src = AUDIO_PLACEHOLDER;
                    break;
                case 'doc':
                    src = DOC_PLACEHOLDER;
                    break;
                case 'video':
                    src = VIDEO_PLACEHOLDER;
                    break;
                default:
                    src = '';
            }

            if (!checkForDuplicates && !src) {
                reader.addEventListener('load', e => {
                    updateAttachment(id, { src: e.target?.result as string });
                });

                reader.readAsDataURL(file);
            }

            return {
                id,
                file,
                src,
                entityId: AttachmentEntityType.Claim,
                filename: file.name,
                description: '',
            };
        });

        // It was decided by infra that to check if file is a duplicate, we need to upload it to file service first, then use received filePublicId to check for duplicates
        if (checkForDuplicates && uploadFile != null) {
            setIsCheckingForDups(true);

            const filePublicIds = (
                await Promise.all(newAttachments.map(async ({ file }) => await uploadFile(file)))
            ).map(({ publicId }) => publicId);

            const possibleDuplicates = await Promise.all(
                filePublicIds.map(
                    async filePublicId =>
                        (
                            await getAttachments({
                                entityType: entityType as EntityTypes,
                                entityPublicId: entityPublicId ?? '',
                                params: {
                                    filePublicId: filePublicId,
                                },
                            })
                        ).data
                )
            );

            const [original, duplicates] = newAttachments.reduce(
                (acc, attachment, i) => {
                    if (possibleDuplicates[i]?.[0] != null) {
                        return [acc[0], [...acc[1], possibleDuplicates[i][0]]];
                    }

                    return [[...acc[0], { ...attachment, filePublicId: filePublicIds[i] }], acc[1]];
                },
                [[], []] as [AttachmentToAdd[], AttachmentDTO[]]
            );

            // We have to move it here, because now handleFiles is async, and since
            // updateAttachment works with state, it is better to make these changes close to each other im time
            const originalWithSrc = original.map(item => {
                if (item.src === '') {
                    const reader = new FileReader();
                    reader.addEventListener('load', e => {
                        setTimeout(() => {
                            updateAttachment(item.id, { src: e.target?.result as string });
                        }, 100);
                    });

                    reader.readAsDataURL(item.file);
                }

                return item;
            });

            setAttachments(currAttachments => [...currAttachments, ...originalWithSrc]);
            setDuplicateAttachments(dupAttachments => [...dupAttachments, ...duplicates]);
            setIsCheckingForDups(false);
        } else {
            setAttachments(attachments => [...attachments, ...newAttachments]);
        }
    }

    const lightBoxOptions: LightBoxOptions = {
        title: lightBoxTitle ?? 'Add item',
        centered: true,
        desktopStyle: true,
        btnClose: true,
        closeByKeyboard: true,
        onClose: close,
        isSubmitting: addAttachmentsLoading || isCheckingForDups,
        error,
        actions: [
            {
                text: 'Cancel',
                type: 'close',
                onClick: close,
            },
            {
                text: error ? 'Close' : 'Save',
                type: 'submit',
                onClick: error ? close : onSave,
                isDisabled: !isValid(),
            },
        ],
    };

    return (
        <LightBoxWrapper>
            <LightBox options={lightBoxOptions}>
                <AddAttachmentsWrapper inputsScheme={inputsScheme} isEmpty={isEmpty} isLoading={isCheckingForDups}>
                    <StyledDragAndDropFileInput
                        acceptType="image/*,.pdf,application/pdf,.mp3,video/*"
                        dragAreaSize={{ width: '100%', height: '362px' }}
                        iconWidth={72}
                        isEmpty={isEmpty}
                        multipleFiles
                        onChange={handleFiles}
                    />
                    <Table
                        additionalInputs={additionalInputs}
                        attachmentTypes={attachmentTypes}
                        attachments={attachments}
                        deleteAttachment={deleteAttachment}
                        update={updateAttachment}
                    />
                    {checkForDuplicates && duplicateAttachments.length > 0 && (
                        <DuplicateAttachments attachments={duplicateAttachments} />
                    )}
                </AddAttachmentsWrapper>
                {!isEmpty && <AddFilesFooter onFilesChange={onFilesChange} />}
            </LightBox>
        </LightBoxWrapper>
    );
};

export default AddAttachments;
