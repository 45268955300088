import { FloatingNoteActions, FloatingNoteActionsTypes } from 'actions/floatingNoteAction';

export interface FloatingNoteState {
    isOpen: boolean;
}

const INITIAL_NOTE_STATE: FloatingNoteState = {
    isOpen: false,
};

export default function noteReducer(state = INITIAL_NOTE_STATE, action: FloatingNoteActionsTypes): FloatingNoteState {
    switch (action.type) {
        case FloatingNoteActions.SET_FLOATING_NOTE_OPEN: {
            const { isOpen } = action.payload;

            return { ...state, isOpen };
        }
        default: {
            return state;
        }
    }
}
