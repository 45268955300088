import { formatNumber } from '@lemonade-hq/lemonation';
import { isDefined } from '@lemonade-hq/ts-helpers';

// forbid fractions and sanitize non digits chars
export function formatNumericField(value: string | undefined): string {
    if (value?.length == null) return '';

    const sanitizedValue = sanitizeValue(value);

    return sanitizedValue.length
        ? formatNumber(parseInt(sanitizedValue), { minimumFractionDigits: 0, maximumFractionDigits: 0 })
        : '';
}

export function parseNumber(value: string): number {
    return parseInt(value.replace(',', ''), 10); // removes commas
}

export function sanitizeValue(value: string): string {
    return value.replace(/\D/g, '');
}

export function validateMinChars(value: string | undefined, minChars: number, allowEmpty: boolean): boolean {
    if (!isDefined(value) || value.length === 0) {
        return allowEmpty;
    }

    return value.length >= minChars;
}

export function isEmptyString(text: unknown, trim = false): boolean {
    if (typeof text !== 'string') return true;

    return trim ? text.trim().length === 0 : text.length === 0;
}

export const getMinCharsValidationMessage = (minChars: number): string => `Enter at least ${minChars} characters`;

export function trimText(text: string, maxChars: number, showEllipsis: boolean): string {
    if (text.length <= maxChars) return text;

    return `${text.slice(0, maxChars)}${showEllipsis ? '...' : ''}`;
}
