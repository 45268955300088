import type { CoverageInstance, CoveragesEdition, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import type {
    UnderwritingFiltersEdition,
    UnderwritingFlagRulesGroup,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

export function findSetting(coveragesEdition: CoveragesEdition, settingCode: string): SettingInstance {
    const setting = coveragesEdition.settings.find(s => s.templateCode === settingCode);
    if (setting === undefined) {
        throw new Error(`Setting ${settingCode} not found in coverage edition ${coveragesEdition.code}`);
    }

    return setting;
}

export function findCoverage(coveragesEdition: CoveragesEdition, coverageCode: string): CoverageInstance {
    const coverage = coveragesEdition.coverages.find(c => c.templateCode === coverageCode);
    if (coverage === undefined) {
        throw new Error(`Coverage ${coverageCode} not found in coverage edition ${coveragesEdition.code}`);
    }

    return coverage;
}

export function findFlag(
    underwritingFiltersEdition: UnderwritingFiltersEdition,
    flagCode: string
): UnderwritingFlagRulesGroup {
    const flag = underwritingFiltersEdition.flagRulesGroup.find(f => f.flagCode === flagCode);
    if (flag === undefined) {
        throw new Error(
            `Flag ${flagCode} not found in underwriting filters edition ${underwritingFiltersEdition.code}`
        );
    }

    return flag;
}
