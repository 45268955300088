import React, { FC } from 'react';
import { Image } from '@lemonade-hq/bluis';
import { ProductTypes } from 'models/Products';

const ProductIcon: FC<React.PropsWithChildren<{ product: ProductTypes }>> = ({ product }) => {
    switch (product.toLowerCase()) {
        case ProductTypes.Home: {
            return <Image img={{ lightSrc: 'home-icon.svg', darkSrc: 'home-icon-dark.svg', width: 30, height: 30 }} />;
        }
        case ProductTypes.Pet: {
            return <Image img={{ lightSrc: 'pet-icon.svg', darkSrc: 'pet-icon-dark.svg', width: 20, height: 20 }} />;
        }
        case ProductTypes.Car: {
            return <Image img={{ lightSrc: 'car-icon.svg', darkSrc: 'car-icon-dark.svg', width: 20, height: 20 }} />;
        }
        case ProductTypes.Life: {
            return (
                <Image
                    img={{
                        lightSrc: 'life-product-type.svg',
                        darkSrc: 'life-product-type-dark.svg',
                        width: 20,
                        height: 20,
                    }}
                />
            );
        }
        default: {
            return null;
        }
    }
};

export default ProductIcon;
