import { Flex, Select, spacing, Text } from '@lemonade-hq/blender-ui';
import type { DialogProps } from '@lemonade-hq/bluis';
import { AlertMode, Dialog } from '@lemonade-hq/bluis';
import { useState } from 'react';
import { styledSelect } from './ABTestDialog.css';
import type { CoverageRuleGroupABTest } from 'models/LoCo/Insurance/DigitalAgentEdition';
import type { ABTestRuleGroup } from 'models/LoCo/Insurance/UnderwritingFiltersEditionV2';

export const RemoveABTestDialog: React.FC<{
    readonly item?: ABTestRuleGroup | CoverageRuleGroupABTest;
    readonly isLoading?: boolean;
    readonly onSubmit: (value: { readonly variantToKeep: string }) => Promise<void>;
    readonly onClose: () => void;
}> = ({ item, isLoading, onSubmit, onClose }) => {
    const [group, setGroup] = useState<string | null>();
    const dialogProps: DialogProps = {
        title: 'Remove A/B Test',
        loading: isLoading,
        notice: [
            {
                title: (
                    <div>
                        <b>Please note: </b>These rules may have been optimized for the Test Population. Please review
                        accordingly prior to releasing the edition.
                    </div>
                ),
                mode: AlertMode.Info,
            },
        ],
        actions: [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Remove A/B Test',
                disabled: group == null,
                type: 'submit',
                onClick: async () => {
                    await onSubmit({ variantToKeep: group ?? '' });
                    onClose();
                },
            },
        ],
    };

    return (
        <Dialog {...dialogProps}>
            <Flex alignItems="center" flexDirection="column" height="210px">
                <Text fontWeight="bold">Are you sure you want to remove this A/B test?</Text>
                <br />
                <Text textAlign="center">
                    Upon removal, only one set of configured rules will remain on the edition. Select which one to keep:
                </Text>
                <Flex alignItems="center" gap={spacing.s16} justifyContent="center" pt={spacing.s24}>
                    <Text>Rules to Keep</Text>
                    <Select
                        className={styledSelect}
                        onChange={value => setGroup(value)}
                        options={
                            item?.variants.map(variant => ({
                                label: variant.variantName,
                                value: variant.variantName,
                            })) ?? []
                        }
                        placeholder="Select Group"
                        selectedKey={group}
                    />
                </Flex>
            </Flex>
        </Dialog>
    );
};
