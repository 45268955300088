import type { Key } from 'react';
import { useCallback, useMemo } from 'react';
import { ExpressionActionType, useExpressionSimpleEditorContext } from './ExpressionSimpleEditorContext';
import type { ExpressionProps } from './ExpressionSimpleEditorShared';
import { ExpressionContainer, getCompareOperators, StyledWhere } from './ExpressionSimpleEditorShared';
import { ArgumentType } from './expressionTypes';
import type { Argument, BinaryExpressionNode } from './expressionTypes';
import { SelectOperator } from './SelectOperator';

interface BinaryExpressionProps extends ExpressionProps {
    readonly left: React.ReactElement;
    readonly right: React.ReactElement;
    readonly argumentType: Argument;
}

export const BinaryExpression: React.FC<BinaryExpressionProps> = ({ id, left, right, argumentType }) => {
    const { state, dispatch } = useExpressionSimpleEditorContext();
    const node = state.expressionTree[id] as BinaryExpressionNode;

    const showOperator = argumentType.type !== ArgumentType.Unknown;
    const showRight = showOperator && node.operator !== '';

    const operators = useMemo(() => getCompareOperators(argumentType.type), [argumentType.type]);

    const updateOperator = useCallback(
        (value: Key | null) => {
            dispatch({
                type: ExpressionActionType.ChangeOperator,
                payload: { id, operator: value as string },
            });
        },
        [dispatch, id]
    );

    return (
        <ExpressionContainer style={{ padding: id === state.root ? '8px 15px' : '' }}>
            {state.root === id && <StyledWhere>If</StyledWhere>}
            {left}
            {showOperator && (
                <SelectOperator
                    operators={operators}
                    selectedOperator={node.operator}
                    updateOperator={updateOperator}
                />
            )}
            {showRight && right}
        </ExpressionContainer>
    );
};
