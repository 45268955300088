import { Button, Flex, IconButton } from '@lemonade-hq/blender-ui';
import type { FC, PropsWithChildren } from 'react';
import { useCallback } from 'react';
import { ExpressionActionType, useExpressionSimpleEditorContext } from './ExpressionSimpleEditorContext';
import type { ExpressionProps } from './ExpressionSimpleEditorShared';
import type { Argument, ArrayExpressionNode } from './expressionTypes';
import { ArgumentType } from './expressionTypes';

interface ArrayExpressionProps extends ExpressionProps {
    readonly argumentType: Argument;
}

export const ArrayItem: FC<PropsWithChildren<{ readonly removable: boolean; readonly id: string }>> = ({
    children,
    removable,
    id,
}) => {
    const { dispatch } = useExpressionSimpleEditorContext();
    const remove = useCallback((): void => {
        dispatch({
            type: ExpressionActionType.RemoveArrayElement,
            payload: { id },
        });
    }, [dispatch, id]);

    return (
        <Flex alignItems="center" gap="1rem" justifyContent="space-between" width="100%">
            {children}
            {removable && <IconButton icon={'trash-solid'} onClick={remove} size="sm" variant="inline" />}
        </Flex>
    );
};

export const ArrayExpression: FC<PropsWithChildren<ArrayExpressionProps>> = ({ children, id, argumentType }) => {
    const { dispatch, state } = useExpressionSimpleEditorContext();

    const node = state.expressionTree[id] as ArrayExpressionNode;

    const showPlus =
        argumentType.type === ArgumentType.Enum ? node.children.length < argumentType.symbols.length : true;

    return (
        <Flex alignItems="flex-start" flexDirection="column" gap="1rem" justifyContent="center">
            {children}
            {showPlus && (
                <Button
                    label="Add New"
                    onClick={() =>
                        dispatch({
                            type: ExpressionActionType.AddArrayElement,
                            payload: { id },
                        })
                    }
                    startIcon="plus-solid"
                    variant="inline"
                />
            )}
        </Flex>
    );
};
