export enum DialogType {
    AddABTest = 'addABTest',
    RenameVariants = 'renameVariants',
    AddTestGroups = 'addTestGroups',
    RemoveTestGroups = 'removeTestGroups',
    RemoveABTest = 'removeABTest',
}

interface PropsByType {
    readonly title: string;
    readonly allowAdd?: boolean;
    readonly allowRemove?: boolean;
    readonly disableAll?: boolean;
    readonly variantsTitle: string;
    readonly disableExistingItems?: boolean;
}

export const typeToProps: Partial<Record<DialogType, PropsByType>> = {
    [DialogType.AddABTest]: {
        title: 'Add A/B Test',
        allowAdd: true,
        allowRemove: true,
        variantsTitle:
            'Add the Variant Name from Unleash for each test group in order to treat them with different rules:',
    },
    [DialogType.AddTestGroups]: {
        title: 'Add Test Groups',
        allowAdd: true,
        allowRemove: true,
        disableExistingItems: true,
        variantsTitle:
            'Add the Variant Name from Unleash for each test group in order to treat them with different rules:',
    },
    [DialogType.RemoveTestGroups]: {
        title: 'Remove Test Groups',
        allowAdd: false,
        allowRemove: true,
        disableAll: true,
        variantsTitle:
            'Add the Variant Name from Unleash for each test group in order to treat them with different rules:',
    },
};

export interface RenameVariantsValues {
    readonly experimentName: string;
    readonly variants: { readonly oldName: string; readonly newName: string }[];
}
