import { createContext, useContext } from 'react';
import { DialogType } from 'components/Claim/WorkflowsV2/Types';
import { HomeClaim } from 'models/HomeClaim';

interface HomeWorkflowContextType {
    claimId: number;
    claimPublicId: string;
    claim: HomeClaim | null;
    dialog: DialogType | null;
    setDialog: (type: DialogType | null) => void;
    reloadClaim: (claimId: number) => void;
    navigateToNextOpenTask: () => void;
    nextOpenTaskTitle: string;
}

const HomeWorkflowContext = createContext<HomeWorkflowContextType>({
    claimId: 0,
    claimPublicId: '',
    claim: null,
    dialog: null,
    setDialog: () => {},
    reloadClaim: () => {},
    navigateToNextOpenTask: () => {},
    nextOpenTaskTitle: '',
});

export const useHomeWorkflowContext = () => useContext(HomeWorkflowContext);

export default HomeWorkflowContext; // backward compatibility
