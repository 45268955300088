import { ServiceName } from '@lemonade-hq/bluiza';
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { blenderGeneral } from '../../../../apiClients';
import { usePessimisticMutation } from "queries/MutationHooks";

interface UpdateEmailSignatureArgs {
    readonly operatorId: string;
    readonly signature: string;
}
const BASE_URL = '/api/v1/interactions/signatures';

export function useUpdateEmailSignature(): UseMutationResult<null, unknown, UpdateEmailSignatureArgs, null> {
    return usePessimisticMutation({
        invalidateKeys: [[ServiceName.BlenderGeneral, 'SIGNATURES']],
        mutationFn: updateEmailSignature,
    });
}

async function updateEmailSignature(args: UpdateEmailSignatureArgs): Promise<null> {
    return blenderGeneral.post(BASE_URL, args);
}

export function useEmailSignature(operatorId: string): UseQueryResult<UpdateEmailSignatureArgs | undefined> {
    return useQuery({
        queryKey: [ServiceName.BlenderGeneral, 'SIGNATURES'],
        queryFn: async () => getEmailSignature(operatorId),
    });
}

export async function getEmailSignature(operatorId: string): Promise<UpdateEmailSignatureArgs | undefined> {
    const url = `${BASE_URL}?operatorIds=${operatorId}`;

    return blenderGeneral.get<UseQueryResult<UpdateEmailSignatureArgs[]>>(url).then(res => res.data.data?.[0]);
}
