import DOMPurify from 'dompurify';
import React, { FC } from 'react';

const BoldedResult: FC<React.PropsWithChildren<{ term: string; value: string }>> = ({ term, value }) => {
    const escapedTerm = term.replace(/[=?#|'<>^*+()%!\\]/g, '');
    const computedValue = value.replace(new RegExp(`(^|)(${escapedTerm})(|$)`, 'ig'), '$1<b>$2</b>$3');

    return <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(computedValue) }} />;
};

export default BoldedResult;
