import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import type { ProductLine } from 'models/LoCo/Insurance/ProductLine';
import { usePessimisticMutation } from 'queries/MutationHooks';

const PRODUCT_LINES_BASE_PATH = '/api/v1/product-lines';

enum ProductLineQueryKey {
    GetProductLines = 'GET_PRODUCT_LINES',
    CreateProductLine = 'CREATE_PRODUCT_LINE',
}

async function getProductLines(): Promise<ProductLine[]> {
    return await insuranceBlender
        .get<{ data: ProductLine[] }>(PRODUCT_LINES_BASE_PATH)
        .then(response => response.data.data);
}

export function useGetProductLines(enabled = true): UseQueryResult<ProductLine[]> {
    return useQuery({
        queryKey: [ProductLineQueryKey.GetProductLines],
        queryFn: async () => await getProductLines(),
        enabled,
    });
}

export function useCreateProductLine() {
    return usePessimisticMutation({
        mutationFn: createProductLine,
        invalidateKeys: [[ProductLineQueryKey.GetProductLines]],
        mutationKey: [ProductLineQueryKey.CreateProductLine],
    });
}

async function createProductLine({
    name,
    description,
}: {
    readonly name: string;
    readonly description: string;
}): Promise<ProductLine> {
    return await insuranceBlender
        .post<{ data: ProductLine }>(PRODUCT_LINES_BASE_PATH, {
            name,
            description,
        })
        .then(response => response.data.data);
}
