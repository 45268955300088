import type { TableHeaderCellProps } from '@lemonade-hq/bluis';
import {
    ContentSection,
    EmptySection,
    ErrorSection,
    LoadingSection,
    Table,
    TableHeader,
    TableRow,
    TableTitle,
} from '@lemonade-hq/bluis';
import { LinkComp } from '@lemonade-hq/bluiza';
import { getProductUrl } from '../../common/urlBuilders';
import { StyledSectionHeader } from '../../LoCoPagesSharedStyles';
import type { Product } from 'models/LoCo/Insurance/Product';
import type { ProductLine } from 'models/LoCo/Insurance/ProductLine';
import { useGetProducts } from 'queries/LoCo/Insurance/ProductQueries';

const headerFields: TableHeaderCellProps[] = [
    { key: 'name', value: 'Product Name', width: 300 },
    { key: 'location', value: 'Location', width: 300 },
    { key: 'description', value: 'Description', width: 300 },
];

const ProductToRow: React.FC<{
    readonly product: Product;
}> = ({ product }) => {
    const url = getProductUrl(product.code);
    const location = `${product.region}-${product.region === 'US' ? product.state : product.country}`;

    return (
        <TableRow
            key={product.code}
            row={[
                { key: 'name', value: <LinkComp url={url}>{product.name}</LinkComp>, style: { width: '250px' } },
                { key: 'location', value: location, style: { width: '220px' } },
                { key: 'description', value: product.description },
            ]}
        />
    );
};

export interface ProductTableProps {
    readonly productLine: ProductLine;
}

export const ProductsTable: React.FC<ProductTableProps> = ({ productLine }) => {
    const { data: productsData, isError, isPending: isLoading } = useGetProducts(productLine.code);

    if (isLoading) {
        return <LoadingSection />;
    }

    if (isError || !productsData) {
        return <ErrorSection />;
    }

    return (
        <ContentSection key={productLine.code}>
            <StyledSectionHeader>
                <TableTitle title={productLine.name} />
            </StyledSectionHeader>
            {productsData.length === 0 ? (
                <EmptySection>No products defined</EmptySection>
            ) : (
                <Table>
                    <TableHeader headers={headerFields} />
                    {productsData
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(product => (
                            <ProductToRow key={product.code} product={product} />
                        ))}
                </Table>
            )}
        </ContentSection>
    );
};
