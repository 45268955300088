import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useHeaderHeight } from './components/Header';

const RoutesWithNoPaddingBottom: RegExp[] = [/\/backoffice\/watch_tower/];

interface StyledLayoutRootProps {
    readonly headerHeight: number;
}

export const StyledLayout = styled.div<StyledLayoutRootProps>`
    padding: ${({ headerHeight }) => `${headerHeight}px 0 20px`};

    @media print {
        padding-top: 0;
    }
`;

interface LayoutRootProps {
    readonly children: React.ReactNode;
}

export const LayoutRoot: React.FC<React.PropsWithChildren<LayoutRootProps>> = ({ children }) => {
    const height = useHeaderHeight();
    const location = useLocation();

    const hidePaddingBottom = useMemo(
        () => RoutesWithNoPaddingBottom.some(w => w.test(location.pathname)),
        [location.pathname]
    );

    return (
        <StyledLayout headerHeight={height} id="layout-container" style={hidePaddingBottom ? { paddingBottom: 0 } : {}}>
            {children}
        </StyledLayout>
    );
};
