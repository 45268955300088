import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { Text } from '@lemonade-hq/cdk';
import React, { useCallback, useMemo } from 'react';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import type { SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import { useRemoveSettingInstanceFromEdition } from 'queries/LoCo/Insurance/CoveragesEditionQueries';

interface RemoveSettingDialogProps {
    readonly editionCode: string;
    readonly setting: SettingInstance & { readonly name: string };
    readonly onClose: () => void;
}

export const RemoveSettingDialog: React.FC<RemoveSettingDialogProps> = ({ editionCode, setting, onClose }) => {
    const {
        mutateAsync: removeSettingInstanceFromEdition,
        isError,
        isPending: isLoading,
    } = useRemoveSettingInstanceFromEdition(editionCode);

    const submit = useCallback(async () => {
        await removeSettingInstanceFromEdition({
            editionCode,
            settingCode: setting.templateCode,
        });
        onClose();
    }, [editionCode, onClose, removeSettingInstanceFromEdition, setting.templateCode]);

    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Remove',
                type: 'submit',
                onClick: submit,
                disabled: isLoading,
            },
        ],
        [isLoading, onClose, submit]
    );

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={isError ? GENERAL_ERROR_MSG : undefined}
            loading={isLoading}
            onClose={onClose}
            title="Remove Coverage"
        >
            <Text variant="body-md">
                You are about to remove setting <b>{setting.name}</b>
            </Text>
        </Dialog>
    );
};
