import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';

const homeBlenderUrlResolver = getUrlResolver('home-blender');

export async function renewalCoverLetter(policyId: string): Promise<{ data: string }> {
    const url = `/backoffice/policies/${policyId}/renewal_cover_letter`;

    return axios.get(url).then(response => response.data);
}

export async function replacementCostReport(policyId: string): Promise<{ data: string }> {
    const url = `/backoffice/policies/${policyId}/replacement_cost_estimate_report`;

    return axios.get(url).then(response => response.data);
}

export async function resendRenewalPolicy(policyId: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/resend_renewal`);

    return axios.post(url).then(response => response.data);
}

export async function resendRenewalDec(policyId: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/resend_renewal_dec`);

    return axios.post(url).then(response => response.data);
}

export async function switchToAnnual(policyId: string): Promise<void> {
    const url = `/backoffice/policies/${policyId}/switch_to_annual_payment_plan`;

    return axios.post(url).then(response => response.data);
}

export async function activateRenewalPolicy({ policyId }: { policyId: string }): Promise<null> {
    const url = `/backoffice/policies/${policyId}/qa_activate_renewal`;

    return axios.post(url).then(response => response.data);
}

export async function reactiveRenewal(policyId: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/uncancel_renewal`);

    return axios.post(url);
}

export async function cancelRenewalPolicy(policyId: string, data: CancelRenewalDetails): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/cancel_renewal`);

    return axios.post(url, data);
}

export type CancelRenewalDetails = {
    reasonType: string;
    reasonSubtype: string;
    reason: string;
};
