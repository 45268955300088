import { Button, Flex, spacing, Tooltip } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import type { AttachmentActionType, AttachmentDTO } from '../types';

export const BulkActionButtons: FC<{
    readonly setActionProps: (props: {
        readonly action: AttachmentActionType;
        readonly attachments: AttachmentDTO[];
    }) => void;
    readonly selectedRows?: Set<string>;
    readonly attachments?: AttachmentDTO[];
    readonly isArchivedTab?: boolean;
}> = ({ setActionProps, selectedRows, attachments, isArchivedTab }) => {
    const selectedAttachments = attachments?.filter(attachment => selectedRows?.has(attachment.publicId)) ?? [];
    const disableRelabel = selectedAttachments.some(attachment => !attachment.actions?.join(',').includes('relabel'));
    const disableArchive = selectedAttachments.some(attachment => !attachment.actions?.join(',').includes('archive'));

    return (
        <Flex gap={spacing.s08}>
            <Tooltip
                content="Action is not possible for one or more of the selected attachments"
                disabled={disableRelabel}
            >
                <Button
                    disabled={selectedAttachments.length === 0 || disableRelabel}
                    label="Re-label"
                    onClick={() => setActionProps({ action: 'relabel', attachments: selectedAttachments })}
                    startIcon="labels"
                    variant="secondary"
                />
            </Tooltip>
            <Tooltip
                content="Action is not possible for one or more of the selected attachments"
                disabled={disableArchive}
            >
                <Button
                    disabled={selectedAttachments.length === 0 || disableArchive}
                    label="Archive"
                    onClick={() => setActionProps({ action: 'archive', attachments: selectedAttachments })}
                    startIcon="archive"
                    variant="secondary"
                />
            </Tooltip>
            {isArchivedTab && (
                <Button
                    disabled={selectedAttachments.length === 0}
                    label="Unarchive"
                    onClick={() => setActionProps({ action: 'unarchive', attachments: selectedAttachments })}
                    startIcon="archive"
                    variant="secondary"
                />
            )}
        </Flex>
    );
};
