import { themedColor, ViewMode } from '@lemonade-hq/boutique';
import { Flex } from '@lemonade-hq/cdk';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Toggle from 'bluis/Toggle';
import useViewMode from 'hooks/useViewMode';
import useViewModeEnabled from 'hooks/useViewModeEnabled';

const Wrapper = styled(Flex)`
    margin-right: 19px;
    .blender-switch {
        width: 40px;
        height: 24px;
        background: ${themedColor('elementBackground')};
        border-color: ${themedColor('elementBackground')};
        border: 1px solid ${themedColor('separator')};
    }
`;

const Icon = styled.span`
    &:first-child {
        font-size: 17px;
        margin-right: 7px;
    }
    &:last-child {
        font-size: 16px;
        margin-left: 7px;
    }
`;

const ToggleViewMode: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [viewMode, toggleViewMode] = useViewMode();
    const viewModeEnabled = useViewModeEnabled();

    const handleToggle = useCallback(() => {
        toggleViewMode();
    }, [toggleViewMode, viewMode]);

    if (!viewModeEnabled) return null;

    return (
        <Wrapper alignItems="center">
            <Icon>☀️</Icon>
            <Toggle checked={viewMode === ViewMode.Dark} onChange={handleToggle} />
            <Icon>🌙</Icon>
        </Wrapper>
    );
};

export default ToggleViewMode;
