export const CLAIM = {
    STATUS: {
        CANCELED: 'Canceled',
        CLOSED: 'Closed',
        FINAL_PAYMENT: 'Final payment',
        OPEN: 'Active',
        PENDING: 'Pending',
        PENDING_DECLINE: 'Pending decline',
        PENDING_INSPECTION: 'Pending inspection',
    },
};

export const GENERAL_ERROR_MSG = 'OOPS... Something went wrong.\nReport a bug if this continues';
export const INVALID_EMAIL_ADDRESS_MSG = 'Oops... this doesn’t seem to be a valid email address';
export const INVALID_PHONE_NUMBER_MSG = 'Oops... this doesn’t seem to be a valid phone number';
