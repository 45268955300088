import { TrackClickSearchResultParams } from './models';

export const generalSearchEventName = 'general_search';
export const addressEventName = 'address_search';

export function getResultsTrackingData({
    entity,
    product,
    url,
    index,
    total,
    entityTotal,
}: TrackClickSearchResultParams) {
    return {
        product,
        entity,
        url,
        result: (index + 1).toString(),
        total: (total ?? 0).toString(),
        entityTotal: entityTotal.toString(),
    };
}
