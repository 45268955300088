import { ComboBox, Flex, Layout, spacing, Text } from '@lemonade-hq/blender-ui';
import { trackEvent } from '@lemonade-hq/boutique';
import { clsx } from 'clsx';
import type { FC } from 'react';
import type { AttachmentFilter } from '../AttachmentsQueries';
import { useGetAttachmentsFilters } from '../AttachmentsQueries';
import { useAttachmentsData } from '../context';
import { shimmering, shimmeringMockBlock } from './Attachments.css';
import type { PersistStateType } from 'hooks/usePersistState/usePersistState';

export function getInitialFilters(hasUnreviewedAttachments: boolean): HubFilters {
    return {
        searchTerm: '',
        type: [],
        source: [],
        sender: [],
        direction: [],
        page: 1,
        status: 'active',
        reviewed: (!hasUnreviewedAttachments).toString() as Reviewed,
    };
}

export function resetComboBoxFilters(filters: HubFilters): HubFilters {
    return {
        ...filters,
        sender: [],
        source: [],
        type: [],
        direction: [],
    };
}

function getDefaultValue(value: string[]): string[] | undefined {
    return value.length > 0 ? value : undefined;
}

export type Reviewed = 'false' | 'true';

export interface HubFilters extends PersistStateType {
    // Filters
    readonly sender: string[];
    readonly source: string[];
    readonly type: string[];
    readonly direction: string[];
    // Search
    readonly searchTerm: string;
    // Pagination
    readonly page: number;
    // tabs
    readonly status: string;
    readonly reviewed: Reviewed;
}

interface FiltersProps {
    readonly filters: HubFilters;
    readonly updateFilters: (newFilters: Partial<HubFilters>) => void;
}

export const Filters: FC<FiltersProps> = ({ filters, updateFilters }) => {
    const { entityType, entityPublicId } = useAttachmentsData();
    const { data, isError, isLoading } = useGetAttachmentsFilters({ entityType, entityPublicId });

    if (isError) return <Text color="error">Error loading filters</Text>;
    if (isLoading)
        return (
            <Flex className={shimmering} gap={spacing.s08}>
                <Layout className={clsx(shimmeringMockBlock)} />
                <Layout className={clsx(shimmeringMockBlock)} />
                <Layout className={clsx(shimmeringMockBlock)} />
            </Flex>
        );
    if (!data) return null;

    const handleChange = (filter: AttachmentFilter, items: { value: string; label: string }[] | null): void => {
        trackEvent('docs.gallery.applied', {
            entity_id: entityPublicId,
            entity_type: entityType,
            name: 'filter_applied',
            filter_type: filter.name,
            filter_value: items?.map(i => i.value).join(',') ?? '',
        });
        updateFilters({ [filter.name]: items?.map(i => i.value) ?? [] });
    };

    return (
        <Flex gap={spacing.s08}>
            {data.map(filter => {
                return (
                    <ComboBox
                        cancelable
                        defaultValue={getDefaultValue(filters[filter.name] as string[])}
                        items={filter.options.map(option => ({
                            value: option.value,
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            label: option.label ?? option.value,
                        }))}
                        key={filter.name}
                        maxAmountOfChips={2}
                        mode="multiple"
                        onSelectionChange={items => handleChange(filter, items)}
                        placeholder={filter.displayValue ?? filter.label}
                        variant="brand"
                    />
                );
            })}
        </Flex>
    );
};
