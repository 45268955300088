import type { MutationFunction, MutationKey, QueryKey, UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface UsePessimisticMutation<TResult, TVariables> {
    readonly mutationFn: MutationFunction<TResult, TVariables>;
    readonly invalidateKeys: QueryKey[];
    readonly onSuccess?: (data: TResult, variables: TVariables) => void;
    readonly onError?: (error: unknown) => void;
    readonly mutationKey?: MutationKey;
}

export function usePessimisticMutation<TResult, TVariables>(
    args: UsePessimisticMutation<TResult, TVariables>
): UseMutationResult<TResult, unknown, TVariables, null> {
    const { mutationFn, invalidateKeys, onSuccess, onError, mutationKey } = args;
    const queryClient = useQueryClient();

    return useMutation<TResult, unknown, TVariables, null>({
        mutationFn,
        mutationKey,
        onSettled: () => {
            invalidateKeys.forEach(key => {
                void queryClient.invalidateQueries({ queryKey: key });
            });
        },
        onSuccess,
        onError,
    });
}

/**
 * @deprecated
 * Use `usePessimisticMutation` instead.
 */
export function usePessimisticMutationLegacy<TVariables, TResult>(
    qFunction: MutationFunction<TResult, TVariables>,
    invalidateKeys: QueryKey[],
    onSuccess?: (data: TResult, variables: TVariables) => void,
    onError?: (error: unknown) => void
): UseMutationResult<TResult, unknown, TVariables, null> {
    return usePessimisticMutation<TResult, TVariables>({
        mutationFn: qFunction,
        invalidateKeys,
        onSuccess,
        onError,
    });
}
