import { themedColor } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import type React from 'react';
import styled, { css } from 'styled-components';

export const ContentContainer = styled(Flex)`
  height: 80px;
  padding: 15px 70px 15px 30px;
  background-color: ${themedColor('componentBackground')};
  border-bottom: 1px solid ${themedColor('separator')};
  align-items: center;
  gap: 20px;
`;

export const RightSideBlock = styled(Flex).attrs({
  role: 'separator',
})<{ readonly noBorder?: boolean }>`
  margin-left: auto;
  border-left: 1px solid ${themedColor('separator')};
  padding-left: 20px;
  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `}
`;

export const EntitySummaryHeader: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <ContentContainer>{children}</ContentContainer>
);
