import { ProductTypes } from '@lemonade-hq/bluiza';
import { useMemo } from 'react';
import { SupervisorContext } from './Context';
import { useSupervisor } from './useSupervisor';
import { useOperatorActivityTracking } from 'hooks/useOperatorActivityTracking';

interface Props {
    readonly children: React.ReactNode;
}

export const SupervisorProvider: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
    const { state, actions } = useSupervisor();

    useOperatorActivityTracking({
        name: 'supervisor-app-timer',
        product: ProductTypes.Supervisor,
    });
    const value = useMemo(() => ({ state, actions }), [state, actions]);

    return <SupervisorContext.Provider value={value}>{children}</SupervisorContext.Provider>;
};
