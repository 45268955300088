import React from 'react';

export class GenericErrorBoundary<P> extends React.Component<P, { error?: Error }> {
  constructor(props: P) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error };
  }
}
