import { Image } from '@lemonade-hq/bluis';
import { Flex, Tooltip } from '@lemonade-hq/cdk';
import styled from 'styled-components';

const ActionButtonWrapper = styled(Flex)<{ readonly top?: string; readonly right?: string }>`
    justify-content: flex-end;
    position: absolute;
    top: ${({ top }) => top ?? '0'};
    right: ${({ right }) => right ?? '0'};
`;

const StyledButton = styled(Flex)`
    height: 28px;
    width: 28px;
    border: 0;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

interface InternalButtonProps {
    readonly onRemoveClick: () => void;
}

const InternalButton: React.FC<InternalButtonProps> = ({ onRemoveClick }) => (
    <StyledButton onClick={onRemoveClick}>
        <Image img={{ lightSrc: 'trash-icn.png', height: 17, width: 15 }} />
    </StyledButton>
);

interface RemoveButtonProps {
    readonly top?: string;
    readonly right?: string;
    readonly onRemoveClick: () => void;
}

export const RemoveButton: React.FC<React.PropsWithChildren<RemoveButtonProps>> = ({ top, right, onRemoveClick }) => {
    return (
        <ActionButtonWrapper right={right} top={top}>
            <Tooltip content="REMOVE">
                <InternalButton onRemoveClick={onRemoveClick} />
            </Tooltip>
        </ActionButtonWrapper>
    );
};
