import type { WorkflowsActionTypes } from 'actions/workflowsActionTypes';
import { WorkflowsActions } from 'actions/workflowsActionTypes';
import type { Task } from 'models/Workflows';

export interface WorkflowsState {
    readonly activeTask: Task | null;
    readonly activeSlug: string;
}

const INITIAL_WORKFLOWS_STATE: WorkflowsState = {
    activeTask: null,
    activeSlug: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function workflowReducer(state = INITIAL_WORKFLOWS_STATE, action: WorkflowsActionTypes): WorkflowsState {
    switch (action.type) {
        case WorkflowsActions.SetActiveTask: {
            const { task } = action.payload;

            return { ...state, activeTask: task };
        }

        case WorkflowsActions.SetActiveSlug: {
            const { slug } = action.payload;

            return { ...state, activeSlug: slug };
        }

        default: {
            return state;
        }
    }
}
