import React from 'react';
import loadable from '@loadable/component';
import { Routes, Route } from 'react-router-dom';
import { FederatedModule, LoadingSection } from '@lemonade-hq/bluis';
import * as Sentry from '@sentry/react';
import { NotesProvider } from 'bluis/Notes';

const AsyncPetQuote = loadable(async () => await import(/* webpackChunkName: "PetQuote" */ '../PetQuote'), {
    fallback: <LoadingSection />,
});

const AsyncPetQuotesList = loadable(
    async () => await import(/* webpackChunkName: "PetQuotesList" */ '../PetQuotesList'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncPetPolicy = loadable(async () => await import(/* webpackChunkName: "PetPolicy" */ '../PetPolicy'), {
    fallback: <LoadingSection />,
});

const AsyncPetPoliciesList = loadable(
    async () => await import(/* webpackChunkName: "PetPoliciesList" */ '../PetPoliciesList'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncPetClaim = loadable(async () => await import(/* webpackChunkName: "PetClaim" */ '../PetClaim'), {
    fallback: <LoadingSection />,
});

const AsyncPetPreapproval = loadable(
    async () => await import(/* webpackChunkName: "PetPreapproval" */ './PetPreapproval'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncPetClaimsList = loadable(
    async () => await import(/* webpackChunkName: "PetClaimsList" */ '../PetClaimsList'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncPetEditions = loadable(async () => await import(/* webpackChunkName: "Editions" */ '../Editions'), {
    fallback: <LoadingSection />,
    resolveComponent: module => module.Editions,
});

const AsyncCreateManualPetClaim = loadable(
    async () => await import(/* webpackChunkName: "CreateManualPetClaim" */ '../PetPolicy/CreateManualPetClaim'),
    {
        fallback: <LoadingSection />,
    }
);

const CreateManualPetPreapproval = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "CreateManualPetPreapproval" */ './PetPreapproval/CreateManualPetPreapproval'
        ).then(module => ({
            default: module.CreateManualPetPreapproval,
        }))
);

const AsyncPetFallback = loadable(
    async () => await import(/* webpackChunkName: "PetNotFoundSection" */ 'components/PetNotFoundSection'),
    {
        fallback: <LoadingSection />,
    }
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Pet: React.FC<React.PropsWithChildren<unknown>> = () => (
    <NotesProvider
        analyticsParams={{
            notesIconClicked: {
                name: 'workflows.note.icon.clicked',
            },
            notesAccordionOpened: {
                name: 'workflows.note.add_note.clicked',
            },
            notesAccordionClosed: {
                name: 'workflows.note.close_editor.clicked',
            },
            noteSubmitted: {
                name: 'workflows.note.submitted',
            },
        }}
    >
        <SentryRoutes>
            <Route element={<AsyncPetPolicy />} path="policies/:policyId/*" />
            <Route element={<AsyncPetClaim />} path="claims/:claimId/*" />
            <Route element={<AsyncPetQuote />} path="quotes/:quoteId/*" />
            <Route
                element={
                    <FederatedModule>
                        <CreateManualPetPreapproval />
                    </FederatedModule>
                }
                path="preapprovals/manual_preapproval"
            />
            <Route element={<AsyncPetPreapproval />} path="preapprovals/:preapprovalId/*" />
            <Route element={<AsyncCreateManualPetClaim />} path="claims/manual_claim" />
            <Route element={<AsyncPetQuotesList />} path="quotes" />
            <Route element={<AsyncPetPoliciesList />} path="policies" />
            <Route element={<AsyncPetClaimsList />} path="claims" />
            <Route element={<AsyncPetEditions />} path="editions" />
            <Route element={<AsyncPetFallback />} path="*" />
        </SentryRoutes>
    </NotesProvider>
);

export default Pet;
