import { PolicyActions, PolicyActionTypes } from 'actions/policyActionsTypes';
import { PetPolicy } from 'models/PetPolicy';
import { LegacyHomePolicy } from 'models/HomePolicy';
import { PetPolicyView } from 'components/PetPolicy/types';

interface PolicyState {
    loadFailed: boolean;
    policy: LegacyHomePolicy | PetPolicy | PetPolicyView | null;
    notifications: Record<string, unknown> | null;
    authorizationError: Error | null;
}

const initialState: PolicyState = {
    loadFailed: false,
    policy: null,
    notifications: null,
    authorizationError: null,
};

export default function policy(state = initialState, action: PolicyActionTypes) {
    const { type } = action;

    switch (type) {
        case PolicyActions.SET_POLICY: {
            const { data } = action;

            if ('notifications' in data) {
                const notificationsKeys = Object.keys(data.notifications);
                const notifications = notificationsKeys.map(key => data.notifications[key]);

                return {
                    ...state,
                    policy: {
                        ...data,
                        notifications,
                    },
                };
            }

            return {
                ...state,
                policy: data,
            };
        }
        case PolicyActions.SET_POLICY_LOAD_FAILED: {
            return {
                ...state,
                loadFailed: true,
            };
        }
        case PolicyActions.SET_POLICY_NOT_FOUND: {
            return {
                ...state,
                notFound: true,
            };
        }
        case PolicyActions.SET_POLICY_AUTHORIZATION_ERROR: {
            return {
                ...state,
                loadFailed: true,
                authorizationError: action.data,
            };
        }
        default:
            return state;
    }
}
