import type { IconName, ListItemMenuSectionProps } from '@lemonade-hq/blender-ui';
import { DatetimeFormat, formatDatetime } from '@lemonade-hq/lemonation';
import type { SerializableToolsRevision as ToolsRevision } from '@lemonade-hq/persisted-tools';
import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useMemo } from 'react';
import type { ToolRevisionForListing } from '../persisted_tools.queries';
import { MISSING_TOOLS_REVISION_ID_MARKER } from '../shared/routing.consts';
import { getComparisonIcon } from './compare.helpers';
import { useComparePageNavigation } from './useComparePageNavigation';

// we got both the public ids and the tools revision themselves since the public ids can come from the url, before we get any data
interface UseToolsRevisionsHistoryProps {
    readonly newerToolsRevision?: ToolsRevision;
    readonly newerToolsRevisionPublicId?: string;
    readonly olderToolsRevision?: ToolsRevision;
    readonly olderToolsRevisionPublicId?: string;
    readonly publishedToolsRevision?: ToolsRevision;
    readonly sunsettedToolsRevisions?: ToolRevisionForListing[];
}

interface UseToolsRevisionsHistoryResult {
    readonly toolsRevisionHistoryForListMenu: ListItemMenuSectionProps<string>[];
    readonly setToolsRevisionRange: (toolsRevisionsPublicIds: string[]) => void;
}

export function useToolsRevisionsHistory({
    newerToolsRevision,
    newerToolsRevisionPublicId,
    olderToolsRevision,
    olderToolsRevisionPublicId,
    publishedToolsRevision,
    sunsettedToolsRevisions,
}: UseToolsRevisionsHistoryProps): UseToolsRevisionsHistoryResult {
    const { navigate: navigateToCompare } = useComparePageNavigation();

    const toolsRevisionHistoryForListMenu = useMemo(() => {
        const mergedList = orderBy(
            uniqBy(
                [
                    publishedToolsRevision,
                    ...(sunsettedToolsRevisions ?? []),
                    olderToolsRevision,
                    newerToolsRevision,
                ].filter(Boolean),
                'publicId'
            ),
            'publishedAt',
            'desc'
        ).filter(Boolean) as ToolsRevision[];

        const beforeIcons = mergedList.map(tr => ({
            id: tr.publicId,
            label:
                tr.publishedAt != null
                    ? formatDatetime(new Date(tr.publishedAt), {
                          datetimeFormat: DatetimeFormat.TimeDefault,
                      })
                    : 'Not in production',
            secondaryLabel: tr.publicId,
            icon: 'dash' as IconName,
            publishedAt: tr.publishedAt ?? 'Not published',
        }));
        const olderIndex = beforeIcons.findIndex(tr => tr.id === olderToolsRevisionPublicId);
        const newerIndex = beforeIcons.findIndex(tr => tr.id === newerToolsRevisionPublicId);

        // go over the list again, and populate with the right icons in order to mimic a timeline
        // e.g. first and last items are a circle, but everything in between is 3 dots
        const withIcons = beforeIcons.map((tr, i) => {
            if (i === olderIndex) {
                return { ...tr, icon: getComparisonIcon('older') };
                // a bit confusing - the older is down the list, with a higher index
            } else if (i === newerIndex) {
                return { ...tr, icon: getComparisonIcon('newer') };
                // a bit confusing - the older is down the list, with a higher index
            }

            return tr;
        });

        const groupedByDays = groupBy(withIcons, tr => new Date(tr.publishedAt).toDateString());
        const sectionsByDays: ListItemMenuSectionProps<string>[] = Object.entries(groupedByDays).map(
            ([day, toolsRevisions]) => ({
                label:
                    day === 'Invalid Date'
                        ? 'Not published'
                        : formatDatetime(new Date(day), {
                              datetimeFormat: DatetimeFormat.DateOrdinalLongDayAndLongMonth,
                          }),
                options: toolsRevisions,
            })
        );

        return sectionsByDays;
    }, [
        newerToolsRevision,
        newerToolsRevisionPublicId,
        olderToolsRevision,
        olderToolsRevisionPublicId,
        publishedToolsRevision,
        sunsettedToolsRevisions,
    ]);

    const setToolsRevisionRange = useCallback(
        (toolsRevisionsPublicIds: string[]) => {
            const filteredToolsRevisionsPublicIds = toolsRevisionsPublicIds.filter(
                id => id !== MISSING_TOOLS_REVISION_ID_MARKER
            );

            if (filteredToolsRevisionsPublicIds.length > 2) {
                const justSelectedItem = last(filteredToolsRevisionsPublicIds);
                // find the first one (which is the 'newer' tools revision), since we only want to change only the 'older' one on selection
                const filteredSortedItem = toolsRevisionHistoryForListMenu
                    .flatMap(section => section.options)
                    .find(tr => filteredToolsRevisionsPublicIds.includes(tr.id));
                navigateToCompare(justSelectedItem, filteredSortedItem?.id ?? MISSING_TOOLS_REVISION_ID_MARKER);
            } else if (filteredToolsRevisionsPublicIds.length === 2) {
                const filteredSortedItems = toolsRevisionHistoryForListMenu
                    .flatMap(section => section.options)
                    .filter(tr => filteredToolsRevisionsPublicIds.includes(tr.id));
                navigateToCompare(filteredSortedItems[1].id, filteredSortedItems[0].id);
            } else if (filteredToolsRevisionsPublicIds.length === 1) {
                const firstItem = filteredToolsRevisionsPublicIds[0];
                navigateToCompare(firstItem, MISSING_TOOLS_REVISION_ID_MARKER);
            } else {
                navigateToCompare(MISSING_TOOLS_REVISION_ID_MARKER, MISSING_TOOLS_REVISION_ID_MARKER);
            }
        },
        [navigateToCompare, toolsRevisionHistoryForListMenu]
    );

    return { toolsRevisionHistoryForListMenu, setToolsRevisionRange };
}
