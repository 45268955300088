/* eslint-disable @lemonade-hq/base/favor-singular-enums */
/* eslint-disable @typescript-eslint/naming-convention */
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type {
    HomeQuotesSearchParams,
    HomeQuotesSearchParamsResponse,
    HomeQuotesSearchResponse,
} from 'apis/Home/QuotesAPI';
import {
    fetchHomeQuoteData,
    fetchSearchParams,
    getAggregatedNotifications,
    getHeaderSettings,
    searchHomeQuotes,
} from 'apis/Home/QuotesAPI';
import type { UpdateReviewAttributes } from 'apis/Home/UnderwritingReviewsAPI';
import { updateReview } from 'apis/Home/UnderwritingReviewsAPI';
import type { QualityGrade, RatingLogItem } from 'models/HomePolicy';
import type { HomeQuoteDataResponse, LowerYourPrice } from 'models/HomeQuote';
import type { HeaderColumn } from 'models/HomeShared';
import type { Syndicate } from 'models/Syndicate';
import { usePessimisticMutationLegacy } from 'queries/MutationHooks';

export interface HomeQuoteData {
    readonly referrals: HomeQuoteDataResponse['referrals'];
    readonly quote_members: HomeQuoteDataResponse['quote_members'];
    readonly quote_answers: HomeQuoteDataResponse['quote_answers'];
    readonly quote_permissions: HomeQuoteDataResponse['quote_permissions'];
    readonly quote_valuation_data: HomeQuoteDataResponse['quote_valuation_data'];
    readonly quote_earthquake_coverage: HomeQuoteDataResponse['quote_earthquake_coverage'];
    readonly syndicate: HomeQuoteDataResponse['syndicate'];
    readonly quote_lower_your_price: HomeQuoteDataResponse['quote_lower_your_price'];
    readonly rating_log: HomeQuoteDataResponse['quote_rating_log'];
    readonly page_layout: HomeQuoteDataResponse['page_layout'];
    readonly quote_rating: HomeQuoteDataResponse['quote_rating'];
    readonly quote: HomeQuoteDataResponse['quote'];
    readonly quote_actions: HomeQuoteDataResponse['quote_actions'];
    readonly quote_summary: HomeQuoteDataResponse['quote_summary'];
    readonly quote_link: HomeQuoteDataResponse['quote_link'];
    readonly quote_pdf: HomeQuoteDataResponse['quote_pdf'];
    readonly user: HomeQuoteDataResponse['user'];
    readonly user_summary: HomeQuoteDataResponse['user_summary'];
    readonly coverages: HomeQuoteDataResponse['coverages'];
    readonly homeowners_review_permissions: HomeQuoteDataResponse['homeowners_review_permissions'];
    readonly partner_details: HomeQuoteDataResponse['partner_details'];
}

export type HomeQuoteRes = HomeQuoteData;

export enum HomeQuoteQueryKeys {
    GetQuotesListSearchFilters = 'get_quotes_list_search_filters',
    GetQuoteList = 'get_quote_list',
    GetReferrals = 'get_referrals',
    GetQuoteUser = 'get_quote_user',
    GetQuoteDetails = 'get_quote_details',
    GetChatAnswers = 'get_chat_answers',
    GetSyndicate = 'get_syndicate',
    GetLowerYourPrice = 'get_lower_your_price',
    GetRatingLog = 'get_rating_log',
    GetQualityGrade = 'get_quality_grade',
    GetQuoteMembers = 'get_quote_members',
    GetQuoteEarthquakeCoverage = 'get_quote_earthquake_coverage',
    GetQuoteSummary = 'get_quote_summary',
    GetQuoteHomeownersReviewPermissions = 'get_homeowners_review_permissions',
    GetHomeOwnersReview = 'get_homeowners_review',
    GetQuoteInitialData = 'get_quote_initial_data',
    GetQuoteRating = 'get_quote_rating',
    GetQuoteActions = 'get_quote_actions',
    GetQuoteLink = 'get_quote_link',
    GetQuotePdf = 'get_quote_pdf',
    GetQuoteNotifications = 'get_quote_notifications',
    GetHeaderSettings = 'get_quotes_header_settings',
    GetQuote = 'get_quote',
    GetDeclineQuoteComponents = 'get_decline_quote_components',
}

export function useQuotesSearchFilters(): UseQueryResult<HomeQuotesSearchParamsResponse> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuotesListSearchFilters],
        queryFn: async () => await fetchSearchParams(),
    });
}

export function useQuotesSearch(searchParams: HomeQuotesSearchParams): UseQueryResult<HomeQuotesSearchResponse> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteList, searchParams],
        queryFn: async () => await searchHomeQuotes(searchParams),
    });
}

async function getHomeQuoteUser(quoteId: string): Promise<Pick<HomeQuoteDataResponse, 'user'>> {
    return await fetchHomeQuoteData(quoteId, ['user']);
}

export function useHomeQuoteUser(quoteId: string): UseQueryResult<Pick<HomeQuoteRes, 'user'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteUser, quoteId],
        queryFn: async () => await getHomeQuoteUser(quoteId),
    });
}

export function useHomeQuoteSummary(
    quoteId: string
): UseQueryResult<Pick<HomeQuoteRes, 'quote_summary' | 'user_summary'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteSummary, quoteId],
        queryFn: async () => await fetchHomeQuoteData(quoteId, ['quote_summary', 'user_summary']),
    });
}

export function useHomeQuote(quoteId: string): UseQueryResult<Pick<HomeQuoteRes, 'quote'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuote, quoteId],
        queryFn: async () => await fetchHomeQuoteData(quoteId, ['quote']),
    });
}

export function useQuoteHomeownersReviewMetadata(
    quoteId: string
): UseQueryResult<Pick<HomeQuoteRes, 'homeowners_review_permissions'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteHomeownersReviewPermissions, quoteId],
        queryFn: async () => await fetchHomeQuoteData(quoteId, ['homeowners_review_permissions']),
    });
}

export async function getQuoteDetails(
    quoteId: string
): Promise<Pick<HomeQuoteDataResponse, 'coverages' | 'partner_details' | 'quote' | 'syndicate'>> {
    return await fetchHomeQuoteData(quoteId, [
        'quote',
        'coverages',
        'quote_permissions',
        'partner_details',
        'syndicate',
    ]);
}

export function useQuoteDetails(
    quoteId: string
): UseQueryResult<Pick<HomeQuoteRes, 'coverages' | 'partner_details' | 'quote_permissions' | 'quote' | 'syndicate'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteDetails, quoteId],
        queryFn: async () => await getQuoteDetails(quoteId),
    });
}

async function getReferrals(quoteId: string): Promise<Pick<HomeQuoteDataResponse, 'referrals'>> {
    return await fetchHomeQuoteData(quoteId, ['referrals']);
}

export function useReferrals(quoteId: string): UseQueryResult<Pick<HomeQuoteRes, 'referrals'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetReferrals, quoteId],
        queryFn: async () => await getReferrals(quoteId),
    });
}

type ChatAnswers = 'quote_answers' | 'quote_valuation_data';

async function getChatAnswers(quoteId: string): Promise<Pick<HomeQuoteDataResponse, ChatAnswers>> {
    return await fetchHomeQuoteData(quoteId, ['quote_answers', 'quote_valuation_data']);
}

export function useChatAnswers(quoteId: string): UseQueryResult<Pick<HomeQuoteRes, ChatAnswers>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetChatAnswers, quoteId],
        queryFn: async () => await getChatAnswers(quoteId),
    });
}

async function getSyndicate(quoteId: string): Promise<Pick<HomeQuoteDataResponse, 'syndicate'>> {
    return await fetchHomeQuoteData(quoteId, ['syndicate']);
}

export function useSyndicate(quoteId: string): UseQueryResult<Syndicate> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetSyndicate, quoteId],
        queryFn: async () => (await getSyndicate(quoteId)).syndicate,
    });
}

async function getLowerYourPrice(quoteId = ''): Promise<Pick<HomeQuoteDataResponse, 'quote_lower_your_price'>> {
    return await fetchHomeQuoteData(quoteId, ['quote_lower_your_price']);
}

export function useLowerYourPrice(quoteId?: string): UseQueryResult<LowerYourPrice> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetLowerYourPrice, quoteId],
        queryFn: async () => (await getLowerYourPrice(quoteId)).quote_lower_your_price,
        enabled: Boolean(quoteId),
    });
}

async function getRatingLog(quoteId = ''): Promise<Pick<HomeQuoteDataResponse, 'quote_rating_log'>> {
    return await fetchHomeQuoteData(quoteId, ['quote_rating_log']);
}

export function useRatingLog(quoteId?: string): UseQueryResult<RatingLogItem[]> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetRatingLog, quoteId],
        queryFn: async () => (await getRatingLog(quoteId)).quote_rating_log,
        enabled: Boolean(quoteId),
    });
}

async function getQualityGrade(quoteId = ''): Promise<Pick<HomeQuoteDataResponse, 'quote_quality_grade'>> {
    return await fetchHomeQuoteData(quoteId, ['quote_quality_grade']);
}

export function useQualityGrade(quoteId?: string): UseQueryResult<QualityGrade> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQualityGrade, quoteId],
        queryFn: async () => (await getQualityGrade(quoteId)).quote_quality_grade,
        enabled: Boolean(quoteId),
    });
}

async function getQuoteMembers(quoteId: string): Promise<Pick<HomeQuoteDataResponse, 'quote_members'>> {
    return await fetchHomeQuoteData(quoteId, ['quote_members']);
}

export function useQuoteMembers(quoteId: string): UseQueryResult<Pick<HomeQuoteRes, 'quote_members'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteMembers, quoteId],
        queryFn: async () => await getQuoteMembers(quoteId),
    });
}

async function getQuoteEarthquakeCoverage(
    quoteId: string
): Promise<Pick<HomeQuoteDataResponse, 'quote_earthquake_coverage'>> {
    return await fetchHomeQuoteData(quoteId, ['quote_earthquake_coverage']);
}

export function useQuoteEarthquakeCoverage(
    quoteId: string
): UseQueryResult<HomeQuoteDataResponse['quote_earthquake_coverage']> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteEarthquakeCoverage, quoteId],
        queryFn: async () => (await getQuoteEarthquakeCoverage(quoteId)).quote_earthquake_coverage,
    });
}

export function getHomeQuoteAddressBasedKeys(quoteId: string): [HomeQuoteQueryKeys, string][] {
    return [[HomeQuoteQueryKeys.GetQuoteDetails, quoteId]];
}

export function getHomeQuoteStatusBasedKeys(quoteId: string): [HomeQuoteQueryKeys, string][] {
    return [
        [HomeQuoteQueryKeys.GetQuoteDetails, quoteId],
        [HomeQuoteQueryKeys.GetQuoteSummary, quoteId],
        [HomeQuoteQueryKeys.GetQuoteInitialData, quoteId],
        [HomeQuoteQueryKeys.GetHomeOwnersReview, quoteId],
    ];
}

export function useUpdateReviewStatusMutation(
    quoteId: string
): UseMutationResult<null, unknown, UpdateReviewAttributes, null> {
    return usePessimisticMutationLegacy(updateReview, getHomeQuoteStatusBasedKeys(quoteId));
}

export function useQuoteInitialData(
    quoteId: string,
    options?: {
        readonly enabled?: boolean;
    }
): UseQueryResult<Pick<HomeQuoteRes, 'page_layout' | 'quote_summary' | 'user_summary'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteInitialData, quoteId],
        queryFn: async () => await fetchHomeQuoteData(quoteId, ['quote_summary', 'user_summary', 'page_layout']),
        ...options,
    });
}

async function getHomeQuoteRating(quoteId: string): Promise<Pick<HomeQuoteDataResponse, 'quote_rating'>> {
    return await fetchHomeQuoteData(quoteId, ['quote_rating']);
}

export function useHomeQuoteRating(quoteId: string): UseQueryResult<HomeQuoteDataResponse['quote_rating']> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteRating, quoteId],
        queryFn: async () => (await getHomeQuoteRating(quoteId)).quote_rating,
    });
}

export function useQuoteActions(
    quoteId: string,
    { enabled = true } = {}
): UseQueryResult<Pick<HomeQuoteRes, 'quote_actions'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteActions, quoteId],
        queryFn: async () => await fetchHomeQuoteData(quoteId, ['quote_actions']),
        enabled,
    });
}

export function useQuoteLink(quoteId: string, { enabled = true } = {}): UseQueryResult<string> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteLink, quoteId],
        queryFn: async () => (await fetchHomeQuoteData(quoteId, ['quote_link'])).quote_link.url,
        enabled,
    });
}

export function useQuotePdf(quoteId: string, { enabled = true } = {}): UseQueryResult<string> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuotePdf, quoteId],
        queryFn: async () => (await fetchHomeQuoteData(quoteId, ['quote_pdf'])).quote_pdf.url,
        enabled,
    });
}

export function useHomeQuoteNotifications(quoteId: string): UseQueryResult<Record<string, unknown>[]> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetQuoteNotifications, quoteId],
        queryFn: async () => await getAggregatedNotifications(quoteId),
        enabled: Boolean(quoteId),
        refetchOnWindowFocus: false,
    });
}

export function useHeaderSettings(): UseQueryResult<HeaderColumn[]> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetHeaderSettings],
        queryFn: async () => await getHeaderSettings(),
    });
}
