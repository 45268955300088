import { Flex } from '@lemonade-hq/blender-ui';
import type { Key } from 'react';
import { useCallback, useMemo } from 'react';
import { ExpressionActionType, useExpressionSimpleEditorContext } from './ExpressionSimpleEditorContext';
import type { ExpressionProps } from './ExpressionSimpleEditorShared';
import { ExpressionContainer, getCompareOperators, StyledWhere } from './ExpressionSimpleEditorShared';
import type { Argument, CallExpressionNode } from './expressionTypes';
import { ArgumentType } from './expressionTypes';
import { SelectOperator } from './SelectOperator';

interface CallExpressionProps extends ExpressionProps {
    readonly children: React.ReactElement[];
    readonly argumentType: Argument;
}

export const CallExpression: React.FC<CallExpressionProps> = ({ id, children, argumentType }) => {
    const { state, dispatch } = useExpressionSimpleEditorContext();

    const node = state.expressionTree[id] as CallExpressionNode;

    const showOperator = argumentType.type !== ArgumentType.Unknown;
    const showRight = showOperator && node.callee !== '';

    const operators = useMemo(() => getCompareOperators(argumentType.type), [argumentType.type]);

    const updateOperator = useCallback(
        (value: Key | null) => {
            dispatch({
                type: ExpressionActionType.ChangeOperator,
                payload: { id, operator: value as string },
            });
        },
        [dispatch, id]
    );

    return (
        <ExpressionContainer style={{ padding: id === state.root ? '8px 15px' : '' }}>
            {state.root === id && <StyledWhere>If</StyledWhere>}
            {children[0]}
            {showOperator && (
                <SelectOperator operators={operators} selectedOperator={node.callee} updateOperator={updateOperator} />
            )}
            {showRight && (
                <Flex alignItems="center" gap="1rem" justifyContent="space-between">
                    {children.slice(1)}
                </Flex>
            )}
        </ExpressionContainer>
    );
};
