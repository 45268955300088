import { themedColor } from '@lemonade-hq/bluis';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Alink = styled.a<{ readonly color?: string }>`
  color: ${({ color }) => color ?? themedColor('link')};

  &:hover {
    text-decoration: none;
    color: ${themedColor('linkHover')};
  }
  &:focus {
    text-decoration: none;
    color: ${themedColor('linkHover')};
  }
`;

export const ClientSideLink = styled(Link)<{ readonly color?: string }>`
  color: ${({ color }) => color ?? themedColor('link')};

  &:hover {
    text-decoration: none;
    color: ${themedColor('linkHover')};
  }
  &:focus {
    text-decoration: none;
    color: ${themedColor('linkHover')};
  }
`;

// list of pages that are still in monolith (lemonade-hq/lemonade) or Blender RB (lemonade-hq/blender)
const blacklistForClientSideRouting = [
  {
    path: /\/backoffice\/home\/switch-requests/,
  },
  {
    path: /\/backoffice\/quotes\/simulation/,
  },
  {
    path: /\/backoffice\/zendesk/,
  },
  {
    path: /\/backoffice\/twilio/,
  },
  {
    path: /\/backoffice\/snippets/,
  },
];

export function isServerNav(url: string): boolean {
  return blacklistForClientSideRouting.some(endpoint => endpoint.path.test(url));
}

export interface GetLinkParams extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  readonly url: string;
  readonly value?: number | string | null;
  readonly color?: string;
  readonly target?: '_blank' | '_self';
  readonly children?: React.ReactNode;
}

export const LinkComp = ({
  url,
  value,
  color,
  target = '_self',
  children,
  ...restProps
}: GetLinkParams): JSX.Element | null => {
  if (((value == null || value === '') && children == null) || url === '') return null;

  const isSSN = isServerNav(url);

  if (target === '_blank') {
    return (
      <Alink color={color} href={url} target={target} {...restProps}>
        {children ?? value}
      </Alink>
    );
  }

  return (
    <ClientSideLink
      color={color}
      data-testid={isSSN ? 'server-side-nav' : 'client-side-nav'}
      reloadDocument={isSSN}
      target={target}
      to={url}
      {...restProps}
    >
      {children ?? value}
    </ClientSideLink>
  );
};
