import React from 'react';
import { Context } from '../Context';
import { useHeaderContext } from '../hooks';

interface ProviderProps {
    readonly children: React.ReactNode;
}

export const HeaderProvider: React.FC<React.PropsWithChildren<ProviderProps>> = ({ children }) => {
    const context = useHeaderContext();

    return <Context.Provider value={context}>{children}</Context.Provider>;
};
