import React from 'react';
import loadable from '@loadable/component';
import { Routes, Route } from 'react-router-dom';
import { LoadingSection } from '@lemonade-hq/bluis';
import * as Sentry from '@sentry/react';

const AsyncLifeQuotesList = loadable(
    async () => import(/* webpackChunkName: "LifeQuotesList" */ 'life/LifeQuotesList'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncLifeQuote = loadable(async () => import(/* webpackChunkName: "LifeQuote" */ 'life/LifeQuote'), {
    fallback: <LoadingSection />,
});

const AsyncLifePoliciesList = loadable(
    async () => import(/* webpackChunkName: "LifePoliciesList" */ 'life/LifePoliciesList'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncLifePolicy = loadable(async () => import(/* webpackChunkName: "LifePolicy" */ 'life/LifePolicy'), {
    fallback: <LoadingSection />,
});

const AsyncLifeGame = loadable(async () => import(/* webpackChunkName: "LifeGame" */ 'life/LifeGame'), {
    fallback: <LoadingSection />,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Life: React.FC<React.PropsWithChildren<unknown>> = () => (
    <SentryRoutes>
        <Route element={<AsyncLifeQuotesList />} path="quotes" />
        <Route element={<AsyncLifeQuote />} path="quotes/:quoteId/*" />
        <Route element={<AsyncLifePoliciesList />} path="policies" />
        <Route element={<AsyncLifePolicy />} path="policies/:policyId/*" />
        <Route element={<AsyncLifeGame />} path="/" />
    </SentryRoutes>
);

export default Life;
