import type { Descendant, Text } from 'slate';
import { jsx } from 'slate-hyperscript';
import type { CustomElement } from '../types';

export function serializeText(text: string | null): string {
    if (!text) return '';
    if (!text.trim()) return '';

    return text;
}

const ELEMENT_TAGS = {
    A: (el: HTMLElement) =>
        el.getAttribute('href') ? { type: 'link', url: el.getAttribute('href') } : { type: 'div' },
    H1: () => ({ type: 'div' }),
    H2: () => ({ type: 'div' }),
    H3: () => ({ type: 'div' }),
    H4: () => ({ type: 'div' }),
    H5: () => ({ type: 'div' }),
    H6: () => ({ type: 'div' }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    UL: () => ({ type: 'bulleted-list' }),
    P: () => ({ type: 'div' }),
    DIV: () => ({ type: 'div' }),
};

const TEXT_TAGS = {
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    STRONG: () => ({ bold: true }),
    B: () => ({ bold: true }),
    SPAN: () => ({}),
};

function isValidTextTag(el: HTMLElement) {
    return Array.from(el.children).every(n => n.nodeType === Node.TEXT_NODE);
}

export function deserializeHtmlElement(
    el: ChildNode | HTMLElement
): CustomElement | Descendant[] | Text | string | null {
    if (el.nodeType === Node.TEXT_NODE) {
        return el.textContent;
    } else if (el.nodeType !== 1) {
        return null;
    } else if (el.nodeName === 'BR') {
        // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
        return jsx('element', { type: 'div' }, jsx('text', ''));
    }

    const { nodeName } = el;

    let children = Array.from(el.childNodes)
        .map(deserializeHtmlElement)
        .map(val => (typeof val === 'string' ? { text: val } : val));

    if (children.length === 0) {
        children = [{ text: '' }];
    }

    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children);
    }

    if (ELEMENT_TAGS[nodeName] !== undefined && children.length > 0) {
        const attrs = ELEMENT_TAGS[nodeName](el as HTMLElement);

        if (typeof children === 'string') {
            children = [{ text: children }];
        }

        // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
        return jsx('element', attrs, children);
    }

    if (TEXT_TAGS[nodeName] !== undefined && isValidTextTag(el as HTMLElement)) {
        const attrs = TEXT_TAGS[nodeName](el);

        if ((el as HTMLElement).style.fontWeight === 'bold' || Number((el as HTMLElement).style.fontWeight) > 400) {
            attrs.bold = true;
        }

        return children.map(child => jsx('text', attrs, child));
    }

    return children as Descendant[];
}
