import React from 'react';
import { Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { LoadingSection } from '@lemonade-hq/bluis';
import * as Sentry from '@sentry/react';

const AsyncPartner = loadable(async () => import(/* webpackChunkName: "Partner" */ '.'), {
    fallback: <LoadingSection />,
});

const AsyncPartnerList = loadable(async () => import(/* webpackChunkName: "PartnerList" */ '../PartnerList'), {
    fallback: <LoadingSection />,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const PartnerRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <SentryRoutes>
            <Route element={<AsyncPartner />} path="/:partnerId" />
            <Route element={<AsyncPartnerList />} path="/" />
        </SentryRoutes>
    );
};

export default PartnerRoutes;
