import { EmptySection } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

export interface OutcomeProps {
    readonly title: string;
    readonly subtitle?: string;
}

const Wrapper = styled(Flex)`
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 30px;
`;

const StyledEmptySection = styled(EmptySection)`
    width: 500px;
`;

export const OutcomeWrapper: React.FC<PropsWithChildren<OutcomeProps>> = ({ children, subtitle, title }) => {
    return (
        <Wrapper>
            <StyledEmptySection subtitle={subtitle}>{title}</StyledEmptySection>
            {children}
        </Wrapper>
    );
};
