import { CardGrid, CardGridArea, Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import * as styles from './ImpactAnalysis.css';
import { insightsMap } from './utils';
import type { Insight, RolloutInsightTopic } from 'models/LoCo/Insurance/ReleaseRolloutInsightsReport';

export const Insights: React.FC<{ readonly topic: RolloutInsightTopic; readonly insights: Insight[] }> = ({
    insights,
    topic,
}) => {
    const areas = insights.map(insight => [insight.insight as string]);

    return (
        <CardGrid areas={areas} showSeparators>
            {insights.map(insight => (
                <CardGridArea
                    areaName={insight.insight}
                    className={styles.grid}
                    key={insight.insight}
                    variant="secondary"
                >
                    <Flex className={styles.row} gap={spacing.s04}>
                        <Text type="h5">{insight.metCriteriaCount}</Text>
                        <Text color="secondary" type="text-md">
                            ({insight.metCriteriaPercent}%)
                        </Text>

                        {insightsMap[topic][insight.insight]}
                    </Flex>
                    {insight.failedToAnalyzeCount > 0 && (
                        <Flex className={styles.row} gap={spacing.s04}>
                            <Text>{insight.failedToAnalyzeCount}</Text>
                            <Text color="secondary" type="text-md">
                                ({insight.failedToAnalyzePercent}%)
                            </Text>
                            could not be fully analyzed
                        </Flex>
                    )}
                </CardGridArea>
            ))}
        </CardGrid>
    );
};
