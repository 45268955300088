import { ContentSection } from '@lemonade-hq/bluis';
import { ProductDetails } from './ProductDetails';
import { PublishedEditionSetsTable } from './PublishedEditionSetsTable';
import type { Product } from 'models/LoCo/Insurance/Product';

export const ProductGeneral: React.FC<{ readonly product: Product }> = ({ product }) => (
    <>
        <ProductDetails product={product} />
        <ContentSection>
            <PublishedEditionSetsTable productCode={product.code} />
        </ContentSection>
    </>
);
