import { CopyButton as CopyButtonInternal, spacing } from '@lemonade-hq/blender-ui';
import type { Property } from 'csstype';
import styled from 'styled-components';

const StyledCopyButtonWrapper = styled.div<{ readonly margin?: Property.Gap }>`
    display: flex;
    align-items: center;
    gap: ${({ margin }) => margin ?? spacing.s04};
`;

const StyledHoverCopyButtonWrapper = styled(StyledCopyButtonWrapper)`
    > :nth-child(2) {
        visibility: hidden;
    }
    &:hover {
        & :nth-child(2) {
            visibility: visible;
        }
    }
`;

interface CopyButtonProps {
    readonly disabled?: boolean;
    readonly successMessage?: string;
    readonly textToCopy: string;
    readonly textToDisplay: JSX.Element | string;
    readonly margin?: Property.Gap;
    readonly visibleOnHover?: boolean;
}

export const CopyableText: React.FC<CopyButtonProps> = ({
    disabled,
    successMessage = 'Copied!',
    textToCopy,
    textToDisplay,
    margin,
    visibleOnHover,
}) => {
    const Component = visibleOnHover ? StyledHoverCopyButtonWrapper : StyledCopyButtonWrapper;

    return (
        <Component margin={margin} onClick={(e: { stopPropagation: () => void }) => e.stopPropagation()}>
            {<span>{textToDisplay}</span>}
            <CopyButtonInternal disabled={disabled} successMessage={successMessage} textToCopy={textToCopy} />
        </Component>
    );
};
