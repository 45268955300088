import { useCallback, useState } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { spacing } from '../../theme/spacing.css';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';
import { Tooltip } from '../Tooltip/Tooltip';

export async function copyToClipboard(textToCopy: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(textToCopy);
  } catch (error) {
    console.error('Failed to copy to clipboard:', error);
  }
}

export type CopyButtonProps = {
  readonly disabled?: boolean;
  readonly successMessage: string;
  readonly className?: string;
  readonly size?: 'lg' | 'md' | 'sm';
  readonly textToCopy: string;
  readonly onAfterCopy?: () => void;
};

export const CopyButton: React.FC<React.PropsWithChildren<CopyButtonProps>> = ({
  disabled,
  successMessage,
  className,
  size = 'md',
  ...restProps
}) => {
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const copyFunction = useCallback(async (): Promise<void> => {
    await copyToClipboard(restProps.textToCopy);
    restProps.onAfterCopy?.();
  }, [restProps]);

  const onMouseDown = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    await copyFunction();
    setShowCopiedMessage(true);
    setTimeout(() => setShowCopiedMessage(false), 1000);
  };

  const CopiedMessage: React.FC = () => (
    <Flex alignItems="center" gap={spacing.s02}>
      <Icon name="check" />
      {successMessage}
    </Flex>
  );

  return (
    <Flex className={className}>
      <Tooltip content={<CopiedMessage />} defaultOpen disabled={!showCopiedMessage} key={showCopiedMessage.toString()}>
        <IconButton
          disabled={disabled}
          icon="copy"
          onMouseDown={onMouseDown}
          size={size}
          title="copy"
          variant="inline"
        />
      </Tooltip>
    </Flex>
  );
};
