import { font, themedColor } from '@lemonade-hq/cdk';
import styled from 'styled-components';

export const BlenderHeader = styled.div`
    height: 50px;
    border-bottom: 1px solid ${themedColor('separator')};
    background: ${themedColor('componentBackground')};
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    @media print {
        display: none;
    }

    .blender-header-right {
        height: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;

        .blender-header-right-main {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 20px;
            border-left: 1px solid ${themedColor('separator')};
        }

        .header-search-bar-container {
            margin-right: 18px;
        }
    }

    .blender-header-left {
        height: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;

        .blender-header-left-main {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 20px 0 0;
            border-right: 1px solid ${themedColor('separator')};

            a {
                padding: 15px 20px;
            }

            .blender-header-logo {
                display: flex;
                align-items: center;

                .blender-header-logo-link {
                    padding: 0 0 0 10px;
                }
            }
        }

        .blender-header-left-context {
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 20px;
            ${font('main', { fontSize: '14px', lineHeight: '22px' })};
            text-transform: uppercase;
        }
    }
`;
