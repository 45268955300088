import { Tag } from '@lemonade-hq/bluis';
import { themedColor } from '@lemonade-hq/boutique';
import styled from 'styled-components';

export const Container = styled.div`
    width: 358px;
    padding: 16px;
    background: ${themedColor('componentBackground')};
    border: 1px solid ${themedColor('separator')};
    border-radius: 10px;
    box-shadow: 0px 2px 9px 6px rgba(51, 51, 51, 0.07);
`;

export const Header = styled.header`
    margin-bottom: 14px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${themedColor('separator')};
`;

interface PillProps {
    readonly active: boolean;
}

export const Pill = styled(Tag)<PillProps>`
    &::before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: ${props => themedColor(props.active ? 'success' : 'secondaryText')};
    }
`;

export const SubTitle = styled.p`
    margin: 4px 0 0;
    color: ${themedColor('secondaryText')};
`;

export const Title = styled.h3`
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
