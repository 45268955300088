import { TabGroup } from '@headlessui/react';
import type { FC, PropsWithChildren } from 'react';
import { Layout } from '../../base/Layout/Layout';
import { TabsProvider } from './context';
import type { TabsSize, TabsVariant } from './types';

interface TabsProps {
  readonly startAtTabIndex?: number;
  // Unique string for accessibility purposes
  readonly uuid?: string;
  readonly size?: TabsSize;
  readonly variant: TabsVariant;
}

export const Tabs: FC<PropsWithChildren<TabsProps>> = ({
  children,
  startAtTabIndex,
  uuid = 'lmnd_tabs',
  size = 'md',
  variant,
  ...props
}) => {
  return (
    <Layout {...props}>
      <TabsProvider size={size} uuid={uuid} variant={variant}>
        <TabGroup defaultIndex={startAtTabIndex}>{children}</TabGroup>
      </TabsProvider>
    </Layout>
  );
};
