import { ErrorSection, LoadingSection } from '@lemonade-hq/bluis';
import type { Maybe } from '@lemonade-hq/ts-helpers';
import { ErrorBoundary } from '@sentry/react';
import { Suspense, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ManageReleaseStep } from '../ReleasesShared';
import type { EditionSelectionData } from './EditionSelectInputs';
import { EditionsSelection } from './EditionsSelection';
import type { EffectiveDatesStepType } from './EffectiveDates';
import { EffectiveDates } from './EffectiveDates';
import { RolloutStrategy } from './RolloutStrategy';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import { StyledDialog } from 'components/LoCo/common/components/Dialog/Dialog';
import { useMiniFlowFormDialog } from 'components/LoCo/common/components/Dialog/useMiniFlowFormDialog';
import { StyledMiniFlow } from 'components/LoCo/common/components/MiniFlow';
import { parseLocalDateToUtcDate } from 'components/LoCo/common/helpers/dateHelpers';
import { getReleaseUrl } from 'components/LoCo/common/urlBuilders';
import type { EditionWithType } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType, VersionType } from 'models/LoCo/Insurance/BaseEdition';
import { useCreateRelease } from 'queries/LoCo/Insurance/ReleasesQueries';

function getEditionCode(editions: readonly DeepNullable<EditionWithType>[], editionType: EditionType): Maybe<string> {
    return editions.find(edition => edition.type === editionType)?.code ?? undefined;
}

const stepIds = [ManageReleaseStep.Editions, ManageReleaseStep.RolloutStrategy, ManageReleaseStep.EffectiveDates];

type ManageReleaseDialogData = {
    readonly editions: EditionSelectionData;
    readonly rolloutStrategy: VersionType;
    readonly effectiveDates: EffectiveDatesStepType;
};

export const ManageReleaseDialog: React.FC<{
    readonly productCode: string;
    readonly onClose: () => void;
}> = ({ productCode, onClose }) => {
    const { mutateAsync: createRelease, isError, isPending } = useCreateRelease();
    const navigate = useNavigate();

    const { actions, currentStep, dispatch, state } = useMiniFlowFormDialog<
        ManageReleaseStep,
        DeepNullable<ManageReleaseDialogData>
    >({
        steps: stepIds,
        onClose,
        onSubmit: async _data => {
            const release = await createRelease({
                productCode,
                rolloutStrategy: state.rolloutStrategy,
                underwritingFiltersEditionCode: getEditionCode(
                    state.editions.selectedEditions,
                    EditionType.UnderwritingFilters
                ),
                coveragesEditionCode: getEditionCode(state.editions.selectedEditions, EditionType.Coverages),
                digitalAgentEditionCode: getEditionCode(state.editions.selectedEditions, EditionType.DigitalAgent),
                ratingEditionCode: getEditionCode(state.editions.selectedEditions, EditionType.Rating),
                newBusinessEffectiveAt: parseLocalDateToUtcDate(state.effectiveDates.newBusiness),
                renewalEffectiveAt: parseLocalDateToUtcDate(state.effectiveDates.renewals),
                platformSchemaRevision:
                    state.editions.selectedPlatformSchema === null
                        ? undefined
                        : Number(state.editions.selectedPlatformSchema),
                productSchemaRevision:
                    state.editions.selectedProductSchema === null
                        ? undefined
                        : Number(state.editions.selectedProductSchema),
            });

            navigate(getReleaseUrl(productCode, release.publicId));
        },
        onNext: step => {
            if (step === ManageReleaseStep.RolloutStrategy && state.rolloutStrategy === VersionType.BugFix) {
                dispatch({ type: 'effectiveDates', value: { ...state.effectiveDates, isValid: true } });
            }
        },
        isNextDisabled: (data, step) => {
            switch (step) {
                case ManageReleaseStep.Editions:
                    return !data.editions.isValid;
                case ManageReleaseStep.RolloutStrategy:
                    return data.rolloutStrategy === null;
                case ManageReleaseStep.EffectiveDates:
                    return !data.effectiveDates.isValid;
                default:
                    return false;
            }
        },
        clear: step => {
            switch (step) {
                case ManageReleaseStep.RolloutStrategy:
                    dispatch({ type: 'rolloutStrategy', value: null });
                    break;
                case ManageReleaseStep.EffectiveDates:
                    dispatch({
                        type: 'effectiveDates',
                        value: {
                            newBusiness: null,
                            renewals: null,
                            isValid: state.rolloutStrategy === VersionType.BugFix,
                        },
                    });
                    break;
                default:
                    break;
            }
        },
        initialData: {
            editions: {
                selectedEditions: [],
                selectedPlatformSchema: null,
                selectedProductSchema: null,
                isValid: false,
            },
            rolloutStrategy: null,
            effectiveDates: {
                newBusiness: null,
                renewals: null,
                isValid: false,
            },
        },
    });

    const steps = useMemo(
        () => [
            {
                title: 'Select editions',
                body: (
                    <EditionsSelection
                        editionSelectionData={state.editions}
                        onChange={newEditionStepData =>
                            dispatch({
                                type: 'editions',
                                value: newEditionStepData,
                            })
                        }
                        productCode={productCode}
                    />
                ),
                id: ManageReleaseStep.Editions,
            },
            {
                title: 'Rollout strategy',
                body: (
                    <RolloutStrategy
                        onChange={value => dispatch({ type: 'rolloutStrategy', value })}
                        selectedEditions={state.editions.selectedEditions as EditionWithType[]}
                        selectedStrategy={state.rolloutStrategy}
                    />
                ),
                id: ManageReleaseStep.RolloutStrategy,
            },
            {
                title: 'Effective dates',
                body: (
                    <EffectiveDates
                        effectiveDates={state.effectiveDates}
                        onChange={value => dispatch({ type: 'effectiveDates', value })}
                        selectedStrategy={state.rolloutStrategy}
                    />
                ),
                id: ManageReleaseStep.EffectiveDates,
            },
        ],
        [dispatch, productCode, state.editions, state.effectiveDates, state.rolloutStrategy]
    );

    return (
        <StyledDialog
            actions={actions}
            closeOnOutsideClick={false}
            error={isError ? 'Failed to create release' : undefined}
            loading={isPending}
            minWidth="800px"
            onClose={onClose}
            size="large"
            title={'Start a Release'}
        >
            <ErrorBoundary fallback={<ErrorSection noBorders />}>
                <Suspense fallback={<LoadingSection noBorders />}>
                    <StyledMiniFlow activeStepIndex={steps.findIndex(step => step.id === currentStep)} steps={steps} />
                </Suspense>
            </ErrorBoundary>
        </StyledDialog>
    );
};
