export type Adjuster = {
    public_id: string;
    full_name: string;
    image_url?: string;
    photo_url?: string;
    squad?: string;
    operator_id?: number;
    role?: string;
};

export type EmailAdjuster = {
    publicId: string;
    fullName: string;
    imageUrl?: string;
    photoUrl?: string;
    squad?: string;
    operatorId?: number;
    role?: string;
}

export type Adjusters = {
    current?: Adjuster | null;
    options?: Adjuster[];
    can_reassign_squad?: boolean;
};

export type EmailAdjustersState = {
    current?: EmailAdjuster | null;
    options?: EmailAdjuster[];
    canReassignSquad?: boolean;
};

export enum AdjusterAvailability {
    AVAILABLE = 'available',
    NOT_AVAILABLE = 'not_available',
}

export interface AdjusterData {
    publicId: string;
    operatorPublicId: string;
    status: AdjusterStatus;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
    phoneExtension: string;
    photoUrl: string | null;
    productType: string;
    role: AdjusterRole;
    office: string;
    squad: string | null;
    availability: string;
    isPhotoPublic: boolean;
    squadInstance: number | null;
    authorityPaymentLevel: number | null;
    eligibleToHandleTopTier: boolean;
    statistics?: { adjusterPublicId: string; oldClaimsAmount: number | null; newClaimsAmount: number | null };
}

export type AdjustersResponse = AdjusterData[];

export enum AdjusterStatus {
    Active = 'active',
    Inactive = 'inactive',
}

export enum AdjusterRole {
    Advocate = 'advocate',
    Specialist = 'specialist',
    Champion = 'champion',
    TeamLead = 'team_lead',
    Admin = 'admin',
    QA = 'qa',
    Temp = 'temp',
    SiuSpecialist = 'siu_specialist',
    SubrogationAnalyst = 'subrogation_analyst',
    Appraiser = 'appraiser',
    Coach = 'coach',
    MRRSpecialist = 'mrr_specialist',
    ClaimAnalyst = 'claim_analyst',
    AppealsCoordinator = 'appeals_coordinator',
    AppealsAnalyst = 'appeals_analyst',
}

export enum AdjusterSquad {
    // Home
    Europe = 'europe',
    Condo = 'condo',
    Dwelling = 'dwelling',
    Contents = 'contents',
    Liability = 'liability',
    LargeLoss = 'large_loss',
    Litigation = 'litigation',
    SpecialInvestigationUnit = 'special_investigation_unit',
    // Home Legacy
    TheftFast = 'theft_fast',
    TheftHighValue = 'theft_high_value',
    WaterRenters = 'water_renters',
    WaterHomeowners = 'water_homeowners',
    Fire = 'fire',

    PetHealth = 'pet_health',
    PetMRR = 'pet_mrr',
    PetSupport = 'pet_support',
    PetAppeals = 'pet_appeals',
    PetPreventativeSupport = 'pet_preventative_support',

    CarFirstNoticeOfLoss = 'car_first_notice_of_loss',
    CarPropertyDamages = 'car_property_damages',
    CarBodilyInjuriesFast = 'car_bodily_injuries_fast',
    CarBodilyInjuriesHighValue = 'car_bodily_injuries_high_value',
    CarDRP = 'car_drp',

    Catastrophe = 'catastrophe',
    Appraisers = 'appraisers',
    PipMedpay = 'pip_medpay',
    Subrogation = 'subrogation',
    Mailroom = 'mailroom',
}

export const NO_SQUAD = 'none';
export const EU_SQUAD = 'europe';
export const EU_OFFICE = 'ams';

export const PET_SQUADS = [
    AdjusterSquad.PetHealth,
    AdjusterSquad.PetMRR,
    AdjusterSquad.PetSupport,
    AdjusterSquad.PetAppeals,
    AdjusterSquad.PetPreventativeSupport,
];
