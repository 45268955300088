import { AlertMode, GENERAL_ERROR_MSG } from '@lemonade-hq/bluis';
import { useCallback } from 'react';
import { RemoveRuleDialog } from '../RemoveRuleDialog';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { useDeleteCoveragesEditionRule } from 'queries/LoCo/Insurance/CoveragesEditionQueries';
import { useDeleteDigitalAgentEditionRules } from 'queries/LoCo/Insurance/DigitalAgentEditionQueries';

interface RemoveSingleRuleDialogProps {
    readonly editionType: EditionType;
    readonly editionCode: string;
    readonly ruleId: string;
    readonly variantName?: string;
    readonly onClose: () => void;
}

export const RemoveSingleRuleDialog: React.FC<RemoveSingleRuleDialogProps> = ({
    onClose,
    editionType,
    editionCode,
    ruleId,
    variantName,
}) => {
    const {
        mutateAsync: deleteCoverageRule,
        isPending: isCoveragesLoading,
        isError: isCoveragesError,
    } = useDeleteCoveragesEditionRule(editionCode);

    const {
        mutateAsync: deleteDigitalAgentRule,
        isPending: isDigitalAgentLoading,
        isError: isDigitalAgentError,
    } = useDeleteDigitalAgentEditionRules(editionCode);

    const onSubmit = useCallback(async () => {
        if (editionType === EditionType.Coverages) {
            await deleteCoverageRule({ ruleId });
        } else {
            await deleteDigitalAgentRule({
                rules: [{ publicId: ruleId, variants: variantName != null ? [variantName] : undefined }],
            });
        }

        onClose();
    }, [editionType, onClose, deleteCoverageRule, ruleId, deleteDigitalAgentRule, variantName]);

    return (
        <RemoveRuleDialog
            error={isDigitalAgentError || isCoveragesError ? GENERAL_ERROR_MSG : undefined}
            isLoading={isCoveragesLoading || isDigitalAgentLoading}
            notice={
                variantName != null
                    ? [
                          {
                              title: `Please note: this rule will only be removed from ${variantName}. It must be removed from each a/b test group separately (if needed)`,
                              mode: AlertMode.Info,
                          },
                      ]
                    : undefined
            }
            onClose={onClose}
            onSubmit={onSubmit}
        />
    );
};
