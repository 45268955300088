import { ProductTypes } from './ProductTypes';

/**
 * Retrieves the product type from a given URL in Blender.
 *
 * @example
 * getProductTypeFromUrl('www.blender.com/backoffice/home')
 * -> ProductTypes.Home ('home')
 */

export function getProductTypeFromUrl(url: string): ProductTypes | null {
  const productLine = url.toLowerCase().split('/')[2] as ProductTypes;

  if (Object.values(ProductTypes).includes(productLine)) {
    return productLine;
  }

  return null;
}
