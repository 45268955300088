import type { ActionsMenuItem } from '@lemonade-hq/blender-ui';
import { ActionsMenu } from '@lemonade-hq/blender-ui';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import { useMemo } from 'react';
import type { FC } from 'react';
import type { AttachmentActionType, AttachmentDTO } from 'components/Attachments/types';

export interface AttachmentActionsMenuProps {
    readonly attachment: AttachmentDTO;
    readonly setActionProps: (props: {
        readonly action: AttachmentActionType;
        readonly attachments: AttachmentDTO[];
    }) => void;
}

export const AttachmentActionsMenu: FC<AttachmentActionsMenuProps> = ({ attachment, setActionProps }) => {
    const items = useMemo<ActionsMenuItem[]>(
        () =>
            attachment.actions
                ?.filter(it => it !== 'check_for_modifications')
                .map(action => ({
                    label: snakeCaseToReadable(action),
                    onClick: () => setActionProps({ action, attachments: [attachment] }),
                })) ?? [],
        [attachment, setActionProps]
    );

    if (items.length === 0) return null;

    return <ActionsMenu items={items} />;
};
