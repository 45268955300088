import type { GenericRecordData, RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import isEmpty from 'lodash/isEmpty';
import type { FC, ReactNode } from 'react';
import { Button } from '../../../Button/Button';
import { DraggableDynamicListItem } from '../../../DynamicList/DraggableDynamicListItem';
import * as styles from '../../../DynamicList/DynamicList.css';
import { DynamicListItem, DynamicListText } from '../../../DynamicList/DynamicListItem';
import { SortableDndContext } from '../../../SortableDnd/SortableDndContext';
import { useForm } from '../../FormContext';
import { FormProvider } from '../../FormProvider';
import { generateTypedFormComponents } from '../../typeHelpers';
import { useConnectToForms } from '../common';
import { DynamicListItemActionMenu } from './common';
import type { DynamicListProps } from './DynamicList';

import { useDynamicListContext } from './DynamicListContext';
import { Flex } from 'libs/blender-ui/src/base/Flex/Flex';
import { spacing } from 'libs/blender-ui/src/theme/spacing.css';

type DynamicListRecordItemsProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TItem extends GenericRecordData,
> = DynamicListProps<TSchema, TSchemaKey, RecordLikeShape> & {
  readonly items: TItem[];
  readonly schemaKey: TSchemaKey;
  readonly itemsSchema: RecordLikeShape;
};

const SaveButton: FC<{ readonly onSave: (data: unknown) => void }> = ({ onSave }) => {
  const { values, validationResults } = useForm();

  return (
    <Button
      disabled={!validationResults.valid}
      label="Done"
      onClick={() => onSave(values)}
      size="sm"
      variant="primary"
    />
  );
};

export const DynamicListRecordItems = <
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TItem extends GenericRecordData,
>(
  props: DynamicListRecordItemsProps<TSchema, TSchemaKey, TItem>,
): ReactNode => {
  const {
    sortable,
    items,
    schemaKey,
    config,
    renderFormAs,
    newItemTemplate,
    additionalItemContext,
    itemsSchema,
    displayAs,
    variant = 'unordered',
    canAddAbove = true,
  } = props;
  const { reorderItems, editingIndex, cancelEditing, finishEditingItem } = useDynamicListContext();
  const { validationResults } = useForm();
  const { disabled } = useConnectToForms({ schemaKey });
  return (
    <SortableDndContext
      direction="vertical"
      disabled={!sortable}
      itemIds={items.map((_, index) => index.toString())}
      onDragEnd={reorderItems}
    >
      {items.map((item, index) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const invalid =
          editingIndex !== index &&
          Object.keys(validationResults.failingValidations).some(key => key.startsWith(`${schemaKey}[${index}]`));
        return editingIndex === index ? (
          // eslint-disable-next-line react/no-array-index-key -- ESLint is drunk
          <DynamicListItem key={`${schemaKey}-${index}`} variant={'unordered'}>
            <FormProvider
              initialConfig={config}
              initialValues={
                { ...(isEmpty(item) ? newItemTemplate : item), ...additionalItemContext } as unknown as undefined
              }
              schema={itemsSchema as TSchema}
            >
              <Flex className={styles.recordListItem({ variant: 'unordered' })} flexDirection="column">
                <li>
                  {/* @ts-expect-error: horror typing story, it's okay as it's internal */}
                  {renderFormAs({
                    // The following line throws TS2590 but we don't actually need type safety here so it's disabled
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
                    components: (generateTypedFormComponents as any)(),
                    index,
                  })}
                </li>
                <Flex gap={spacing.s08} justifyContent="flex-end">
                  <Button label="Cancel" onMouseDown={cancelEditing} size="sm" variant="inline" />
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any */}
                  <SaveButton onSave={data => finishEditingItem(index, data as any)} />
                </Flex>
              </Flex>
            </FormProvider>
          </DynamicListItem>
        ) : (
          <DraggableDynamicListItem
            additionalComponent={
              disabled ? null : (
                <DynamicListItemActionMenu
                  canAddAbove={canAddAbove}
                  editable
                  index={index}
                  items={items}
                  schemaKey={schemaKey}
                />
              )
            }
            id={index.toString()}
            invalid={invalid}
            // eslint-disable-next-line react/no-array-index-key
            key={`${schemaKey}-${index}`}
            sortable={sortable}
            variant={variant}
          >
            <DynamicListText text={displayAs(item)} />
          </DraggableDynamicListItem>
        );
      })}
    </SortableDndContext>
  );
};
