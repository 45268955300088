// Claim
export const SET_CLAIM_DATA = 'SET_CLAIM_DATA';
export const SET_CLAIM_LOAD_FAILED = 'SET_CLAIM_LOAD_FAILED';
export const CLAIM_ADD_PAYEE = 'CLAIM_ADD_PAYEE';
export const CLAIM_UPDATE_ITEM = 'CLAIM_UPDATE_ITEM';
export const SET_CLAIM_NOTIFICATIONS = 'SET_CLAIM_NOTIFICATIONS';
export const CLAIM_UPDATE_SUBRO = 'CLAIM_UPDATE_SUBRO';
export const CLAIM_UPDATE_ATTACHMENT = 'CLAIM_UPDATE_ATTACHMENT';
export const SET_CLAIM_AUTHORIZATION_ERROR = 'SET_CLAIM_AUTHORIZATION_ERROR';
export const UPDATE_STATE_AFTER_EMAIL_SENT = 'UPDATE_STATE_AFTER_EMAIL_SENT';

// USER
export const SET_USER = 'SET_USER';
export const SET_USER_LOAD_FAILED = 'SET_USER_LOAD_FAILED';
export const SET_USER_TIMELINE = 'SET_USER_TIMELINE';
export const SET_USER_AUTHORIZATION_ERROR = 'SET_USER_AUTHORIZATION_ERROR';

// QUOTE
export const SET_QUOTE = 'SET_QUOTE';
export const SET_QUOTE_LOAD_FAILED = 'SET_QUOTE_LOAD_FAILED';
export const SET_QUOTE_NOT_FOUND = 'SET_QUOTE_NOT_FOUND';
export const SET_QUOTE_AUTHORIZATION_ERROR = 'SET_QUOTE_AUTHORIZATION_ERROR';

// CURRENCY
export const SET_CURRENCY_LOCALE = 'SET_CURRENCY_LOCALE';
