import axios from 'axios';
import { EntityTypes } from '@lemonade-hq/bluiza';
import { snakeToCamelCaseKeys } from '@lemonade-hq/ts-helpers';
import { Attachment } from 'models/Attachment';
import { Recipient } from 'models/Recipient';
import { Email } from 'models/Email';
import { blenderGeneral } from '../apiClients';

export interface SendEmailArgs {
    recipient: Recipient;

    entityId?: string;
    entityType?: EntityTypes;
    subject: string;
    content: string;
    attachments: Attachment[];
    isNewEmail: boolean;
    ccRecipients: Recipient[];
    shouldUpdateThreadOwner?: boolean;
}

export type SendEmailArgsWithEntityId = SendEmailArgs & { entityId: string };

interface SendEmailPayload {
    id?: string;
    recipient: Recipient;
    subject: string;
    content: string;
    attachments_ids: string[];
    create_new_ticket: boolean;
    cc_recipients: Recipient[];
    entity_type?: EntityTypes;
    update_thread_owner?: boolean;
}

export async function sendEmail(
    args: SendEmailArgsWithEntityId,
    baseUrl: string,
    camelCaseKeys = false
): Promise<void> {
    const { entityId, attachments, isNewEmail, ccRecipients, entityType, shouldUpdateThreadOwner, ...restArgs } = args;
    const url = `${baseUrl}/${entityId}/send_email`;
    const attachments_ids = attachments.map(a => a.file_public_id);

    const payload: SendEmailPayload = {
        id: entityId,
        attachments_ids,
        create_new_ticket: isNewEmail,
        cc_recipients: ccRecipients,
        entity_type: entityType,
        update_thread_owner: shouldUpdateThreadOwner,
        ...restArgs,
    };

    if (camelCaseKeys) {
        const camelCasePayload = snakeToCamelCaseKeys({ ...payload });

        return axios.post(url, camelCasePayload);
    }

    return axios.post(url, payload);
}

export async function mockWebHook(emailId: string, notificationsEmailId: string, event: string): Promise<void> {
    const url = `/backoffice/emails/${emailId}`;
    const body = {
        event,
        notifications_email_id: notificationsEmailId,
    };

    return axios.patch(url, body).then(() => {
        window.location.reload();
    });
}

export async function getEmails(options?: { ticketPublicIds?: string | string[] }): Promise<Email[]> {
    const response = await blenderGeneral.get<{ data: Email[] }>('/api/v1/emails', { params: options });

    return response.data.data;
}
