import { ContentSection, Image, LinkTab, PageWrapper, TabList, Tabs } from '@lemonade-hq/bluis';
import { EntitySummaryHeader, HeaderGeneralDetails } from '@lemonade-hq/bluiza';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CoveragesRegistry } from './RegistryCoverages/CoveragesRegistry';
import { SettingsRegistry } from './RegistrySettings/SettingsRegistry';
import { HeaderPortal } from 'components/Header';
import { HeaderImageSizes, ImageContainer } from 'components/LoCo/LoCoPagesSharedStyles';

export const ProductLineErrorMessage = 'Failed to load product lines';

export const CoveragesAndSettingsRegistry: React.FC = () => {
    return (
        <>
            <HeaderPortal>
                <EntitySummaryHeader>
                    <ImageContainer>
                        <Image
                            img={{
                                lightSrc: 'appraisal.svg',
                                darkSrc: 'appraisal-dark.svg',
                                ...HeaderImageSizes,
                            }}
                        />
                    </ImageContainer>
                    <HeaderGeneralDetails title="Global Coverages Registry" />
                </EntitySummaryHeader>
            </HeaderPortal>
            <PageWrapper>
                <ContentSection>
                    <Tabs>
                        <HeaderPortal>
                            <TabList pageTabs>
                                <LinkTab key="coverages" to="coverages">
                                    Coverages
                                </LinkTab>
                                <LinkTab key="settings" to="settings">
                                    Settings
                                </LinkTab>
                            </TabList>
                        </HeaderPortal>
                        <Routes>
                            <Route element={<Navigate replace to="coverages" />} path="/" />
                            <Route element={<CoveragesRegistry />} key="coverages" path="coverages" />
                            <Route element={<SettingsRegistry />} key="settings" path="settings" />
                        </Routes>
                    </Tabs>
                </ContentSection>
            </PageWrapper>
        </>
    );
};
