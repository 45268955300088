/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getEnvId, getProductTypeFromUrl } from '@lemonade-hq/bluiza';
import { useCurrentPrincipal } from '@lemonade-hq/boutique';
import { FlagsProvider, UnleashFlags } from '@lemonade-hq/flags';
import React, { useMemo } from 'react';

interface EnsureFlagsProps {
    readonly children: React.ReactNode;
}

export const EnsureFlags: React.FC<React.PropsWithChildren<EnsureFlagsProps>> = ({ children }) => {
    const { principal, operator } = useCurrentPrincipal();
    const url = window.location.pathname;

    const unleashFlags = useMemo(() => {
        const client = new UnleashFlags({
            appName: 'blender',
            clientKey: import.meta.env.VITE_UNLEASH_CLIENT_KEY ?? '',
            environment: import.meta.env.VITE_UNLEASH_ENVIRONMENT ?? '',
            refreshInterval: 3 * 60,
            metricsInterval: 3 * 60,
            url: import.meta.env.VITE_UNLEASH_URL ?? '',
        });

        client.setContext({
            userId: operator?.id.toString() ?? principal.subject,
            properties: {
                env_id: getEnvId() ?? '',
                stage: import.meta.env.VITE_ENV_NAME ?? '',
                operatorEmail: operator?.email ?? '',
                pageProductLine: getProductTypeFromUrl(url) ?? 'unknown',
            },
        });

        return client;
    }, [operator?.email, operator?.id, principal.subject, url]);

    return <FlagsProvider client={unleashFlags}>{children}</FlagsProvider>;
};
