import { useEffect, useRef } from 'react';
import type { MutableRefObject } from 'react';
import { ExpressionFreeTextEditor } from '../../ExpressionFreeTextEditor/ExpressionFreeTextEditor';
import type { InputFunction } from '../../ExpressionSimpleEditor/operators';
import type { RuleBuilderRefMethods } from '../ManageRuleDialogContext';
import type { SchemaResponse } from 'models/LoCo/Insurance/Schema';

interface SegmentRuleFreeTextEditorProps {
    readonly productSchema: SchemaResponse;
    readonly renderExpressionRef: MutableRefObject<RuleBuilderRefMethods | null>;
    readonly expression: string;
    readonly onValidation?: (valid: boolean) => void;
    readonly additionalFunctions?: Record<string, InputFunction>;
}

export const SegmentRuleFreeTextEditor: React.FC<SegmentRuleFreeTextEditorProps> = ({
    productSchema,
    renderExpressionRef,
    expression,
    onValidation,
    additionalFunctions,
}) => {
    const editorValueRef: MutableRefObject<string | null> = useRef<string | null>(null);

    useEffect(() => {
        renderExpressionRef.current = {
            get: () => {
                if (editorValueRef.current === null) {
                    throw new Error('Editor value is not set');
                }

                return editorValueRef.current;
            },
        };
    }, [renderExpressionRef]);

    return (
        <ExpressionFreeTextEditor
            additionalFunctions={additionalFunctions}
            editorValueRef={editorValueRef}
            expression={expression}
            onValidation={onValidation}
            productSchema={productSchema}
        />
    );
};
