import { AddButton, Select } from '@lemonade-hq/bluis';
import { useMemo } from 'react';
import styled from 'styled-components';
import { getAdditionalFunctions } from '../../../ExpressionSimpleEditor/getAdditionalFunctions';
import { InputWrapper } from '../../../InputWrapper';
import { useManageRuleDialogContext, useRegistryContext } from '../../ManageRuleDialogContext';
import { SelectOutcomeExpression } from './SelectOutcomeExpression';
import { useGetProductCode } from 'components/LoCo/common/hooks/useGetProduct';
import { CoverageRuleType, RoundTo, RuleOutcomeType } from 'models/LoCo/Insurance/CoverageRule';
import { useGetLatestProductSchemaAttributes } from 'queries/LoCo/Insurance/ProductQueries';

const ExpressionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StyledInputWrapper = styled(InputWrapper)`
    align-items: flex-start;
`;

export const SettingAdditionalOptions: React.FC = () => {
    const {
        values: { outcome, ruleType, lifecycleContexts },
        dispatch,
    } = useManageRuleDialogContext();

    const { coverages, settings } = useRegistryContext();

    const additionalFunctions = useMemo(
        () =>
            getAdditionalFunctions(
                coverages.map(c => c.name),
                settings.map(s => s.name),
                lifecycleContexts ?? []
            ),
        [coverages, settings, lifecycleContexts]
    );

    const productCode = useGetProductCode();
    const { data: productSchema, isError, isLoading } = useGetLatestProductSchemaAttributes(productCode, true);
    if (isLoading) {
        return 'Loading...';
    }

    if (isError || productSchema === undefined) {
        return 'Error';
    }

    if (outcome?.type === RuleOutcomeType.SettingExactValuesOutcome) {
        if (ruleType === CoverageRuleType.InitialValue)
            return (
                <InputWrapper label="Value to match">
                    <SelectOutcomeExpression
                        additionalFunctions={additionalFunctions}
                        allowFreeText
                        dispatch={dispatch}
                        outcome={outcome}
                        productSchema={productSchema}
                    />
                </InputWrapper>
            );
        else {
            return (
                <StyledInputWrapper label="Values">
                    <ExpressionsWrapper>
                        {outcome.values.length === 0 ? (
                            <SelectOutcomeExpression
                                additionalFunctions={additionalFunctions}
                                additionalOptions={{ rangeIndex: 0 }}
                                dispatch={dispatch}
                                outcome={outcome}
                                productSchema={productSchema}
                            />
                        ) : (
                            outcome.values.map((_, index) => (
                                <SelectOutcomeExpression
                                    additionalFunctions={additionalFunctions}
                                    additionalOptions={{ rangeIndex: index }}
                                    dispatch={dispatch}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    outcome={outcome}
                                    productSchema={productSchema}
                                />
                            ))
                        )}
                        <AddButton
                            onClick={() =>
                                dispatch({
                                    type: 'outcome',
                                    value: { ...outcome, values: [...outcome.values, ''] },
                                })
                            }
                        />
                    </ExpressionsWrapper>
                </StyledInputWrapper>
            );
        }
    }

    if (outcome?.type === RuleOutcomeType.SettingNearestToValueOutcome)
        return (
            <>
                <InputWrapper label="Direction">
                    <Select
                        onOptionSelected={option => {
                            dispatch({
                                type: 'outcome',
                                value: {
                                    ...outcome,
                                    roundTo: option.value,
                                },
                            });
                        }}
                        options={[
                            { id: 'below', value: RoundTo.Down, label: 'Nearest Below' },
                            { id: 'above', value: RoundTo.Up, label: 'Nearest Above' },
                            { id: 'nearest', value: RoundTo.Nearest, label: 'Nearest (Absolute)' },
                        ]}
                        value={outcome.roundTo ?? null}
                    />
                </InputWrapper>
                <InputWrapper label="Value to Match">
                    <SelectOutcomeExpression
                        additionalFunctions={additionalFunctions}
                        allowFreeText
                        dispatch={dispatch}
                        outcome={outcome}
                        productSchema={productSchema}
                    />
                </InputWrapper>
            </>
        );
    if (outcome?.type === RuleOutcomeType.SettingRangeValueOutcome)
        return (
            <>
                <InputWrapper label="Minimum Value">
                    <SelectOutcomeExpression
                        additionalFunctions={additionalFunctions}
                        additionalOptions={{ isMin: true }}
                        allowFreeText
                        dispatch={dispatch}
                        outcome={outcome}
                        productSchema={productSchema}
                    />
                </InputWrapper>
                <InputWrapper label="Maximum Value">
                    <SelectOutcomeExpression
                        additionalFunctions={additionalFunctions}
                        additionalOptions={{ isMax: true }}
                        allowFreeText
                        dispatch={dispatch}
                        outcome={outcome}
                        productSchema={productSchema}
                    />
                </InputWrapper>
            </>
        );
};
