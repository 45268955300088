import { Text } from '@lemonade-hq/blender-ui';
import { Alert, AlertMode } from '@lemonade-hq/bluis';
import type { ToolSchemaKey } from '@lemonade-hq/persisted-tools';
import uniq from 'lodash/uniq';
import type { FC } from 'react';
import { prettifyField } from '../tool.helpers';
import * as styles from './toolEditorAlert.css';
import { toSentence } from 'components/pet/stringsHelper';

function formatFailingFields(fields: string[]): string {
    return toSentence(uniq(fields.map(v => prettifyField(v.split('[')[0].split('.')[0] as ToolSchemaKey))));
}

// TODO: Alert should be replaced with the new Zest Banner component
export const InvalidToolAlert: FC<{ readonly invalidFields: string[] }> = ({ invalidFields }) => (
    <Alert
        className={styles.toolEditorAlert}
        mode={AlertMode.Error}
        title={
            <span>
                <Text fontWeight="bold">Please fix:&nbsp;</Text>
                <Text>{formatFailingFields(invalidFields)}</Text>
            </span>
        }
    />
);
