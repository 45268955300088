import { Flex, spacing } from '@lemonade-hq/blender-ui';
import {
    ErrorSection,
    SummaryInnerSectionItemKey as Key,
    LoadingSection,
    SummarySectionContent,
    SummarySectionTitle,
    SummaryInnerSectionItemValue as Value,
} from '@lemonade-hq/bluis';
import { LinkComp } from '@lemonade-hq/bluiza';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { ErrorBoundary } from '@sentry/react';
import { Suspense, useCallback, useState } from 'react';
import styled from 'styled-components';
import { getFormattedDate, getFormattedDateMaybe } from '../../common/helpers/dateHelpers';
import { EditionsListLinks, friendlyNameRenderer } from '../../products/ProductMissionControl/EditionsListLinks';

import { viewNoteWrapper } from './dialogs/ReleaseDetails.css';
import type { DialogData } from './dialogs/ReleaseDetailsDialogs';
import { DialogType, ReleaseDetailsDialogs } from './dialogs/ReleaseDetailsDialogs';
import { ReleaseEditionSets } from './ReleaseEditionSets';
import { EditIcon } from 'components/LoCo/common/components/EditIcon';
import { getVersionTypeDisplayName } from 'components/LoCo/common/display-texts/common';
import { getReleasesUrl } from 'components/LoCo/common/urlBuilders';
import { StyledClickable, StyledSection } from 'components/LoCo/LoCoPagesSharedStyles';
import { SchemasLinks } from 'components/LoCo/products/ProductMissionControl/SchemasLinks';
import type { Product } from 'models/LoCo/Insurance/Product';
import { ReleaseStatus } from 'models/LoCo/Insurance/Release';
import type { Release } from 'models/LoCo/Insurance/Release';

const StyledSummarySectionContent = styled(SummarySectionContent)`
    margin-bottom: ${spacing.s24};
`;

const StyledSectionWrapper = styled(Flex)`
    flex-direction: column;
`;

interface ReleaseDetailsProps {
    readonly release: Release;
    readonly product: Product;
}

const ReleaseDetailsContent: React.FC<ReleaseDetailsProps> = ({ release, product }) => {
    const [dialogData, setDialogData] = useState<DialogData | null>(null);

    const openEditNoteDialog = useCallback(() => {
        setDialogData({
            note: release.note,
            releasePublicId: release.publicId,
            type: DialogType.EditNote,
        });
    }, [release.note, release.publicId]);

    const editNoteEnabled = release.status === ReleaseStatus.Draft;

    return (
        <StyledSection>
            <SummarySectionTitle>General Details</SummarySectionTitle>
            <StyledSectionWrapper>
                <StyledSummarySectionContent>
                    <Key>Product</Key>
                    <Value>
                        <LinkComp url={getReleasesUrl(product.code)}>{product.name}</LinkComp>
                    </Value>

                    <Key>Editions</Key>
                    <Value>
                        {
                            <Flex flexDirection="column">
                                <EditionsListLinks
                                    editionLabelRenderer={friendlyNameRenderer}
                                    editions={release.editions}
                                    productCode={release.productCode}
                                />
                                <SchemasLinks
                                    productCode={release.productCode}
                                    schemaRevisions={{
                                        platform: release.platformSchemaRevision,
                                        product: release.productSchemaRevision,
                                    }}
                                />
                            </Flex>
                        }
                    </Value>
                </StyledSummarySectionContent>
                <StyledSummarySectionContent>
                    <Key>Rollout strategy</Key>
                    <Value>{getVersionTypeDisplayName(release.rolloutStrategy)}</Value>
                </StyledSummarySectionContent>
                <StyledSummarySectionContent>
                    <Key>Effective New Business</Key>
                    <Value>{getFormattedDateMaybe(release.newBusinessEffectiveAt)}</Value>

                    <Key>Effective Renewals</Key>
                    <Value>{getFormattedDateMaybe(release.renewalEffectiveAt)}</Value>
                </StyledSummarySectionContent>
                <StyledSummarySectionContent>
                    <Key>Created</Key>
                    <Value>{`${getFormattedDate(release.startedAt)} by ${release.startedByName}`}</Value>

                    {release.publishedAt && (
                        <>
                            <Key>Published</Key>
                            <Value>{`${getFormattedDate(release.publishedAt)} by ${release.publishedByName}`}</Value>
                        </>
                    )}

                    {release.cancelledAt && (
                        <>
                            <Key>Cancelled</Key>
                            <Value>{`${getFormattedDate(release.cancelledAt)} by ${release.cancelledByName}`}</Value>
                        </>
                    )}
                </StyledSummarySectionContent>
                <SummarySectionContent>
                    <Key>Release Note</Key>
                    <Value>
                        {isDefined(release.note) && release.note.length > 0 ? (
                            <Flex className={viewNoteWrapper}>
                                {release.note}
                                {editNoteEnabled && <EditIcon onClick={openEditNoteDialog} />}
                            </Flex>
                        ) : editNoteEnabled ? (
                            <StyledClickable onClick={openEditNoteDialog}>+ Add a release note</StyledClickable>
                        ) : null}
                    </Value>
                </SummarySectionContent>
            </StyledSectionWrapper>
            {dialogData && <ReleaseDetailsDialogs dialogData={dialogData} onClose={() => setDialogData(null)} />}
        </StyledSection>
    );
};

export const ReleaseDetails: React.FC<ReleaseDetailsProps> = ({ release, product }) => {
    return (
        <ErrorBoundary fallback={<ErrorSection noBorders />}>
            <Suspense fallback={<LoadingSection noBorders />}>
                <ReleaseDetailsContent product={product} release={release} />
                <StyledSectionWrapper>
                    <ReleaseEditionSets productCode={release.productCode} release={release} />
                </StyledSectionWrapper>
            </Suspense>
        </ErrorBoundary>
    );
};
