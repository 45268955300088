import type { SettingType } from './SettingType';

export interface CoverageTemplateResponse {
    readonly code: string;
    readonly name: string;
    readonly description: string;
}

export enum SettingLimitType {
    List = 'list',
    Range = 'range',
}

export interface RangeTypeLimit {
    readonly type: SettingLimitType.Range;
    readonly min: number;
    readonly max: number;
    readonly step: number;
}

export interface ListTypeLimit {
    readonly type: SettingLimitType.List;
    readonly values: number[];
    readonly includeUnlimited: boolean;
}

export type RatingEditionSettingValues = ListTypeLimit | RangeTypeLimit;

export type SettingResponse = {
    readonly code: string;
    readonly name: string;
    readonly type?: SettingType;
    readonly values: RatingEditionSettingValues;
    readonly error?: string;
};

export interface CoverageResponse {
    readonly code: string;
    readonly name: string;
    readonly error?: string;
}

export interface RatingMetadata {
    readonly coverages: CoverageResponse[];
    readonly settings: SettingResponse[];
}
