import { LegacyHomePolicy } from 'models/HomePolicy';
import { PetPolicy } from 'models/PetPolicy';
import { PetPolicyView } from 'components/PetPolicy/types';

export enum PolicyActions {
    SET_POLICY = 'SET_POLICY',
    SET_POLICY_NOT_FOUND = 'SET_POLICY_NOT_FOUND',
    SET_POLICY_AUTHORIZATION_ERROR = 'SET_POLICY_AUTHORIZATION_ERROR',
    SET_POLICY_LOAD_FAILED = 'SET_POLICY_LOAD_FAILED',
}

interface SetPolicyAction {
    type: PolicyActions.SET_POLICY;
    data: LegacyHomePolicy | PetPolicy | PetPolicyView;
}

interface SetPolicyAuthorizationErrorAction {
    type: PolicyActions.SET_POLICY_AUTHORIZATION_ERROR;
    data: Error | string;
}

interface SetPolicyFailedToLoadAction {
    type: PolicyActions.SET_POLICY_LOAD_FAILED;
}

interface SetPolicyNotFoundAction {
    type: PolicyActions.SET_POLICY_NOT_FOUND;
}

export type PolicyActionTypes =
    | SetPolicyAction
    | SetPolicyAuthorizationErrorAction
    | SetPolicyFailedToLoadAction
    | SetPolicyNotFoundAction;
