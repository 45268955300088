import React from 'react';
import { Helmet } from 'react-helmet';

const GeneralPageTitle: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
    <>
        <Helmet>
            <title>Blender By Lemonade</title>
        </Helmet>
        {children}
    </>
);

export default GeneralPageTitle;
