import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import type { CoupledSettingsEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoupledSetting, CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';

interface SettingCoupledProps {
    readonly change: CoupledSettingsEntry;
    readonly coveragesEdition: CoveragesEdition;
}

export const SettingCoupled: FC<SettingCoupledProps> = ({ change, coveragesEdition }) => {
    const [settingATemplateCode, settingBTemplateCode] = change.metadata.coupledSettingCodes;

    const settingA = coveragesEdition.settings.find(({ templateCode }) => templateCode === settingATemplateCode);

    if (settingA === undefined) {
        throw new Error(`Setting ${settingATemplateCode} not found in coverage edition ${coveragesEdition.code}`);
    }

    const coupling = coveragesEdition.settings.find(
        ({ templateCode }) => templateCode === settingATemplateCode
    )?.coupledSetting;

    if (coupling === undefined) {
        throw new Error(`Coupling ${settingA.name}  not found in coverage edition ${coveragesEdition.code}`);
    }

    if (coupling.coupledSettingTemplateCode !== settingBTemplateCode) {
        throw new Error(
            `Coupling ${settingATemplateCode} and ${settingBTemplateCode} does not match in coverage edition ${coveragesEdition.code}.
            Found ${coupling.coupledSettingTemplateCode} instead of ${settingBTemplateCode}`
        );
    }

    return (
        <ChangeAccordion
            generalTabData={[getCouplingValuePairs({ settingName: settingA.name, coupling })]}
            originalRules={null}
            ruleType={'decline'}
            title={`${settingA.name} and ${coupling.coupledSettingName} coupled`}
            updatedRules={null}
        />
    );
};

export function getCouplingValuePairs({
    coupling: { coupledSettingName, values },
    settingName,
}: {
    readonly settingName: string;
    readonly coupling: CoupledSetting;
}): LabelValueItem {
    return {
        label: `Value Pairs ${settingName}/${coupledSettingName}`,
        value: values
            .map(({ currentSettingValue, coupledSettingValue }) => `${currentSettingValue}/${coupledSettingValue}`)
            .join(', '),
    };
}
