import { Flex, flexDoNotShrink, Icon, spacing, Spinner, Text, Tooltip } from '@lemonade-hq/blender-ui';
import { formatOccurredAt } from '@lemonade-hq/bluiza';
import type { ToolsRevision } from '@lemonade-hq/persisted-tools';
import { ToolsRevisionStatus } from '@lemonade-hq/persisted-tools';
import type { FC } from 'react';

export const ToolRevisionPublishNotesTooltip: FC<{ readonly toolsRevision: ToolsRevision }> = ({ toolsRevision }) => {
    return toolsRevision.publishNotes != null ? (
        <Tooltip content={toolsRevision.publishNotes}>
            <Icon className={flexDoNotShrink} color="textTertiary" name="info-circle-solid" />
        </Tooltip>
    ) : null;
};

export const PlaygroundToolEditorHeaderNotification: FC<{
    readonly toolsRevision: ToolsRevision | undefined;
    readonly isLoading: boolean | undefined;
}> = ({ isLoading, toolsRevision }) => {
    if (toolsRevision?.status === ToolsRevisionStatus.Published) {
        return (
            <Flex alignItems="center" gap={spacing.s06}>
                <Text color="secondary">
                    {`Published ${formatOccurredAt(toolsRevision.publishedAt).toLowerCase()}`}
                </Text>
                <ToolRevisionPublishNotesTooltip toolsRevision={toolsRevision} />
            </Flex>
        );
    }

    if (toolsRevision?.status === ToolsRevisionStatus.Archived) {
        return <Text color="secondary">Archived</Text>;
    }

    if (toolsRevision?.status === ToolsRevisionStatus.Sunsetted) {
        return (
            <Flex alignItems="center" gap={spacing.s06}>
                <Text color="secondary">Sunsetted {formatOccurredAt(toolsRevision.sunsettedAt).toLowerCase()}</Text>
                <ToolRevisionPublishNotesTooltip toolsRevision={toolsRevision} />
            </Flex>
        );
    }

    if (isLoading) {
        return (
            <>
                <Spinner color="grey" size="sm" />
                <Text color="secondary" type="text-sm">
                    Saving...
                </Text>
            </>
        );
    }
};
