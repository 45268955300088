import {
    Fetchers,
    InferDataSchemaFromEntityType,
    InferSerializersFromEntityType,
    DataAggregatorResponse,
    fetchers,
    Fetcher,
    EntityType,
} from './config';

type Staller<
    TEntityType extends EntityType,
    TSerializer extends InferSerializersFromEntityType<TEntityType> = InferSerializersFromEntityType<TEntityType>
> = DataAggregatorResponse<InferDataSchemaFromEntityType<TEntityType>, TSerializer>;

type FetchingState<
    TEntityType extends EntityType = EntityType,
    TSerializer extends InferSerializersFromEntityType<TEntityType> = InferSerializersFromEntityType<TEntityType>
> = {
    staller: Promise<Staller<TEntityType, TSerializer>> | null;
    serializers: Set<TSerializer>;
};

class DataAggClient {
    private cache: Map<string, FetchingState> = new Map();

    public constructor(private fetchers: Fetchers, private stallTime = 10) {}

    public async fetch<
        TEntityType extends EntityType,
        TSerializer extends InferSerializersFromEntityType<TEntityType>
    >({
        entityType,
        entityId,
        serializer,
    }: {
        entityType: TEntityType;
        entityId: string;
        serializer: TSerializer;
    }): Promise<Pick<InferDataSchemaFromEntityType<TEntityType>, TSerializer>> {
        // initialize a state to hold the current staller and serializers
        let state: FetchingState<TEntityType, TSerializer>;
        const cache = this.cache as Map<string, FetchingState<TEntityType, TSerializer>>;
        const cacheKey = `${entityType}_${entityId}`;

        // get the current state from the cache or initialize a new one
        if (this.cache.has(cacheKey)) {
            state = cache.get(cacheKey) as FetchingState<TEntityType, TSerializer>;
        } else {
            state = { staller: null, serializers: new Set<TSerializer>() } as FetchingState<TEntityType, TSerializer>;
            cache.set(cacheKey, state);
        }

        // if the current state doesn't have a staller, initialize one
        // this will wait the `stallTime` before calling the server
        if (state.staller == null) {
            state.staller = this.stall(entityType, entityId, state);
        }

        // add the serializer to the current state
        // when the staller finishes, it will call the server with all the serializers added
        state.serializers.add(serializer);

        const response = await state.staller;

        // return only the requested serializer
        return response.data;
    }

    private async stall<
        TEntityType extends EntityType,
        TSerializer extends InferSerializersFromEntityType<TEntityType>
    >(
        entityType: TEntityType,
        entityId: string,
        entityData: FetchingState<TEntityType, TSerializer>
    ): Promise<Staller<TEntityType>> {
        // wait the stall time before calling the server
        await sleep(this.stallTime);

        // get the fetcher for the current entity type
        const fetcher = this.fetchers[entityType] as Fetcher<InferDataSchemaFromEntityType<TEntityType>>;

        // call the server with all the serializers added to the current state
        const dataPromise = fetcher(entityId, [...entityData.serializers]);

        // reset the current state
        entityData.serializers = new Set<TSerializer>();
        entityData.staller = null;

        return dataPromise;
    }
}

async function sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const dataAggClient = new DataAggClient(fetchers);
