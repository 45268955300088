import { assignInlineVars } from '@vanilla-extract/dynamic';
import { clsx } from 'clsx';
import type { HTMLAttributes, Ref } from 'react';
import { forwardRef } from 'react';
import type { RequiredDeep } from 'type-fest';
import { colors } from '../../theme/colors.css';
import type { ThemeColor } from '../../theme/ThemeProvider';
import * as styles from './Icon.css';
import type { IconName } from './types';

export type IconSize = RequiredDeep<Parameters<(typeof styles)['root']>[0]>['size'];

export interface IconProps extends Omit<HTMLAttributes<SVGSVGElement>, 'children'> {
  readonly color?: ThemeColor;
  readonly name: IconName;
  readonly size?: IconSize;
  readonly rotation?: '180deg' | 'ccw90deg' | 'cw90deg';
}

export const Icon = forwardRef(
  (
    { className, color, name, size = 'md', rotation, ...restProps }: IconProps,
    ref: Ref<SVGSVGElement>,
  ): JSX.Element => {
    const isAccessible = restProps['aria-label'] != null;
    const ariaHidden = isAccessible ? undefined : true;
    const role = isAccessible ? 'img' : 'presentation';
    const colorToken = color != null ? colors[color] : 'currentColor';

    return (
      <svg
        {...restProps}
        aria-hidden={ariaHidden}
        className={clsx(styles.root({ size }), className)}
        ref={ref}
        role={role}
        style={assignInlineVars({ [styles.iconColorVar]: colorToken })}
        {...(rotation ? { [`${styles.ROTATED_STATE_DATA_ATTRIBUTE}-${rotation}`]: true } : {})}
      >
        <use aria-hidden="true" href={`#Icon__${name}`} />
      </svg>
    );
  },
);
