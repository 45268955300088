import axios from 'axios';
import { OrderByDirection } from '@lemonade-hq/bluis';
import { stringify } from 'qs';
import { stringifyUrl } from 'query-string';
import { getUrlResolver, ServiceNames } from '@lemonade-hq/bluiza';
import { TimelineEvent } from 'models/UserTimeline';
import { Country, LegacyUserData, UserData, UserListItem, UserNotification, UserV2 } from 'models/User';
import { Alert } from 'models/Alert';
import { UserZendeskData } from 'models/ZendeskData';

const fallbackMap: Set<string> = new Set();

const blenderGeneralUrlResolver = getUrlResolver({ service: ServiceNames.BlenderGeneral, fallbackMap });
const carBlenderUrlResolver = getUrlResolver({ service: ServiceNames.CarBlender, fallbackMap });
const homeBlenderUrlResolver = getUrlResolver({ service: ServiceNames.HomeBlender, fallbackMap });
const petBlenderUrlResolver = getUrlResolver({ service: ServiceNames.PetBlender, fallbackMap });
const lifeBlenderUrlResolver = getUrlResolver({ service: ServiceNames.LifeBlender, fallbackMap });

export enum SortColumn {
    RegisteredAt = 'registered_at',
}

interface ZendeskResponse {
    user_details: { id: number; email: string } & { [key: string]: unknown };
}

export interface UsersSearchParamsResponse {
    countries: Country[];
}

export interface UsersSearchResponse {
    users: UserListItem[];
    offset: number;
    pageSize: number;
    total: number;
}
export interface UserSearchSort {
    readonly column: SortColumn;
    readonly direction: OrderByDirection;
}

export interface SearchFilter {
    readonly country?: string;
    readonly lastName?: string;
}
export interface UsersSearchParams {
    page: number;
    limit: number;
    sort?: UserSearchSort;
    filter?: SearchFilter;
}

export interface PolicyData {
    id: number;
    public_id: string;
    payment_plan: string;
}

export interface PoliciesForManualOverrideResponse {
    policies: PolicyData[];
}

export interface UpdateUserParams {
    id: number;
    first_name?: string;
    last_name?: string;
    email?: string;
    date_of_birth?: string;
    phone_number?: string;
}

export type UserDataIncludeKeysResponse<K extends keyof UserData> = {
    data: Pick<UserData, K>;
    error: { [key in K]: string | undefined } | undefined;
};

export async function getLegacyUserData(id: string | number): Promise<LegacyUserData> {
    const url = `/backoffice/users/${id}/data`;

    return await axios.get(url).then(response => response.data);
}

export async function getLegacyNotifications(publicId: string): Promise<UserNotification> {
    const url = `/backoffice/users/${publicId}/notifications`;

    return await axios.get(url).then(response => response.data);
}

export async function getUserTimeline(id: number | string): Promise<TimelineEvent[]> {
    const url = `/backoffice/users/${id}/timeline`;

    return await axios.get<{ timeline: TimelineEvent[] }>(url).then(response => response.data.timeline);
}

export async function getUserOpportunityEligibility(id: string | number): Promise<boolean> {
    const url = blenderGeneralUrlResolver(`/api/v1/ltv/opportunities/user/${id}/eligible`);

    return await axios.get<{ data: boolean }>(url).then(response => response.data.data);
}

export async function fetchUserDataIncludeKeys<K extends keyof UserData>(
    publicUserId: string,
    keys: K[],
    userEmail?: string
): Promise<UserDataIncludeKeysResponse<K>> {
    const url = stringifyUrl(
        {
            url: blenderGeneralUrlResolver(`/api/v1/users/${publicUserId}/data`),
            query: {
                include: keys,
                email: userEmail,
            },
        },
        { arrayFormat: 'comma' }
    );

    return await axios.get(url).then(response => response.data.data);
}

export async function fetchUserHomeDataIncludeKeys<K extends keyof UserData>(
    publicUserId: string,
    keys: K[]
): Promise<UserDataIncludeKeysResponse<K>> {
    const url = stringifyUrl(
        {
            url: homeBlenderUrlResolver(`/api/v1/users/${publicUserId}`),
            query: {
                include: keys,
            },
        },
        { arrayFormat: 'comma' }
    );

    return await axios.get(url).then(response => response.data.data);
}

export async function fetchUserHomeData<K extends keyof UserData>(
    publicUserId: string,
    keys: K[]
): Promise<Pick<UserData, K>> {
    return (await fetchUserHomeDataIncludeKeys(publicUserId, keys)).data;
}

export async function fetchUserCarDataIncludeKeys<K extends keyof UserData>(
    publicUserId: string,
    keys: K[]
): Promise<UserDataIncludeKeysResponse<K>> {
    const url = stringifyUrl(
        {
            url: carBlenderUrlResolver(`/api/v1/users/${publicUserId}`),
            query: {
                include: keys,
            },
        },
        { arrayFormat: 'comma' }
    );

    return await axios.get(url).then(response => response.data);
}

export async function fetchUserCarData<K extends keyof UserData>(
    publicUserId: string,
    keys: K[]
): Promise<Pick<UserData, K>> {
    return (await fetchUserCarDataIncludeKeys(publicUserId, keys)).data;
}

export async function fetchUserPetDataIncludeKeys<K extends keyof UserData>(
    publicUserId: string,
    keys: K[]
): Promise<UserDataIncludeKeysResponse<K>> {
    const url = stringifyUrl(
        {
            url: petBlenderUrlResolver(`/api/v1/users/${publicUserId}/data`),
            query: {
                include: keys,
            },
        },
        { arrayFormat: 'comma' }
    );

    return await axios.get(url).then(response => response.data);
}

export async function fetchUserPetData<K extends keyof UserData>(
    publicUserId: string,
    keys: K[]
): Promise<Pick<UserData, K>> {
    return (await fetchUserPetDataIncludeKeys(publicUserId, keys)).data;
}

export async function fetchUserData<K extends keyof UserData>(
    publicUserId: string,
    keys: K[],
    userEmail?: string
): Promise<Pick<UserData, K>> {
    return (await fetchUserDataIncludeKeys(publicUserId, keys, userEmail)).data;
}

export async function fetchUserLifeDataIncludeKeys<K extends keyof UserData>(
    publicUserId: string,
    keys: K[]
): Promise<UserDataIncludeKeysResponse<K>> {
    const url = stringifyUrl(
        {
            url: lifeBlenderUrlResolver(`/api/v1/users/${publicUserId}/data`),
            query: {
                include: keys,
            },
        },
        { arrayFormat: 'comma' }
    );

    return await axios.get(url).then(response => response.data);
}

export async function fetchUserLifeData<K extends keyof UserData>(
    publicUserId: string,
    keys: K[]
): Promise<Pick<UserData, K>> {
    return (await fetchUserLifeDataIncludeKeys(publicUserId, keys)).data;
}

export async function getZendeskData(email: string, userPublicId?: string): Promise<ZendeskResponse> {
    let url = `/backoffice/zendesk/data?email=${encodeURIComponent(email)}`;

    if (userPublicId != null) {
        url += `&userPublicId=${userPublicId}`;
    }

    return await axios.get(url).then(response => response.data.data);
}

export async function getPoliciesForManualOverride(id: number): Promise<PoliciesForManualOverrideResponse> {
    const url = `/backoffice/users/${id}/credit_reports/policies_for_manual_override`;

    return await axios.get(url).then(response => response.data);
}

export async function runCreditScoreManualOverride(id: number): Promise<void> {
    const url = `/backoffice/users/${id}/credit_reports/manual_override`;

    return await axios.post(url).then(response => response.data);
}

export async function updateUserData({
    id,
    first_name,
    last_name,
    email,
    date_of_birth,
    phone_number,
}: UpdateUserParams): Promise<void> {
    const url = blenderGeneralUrlResolver(`/api/v1/users/${id}`);

    const body = {
        ...(first_name && { firstName: first_name }),
        ...(last_name && { lastName: last_name }),
        ...(email && { email }),
        ...(date_of_birth && { dateOfBirth: date_of_birth }),
        ...(phone_number && { phoneNumber: phone_number }),
    };

    return await axios.patch(url, body).then(response => response.data);
}

export async function blockClaim(userId: number) {
    const url = `/backoffice/users/${userId}/update_claims_blocked`;
    const data = {
        user: {
            claims_blocked: true,
        },
    };

    return await axios.put(url, data).then(response => response.data);
}

export async function unBlockClaim(userId: number) {
    const url = `/backoffice/users/${userId}/update_claims_blocked`;
    const data = {
        user: {
            claims_blocked: false,
        },
    };

    return await axios.put(url, data).then(response => response.data);
}

export async function simulateCredit({
    userId,
    tier,
    creditDate,
}: {
    userId: number;
    tier: number;
    creditDate: string | null;
}) {
    const url = `/backoffice/users/${userId}/simulate_credit_score`;
    const data = { tier, report_date: creditDate };

    return await axios.post(url, data).then(response => response.data);
}

export async function simulatePhoneVerificationEmail(userId: string) {
    const url = `/backoffice/users/${userId}/send_test_phone_verification_email`;

    return await axios.post(url).then(response => response.data);
}

export async function simulateSecondPhoneVerificationEmail(userId: string) {
    const url = `/backoffice/users/${userId}/send_test_phone_verification_email_second`;

    return await axios.post(url).then(response => response.data);
}

export async function simulateEQNotification(userId: number) {
    const url = `/backoffice/users/${userId}/send_test_earthquake_notification`;
    const data = { notification_number: 2 };

    return await axios.post(url, data).then(response => response.data);
}

export async function unSubscribeFromCustomerIO(userId: number) {
    const url = `/backoffice/users/${userId}/unsubscribe_from_customer_io`;

    return await axios.post(url).then(response => response.data);
}

export async function blockUser({ userId, reason, note }: { userId: number; reason: string; note: string }) {
    const url = `/backoffice/users/${userId}/block`;
    const data = { reason, note };

    return await axios.post(url, data).then(response => response.data);
}

export async function unblockUser({ userId, note }: { userId: number; note: string }) {
    const url = `/backoffice/users/${userId}/unblock`;
    const data = { note };

    return await axios.post(url, data).then(response => response.data);
}

export async function revokeAccess({ userId, note, reason }: { userId: number; note: string; reason: string }) {
    const url = `/backoffice/users/${userId}/revoke`;
    const data = { comment: `Reason: ${reason}\n${note}` };

    return await axios.post(url, data).then(response => response.data);
}

export async function unRevokeAccess({ userId, note }: { userId: number; note: string }) {
    const url = `/backoffice/users/${userId}/unrevoke`;
    const data = { comment: note };

    return await axios.post(url, data).then(response => response.data);
}

export async function getUserAlerts(userPublicId: string): Promise<Alert[]> {
    const url = blenderGeneralUrlResolver(`/api/v1/users/${userPublicId}/alerts`);

    return await axios.get(url).then(response => response.data.data);
}

export async function getUserZendeskData(userEmail: string): Promise<UserZendeskData> {
    const zendeskProperties = ['user'];

    const url = stringifyUrl(
        {
            url: blenderGeneralUrlResolver('/api/v1/users/zendesk_data'),
            query: {
                email: userEmail,
                include: zendeskProperties,
            },
        },
        { arrayFormat: 'comma' }
    );

    return await axios.get(url).then(response => response.data.data.data);
}

export async function fetchSearchParams(): Promise<UsersSearchParamsResponse> {
    const url = blenderGeneralUrlResolver('/api/v1/users/query_params');

    return await axios.get<{ data: UsersSearchParamsResponse }>(url).then(response => response.data.data);
}

export async function searchUsers(searchParams: UsersSearchParams): Promise<UsersSearchResponse> {
    const queryString = stringify(searchParams, { skipNulls: true });
    const url = blenderGeneralUrlResolver(`/api/v1/users?${queryString}`);

    return await axios.get<{ data: UsersSearchResponse }>(url).then(response => response.data.data);
}

export async function listUsers(searchParams: {
    phoneNumbers?: string[];
    emails?: string[];
    userPublicId?: string[];
}): Promise<UserV2> {
    const queryString = stringify(searchParams, { skipNulls: true });

    const url = blenderGeneralUrlResolver(`/api/v1/users/list?${queryString}`);

    return await axios.get<{ data: UserV2 }>(url).then(response => response.data.data);
}
