import { Flex } from '@lemonade-hq/cdk';

interface SimulationViolationsProps {
    readonly violations: string[];
}

export const SimulationViolations: React.FC<SimulationViolationsProps> = ({ violations }) => {
    return (
        <Flex style={{ flexDirection: 'column', gap: '10px' }}>
            <span>
                <b>Editions are incompatible.</b> To publish an Edition Set with these editions, please resolve the
                following violations:
            </span>

            <ul>
                {violations.map(violation => (
                    <li key={violation}>{violation}</li>
                ))}
            </ul>
        </Flex>
    );
};
