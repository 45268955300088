/* eslint-disable react/no-array-index-key */
import { Accordion, Card, Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import type { ToolSchemaKey } from '@lemonade-hq/persisted-tools';
import type { FC } from 'react';
import { ToolEditorFormSection } from '../toolEditor/components/ToolEditorFormSection';
import * as shimmeringStyles from './shimmering.css';
import { prettifyField } from './tool.helpers';

// The goal of this component is to create a shared interface for displaying a tool in the playground.
// Whether it's displayed as Form, or just static representation of the tool,
// this component ensures that every field of the tool is represented in a consistent way, in the same order.
type PlaygroundToolStructuredContainerProps = {
    readonly [K in Exclude<ToolSchemaKey, 'name'> as `render${Capitalize<K>}`]: React.ReactNode;
} & {
    readonly renderBadges: {
        readonly type?: React.ReactNode;
        readonly topic?: React.ReactNode;
        readonly userGroups?: React.ReactNode;
    };
    readonly renderTopBar: React.ReactNode;
};

export const PlaygroundToolStructuredContainer: FC<PlaygroundToolStructuredContainerProps> = ({
    renderBadges,
    renderDescription,
    renderInstructions,
    renderMacros,
    renderMode,
    renderNotes,
    renderParams,
    renderProducts,
    renderQuestions,
    renderRegion,
    renderSubClassifiers,
    renderSubtopic,
    renderTicketsIdsExamples,
    renderTopBar,
    renderTopic,
    renderType,
    renderUserGroups,
}) => {
    return (
        <Flex as="main" flexDirection="column" flexGrow="1" gap={spacing.s16}>
            {renderTopBar}

            {/* Mode and Info */}
            <Flex flexDirection="column" gap={spacing.s02}>
                <Flex alignItems="flex-start" gap={spacing.s04}>
                    {renderMode}
                    <Text color="tertiary" paddingInline={spacing.s02} type="text-sm">
                        |
                    </Text>
                    <Flex flexWrap="wrap" gap={spacing.s04}>
                        {renderBadges.type}
                        {renderBadges.topic}
                        {renderBadges.userGroups}
                    </Flex>
                </Flex>
            </Flex>

            {/* Description */}
            <Accordion isOpenByDefault title={prettifyField('description')}>
                <Card borderRadius="sm" padding="1.2rem" variant="primary">
                    {renderDescription}
                </Card>
            </Accordion>

            {/* Lists */}
            {renderQuestions}
            {renderSubClassifiers}
            {renderInstructions}

            {/* Configurations */}
            <ToolEditorFormSection className={shimmeringStyles.hiddenWhileShimmering} title="Configurations">
                {renderType}
                {renderRegion}
                {renderUserGroups}
                {renderProducts}
                {renderParams}
                {renderMacros}
                {renderTopic}
                {renderSubtopic}
            </ToolEditorFormSection>

            {/* Misc */}
            <ToolEditorFormSection className={shimmeringStyles.hiddenWhileShimmering} title="References">
                {renderTicketsIdsExamples}
                {renderNotes}
            </ToolEditorFormSection>
        </Flex>
    );
};
