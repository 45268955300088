import type { DialogAction, DialogProps, Step } from '@lemonade-hq/bluis';
import { AlertMode, Dialog, DialogContent, GENERAL_ERROR_MSG, MiniFlow } from '@lemonade-hq/bluis';
import { useAnalytics, useCurrentPrincipal } from '@lemonade-hq/boutique';
import type { Maybe } from '@lemonade-hq/ts-helpers';
import React, { useMemo, useState } from 'react';

import styled from 'styled-components';
import { SetAgentPassword, ShowAgentPassword } from './components/AgentPassword';
import { CheckConnectionStep } from './components/CheckConnectionStep';
import { ConnectStep } from './components/ConnectStep';
import { SelectRaterStep } from './components/SelectRaterStep';
import { useHasAgentPassword, useSetAgentPassword } from './queries/useHasAgentPassword';
import type { Rater } from './queries/useRaters';
import { checkConnection, useRatersPassword } from './queries/useRaters';

const StyledDialog = styled(Dialog)`
    ${DialogContent} {
        padding: 0;
    }
`;

enum StepId {
    SelectRater = 0,
    Connect = 1,
    Success = 2,
}

interface ConnectToRaterDialogProps {
    readonly onClose: () => void;
}

enum AuthenticationType {
    Old = 'old',
    New = 'new',
}

const DIALOG_AUTHENTICATION_TYPE = AuthenticationType.Old as AuthenticationType;

const ConnectToRaterDialogOld: React.FC<React.PropsWithChildren<ConnectToRaterDialogProps>> = ({ onClose }) => {
    const { data: hasAgentPassword, isPending: isLoadingPassword } = useHasAgentPassword();
    const [editPassword, setEditPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const { mutateAsync, isPending: isSettingPassword } = useSetAgentPassword();
    const { principal } = useCurrentPrincipal();
    const [error, setError] = useState<JSX.Element | string>('');
    const dialogActions = useMemo(
        () =>
            [
                {
                    type: 'other',
                    text: 'Close',
                    onClick() {
                        onClose();
                        setError('');
                    },
                },
                !hasAgentPassword || editPassword
                    ? {
                          type: 'submit',
                          text: 'Save Password',
                          disabled: !newPassword || error,
                          onClick() {
                              setError('');
                              mutateAsync(newPassword)
                                  .then(() => onClose())
                                  .catch(() => setError(GENERAL_ERROR_MSG));
                          },
                      }
                    : null,
            ].filter(Boolean) as DialogAction[],
        [onClose, mutateAsync, newPassword, hasAgentPassword, editPassword, error]
    );

    if (isLoadingPassword) return null;

    return (
        <StyledDialog
            actions={dialogActions}
            error={error}
            loading={isSettingPassword}
            onClose={onClose}
            size="large"
            title="Connect a rater"
        >
            {editPassword || !hasAgentPassword ? (
                <SetAgentPassword onError={setError} onSuccess={setNewPassword} />
            ) : (
                <ShowAgentPassword onReset={() => setEditPassword(true)} username={principal.email} />
            )}
        </StyledDialog>
    );
};

const ConnectToRaterDialogNew: React.FC<React.PropsWithChildren<ConnectToRaterDialogProps>> = ({ onClose }) => {
    const { principal } = useCurrentPrincipal();
    const { mutateAsync, isPending: isLoading, data: passwordData } = useRatersPassword();
    const [activeStep, setActiveStep] = useState<StepId>(StepId.SelectRater);
    const [error, setError] = useState<JSX.Element | string>('');
    const [rater, setRater] = useState<Maybe<Rater>>(null);
    const { trackEvent } = useAnalytics();
    const credentials = useMemo(() => ({ username: principal.email, password: passwordData?.password ?? '' }), [
        passwordData?.password,
        principal.email,
    ]);

    const steps: Step[] = [
        {
            id: '0',
            title: 'Select Rater',
            body: <SelectRaterStep rater={rater?.publicId} setRater={setRater} />,
        },
        {
            id: '1',
            title: 'Connect',
            body: <ConnectStep credentials={credentials} rater={rater} />,
        },
        { id: '2', title: 'Check connection', body: <CheckConnectionStep /> },
    ];

    const stepActions: Record<StepId, DialogAction[]> = useMemo(
        () => ({
            [StepId.SelectRater]: [
                {
                    type: 'other',
                    text: 'Cancel',
                    onClick() {
                        onClose();
                    },
                },
                {
                    type: 'submit',
                    text: 'Next',
                    onClick() {
                        setError('');
                        if (!rater) return;

                        trackEvent('rater_selected', {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            rater_id: rater.publicId,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            agent_id: principal.subject,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            partner_id: principal.partnerPublicId ?? '',
                            status: rater.lastConnected ? 'connected' : 'disconnected',
                        });
                        mutateAsync(rater.publicId)
                            .then(() => {
                                setActiveStep(StepId.Connect);
                            })
                            .catch(() => {
                                setError(GENERAL_ERROR_MSG);
                            });
                    },
                    disabled: !rater,
                },
            ],

            [StepId.Connect]: [
                {
                    type: 'other',
                    text: 'Back',
                    onClick() {
                        trackEvent('connect_screen_back.clicked', {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            agent_id: principal.subject,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            partner_id: principal.partnerPublicId ?? '',
                        });
                        setError('');
                        setActiveStep(step => step - 1);
                    },
                },
                {
                    type: 'submit',
                    text: 'Check Connection',
                    onClick() {
                        setError('');
                        if (!rater) {
                            setError(GENERAL_ERROR_MSG);
                            return;
                        }

                        trackEvent('connect_screen_next.clicked', {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            agent_id: principal.subject,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            partner_id: principal.partnerPublicId ?? '',
                        });
                        checkConnection(rater.publicId)
                            .then(isConnected => {
                                if (isConnected) {
                                    trackEvent('rater_connected.success', {
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        agent_id: principal.subject,
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        partner_id: principal.partnerPublicId ?? '',
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        rater_id: rater.publicId,
                                    });
                                    setActiveStep(StepId.Success);
                                } else {
                                    trackEvent('rater_connected.failed', {
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        agent_id: principal.subject,
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        partner_id: principal.partnerPublicId ?? '',
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        rater_id: rater.publicId,
                                    });
                                    setError(
                                        <>
                                            <strong>Looks like {rater.name} hasn&apos;t been connected yet.</strong>{' '}
                                            Please make sure you enter and save your credentials into {rater.name}{' '}
                                            before you close this window.,
                                        </>
                                    );
                                }
                            })
                            .catch(() => {
                                trackEvent('rater_connected.failed', {
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    agent_id: principal.subject,
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    partner_id: principal.partnerPublicId ?? '',
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    rater_id: rater.publicId,
                                });
                                setError(GENERAL_ERROR_MSG);
                            });
                    },
                },
            ],

            [StepId.Success]: [
                {
                    type: 'other',
                    text: 'Yay!',
                    onClick() {
                        onClose();
                    },
                },
            ],
        }),
        [rater, mutateAsync, onClose, trackEvent, principal.subject, principal.partnerPublicId]
    );

    const dialogActions = useMemo(() => stepActions[activeStep], [stepActions, activeStep]);

    const dialogProps: DialogProps = useMemo(
        () => ({
            title: 'Connect a rater',
            size: 'large',
            actions: dialogActions,
            loading: isLoading,
            error,
            notice: rater?.lastConnected
                ? [
                      {
                          title: (
                              <>
                                  <strong>{rater.name} is already connected.</strong> If you continue, you’ll have to
                                  reenter your new credentials into {rater.name}.
                              </>
                          ),
                          mode: AlertMode.Info,
                      },
                  ]
                : undefined,
            onClose,
        }),
        [dialogActions, error, isLoading, rater, onClose]
    );

    return (
        <StyledDialog {...dialogProps}>
            <MiniFlow activeStepIndex={activeStep} steps={steps} />
        </StyledDialog>
    );
};

export const ConnectToRaterDialog: React.FC<React.PropsWithChildren<ConnectToRaterDialogProps>> = ({ onClose }) => {
    if (DIALOG_AUTHENTICATION_TYPE === AuthenticationType.New) {
        return <ConnectToRaterDialogNew onClose={onClose} />;
    }

    return <ConnectToRaterDialogOld onClose={onClose} />;
};
