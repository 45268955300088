import { Flex, Grid, spacing, Text } from '@lemonade-hq/blender-ui';
import { Dialog } from '@lemonade-hq/bluis';
import { useCallback, useState } from 'react';
import type { EditionSelectionData } from './EditionSelectInputs';
import { EditionSelectInputs } from './EditionSelectInputs';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import type { EditionWithType } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { Release } from 'models/LoCo/Insurance/Release';
import { useUpdateEditions } from 'queries/LoCo/Insurance/ReleasesQueries';

interface ReplaceEditionsProps {
    readonly releasePublicId: string;
    readonly productCode: string;
    readonly editions: Release['editions'];
    readonly platformSchemaRevision: number | null;
    readonly productSchemaRevision: number | null;
    readonly onClose: () => void;
}

export const ReplaceEditions: React.FC<ReplaceEditionsProps> = ({
    releasePublicId,
    onClose,
    productCode,
    editions,
    platformSchemaRevision,
    productSchemaRevision,
}) => {
    const [updatedEditionSelectionData, setUpdatedEditionSelectionData] = useState<DeepNullable<EditionSelectionData>>({
        isValid: null,
        selectedEditions: Object.entries(editions).map(
            ([type, edition]) => ({ ...edition, type: type as EditionType }) as EditionWithType
        ),
        selectedPlatformSchema: platformSchemaRevision !== null ? platformSchemaRevision.toString() : null,
        selectedProductSchema: productSchemaRevision !== null ? productSchemaRevision.toString() : null,
    });

    const getCode = (editionType: EditionType): string | undefined =>
        updatedEditionSelectionData.selectedEditions.find(e => e.type === editionType)?.code ?? undefined;

    const { mutateAsync, isPending, error } = useUpdateEditions({
        releasePublicId,
        codes: {
            coveragesEditionCode: getCode(EditionType.Coverages),
            digitalAgentEditionCode: getCode(EditionType.DigitalAgent),
            ratingEditionCode: getCode(EditionType.Rating),
            underwritingFiltersEditionCode: getCode(EditionType.UnderwritingFilters),
        },
        schema: {
            platformSchemaRevision:
                updatedEditionSelectionData.selectedPlatformSchema !== null
                    ? Number(updatedEditionSelectionData.selectedPlatformSchema)
                    : undefined,
            productSchemaRevision:
                updatedEditionSelectionData.selectedProductSchema !== null
                    ? Number(updatedEditionSelectionData.selectedProductSchema)
                    : undefined,
        },
    });

    const publish = useCallback(async () => {
        await mutateAsync(releasePublicId);
        onClose();
    }, [mutateAsync, onClose, releasePublicId]);

    return (
        <Dialog
            actions={[
                { type: 'close', text: 'Cancel', onClick: onClose },
                { type: 'submit', text: 'Save', onClick: publish },
            ]}
            error={error === null ? undefined : (error as Error).message}
            loading={isPending}
            title="Replace editions in release"
        >
            <Flex flexDirection="column" gap={spacing.s16} height="370px">
                <Text margin="0 0 1.6rem 0">
                    When the editions of a release are updated, the system will recalculate the needed edition sets,
                    recheck validations, and generate rollout insights accordingly.
                </Text>
                <Grid gap="1rem" gridTemplateColumns="2fr 3fr" style={{ alignItems: 'center' }}>
                    <EditionSelectInputs
                        editionSelectionData={updatedEditionSelectionData}
                        onChange={newEditionSelectionData => {
                            setUpdatedEditionSelectionData(newEditionSelectionData);
                        }}
                        productCode={productCode}
                    />
                </Grid>
            </Flex>
        </Dialog>
    );
};
