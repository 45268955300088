import type { CurrencyProps } from '@lemonade-hq/lemonation';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector as useReduxSelector } from 'react-redux';
import type { Policy } from '../models/HomePolicy';
import type { CommunicationState } from '../reducers/communication';
import type { FloatingNoteState } from '../reducers/floatingNote';
import type { ViewState } from '../reducers/view';
import type { WorkflowsState } from '../reducers/workflows';
import type { HomeClaim } from 'models/HomeClaim';
import type { HomeClaimNotification } from 'models/HomeClaimNotifications';

export interface ClaimData {
    readonly claim?: HomeClaim;
    readonly loadFailed: boolean;
    readonly notifications: HomeClaimNotification[];
    readonly authorizationError: Error | null;
}

export interface RootState {
    readonly floatingNote: FloatingNoteState;
    readonly communication: CommunicationState;
    readonly workflows: WorkflowsState;
    readonly claim: ClaimData;
    readonly view: ViewState;
    readonly currency: {
        readonly currencySettings: CurrencyProps;
    };
    readonly policy: {
        readonly policy: Policy;
    };
    readonly operator: {
        readonly id: string;
        readonly roles: string[];
    };
}

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
