import { Select } from '@lemonade-hq/blender-ui';
import type { Key } from 'react-aria-components';
import { Step } from 'components/LoCo/common/components/CoverageRules/ManageRuleCommon';
import { OutcomeWrapper } from 'components/LoCo/common/components/Dialog/OutcomeWrapper';
import { InputWrapper } from 'components/LoCo/common/components/InputWrapper';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import { useGetSuspenseRegistryReasonsByProductLine } from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

interface ReasonSelectionProps {
    readonly lifecycleContext: UnderwritingDecisionLifecycleContext;
    readonly reasonCode: string | null;
    readonly onChange: (value: Key | null) => void;
}

export const ReasonSelection: React.FC<ReasonSelectionProps> = ({ lifecycleContext, onChange, reasonCode }) => {
    const product = useGetProductData();
    const {
        data: { data: reasons },
    } = useGetSuspenseRegistryReasonsByProductLine(product.productLineCode, lifecycleContext);

    return (
        <Step>
            <OutcomeWrapper title="Select Reason">
                <InputWrapper label="Reason">
                    <Select
                        onChange={onChange}
                        options={reasons.map(reason => ({ label: reason.name, value: reason.code }))}
                        placeholder="Select Reason"
                        selectedKey={reasonCode ?? undefined}
                    />
                </InputWrapper>
            </OutcomeWrapper>
        </Step>
    );
};
