/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { instanceOfAxiosError } from '@lemonade-hq/bluiza';
import type {
    SerializableTool as Tool,
    ToolSchemaValues,
    SerializableToolsRevision as ToolsRevision,
} from '@lemonade-hq/persisted-tools';
import { ToolChangeStatus, ToolsRevisionStatus } from '@lemonade-hq/persisted-tools';
import { randomUuid } from '@lemonade-hq/ts-helpers';
import type {
    InfiniteData,
    QueryClient,
    UseInfiniteQueryResult,
    UseMutationResult,
    UseQueryResult,
} from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import type { SetRequired } from 'type-fest';
import { blenderGeneral } from '../../../apiClients';
import { generateMockEmptyTool } from './fixtures/persisted_tools.fixtures';
import type { PlaygroundURLParams } from './shared/routing.consts';
import { SignalAbortError, sleepWithSignal } from 'commons/Signal';

const BASE_URL = '/api/v1/ai-playground';
const SUNSETTED_PAGE_SIZE = 100;

export enum PersistedToolsQueryKey {
    GetToolEditorSchema = 'GET_TOOL_EDITOR_SCHEMA',
    GetToolsRevision = 'GET_TOOLS_REVISION',
    ListPendingToolsRevisions = 'LIST_PENDING_TOOLS_REVISIONS',
    ListSunsettedToolsRevisions = 'LIST_SUNSETTED_TOOLS_REVISIONS',
    GetPublishedToolsRevision = 'GET_PUBLISHED_TOOLS_REVISION',
    UpdateToolsRevision = 'UPDATE_TOOLS_REVISION',
}

export type ToolRevisionForListing = Pick<ToolsRevision, 'publicId' | 'publishedAt'>;

export const DEFAULT_EMPTY_TOOL_REVISION: ToolsRevision = {
    publicId: randomUuid().slice(0, 10),
    status: ToolsRevisionStatus.Pending,
    createdBy: '',
    indexPublicId: '',
    addedAt: '',
    updatedAt: '',
    tools: new Array(20).fill(null).map(() => generateMockEmptyTool()),
    syncStatus: { canSync: false, errors: [] },
};

// helpers

function removeToolsRevisionFromPendingQueryCache(queryClient: QueryClient, removedToolsRevision: ToolsRevision): void {
    // remove the just published one from the pending list
    const cachedList = queryClient.getQueryData<ToolsRevision[]>([PersistedToolsQueryKey.ListPendingToolsRevisions]);
    if (cachedList != null) {
        queryClient.setQueryData(
            [PersistedToolsQueryKey.ListPendingToolsRevisions],
            cachedList.filter(tr => tr.publicId !== removedToolsRevision.publicId)
        );
    }
}

function updateToolsRevisionInQueryCache(queryClient: QueryClient, updatedToolsRevision: ToolsRevision): void {
    queryClient.setQueryData(
        [PersistedToolsQueryKey.GetToolsRevision, updatedToolsRevision.publicId],
        updatedToolsRevision
    );
    const cachedList = queryClient.getQueryData<ToolsRevision[]>([PersistedToolsQueryKey.ListPendingToolsRevisions]);

    // make sure the list always has the up to date tools revision
    queryClient.setQueryData(
        [PersistedToolsQueryKey.ListPendingToolsRevisions],
        cachedList?.map(tr => (tr.publicId === updatedToolsRevision.publicId ? updatedToolsRevision : tr)) ?? [
            updatedToolsRevision,
        ]
    );
}

function createToolsRevisionInQueryCache(queryClient: QueryClient, newToolsRevision: ToolsRevision): void {
    queryClient.setQueryData([PersistedToolsQueryKey.GetToolsRevision, newToolsRevision.publicId], newToolsRevision);
    const cachedList = queryClient.getQueryData<ToolsRevision[]>([PersistedToolsQueryKey.ListPendingToolsRevisions]);

    // add the just created one to the pending list
    queryClient.setQueryData(
        [PersistedToolsQueryKey.ListPendingToolsRevisions],
        [...(cachedList ?? []), newToolsRevision]
    );
}

function publishToolsRevisionInQueryCache(queryClient: QueryClient, publishedToolsRevision: ToolsRevision): void {
    queryClient.setQueryData(
        [PersistedToolsQueryKey.GetToolsRevision, publishedToolsRevision.publicId],
        publishedToolsRevision
    );
    queryClient.setQueryData([PersistedToolsQueryKey.GetPublishedToolsRevision], publishedToolsRevision);
    removeToolsRevisionFromPendingQueryCache(queryClient, publishedToolsRevision);
}

function archiveToolsRevisionInQueryCache(queryClient: QueryClient, toolsRevisionToArchive: ToolsRevision): void {
    const archivedToolsRevision = { ...toolsRevisionToArchive, status: ToolsRevisionStatus.Archived };
    queryClient.setQueryData(
        [PersistedToolsQueryKey.GetToolsRevision, archivedToolsRevision.publicId],
        archivedToolsRevision
    );
    removeToolsRevisionFromPendingQueryCache(queryClient, archivedToolsRevision);
}

// schema

export function useGetToolsEditorSchemaValues(): UseQueryResult<ToolSchemaValues> {
    return useQuery({
        queryKey: [PersistedToolsQueryKey.GetToolEditorSchema],
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Number.POSITIVE_INFINITY,
        queryFn: async () => {
            const url = `${BASE_URL}/tool_editor/schema`;
            const response = await blenderGeneral.get<{ readonly data: ToolSchemaValues }>(url);
            return response.data.data;
        },
    });
}

// tools revisions

export function useGetPublishedToolsRevision({
    enabled,
}: {
    readonly enabled?: boolean;
} = {}): UseQueryResult<ToolsRevision> {
    const queryClient = useQueryClient();

    return useQuery({
        enabled: enabled ?? true,
        queryKey: [PersistedToolsQueryKey.GetPublishedToolsRevision],
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        placeholderData: DEFAULT_EMPTY_TOOL_REVISION,
        queryFn: async () => {
            const url = `${BASE_URL}/tools_revisions/published`;
            const response = await blenderGeneral.get<{ readonly data: ToolsRevision }>(url);
            const publishedToolsRevision = response.data.data;
            queryClient.setQueryData(
                [PersistedToolsQueryKey.GetToolsRevision, publishedToolsRevision.publicId],
                publishedToolsRevision
            );
            return publishedToolsRevision;
        },
    });
}

export function useListSunsettedToolsRevisions({
    enabled,
}: {
    readonly enabled?: boolean;
} = {}): UseInfiniteQueryResult<InfiniteData<ToolRevisionForListing[]>> {
    return useInfiniteQuery({
        enabled: enabled ?? true,
        queryKey: [PersistedToolsQueryKey.ListSunsettedToolsRevisions],
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        initialPageParam: 0,
        placeholderData: {
            pages: [] as ToolRevisionForListing[][],
            pageParams: [0],
        },
        getNextPageParam: (_lastPage, _allPages, lastPageParam) => lastPageParam + 1,
        queryFn: async ({ pageParam }) => {
            const url = `${BASE_URL}/tools_revisions/sunsetted?size=${SUNSETTED_PAGE_SIZE}&offset=${SUNSETTED_PAGE_SIZE * pageParam}&fields=publishedAt`;
            const response = await blenderGeneral.get<{ readonly data: ToolRevisionForListing[] }>(url);
            return response.data.data;
        },
    });
}

export function useCreateToolsRevision(): UseMutationResult<ToolsRevision, unknown, void> {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const url = `${BASE_URL}/tools_revisions`;
            const response = await blenderGeneral.post<{ readonly data: ToolsRevision }>(url);
            return response.data.data;
        },
        onSuccess: newToolsRevision => {
            createToolsRevisionInQueryCache(queryClient, newToolsRevision);
        },
    });
}

export function useGetToolsRevision(
    toolsRevisionPublicId: string | undefined,
    {
        enabled,
        disablePlaceholder,
        staleTime,
    }: { readonly enabled?: boolean; readonly disablePlaceholder?: boolean; readonly staleTime?: number } = {}
): UseQueryResult<ToolsRevision> {
    return useQuery({
        enabled: enabled ?? toolsRevisionPublicId != null,
        placeholderData: disablePlaceholder ? undefined : DEFAULT_EMPTY_TOOL_REVISION,
        queryKey: [PersistedToolsQueryKey.GetToolsRevision, toolsRevisionPublicId],
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: staleTime ?? 0,
        queryFn: async () => {
            const url = `${BASE_URL}/tools_revisions/${toolsRevisionPublicId}`;
            const response = await blenderGeneral.get<{ readonly data: ToolsRevision }>(url);
            const toolsRevision = response.data.data;
            return toolsRevision;
        },
    });
}

export function useListPendingToolsRevisions(): UseQueryResult<ToolsRevision[]> {
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: [PersistedToolsQueryKey.ListPendingToolsRevisions],
        placeholderData: [DEFAULT_EMPTY_TOOL_REVISION, DEFAULT_EMPTY_TOOL_REVISION, DEFAULT_EMPTY_TOOL_REVISION],
        queryFn: async () => {
            const url = `${BASE_URL}/tools_revisions`;
            const response = await blenderGeneral.get<{ readonly data: ToolsRevision[] }>(url);
            const toolsRevisions = response.data.data;
            toolsRevisions.forEach(toolsRevision => {
                queryClient.setQueryData(
                    [PersistedToolsQueryKey.GetToolsRevision, toolsRevision.publicId],
                    toolsRevision
                );
            });
            return toolsRevisions;
        },
    });
}

export function useGetCurrentToolsRevision(options?: { readonly enabled?: boolean }): UseQueryResult<ToolsRevision> {
    const { toolsRevisionPublicId = '' } = useParams<PlaygroundURLParams>();
    return useGetToolsRevision(toolsRevisionPublicId, options);
}

export function usePublishToolsRevision(): UseMutationResult<
    ToolsRevision,
    unknown,
    { readonly publishNotes: string; readonly toolsRevisionPublicId: string }
> {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ toolsRevisionPublicId, publishNotes }) => {
            const url = `${BASE_URL}/tools_revisions/${toolsRevisionPublicId}/publish`;
            const response = await blenderGeneral.post<{ readonly data: ToolsRevision }>(url, { publishNotes });
            return response.data.data;
        },
        onSuccess: publishedToolsRevision => {
            publishToolsRevisionInQueryCache(queryClient, publishedToolsRevision);
        },
    });
}

// archive tools revision

export function useArchiveToolsRevision(): UseMutationResult<ToolsRevision, string, string, void> {
    const queryClient = useQueryClient();

    return useMutation({
        onMutate: (toolsRevisionPublicId: string) => {
            const queryKey = [PersistedToolsQueryKey.GetToolsRevision, toolsRevisionPublicId];
            const toolsRevisionToArchive = queryClient.getQueryData<ToolsRevision>(queryKey)!;
            archiveToolsRevisionInQueryCache(queryClient, toolsRevisionToArchive);
        },
        mutationFn: async (toolsRevisionPublicId: string) => {
            const url = `${BASE_URL}/tools_revisions/${toolsRevisionPublicId}/archive`;
            const response = await blenderGeneral.post<{ readonly data: ToolsRevision }>(url);
            return response.data.data;
        },
    });
}

// tools

interface ToolMutationContext {
    readonly previousValue: ToolsRevision | undefined;
    readonly newValue: ToolsRevision | undefined;
}

function getToolErrorMessage(e: unknown): string {
    return instanceOfAxiosError(e)
        ? e.response != null
            ? (e.response.data as { readonly message: string }).message
            : 'Unknown error'
        : 'Unknown error';
}

// create tool

export function useCreateTool(
    {
        toolsRevisionPublicId,
    }: {
        readonly toolsRevisionPublicId: string;
    },
    { onError }: { readonly onError?: (err: unknown) => void } = {}
): UseMutationResult<Tool, unknown, SetRequired<Partial<Tool>, 'name'>> {
    const queryClient = useQueryClient();
    const mutationKey = [PersistedToolsQueryKey.UpdateToolsRevision, toolsRevisionPublicId];
    const queryKey = [PersistedToolsQueryKey.GetToolsRevision, toolsRevisionPublicId];

    return useMutation({
        mutationKey,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });
        },
        mutationFn: async data => {
            const url = `${BASE_URL}/tools_revisions/${toolsRevisionPublicId}/tools`;
            const response = await blenderGeneral.post<{ readonly data: Tool }>(url, data);
            return response.data.data;
        },
        onSuccess: createdTool => {
            const toolsRevision = queryClient.getQueryData<ToolsRevision>(queryKey)!;
            const updatedToolsRevision = produce(toolsRevision, draft => {
                draft.tools.push(createdTool);
                return draft;
            });
            updateToolsRevisionInQueryCache(queryClient, updatedToolsRevision);
        },
        onError: e => {
            onError?.(getToolErrorMessage(e));
        },
    });
}

// update tool

function getOptimisticUpdateMutationFunction(
    queryClient: QueryClient,
    toolsRevisionPublicId: string | undefined,
    toolPublicId: string | undefined
): (data: Partial<Tool>) => Promise<ToolMutationContext> {
    const queryKey = [PersistedToolsQueryKey.GetToolsRevision, toolsRevisionPublicId];

    return async data => {
        if (toolsRevisionPublicId == null) throw new Error('Missing tools revision public ID');
        if (toolPublicId == null) throw new Error('Missing tool public ID');

        await queryClient.cancelQueries({ queryKey });

        const previousValue = queryClient.getQueryData<ToolsRevision>(queryKey);
        const newValue = produce(previousValue, draft => {
            const toolToUpdate = draft?.tools.find(tool => tool.publicId === toolPublicId);
            if (toolToUpdate) {
                Object.assign(toolToUpdate, { ...data, lastEditedAt: new Date().toISOString() });
            }

            return draft;
        });
        queryClient.setQueryData(queryKey, newValue);
        return { previousValue, newValue };
    };
}

export function useUpdateToolLocally({
    toolsRevisionPublicId,
}: {
    readonly toolsRevisionPublicId?: string;
    readonly toolPublicId?: string;
}): UseMutationResult<void, unknown, Partial<Tool> & { readonly toolPublicId: string }, ToolMutationContext> {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [PersistedToolsQueryKey.UpdateToolsRevision, toolsRevisionPublicId],
        onMutate: async ({ toolPublicId, ...data }) =>
            await getOptimisticUpdateMutationFunction(queryClient, toolsRevisionPublicId, toolPublicId)(data),
        mutationFn: async () => await Promise.resolve(),
    });
}

export function useUpdateTool(
    {
        toolsRevisionPublicId,
    }: {
        readonly toolsRevisionPublicId?: string;
    },
    {
        onError,
        debounce,
    }: {
        readonly debounce?: number;
        readonly onError?: (err: unknown) => void;
    } = {}
): UseMutationResult<Tool, unknown, Partial<Tool> & { readonly toolPublicId: string }, ToolMutationContext> {
    const abortControllerRef = useRef<AbortController | null>(null);

    const queryClient = useQueryClient();
    const mutationKey = [PersistedToolsQueryKey.UpdateToolsRevision, toolsRevisionPublicId];
    const queryKey = [PersistedToolsQueryKey.GetToolsRevision, toolsRevisionPublicId];

    return useMutation({
        mutationKey,
        onMutate: async ({ toolPublicId, ...data }) => {
            abortControllerRef.current?.abort();
            const toolsRevision = queryClient.getQueryData<ToolsRevision>(queryKey)!;
            const tool = toolsRevision.tools.find(t => t.publicId === toolPublicId)!;
            return await getOptimisticUpdateMutationFunction(
                queryClient,
                toolsRevisionPublicId,
                toolPublicId
            )({
                ...data,
                changeStatus:
                    tool.changeStatus === ToolChangeStatus.New ? ToolChangeStatus.New : ToolChangeStatus.Edited,
            });
        },
        mutationFn: async ({ toolPublicId, ...data }) => {
            abortControllerRef.current = new AbortController();
            await sleepWithSignal(debounce ?? 0, { signal: abortControllerRef.current.signal });
            const mutationsInCache = queryClient.getMutationCache().getAll();
            const lastSuccessfulMutationIndex = mutationsInCache.findLastIndex(
                mutation => isEqual(mutation.options.mutationKey, mutationKey) && mutation.state.status === 'success'
            );
            const cancelledDebouncedMutations = mutationsInCache
                .slice(lastSuccessfulMutationIndex + 1)
                .filter(
                    mutation =>
                        isEqual(mutation.options.mutationKey, mutationKey) &&
                        mutation.state.error instanceof SignalAbortError
                );
            const currentDataAndAlsoFromCancelledDebouncedMutations = Object.assign(
                // @ts-expect-error: this is fine, it's an array - doesn't work even with casting
                ...cancelledDebouncedMutations.map(mutation => omit(mutation.state.variables, 'toolPublicId')),
                data
            );
            const url = `${BASE_URL}/tools_revisions/${toolsRevisionPublicId}/tools/${toolPublicId}`;
            return (
                await blenderGeneral.put<{ readonly data: Tool }>(
                    url,
                    currentDataAndAlsoFromCancelledDebouncedMutations
                )
            ).data.data;
        },
        onSuccess: updatedTool => {
            const toolsRevision = queryClient.getQueryData<ToolsRevision>(queryKey)!;
            const updatedToolsRevision = produce(toolsRevision, draft => {
                const matchingTool = draft.tools.find(t => updatedTool.publicId === t.publicId);
                if (matchingTool) {
                    Object.assign(matchingTool, {
                        validStatus: updatedTool.validStatus,
                        dirtyFields: updatedTool.dirtyFields,
                        changeStatus: updatedTool.changeStatus,
                    });
                }
            });
            updateToolsRevisionInQueryCache(queryClient, updatedToolsRevision);
            return updatedTool;
        },
        onError: e => {
            if (!(e instanceof SignalAbortError)) {
                onError?.(getToolErrorMessage(e));
            }
        },
    });
}

// delete tool

export function useDeleteTool(
    {
        toolsRevisionPublicId,
    }: {
        readonly toolsRevisionPublicId?: string;
        readonly toolPublicId?: string;
    },
    { onError }: { readonly onError?: (err: unknown) => void } = {}
): UseMutationResult<Tool, string, string, ToolMutationContext> {
    const queryClient = useQueryClient();
    const mutationKey = [PersistedToolsQueryKey.UpdateToolsRevision, toolsRevisionPublicId];
    const queryKey = [PersistedToolsQueryKey.GetToolsRevision, toolsRevisionPublicId];

    return useMutation({
        mutationKey,
        onMutate: async (toolPublicId: string) => {
            await queryClient.cancelQueries({ queryKey });
            const previousToolsRevision = queryClient.getQueryData<ToolsRevision>(queryKey)!;
            const updatedToolsRevision = produce(previousToolsRevision, draft => {
                const toolToUpdate = draft.tools.find(tool => tool.publicId === toolPublicId);
                if (toolToUpdate) {
                    Object.assign(toolToUpdate, { changeStatus: ToolChangeStatus.Deleted });
                }

                return draft;
            });
            updateToolsRevisionInQueryCache(queryClient, updatedToolsRevision);
            return { previousValue: previousToolsRevision, newValue: updatedToolsRevision };
        },
        mutationFn: async (toolPublicId: string) => {
            const url = `${BASE_URL}/tools_revisions/${toolsRevisionPublicId}/tools/${toolPublicId}`;
            const response = await blenderGeneral.delete<{ readonly data: Tool }>(url);
            return response.data.data;
        },
        onError: (e, _variables, context) => {
            queryClient.setQueryData(queryKey, context?.previousValue);
            onError?.(getToolErrorMessage(e));
        },
    });
}
