/* eslint-disable react/no-array-index-key */
import { wrap } from '@lemonade-hq/ts-helpers';
import { clsx } from 'clsx';
import { useCallback, useRef, useState } from 'react';
import type { Selection } from 'react-aria-components';
import { Header, ListBox, ListBoxItem, Section } from 'react-aria-components';
import type { LayoutProps } from '../../base/Layout/Layout';
import { Layout } from '../../base/Layout/Layout';
import { useLayoutProps } from '../../base/Layout/Layout.css';
import { Text } from '../../base/Text/Text';
import type { SelectionMode } from '../../theme/selection';
import { spacing } from '../../theme/spacing.css';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/types';
import type { ListItemProps } from '../ListItem/ListItem';
import { ListItemContent } from '../ListItem/ListItem';
import { listItem } from '../ListItem/ListItem.css';
import * as styles from './ListItemMenu.css';
import { useListMenuScroll } from './useListMenuScroll';

export const LIST_ITEM_MENU_COUNT_INTERPOLATION = `%{count}`;

export type ListItemMenuOption<TItemIdentifier extends number | string = number | string> =
  ListItemProps<TItemIdentifier>;

export interface ListItemMenuSectionProps<TItemIdentifier extends number | string = number | string> {
  readonly label: string;
  readonly id?: string;
  readonly sideLabel?: string;
  readonly secondaryLabel?: string;
  readonly icon?: IconName;
  readonly options: ListItemMenuOption<TItemIdentifier>[];
}

export interface ListItemMenuSectionPresentationalProps<TItemIdentifier extends number | string = number | string>
  extends ListItemMenuSectionProps<TItemIdentifier>,
    Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  readonly isCollapsed: boolean;
  readonly sideLabel?: string;
}

export type ListItemMenuProps<
  TItemIdentifier extends number | string = number | string,
  TMode extends SelectionMode = 'single',
> = Omit<LayoutProps, 'onSelect'> & {
  readonly selection?: NoInfer<TMode> extends 'single' ? NoInfer<TItemIdentifier> : NoInfer<TItemIdentifier>[];
  readonly onSelect?: NoInfer<TMode> extends 'single'
    ? (id: NoInfer<TItemIdentifier>) => void
    : (ids: NoInfer<TItemIdentifier>[]) => void;
  readonly sections: ListItemMenuSectionProps<TItemIdentifier>[];
  readonly preventDeselection?: boolean;
  readonly mode?: TMode;
  readonly asTimeline?: boolean;
};

const ListItemMenuSectionHeader = ({
  label,
  sideLabel,
  options,
  isCollapsed,
  ...props
}: ListItemMenuSectionPresentationalProps): JSX.Element => {
  return (
    <Header>
      <Layout
        alignItems="center"
        className={styles.listItemMenuHeader}
        display="flex"
        flexDirection="row"
        gap={spacing.s12}
        {...props}
      >
        <Icon name="chevron-down" rotation={isCollapsed ? 'ccw90deg' : undefined} size="sm" />
        <Text fontWeight="bold">{label}</Text>
        <Layout className={styles.listItemMenuHeaderSideLabel} marginInlineStart="auto">
          {sideLabel != null && (
            <span>{sideLabel.replace(LIST_ITEM_MENU_COUNT_INTERPOLATION, options.length.toString())}</span>
          )}
        </Layout>
      </Layout>
    </Header>
  );
};

export const ListItemMenu = <
  TItemIdentifier extends number | string = number | string,
  TMode extends SelectionMode = 'single',
>({
  sections,
  className,
  style,
  selection,
  // @ts-expect-error the TMode type should be inferred and not explicity passed via type args
  mode = 'single',
  preventDeselection,
  onSelect,
  asTimeline,
  ...props
}: ListItemMenuProps<TItemIdentifier, TMode>): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { className: internalClassName, style: internalStyle, otherProps } = useLayoutProps(props);
  const [collapsedSections, setCollapsedSections] = useState<Record<string, boolean>>({});

  useListMenuScroll({
    scrollContainer: containerRef,
    contentContainer: contentRef,
    toggleableBottomStyle: styles.listItemMenuBottomOverflow,
    toggleableTopStyle: styles.listItemMenuTopOverflow,
  });

  const handleOnToggleSection = useCallback((sectionName: string) => {
    setCollapsedSections(prevState => {
      return {
        ...prevState,
        [sectionName]: !prevState[sectionName],
      };
    });
  }, []);

  const handleOnSelect = useCallback(
    (result: Selection) => {
      const results = result as Set<TItemIdentifier>;
      const parentSections = sections.filter(section => section.options.some(option => results.has(option.id)));
      parentSections.forEach(section => {
        setCollapsedSections(prevState => {
          return {
            ...prevState,
            [section.label]: false,
          };
        });
      });

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (results == null || (results.size < 1 && preventDeselection)) return; // do not allow to de-select an item if preventDeselction is true
      if (mode === 'single') {
        const [singleResult] = results;
        onSelect?.(singleResult as TItemIdentifier & TItemIdentifier[]);
      } else {
        onSelect?.([...results] as TItemIdentifier & TItemIdentifier[]);
      }
    },
    [onSelect, sections, preventDeselection, mode],
  );

  return (
    <Layout
      className={clsx(styles.listItemMenu, className, internalClassName)}
      ref={containerRef}
      style={{ ...internalStyle, ...style }}
      {...otherProps}
    >
      <ListBox
        aria-label="test"
        className={clsx({ [styles.listItemMenuTimeline]: asTimeline })}
        onSelectionChange={handleOnSelect}
        ref={contentRef}
        selectedKeys={selection != null ? wrap(selection) : []}
        selectionMode={mode}
      >
        {sections.map(section => {
          const isCollapsed = collapsedSections[section.label];
          return (
            <Section className={styles.listItemMenuSection} key={section.label}>
              <ListItemMenuSectionHeader
                isCollapsed={isCollapsed}
                label={section.label}
                onClick={() => handleOnToggleSection(section.label)}
                options={section.options}
                sideLabel={section.sideLabel}
              />
              {!isCollapsed &&
                section.options.map((item, i) => {
                  return (
                    <ListBoxItem
                      className={clsx(styles.listItemMenuItem, listItem)}
                      id={item.id}
                      key={`${item.id}-${i}`}
                      textValue={typeof item.label === 'string' ? item.label : ''}
                    >
                      <ListItemContent
                        color={item.color}
                        icon={item.icon}
                        label={item.label}
                        secondaryLabel={item.secondaryLabel}
                        sideLabel={item.sideLabel}
                      />
                    </ListBoxItem>
                  );
                })}
            </Section>
          );
        })}
      </ListBox>
    </Layout>
  );
};
