import { useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
    MISSING_TOOLS_REVISION_ID_MARKER,
    PLAYGROUND_COMPARE_PATH_WITH_PARAMS,
    PLAYGROUND_COMPARE_PATH_WITH_PARAMS_AND_TOOL,
    PLAYGROUND_FULL_PATH_PREFIX,
    PlaygroundURLParam,
} from '../shared/routing.consts';
import type { PlaygroundURLParams } from '../shared/routing.consts';

interface UseComparePageNavigationResult {
    readonly navigate: (older?: string, newer?: string, toolName?: string) => void;
    readonly olderToolsRevisionPublicId?: string;
    readonly newerToolsRevisionPublicId?: string;
    readonly toolName?: string;
}

export function useComparePageNavigation(): UseComparePageNavigationResult {
    const reactRouterNavigate = useNavigate();
    const { olderToolsRevisionPublicId, newerToolsRevisionPublicId, toolName } = useParams<PlaygroundURLParams>();

    const navigate = useCallback(
        (older?: string, newer?: string, tool?: string) => {
            reactRouterNavigate(
                generatePath(
                    `${PLAYGROUND_FULL_PATH_PREFIX}${tool != null ? PLAYGROUND_COMPARE_PATH_WITH_PARAMS_AND_TOOL : PLAYGROUND_COMPARE_PATH_WITH_PARAMS}`,
                    {
                        [PlaygroundURLParam.OlderToolsRevisionPublicId]: older ?? MISSING_TOOLS_REVISION_ID_MARKER,
                        [PlaygroundURLParam.NewerToolsRevisionPublicId]: newer ?? MISSING_TOOLS_REVISION_ID_MARKER,
                        [PlaygroundURLParam.ToolName]: tool ?? null,
                    }
                )
            );
        },
        [reactRouterNavigate]
    );

    return { navigate, olderToolsRevisionPublicId, newerToolsRevisionPublicId, toolName };
}
