import { Select } from '@lemonade-hq/bluis';

import { capitalize } from '@lemonade-hq/ts-helpers';
import { InputWrapper } from '../../../InputWrapper';
import { useManageRuleDialogContext } from '../../ManageRuleDialogContext';
import { Outcome } from './Outcome';
import { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import { CoverageDefaultValue, RuleOutcomeType } from 'models/LoCo/Insurance/CoverageRule';

function getTitle(lifecycle: RuleLifecycleContext.Moving | RuleLifecycleContext.Renewal): string {
    return lifecycle === RuleLifecycleContext.Renewal ? 'Initial Renewal Value' : 'Initial Value After Moving';
}

function getMatchPreviousOptionName(lifecycle: RuleLifecycleContext.Moving | RuleLifecycleContext.Renewal): string {
    return lifecycle === RuleLifecycleContext.Renewal
        ? 'Match previous policy term'
        : 'Match pre-move policy (if available)';
}

export const CoverageInitialValue: React.FC<{
    readonly coverageName: string;
    readonly lifecycle: RuleLifecycleContext.Moving | RuleLifecycleContext.Renewal;
}> = ({ coverageName, lifecycle }) => {
    const {
        values: { outcome, entityType },
        dispatch,
    } = useManageRuleDialogContext();
    if (entityType == null) throw new Error('Entity type is required');

    return (
        <Outcome coverageOrSettingName={coverageName} outcomeTitle={getTitle(lifecycle)} ruleEntityType={entityType}>
            <InputWrapper label="Renewal default">
                <Select
                    onOptionSelected={option => {
                        if (option.value === RuleOutcomeType.MatchPreviousPolicyOutcome) {
                            dispatch({
                                type: 'outcome',
                                value: { type: RuleOutcomeType.MatchPreviousPolicyOutcome },
                            });
                        } else {
                            dispatch({
                                type: 'outcome',
                                value: {
                                    type: RuleOutcomeType.CoverageDefaultValueOutcome,
                                    value: option.value as CoverageDefaultValue,
                                },
                            });
                        }
                    }}
                    options={[
                        {
                            value: RuleOutcomeType.MatchPreviousPolicyOutcome,
                            label: getMatchPreviousOptionName(lifecycle),
                            id: 'match-previous-policy',
                        },
                        {
                            value: CoverageDefaultValue.On,
                            label: capitalize(CoverageDefaultValue.On),
                            id: 'on',
                        },
                        {
                            value: CoverageDefaultValue.Off,
                            label: capitalize(CoverageDefaultValue.Off),
                            id: 'off',
                        },
                    ]}
                    value={
                        outcome?.type === RuleOutcomeType.CoverageDefaultValueOutcome
                            ? outcome.value
                            : outcome?.type === RuleOutcomeType.MatchPreviousPolicyOutcome
                              ? outcome.type
                              : null
                    }
                />
            </InputWrapper>
        </Outcome>
    );
};
