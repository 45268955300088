import { BlenderLayer } from '@lemonade-hq/bluis';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { useHeader, useObservedHeight } from '../hooks';

const StyledContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: ${BlenderLayer.Header};

    @media print {
        position: static;
    }
`;

interface ContainerProps {
    readonly children: React.ReactElement;
}

export const HeaderContainer: React.FC<React.PropsWithChildren<ContainerProps>> = ({ children }) => {
    const { setHeight, setPortal } = useHeader();
    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    const height = useObservedHeight(container);

    useEffect(() => {
        setHeight(height);
    }, [height, setHeight]);

    return (
        <StyledContainer ref={setContainer}>
            {children}
            <div ref={setPortal} />
        </StyledContainer>
    );
};
