import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type {
    SerializableTool as Tool,
    SerializableToolsRevision as ToolsRevision,
} from '@lemonade-hq/persisted-tools';
import type { FC } from 'react';
import newTool from '../assets/new_tool.png';
import { DeletedToolAlert } from '../shared/alerts/DeletedToolAlert';
import { InexistentToolAlert } from '../shared/alerts/InexistentToolAlert';
import type { ComparisonType } from './compare.helpers';
import { PlaygroundComparableToolTopBar } from './PlaygroundComparedToolTopBar';

export const CompareNoTool: FC<{
    readonly tool?: Tool;
    readonly toolsRevision: ToolsRevision;
    readonly comparisonType: ComparisonType;
}> = ({ tool, toolsRevision, comparisonType }) => {
    return (
        <Flex flexDirection="column" flexGrow="1" gap={spacing.s16}>
            {comparisonType === 'newer' ? <DeletedToolAlert /> : <InexistentToolAlert />}
            <PlaygroundComparableToolTopBar tool={tool} toolsRevision={toolsRevision} />
            <Flex alignItems="center" flexGrow="1" height="100%" justifyContent="center">
                <img alt="new" height={205} src={newTool} width={236} />
            </Flex>
        </Flex>
    );
};
