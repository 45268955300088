import { TagMode } from '@lemonade-hq/bluis';
import type { Descendant } from '@lemonade-hq/bluiza';
import { slateFromHtml } from '@lemonade-hq/cdk';
import type { MultiselectOption, SelectOptionBase } from '@lemonade-hq/cdk';
import type { Locale } from '@lemonade-hq/lemonation';
import { DatetimeFormat, formatDatetime } from '@lemonade-hq/lemonation';
import type { Timezone } from '@lemonade-hq/ts-helpers';
import { snakeCaseToReadable, titlize } from '@lemonade-hq/ts-helpers';
import type { Message, SupervisorTicketFilters, TicketAiReview, TicketParam, TimelineItem } from '../shared/types';
import { TimelineItemType } from '../shared/types';
import { defaultFilters } from './supervisor.const';

/**
 *
 * @param {string} input
 * @returns {string}
 * @example
 * convertLinksToHtml('This is a [link](https://example.com)')
 * => 'This is a <a href="https://example.com">link</a>'
 */
export function convertLinksToHtml(input: string): string {
    const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    const intermediateInput = input.replace(exp, "<a href='$1' target='_blank'>$1</a>");
    const exp2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    return intermediateInput.replace(exp2, "$1<a href='http://$2' target='_blank'>$2</a>");
}

export function convertNewLinesToHtmlBreaks(input: string): string {
    return input.split('\n').join('<br>');
}

export function convertHtmlBreaksToNewLines(input: string): string {
    return input.split(/<br\s*\/?>/g).join('\n');
}

export function slateFromAdjustedHtml(string: string): Descendant[] {
    const suggestionWithHtmlLinks = convertLinksToHtml(string);
    const editorFormatResponse = convertHtmlBreaksToNewLines(suggestionWithHtmlLinks);

    return slateFromHtml(editorFormatResponse);
}

export function removeStyleAttributesFromHtml(htmlString: string): string {
    return htmlString.replace(/style="([^"]*)"/g, (_, styles: string) => {
        const styleAttributes = ['font-family', 'font-size', 'font', 'width', 'height'];
        const newStyles = styles
            .split(';')
            .filter(style => {
                return !styleAttributes.some(attr => style.trim().startsWith(attr));
            })
            .join(';');

        return `style="${newStyles}"`;
    });
}

export function buildTicketParams(ticket: TicketAiReview): TicketParam[] {
    const params: TicketParam[] = [];

    params.push({
        id: 'toolInvoked',
        mode: TagMode.Alert,
        text: `Intent: ${titlize(snakeCaseToReadable(ticket.toolInvoked))}`,
    });

    params.push({
        id: 'entityPublicId',
        mode: TagMode.Info,
        text: `Policy ID: ${ticket.entityPublicId ?? 'N/A'}`,
    });

    params.push({
        id: 'entityProduct',
        mode: TagMode.Info,
        text: `LOB: ${ticket.entityProduct != null ? snakeCaseToReadable(ticket.entityProduct) : 'N/A'}`,
    });

    return params;
}

export function getFiltersFromLocalStorage(): SupervisorTicketFilters {
    const storedFilters = localStorage.getItem('supervisorTicketFilters');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return storedFilters != null && storedFilters.length > 0 ? JSON.parse(storedFilters) : defaultFilters;
}

export function updateFiltersInLocalStorage(filters: SupervisorTicketFilters): void {
    localStorage.setItem('supervisorTicketFilters', JSON.stringify(filters));
}

export function isMessage(item: TimelineItem): item is Message {
    return item.type === TimelineItemType.Message || item.type === undefined;
}

export function formatDateTime({
    date,
    locale,
    timezone,
}: {
    readonly date?: string;
    readonly locale?: Locale;
    readonly timezone?: Timezone;
}): string {
    if (date == null) return '';

    const dateOnly = formatDatetime(new Date(date), {
        ...(locale != null && { locale }),
        timezone,
        datetimeFormat: DatetimeFormat.DateOrdinalShortMonth,
    });
    const timeOnly = formatDatetime(new Date(date), {
        ...(locale != null && { locale }),
        timezone,
        datetimeFormat: DatetimeFormat.TimeDefault,
    });

    return `${dateOnly}, ${timeOnly}`; // e.g. Sep 17, 2023, 02:00pm
}

export function findSecondLastMessageIndex(timeline: TimelineItem[]): number {
    let messageCount = 0;

    for (let i = timeline.length - 1; i >= 0; i--) {
        if (isMessage(timeline[i])) {
            messageCount++;
            if (messageCount === 3) {
                return i + 1;
            }
        }
    }

    return 0;
}

export function generateMultiSelectOptions(list: string[]): MultiselectOption[] {
    return list.map(item => ({
        label: snakeCaseToReadable(item),
        id: item,
    }));
}

export function generateSelectOptions(list: string[]): SelectOptionBase[] {
    return list.map(item => ({
        label: item,
        id: item,
        value: item,
    }));
}

export function generateIntentsOptions(intents: Record<string, string>): MultiselectOption[] {
    return Object.keys(intents).map(key => ({
        label: intents[key],
        id: key,
    }));
}
