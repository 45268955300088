/* eslint-disable react/no-array-index-key */
import { Card, Flex, spacing } from '@lemonade-hq/blender-ui';
import type {
    SerializableTool as Tool,
    SerializableToolsRevision as ToolsRevision,
} from '@lemonade-hq/persisted-tools';
import { clsx } from 'clsx';
import type { FC } from 'react';
import * as shimmeringStyles from '../shared/shimmering.css';
import type { ComparisonType } from './compare.helpers';
import { CompareBothEmptyToolRevisions } from './CompareBothEmptyToolRevisions';
import { CompareLoadingToolRevisions } from './CompareLoadingToolRevisions';
import { CompareNoTool } from './CompareNoTool';
import { CompareSingleEmptyToolRevisions } from './CompareSingleEmptyToolRevisions';
import { CompareTool } from './CompareTool';
import { PlaygroundComparableToolsRevisionTopBar } from './PlaygroundComparedToolsRevisionTopBar';
import type { ToolsDiff } from './useToolsDiff';

export const PlaygroundComparedToolContainer: FC<{
    readonly tool?: Tool;
    readonly diff: ToolsDiff | null;
    readonly toolsRevision?: ToolsRevision;
    readonly counterpartToolsRevision?: ToolsRevision;
    readonly isLoading: boolean;
    readonly isLoadingCounterpart: boolean;
    readonly comparisonType: ComparisonType;
}> = ({ isLoading, isLoadingCounterpart, tool, toolsRevision, diff, counterpartToolsRevision, comparisonType }) => {
    return (
        <Flex flexDirection="column" gap={spacing.s08} role="definition" width="100%">
            <PlaygroundComparableToolsRevisionTopBar
                className={clsx({ [shimmeringStyles.shimmering]: isLoading })}
                comparisonType={comparisonType}
                toolsRevision={toolsRevision}
            />
            <Card
                className={clsx({ [shimmeringStyles.shimmering]: isLoading })}
                display="flex"
                flexGrow={1}
                id={comparisonType}
                overflow="scroll"
                width="100%"
            >
                {isLoadingCounterpart ? (
                    <CompareLoadingToolRevisions />
                ) : counterpartToolsRevision == null && toolsRevision == null ? (
                    <CompareBothEmptyToolRevisions />
                ) : counterpartToolsRevision == null || toolsRevision == null ? (
                    <CompareSingleEmptyToolRevisions />
                ) : tool == null ? (
                    <CompareNoTool comparisonType={comparisonType} tool={tool} toolsRevision={toolsRevision} />
                ) : (
                    <CompareTool
                        comparisonType={comparisonType}
                        diff={diff}
                        tool={tool}
                        toolsRevision={toolsRevision}
                    />
                )}
            </Card>
        </Flex>
    );
};
