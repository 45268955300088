import type { SelectOption } from '@lemonade-hq/bluis';
import { FormInputWrapper, Select } from '@lemonade-hq/bluis';
import type { FormProps } from '@lemonade-hq/cdk';
import { basicRequiredValidation, Flex, themedColor, useForm } from '@lemonade-hq/cdk';
import type { Locale } from '@lemonade-hq/lemonation';
import { ALL_LOCALES } from '@lemonade-hq/lemonation';
import { isDefined } from '@lemonade-hq/ts-helpers';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import styled from 'styled-components';
import { FormTextArea } from '../SharedStyles';
import type { LocalizedExplanationItem } from '../underwritingUtils';
import { MIN_EXPLANATION_CHARS, validateExplanationMinChars } from '../underwritingUtils';
import { CopyableComponent } from 'components/LoCo/common/components/Copyable/CopyableComponent';
import { RemoveButton } from 'components/LoCo/common/components/RemoveButton';
import { getMinCharsValidationMessage } from 'components/LoCo/common/helpers/inputHelpers';
import { StyledFormInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

const StyledLocaleWrapper = styled(Flex)`
    padding: 24px;
    position: relative;
    border-radius: 5px;
    border: 1px solid ${themedColor('separator')};
`;

const StyledFieldsWrapper = styled(Flex)`
    flex-direction: column;
    gap: 4px;
    padding-left: 40px;
    width: 825px;
`;

const StyledFormRow = styled(StyledFormInputWrapper)`
    > div {
        align-items: flex-start;
        > label {
            padding-top: 10px;
        }
    }
`;

const StyledFormDropdownWrapper = styled(FormInputWrapper)`
    width: 485px;
`;

const MIN_EXPLANATION_CHARS_MESSAGE = getMinCharsValidationMessage(MIN_EXPLANATION_CHARS);

interface LocalizedExplanationProps {
    readonly localizedExplanationItem: LocalizedExplanationItem;
    readonly allItems: LocalizedExplanationItem[];
    readonly showErrors: boolean;
    readonly isLoading: boolean;
    readonly disabled: boolean;
    readonly editable: boolean;
    readonly decisionType: UnderwritingDecisionLifecycleContext;
    readonly onChange: (item: LocalizedExplanationItem) => void;
    readonly onRemove: () => void;
}

export const LocalizedExplanation: React.FC<LocalizedExplanationProps> = ({
    localizedExplanationItem,
    allItems,
    isLoading,
    disabled,
    editable,
    onChange,
    onRemove,
}) => {
    const otherItems = allItems.filter(item => item.locale !== localizedExplanationItem.locale);

    const localeOptions: SelectOption[] = useMemo(() => {
        return ALL_LOCALES.filter(locale => !otherItems.some(otherItem => otherItem.locale === locale)) // filter out those who exist in other items
            .sort((a, b) => a.localeCompare(b))
            .map(locale => ({
                value: locale,
                id: locale,
                label: locale,
            }));
    }, [otherItems]);

    const form = {
        fields: {
            locale: {
                startValue: localizedExplanationItem.locale ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            chat: {
                startValue: localizedExplanationItem.chat ?? '',
                validations: {
                    charMinLength: {
                        errorMessage: MIN_EXPLANATION_CHARS_MESSAGE,
                        test: validateExplanationMinChars,
                        skipCondition: formValues => isEmpty(formValues.chat),
                    },
                },
            },
            email: {
                startValue: localizedExplanationItem.email ?? '',
                validations: {
                    charMinLength: {
                        errorMessage: MIN_EXPLANATION_CHARS_MESSAGE,
                        test: validateExplanationMinChars,
                        skipCondition: formValues => isEmpty(formValues.email),
                    },
                },
            },
            snail: {
                startValue: localizedExplanationItem.snail ?? '',
                validations: {
                    charMinLength: {
                        errorMessage: MIN_EXPLANATION_CHARS_MESSAGE,
                        test: validateExplanationMinChars,
                        skipCondition: formValues => isEmpty(formValues.snail),
                    },
                },
            },
        },
    } as const satisfies FormProps;

    const { values, setValue, errors } = useForm(form);

    const allExplanationsAreEmpty = values.chat === '' && values.email === '' && values.snail === '';

    function onValueChange<T extends keyof typeof form.fields, K extends (typeof form.fields)[T]['startValue']>(
        key: T,
        value: K
    ): void {
        setValue(key, value);

        onChange({
            ...{
                locale: values.locale as Locale,
                chat: values.chat,
                email: values.email,
                snail: values.snail,
            },
            ...{ [key]: value }, // override changed value
            isValid: null, // will be set by parent
        });
    }

    return (
        <StyledLocaleWrapper key={`item_${localizedExplanationItem.locale}`}>
            <StyledFieldsWrapper>
                {!disabled && editable && otherItems.length > 0 && (
                    <RemoveButton onRemoveClick={onRemove} right="16px" top="16px" />
                )}
                <StyledFormDropdownWrapper label="Locale">
                    <Select
                        disabled={isLoading || disabled || !editable}
                        onOptionSelected={selectedOption => {
                            onValueChange('locale', selectedOption.value as Locale);
                        }}
                        options={localeOptions}
                        placeholder=""
                        value={values.locale}
                        width={200}
                    />
                </StyledFormDropdownWrapper>
                <StyledFormRow
                    errorMessages={[errors.chat?.charMinLength]}
                    label={'Chat Explanation'}
                    showErrors={!isEmpty(errors.chat?.charMinLength ?? '')}
                >
                    <CopyableComponent textToCopy={values.chat} width={'500px'}>
                        <FormTextArea
                            disabled={disabled}
                            onChange={value => onValueChange('chat', value)}
                            value={values.chat}
                            width="100%"
                        />
                    </CopyableComponent>
                </StyledFormRow>
                <StyledFormRow
                    errorMessages={[errors.email?.charMinLength]}
                    label={'Email Bullet'}
                    showErrors={!isEmpty(errors.email?.charMinLength ?? '')}
                >
                    <CopyableComponent textToCopy={values.email} width={'500px'}>
                        <FormTextArea
                            disabled={disabled}
                            onChange={value => onValueChange('email', value)}
                            value={values.email}
                            width={'100%'}
                        />
                    </CopyableComponent>
                </StyledFormRow>
                <StyledFormRow
                    errorMessages={[
                        isDefined(errors.snail?.charMinLength)
                            ? errors.snail.charMinLength
                            : allExplanationsAreEmpty
                              ? 'Draft cannot be saved with all texts missing'
                              : '',
                    ]}
                    label={'Letter (Snail Mail) Bullet'}
                    showErrors={!isEmpty(errors.snail?.charMinLength ?? '') || allExplanationsAreEmpty}
                >
                    <CopyableComponent textToCopy={values.snail} width={'500px'}>
                        <FormTextArea
                            disabled={disabled}
                            onChange={value => onValueChange('snail', value)}
                            value={values.snail}
                            width="100%"
                        />
                    </CopyableComponent>
                </StyledFormRow>
            </StyledFieldsWrapper>
        </StyledLocaleWrapper>
    );
};
