import { TabList as HeadlessTabList } from '@headlessui/react';
import { clsx } from 'clsx';
import type { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { Children, cloneElement, isValidElement, memo, useEffect, useRef, useState } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import { borderRadius } from '../../theme/radius.css';
import { useTabs } from './context';
import { highlightSlider, tabList } from './Tabs.css';

interface TabListProps extends HTMLAttributes<HTMLDivElement> {
  readonly children: ReactNode;
  readonly underline?: boolean;
  readonly selectedIndex: number;
}

const TabListComponent: FC<PropsWithChildren<TabListProps>> = ({
  children,
  underline = false,
  selectedIndex,
  ...props
}) => {
  const { variant, size } = useTabs();
  const refs = useRef<HTMLButtonElement[]>([]);
  const [sliderStyle, setSliderStyle] = useState({
    left: refs.current[selectedIndex]?.offsetLeft,
    width: refs.current[selectedIndex]?.offsetWidth,
  });

  useEffect(() => {
    setSliderStyle({ left: refs.current[selectedIndex]?.offsetLeft, width: refs.current[selectedIndex]?.offsetWidth });
  }, [refs, selectedIndex]);

  return (
    <Flex
      className={clsx(
        tabList({ variant, size, underline: String(variant === 'inline' && underline) }),
        borderRadius({ borderRadius: variant === 'inline' && underline ? undefined : 'sm' }),
      )}
      role="tablist"
      {...props}
    >
      {Children.map(children, (child: ReactNode, index: number) => {
        if (!isValidElement(child)) return child;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return cloneElement(child, {
          index,
          size,
          ref: (node: HTMLButtonElement) => {
            refs.current[index] = node;
          },
          setSliderStyle,
          ...child.props,
        });
      })}
      <Layout
        className={clsx(
          highlightSlider({ variant, size }),
          borderRadius({ borderRadius: variant === 'inline' ? undefined : 'sm' }),
        )}
        left={`${sliderStyle.left}px`}
        width={`${sliderStyle.width}px`}
      />
    </Flex>
  );
};

export const TabList = memo<Omit<PropsWithChildren<TabListProps>, 'selectedIndex'>>(props => (
  <HeadlessTabList>
    {({ selectedIndex }) => <TabListComponent {...props} selectedIndex={selectedIndex} />}
  </HeadlessTabList>
));
