import styled from 'styled-components';
import { Flex, Box } from '@lemonade-hq/cdk';
import { font } from '@lemonade-hq/boutique';
import { themedColor } from '@lemonade-hq/bluis';

export const Container = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    padding: 12px 19px;
    color: ${themedColor('primaryText')};
    border-bottom: 1px solid ${themedColor('separator')};
    transition: background-color 200ms ease-in-out;

    .icon-search-preview {
        position: relative;
        right: 1px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1px;
    }

    &:hover {
        background-color: ${themedColor('elementBackground')};
    }
`;

export const LeftSection = styled(Flex)`
    flex-direction: column;
`;

export const MainInfo = styled(Box)`
    ${font('main', { fontSize: '14px', lineHeight: 'normal', fontWeight: 400 })}
    margin-bottom: 2px;
`;

export const SecondaryInfo = styled(Flex)`
    ${font('main', { fontSize: '12px', lineHeight: 'normal', fontWeight: 400 })}
    align-items: center;
    color: ${themedColor('secondaryText')};
`;

export const SecondaryInfoUpperCase = styled(SecondaryInfo)`
    text-transform: uppercase;
`;

export const SecondaryInfoCapitalize = styled(SecondaryInfo)`
    text-transform: capitalize;
`;

export const EntityDesc = styled(Box)`
    font-size: 12px;
`;
