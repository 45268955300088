import { AddIcon, Spinner } from '@lemonade-hq/bluis';
import { font } from '@lemonade-hq/boutique';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { ThemedColors } from './Colors';

const FileInput = styled.input`
    display: none !important;
`;

const StyledSpinner = styled(Spinner).attrs(() => ({ size: 22 }))`
    margin: 0;
    margin-right: 10px;
`;

export const StyledAddIcon = styled(AddIcon)`
    &&& {
        overflow: visible;
        margin-right: 10px;

        circle {
            transition: fill 0.2s ease-in;
            stroke: ${ThemedColors.primaryColor};
            stroke-width: 1.5px;
            fill: ${ThemedColors.componentBackground};
        }

        #plus {
            transition: fill 0.2s ease-in;
            fill: ${ThemedColors.primaryColor};
        }
    }
`;

const AddFilesButton = styled.button`
    background: transparent;
    border: 0;
    outline: 0;
    pointer-events: all;
    padding: 0;
    margin-left: 36px;
    display: flex;
    align-items: center;
    ${font('main', { fontSize: '14px', lineHeight: '21px' })}
    color: ${ThemedColors.primaryText};

    &:disabled {
        background: transparent;
        color: ${ThemedColors.primaryText};
        pointer-events: none;
    }

    &:hover {
        transition: color 0.2s ease-in;

        ${StyledAddIcon} {
            circle {
                fill: ${ThemedColors.primaryColor};
            }

            #plus {
                fill: ${ThemedColors.componentBackground};
            }
        }

        color: ${ThemedColors.primaryColor};
    }
`;

const Footer = styled.div`
    height: 46px;
    width: 100%;
    background-color: ${ThemedColors.componentBackground};
    box-shadow: 0 -3px 6px -3px ${ThemedColors.separatorColor};
    display: flex;
    z-index: 1;
`;

const AddFilesFooter: React.FC<
    React.PropsWithChildren<{
        readonly onFilesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
        readonly loading?: boolean;
        readonly accept?: string[];
    }>
> = ({ onFilesChange, loading, accept }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    function onAddFilesClick() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <Footer>
            <AddFilesButton disabled={loading} onClick={onAddFilesClick}>
                {loading ? <StyledSpinner /> : <StyledAddIcon />}
                Add more files
            </AddFilesButton>
            <FileInput
                accept={accept ? accept.join(',') : 'image/*,.pdf,application/pdf,.mp3,video/*'}
                multiple
                onChange={onFilesChange}
                ref={fileInputRef}
                type="file"
            />
        </Footer>
    );
};

export default AddFilesFooter;
