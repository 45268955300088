import { useCallback, useEffect, useState } from 'react';

interface UsePaginationReturnValue {
  readonly selectedPage: number;
  readonly handlePageClick: (page: number) => void;
  readonly handleNextClick: () => void;
  readonly handlePrevClick: () => void;
  readonly handleFirstClick: () => void;
  readonly handleLastClick: () => void;
}

export const usePagination = ({
  currentPage,
  totalPages,
}: {
  readonly currentPage: number;
  readonly totalPages: number;
}): UsePaginationReturnValue => {
  const [selectedPage, setSelectedPage] = useState(currentPage);

  useEffect(() => {
    setSelectedPage(currentPage);
  }, [currentPage]);

  const handlePageClick = useCallback((page: number): void => {
    setSelectedPage(page);
  }, []);

  const handleNextClick = useCallback((): void => {
    setSelectedPage(prevPage => prevPage + 1);
  }, []);

  const handlePrevClick = useCallback((): void => {
    setSelectedPage(prevPage => prevPage - 1);
  }, []);

  const handleFirstClick = useCallback((): void => {
    setSelectedPage(1);
  }, []);

  const handleLastClick = useCallback((): void => {
    setSelectedPage(totalPages);
  }, [totalPages]);

  return {
    selectedPage,
    handlePageClick,
    handleNextClick,
    handlePrevClick,
    handleFirstClick,
    handleLastClick,
  };
};
