import { Button } from '@lemonade-hq/blender-ui';
import { useCallback, useState } from 'react';
import { highlightRow } from './animateReorderedRow.css';
import type { Direction } from './Arrows';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useOrderableTable(items: string[], reorder: (item: string, order: number) => Promise<void>) {
    const [isReorderMode, setIsReorderMode] = useState(false);
    const [reorderedItem, setReorderedItem] = useState<string | null>(null);

    const handleReorder = async (item: string, direction: Direction, index: number): Promise<void> => {
        if ((direction === 'Up' && index === 0) || (direction === 'Down' && index === items.length - 1)) return;

        await reorder(item, direction === 'Up' ? index - 1 : index + 1);
        setReorderedItem(item);
    };

    const reorderButton = items.length > 1 && (
        <Button
            label={isReorderMode ? 'Done' : 'Reorder'}
            onClick={() => setIsReorderMode(!isReorderMode)}
            variant="inline"
        />
    );

    const getClassNameForItem = useCallback(
        (item: string): string | undefined => (reorderedItem === item ? highlightRow : undefined),
        [reorderedItem]
    );

    return {
        handleReorder,
        reorderButton,
        isReorderMode,
        getClassNameForItem,
    };
}
