import { Flex, Input, spacing, Text } from '@lemonade-hq/blender-ui';
import { Dialog, FormInputWrapper } from '@lemonade-hq/bluis';
import { useCallback, useState } from 'react';
import { useTestInStagingEnv } from 'queries/LoCo/Insurance/ReleasesQueries';

interface TestInStagingDialogProps {
    readonly releasePublicId: string;
    readonly onClose: () => void;
}

export const TestInStagingDialog: React.FC<TestInStagingDialogProps> = ({ releasePublicId, onClose }) => {
    const [envId, setEnvId] = useState<string>('');
    const { mutateAsync, isPending, error } = useTestInStagingEnv(releasePublicId, envId);

    const publish = useCallback(async () => {
        await mutateAsync(releasePublicId);
        onClose();
    }, [mutateAsync, onClose, releasePublicId]);

    return (
        <Dialog
            actions={[
                { type: 'close', text: 'Cancel', onClick: onClose },
                { type: 'submit', text: 'Save to staging', onClick: publish, disabled: envId === '' },
            ]}
            error={error === null ? undefined : (error as Error).message}
            loading={isPending}
            title="Test release in staging environment"
        >
            <Flex flexDirection="column" gap={spacing.s16}>
                <Text>
                    All Edition Sets included in the Release will be made available in the selected environment with the
                    defined Effective Dates.
                </Text>
                <Text>
                    The Release will be removed from the environment automatically after 30 days or if the Release is
                    canceled, and can be removed manually via Blender at any time.
                </Text>
                <FormInputWrapper label="Test in t-env:">
                    <Input onChange={v => setEnvId(v.target.value)} placeholder="t-env Number" value={envId} />
                </FormInputWrapper>
            </Flex>
        </Dialog>
    );
};
