import * as types from '../actions/actionTypes';

const initialState = {
    loadFailed: false,
    quote: false,
    authorizationError: null,
};

export default function quote(state = initialState, action = {}) {
    const { type, data } = action;

    switch (type) {
        case types.SET_QUOTE: {
            const notifications = Object.keys(data.notifications || {}).map(key => data.notifications[key]);

            return {
                ...state,
                quote: {
                    ...data,
                    notifications,
                },
            };
        }
        case types.SET_QUOTE_LOAD_FAILED: {
            return {
                ...state,
                loadFailed: true,
            };
        }
        case types.SET_QUOTE_AUTHORIZATION_ERROR: {
            return {
                ...state,
                loadFailed: true,
                authorizationError: data,
            };
        }
        case types.SET_QUOTE_NOT_FOUND: {
            return {
                ...state,
                notFound: true,
            };
        }
        default:
            return state;
    }
}
