import type { TableColumn, TableItem } from '@lemonade-hq/blender-ui';
import { Table } from '@lemonade-hq/blender-ui';
import * as styles from './ChangesSummary.css';
import type { UWRuleType } from 'components/LoCo/common/display-texts/underwriting-rules';
import type { UnderwritingDeclineRule, UnderwritingFlagRule } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

export type UnderwritingRules<TRuleType extends UWRuleType> = TRuleType extends 'decline'
    ? UnderwritingDeclineRule[]
    : UnderwritingFlagRule[];

interface UnderwritingRulesTableProps<TRuleType extends UWRuleType> {
    readonly ruleType: TRuleType;
    readonly rules: UnderwritingRules<TRuleType>;
}

export const UnderwritingRulesTable = <TRuleType extends UWRuleType>({
    ruleType,
    rules,
}: UnderwritingRulesTableProps<TRuleType>): JSX.Element => {
    const columns: TableColumn[] = [{ key: 'segment', name: 'segment' }];
    const rows: Record<string, TableItem>[] = rules.map(rule => ({ segment: { value: rule.expression } }));

    if (ruleType === 'decline') {
        const declineRules = rules as UnderwritingDeclineRule[];
        columns.push({ key: 'reason', name: 'Reason' });

        rows.forEach((row, index) => {
            row.outcome = { value: declineRules[index].reasonDisplay };
        });
    }

    return <Table className={styles.table} columns={columns} data={rows} />;
};
