import assert from 'assert';
import type { SelectOptionBase } from '@lemonade-hq/blender-ui';
import { Select } from '@lemonade-hq/blender-ui';
import { ErrorSection } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useCallback, useMemo } from 'react';
import type { Key } from 'react-aria-components';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import type { Edition, EditionWithType } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import {
    useGetProductPublishedEditionSets,
    useGetProductSchemaRevisions,
    useSuspenseGetLatestMinors,
} from 'queries/LoCo/Insurance/ProductQueries';

function isValidForm(
    selectedEditionsCount: number,
    selectedProductSchema: string | null,
    selectedPlatformSchema: string | null,
    isProductFirstRelease: boolean
): boolean {
    if (selectedEditionsCount === 0 && !isDefined(selectedProductSchema) && !isDefined(selectedPlatformSchema)) {
        return false;
    }

    if (!isProductFirstRelease) {
        return true;
    }

    return (
        selectedEditionsCount === Object.keys(EditionType).length &&
        isDefined(selectedProductSchema) &&
        isDefined(selectedPlatformSchema)
    );
}

export interface EditionSelectionData {
    readonly selectedEditions: EditionWithType[];
    readonly selectedPlatformSchema: string | null;
    readonly selectedProductSchema: string | null;
    readonly isValid: boolean;
}

interface EditionSelectInputsProps {
    readonly productCode: string;
    readonly editionSelectionData: DeepNullable<EditionSelectionData>;
    readonly onChange: (newEditionSelectionData: DeepNullable<EditionSelectionData>) => void;
}

export const EditionSelectInputs: React.FC<EditionSelectInputsProps> = ({
    productCode,
    onChange,
    editionSelectionData,
}) => {
    const { data: latestMinors } = useSuspenseGetLatestMinors(productCode);
    const { data: productPublishedEditionSets, isError: isErrorProductPublishedEditionSets } =
        useGetProductPublishedEditionSets(productCode);
    const {
        data: schemaRevisions,
        isPending: isLoadingSchemaRevisions,
        isError: isErrorSchemaRevisions,
    } = useGetProductSchemaRevisions(productCode);

    const allEditionsAreRequired =
        isDefined(productPublishedEditionSets?.data) && !productPublishedEditionSets.data.length;

    const productSchemaOptions = useMemo<SelectOptionBase[]>(() => {
        if (!schemaRevisions) return [];

        return schemaRevisions.productRevisions.map(revision => ({
            id: revision.revision,
            value: revision.revision.toString(),
            label: revision.revision.toString(),
        }));
    }, [schemaRevisions]);

    const platformSchemaOptions = useMemo<SelectOptionBase[]>(() => {
        if (!schemaRevisions) return [];

        return schemaRevisions.platformRevisions.map(revision => ({
            id: revision.revision,
            value: revision.revision.toString(),
            label: revision.revision.toString(),
        }));
    }, [schemaRevisions]);

    const editionsData = useMemo(() => {
        function getEdition(editionType: EditionType): DeepNullable<Edition> | undefined {
            return editionSelectionData.selectedEditions.find(edition => edition.type === editionType);
        }

        return [
            {
                options: latestMinors.coverages,
                type: EditionType.Coverages,
                selectedEdition: getEdition(EditionType.Coverages),
            },
            {
                options: latestMinors.digitalAgent,
                type: EditionType.DigitalAgent,
                selectedEdition: getEdition(EditionType.DigitalAgent),
            },
            {
                options: latestMinors.underwritingFilters,
                type: EditionType.UnderwritingFilters,
                selectedEdition: getEdition(EditionType.UnderwritingFilters),
            },
            {
                options: latestMinors.rating,
                type: EditionType.Rating,
                selectedEdition: getEdition(EditionType.Rating),
            },
        ];
    }, [latestMinors, editionSelectionData]);

    const onEditionSelection = useCallback(
        (editionType: EditionType, editions: Edition[]) => (code: Key | null) => {
            const edition = editions.find(option => option.code === code);
            assert(edition, 'Edition summary not found');

            // take into account selected edition (which is not yet in props as it was just selected)
            const selectedEditionsCount =
                editionSelectionData.selectedEditions.filter(selectedEdition => editionType !== selectedEdition.type)
                    .length + 1;

            const isFormValid = isValidForm(
                selectedEditionsCount,
                editionSelectionData.selectedProductSchema,
                editionSelectionData.selectedPlatformSchema,
                allEditionsAreRequired
            );
            const newSelectedEdition = {
                ...edition,
                type: editionType,
            };
            const newSelectedEditions =
                isDefined(editionSelectionData) && isDefined(editionSelectionData.selectedEditions)
                    ? [
                          ...editionSelectionData.selectedEditions.filter(
                              selectedEdition => editionType !== selectedEdition.type
                          ),
                          newSelectedEdition,
                      ]
                    : [newSelectedEdition];

            onChange({
                ...editionSelectionData,
                selectedEditions: newSelectedEditions,
                isValid: isFormValid,
            });
        },
        [allEditionsAreRequired, editionSelectionData, onChange]
    );

    const onSchemaSelection = useCallback(
        (selectedProductSchema: string | null, selectedPlatformSchema: string | null) => {
            const isFormValid = isValidForm(
                editionSelectionData.selectedEditions.length,
                selectedProductSchema,
                selectedPlatformSchema,
                allEditionsAreRequired
            );

            onChange({
                ...editionSelectionData,
                selectedPlatformSchema,
                selectedProductSchema,
                isValid: isFormValid,
            });
        },
        [allEditionsAreRequired, editionSelectionData, onChange]
    );

    if (isErrorProductPublishedEditionSets || isErrorSchemaRevisions) {
        return <ErrorSection noBorders />;
    }

    return (
        <>
            {editionsData.map(editionsItem => (
                <>
                    <Flex>{getEditionName(editionsItem.type)}</Flex>
                    <Select
                        onChange={onEditionSelection(editionsItem.type, editionsItem.options)}
                        options={editionsItem.options.map(option => ({
                            label: `${option.friendlyName} (${option.version})`,
                            value: option.code,
                        }))}
                        placeholder="Select Edition"
                        selectedKey={editionsItem.selectedEdition?.code ?? undefined}
                    />
                </>
            ))}
            <Flex>Product Schema</Flex>
            <Select
                disabled={isLoadingSchemaRevisions}
                onChange={value => onSchemaSelection(value as string, editionSelectionData.selectedPlatformSchema)}
                options={productSchemaOptions}
                placeholder="Select Schema"
                selectedKey={editionSelectionData.selectedProductSchema ?? undefined}
            />
            <Flex>Platform Schema</Flex>
            <Select
                disabled={isLoadingSchemaRevisions}
                onChange={value => onSchemaSelection(editionSelectionData.selectedProductSchema, value as string)}
                options={platformSchemaOptions}
                placeholder="Select Schema"
                selectedKey={editionSelectionData.selectedPlatformSchema ?? undefined}
            />
        </>
    );
};
