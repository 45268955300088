import { Flex } from '@lemonade-hq/blender-ui';
import { EmptySection } from '@lemonade-hq/bluis';
import type { ComponentProps } from 'react';
import { rulesCountDisplay } from 'components/LoCo/common/components/CoverageRules/CoverageRulesShared';
import { SettingInstanceValues } from 'components/LoCo/common/components/CoverageSettings/SettingValues';
import type { ColumnsToRow } from 'components/LoCo/common/components/GridTable/GridTable';
import { GridTable } from 'components/LoCo/common/components/GridTable/GridTable';
import { unitDisplay } from 'components/LoCo/common/display-texts/setting-instance';
import type { SettingInstancesTableProps } from 'components/LoCo/editions/coverage-editions/Settings/SettingInstancesTable/SettingsTableShared';
import {
    scopeDisplay,
    SettingInstanceActions,
    SettingInstanceDuration,
    SettingName,
    SettingsTableExpendedDetails,
    SubHeader,
    SubHeaderWrapper,
} from 'components/LoCo/editions/coverage-editions/Settings/SettingInstancesTable/SettingsTableShared';
import type { LimitSettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import { isSubLimitSettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';

const limitTableColumns = [
    { key: 'name', title: 'Setting Name', width: '2fr' },
    { key: 'values', title: 'Values', width: '1.5fr' },
    { key: 'unit', title: 'Unit', width: '0.7fr' },
    { key: 'scope', title: 'Scope', width: '1fr' },
    { key: 'duration', title: 'Duration', width: '1fr' },
    { key: 'filedRules', title: 'Filed Rules', width: '120px' },
    { key: 'actions', title: '', width: '40px' },
] as const;

function getLimitRow(
    settingInstance: LimitSettingInstance,
    hideActions: boolean,
    onActionRequested: ComponentProps<typeof SettingInstanceActions>['onActionRequested']
): ColumnsToRow<typeof limitTableColumns> {
    return {
        name: {
            value: (
                <SubHeaderWrapper>
                    <SettingName settingInstance={settingInstance} />
                    {isSubLimitSettingInstance(settingInstance) && (
                        <SubHeader style={{ color: 'gray' }}>Sub-Limit of: {settingInstance.parentLimitName}</SubHeader>
                    )}
                </SubHeaderWrapper>
            ),
        },
        values: {
            value: (
                <Flex flexDirection="column" width={'100%'}>
                    <SettingInstanceValues settingInstance={settingInstance} />
                </Flex>
            ),
        },
        unit: { value: unitDisplay(settingInstance) },
        scope: { value: scopeDisplay(settingInstance) },
        duration: { value: <SettingInstanceDuration settingInstance={settingInstance} /> },
        filedRules: { value: rulesCountDisplay(settingInstance.relatedRules) },
        actions: {
            value: (
                <SettingInstanceActions
                    hideActions={hideActions}
                    instance={settingInstance}
                    onActionRequested={onActionRequested}
                />
            ),
        },
    };
}

export const LimitTable: React.FC<SettingInstancesTableProps<LimitSettingInstance>> = ({
    settingInstances,
    coveragesEdition,
    setDialog,
    hideActions,
}) => {
    return settingInstances.length === 0 ? (
        <EmptySection>No Limit settings configured</EmptySection>
    ) : (
        <GridTable
            columns={limitTableColumns}
            rows={settingInstances.map(settingInstance => ({
                values: getLimitRow(settingInstance, hideActions, dialogType =>
                    setDialog({
                        type: dialogType,
                        data: settingInstance,
                        editionCoverages: coveragesEdition.coverages,
                    })
                ),
                expended: (
                    <SettingsTableExpendedDetails
                        editionCode={coveragesEdition.code}
                        hideActions={hideActions}
                        settingInstance={settingInstance}
                    />
                ),
            }))}
        />
    );
};
