import type { Locale } from '@lemonade-hq/lemonation';
import { isDefined } from '@lemonade-hq/ts-helpers';
import type { UseMutationResult, UseQueryResult, UseSuspenseQueryResult } from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import qs from 'qs';
import { insuranceBlender } from '../../../apiClients';
import type { Pagination } from './QueriesShared';
import { useSuspenseQueryWithErrorHandling } from 'components/LoCo/common/hooks/useSuspenseQueryWithErrorHandling';
import { UnderwritingEntityType } from 'components/LoCo/global-registry/underwriting-registry/underwritingUtils';
import type { PaginationResult } from 'models/CarShared';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type {
    Explanation,
    ExplanationContent,
    ExplanationSummary,
    ImpactedResources,
    Reason,
    ReasonViolationsWrapper,
    UnderwritingDecisionFlag,
} from 'models/LoCo/Insurance/UnderwritingRegistry';
import { usePessimisticMutation } from 'queries/MutationHooks';

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE = 1;

const DEFAULT_PAGINATION: Pagination = {
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
};

export interface SpecificExplanationSchema {
    readonly explanationCode: string;
    readonly state?: string;
    readonly country: string;
}

type UpdateProductLinesRequest = {
    readonly productLineCodes: string[];
};

interface AddReasonRequest {
    readonly name: string;
    readonly note: string;
    readonly defaultExplanationCode?: string;
    readonly type: UnderwritingDecisionLifecycleContext;
    readonly specificExplanations: SpecificExplanationSchema[];
    readonly productLineCodes: string[];
}

interface UpdateReasonRequest {
    readonly note: string;
    readonly defaultExplanationCode?: string;
    readonly type: UnderwritingDecisionLifecycleContext;
    readonly specificExplanations?: SpecificExplanationSchema[];
}

interface AddExplanationRequest {
    readonly name: string;
    readonly note: string;
    readonly type: UnderwritingDecisionLifecycleContext;
    readonly productLineCodes: string[];
    readonly explanationDetails: Partial<Record<Locale, ExplanationContent>>;
}

interface AddFlagRequest {
    readonly name: string;
    readonly description: string;
    readonly productLineCodes: string[];
}

interface UpdateFlagRequest {
    readonly description: string;
    readonly productLineCodes: string[];
}

interface UpdateExplanationRequest {
    readonly note: string;
    readonly explanationDetails: Partial<Record<Locale, ExplanationContent>>;
}

type RequestWithPublicId<T> = { readonly publicId: string; readonly data: T };

const UNDERWRITING_REGISTRY_BASE_PATH = '/api/v1/registry/underwriting';
const REASONS_BASE_PATH = `${UNDERWRITING_REGISTRY_BASE_PATH}/reasons`;
const EXPLANATIONS_BASE_PATH = `${UNDERWRITING_REGISTRY_BASE_PATH}/explanations`;

export enum RegistryQueryKey {
    GetRegistryReasons = 'GET_REGISTRY_REASONS',
    GetRegistryExplanationsSummaries = 'GET_REGISTRY_EXPLANATIONS_SUMMARIES',
    CreateRegistryReason = 'CREATE_REGISTRY_REASON',
    UpdateRegistryReason = 'UPDATE_REGISTRY_REASON',
    ArchiveRegistryReasonDraft = 'ARCHIVE_REGISTRY_REASON_DRAFT',
    CloneRegistryReason = 'CLONE_REGISTRY_REASON',
    PublishRegistryReasonDraft = 'PUBLISH_REGISTRY_REASON_DRAFT',
    GetAffectedProductsForPublishReasonDraft = 'GET_AFFECTED_PRODUCTS_FOR_PUBLISH_REASON_DRAFT',
    GetRegistryExplanations = 'GET_REGISTRY_EXPLANATIONS',
    ArchiveRegistryExplanationDraft = 'ARCHIVE_REGISTRY_EXPLANATION_DRAFT',
    PublishRegistryExplanationDraft = 'PUBLISH_REGISTRY_EXPLANATION_DRAFT',
    CreateRegistryExplanation = 'CREATE_REGISTRY_EXPLANATION',
    GetExplanationImpactedResources = 'GET_EXPLANATION_IMPACTED_RESOURCES',
    UpdateRegistryProductLines = 'UPDATE_REGISTRY_PRODUCT_LINES',
    UpdateRegistryExplanation = 'UPDATE_REGISTRY_EXPLANATION',
    CloneRegistryExplanation = 'CLONE_REGISTRY_EXPLANATION',
    GetRegistryFlags = 'GET_REGISTRY_FLAGS',
    CreateRegistryFlag = 'CREATE_REGISTRY_FLAG',
    UpdateRegistryFlag = 'UPDATE_REGISTRY_FLAG',
    GetReasonViolations = 'GET_REASON_VIOLATIONS',
}

export async function getRegistryReasons({
    decisionType,
    page,
    size,
    productLineCode,
}: {
    readonly decisionType: UnderwritingDecisionLifecycleContext;
    readonly page?: number;
    readonly size?: number;
    readonly productLineCode?: string;
}): Promise<PaginationResult<Reason>> {
    const query = qs.stringify({
        type: decisionType,
        page,
        size,
        productLineCode,
    });

    return await insuranceBlender
        .get<PaginationResult<Reason>>(`${REASONS_BASE_PATH}?${query}`)
        .then(response => response.data);
}

export function useSuspenseGetRegistryReasons(
    decisionType: UnderwritingDecisionLifecycleContext,
    { page = DEFAULT_PAGE, size = DEFAULT_PAGE_SIZE }: Pagination = DEFAULT_PAGINATION
): UseSuspenseQueryResult<PaginationResult<Reason>, unknown> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [RegistryQueryKey.GetRegistryReasons, decisionType, page, size],
        queryFn: async () => await getRegistryReasons({ decisionType, page, size }),
    });
}

export async function getRegistryExplanationSummaries(
    decisionType: UnderwritingDecisionLifecycleContext,
    productLineCodes: string[]
): Promise<ExplanationSummary[]> {
    const query = qs.stringify({
        type: decisionType,
        productLineCodes,
    });

    return await insuranceBlender
        .get<{ data: ExplanationSummary[] }>(`${EXPLANATIONS_BASE_PATH}/summaries?${query}`)
        .then(response => response.data.data);
}

export function useGetRegistryExplanationSummaries(
    decisionType: UnderwritingDecisionLifecycleContext,
    productLineCodes: string[]
): UseQueryResult<ExplanationSummary[], unknown> {
    return useQuery({
        queryKey: [RegistryQueryKey.GetRegistryExplanationsSummaries, decisionType, productLineCodes],
        queryFn: async () => await getRegistryExplanationSummaries(decisionType, productLineCodes),
        enabled: productLineCodes.length > 0,
    });
}

export async function createReason(data: AddReasonRequest): Promise<void> {
    await insuranceBlender.post<{ data: AddReasonRequest }>(REASONS_BASE_PATH, data);
}

export function useCreateReason(): UseMutationResult<void, unknown, AddReasonRequest, null> {
    return usePessimisticMutation({
        mutationFn: createReason,
        invalidateKeys: [[RegistryQueryKey.GetRegistryReasons]],
        mutationKey: [RegistryQueryKey.CreateRegistryReason],
    });
}

export async function updateReason(request: RequestWithPublicId<UpdateReasonRequest>): Promise<void> {
    await insuranceBlender.put<{ data: UpdateReasonRequest }>(`${REASONS_BASE_PATH}/${request.publicId}`, request.data);
}

export function useUpdateReason(publicId: string): UseMutationResult<void, unknown, UpdateReasonRequest, null> {
    return usePessimisticMutation({
        mutationFn: async (request: UpdateReasonRequest) => await updateReason({ publicId, data: request }),
        invalidateKeys: [[RegistryQueryKey.GetRegistryReasons]],
        mutationKey: [RegistryQueryKey.UpdateRegistryReason],
    });
}

async function archiveReasonDraft(publicId: string): Promise<void> {
    await insuranceBlender.put(`${REASONS_BASE_PATH}/${publicId}/archive`);
}

export function useArchiveReasonDraft(): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: archiveReasonDraft,
        invalidateKeys: [[RegistryQueryKey.GetRegistryReasons]],
        mutationKey: [RegistryQueryKey.ArchiveRegistryReasonDraft],
    });
}

export async function cloneReason(request: RequestWithPublicId<UpdateReasonRequest>): Promise<void> {
    await insuranceBlender.post<{
        data: UpdateReasonRequest;
    }>(`${REASONS_BASE_PATH}/${request.publicId}/clone`, request.data);
}

export function useCloneReason(publicId: string): UseMutationResult<void, unknown, UpdateReasonRequest, null> {
    return usePessimisticMutation({
        mutationFn: async (request: UpdateReasonRequest) => await cloneReason({ publicId, data: request }),
        invalidateKeys: [[RegistryQueryKey.GetRegistryReasons]],
        mutationKey: [RegistryQueryKey.CloneRegistryReason],
    });
}

async function publishReasonDraft(publicId: string): Promise<void> {
    await insuranceBlender.post(`${REASONS_BASE_PATH}/${publicId}/publish`);
}

async function updateUnderwritingProductLines(
    entityCode: string,
    entityType: UnderwritingEntityType,
    request: UpdateProductLinesRequest
): Promise<void> {
    const entityPath = entityType === UnderwritingEntityType.Explanation ? 'explanations' : 'reasons';

    await insuranceBlender.put<{
        data: { productLineCodes: string[] };
    }>(`${UNDERWRITING_REGISTRY_BASE_PATH}/${entityPath}/product-lines/${entityCode}`, request);
}

export function useUpdateUnderwritingProductLines(
    entityCode: string,
    entityType: UnderwritingEntityType
): UseMutationResult<void, unknown, UpdateProductLinesRequest, null> {
    return usePessimisticMutation({
        mutationFn: async (request: UpdateProductLinesRequest) =>
            await updateUnderwritingProductLines(entityCode, entityType, {
                productLineCodes: request.productLineCodes,
            }),
        invalidateKeys: [
            entityType === UnderwritingEntityType.Explanation
                ? [[RegistryQueryKey.GetRegistryExplanations], [RegistryQueryKey.GetRegistryExplanationsSummaries]]
                : [RegistryQueryKey.GetRegistryReasons],
        ],

        mutationKey: [RegistryQueryKey.UpdateRegistryProductLines],
    });
}

export function usePublishReasonDraft(): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: publishReasonDraft,
        invalidateKeys: [[RegistryQueryKey.GetRegistryReasons]],
        mutationKey: [RegistryQueryKey.PublishRegistryReasonDraft],
    });
}

async function getAffectedProductsForPublishReasonDraft(code: string): Promise<string[]> {
    return await insuranceBlender
        .get<{ data: { productNames: string[] } }>(`${REASONS_BASE_PATH}/${code}/affected-products`)
        .then(response => response.data.data.productNames);
}

export function useSuspenseGetAffectedProductsForPublishReasonDraft(
    code: string
): UseSuspenseQueryResult<string[], unknown> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [RegistryQueryKey.GetAffectedProductsForPublishReasonDraft],
        queryFn: async () => await getAffectedProductsForPublishReasonDraft(code),
        gcTime: 0, // fetch fresh data to avoid stale cache (e.g. caused by product changes)
    });
}

export async function getRegistryExplanations(
    decisionType: UnderwritingDecisionLifecycleContext,
    page: number,
    size: number
): Promise<PaginationResult<Explanation>> {
    const query = qs.stringify({
        type: decisionType,
        page,
        size,
    });

    return await insuranceBlender
        .get<PaginationResult<Explanation>>(`${EXPLANATIONS_BASE_PATH}?${query}`)
        .then(response => response.data);
}

export function useGetRegistryExplanations(
    decisionType: UnderwritingDecisionLifecycleContext,
    { page = DEFAULT_PAGE, size = DEFAULT_PAGE_SIZE }: Pagination = DEFAULT_PAGINATION,
    enabled = true
): UseQueryResult<PaginationResult<Explanation>, unknown> {
    return useQuery({
        queryKey: [RegistryQueryKey.GetRegistryExplanations, decisionType, page, size],
        queryFn: async () => await getRegistryExplanations(decisionType, page, size),
        enabled,
    });
}

async function archiveExplanationDraft(publicId: string): Promise<void> {
    await insuranceBlender.put(`${EXPLANATIONS_BASE_PATH}/${publicId}/archive`);
}

export function useArchiveExplanationDraft(): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: archiveExplanationDraft,
        invalidateKeys: [
            [RegistryQueryKey.GetRegistryExplanations],
            [RegistryQueryKey.GetRegistryExplanationsSummaries],
        ],
        mutationKey: [RegistryQueryKey.ArchiveRegistryExplanationDraft],
    });
}

async function publishExplanationDraft(publicId: string): Promise<void> {
    await insuranceBlender.post(`${EXPLANATIONS_BASE_PATH}/${publicId}/publish`);
}

export function usePublishExplanationDraft(): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: publishExplanationDraft,
        invalidateKeys: [
            [RegistryQueryKey.GetRegistryExplanations],
            [RegistryQueryKey.GetRegistryExplanationsSummaries],
        ],
        mutationKey: [RegistryQueryKey.PublishRegistryExplanationDraft],
    });
}

export async function createExplanation(request: AddExplanationRequest): Promise<void> {
    await insuranceBlender.post<{ data: AddExplanationRequest }>(EXPLANATIONS_BASE_PATH, request);
}

export function useCreateExplanation(): UseMutationResult<void, unknown, AddExplanationRequest, null> {
    return usePessimisticMutation({
        mutationFn: async (request: AddExplanationRequest) => await createExplanation(request),
        invalidateKeys: [
            [RegistryQueryKey.GetRegistryExplanations],
            [RegistryQueryKey.GetRegistryExplanationsSummaries],
        ],
        mutationKey: [RegistryQueryKey.CreateRegistryExplanation],
    });
}

export async function updateExplanation(request: RequestWithPublicId<UpdateExplanationRequest>): Promise<void> {
    await insuranceBlender.put<{
        data: UpdateExplanationRequest;
    }>(`${EXPLANATIONS_BASE_PATH}/${request.publicId}`, request.data);
}

export function useUpdateExplanation(
    publicId: string
): UseMutationResult<void, unknown, UpdateExplanationRequest, null> {
    return usePessimisticMutation({
        mutationFn: async (request: UpdateExplanationRequest) => await updateExplanation({ publicId, data: request }),
        invalidateKeys: [
            [RegistryQueryKey.GetRegistryExplanations],
            [RegistryQueryKey.GetRegistryExplanationsSummaries],
        ],
        mutationKey: [RegistryQueryKey.CreateRegistryExplanation],
    });
}

export async function cloneExplanation(request: RequestWithPublicId<UpdateExplanationRequest>): Promise<void> {
    await insuranceBlender.post<{
        data: UpdateExplanationRequest;
    }>(`${EXPLANATIONS_BASE_PATH}/${request.publicId}/clone`, request.data);
}

export function useCloneExplanation(
    publicId: string
): UseMutationResult<void, unknown, UpdateExplanationRequest, null> {
    return usePessimisticMutation({
        mutationFn: async (request: UpdateExplanationRequest) => await cloneExplanation({ publicId, data: request }),
        invalidateKeys: [
            [RegistryQueryKey.GetRegistryExplanations],
            [RegistryQueryKey.GetRegistryExplanationsSummaries],
        ],
        mutationKey: [RegistryQueryKey.CloneRegistryExplanation],
    });
}

async function getExplanationImpactedResources(publicId: string): Promise<ImpactedResources> {
    return await insuranceBlender
        .get<{
            data: ImpactedResources;
        }>(`${UNDERWRITING_REGISTRY_BASE_PATH}/explanations/${publicId}/impacted-resources`)
        .then(response => response.data.data);
}

export function useSuspenseGetExplanationImpactedResources(
    publicId: string
): UseSuspenseQueryResult<ImpactedResources, unknown> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [RegistryQueryKey.GetExplanationImpactedResources, publicId],
        queryFn: async () => await getExplanationImpactedResources(publicId),
        gcTime: 0, // fetch fresh data to avoid stale cache (e.g. caused by reason updates)
    });
}

async function getRegistryFlags(page: number, size: number): Promise<PaginationResult<UnderwritingDecisionFlag>> {
    const query = qs.stringify({
        page,
        size,
    });
    return await insuranceBlender
        .get<PaginationResult<UnderwritingDecisionFlag>>(`${UNDERWRITING_REGISTRY_BASE_PATH}/flags?${query}`)
        .then(response => response.data);
}

export function useSuspenseGetRegistryFlags({
    page = DEFAULT_PAGE,
    size = DEFAULT_PAGE_SIZE,
}: Pagination = DEFAULT_PAGINATION): UseSuspenseQueryResult<PaginationResult<UnderwritingDecisionFlag>, unknown> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [RegistryQueryKey.GetRegistryFlags, page, size],
        queryFn: async () => await getRegistryFlags(page, size),
    });
}

export async function getRegistryFlagsByProductLine(productLineCode: string): Promise<UnderwritingDecisionFlag[]> {
    return await insuranceBlender
        .get<{ data: UnderwritingDecisionFlag[] }>(`api/v1/registry/product-lines/${productLineCode}/flags`)
        .then(response => response.data.data);
}

export function useSuspenseGetRegistryFlagsByProductLine(
    productLineCode: string
): UseSuspenseQueryResult<UnderwritingDecisionFlag[], unknown> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [RegistryQueryKey.GetRegistryFlags, productLineCode],
        queryFn: async () => await getRegistryFlagsByProductLine(productLineCode),
    });
}

export async function createFlag(request: AddFlagRequest): Promise<void> {
    await insuranceBlender.post<{
        data: AddFlagRequest;
    }>(`${UNDERWRITING_REGISTRY_BASE_PATH}/flags`, request);
}

export function useCreateFlag(): UseMutationResult<void, unknown, AddFlagRequest, null> {
    return usePessimisticMutation({
        mutationFn: async (request: AddFlagRequest) => await createFlag(request),
        invalidateKeys: [[RegistryQueryKey.GetRegistryFlags]],
        mutationKey: [RegistryQueryKey.CreateRegistryFlag],
    });
}

export async function updateFlag(code: string | undefined, request: UpdateFlagRequest): Promise<void> {
    if (isDefined(code)) {
        await insuranceBlender.put<{
            data: UpdateFlagRequest;
        }>(`${UNDERWRITING_REGISTRY_BASE_PATH}/flags/${code}`, request);
    } else {
        await Promise.reject(new Error('Code must be defined'));
    }
}

export function useUpdateFlag(code: string | undefined): UseMutationResult<void, unknown, UpdateFlagRequest, null> {
    return usePessimisticMutation({
        mutationFn: async (request: UpdateFlagRequest) => await updateFlag(code, request),
        invalidateKeys: [[RegistryQueryKey.GetRegistryFlags]],
        mutationKey: [RegistryQueryKey.CreateRegistryFlag],
    });
}

async function getReasonViolations(publicId: string): Promise<ReasonViolationsWrapper> {
    return await insuranceBlender
        .get<{
            data: ReasonViolationsWrapper;
        }>(`${UNDERWRITING_REGISTRY_BASE_PATH}/reasons/${publicId}/violations`)
        .then(response => response.data.data);
}

export function useSuspenseGetReasonViolations(
    publicId: string
): UseSuspenseQueryResult<ReasonViolationsWrapper, unknown> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [RegistryQueryKey.GetReasonViolations, publicId],
        queryFn: async () => await getReasonViolations(publicId),
        gcTime: 0, // fetch fresh data to avoid stale cache (e.g. caused by explanation/reason updates)
    });
}

export function useGetSuspenseRegistryReasonsByProductLine(
    productLineCode: string,
    type: UnderwritingDecisionLifecycleContext
): UseSuspenseQueryResult<PaginationResult<Reason>, unknown> {
    return useSuspenseQuery({
        queryKey: [RegistryQueryKey.GetRegistryReasons, productLineCode],
        queryFn: async () =>
            await getRegistryReasons({
                productLineCode,
                decisionType: type,
            }),
    });
}
