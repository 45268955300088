import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { Text } from '@lemonade-hq/cdk';
import { useMemo } from 'react';
import { useClearEnvAvailability } from 'queries/LoCo/Insurance/EditionSetQueries';

const useDeleteButtons = (onClose: () => void, onSubmit: () => Promise<void>, isLoading: boolean): DialogAction[] => {
    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Remove',
                type: 'submit',
                onClick: onSubmit,
                disabled: isLoading,
            },
        ],
        [isLoading, onClose, onSubmit]
    );

    return actions;
};

type RemoveEnvDialogProps = {
    readonly productCode: string;
    readonly editionSetCode: string;
    readonly envId: string;
    readonly onClose: () => void;
};

export const RemoveEnvDialog: React.FC<RemoveEnvDialogProps> = ({ onClose, productCode, editionSetCode, envId }) => {
    const { mutateAsync, isError, isPending } = useClearEnvAvailability(productCode);
    const actions = useDeleteButtons(
        onClose,
        async () => {
            await mutateAsync({
                editionSetCode,
                envId,
            });

            onClose();
        },
        isPending
    );

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={isError ? 'Failed to remove release from environment' : undefined}
            loading={isPending}
            onClose={onClose}
            title={'Remove Edition Set from Environment'}
        >
            <Text variant="body-md">{`Are you sure you want to remove the edition set from ${envId} Staging environment?`}</Text>
        </Dialog>
    );
};
