import { ManageReleaseNoteDialog } from './ManageReleaseNoteDialog';

export enum DialogType {
    EditNote = 'edit-note',
}

export type DialogData = {
    readonly type: DialogType.EditNote;
    readonly releasePublicId: string;
    readonly note: string | undefined;
};

export interface ReleaseDetailsDialogsProps {
    readonly dialogData: DialogData;
    readonly onClose: () => void;
}

export const ReleaseDetailsDialogs: React.FC<ReleaseDetailsDialogsProps> = ({ dialogData, onClose }) => {
    switch (dialogData.type) {
        case DialogType.EditNote:
            return (
                <ManageReleaseNoteDialog
                    initialNote={dialogData.note ?? ''}
                    onClose={onClose}
                    releasePublicId={dialogData.releasePublicId}
                />
            );
        default:
            return null;
    }
};
