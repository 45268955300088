import { InfoIcon, InfoOutlinedIcon, Radio } from '@lemonade-hq/bluis';
import { Flex, Tooltip } from '@lemonade-hq/cdk';
import { useMemo } from 'react';
import {
    dialogStepItemsWrapper,
    labelWithTooltip,
    NoticeWrapper,
    stepTitle,
    stepWrapper,
    tooltipContent,
} from './ManageReleaseDialog.css';
import { DialogStep, DialogStepWrapper } from 'components/LoCo/common/components/Dialog/Dialog';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import type { EditionWithType } from 'models/LoCo/Insurance/BaseEdition';
import { VersionType } from 'models/LoCo/Insurance/BaseEdition';

function getSingleMinorNotice({ type, versionMajor, version }: EditionWithType): JSX.Element {
    const editionName = getEditionName(type);
    return (
        <>
            {editionName} {version} can be released as a product update for existing quotes and policies associated with
            any {editionName} edition with a {versionMajor}.x version.
            <br />A list of these quotes and policies will be available in the rollout insights report for this release.
        </>
    );
}

function getMultipleMinorNotice(selectedEditions: EditionWithType[]): JSX.Element {
    return (
        <ul>
            {selectedEditions.map(edition => (
                <li key={edition.type}>
                    {`Any ${getEditionName(edition.type)} edition with a ${edition.versionMajor}.x version`}
                </li>
            ))}
        </ul>
    );
}

function getBannerText(selectedEditions: EditionWithType[], versionType: VersionType | null): JSX.Element | null {
    const minorEditions = selectedEditions.filter(edition => edition.versionType === VersionType.Minor);
    switch (versionType) {
        case VersionType.Major:
            if (minorEditions.length > 0) {
                return (
                    <>
                        <b>Please note: </b>
                        {
                            'Some of the selected editions were approved as minor versions. When applying this rollout strategy, they will not be made available to existing quotes or policies.'
                        }
                    </>
                );
            }

            return <>{getSingleMinorNotice(selectedEditions[0])}</>;

            break;
        case VersionType.Minor:
            if (selectedEditions.length === 1) {
                return <>{getSingleMinorNotice(selectedEditions[0])}</>;
            }

            return (
                <>
                    {
                        'The selected editions can be released as a product update for existing quotes and policies with all of the following:'
                    }
                    {getMultipleMinorNotice(selectedEditions)}
                    {
                        'A list of these quotes and pPolicies will be available in the rollout insights report for this release.'
                    }
                </>
            );

        default:
            break;
    }

    return null;
}

interface RolloutStrategyProps {
    readonly selectedEditions: EditionWithType[];
    readonly selectedStrategy: VersionType | null;
    readonly onChange: (rolloutStrategy: VersionType) => void;
}

export const RolloutStrategy: React.FC<RolloutStrategyProps> = ({ selectedStrategy, selectedEditions, onChange }) => {
    const strategies = useMemo(
        () => [
            {
                title: 'New Business & Renewals only',
                type: VersionType.Major,
                tooltipContent: [
                    'Once effective, the edition(s) in the release will be used when creating new quotes and renewal policies,',
                    'but will not impact existing quotes or policies (until renewal) that were created earlier.',
                ],
                enabled: true,
            },
            {
                title: 'Product Update for Existing Quotes and Policies',
                type: VersionType.Minor,
                tooltipContent: [
                    'Once effective, the edition(s) in the release will become available as new options for existing quotes and policies',
                    'of the same major edition series - this will not change anything about these entities unless they are edited.',
                ],
                enabled: selectedEditions.every(edition => edition.versionType === VersionType.Minor),
            },
            {
                title: 'Bug Fix for Existing Quotes and Policies',
                type: VersionType.BugFix,
                tooltipContent: [
                    'The edition(s) in the release carry corrections for previous editions.',
                    'The changes should be applied to existing quotes and policies from the impacted editions.',
                ],
                enabled: selectedEditions.every(edition => edition.versionType === VersionType.BugFix),
            },
        ],
        [selectedEditions]
    );

    const bannerText = getBannerText(selectedEditions, selectedStrategy);

    return (
        <DialogStepWrapper>
            <DialogStep padding="40px 64px">
                <Flex className={stepWrapper}>
                    <Flex className={stepTitle}>What is the intended rollout strategy for this release?</Flex>
                    <Flex className={dialogStepItemsWrapper}>
                        {strategies.map(strategy => (
                            <Flex className={labelWithTooltip} key={strategy.type}>
                                <Radio
                                    checked={selectedStrategy === strategy.type}
                                    disabled={!strategy.enabled}
                                    id={strategy.type}
                                    name="strategy-type"
                                    onChange={() => onChange(strategy.type)}
                                >
                                    {strategy.title}
                                </Radio>
                                <Tooltip
                                    alignment="bottom"
                                    content={
                                        <Flex className={tooltipContent}>
                                            {strategy.tooltipContent.map((tooltipContentItem, index) => (
                                                // the array does not change, and there's no unique key to use
                                                // eslint-disable-next-line react/no-array-index-key
                                                <div key={`tooltipItem${index}`}>{tooltipContentItem}</div>
                                            ))}
                                        </Flex>
                                    }
                                >
                                    <Flex>
                                        <InfoOutlinedIcon />
                                    </Flex>
                                </Tooltip>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </DialogStep>
            {bannerText && (
                <Flex className={NoticeWrapper}>
                    <InfoIcon />
                    <div>{bannerText}</div>
                </Flex>
            )}
        </DialogStepWrapper>
    );
};
