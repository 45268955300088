import type { IconName } from '@lemonade-hq/blender-ui';

export const OLDER_TOOL = 'older';
export const NEWER_TOOL = 'newer';

export type ComparisonType = typeof NEWER_TOOL | typeof OLDER_TOOL;

export function getComparisonIcon(comparisonType: ComparisonType): IconName {
    return comparisonType === NEWER_TOOL ? 'dashed-circle' : 'dashed-circle-with-bullet';
}
