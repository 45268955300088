import axios from 'axios';
import { PendingReason, ProductTypes, TaskStatus } from '@lemonade-hq/bluiza';
import { AdjusterPermission } from 'models/AdjusterPermission';
import { AssignableAdjusters } from 'models/AssignableOperators';
import { getUrlResolver } from 'commons/UrlResolver';
import { StatusDescriptionResponse } from 'models/StatusDescription';

const blenderGeneralUrlResolver = getUrlResolver('blender-general');

export async function markAsDone({ taskId }: { taskId: string }): Promise<null> {
    const url = `/backoffice/workflows/tasks/${taskId}/done`;

    return axios.post(url).then(response => response.data.data);
}
export async function reopenTask({ taskId }: { taskId: string }): Promise<null> {
    const url = `/backoffice/workflows/tasks/${taskId}/reopen`;

    return axios.post(url).then(response => response.data.data);
}

export async function dismiss({ taskId, reason }: { taskId: string; reason: string }): Promise<null> {
    const url = `/backoffice/workflows/tasks/${taskId}/dismiss`;

    return axios.post(url, { reason }).then(response => response.data.data);
}

export async function getAssignableOperators(
    productType: ProductTypes,
    taskPublicId: string
): Promise<AssignableAdjusters> {
    const url = blenderGeneralUrlResolver(
        `/api/v1/workflows/assignable_operators?productType=${productType}&taskPublicId=${taskPublicId}`
    );

    return axios.get<{ data: AssignableAdjusters }>(url).then(response => response.data.data);
}

export async function getAssignableOperatorsByTaskType(
    productType: ProductTypes,
    taskType: string
): Promise<AssignableAdjusters> {
    const url = blenderGeneralUrlResolver(
        `/api/v1/workflows/assignable_operators?productType=${productType}&taskType=${taskType}`
    );

    return axios.get<{ data: AssignableAdjusters }>(url).then(response => response.data.data);
}

export async function assign({ taskId, operatorId }: { taskId: string; operatorId: string | null }): Promise<null> {
    const url = `/backoffice/workflows/tasks/${taskId}/assign`;
    const response = await axios.post(url, { operatorPublicId: operatorId === '' ? null : operatorId });

    return response.data.data;
}

export async function assignTaskToSquad({
    taskPublicId,
    squad,
}: {
    taskPublicId: string;
    squad: string;
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/assign_task_to_squad`);

    return axios.post(url, { taskPublicId, squad });
}

export async function getAdjusterPermissions(squad: string | null): Promise<AdjusterPermission[]> {
    const url = blenderGeneralUrlResolver(`/api/v1/clx_permissions/${squad}`);

    return axios.get<{ data: AdjusterPermission[] }>(url).then(response => response.data.data);
}

export async function getAvailableRoles(): Promise<string[]> {
    const url = blenderGeneralUrlResolver(`/api/v1/clx_permissions/available_roles`);

    return axios.get<{ data: string[] }>(url).then(response => response.data.data);
}

export async function getAvailableSquads(): Promise<string[]> {
    const url = blenderGeneralUrlResolver(`/api/v1/clx_permissions/available_squads`);

    return axios.get<{ data: string[] }>(url).then(response => response.data.data);
}

export async function getAvailableTaskTypes(): Promise<string[]> {
    const url = blenderGeneralUrlResolver(`/api/v1/clx_permissions/available_task_types`);

    return axios.get<{ data: string[] }>(url).then(response => response.data.data);
}

export async function updateTask({
    taskId,
    title,
    actionDate,
    status,
    pendingReason,
}: {
    taskId: string;
    title?: string;
    actionDate?: string;
    status?: TaskStatus;
    pendingReason?: PendingReason;
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/tasks/${taskId}`);

    return axios.patch(url, { title, actionDate, status, pendingReason }).then(response => response.data.data);
}

export async function getStatusDescriptionList(taskPublicId: string): Promise<StatusDescriptionResponse[]> {
    const url = blenderGeneralUrlResolver(`/api/v1/status_descriptions?taskPublicId=${taskPublicId}`);

    return axios.get<StatusDescriptionResponse[]>(url).then(response => response.data);
}

export async function createStatusDescription({
    content,
    operatorPublicId,
    taskPublicId,
}: {
    content: string;
    operatorPublicId: string;
    taskPublicId: string;
}): Promise<StatusDescriptionResponse> {
    const url = blenderGeneralUrlResolver('/api/v1/status_descriptions');

    return axios.post(url, { content, operatorPublicId, taskPublicId }).then(response => response.data);
}

export async function dismissStatusDescription({
    statusDescriptionPublicId,
}: {
    statusDescriptionPublicId: string;
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/status_descriptions/${statusDescriptionPublicId}/dismiss`);

    return axios.post(url);
}
