import { isDefined } from '@lemonade-hq/ts-helpers';
import { useMemo } from 'react';
import { SettingValues } from 'components/LoCo/common/components/CoverageSettings/SettingValues';
import type { Values } from 'models/LoCo/Insurance/CoveragesEdition';
import { ValueType } from 'models/LoCo/Insurance/CoveragesEdition';
import type { RatingEditionSettingValues, SettingResponse } from 'models/LoCo/Insurance/Rating';
import { SettingLimitType } from 'models/LoCo/Insurance/Rating';

function transformRatingValues(values: RatingEditionSettingValues): Values {
    if (values.type === SettingLimitType.List) {
        return {
            type: ValueType.List,
            values: values.values,
            includeUnlimited: values.includeUnlimited,
        };
    }

    return {
        type: ValueType.Range,
        min: values.min,
        max: values.max,
        step: values.step,
    };
}

export const RatingSetting: React.FC<{ readonly setting: SettingResponse }> = ({ setting: settingResponse }) => {
    const values = useMemo(() => transformRatingValues(settingResponse.values), [settingResponse.values]);

    if (isDefined(settingResponse.type)) {
        return <SettingValues settingName={settingResponse.name} type={settingResponse.type} values={values} />;
    }

    return null;
};
