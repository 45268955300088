import axios from 'axios';

export interface IntegrationsResponse {
    data: {
        id: number;
        type: string;
        name: string;
        status: string;
    }[];
}

export async function getConfigurations(): Promise<IntegrationsResponse> {
    const url = '/backoffice/integrations';

    return axios.get(url).then(response => response.data);
}

export interface SetIntegrationResponse {
    integration: {
        id: number;
        type: string;
        name: string;
        status: string;
    };
}

export async function setConfigurations(type: string, name: string, status: string): Promise<SetIntegrationResponse> {
    const url = `/backoffice/integrations/${type}/${name}`;

    return axios.patch(url, { status }).then(response => response.data);
}
