import { ServiceName } from '@lemonade-hq/bluiza';
import type { UseMutationResult } from '@tanstack/react-query';
import { AdjusterQueryKeys } from 'queries/AdjusterQueries';
import { blenderGeneral } from '../../../../apiClients';
import type { AdjusterAvailability } from './useAdjuster';
import { usePessimisticMutation } from "queries/MutationHooks";

export function useUpdateAdjusterAvailability(
    region?: string
): UseMutationResult<
    null,
    unknown,
    { readonly adjusterId: string; readonly availability: AdjusterAvailability },
    null
> {
    return usePessimisticMutation({
        invalidateKeys: [
            [ServiceName.BlenderGeneral, 'ADJUSTER'],
            [AdjusterQueryKeys.GetAdjustersList, region],
        ],
        mutationFn: async ({ adjusterId, availability }: { adjusterId: string; availability: AdjusterAvailability }) =>
            blenderGeneral.put(`/api/v1/adjusters/${adjusterId}/availability`, { availability }),
    });
}
