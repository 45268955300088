import type { Maybe } from '@lemonade-hq/ts-helpers';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { getMajorVersionSeriesName } from '../display-texts/common';
import type { Edition, EditionAlert, VersionType, VersionTypeInfo } from 'models/LoCo/Insurance/BaseEdition';
import { EditionAlertLevel, EditionAlertType, EditionStatus, EditionType } from 'models/LoCo/Insurance/BaseEdition';

export function isEditionApproved(edition?: Edition): boolean {
    return edition != null && isDefined(edition.approvedAt) && isDefined(edition.approvedBy);
}

export function isEditionArchived(edition?: Edition): boolean {
    return edition != null && isDefined(edition.archivedAt) && isDefined(edition.archivedBy);
}

const EDITION_TYPE_TO_DISPLAY_NAME: Record<EditionType, string> = {
    [EditionType.Rating]: 'Rating',
    [EditionType.Coverages]: 'Coverages',
    [EditionType.DigitalAgent]: 'Digital Agent',
    [EditionType.UnderwritingFilters]: 'Underwriting Filters',
};

export function getEditionName(editionType: EditionType): string {
    return EDITION_TYPE_TO_DISPLAY_NAME[editionType];
}

const VERSION_PLACEHOLDER = '[VERSION]';
const editionApproveEligibilityAlertTexts: Record<EditionAlertType, string> = {
    [EditionAlertType.AllVersionTypesBlocked]: `Since the base edition is not the latest minor version in the ${VERSION_PLACEHOLDER} series, this Edition Draft cannot be approved for a major or minor release.`,
    [EditionAlertType.MajorVersionBlocked]: `Since the base edition is not the latest version, this Edition Draft cannot be approved for a major release. It can be approved as minor in the ${VERSION_PLACEHOLDER} series.`,
    [EditionAlertType.EligibleToAllVersionTypes]: `This edition can be approved for a major release, or a minor release in the ${VERSION_PLACEHOLDER} series.`,
};

export function getEditionApproveEligibility(
    edition: Edition,
    editionAlerts: EditionAlert[],
    baseEdition: Maybe<Edition>
): {
    readonly text: string;
    readonly isWarning: boolean;
} | null {
    if (
        isEditionApproved(edition) ||
        isEditionArchived(edition) ||
        editionAlerts.length === 0 ||
        !isDefined(baseEdition) ||
        !isDefined(baseEdition.versionMajor)
    ) {
        return null;
    }

    const editionEligibilityAlert = editionAlerts.find(alert => alert.type in editionApproveEligibilityAlertTexts);
    if (isDefined(editionEligibilityAlert)) {
        return {
            text: editionApproveEligibilityAlertTexts[editionEligibilityAlert.type].replace(
                VERSION_PLACEHOLDER,
                getMajorVersionSeriesName(baseEdition.versionMajor)
            ),
            isWarning: editionEligibilityAlert.level !== EditionAlertLevel.Info,
        };
    }

    return null;
}

export function isVersionTypeAllowedForApproval(versionType: VersionType, versionTypes: VersionTypeInfo[]): boolean {
    return versionTypes.find(type => type.type === versionType)?.allowed ?? false;
}

export function getEditionStatus(edition: Edition): EditionStatus {
    if (isEditionArchived(edition)) {
        return EditionStatus.Archived;
    }

    if (isEditionApproved(edition)) {
        return EditionStatus.Approved;
    }

    return EditionStatus.Draft;
}
