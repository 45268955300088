import type { Infer, RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import type { FocusEvent, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { Flex } from '../../../../base/Flex/Flex';
import { Text } from '../../../../base/Text/Text';
import { spacing } from '../../../../theme/spacing.css';
import type { RadioGroupProps as BUIRadioGroupProps } from '../../../RadioGroup/RadioGroup';
import { Radio as BUIRadio, RadioGroup as BUIRadioGroup } from '../../../RadioGroup/RadioGroup';
import { useForm } from '../../FormContext';
import { getOptionsFromSchema } from '../../utils';
import type { CommonAdapterProps } from '../common';
import { useConnectToForms } from '../common';
import type { AssertSchemaKeyIsOfTypeAtomic } from '../shape-assertion.types';

type BaseProps = Omit<BUIRadioGroupProps, 'onChange' | 'value'>;

export type AssertedRadioProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = BaseProps &
  (RadioProps<TSchema, TSchemaKey> extends infer TExtractedProps extends {
    readonly schemaKey: infer TSchemaKeyToCheck extends TSchemaKey;
  }
    ? AssertSchemaKeyIsOfTypeAtomic<TSchema, TSchemaKeyToCheck, TExtractedProps>
    : never);

type RadioProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = BaseProps &
  CommonAdapterProps<TSchema, TSchemaKey> & { readonly labels?: Record<Infer<TSchema>[NoInfer<TSchemaKey>], string> };

export const Radio = <TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(props): ReactNode => {
  const { schemaKey, rules, onBlur: externalOnBlur, labels, ...restProps } = props as RadioProps<TSchema, TSchemaKey>;
  const { values: data, dispatch, schema } = useForm<TSchema>();
  const { disabled, visible } = useConnectToForms({ schemaKey, rules });

  const optionsFromSchema = useMemo(() => {
    return getOptionsFromSchema<string>(schema, schemaKey, data);
  }, [schema, schemaKey, data]);

  const unTypedLabels = labels as Record<string, string> | undefined;

  const onChange = useCallback(
    (value: unknown) => {
      dispatch({
        type: 'setValue',
        key: schemaKey as RecordPath<TSchema>,
        value: value as Infer<TSchema>[RecordPath<TSchema>],
      });
    },
    [dispatch, schemaKey],
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLDivElement>) => {
      dispatch({ type: 'blur', key: schemaKey });
      externalOnBlur?.(e);
    },
    [dispatch, schemaKey, externalOnBlur],
  );

  return visible ? (
    <BUIRadioGroup
      disabled={disabled}
      onBlur={onBlur}
      onValueChange={onChange}
      value={get(data, schemaKey)}
      {...restProps}
    >
      {optionsFromSchema.map(value => (
        <Flex alignItems="center" gap={spacing.s06} key={value}>
          <BUIRadio id={value} value={value} />
          <Text htmlFor={value} type="label-sm">
            {unTypedLabels ? unTypedLabels[value] : capitalize(value)}
          </Text>
        </Flex>
      ))}
    </BUIRadioGroup>
  ) : null;
};
