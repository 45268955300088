import { CardGrid, CardGridArea } from '@lemonade-hq/blender-ui';
import { useToggle } from '@lemonade-hq/cdk';
import type { Tool } from '@lemonade-hq/persisted-tools';
import { COMPARABLE_TOOL_FIELDS, ToolsRevisionStatus } from '@lemonade-hq/persisted-tools';
import { clsx } from 'clsx';
import pick from 'lodash/pick';
import type { FC, HTMLAttributes } from 'react';
import { useCallback } from 'react';
import {
    useDeleteTool,
    useGetCurrentToolsRevision,
    useGetToolsEditorSchemaValues,
    useGetToolsRevision,
    useUpdateTool,
    useUpdateToolLocally,
} from '../persisted_tools.queries';
import { PlaygroundArea } from '../shared/playground.layout';
import * as shimmeringStyles from '../shared/shimmering.css';
import { displayError } from '../shared/tool.helpers';
import { PublishToolsRevisionDialog } from './dialogs/PublishToolsRevisionDialog';
import { ToolNamingDialog } from './dialogs/ToolNamingDialog';
import { PlaygroundToolEditorForm } from './PlaygroundToolEditorForm';
import { PlaygroundToolEditorHeader } from './PlaygroundToolEditorHeader';
import { PlaygroundToolEditorMain } from './PlaygroundToolEditorMain';
import { PlaygroundToolEditorSidebar } from './PlaygroundToolEditorSidebar';
import { useSelectedTool } from './useSelectedTool';

export const PlaygroundToolEditor: FC<HTMLAttributes<HTMLDivElement>> = ({
    className: externalClassName,
    ...props
}) => {
    const { data: toolSchemaValues } = useGetToolsEditorSchemaValues();
    const { data: currentToolsRevision, isPlaceholderData } = useGetCurrentToolsRevision();
    const { data: parentToolsRevision } = useGetToolsRevision(currentToolsRevision?.parentToolsRevisionPublicId, {
        disablePlaceholder: true,
    });
    const [selectedTool, handleChangeToolName] = useSelectedTool(isPlaceholderData ? undefined : currentToolsRevision);
    const mutationsContext = { toolsRevisionPublicId: currentToolsRevision?.publicId };

    const { mutate: updateTool, isPending: isUpdatingTool } = useUpdateTool(mutationsContext, {
        debounce: 500,
        onError: displayError('Error updating tool'),
    });

    const { mutate: deleteTool, isPending: isDeletingTool } = useDeleteTool(mutationsContext, {
        onError: displayError('Error deleting tool'),
    });
    const { mutate: updateToolLocally } = useUpdateToolLocally(mutationsContext);
    const { close: closeCreateToolDialog, isOpen: isCreateToolDialogOpen, open: openCreateToolDialog } = useToggle();
    const { close: closeCloneToolDialog, isOpen: isCloneToolDialogOpen, open: openCloneToolDialog } = useToggle();
    const { close: closePublishDialog, isOpen: isPublishDialogOpen, open: openPublishDialog } = useToggle();

    const isReadonly = currentToolsRevision?.status !== ToolsRevisionStatus.Pending;

    const handleUpdateTool = useCallback(
        (toolPublicId: string, data: Partial<Tool>, options?: { local?: boolean }) => {
            if (options?.local) {
                updateToolLocally({ ...data, toolPublicId });
            } else {
                updateTool({ ...data, toolPublicId });
            }
        },
        [updateTool, updateToolLocally]
    );

    const handleToolCreated = useCallback(
        (newToolName: string) => {
            closeCreateToolDialog();
            closeCloneToolDialog();
            handleChangeToolName(newToolName);
        },
        [closeCreateToolDialog, handleChangeToolName, closeCloneToolDialog]
    );

    const handleDeleteTool = useCallback(() => {
        if (selectedTool == null) return;

        deleteTool(selectedTool.publicId);
    }, [deleteTool, selectedTool]);

    const handleDiscardToolChanges = useCallback(
        (toolPublicId: string) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const tool = currentToolsRevision!.tools.find(t => t.publicId === toolPublicId)!;
            const parentTool = parentToolsRevision?.tools.find(t => t.name === tool.name);
            handleUpdateTool(toolPublicId, pick(parentTool, COMPARABLE_TOOL_FIELDS) as Partial<Tool>);
        },
        [handleUpdateTool, parentToolsRevision, currentToolsRevision]
    );

    return (
        <>
            <CardGrid
                areas={[
                    [PlaygroundArea.ToolEditorHeader, PlaygroundArea.ToolEditorHeader],
                    [PlaygroundArea.ToolEditorSidebar, PlaygroundArea.ToolEditorMain],
                ]}
                className={clsx(externalClassName, { [shimmeringStyles.shimmering]: isPlaceholderData })}
                showSeparators
                {...props}
            >
                <CardGridArea areaName={PlaygroundArea.ToolEditorSidebar}>
                    <PlaygroundToolEditorSidebar
                        isReadonly={isReadonly}
                        onChangeToolName={handleChangeToolName}
                        onCreateNewTool={openCreateToolDialog}
                        selectedToolName={selectedTool?.name}
                        toolsRevision={currentToolsRevision}
                    />
                </CardGridArea>
                <CardGridArea areaName={PlaygroundArea.ToolEditorHeader}>
                    <PlaygroundToolEditorHeader
                        isLoading={isUpdatingTool || isDeletingTool || isPlaceholderData}
                        isReadonly={isReadonly}
                        onPublish={openPublishDialog}
                        toolsRevision={currentToolsRevision}
                    />
                </CardGridArea>
                <CardGridArea areaName={PlaygroundArea.ToolEditorMain} overflow="scroll">
                    {selectedTool && (
                        <PlaygroundToolEditorForm
                            isReadonly={isReadonly}
                            key={`${currentToolsRevision?.publicId}-${selectedTool.name}`} // in order to trigger form reset when tool changes
                            tool={selectedTool}
                            toolSchemaValues={toolSchemaValues}
                            toolsRevision={currentToolsRevision}
                        >
                            <PlaygroundToolEditorMain
                                onCloneTool={openCloneToolDialog}
                                onDeleteTool={handleDeleteTool}
                                onDiscardToolChanges={handleDiscardToolChanges}
                                onUpdateTool={handleUpdateTool}
                                tool={selectedTool}
                                toolSchemaValues={toolSchemaValues}
                                toolsRevision={currentToolsRevision}
                            />
                        </PlaygroundToolEditorForm>
                    )}
                </CardGridArea>
            </CardGrid>
            {isCreateToolDialogOpen && (
                <ToolNamingDialog
                    onClose={closeCreateToolDialog}
                    onToolCreated={handleToolCreated}
                    toolsRevision={currentToolsRevision}
                />
            )}
            {isCloneToolDialogOpen && (
                <ToolNamingDialog
                    baseTool={selectedTool}
                    onClose={closeCloneToolDialog}
                    onToolCreated={handleToolCreated}
                    toolsRevision={currentToolsRevision}
                />
            )}
            {currentToolsRevision != null && parentToolsRevision != null && isPublishDialogOpen && (
                <PublishToolsRevisionDialog
                    onClose={closePublishDialog}
                    onToolsRevisionPublished={closePublishDialog}
                    parentToolsRevision={parentToolsRevision}
                    toolsRevision={currentToolsRevision}
                />
            )}
        </>
    );
};
