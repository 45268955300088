import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog, GENERAL_ERROR_MSG, Multiselect } from '@lemonade-hq/bluis';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useMemo, useState } from 'react';
import { StyledFormInputWrapper } from '../../LoCoPagesSharedStyles';
import type { UnderwritingEntityType } from './underwritingUtils';
import { getDecisionTypeText } from './underwritingUtils';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import { useGetProductLines } from 'queries/LoCo/Insurance/ProductLineQueries';
import { useUpdateUnderwritingProductLines } from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

interface ManageUnderwritingProductLinesDialogProps {
    readonly onClose: () => void;
    readonly code: string;
    readonly decisionType: UnderwritingDecisionLifecycleContext;
    readonly entityType: UnderwritingEntityType;
    readonly productLines: string[];
}

export const ManageUnderwritingProductLinesDialog: React.FC<ManageUnderwritingProductLinesDialogProps> = ({
    onClose,
    code,
    decisionType,
    entityType,
    productLines: initialProductLines,
}) => {
    const [selectedProductLines, setSelectedProductLines] = useState<string[]>(initialProductLines);

    const {
        isError: isErrorLoadingProductLines,
        isLoading: isLoadingProductLines,
        data: allProductLines,
    } = useGetProductLines();

    const {
        mutateAsync: updateUnderwritingProductLines,
        isPending: isLoadingUpdateProductLines,
        isError: isErrorUpdateProductLines,
    } = useUpdateUnderwritingProductLines(code, entityType);

    const onSave = async (): Promise<void> => {
        await updateUnderwritingProductLines({
            productLineCodes: selectedProductLines,
        });
        onClose();
    };

    const actions: DialogAction[] = [
        {
            text: 'Cancel',
            type: 'close',
            onClick: onClose,
        },
        {
            text: 'Save',
            type: 'submit',
            onClick: onSave,
            disabled: isLoadingProductLines || isLoadingUpdateProductLines || selectedProductLines.length === 0,
        },
    ];

    const productLineOptions = useMemo(() => {
        if (isDefined(allProductLines)) {
            return allProductLines.map(productLine => ({
                id: productLine.code,
                value: productLine.code,
                label: productLine.name,
            }));
        }

        return [];
    }, [allProductLines]);

    const error = isErrorLoadingProductLines
        ? 'Error updating product lines'
        : isErrorUpdateProductLines
          ? GENERAL_ERROR_MSG
          : undefined;

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={error}
            loading={isLoadingProductLines || isLoadingUpdateProductLines}
            onClose={onClose}
            title={`Update ${getDecisionTypeText(decisionType)} ${entityType}`}
        >
            <StyledFormInputWrapper inputWidth="250" label="Product Lines">
                <Multiselect
                    disabled={isLoadingProductLines || isLoadingUpdateProductLines}
                    options={productLineOptions}
                    placeholder="Select"
                    selectedIds={selectedProductLines}
                    setSelectedOptions={productLineOption =>
                        setSelectedProductLines(productLineOption.map(option => option.value))
                    }
                    width={250}
                />
            </StyledFormInputWrapper>
        </Dialog>
    );
};
