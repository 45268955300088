import { getToolDirtyFields, ToolChangeStatus } from '@lemonade-hq/persisted-tools';
import type {
    SerializableTool as Tool,
    SerializableToolsRevision as ToolsRevision,
} from '@lemonade-hq/persisted-tools';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import { useComparePageNavigation } from './useComparePageNavigation';

interface UseComparedToolsProps {
    readonly olderToolsRevision?: ToolsRevision;
    readonly newerToolsRevision?: ToolsRevision;
}

interface CalculatedToolStatus {
    readonly name: string;
    readonly changeStatus: ToolChangeStatus;
}

interface UseComparedToolsResult {
    readonly calculatedToolsStatuses: CalculatedToolStatus[];
    readonly olderTool?: Tool;
    readonly newerTool?: Tool;
    readonly selectedToolName: string;
}

// used to manage both the current selected tool, and in general which tools between 2 revisions have differences.
// unlike `useToolsDiff`, this hook doesn't calculate the diff, but rather says which tools have a diff.
export function useComparedTools({
    olderToolsRevision,
    newerToolsRevision,
}: UseComparedToolsProps): UseComparedToolsResult {
    const { toolName: selectedToolNameFromURL } = useComparePageNavigation();

    const olderToolsMap = useMemo(
        () => new Map<string, Tool>(olderToolsRevision?.tools.map(t => [t.name, t]) ?? []),
        [olderToolsRevision]
    );
    const newerToolsMap = useMemo(
        () => new Map<string, Tool>(newerToolsRevision?.tools.map(t => [t.name, t]) ?? []),
        [newerToolsRevision]
    );

    const calculatedToolsStatuses: CalculatedToolStatus[] = useMemo(() => {
        if (newerToolsRevision == null || olderToolsRevision == null) {
            return [];
        }

        const toolsStatuses: CalculatedToolStatus[] = [];

        newerToolsRevision.tools.forEach(newerTool => {
            const matchingOlderTool = olderToolsMap.get(newerTool.name);
            if (matchingOlderTool == null || newerTool.changeStatus === ToolChangeStatus.New) {
                toolsStatuses.push({ name: newerTool.name, changeStatus: ToolChangeStatus.New });
            } else {
                const dirtyFields = getToolDirtyFields(newerTool, matchingOlderTool);
                if (!isEmpty(dirtyFields)) {
                    toolsStatuses.push({ name: newerTool.name, changeStatus: ToolChangeStatus.Edited });
                }
            }
        });

        olderToolsRevision.tools.forEach(olderTool => {
            const newerTools = newerToolsMap.get(olderTool.name);
            if (newerTools == null || newerTools.changeStatus === ToolChangeStatus.Deleted) {
                toolsStatuses.push({ name: olderTool.name, changeStatus: ToolChangeStatus.Deleted });
            }
        });

        return sortBy(toolsStatuses, 'name');
    }, [newerToolsRevision, olderToolsRevision, olderToolsMap, newerToolsMap]);

    const selectedToolName = selectedToolNameFromURL ?? calculatedToolsStatuses[0]?.name;

    const olderTool = olderToolsMap.get(selectedToolName);
    const newerTool = newerToolsMap.get(selectedToolName);

    return { olderTool, newerTool, calculatedToolsStatuses, selectedToolName };
}
