import type React from 'react';
import { createPortal } from 'react-dom';
import { useHeader } from '../hooks';

interface PortalProps {
    readonly children: React.ReactNode;
}

export const HeaderPortal: React.FC<React.PropsWithChildren<PortalProps>> = ({ children }) => {
    const context = useHeader();

    if (!context.portal) return null;

    return createPortal(children, context.portal);
};
