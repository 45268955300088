import { Select } from '@lemonade-hq/bluis';
import { InputWrapper } from '../../../InputWrapper';
import { useManageRuleDialogContext } from '../../ManageRuleDialogContext';
import { Outcome } from './Outcome';
import { SettingAdditionalOptions } from './SettingAdditionalOptions';
import { isEnumValue } from 'components/LoCo/common/helpers/typeHelpers';
import { RuleOutcomeType, SettingDefaultValueType, Treatment } from 'models/LoCo/Insurance/CoverageRule';

export const SettingInitialValue: React.FC<{
    readonly settingName: string;
    readonly label: string;
}> = ({ settingName, label }) => {
    const {
        values: { outcome, entityType },
        dispatch,
    } = useManageRuleDialogContext();
    if (entityType == null) throw new Error('Entity type is required');

    return (
        <Outcome coverageOrSettingName={settingName} outcomeTitle={'Initial Renewal Value'} ruleEntityType={entityType}>
            <InputWrapper label="Renewal default">
                <Select
                    onOptionSelected={option => {
                        if (option.value === RuleOutcomeType.MatchPreviousPolicyOutcome) {
                            dispatch({
                                type: 'outcome',
                                value: { type: RuleOutcomeType.MatchPreviousPolicyOutcome },
                            });
                        } else if (isEnumValue(SettingDefaultValueType, option.value)) {
                            dispatch({
                                type: 'outcome',
                                value: { type: RuleOutcomeType.SettingDefaultValueOutcome, value: option.value },
                            });
                        } else if (option.value === RuleOutcomeType.SettingNearestToValueOutcome) {
                            dispatch({
                                type: 'outcome',
                                value: { type: RuleOutcomeType.SettingNearestToValueOutcome, expression: '' },
                            });
                        } else {
                            dispatch({
                                type: 'outcome',
                                value: {
                                    type: RuleOutcomeType.SettingExactValuesOutcome,
                                    values: [],
                                    treatment: Treatment.Forbid,
                                },
                            });
                        }
                    }}
                    options={[
                        {
                            value: RuleOutcomeType.MatchPreviousPolicyOutcome,
                            // label: 'Match Previous Policy Term',
                            label,
                            id: 'match-previous-policy',
                        },
                        { id: 'lowest', label: 'Lowest Available', value: SettingDefaultValueType.Lowest },
                        { id: 'highest', label: 'Highest Available', value: SettingDefaultValueType.Highest },
                        { id: 'nearest', label: 'Nearest To', value: RuleOutcomeType.SettingNearestToValueOutcome },
                    ]}
                    value={
                        outcome?.type === RuleOutcomeType.SettingDefaultValueOutcome
                            ? outcome.value
                            : outcome?.type ?? null
                    }
                />
            </InputWrapper>
            <SettingAdditionalOptions />
        </Outcome>
    );
};
