import React from 'react';
import styled from 'styled-components';

const DeleteButton = styled.button`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    background-size: auto 18px;
    background-image: url('${__assets_url}blender_assets/backoffice/remove-attachment.png');
    background-position: center center;
    background-repeat: no-repeat;
    top: -7px;
    right: -7px;
    outline: none;
    border: 0;
    z-index: 1000;
`;

interface DeleteAttachmentButtonProps {
    readonly onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
    readonly attachmentId: string;
}
const DeleteAttachmentButton: React.FC<React.PropsWithChildren<DeleteAttachmentButtonProps>> = ({
    onDelete,
    attachmentId,
    ...styles
}) => <DeleteButton type="button" {...styles} data-attachment-id={attachmentId} onClick={onDelete} />;

export default DeleteAttachmentButton;
