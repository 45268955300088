import { createContext } from 'react';

export interface HeaderContext {
    readonly height: number;
    readonly portal?: HTMLDivElement;
    readonly setHeight: (height: number) => void;
    readonly setPortal: (node: HTMLDivElement) => void;
}

export const Context = createContext<HeaderContext | null>((null as unknown) as HeaderContext);
