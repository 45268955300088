import { ellipsis, Layout, Table } from '@lemonade-hq/blender-ui';
import { DatetimeFormat, formatDatetime } from '@lemonade-hq/lemonation';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import { useCallback } from 'react';
import type { FC } from 'react';
import { MISSING_VALUE } from '../../utils';
import { tableWrapper } from '../Attachments.css';
import { AttachmentActionsMenu } from './AttachmentActionMenu';
import { NoResults } from './NoResults';
import { TableLoader } from './TableLoader';
import type { AttachmentTable } from './types';
import { AttachmentThumbnail } from 'components/Attachments/AttachmentThumbnail';
import { useAttachmentsData } from 'components/Attachments/context';

const cols = [
    { key: 'id', name: '', hidden: true },
    { key: 'thumbnail', name: '', width: '80px' },
    { key: 'fileName', name: 'File Name', width: '200px' },
    { key: 'type', name: 'Type' },
    { key: 'description', name: 'Description', width: '480px' },
    { key: 'uploadDate', name: 'Upload date' },
    { key: 'actions', name: '', width: '50px' },
];

export const UnreviewedAttachmentsTable: FC<AttachmentTable> = ({
    setActionProps,
    attachments,
    isLoading,
    selectedRows,
    handleToggleRow,
    handleToggleAll,
    showGallery,
}) => {
    const { entityPublicId, entityType } = useAttachmentsData();
    const handleClick = useCallback(
        (index: number): void => {
            showGallery(index);
        },
        [showGallery]
    );

    const getRows = useCallback(() => {
        return attachments.map((attachment, index) => {
            return {
                id: { value: attachment.publicId, hidden: true },
                thumbnail: {
                    value: (
                        <AttachmentThumbnail
                            attachment={attachment}
                            entityPublicId={entityPublicId}
                            entityType={entityType}
                            onClick={() => handleClick(index)}
                        />
                    ),
                },
                fileName: {
                    value: (
                        <Layout className={ellipsis} maxWidth="200px">
                            {attachment.fileName ?? MISSING_VALUE}
                        </Layout>
                    ),
                },
                type: { value: snakeCaseToReadable(attachment.type ?? MISSING_VALUE) },
                description: { value: <Layout>{attachment.description ?? MISSING_VALUE}</Layout> },
                uploadDate: {
                    value:
                        attachment.uploadedAt != null
                            ? formatDatetime(new Date(attachment.uploadedAt), {
                                  datetimeFormat: DatetimeFormat.DateOrdinalShortMonthWithTime,
                              })
                            : MISSING_VALUE,
                },
                actions: {
                    value: <AttachmentActionsMenu attachment={attachment} setActionProps={setActionProps} />,
                },
            };
        });
    }, [attachments, entityPublicId, entityType, handleClick, setActionProps]);

    const onSelectRow = (rowId: string): void => {
        handleToggleRow(rowId);
    };

    const onSelectAll = (rowIds: string[]): void => {
        handleToggleAll(rowIds);
    };

    const checkableProps = {
        checkedIds: Array.from(selectedRows),
        onAllChecked: onSelectAll,
        onRowChecked: onSelectRow,
        rowIdentifier: 'id',
    };

    return (
        <Layout className={tableWrapper}>
            {isLoading ? (
                <TableLoader />
            ) : attachments.length === 0 ? (
                <NoResults />
            ) : (
                <Table checkable={checkableProps} columns={cols} data={getRows()} />
            )}
        </Layout>
    );
};
