/* eslint-disable @typescript-eslint/naming-convention */
import type { CoreEntityType, ProductTypes } from '@lemonade-hq/bluiza';
import { useAnalytics, useCurrentPrincipal } from '@lemonade-hq/boutique';
import { useCallback, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import type { IIdleTimerProps } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';

/**
 * A hook to send tracking event every 10s when operator is active, this aims to check net handling time of a claim in Blender.
 * It uses react-idle-timer to detect if operator is active.
 * Fires tracking events:
 * (1) every 10s of activity
 * (2) on pathname/hash change
 * (3) when an operator becomes active after inactivity of more then 10s
 *
 * Fires operator_total_active_time event on unmount to track total active time, and when url changes.
 *
 * @param entityId - The entity id to track
 * @param name - The name of the timer
 * @param product - The product type (Home, Pet, Car)
 * @param entity - The entity type (Claim, User, Policy, etc)
 */

interface OperatorActivityTrackingProps {
    readonly name: string;
    readonly entityId?: string;
    readonly product?: ProductTypes | string;
    readonly entity?: CoreEntityType | string;
    readonly params?: Record<string, string>;
}

export const useOperatorActivityTracking = ({
    entityId,
    name,
    product,
    entity,
    params,
}: OperatorActivityTrackingProps): void => {
    const { trackAnalyticsEvent } = useAnalytics();
    const { operator } = useCurrentPrincipal();
    const operatorId = operator?.id ?? '';
    const { pathname, hash } = useLocation();

    const getTrackingParams = useCallback(
        (taskId: string): Record<string, string> => ({
            operator_id: operatorId,
            url: window.location.href,
            ...(entityId != null && { entityId }),
            ...(product != null && { product }),
            ...(entity != null && { entity }),
            ...(taskId && { task_id: taskId.replace('#', '') }),
            ...(params && { ...params }),
        }),
        [entity, entityId, operatorId, params, product]
    );

    const trackOperatorActivity = useCallback((): void => {
        const { hash: taskId } = window.location;

        trackAnalyticsEvent('operator_active', getTrackingParams(taskId));
    }, [getTrackingParams, trackAnalyticsEvent]);

    const interval = 10_000;
    const timerProps: IIdleTimerProps = {
        name,
        timeout: 10_500, // time for a user to be set as idle
        eventsThrottle: 500,
        syncTimers: 500,
        onActive: trackOperatorActivity,
        events: [
            'click',
            'mousemove',
            'mousedown',
            'keydown',
            'drag',
            'scroll',
            'keydown',
            'keypress',
            'keyup',
            'wheel',
        ],
    };

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { isIdle, getActiveTime } = useIdleTimer(timerProps);

    const trackActiveTime = useCallback((): void => {
        trackAnalyticsEvent('operator_total_active_time', {
            ...getTrackingParams(window.location.hash),
            active_time: getActiveTime().toString(),
        });
    }, [getActiveTime, getTrackingParams, trackAnalyticsEvent]);

    const trackIsActive = useCallback((): void => {
        if (!isIdle() && document.visibilityState === 'visible') {
            trackOperatorActivity();
        }
    }, [isIdle, trackOperatorActivity]);

    useEffect(() => {
        const activeInterval = setInterval(() => {
            trackIsActive();
        }, interval);

        return () => {
            clearInterval(activeInterval);
            trackActiveTime();
        };
    }, [trackActiveTime, trackIsActive]);

    useEffect(() => {
        trackOperatorActivity();
        trackActiveTime();
    }, [hash, pathname, trackActiveTime, trackOperatorActivity]);
};
