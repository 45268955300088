import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type { DialogAction } from '@lemonade-hq/bluis';
import { AlertMode, Dialog, ErrorSection, Form, LoadingSection, Radio } from '@lemonade-hq/bluis';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { GeneralViolations, Violations } from 'components/LoCo/common/components/ViolationsErrorMessage';
import { getVersionTypeDisplayName } from 'components/LoCo/common/display-texts/common';
import { getEditionName, isVersionTypeAllowedForApproval } from 'components/LoCo/common/editions/editionHelpers';
import { LabelWithTooltip } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedLabels';
import type { VersionTypeInfo } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType, VersionType } from 'models/LoCo/Insurance/BaseEdition';
import {
    useApproveEdition,
    useGetEditionApproveEligibility,
    useGetEditionViolations,
    useGetLatestMinors,
} from 'queries/LoCo/Insurance/BaseEditionQueries';

interface ApproveEditionDialogDraftProps {
    readonly productCode: string;
    readonly editionType: EditionType;
    readonly editionCode: string;
    readonly onClose: () => void;
}

const WARNING_MSG = "Once you approve this edition, you won't be able to make any changes to it";

const StyledInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
`;

const StyledInputTitle = styled.span`
    margin: 10px 0;
    font-weight: 600;
`;

const DisabledRadioLabel = styled.span`
    opacity: 0.7;
`;

const StyledRadioWrapper = styled.div`
    display: flex;
    align-items: center;
`;

function getBlockReasonIfExists(
    versionType: VersionType,
    versionTypes: VersionTypeInfo[],
    editionType: EditionType,
    latestVersion?: string
): string | null {
    if (isVersionTypeAllowedForApproval(versionType, versionTypes)) {
        return null;
    }

    switch (versionType) {
        case VersionType.Major:
            return `For a ${getVersionTypeDisplayName(VersionType.Major)} release, create a new clone of the latest ${getEditionName(editionType)} edition ${isDefined(latestVersion) ? `(${latestVersion})` : ''}`;
        case VersionType.Minor:
        case VersionType.BugFix:
            return `For a ${getVersionTypeDisplayName(versionType)} release, create a new clone of the latest minor version of the ${getEditionName(editionType)} edition in the desired major series`;
        default:
            return null;
    }
}

export const ApproveEditionDialogDraft: React.FC<ApproveEditionDialogDraftProps> = ({
    editionCode,
    onClose,
    editionType,
    productCode,
}) => {
    const { values, setValue, valid } = useForm({
        fields: {
            versionType: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
        },
    });

    const {
        data: violations,
        isLoading: isLoadingViolations,
        isError: isViolationsError,
    } = useGetEditionViolations(editionCode, editionType);

    const {
        mutateAsync: approveEdition,
        isError: isApproveError,
        isPending: isSubmitting,
        error,
    } = useApproveEdition(productCode, editionCode, editionType);

    const {
        data: approvalEligibility,
        isLoading: isLoadingApprovalEligibility,
        isError: isErrorApprovalEligibility,
    } = useGetEditionApproveEligibility(editionCode, editionType);

    const {
        data: latestMinors,
        isLoading: isLoadingLatestMinors,
        isError: isErrorLatestMinors,
    } = useGetLatestMinors(productCode, EditionType.Coverages);

    const onSubmit = useCallback(async () => {
        await approveEdition({ editionCode, versionType: values.versionType as VersionType, editionType });
        onClose();
    }, [approveEdition, editionCode, editionType, onClose, values.versionType]);

    const actions = useMemo<DialogAction[]>(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Approve',
                type: 'submit',
                onClick: onSubmit,
                disabled: isSubmitting || !valid || !isDefined(violations) || violations.hasViolation,
            },
        ],
        [onClose, onSubmit, isSubmitting, valid, violations]
    );

    const isChecked = useCallback(
        (radioItem: string) => {
            return radioItem === values.versionType;
        },
        [values.versionType]
    );

    const dialogError = useMemo(() => {
        if (isLoadingViolations) return;
        if (isViolationsError || !isDefined(violations)) {
            return 'Error Loading Violations';
        }

        if (violations.hasViolation) {
            return <GeneralViolations title="The edition cannot be published:" violations={violations.messages} />;
        }

        if (isApproveError) {
            return <Violations editionType={editionType} error={error} />;
        }
    }, [editionType, error, isApproveError, isLoadingViolations, isViolationsError, violations]);

    const versionTypeOptions = useMemo<Record<VersionType, { disabledReason: string | null }>>(() => {
        const versionTypes = approvalEligibility?.versionTypes ?? [];
        const latestVersion = latestMinors?.[0]?.version ?? undefined;

        return {
            [VersionType.Major]: {
                disabledReason: getBlockReasonIfExists(VersionType.Major, versionTypes, editionType, latestVersion),
            },
            [VersionType.Minor]: {
                disabledReason: getBlockReasonIfExists(VersionType.Minor, versionTypes, editionType),
            },
            [VersionType.BugFix]: {
                disabledReason: getBlockReasonIfExists(VersionType.BugFix, versionTypes, editionType),
            },
        };
    }, [approvalEligibility?.versionTypes, editionType, latestMinors]);

    const isLoading = isLoadingViolations || isLoadingApprovalEligibility || isLoadingLatestMinors;

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={dialogError}
            loading={isSubmitting}
            notice={
                isApproveError || violations?.hasViolation ? undefined : [{ title: WARNING_MSG, mode: AlertMode.Info }]
            }
            onClose={onClose}
            title="Approve Edition Draft"
        >
            <Form style={{ marginTop: 0 }}>
                {isLoading ? (
                    <LoadingSection noBorders noShadow />
                ) : isErrorApprovalEligibility || isErrorLatestMinors ? (
                    <ErrorSection noBorders />
                ) : (
                    <StyledInputWrapper>
                        <StyledInputTitle>Version Type</StyledInputTitle>
                        {Object.entries(versionTypeOptions).map(([versionType, approveEligibility]) => {
                            return (
                                <StyledRadioWrapper key={versionType}>
                                    <Radio
                                        defaultChecked={isChecked(versionType)}
                                        disabled={approveEligibility.disabledReason != null}
                                        id={versionType}
                                        name="version_type_radio"
                                        onChange={e => setValue('versionType', e.target.value as VersionType)}
                                        value={versionType}
                                    />
                                    {approveEligibility.disabledReason != null ? (
                                        <Flex gap={spacing.s06}>
                                            <DisabledRadioLabel>
                                                {getVersionTypeDisplayName(versionType as VersionType)}
                                            </DisabledRadioLabel>
                                            <LabelWithTooltip
                                                label=""
                                                tooltipContent={
                                                    <Flex maxWidth={'400px'} whiteSpace="pre-wrap">
                                                        {approveEligibility.disabledReason}
                                                    </Flex>
                                                }
                                                type={'info'}
                                            />
                                        </Flex>
                                    ) : (
                                        <span>{getVersionTypeDisplayName(versionType as VersionType)}</span>
                                    )}
                                </StyledRadioWrapper>
                            );
                        })}
                    </StyledInputWrapper>
                )}
            </Form>
        </Dialog>
    );
};
