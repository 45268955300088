import { usePrevious } from '@lemonade-hq/cdk';
import type { FC } from 'react';
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import type { NoteEntity } from '../../types';
import type { NoteProps } from '../Note/Note';
import { Note } from '../Note/Note';

export interface NoteListProps {
    readonly notes: NoteEntity[];
    readonly timezone: string;
    readonly renderNoteFooter?: NoteProps['renderFooter'];
}

export const NoteList: FC<React.PropsWithChildren<NoteListProps>> = ({ notes, timezone, renderNoteFooter }) => {
    const { search } = useLocation();
    const [searchParams] = useSearchParams(search);
    const noteIdSearchParam = searchParams.get('noteId');

    const prevNotes = usePrevious(notes, notes);
    const isRenderedMoreThanOnce = prevNotes.length !== notes.length;
    const shouldFocus = notes.length === 1 && isRenderedMoreThanOnce;

    return (
        <div>
            {notes.map(note => {
                const { publicId, operatorName, operatorImageUrl, content, timestamp, adjusterRole } = note;
                const shouldHighlight = noteIdSearchParam === publicId;

                return (
                    <Note
                        adjusterRole={adjusterRole}
                        avatarUrl={operatorImageUrl}
                        content={content}
                        focusOptions={{
                            shouldFocus: shouldFocus || shouldHighlight,
                            hasAnimatedBorder: shouldHighlight,
                        }}
                        key={publicId}
                        name={operatorName}
                        note={note}
                        renderFooter={renderNoteFooter}
                        timestamp={timestamp}
                        timezone={timezone}
                    />
                );
            })}
        </div>
    );
};
