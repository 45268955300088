import { ThemeProvider, trackEvent } from '@lemonade-hq/boutique';
import { GlobalStylesheet } from '@lemonade-hq/bluis-atomic';
import { css, createGlobalStyle } from 'styled-components';
import useViewMode from '../hooks/useViewMode';
import { ThemeProvider as BuiThemeProvider } from '@lemonade-hq/blender-ui';
import { useEffect } from 'react';


const fontFamily = css`
    html,
    body {
        font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif;
        font-size: 14px;
    }
`;

export const GlobalFontFamily = createGlobalStyle`${fontFamily}`;


const SCTheme:React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [viewMode] = useViewMode();

    useEffect(() => {
        trackEvent('view_mode', { viewMode });
    }, []);

    return (
        <ThemeProvider themeName={viewMode}>
            <GlobalStylesheet />
            <GlobalFontFamily />
            {children}
        </ThemeProvider>
    );
}

export const EnsureTheme: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <BuiThemeProvider>
            <SCTheme>{children}</SCTheme>
        </BuiThemeProvider>
    );
}
