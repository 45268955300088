import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SupervisorProvider } from './components/Provider';
import { SupervisorPage } from './components/SupervisorPage';
import { WelcomePage } from './components/WelcomePage';

export const Supervisor: FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <SupervisorProvider>
            <Routes>
                <Route element={<WelcomePage />} path="" />
                <Route element={<SupervisorPage withZDWidget />} path="/:ticketId" />
            </Routes>
        </SupervisorProvider>
    );
};
