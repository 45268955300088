import {
    useMutation,
    UseMutationResult,
    useQueryClient,
    MutationFunction,
    QueryKey,
    QueryClient,
} from '@tanstack/react-query';
import { EntityType } from './config';

interface UseDataAggMutationOptions<TVariables, TData = null> {
    mFunction: MutationFunction<TData, TVariables>;
    invalidateKeys: QueryKey[];
    onSuccess?: (data: TData, variables: TVariables) => void;
}

export function useDataAggMutation<TVariables, TData = null>({
    mFunction,
    invalidateKeys,
    onSuccess,
}: UseDataAggMutationOptions<TVariables, TData>): UseMutationResult<TData, unknown, TVariables, null> {
    const cache = useQueryClient();

    return useMutation<TData, unknown, TVariables, null>({
        mutationFn: mFunction,
        onSettled: () => {
            invalidateKeys.forEach(key => {
                cache.invalidateQueries({ queryKey: key });
            });
        },
        onSuccess,
    });
}

interface UseDataAggOptimisticMutationOptions<TData, TSerializer extends keyof TData, TVariables = unknown> {
    entityType: EntityType;
    entityId: string;
    mFunction: MutationFunction<null, TVariables>;
    invalidateSerializers: TSerializer[];
    /**
     * Will be called once for each serializer in the invalidateKeys array,
     * allowing to optimistically mutate the data before the mutation is called.
     * If no mutation is needed for a serializer, return the data as is.
     */
    mutate: (
        data: Pick<TData, TSerializer>[TSerializer],
        variables: TVariables,
        serializer: TSerializer
    ) => Pick<TData, TSerializer>[TSerializer];
    onSettled?: (queryClient: QueryClient) => void;
    // allowing to pass non `dataAgg` queries to invalidate
    additionalQueriesToInvalidate?: [string, string][];
}

export function useDataAggOptimisticMutation<TData, TSerializer extends keyof TData, TVariables = unknown>({
    mFunction,
    invalidateSerializers,
    mutate,
    onSettled,
    entityType,
    entityId,
    additionalQueriesToInvalidate = [],
}: UseDataAggOptimisticMutationOptions<TData, TSerializer, TVariables>): UseMutationResult<
    null,
    unknown,
    TVariables,
    TData
> {
    const queryClient = useQueryClient();

    return useMutation<null, unknown, TVariables, TData>({
        mutationFn: mFunction,
        onMutate: variables => {
            invalidateSerializers.forEach(serializer => {
                queryClient.cancelQueries({
                    queryKey: [serializer, entityId, entityType],
                });
            });
            additionalQueriesToInvalidate.forEach(key => {
                queryClient.cancelQueries({ queryKey: key });
            });

            invalidateSerializers.forEach(serializer => {
                const key = [serializer, entityId, entityType];
                const prevData = queryClient.getQueryData<Partial<Pick<TData, TSerializer>>[TSerializer]>(key);

                if (prevData == null) return;

                queryClient.setQueryData<Pick<TData, TSerializer>[TSerializer]>(key, () => {
                    return mutate(prevData, variables, serializer);
                });
            });

            return {} as TData;
        },
        onSettled: () => {
            invalidateSerializers.forEach(serializer => {
                queryClient.invalidateQueries({
                    queryKey: [serializer, entityId, entityType],
                });
            });
            additionalQueriesToInvalidate.forEach(key => {
                queryClient.invalidateQueries({ queryKey: key });
            });
            onSettled?.(queryClient);
        },
    });
}
