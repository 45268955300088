import type { UseQueryResult } from '@tanstack/react-query';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { blenderGeneral } from '../../apiClients';
import type { GetGenericQueueParams, GetGenericQueueResponse } from './types';

export const getGenericQueue = async ({
    queueName,
    assignee,
    ...params
}: GetGenericQueueParams): Promise<GetGenericQueueResponse> => {
    return await blenderGeneral
        .get(`/api/v1/generic_queues/${queueName}${assignee == null ? '' : `/${assignee}`}`, { params })
        .then(res => res.data?.data);
};

enum GenericQueueKey {
    GetGenericQueue = 'GET_GENERIC_QUEUE',
}

export const useGetGenericQueue = (params: GetGenericQueueParams): UseQueryResult<GetGenericQueueResponse> => {
    return useQuery({
        queryKey: [GenericQueueKey.GetGenericQueue, params.queueName, params.assignee, params],
        queryFn: async () => await getGenericQueue(params),
        placeholderData: keepPreviousData,
    });
};
