import { EnvName, getEnv } from '../config';
import { getIsDevKubernetes } from '../config/kubernetes';

export function getEnvId(env: EnvName = getEnv(), isDevKubernetes: boolean = getIsDevKubernetes()): string | null {
  if (env !== EnvName.Staging && !isDevKubernetes) return null;

  const envIdRegex =
    env === EnvName.Development ? /^[^.]+-(t-[0-9a-zA-Z-]+)\./ : /^[^.]+-(master|t[0-9]+|t-[0-9a-zA-Z-]+)\./; // Updated regex to include both t[0-9]+ and t-[0-9a-zA-Z-]+ for Staging
  const { hostname } = window.location;
  const [, envId] = envIdRegex.exec(hostname) ?? [null, null];

  return envId ?? 'master';
}

// Determine if we are in "external" blender
export function getBlenderDomain(envName: EnvName = getEnv()): string {
  const currentHost = window.location.hostname;

  switch (envName) {
    case EnvName.Production:
      return (/(-agents)?\.lemonade\.com$/.exec(currentHost) ?? [null])[1] ?? '';
    case EnvName.Staging:
      return (/(\.public)?\.lmndstaging\.com$/.exec(currentHost) ?? [null])[1] ?? '';
    case EnvName.Development:
      return (/(\.public)?\.lmnddev\.com$/.exec(currentHost) ?? [null])[1] ?? '';
    // istanbul-ignore-next impossible in ts
    default:
      return '';
  }
}
