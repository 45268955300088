import { LinkComp } from '@lemonade-hq/bluiza';
import { Flex } from '@lemonade-hq/cdk';
import type { Maybe } from '@lemonade-hq/ts-helpers';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { links } from './dialogs/ReleaseTable.css';
import { getSchemaTypeName } from 'components/LoCo/common/display-texts/common';
import { getSchemaUrl } from 'components/LoCo/common/urlBuilders';
import type { SchemaType } from 'models/LoCo/Insurance/Schema';
type SchemaLabelRenderer = (schemaType: SchemaType, schemaRevision: number) => React.ReactNode;

type SchemaLabelProps = {
    readonly productCode: string;
    readonly schemaRevision: number;
    readonly schemaType: SchemaType;
    readonly openLinkInNewTab?: boolean;
    readonly schemasLabelRenderer: SchemaLabelRenderer;
};

interface SchemasLinksProps {
    readonly openLinkInNewTab?: boolean;
    readonly productCode: string;
    readonly schemaRevisions: Record<'platform' | 'product', Maybe<number>>;
    readonly schemaLabelRenderer?: SchemaLabelRenderer;
}

export function simpleNameRenderer(schemaType: SchemaType, schemaVersion: number): React.ReactNode {
    return `${getSchemaTypeName(schemaType)} Schema ${schemaVersion}`;
}

const SchemaLink: React.FC<SchemaLabelProps> = ({
    productCode,
    schemaRevision,
    schemaType,
    openLinkInNewTab,
    schemasLabelRenderer,
}) => {
    const label = schemasLabelRenderer(schemaType, schemaRevision);

    const url =
        schemaType === 'platform'
            ? getSchemaUrl(productCode, undefined, schemaRevision.toString())
            : getSchemaUrl(productCode, schemaRevision.toString(), undefined);

    return (
        <LinkComp target={openLinkInNewTab ? '_blank' : '_self'} url={url}>
            {label}
        </LinkComp>
    );
};

export const SchemasLinks: React.FC<SchemasLinksProps> = ({
    productCode,
    schemaRevisions,
    schemaLabelRenderer = simpleNameRenderer,
    openLinkInNewTab = false,
}) => {
    const schemas = Object.entries(schemaRevisions)
        .filter(([_, revision]) => isDefined(revision))
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(([schemaType, schemaRevision]) => ({ schemaType, schemaRevision: schemaRevision! }));

    if (schemas.length === 0) {
        return null;
    }

    return (
        <Flex className={links}>
            {schemas.map(({ schemaType, schemaRevision }) => (
                <SchemaLink
                    key={schemaType}
                    openLinkInNewTab={openLinkInNewTab}
                    productCode={productCode}
                    schemaRevision={schemaRevision}
                    schemaType={schemaType as SchemaType}
                    schemasLabelRenderer={schemaLabelRenderer}
                />
            ))}
        </Flex>
    );
};
