import { toast } from '@lemonade-hq/bluis';
import omit from 'lodash/omit';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiResponseType, Tag } from '../../shared/types';
import type {
    InterveneParams,
    ReviewParams,
    SupervisorMetadata,
    SupervisorTicketFilters,
    TicketAiReview,
} from '../../shared/types';
import { defaultMetadata } from '../supervisor.const';
import {
    useAssignNextAiResponse,
    useExpireTicket,
    useInterveneAiResponse,
    useReleaseTicketToZendesk,
    useResetReview,
    useResponseTagReview,
    useSkipTicket,
    useSupervisorMetadata,
    useUnassignTicket,
} from '../supervisor.queries';
import { getFiltersFromLocalStorage, updateFiltersInLocalStorage } from '../supervisor.utils';

enum SupervisorActionType {
    SetMode = 'SET_MODE',
    SetTicketId = 'SET_TICKET_ID',
    SetTicketData = 'SET_TICKET_DATA',
    SetIsLoadingTicket = 'SET_IS_LOADING_TICKET',
    SetIsSkippingTicket = 'SET_IS_SKIPPING_TICKET',
    SetMacroTags = 'SET_MACRO_TAGS',
    Reset = 'RESET',
}

interface SetModeAction {
    readonly type: SupervisorActionType.SetMode;
    readonly payload: AiResponseType;
}

interface SetTicketDataAction {
    readonly type: SupervisorActionType.SetTicketData;
    readonly payload: TicketAiReview;
}

interface SetLoadingTicketAction {
    readonly type: SupervisorActionType.SetIsLoadingTicket;
    readonly payload: boolean;
}

interface SetIsSkippingTicketAction {
    readonly type: SupervisorActionType.SetIsSkippingTicket;
    readonly payload: boolean;
}

interface SetMacroTagsAction {
    readonly type: SupervisorActionType.SetMacroTags;
    readonly payload: string[];
}

interface ResetAction {
    readonly type: SupervisorActionType.Reset;
}

type SupervisorAction =
    | ResetAction
    | SetIsSkippingTicketAction
    | SetLoadingTicketAction
    | SetMacroTagsAction
    | SetModeAction
    | SetTicketDataAction;

export interface SupervisorState {
    readonly mode: AiResponseType;
    readonly ticketData?: TicketAiReview;
    readonly isLoadingTicket?: boolean;
    readonly isSkippingTicket?: boolean;
    readonly macroTags: string[];
    readonly filters: SupervisorTicketFilters;
    readonly metadata: SupervisorMetadata;
}

const INITIAL_SUPERVISOR_STATE: SupervisorState = {
    mode: AiResponseType.Draft,
    macroTags: [],
    filters: getFiltersFromLocalStorage(),
    metadata: defaultMetadata,
};

function supervisorStateReducer(state: SupervisorState, action: SupervisorAction): SupervisorState {
    switch (action.type) {
        case SupervisorActionType.SetMode:
            return { ...state, mode: action.payload };
        case SupervisorActionType.SetTicketData:
            return { ...state, ticketData: action.payload };
        case SupervisorActionType.SetIsLoadingTicket:
            return { ...state, isLoadingTicket: action.payload };
        case SupervisorActionType.SetIsSkippingTicket:
            return { ...state, isSkippingTicket: action.payload };
        case SupervisorActionType.SetMacroTags:
            return { ...state, macroTags: action.payload };
        case SupervisorActionType.Reset:
            return { ...state, ...omit(INITIAL_SUPERVISOR_STATE, 'mode') };

        default:
            return state;
    }
}

export interface SupervisorActions {
    readonly setMode: (mode: AiResponseType) => void;
    readonly setTicketData: (data: TicketAiReview) => void;
    readonly setLoadingTicket: (isLoading: boolean) => void;
    readonly setMacroTags: (macroTags: string[]) => void;
    readonly setIsSkippingTicket: (isSkipping: boolean) => void;
    readonly skipTicket: () => Promise<void>;
    readonly unassignTicket: () => Promise<void>;
    readonly loadNextTicket: () => Promise<void>;
    readonly reviewAiResponse: (params: ReviewParams) => Promise<void>;
    readonly interveneAiResponse: (params: InterveneParams) => Promise<void>;
    readonly reset: () => void;
    readonly onReviewPass: () => Promise<void>;
    readonly onResetReview: () => Promise<void>;
    readonly releaseToZendesk: () => Promise<void>;
    readonly updateFilters: (filters: SupervisorTicketFilters) => void;
    readonly expireTicket: () => Promise<void>;
}

export function useSupervisor(): { readonly state: SupervisorState; readonly actions: SupervisorActions } {
    const navigate = useNavigate();
    const [filters, setFilters] = useState<SupervisorTicketFilters>(getFiltersFromLocalStorage());
    const [state, dispatch] = useReducer(supervisorStateReducer, INITIAL_SUPERVISOR_STATE);
    const { mutateAsync: assignNextAiReview } = useAssignNextAiResponse();
    const { mutateAsync: unassignCurrentTicket } = useUnassignTicket();
    const { mutateAsync: skipCurrentTicket } = useSkipTicket();
    const { mutateAsync: releaseTicketToZendesk } = useReleaseTicketToZendesk();
    const { mutateAsync: reviewAiResponse } = useResponseTagReview();
    const { mutateAsync: resetReview } = useResetReview();
    const { mutateAsync: interveneAiResponse } = useInterveneAiResponse();
    const { mutateAsync: expireTicketMutation } = useExpireTicket();
    const { data: metadata, refetch: refetchMetadata } = useSupervisorMetadata(filters);
    const ticketId = state.ticketData?.publicId ?? '';

    useEffect(() => {
        void refetchMetadata();
    }, [filters, refetchMetadata]);

    function setMode(mode: AiResponseType): void {
        dispatch({ type: SupervisorActionType.SetMode, payload: mode });
    }

    function setMacroTags(macroTags: string[]): void {
        dispatch({ type: SupervisorActionType.SetMacroTags, payload: macroTags });
    }

    function setTicketData(data: TicketAiReview): void {
        dispatch({ type: SupervisorActionType.SetTicketData, payload: data });
    }

    function setLoadingTicket(isLoading: boolean): void {
        dispatch({ type: SupervisorActionType.SetIsLoadingTicket, payload: isLoading });
    }

    function setIsSkippingTicket(isSkipping: boolean): void {
        dispatch({ type: SupervisorActionType.SetIsSkippingTicket, payload: isSkipping });
    }

    function updateFilters(updatedFilters: SupervisorTicketFilters): void {
        updateFiltersInLocalStorage(updatedFilters);
        setFilters(updatedFilters);
    }

    const reset = useCallback(() => dispatch({ type: SupervisorActionType.Reset }), []);

    async function unassignTicket(): Promise<void> {
        if (!ticketId) {
            return;
        }

        await unassignCurrentTicket(ticketId);
    }

    async function loadNextTicket(): Promise<void> {
        setLoadingTicket(true);
        try {
            await refetchMetadata();

            const response = await assignNextAiReview({
                responseType: state.mode,
                filters,
            });

            // If there's no next ticket, then we redirect to the supervisor page.
            // Otherwise we redirect to the ticket page.
            if (response == null) {
                navigate('/backoffice/supervisor');
            } else {
                navigate(response.publicId);
            }

            reset();
        } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error(error);
            toast.error('Failed to load the next ticket');
            throw error;
        } finally {
            setLoadingTicket(false);
        }
    }

    async function skipTicket(): Promise<void> {
        setIsSkippingTicket(true);
        try {
            await loadNextTicket();
            await skipCurrentTicket(ticketId);
        } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error(error);
        } finally {
            setIsSkippingTicket(false);
        }
    }

    async function releaseToZendesk(): Promise<void> {
        try {
            await releaseTicketToZendesk(ticketId);
            await loadNextTicket();
        } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }

    async function onReviewPass(): Promise<void> {
        await refetchMetadata();
        await reviewAiResponse({
            tag: Tag.Pass,
            ticketId,
        });
    }

    async function onResetReview(): Promise<void> {
        if (state.ticketData?.publicId == null) {
            return;
        }

        await resetReview(state.ticketData.publicId);
    }

    async function handleReviewAiResponse(params: ReviewParams): Promise<void> {
        await reviewAiResponse(params);
    }

    async function handleInterveneAiResponse(params: InterveneParams): Promise<void> {
        await interveneAiResponse(params);
    }

    async function expireTicket(): Promise<void> {
        try {
            await expireTicketMutation(ticketId);
            await loadNextTicket();
        } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }

    return {
        actions: {
            setMode,
            setTicketData,
            setLoadingTicket,
            setMacroTags,
            setIsSkippingTicket,
            skipTicket,
            unassignTicket,
            loadNextTicket,
            onReviewPass,
            onResetReview,
            reviewAiResponse: handleReviewAiResponse,
            interveneAiResponse: handleInterveneAiResponse,
            reset,
            releaseToZendesk,
            updateFilters,
            expireTicket,
        },
        state: {
            ...state,
            metadata: metadata ?? defaultMetadata,
            filters,
        },
    };
}
