import React from 'react';
import { Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import loadable from '@loadable/component';
import { LoadingSection } from '@lemonade-hq/bluis';
import { QueryClient } from '@tanstack/react-query';
import { UserQueryKeys } from 'queries/UserQueries';
import { getLegacyUserData } from 'apis/UsersAPITyped';
import { queryClient } from '../../queryClient';

const AsyncUser = loadable(async () => import(/* webpackChunkName: "User" */ '.'), {
    fallback: <LoadingSection />,
});

const AsyncUsersList = loadable(async () => import(/* webpackChunkName: "UsersList" */ '../UsersList'), {
    fallback: <LoadingSection />,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const userPageLoader = (queryClient: QueryClient) => async ({ params }) => {
    const queryKey = [UserQueryKeys.GetUserDetails, String(params.userId)];

    return (
        queryClient.getQueryData(queryKey) ??
        queryClient.fetchQuery({ queryKey, queryFn: async () => getLegacyUserData(params.userId) })
    );
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const UserRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <SentryRoutes>
            <Route element={<AsyncUsersList />} path="/" />
            <Route element={<AsyncUser />} path="/:userId/*" loader={userPageLoader(queryClient)} />
        </SentryRoutes>
    );
};

export default UserRoutes;
