import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { findTask, Task } from '@lemonade-hq/bluiza';
import { HomeClaimQueryKeys, WorkflowDataType } from 'queries/HomeClaimQueries';
import { HomeTaskType } from '../../Types';

import { useHomeWorkflowContext } from '../../HomeWorkflowContext';

type TaskToFind = Task<typeof HomeTaskType>;

export interface UseFindHomeTaskProps {
    type: TaskToFind['type'];
}

export interface UseFindHomeTaskReturn {
    /**
     * A callback to find the task. To be used after invalidating workflows.
     *
     * @example
     * const { findTask } = useFindHomeTask({ type });
     * await invalidateWorkflows();
     * const task = findTask()
     */
    findTask: () => TaskToFind | undefined;
    task?: TaskToFind;
}

/**
 * Find a workflow task by a type.
 */
export function useFindHomeTask({ type }: UseFindHomeTaskProps): UseFindHomeTaskReturn {
    const { claimPublicId } = useHomeWorkflowContext();
    const queryClient = useQueryClient();

    const find = useCallback(() => {
        const queryData = queryClient.getQueryData<WorkflowDataType>([HomeClaimQueryKeys.GetWorkflow, claimPublicId]);

        if (queryData == null) {
            return;
        }

        return findTask(queryData.workflow.flat(), task => task.type === type)?.task.task;
    }, [claimPublicId, queryClient, type]);

    const task = useMemo(() => {
        return find();
    }, [find]);

    return { findTask: find, task };
}
