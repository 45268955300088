/* eslint-disable @typescript-eslint/no-misused-promises */
import type { GroupedAutocompleteOption, GroupedAutocompleteRoot } from '@lemonade-hq/bluis';
import { BlenderLayer, GroupedAutocomplete, LightingIcon } from '@lemonade-hq/bluis';
import { trackEvent } from '@lemonade-hq/boutique';
import type { AutocompleteOptionId } from '@lemonade-hq/cdk';
import { slateFromHtml, useEditorActions } from '@lemonade-hq/cdk';
import type { Locale } from '@lemonade-hq/lemonation';
import { NOOP } from '@lemonade-hq/ts-helpers';
import type { Options as PopoverOptions } from '@popperjs/core';
import type React from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { getBoardGroup, getCard } from '../apis/MacrosAPI';
import { useMacrosBoardGroup } from '../queries/MacrosQueries';
import type {
  MacrosCard,
  MacrosCollection,
  MacrosInitProps,
  MacrosSupportedEntity,
  MacrosSupportedProductType,
} from '../types';
import {
  formatMacrosContent,
  getCardsFromOptions,
  getRelevantOptionsForSearch,
  hasCoreEntityInName,
  hasProductInName,
  mapEntityAndLocaleToOptions,
  mapProductToOptions,
  mapToGroupedAutocompleteOption,
  sortGroupedOptions,
} from './utils';

const PLACEHOLDER = 'Apply macro';
const ATTACHMENT = 'attachment';
const CANT_LOAD_WIDGET_ERROR_MESSAGE =
  'Oops… something went wrong. Please try again and if the problem continues, post in #bug-reports';
const CANT_LOAD_MACRO_ERROR_MESSAGE =
  'Oops... something went wrong with macros. Please try again and if the problem continues, post in #bug-reports.';
const POPOVER_OPTIONS: PopoverOptions = {
  placement: 'top-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    },
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top'],
      },
    },
  ],
};

const StyledWrapper = styled.div`
  display: inline-grid;
  position: relative;
  z-index: ${BlenderLayer.StickyTabs};
`;

export interface MacrosProps {
  readonly options: GroupedAutocompleteRoot;
  readonly macrosInitProps: MacrosInitProps;
  readonly width?: number;
  readonly onError: (errorMessage?: string) => void;
  readonly onFocus?: () => void;
  readonly onAttachmentTag?: () => void;
  readonly onSelect?: (macros: MacrosCard) => void;
  readonly maxSearchResults?: number;
}

export const Macros: React.FC<MacrosProps> = ({
  options,
  macrosInitProps,
  width,
  onError,
  onFocus,
  onAttachmentTag,
  onSelect,
  maxSearchResults,
}) => {
  const { entityType, productType, locale, params, config } = macrosInitProps;
  const [boardGroup, setBoardGroup] = useState<string | null>(productType);
  const [collection, setCollection] = useState<MacrosCollection | null>({ locale, entityType });
  const [currentOptions, setCurrentOptions] = useState<GroupedAutocompleteOption[] | GroupedAutocompleteRoot>(
    sortGroupedOptions(options, 'asc'),
  );
  const [searchResults, setSearchResults] = useState<GroupedAutocompleteOption[]>();
  const [isLoadingMacros, setIsLoadingMacros] = useState(false);
  const [pathInOptions, setPathInOptions] = useState<AutocompleteOptionId[]>([]);
  const { insertFragment } = useEditorActions();

  const handleOrderOptions = useCallback((newOptions: GroupedAutocompleteOption[] | GroupedAutocompleteRoot): void => {
    const ordered = sortGroupedOptions(newOptions, 'asc');
    setCurrentOptions(ordered);
  }, []);

  const handleBoardGroupSelected = useCallback(
    async (selectedOption: string): Promise<void> => {
      if (collection === null) {
        onError(CANT_LOAD_WIDGET_ERROR_MESSAGE);
        return;
      }

      if (boardGroup !== null) {
        setBoardGroup(null);
        handleOrderOptions(mapProductToOptions(collection));
        return;
      }

      setIsLoadingMacros(true);

      const data = await getBoardGroup({
        entityType: collection.entityType,
        locale: collection.locale,
        productType: selectedOption as MacrosSupportedProductType,
      });

      handleOrderOptions(mapToGroupedAutocompleteOption(data, macrosInitProps.config?.searchHierarchy ?? false));
      setBoardGroup(selectedOption);
      setIsLoadingMacros(false);
    },
    [collection, boardGroup, handleOrderOptions, macrosInitProps, onError],
  );

  const handleCollectionSelected = useCallback(
    (selectedOption: string): void => {
      if (collection === null) {
        const [coreCurrentEntityType, currentLocale] = selectedOption.split('_');

        if (coreCurrentEntityType === undefined || currentLocale === undefined) {
          onError(CANT_LOAD_WIDGET_ERROR_MESSAGE);
          return;
        }

        const newCollection = {
          entityType: coreCurrentEntityType.toLowerCase() as MacrosSupportedEntity,
          locale: currentLocale as Locale,
        };

        setCollection(newCollection);
        handleOrderOptions(mapProductToOptions(newCollection));
        return;
      }

      setCollection(null);
      handleOrderOptions(mapEntityAndLocaleToOptions(locale));
    },
    [collection, onError, locale, handleOrderOptions],
  );

  const handleOnAsyncOptionSelected = useCallback(
    async (selectedOptionId: number | string): Promise<void> => {
      const selectedOption = selectedOptionId.toString();

      if (hasProductInName(selectedOption)) {
        await handleBoardGroupSelected(selectedOption);
        return;
      }

      if (hasCoreEntityInName(selectedOption)) {
        handleCollectionSelected(selectedOption);
      }
    },
    [handleCollectionSelected, handleBoardGroupSelected],
  );

  const handleOnSendTrackEvent = useCallback(
    ({ content, tags, title, guruId }: MacrosCard): void => {
      let trackData = {
        url: document.URL,
        isFromSearch: searchResults && searchResults.length > 0 ? 'true' : 'false',
        content,
        tags: tags.join(','),
        title,
        guruId,
      };

      if (collection) {
        trackData = { ...trackData, ...collection };
      }

      if (boardGroup !== null) {
        trackData = { ...trackData, ...{ productType: boardGroup } };
      }

      trackEvent('macros.selected', trackData);
    },
    [boardGroup, collection, searchResults],
  );

  const handleOptionSelected = useCallback(
    async (option: GroupedAutocompleteOption): Promise<void> => {
      try {
        setPathInOptions([]);
        const card = await getCard(option.id as string);

        const parsedContent = formatMacrosContent(card, params, config);

        insertFragment(slateFromHtml(parsedContent));

        if (card.tags.includes(ATTACHMENT)) {
          onAttachmentTag?.();
        }

        onSelect?.(card);
        handleOnSendTrackEvent(card);
        setSearchResults([]);

        onError();
      } catch (err: unknown) {
        onError(CANT_LOAD_MACRO_ERROR_MESSAGE);
      }
    },
    [params, config, insertFragment, onSelect, handleOnSendTrackEvent, onError, onAttachmentTag],
  );

  const handleOnSearch = useCallback(
    (query: string): void => {
      if (collection === null || boardGroup === null) {
        setSearchResults([]);
        return;
      }

      const optionsForSearch = getRelevantOptionsForSearch(pathInOptions, currentOptions);

      if (query === '' || optionsForSearch === null) {
        setSearchResults([]);
        return;
      }

      const availableCards = getCardsFromOptions(optionsForSearch, []);
      const searchTerms = query.toLowerCase().split(' ');
      const matchingCards = availableCards
        .filter(card =>
          searchTerms.every(term => card.searchKey?.includes(term) ?? card.label.toLowerCase().includes(term)),
        )
        .slice(0, maxSearchResults ?? availableCards.length);

      setSearchResults(matchingCards);
    },
    [currentOptions, pathInOptions, collection, boardGroup, maxSearchResults],
  );

  const handleOnNavigate = useCallback(
    (direction: 'backward' | 'forward', idToNavigate: AutocompleteOptionId): void => {
      const idAsString = idToNavigate.toString();
      if (hasCoreEntityInName(idAsString) || hasProductInName(idAsString)) {
        return;
      }

      if (direction === 'backward') {
        setPathInOptions(pathInOptions.slice(0, -1));
      } else {
        setPathInOptions([...pathInOptions, idAsString]);
      }
    },
    [pathInOptions],
  );

  return (
    <StyledWrapper>
      <GroupedAutocomplete
        allowArrowsNavigation
        icon={<LightingIcon />}
        isLoading={isLoadingMacros}
        onAsyncOptionSelected={handleOnAsyncOptionSelected}
        onFocus={onFocus}
        onNavigate={handleOnNavigate}
        onOptionSelected={handleOptionSelected}
        onSearch={handleOnSearch}
        options={currentOptions}
        placeholder={PLACEHOLDER}
        popoverOptions={POPOVER_OPTIONS}
        searchResults={searchResults}
        width={width}
      />
    </StyledWrapper>
  );
};

export interface MacrosWidgetProps {
  readonly macrosInitProps: MacrosInitProps;
  readonly width?: number;
  readonly onError: (errorMessage?: string) => void;
  readonly onFocus?: () => void;
  readonly onAttachmentTag?: () => void;
  readonly onSelect?: (macros: MacrosCard) => void;
  readonly maxSearchResults?: number;
}

export const MacrosWidget: React.FC<MacrosWidgetProps> = ({
  macrosInitProps,
  width,
  onError,
  onFocus,
  onAttachmentTag,
  onSelect,
  maxSearchResults,
}) => {
  const { data, isError, isLoading } = useMacrosBoardGroup(macrosInitProps);

  if (isError) onError(CANT_LOAD_WIDGET_ERROR_MESSAGE);
  if (isError || isLoading) {
    return (
      <StyledWrapper>
        <GroupedAutocomplete
          disabled={isError}
          icon={<LightingIcon />}
          isLoading={isLoading}
          onSearch={NOOP}
          options={[]}
          placeholder={PLACEHOLDER}
          popoverOptions={POPOVER_OPTIONS}
          width={width}
        />
      </StyledWrapper>
    );
  }

  if (data === undefined) {
    return null;
  }

  return (
    <Macros
      macrosInitProps={macrosInitProps}
      maxSearchResults={maxSearchResults}
      onAttachmentTag={onAttachmentTag}
      onError={onError}
      onFocus={onFocus}
      onSelect={onSelect}
      options={mapToGroupedAutocompleteOption(data, macrosInitProps.config?.searchHierarchy ?? false)}
      width={width}
    />
  );
};
