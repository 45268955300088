import { Flex, IconButton, Select, spacing } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { useAnalytics } from '@lemonade-hq/boutique';
import type { FC } from 'react';
import { useLayoutEffect, useMemo, useReducer } from 'react';
import type { AttachmentDTO } from '../../types';
import { image, imageActionBg, imageActions, imageWrapper, zoomInput } from './Media.css';
import { getAttachmentAnalyticsParam } from 'components/Attachments/utils';

const zoomValues = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500];
const zoomResetIndex = zoomValues.indexOf(100);

type State = {
    readonly rotate: number;
    readonly zoomIndex: number;
};

const initialState: State = {
    rotate: 0,
    zoomIndex: zoomResetIndex,
};

type Action =
    | { readonly type: 'RESET' }
    | { readonly type: 'SET_ROTATE'; readonly payload: number }
    | { readonly type: 'SET_ZOOM_INDEX'; readonly payload: number };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_ROTATE':
            return { ...state, rotate: action.payload };
        case 'SET_ZOOM_INDEX':
            return { ...state, zoomIndex: action.payload };
        case 'RESET':
            return { rotate: 0, zoomIndex: zoomResetIndex };
        default:
            // eslint-disable-next-line no-console
            console.error('This action type is not supported');
            return state;
    }
};

const ImageActions: FC<{
    readonly dispatch: React.Dispatch<Action>;
    readonly state: State;
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
}> = ({ dispatch, state, attachment, entityPublicId, entityType }) => {
    const { trackEvent } = useAnalytics();
    const { rotate, zoomIndex } = state;
    const { url } = attachment;

    useLayoutEffect(() => {
        return () => {
            dispatch({ type: 'RESET' });
        };
    }, [dispatch, url]);

    const options = useMemo(
        () =>
            zoomValues.map((value, index) => ({
                label: `${value}%`,
                value: index,
            })),
        []
    );

    const downloadFile = (): void => {
        trackEvent('docs.gallery.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            name: 'download',
        });
        window.open(url.replaceAll('?inline=true', ''), '_blank');
    };

    return (
        <Flex className={imageActions} gap={spacing.s08}>
            <IconButton
                className={imageActionBg}
                color="neutral7"
                icon="rotate"
                onClick={() => dispatch({ type: 'SET_ROTATE', payload: rotate + 90 })}
                size="lg"
                variant="inline"
            />
            <Flex alignItems="center" className={imageActionBg} padding={`0 ${spacing.s10}`}>
                <IconButton
                    color="neutral7"
                    disabled={zoomIndex === 0}
                    icon="minus-solid"
                    onClick={() => dispatch({ type: 'SET_ZOOM_INDEX', payload: zoomIndex - 1 })}
                    variant="inline"
                />
                <Flex alignItems="center" justifyContent="center" padding={`0 ${spacing.s08} 0 0`}>
                    <Select<never, { value: number; label: string }>
                        className={zoomInput}
                        onChange={value => dispatch({ type: 'SET_ZOOM_INDEX', payload: value ?? 100 })}
                        options={options}
                        placeholder=""
                        selectedKey={zoomIndex}
                    />
                </Flex>
                <IconButton
                    color="neutral7"
                    disabled={zoomIndex === zoomValues.length - 1}
                    icon="plus-solid"
                    onClick={() => dispatch({ type: 'SET_ZOOM_INDEX', payload: zoomIndex + 1 })}
                    variant="inline"
                />
            </Flex>
            <IconButton
                className={imageActionBg}
                color="neutral7"
                icon="download"
                onClick={downloadFile}
                size="lg"
                variant="inline"
            />
        </Flex>
    );
};

export const GalleryImage: FC<{
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
}> = ({ attachment, entityPublicId, entityType }) => {
    const { fileName, url } = attachment;
    const [state, dispatch] = useReducer(reducer, initialState);
    const zoomValue = useMemo(() => zoomValues[state.zoomIndex], [state.zoomIndex]);

    return (
        <div className={imageWrapper}>
            <img
                alt={fileName ?? ''}
                className={image}
                loading="lazy"
                src={url}
                style={{ transform: `scale(${zoomValue}%) rotate(${state.rotate}deg)` }}
            />
            <ImageActions
                attachment={attachment}
                dispatch={dispatch}
                entityPublicId={entityPublicId}
                entityType={entityType}
                state={state}
            />
        </div>
    );
};
