import { Flex, spacing, Spinner } from '@lemonade-hq/blender-ui';
import {
    SummaryInnerSectionItemKey as Key,
    SummarySectionContent,
    SummarySectionTitle,
    SummaryInnerSectionItemValue as Value,
} from '@lemonade-hq/bluis';
import { LinkComp } from '@lemonade-hq/bluiza';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useMemo } from 'react';
import { CopyableText } from '../components/Copyable/CopyButton';
import { getVersionTypeDisplayName } from '../display-texts/common';
import { getFormattedDate } from '../helpers/dateHelpers';
import { getEditionApproveEligibility } from './editionHelpers';
import { getEditionUrl, getProductUrl } from 'components/LoCo/common/urlBuilders';
import { LabelWithTooltip } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedLabels';
import { StyledSection } from 'components/LoCo/LoCoPagesSharedStyles';
import type { Edition, EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { Product } from 'models/LoCo/Insurance/Product';
import { useGetEditionApproveEligibility, useGetEditionSummary } from 'queries/LoCo/Insurance/BaseEditionQueries';

function getEditionName(edition: Edition): string {
    return isDefined(edition.version) ? `${edition.friendlyName} (${edition.version})` : edition.friendlyName;
}

interface SummaryProps {
    readonly type: EditionType;
    readonly edition: Edition;
    readonly product: Product;
}

export const EditionSummarySection: React.FC<SummaryProps> = ({ type, edition, product }) => {
    const { data: approvalEligibility, isLoading: isLoadingApprovalEligibility } = useGetEditionApproveEligibility(
        edition.code,
        type
    );

    const { data: baseEdition, isLoading: isLoadingBaseEdition } = useGetEditionSummary(
        edition.baseEditionCode ?? '',
        type
    );

    const approveEligibilityNotification = useMemo(() => {
        if (!isDefined(approvalEligibility) || !isDefined(baseEdition)) return null;

        const approveEligibility = getEditionApproveEligibility(
            edition,
            approvalEligibility.editionAlerts,
            baseEdition
        );
        if (approveEligibility != null) {
            return (
                <LabelWithTooltip
                    label=""
                    tooltipContent={approveEligibility.text}
                    type={approveEligibility.isWarning ? 'error' : 'info'}
                />
            );
        }
    }, [approvalEligibility, baseEdition, edition]);

    return (
        <StyledSection>
            <SummarySectionTitle>Edition Details</SummarySectionTitle>
            <SummarySectionContent>
                <Key>Product</Key>
                <Value>
                    <CopyableText
                        textToCopy={product.code}
                        textToDisplay={<LinkComp url={getProductUrl(product.code)}>{product.name}</LinkComp>}
                        visibleOnHover
                    />{' '}
                </Value>
                <Key>Edition Description</Key>
                <Value>{edition.description}</Value>
                <Key>Created By</Key>
                <Value>{edition.addedByName}</Value>
                <Key>Edition Code</Key>
                <Value>
                    <CopyableText textToCopy={edition.code} textToDisplay={edition.code} visibleOnHover />
                </Value>
                {edition.baseEditionCode != null && (
                    <>
                        <Key>Base Edition</Key>
                        <Value>
                            {isLoadingApprovalEligibility || isLoadingBaseEdition ? (
                                <Spinner size="md" />
                            ) : (
                                <CopyableText
                                    textToCopy={edition.baseEditionCode}
                                    textToDisplay={
                                        <Flex gap={spacing.s06}>
                                            <LinkComp url={getEditionUrl(product.code, edition.baseEditionCode, type)}>
                                                {isDefined(baseEdition)
                                                    ? getEditionName(baseEdition)
                                                    : edition.baseEditionCode}
                                            </LinkComp>
                                            {approveEligibilityNotification}
                                        </Flex>
                                    }
                                    visibleOnHover
                                />
                            )}
                        </Value>
                    </>
                )}
                {edition.approvedAt != null && (
                    <>
                        <Key>Approved At</Key>
                        <Value>{getFormattedDate(edition.approvedAt)}</Value>
                        <Key>Approved By</Key>
                        <Value>{edition.approvedByName}</Value>
                    </>
                )}
                {edition.archivedAt != null && (
                    <>
                        <Key>Archived At</Key>
                        <Value>{getFormattedDate(edition.archivedAt)}</Value>
                        <Key>Archived By</Key>
                        <Value>{edition.archivedByName}</Value>
                    </>
                )}
                {edition.version != null && edition.versionType != null && (
                    <>
                        <Key>Version</Key>
                        <Value>{`${edition.version} (${getVersionTypeDisplayName(edition.versionType)})`}</Value>
                    </>
                )}
            </SummarySectionContent>
        </StyledSection>
    );
};
