import { FORCE_BLENDER_UI_THEME } from 'libs/blender-ui/src/theme/global.css';
import { useLayoutEffect } from 'react';

// enables fullscreen mode for the current page when mounted by adding global styles

export function useForceBlenderUI(): void {
    useLayoutEffect(() => {
        document.body.classList.add(FORCE_BLENDER_UI_THEME as string);
        return () => {
            document.body.classList.remove(FORCE_BLENDER_UI_THEME as string);
        };
    }, []);
}
