import type { TableProps } from '@lemonade-hq/blender-ui';
import { Card, Flex, Table } from '@lemonade-hq/blender-ui';

import type { FC } from 'react';
import { useGetGenericQueue } from './GenericQueueQueries';
import type { GetGenericQueueResponse } from './types';

const getTableColumns = (
    headers: GetGenericQueueResponse['headers'],
    sort: GetGenericQueueResponse['options']['sortBy']
): TableProps['columns'] =>
    headers.map(({ key, label }) => ({
        key,
        name: label,
        sortable: sort.some(({ datapoint }) => datapoint === key),
    }));

const getTableData = (rows: GetGenericQueueResponse['rows']): TableProps['data'] =>
    rows.map<TableProps['data'][number]>(({ cells }) =>
        cells.reduce((acc, { key, value }) => ({ ...acc, [key]: { value } }), {})
    );

export const GenericQueue: FC<{ readonly queueName: string }> = ({ queueName }) => {
    const { data } = useGetGenericQueue({
        queueName,
        size: 1,
        page: 1,
    });

    if (!data) return null;

    return (
        <Card>
            <Flex flexDirection="column">
                <Table columns={getTableColumns(data.headers, data.options.sortBy)} data={getTableData(data.rows)} />
            </Flex>
        </Card>
    );
};
