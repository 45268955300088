import { Tag, TagMode } from '@lemonade-hq/bluis';
import { Flex, font, themedColor } from '@lemonade-hq/cdk';
import { useMemo } from 'react';
import styled from 'styled-components';
import type { ConsensusResult, ReviewStats } from '../../../shared/types';
import { useSupervisorContext } from '../Context';

const ReviewsLeftLabel = styled.i`
    color: ${themedColor('secondaryText')};
`;

const Title = styled.h3`
    ${font('main', { fontSize: '14px', fontWeight: 700 })}
    margin: 0;
`;

const StyledTag = styled(Tag)`
    text-transform: unset;
`;

type ConsensusReviewProps = {
    readonly reviewStats: ReviewStats;
};

const ConsensusReview: React.FC<ConsensusReviewProps> = ({ reviewStats }) => {
    const { numberOfDistinctReviewsLeft, numberOfPassedReviews, numberOfFailedReviews } = reviewStats;

    const totalReviews = numberOfPassedReviews + numberOfFailedReviews;

    const { mode, status } = useMemo(() => {
        let internalStatus: ConsensusResult;
        let internalMode: TagMode;

        if (numberOfPassedReviews === numberOfFailedReviews) {
            internalStatus = 'TIE';
            internalMode = TagMode.Info;
        } else if (numberOfPassedReviews > numberOfFailedReviews) {
            internalStatus = 'PASS';
            internalMode = TagMode.Success;
        } else {
            internalStatus = 'FAIL';
            internalMode = TagMode.Error;
        }

        if (numberOfDistinctReviewsLeft > 0) {
            internalMode = TagMode.Info;
        }

        return { status: internalStatus, mode: internalMode };
    }, [numberOfDistinctReviewsLeft, numberOfPassedReviews, numberOfFailedReviews]);

    const summary = `${status} (${numberOfPassedReviews}/${totalReviews})`;

    return (
        <Flex alignItems="center" gap="6px">
            <StyledTag light mode={mode}>
                {summary}
            </StyledTag>
            {numberOfDistinctReviewsLeft > 0 && (
                <ReviewsLeftLabel>{numberOfDistinctReviewsLeft} Reviews left</ReviewsLeftLabel>
            )}
        </Flex>
    );
};

export const ReviewsSummary: React.FC = () => {
    const {
        state: { ticketData },
    } = useSupervisorContext();

    if (ticketData?.reviewStats == null) return null;

    return (
        <Flex flexDirection="column" gap="6px">
            <Title>Reviews Summary</Title>
            <ConsensusReview reviewStats={ticketData.reviewStats} />
        </Flex>
    );
};
