import type { EnvName } from '@lemonade-hq/bluiza';
import { getAxiosClient, ServiceName } from '@lemonade-hq/bluiza';

const envName = import.meta.env.VITE_ENV_NAME as EnvName;

export const blender = getAxiosClient(ServiceName.Blender, envName);

export const blenderGeneral = getAxiosClient(ServiceName.BlenderGeneral, envName);

export const homeBlender = getAxiosClient(ServiceName.HomeBlender, envName);

export const carBlender = getAxiosClient(ServiceName.CarBlender, envName);

export const petBlender = getAxiosClient(ServiceName.PetBlender, envName);

export const blenderRating = getAxiosClient(ServiceName.RatingBlender, envName);

export const insuranceBlender = getAxiosClient(ServiceName.InsuranceBlender, envName);
