import { EmptySection } from '@lemonade-hq/bluis';
import type { ComponentProps } from 'react';
import type { ColumnsToRow } from '../../../../common/components/GridTable/GridTable';
import { GridTable } from '../../../../common/components/GridTable/GridTable';
import { rulesCountDisplay } from 'components/LoCo/common/components/CoverageRules/CoverageRulesShared';
import { SettingInstanceValues } from 'components/LoCo/common/components/CoverageSettings/SettingValues';
import { unitDisplay } from 'components/LoCo/common/display-texts/setting-instance';
import type { SettingInstancesTableProps } from 'components/LoCo/editions/coverage-editions/Settings/SettingInstancesTable/SettingsTableShared';
import {
    scopeDisplay,
    SettingInstanceActions,
    SettingInstanceDuration,
    SettingName,
    SettingsTableExpendedDetails,
} from 'components/LoCo/editions/coverage-editions/Settings/SettingInstancesTable/SettingsTableShared';
import type { DeductibleSettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';

const deductibleTableColumns = [
    { key: 'name', title: 'Setting Name', width: '1.5fr' },
    { key: 'values', title: 'Values', width: '1.5fr' },
    { key: 'unit', title: 'Unit', width: '0.7fr' },
    { key: 'scope', title: 'Scope', width: '1fr' },
    { key: 'duration', title: 'Duration', width: '1fr' },
    { key: 'filedRules', title: 'Filed Rules', width: '120px' },
    { key: 'actions', title: '', width: '40px' },
] as const;

function getDeductibleRow(
    settingInstance: DeductibleSettingInstance,
    hideActions: boolean,
    onActionRequested: ComponentProps<typeof SettingInstanceActions>['onActionRequested']
): ColumnsToRow<typeof deductibleTableColumns> {
    return {
        name: { value: <SettingName settingInstance={settingInstance} /> },
        values: {
            value: <SettingInstanceValues settingInstance={settingInstance} />,
        },
        unit: { value: unitDisplay(settingInstance) },
        scope: { value: scopeDisplay(settingInstance) },
        duration: { value: <SettingInstanceDuration settingInstance={settingInstance} /> },
        filedRules: { value: rulesCountDisplay(settingInstance.relatedRules) },
        actions: {
            value: (
                <SettingInstanceActions
                    hideActions={hideActions}
                    instance={settingInstance}
                    onActionRequested={onActionRequested}
                />
            ),
        },
    };
}

export const DeductibleTable: React.FC<SettingInstancesTableProps<DeductibleSettingInstance>> = ({
    settingInstances,
    coveragesEdition,
    setDialog,
    hideActions,
}) => {
    return settingInstances.length === 0 ? (
        <EmptySection>No Limit deductible configured</EmptySection>
    ) : (
        <GridTable
            columns={deductibleTableColumns}
            rows={settingInstances.map(settingInstance => ({
                values: getDeductibleRow(settingInstance, hideActions, dialogType =>
                    setDialog({
                        type: dialogType,
                        data: settingInstance,
                        editionCoverages: coveragesEdition.coverages,
                    })
                ),
                expended: (
                    <SettingsTableExpendedDetails
                        editionCode={coveragesEdition.code}
                        hideActions={hideActions}
                        settingInstance={settingInstance}
                    />
                ),
            }))}
        />
    );
};
