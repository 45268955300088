import { Alert, Card as BluisCard, themedColor } from '@lemonade-hq/bluis';
import type { AlertMode, CardProps as BluisCardProps } from '@lemonade-hq/bluis';
import styled, { css } from 'styled-components';

export const CARD_BORDER_RADIUS = '16px';

export interface CardProps extends BluisCardProps {
    readonly alertProps?: {
        readonly mode: AlertMode;
        readonly title: JSX.Element | string;
    };
    readonly isDashed?: boolean;
    readonly isGlow?: boolean;
    readonly isWithPadding?: boolean;
}

const StyledCard = styled(BluisCard)<Pick<CardProps, 'isDashed' | 'isGlow'>>`
    border-radius: ${CARD_BORDER_RADIUS};
    box-shadow: 0px 10px 28px -2px rgba(0, 0, 0, 0.06);
    border-color: ${themedColor('lightBackground')};
    margin-top: 0;
    padding: 0;

    ${({ isDashed }) =>
        isDashed &&
        css`
            border: 1px dashed ${themedColor('inputBorder')};
        `}

    ${({ isGlow }) =>
        isGlow &&
        css`
            border: 1px solid rgba(255, 0, 131, 0.3);
            box-shadow: 0px 0px 24px 0px rgba(255, 0, 131, 0.12);
        `}
`;

const CardBody = styled.div<Pick<CardProps, 'isWithPadding'>>`
    padding: 24px;
    height: 100%;

    ${({ isWithPadding }) =>
        !isWithPadding &&
        css`
            padding: 0;
        `}
`;

const StyledAlert = styled(Alert)`
    border-radius: ${CARD_BORDER_RADIUS} ${CARD_BORDER_RADIUS} 0 0;
`;

export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({
    alertProps,
    children,
    isDashed,
    isGlow,
    isWithPadding = true,
    ...props
}) => {
    const isWithAlert = alertProps != null;

    return (
        <StyledCard isDashed={isDashed} isGlow={isGlow} {...props}>
            {isWithAlert && <StyledAlert {...alertProps} />}
            <CardBody isWithPadding={isWithPadding}>{children}</CardBody>
        </StyledCard>
    );
};
