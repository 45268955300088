import { Flex, Icon, Text } from '@lemonade-hq/blender-ui';
import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import type { FC } from 'react';
import { useArchiveToolsRevision } from '../../persisted_tools.queries';

interface ArchiveToolsRevisionDialogProps {
    readonly onClose: () => void;
    readonly toolsRevisionPublicId: string;
}

export const ArchiveToolsRevisionDialog: FC<ArchiveToolsRevisionDialogProps> = ({ onClose, toolsRevisionPublicId }) => {
    const { mutate: archive, isPending } = useArchiveToolsRevision();

    const handleArchive = async (): Promise<void> => {
        archive(toolsRevisionPublicId);
        onClose();
    };

    const actions: DialogAction[] = [
        {
            type: 'close',
            text: 'cancel',
        },
        {
            type: 'submit',
            text: 'Archive',
            onClick: handleArchive,
        },
    ];

    const dialogProps = {
        actions,
        closeOnOutsideClick: true,
        loading: isPending,
        onClose,
        title: `Archive tools revision ${toolsRevisionPublicId}`,
    };

    return (
        <Dialog {...dialogProps}>
            <Flex alignItems="center" justifyContent="center">
                <Icon color="attention1" name="alert-circle-solid" />
                &nbsp;
                <Text>All changes in this tools revision will be lost once archived.</Text>
            </Flex>
        </Dialog>
    );
};
