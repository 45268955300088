import { clsx } from 'clsx';
import type { ButtonHTMLAttributes, ElementType, Ref } from 'react';
import { forwardRef } from 'react';
import type { ThemeColor } from '../../theme/ThemeProvider';
import type { IconProps, IconSize } from '../Icon/Icon';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/types';
import { Spinner } from '../Spinner/Spinner';
import { iconButtonStyles } from './IconButton.css';

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly icon: IconName;
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly variant?: 'inline' | 'primary' | 'secondary';
  readonly size?: 'lg' | 'md' | 'sm';
  readonly iconSize?: IconSize;
  readonly color?: ThemeColor;
  readonly as?: keyof JSX.IntrinsicElements;
  readonly rotation?: IconProps['rotation'];
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      variant = 'primary',
      loading = false,
      size = 'md',
      className,
      icon,
      color,
      iconSize,
      as = 'button',
      disabled = false,
      rotation,
      ...rest
    }: IconButtonProps,
    ref: Ref<HTMLButtonElement>,
  ): JSX.Element => {
    const Component = as as ElementType;
    return (
      <Component
        className={clsx(iconButtonStyles({ variant, size }), className)}
        data-loading={loading}
        disabled={disabled || loading}
        ref={ref}
        type="button"
        {...rest}
      >
        {loading ? (
          <Spinner color={variant === 'primary' ? 'white' : 'pink'} size={size} />
        ) : (
          <Icon color={color} data-testid={icon} name={icon} rotation={rotation} size={iconSize ?? size} />
        )}
      </Component>
    );
  },
);
