import type { Descendant } from '@lemonade-hq/bluiza';
import { NOOP } from '@lemonade-hq/ts-helpers';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { NotesAnalyticsParams } from './types';

type DraftValue = Descendant[];

type StatusDescriptionDrafts = Record<string, string>;

interface NotesContextProps {
    readonly initNotes: (entityPublicId: string, notesAmount: number) => DraftValue;
    readonly updateNoteDraft: (entityPublicId: string, noteDraft: DraftValue) => void;
    readonly updateNotesAmount: (entityPublicId: string, notesAmount: number) => void;
    readonly getNotesAmount: (entityPublicId: string) => number | null;
    readonly focusOnNotes: () => void;
    readonly setNotesRef: (element: Element | null) => void;
    readonly isFocusing: boolean;
    readonly setIsFocusing: (isFocusing: boolean) => void;

    readonly statusDescriptionDrafts: StatusDescriptionDrafts;
    readonly setStatusDescriptionDrafts: (
        nextDrafts: StatusDescriptionDrafts | ((currentDrafts: StatusDescriptionDrafts) => StatusDescriptionDrafts)
    ) => void;
}

const initProps: NotesContextProps = {
    initNotes: () => [],
    updateNoteDraft: NOOP,
    updateNotesAmount: NOOP,
    getNotesAmount: () => 0,
    focusOnNotes: NOOP,
    setNotesRef: NOOP,
    isFocusing: false,
    setIsFocusing: NOOP,

    statusDescriptionDrafts: {},
    setStatusDescriptionDrafts: NOOP,
};

export const NotesContext = createContext<NotesContextProps>(initProps);

type NotesProviderState = Record<
    string,
    | {
          readonly amount: number;
          readonly currentNoteDraft: DraftValue;
      }
    | undefined
>;

export interface NotesProviderProps {
    readonly children: React.ReactNode;
    readonly analyticsParams?: NotesAnalyticsParams;
}

export const NotesProvider: React.FC<React.PropsWithChildren<NotesProviderProps>> = ({ children, analyticsParams }) => {
    const [notes, setNotes] = useState<NotesProviderState>({});
    const notesRef = useRef<Element | null>(null);
    const [isFocusing, setIsFocusing] = useState(false);

    const initNotes = useCallback(
        (entityPublicId: string, notesAmount: number): DraftValue => {
            const currentNoteDraft = notes[entityPublicId]?.currentNoteDraft ?? [];

            setNotes(prevNotes => {
                if (prevNotes[entityPublicId]) return prevNotes;

                return {
                    ...prevNotes,
                    [entityPublicId]: {
                        amount: notesAmount,
                        currentNoteDraft,
                    },
                };
            });

            return currentNoteDraft;
        },
        [notes, setNotes]
    );

    const updateNoteDraft = useCallback(
        (entityPublicId: string, noteDraft: DraftValue) => {
            setNotes(prevNotes => {
                if (!prevNotes[entityPublicId]) return prevNotes;

                return {
                    ...prevNotes,
                    [entityPublicId]: {
                        amount: prevNotes[entityPublicId]?.amount ?? 0,
                        currentNoteDraft: noteDraft,
                    },
                };
            });
        },
        [setNotes]
    );

    const updateNotesAmount = useCallback(
        (entityPublicId: string, amount: number) => {
            setNotes(prevNotes => {
                if (!prevNotes[entityPublicId]) return prevNotes;

                return {
                    ...prevNotes,
                    [entityPublicId]: {
                        amount,
                        currentNoteDraft: prevNotes[entityPublicId]?.currentNoteDraft ?? [],
                    },
                };
            });
        },
        [setNotes]
    );

    const getNotesAmount = useCallback(
        (entityPublicId: string) => {
            return notes[entityPublicId]?.amount ?? null;
        },
        [notes]
    );

    const focusOnNotes = useCallback(() => {
        setIsFocusing(true);
        notesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const setNotesRef = useCallback<NotesContextProps['setNotesRef']>(element => {
        notesRef.current = element;
    }, []);

    useEffect(() => {
        return () => {
            notesRef.current = null;
        };
    }, []);

    const [statusDescriptionDrafts, setStatusDescriptionDrafts] = useState<StatusDescriptionDrafts>({});

    const contextValue = useMemo(
        () => ({
            initNotes,
            updateNoteDraft,
            getNotesAmount,
            updateNotesAmount,
            focusOnNotes,
            setNotesRef,
            isFocusing,
            setIsFocusing,
            analyticsParams,
            statusDescriptionDrafts,
            setStatusDescriptionDrafts,
        }),
        [
            initNotes,
            updateNoteDraft,
            getNotesAmount,
            updateNotesAmount,
            focusOnNotes,
            setNotesRef,
            isFocusing,
            analyticsParams,
            statusDescriptionDrafts,
        ]
    );

    return <NotesContext.Provider value={contextValue}>{children}</NotesContext.Provider>;
};

interface NotesContextWithIdProps
    extends Omit<
        NotesContextProps,
        | 'getNotesAmount'
        | 'initNotes'
        | 'setStatusDescriptionDrafts'
        | 'statusDescriptionDrafts'
        | 'updateNoteDraft'
        | 'updateNotesAmount'
    > {
    readonly initNotes: (notesAmount: number) => DraftValue;
    readonly updateNoteDraft: (noteDraft: DraftValue) => void;
    readonly updateNotesAmount: (amount: number) => void;
    readonly notesAmount: number | null;
    readonly analyticsParams?: NotesAnalyticsParams;
    readonly statusDescriptionDraft: string;
    readonly setStatusDescriptionDraft: (nextDraft: string) => void;
}

export const useNotesContext = (entityPublicId: string): NotesContextWithIdProps => {
    const {
        initNotes,
        updateNoteDraft,
        getNotesAmount,
        updateNotesAmount,
        statusDescriptionDrafts,
        setStatusDescriptionDrafts,
        ...rest
    } = useContext(NotesContext);

    const contextValueWithId = useMemo<NotesContextWithIdProps>(
        () => ({
            initNotes: (notesAmount: number) => initNotes(entityPublicId, notesAmount),
            updateNoteDraft: (noteDraft: DraftValue) => updateNoteDraft(entityPublicId, noteDraft),
            updateNotesAmount: (amount: number) => updateNotesAmount(entityPublicId, amount),
            notesAmount: getNotesAmount(entityPublicId),
            statusDescriptionDraft: statusDescriptionDrafts[entityPublicId],
            setStatusDescriptionDraft: (nextDraft: string) => {
                setStatusDescriptionDrafts(prevDrafts => ({
                    ...prevDrafts,
                    [entityPublicId]: nextDraft,
                }));
            },
            ...rest,
        }),
        [
            getNotesAmount,
            entityPublicId,
            statusDescriptionDrafts,
            rest,
            initNotes,
            updateNoteDraft,
            updateNotesAmount,
            setStatusDescriptionDrafts,
        ]
    );

    return contextValueWithId;
};
