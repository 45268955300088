import { Alert, AlertMode, Spinner } from '@lemonade-hq/bluis';
import classNames from 'classnames';
import type { ReactElement } from 'react';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { wrap } from '../../../Commons/ArrayUtils';
import Actions from './Actions';
import { LightboxWrapper } from './style';

type ReturnString = () => string;
type ReturnBoolean = () => boolean;
type ReturnElement = () => ReactElement;
type ReturnActions = () => LightBoxAction[];

export const LIGHTBOX_Z_INDEX = 10_000;

export interface LightBoxOptions {
    readonly title?: string;
    readonly subtitle?: string;
    readonly notice?: JSX.Element | JSX.Element[] | string[] | string;
    readonly error?: JSX.Element | string | false | null;
    readonly actions?: LightBoxAction[] | ReturnActions;
    readonly className?: ReturnString | string;
    readonly footerCont?: ReactElement | ReturnElement;
    readonly embedded?: boolean;
    readonly isSubmitting?: boolean;
    readonly isSimulating?: boolean;
    readonly centered?: boolean;
    readonly desktopStyle?: boolean;
    readonly btnClose?: ReturnBoolean | boolean;
    readonly leftPanel?: ReactElement;
    readonly scrollable?: boolean;
    readonly closing?: boolean;
    readonly keepActionsVisibleOnServerError?: boolean;
    readonly closeByKeyboard?: boolean;
    readonly onOpen?: () => void;
    readonly onClose?: () => void;
}

export type LightBoxActionType = 'close' | 'submit';

interface ActionBase {
    readonly className?: ReturnString | string;
    readonly isDisabled?: ReturnBoolean | boolean;
    readonly text: ReturnString | string;
}

type SubmitAction = ActionBase & {
    readonly type: 'submit';
} & ({ readonly form: string; readonly onClick?: undefined } | { readonly onClick: () => void });

type CloseAction = ActionBase & {
    readonly type: 'close';
    readonly onClick: () => void;
};

type OtherAction = ActionBase & {
    readonly type: undefined;
    readonly onClick: () => void;
};

export type LightBoxAction = CloseAction | OtherAction | SubmitAction;

export const LightboxPortal: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    // 🌀🏃
    const wrapperElement = document.querySelector('#wrapper');

    return wrapperElement != null && children != null ? createPortal(children, wrapperElement) : null;
};

/**
 * @deprecated
 * use Dialog from @lemonade-hq/bluis instead
 */
const LightBox: React.FC<React.PropsWithChildren<{ readonly options: LightBoxOptions }>> = ({ options, children }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [actionError, setActionError] = useState<string>('');

    function onActionError(errorText: string): void {
        setActionError(errorText);
    }

    function close() {
        setIsOpen(false);
    }

    useEffect(() => {
        const { onOpen, onClose } = options;

        function handleKeyDown(e: KeyboardEvent) {
            const { closeByKeyboard = true } = options;
            const isEscKey = e.keyCode === 27;

            if (closeByKeyboard && isEscKey) {
                close();
            }
        }

        function handleTouchMove(e: TouchEvent) {
            e.preventDefault();
        }

        function cleanUp() {
            document.documentElement.removeEventListener('touchmove', handleTouchMove);
            document.documentElement.removeEventListener('keyup', handleKeyDown);

            document.documentElement.classList.remove('popup-visible');
            document.body.classList.remove('popup-visible');
        }
        if (isOpen) {
            if (onOpen) onOpen();

            document.documentElement.addEventListener('touchmove', handleTouchMove, false);
            document.documentElement.addEventListener('keyup', handleKeyDown, false);

            setTimeout(() => {
                document.documentElement.classList.add('popup-visible');
                document.body.classList.add('popup-visible');
            }, 500);
        }

        if (!isOpen) {
            if (onClose) onClose();

            cleanUp();
        }

        return cleanUp;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    if (!isOpen) return null;

    const {
        title,
        subtitle,
        notice,
        error,
        actions = [],
        className,
        footerCont,
        embedded,
        isSubmitting = false,
        isSimulating,
        centered = false,
        desktopStyle = true,
        btnClose = true,
        leftPanel,
        scrollable = false,
        closing,
        keepActionsVisibleOnServerError = false,
    } = options;
    const shouldBtnCloseAppear = typeof btnClose === 'function' ? btnClose() : btnClose;
    const actionsOpts = typeof actions === 'function' ? actions() : actions;
    const customClassName = typeof className === 'function' ? className() : className;
    const footerContent = typeof footerCont === 'function' ? footerCont() : footerCont;
    const closeButton = shouldBtnCloseAppear && (
        <button aria-label="Close" className="btn-close" onClick={close}>
            <span>×</span>
        </button>
    );

    const withError = () => {
        return Boolean(error);
    };

    const lightboxClass = classNames('bo-lightbox-bluis', customClassName, {
        closing,
        'lightbox-desktop': desktopStyle,
        submitting: isSubmitting && !withError(),
        simulating: isSimulating,
        'has-error': error,
    });

    const contentClass = classNames('lightbox-content', {
        'content-centered': centered,
    });

    const bodyClass = classNames('lightbox-body', {
        scrollable,
    });

    const embeddedErrorClass = classNames('embedded-error', {
        shown: embedded && error,
    });

    const stringifyError: JSX.Element | string | null | undefined = error === false ? '' : error;

    const hasNotice = Boolean(notice);
    const hasError = Boolean(actionError);

    return (
        <LightboxWrapper>
            <div className={lightboxClass}>
                <TransitionGroup appear>
                    <CSSTransition classNames="ani-lightbox-content" timeout={500}>
                        <div className="lightbox-container">
                            {leftPanel && <div className="lightbox-left-panel">{leftPanel}</div>}
                            <div className={contentClass}>
                                {title && (
                                    <div className="lightbox-header">
                                        <h4>{title}</h4>
                                        {subtitle && <h5>{subtitle}</h5>}
                                        {embedded && <button className="btn-back" onClick={close} />}
                                        {embedded && (
                                            <div className={embeddedErrorClass}>
                                                <p className="embedded-error-content">{error}</p>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {!embedded && closeButton}

                                <div className={bodyClass}>{children}</div>

                                {hasError && <Alert mode={AlertMode.Error} title={actionError} />}
                                {hasNotice && (
                                    <div className="lightbox-notice-container">
                                        {wrap(notice).map((n, i) => {
                                            return (
                                                <div className="lightbox-notice" key={i}>
                                                    {typeof n === 'string' ? <span>{n}</span> : n}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                {actionsOpts.length > 0 && (
                                    <Actions
                                        actions={actionsOpts}
                                        embedded={embedded}
                                        error={stringifyError}
                                        keepActionsVisibleOnServerError={keepActionsVisibleOnServerError}
                                        loading={isSubmitting}
                                        onActionError={onActionError}
                                        onClose={close}
                                        withError={withError()}
                                    />
                                )}
                                {isSimulating && <Spinner size="small" />}
                                {footerContent}
                            </div>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </LightboxWrapper>
    );
};

export default LightBox;
