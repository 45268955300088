import type { MentionsChangeProps } from '@lemonade-hq/cdk';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getMentionOptions } from '../apis/MentionsAPI';
import type { MentionUser } from '../types';

enum MentionsKey {
  GetMentionOptions = 'GET_MENTION_OPTIONS',
}

export function useGetMentionUsers(
  mentionProps: MentionsChangeProps,
  enabled?: boolean,
): UseQueryResult<MentionUser[]> {
  return useQuery({
    queryKey: [MentionsKey.GetMentionOptions, mentionProps.searchQuery],
    queryFn: async () => await getMentionOptions(mentionProps.searchQuery),
    enabled: mentionProps.isSearching && enabled,
  });
}
