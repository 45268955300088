import type { FC } from 'react';
import { NonExpandableChange } from '../NonExpandableChange';
import type { SchemaUpdatedEntry } from 'models/LoCo/Insurance/ChangesLog';
import { ChangeType } from 'models/LoCo/Insurance/ChangesLog';

interface SchemaUpdatedProps {
    readonly change: SchemaUpdatedEntry;
    readonly platformSchemaRevision: number;
    readonly productSchemaRevision: number;
    readonly basePlatformSchemaRevision: number | null;
    readonly baseProductSchemaRevision: number | null;
}

export const SchemaUpdated: FC<SchemaUpdatedProps> = ({
    change,
    basePlatformSchemaRevision,
    baseProductSchemaRevision,
    platformSchemaRevision,
    productSchemaRevision,
}) => {
    const schemaChanged =
        change.changeType === ChangeType.ProductSchemaUpdated ? 'Product schema updated' : 'Platform schema updated';

    const version =
        change.changeType === ChangeType.ProductSchemaUpdated ? productSchemaRevision : platformSchemaRevision;

    const baseVersion =
        change.changeType === ChangeType.ProductSchemaUpdated ? baseProductSchemaRevision : basePlatformSchemaRevision;

    const fromVersion = baseVersion !== null ? ` from version ${baseVersion} ` : '';

    const toVersion = ` to version ${version}`;

    return <NonExpandableChange title={`${schemaChanged}${fromVersion}${toVersion}`} />;
};
