import {
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertMode,
    Card,
    ChevronIcon,
    MainButton,
} from '@lemonade-hq/bluis';

import type { MacrosInitProps } from '@lemonade-hq/bluiza';
import { RichTextEditor } from '@lemonade-hq/bluiza';
import { font, themedColor, Tooltip, useAnalytics } from '@lemonade-hq/boutique';
import {
    editorValueIsEmpty,
    hasMacrosInEditor,
    hasReachedMaxLength,
    useAccordion,
    useRichTextEditor,
    useRichTextEditorState,
} from '@lemonade-hq/cdk';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useNotesContext } from '../../context';
import type { AdditionalEntity, DraftValue } from '../../types';

const Heading = styled.h3<{ readonly isActive: boolean }>`
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    ${({ isActive }) =>
        isActive &&
        css`
            color: ${themedColor('primary')};
        `}
`;

const AddIcon = styled.div.attrs({ children: <span>+</span> })<{ readonly isActive: boolean }>`
    ${font('main', { fontSize: '22px', fontWeight: 700 })}
    ${({ isActive }) =>
        isActive &&
        css`
            color: ${themedColor('primary')};
        `}
`;

const StyledAccordionDetails = styled(AccordionDetails)<{ readonly isOpen: boolean }>`
    cursor: text;
    overflow: initial;
`;

const StyledRichTextEditor = styled(RichTextEditor)`
    max-height: 300px;
    overflow-y: auto;
    background-color: ${themedColor('disabledBackground')};
    width: calc(100% - 32px);
    margin: 0 auto 16px;
    border-radius: 4px;
    padding: 12px 16px;
`;

const StyledCard = styled(Card)<{ readonly isOpen: boolean; readonly disabled?: boolean }>`
    padding: 0;
    background-color: ${themedColor('componentBackground')};
    border: 1px solid ${themedColor('separator')};

    ${({ disabled }) => {
        if (!disabled) return;

        return css`
            pointer-events: none;
            box-shadow: none;
            opacity: 0.5;
        `;
    }}

    ${({ isOpen }) => {
        if (!isOpen) return;

        return css`
            border-color: ${themedColor('inputBorder')};
        `;
    }}
`;

const StyledAccordionSummary = styled(AccordionSummary)`
    text-align: left;
    height: auto;
    padding: 10px 16px;

    & > button {
        padding: 0;
    }

    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
        color: ${themedColor('primary')};
    }
`;

const StyledAlert = styled(Alert)`
    font-weight: bold;
`;

const StyledChevronIcon = styled(ChevronIcon)<{ readonly isOpen: boolean }>`
    margin: 0 0 0 auto;
    transition:
        transform 0.4s,
        stroke 0.4s;
    ${({ isOpen }) => {
        if (!isOpen) return;

        return css`
            transform: rotate(180deg);
        `;
    }}
`;

const MAX_CHAR_LENGTH = 6000;
const MAX_NOTES_AMOUNT = 20;

export interface NoteEditorProps {
    readonly openedByDefault?: boolean;
    readonly entityId: string;
    readonly macrosInitProps?: MacrosInitProps;
    readonly draftValue: DraftValue | null;
    readonly isAdding: boolean;
    readonly showMentions?: boolean;
    readonly onAddNote: (
        value: DraftValue,
        onFailed: () => void,
        entities?: AdditionalEntity[],
        analyticsProps?: Record<string, string>
    ) => Promise<void>;
    readonly renderFooter?: () => React.ReactNode;
    readonly renderSubmitButton?: (props: {
        readonly isDisabled: boolean;
        readonly isLoading: boolean;
        readonly onAddNote: (entities?: AdditionalEntity[], analyticsProps?: Record<string, string>) => Promise<void>;
    }) => React.ReactNode;
}

export const NoteEditor: FC<React.PropsWithChildren<NoteEditorProps>> = ({
    draftValue,
    entityId,
    showMentions,
    onAddNote,
    isAdding,
    macrosInitProps,
    renderFooter,
    renderSubmitButton,
}) => {
    const { updateNoteDraft, notesAmount, analyticsParams } = useNotesContext(entityId);
    const { trackEvent } = useAnalytics();
    const editor = useRichTextEditor();
    const [value, setValue, resetEditor, rerenderEditor] = useRichTextEditorState({ editor });
    const { toggle, isOpen } = useAccordion();
    const isEmpty = useMemo(() => editorValueIsEmpty(value), [value]);

    const isEditorDisabled = useMemo(() => {
        if (isEmpty) return true;
        if (hasMacrosInEditor(value)) return true;
        if (hasReachedMaxLength(editor, MAX_CHAR_LENGTH + 1)) return true;

        return false;
    }, [editor, isEmpty, value]);

    useEffect(() => {
        if (!draftValue || editorValueIsEmpty(draftValue)) return;

        setValue(draftValue);
        rerenderEditor();
    }, [draftValue, setValue, rerenderEditor]);

    const handleNoteChange = (newValue: DraftValue): void => {
        setValue(newValue);
        updateNoteDraft(newValue);
    };

    const handleAddNote = useCallback(
        async (entities: AdditionalEntity[] = [], analyticsProps?: Record<string, string>): Promise<void> => {
            const onAddNoteFailed = (): void => {
                setValue(value);
                rerenderEditor();
                toggle?.();
            };

            await onAddNote(value, onAddNoteFailed, entities, analyticsProps);
            toggle?.();
            resetEditor();
            rerenderEditor();
        },
        [onAddNote, value, toggle, resetEditor, rerenderEditor, setValue]
    );

    const handleEditorToggle = (): void => {
        if (isOpen && analyticsParams?.notesAccordionClosed) trackEvent(analyticsParams.notesAccordionClosed.name);
        if (!isOpen && analyticsParams?.notesAccordionOpened) trackEvent(analyticsParams.notesAccordionOpened.name);
    };

    const maxLengthMessage = useMemo(() => {
        if (editorValueIsEmpty(value)) return null;
        if (!hasReachedMaxLength(editor, MAX_CHAR_LENGTH)) return null;

        return <StyledAlert flat mode={AlertMode.Attention} title="You've reached the maximum amount of characters" />;
    }, [editor, value]);

    const customFooter = useMemo(() => {
        if (renderFooter != null) {
            return (
                <>
                    {maxLengthMessage}
                    {renderFooter()}
                </>
            );
        }

        return maxLengthMessage;
    }, [maxLengthMessage, renderFooter]);

    const submitButton = useMemo(() => {
        if (renderSubmitButton != null) {
            return renderSubmitButton({ isDisabled: isEditorDisabled, isLoading: isAdding, onAddNote: handleAddNote });
        }

        return (
            <MainButton disabled={isEditorDisabled} loading={isAdding} onClick={async () => await handleAddNote()}>
                ADD
            </MainButton>
        );
    }, [renderSubmitButton, isAdding, isEditorDisabled, handleAddNote]);

    const isReachedNoteLimit = notesAmount != null && notesAmount >= MAX_NOTES_AMOUNT;

    if (isReachedNoteLimit) {
        return (
            <Tooltip alignment="bottom-center" content="You've reached the maximum amount of notes">
                <div>
                    <StyledCard aria-disabled="true" disabled isOpen={false}>
                        <StyledAccordionSummary hideDivider>
                            <AddIcon isActive={false} />
                            <Heading isActive={false}>Add note</Heading>
                            <StyledChevronIcon isOpen={false} />
                        </StyledAccordionSummary>
                    </StyledCard>
                </div>
            </Tooltip>
        );
    }

    return (
        <StyledCard isOpen={isOpen} onClick={handleEditorToggle}>
            <StyledAccordionSummary hideDivider>
                <AddIcon isActive={isOpen} />
                <Heading isActive={isOpen}>Add note</Heading>
                <StyledChevronIcon isOpen={isOpen} />
            </StyledAccordionSummary>

            <StyledAccordionDetails isOpen={isOpen}>
                <StyledRichTextEditor
                    bordered={false}
                    customFooter={customFooter}
                    editor={editor}
                    macrosInitProps={macrosInitProps}
                    maxLength={MAX_CHAR_LENGTH}
                    onChange={handleNoteChange}
                    placeholder="Add a note..."
                    showMentions={showMentions}
                    toolbarEndComponent={submitButton}
                    value={value}
                />
            </StyledAccordionDetails>
        </StyledCard>
    );
};
