import type { AuthErrorResponseData } from '@lemonade-hq/bluis';
import {
  AUTH_ERROR_ASSIST_MSG,
  Dialog,
  ErrorSection,
  isAuthorizationError,
  LockIcon,
  SummarySection,
  SummarySectionTitle,
} from '@lemonade-hq/bluis';
import { Flex, font, themedColor } from '@lemonade-hq/cdk';
import type { AxiosError } from 'axios';
import { useState } from 'react';
import styled from 'styled-components';
import { getAuthorizationPolicyName, isLoadingChunkError } from './utils';

const StyledDialog = styled(Dialog)`
  /* For cases where the "Permissions Needed" Dialog is opened along to another Dialog */
  position: fixed;
  z-index: 10000;
`;

const StyledSummaryBody = styled(Flex)`
  align-items: center;
`;

const StyledSummaryTitle = styled(SummarySectionTitle)`
  border-right: none;
`;

const SummaryLockIcon = styled(LockIcon)`
  height: 36px;
  width: 36px;
  margin-right: 20px;
`;

const SubTitle = styled.h3`
  font-size: 18px;
  line-height: 23px;
  color: ${themedColor('primaryText')};
`;

const StyledLockIcon = styled(LockIcon)`
  height: 67px;
  width: 67px;
  margin: 80px 0 25px 0;
`;

const Title = styled.h1`
  ${font('alt', { fontSize: '32px', lineHeight: '40px' })};
  margin-bottom: 5px;
  color: ${themedColor('primaryText')};
`;

const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export interface FallBackBaseProps {
  readonly error?: unknown;
  readonly policyName?: string;
  readonly onClose?: () => void;
}

interface AuthErrorMessageProps {
  readonly renderedPolicyName: string;
}

function getAuthErrorDetails(
  error: AxiosError<AuthErrorResponseData>,
  policyName?: string,
): {
  readonly renderedPolicyName: string;
  readonly customMessage: string | undefined;
} {
  const authError = error;
  const renderedPolicyName = policyName ?? getAuthorizationPolicyName(authError);
  const customMessage = authError.response?.data.data?.customMessage;

  return { renderedPolicyName, customMessage };
}

const AuthErrorMessage: React.FC<AuthErrorMessageProps> = ({ renderedPolicyName }) => (
  <>
    {AUTH_ERROR_ASSIST_MSG}
    <br />
    Include this reference: <b>“{renderedPolicyName}”</b> authorization policy
  </>
);

export const PageAuthErrorFallback: React.FC<FallBackBaseProps> = ({ error, policyName }) => {
  const { renderedPolicyName, customMessage } = getAuthErrorDetails(
    error as AxiosError<AuthErrorResponseData>,
    policyName,
  );

  return (
    <Container>
      <StyledLockIcon />
      <Title>You shall not pass!</Title>
      <SubTitle>It looks like you don’t have permission to access this page.</SubTitle>

      <p>{customMessage ?? <AuthErrorMessage renderedPolicyName={renderedPolicyName}></AuthErrorMessage>}</p>
    </Container>
  );
};

export const PermissionNeededDialog: React.FC<FallBackBaseProps> = ({ error, policyName, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  function closeDialog(): void {
    onClose?.();
    setIsOpen(false);
  }

  const { renderedPolicyName, customMessage } = getAuthErrorDetails(
    error as AxiosError<AuthErrorResponseData>,
    policyName,
  );

  return isOpen ? (
    <StyledDialog
      actions={[{ type: 'submit', onClick: closeDialog, text: 'GOT IT' }]}
      onClose={closeDialog}
      title="PERMISSION NEEDED"
    >
      Looks like you don’t have permission to do that.
      <br />
      {customMessage ?? <AuthErrorMessage renderedPolicyName={renderedPolicyName}></AuthErrorMessage>}
    </StyledDialog>
  ) : null;
};

export const RefreshDialogFallback: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  function handleReload(): void {
    setIsLoading(true);
    window.location.reload();
  }

  return (
    <Dialog
      actions={[{ type: 'submit', onClick: handleReload, text: 'Refresh' }]}
      loading={isLoading}
      title="Refresh needed"
    >
      You are using an outdated version of Blender.
      <br />
      Please refresh the page before any further work.
    </Dialog>
  );
};

interface SectionErrorFallbackProps extends FallBackBaseProps {
  readonly title?: string;
}

export const SectionAuthErrorFallback: React.FC<SectionErrorFallbackProps> = ({ title, error, policyName }) => {
  const { renderedPolicyName, customMessage } = getAuthErrorDetails(
    error as AxiosError<AuthErrorResponseData>,
    policyName,
  );

  return (
    <SummarySection>
      {title != null && <StyledSummaryTitle>{title}</StyledSummaryTitle>}
      <StyledSummaryBody>
        <SummaryLockIcon />
        <p>
          Looks like you don’t have permission to view this section.
          <br />
          {customMessage ?? <AuthErrorMessage renderedPolicyName={renderedPolicyName}></AuthErrorMessage>}
        </p>
      </StyledSummaryBody>
    </SummarySection>
  );
};

type BoundaryArgs =
  | {
      readonly type: 'section';
      readonly title: string;
    }
  | { readonly type: 'page' };

export function getFallbackComponent({
  error,
  boundaryArgs,
}: {
  readonly error: unknown;
  readonly boundaryArgs?: BoundaryArgs;
}): React.ReactNode | null {
  if (isLoadingChunkError(error)) {
    return <RefreshDialogFallback />;
  }

  if (isAuthorizationError(error)) {
    switch (boundaryArgs?.type) {
      case 'page':
        return <PageAuthErrorFallback error={error} />;
      case 'section':
        return <SectionAuthErrorFallback error={error} title={boundaryArgs.title} />;
      default:
        return <PermissionNeededDialog error={error} />;
    }
  }

  return <ErrorSection />;
}
