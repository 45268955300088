import type { DialogAction } from '@lemonade-hq/bluis';
import { AlertMode, Dialog, InlineButton, Input, Multiselect, Select } from '@lemonade-hq/bluis';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormTextArea } from '../SharedStyles';
import type { DialogTypes } from '../UnderwritingDialogs';
import type { ActionType, ReasonEntityType, SpecificExplanationItem } from '../underwritingUtils';
import {
    compareExplanationsCountryAndState,
    getDecisionTypeText,
    getExplanationOptions,
    getManageEntityDescriptionLabel,
    isCountryUS,
    isDraftReasonVersion,
    MIN_INTERNAL_NOTE_CHARS,
} from '../underwritingUtils';
import { SpecificExplanation } from './SpecificExplanation';
import { isEmptyString, validateMinChars } from 'components/LoCo/common/helpers/inputHelpers';
import { ProductLineErrorMessage } from 'components/LoCo/global-registry/coverages-registry/CoveragesAndSettingsRegistry';
import { StyledFormInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import { DialogType, generateGUID } from 'components/LoCo/products/SharedTableConfig';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type { Reason, ReasonVersion } from 'models/LoCo/Insurance/UnderwritingRegistry';
import { useGetProductLines } from 'queries/LoCo/Insurance/ProductLineQueries';
import type { SpecificExplanationSchema } from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';
import {
    useCloneReason,
    useCreateReason,
    useGetRegistryExplanationSummaries,
    useUpdateReason,
} from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledInlineButton = styled(InlineButton)`
    align-self: flex-start;
`;

const StyledFormRow = styled(StyledFormInputWrapper)`
    > div {
        align-items: flex-start;
        > label {
            padding-top: 10px;
        }
    }
`;

type ReasonFormFields = Omit<Reason, 'reasonVersions'> &
    Pick<ReasonVersion, 'defaultExplanationCode' | 'defaultExplanationName' | 'note' | 'specificExplanations'>;

function transformReasonVersionToFormFields(
    reason: Reason,
    reasonVersion: ReasonVersion,
    dialogType: DialogType
): ReasonFormFields {
    return {
        defaultExplanationCode: reasonVersion.defaultExplanationCode,
        defaultExplanationName: reasonVersion.defaultExplanationName,
        note: isDraftReasonVersion(reasonVersion) || dialogType === DialogType.View ? reasonVersion.note : '', // keep note when editing a draft, empty when cloning a version
        name: reason.name,
        productLines: reason.productLines,
        code: reason.code,
        type: reason.type,
        specificExplanations: reasonVersion.specificExplanations,
    };
}

function areAllSpecificCountriesAndStatesValid(specificExplanationItems: SpecificExplanationItem[]): boolean {
    const res = specificExplanationItems.every(
        specificExplanation =>
            !isEmptyString(specificExplanation.code) &&
            specificExplanation.country.length > 0 &&
            (isCountryUS(specificExplanation.country) ? (specificExplanation.state?.length ?? 0) > 0 : true)
    );
    return res;
}

function validateExplanation(updatedValues: SpecificExplanationItem): boolean {
    return (
        updatedValues.country.length > 0 &&
        updatedValues.code.length > 0 &&
        (!isCountryUS(updatedValues.country) || validateMinChars(updatedValues.state, 2, false))
    );
}

// a consolidated state, based on useForm and a dynamic array, since useForm doesn't support arrays
// disabled return type - long and cumbersome return type...
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useFormWithArray(editedEntity: ReasonFormFields | null) {
    const [specificExplanationsState, setSpecificExplanationsState] = useState<SpecificExplanationItem[]>(
        editedEntity?.specificExplanations.map(specificExplanation => ({
            ...specificExplanation,
            guid: generateGUID(),
            isValid: true,
        })) ?? []
    );

    const { values, setValue, valid, errors } = useForm({
        fields: {
            name: {
                startValue: editedEntity?.name ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            internalNote: {
                startValue: editedEntity?.note ?? '',
                validations: {
                    required: basicRequiredValidation,
                    charMinLength: {
                        errorMessage: `Enter at least ${MIN_INTERNAL_NOTE_CHARS} characters`,
                        test: (value: string) => {
                            return value.trim().length >= MIN_INTERNAL_NOTE_CHARS;
                        },
                        skipCondition: formValues => isEmptyString(formValues.internalNote),
                    },
                },
            },
            productLines: {
                startValue: editedEntity?.productLines.map(productLine => productLine.code) ?? [],
                validations: {
                    required: {
                        errorMessage: '',
                        test: (value: string[]) => value.length > 0,
                        skipCondition: () => isDefined(editedEntity),
                    },
                },
            },
            defaultExplanationCode: {
                startValue: editedEntity?.defaultExplanationCode,
                validations: {},
            },
            defaultExplanationName: {
                startValue: editedEntity?.defaultExplanationName ?? '',
                validations: {},
            },
        },
    });

    const mergedValues = {
        ...values,
        specificExplanations: specificExplanationsState,
    };

    return {
        values: mergedValues,
        setValue,
        errors,
        valid: valid && specificExplanationsState.every(s => s.isValid),
        setArray: setSpecificExplanationsState,
    };
}

interface ManageReasonVersionDialogProps {
    readonly onClose: () => void;
    readonly originalEntity:
        | Extract<DialogTypes<ReasonEntityType>, { readonly type: ActionType.CreateOrEditDraft }>['originalEntity']
        | null;
    readonly decisionType: UnderwritingDecisionLifecycleContext;
}

export const ManageReasonVersionDialog: React.FC<ManageReasonVersionDialogProps> = ({
    onClose,
    originalEntity,
    decisionType,
}) => {
    const dialogType: DialogType = isDefined(originalEntity)
        ? originalEntity.isEditable
            ? DialogType.Edit
            : DialogType.View
        : DialogType.Add;

    const { values, setValue, valid, setArray, errors } = useFormWithArray(
        isDefined(originalEntity)
            ? transformReasonVersionToFormFields(originalEntity.entity, originalEntity.entityVersion, dialogType)
            : null
    );

    const showProductLines = dialogType !== DialogType.View && !isDefined(originalEntity); // show only in case of brand new reason

    const {
        isError: productLinesError,
        isLoading: isLoadingProductLines,
        data: productLines,
    } = useGetProductLines(showProductLines);
    const {
        error: explanationsError,
        isLoading: isLoadingExplanations,
        data: explanations,
    } = useGetRegistryExplanationSummaries(decisionType, values.productLines);

    const { error: errorCreate, mutateAsync: createReason, isPending: isCreatingReason } = useCreateReason();

    const {
        error: errorUpdate,
        mutateAsync: updateReason,
        isPending: isUpdatingReason,
    } = useUpdateReason(originalEntity?.entityVersion.publicId ?? '');

    const {
        error: errorClone,
        mutateAsync: cloneReason,
        isPending: isCloningReason,
    } = useCloneReason(originalEntity?.entityVersion.publicId ?? '');

    const handleSave = useCallback(async () => {
        const specificExplanations = values.specificExplanations.map<SpecificExplanationSchema>(
            specificExplanation => ({
                country: specificExplanation.country,
                state: specificExplanation.state,
                explanationCode: specificExplanation.code,
            })
        );

        if (isDefined(originalEntity)) {
            if (isDraftReasonVersion(originalEntity.entityVersion)) {
                await updateReason({
                    note: values.internalNote,
                    defaultExplanationCode: values.defaultExplanationCode,
                    specificExplanations,
                    type: decisionType,
                });
            } else {
                await cloneReason({
                    note: values.internalNote,
                    defaultExplanationCode: values.defaultExplanationCode,
                    specificExplanations,
                    type: decisionType,
                });
            }
        } else {
            await createReason({
                name: values.name,
                note: values.internalNote,
                productLineCodes: values.productLines,
                defaultExplanationCode: values.defaultExplanationCode,
                specificExplanations,
                type: decisionType,
            });
        }

        onClose();
    }, [
        originalEntity,
        onClose,
        updateReason,
        values.internalNote,
        values.productLines,
        values.defaultExplanationCode,
        values.specificExplanations,
        values.name,
        decisionType,
        cloneReason,
        createReason,
    ]);

    const actions = useMemo<DialogAction[]>(() => {
        const actionsArray: DialogAction[] = [
            {
                text: dialogType === DialogType.View ? 'Close' : 'Cancel',
                type: 'close',
                onClick: onClose,
            },
        ];

        if (!isDefined(originalEntity) || originalEntity.isEditable) {
            actionsArray.push({ text: 'Save draft', type: 'submit', disabled: !valid, onClick: handleSave });
        }

        return actionsArray;
    }, [dialogType, onClose, originalEntity, valid, handleSave]);

    const productLineOptions = useMemo(
        () =>
            isDefined(productLines)
                ? productLines.map(productLine => ({
                      id: productLine.code,
                      value: productLine.code,
                      label: productLine.name,
                  }))
                : [],
        [productLines]
    );

    const defaultExplanationCodeOptions = getExplanationOptions(
        values.defaultExplanationCode,
        values.defaultExplanationName,
        explanations
    );

    const explanationExcludingDefault = explanations?.filter(
        explanation => explanation.code !== values.defaultExplanationCode
    );

    const errorMessage = productLinesError
        ? ProductLineErrorMessage
        : errorCreate?.toString() ??
          errorUpdate?.toString() ??
          errorClone?.toString() ??
          explanationsError?.toString() ??
          undefined;

    const disableForm =
        isLoadingProductLines ||
        isCloningReason ||
        isLoadingExplanations ||
        isUpdatingReason ||
        isCreatingReason ||
        dialogType === DialogType.View;

    const descriptionLabel = useMemo(() => {
        return getManageEntityDescriptionLabel(dialogType, originalEntity?.entityVersion.version);
    }, [dialogType, originalEntity]);

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={errorMessage}
            loading={isUpdatingReason || isCreatingReason || isCloningReason}
            notice={
                dialogType !== DialogType.View
                    ? [
                          {
                              mode: AlertMode.Info,
                              title: `After you save changes as a draft, they won't be visible in production until the version is published.`,
                          },
                      ]
                    : undefined
            }
            onClose={onClose}
            size="large"
            title={`${dialogType === DialogType.Add ? 'Add ' : dialogType === DialogType.Edit ? 'Edit ' : ''} ${getDecisionTypeText(decisionType)} Reason`}
        >
            <FormWrapper>
                <StyledFormInputWrapper inputWidth="250px" label="Internal Name">
                    <Input
                        disabled={disableForm || isDefined(originalEntity)}
                        onChange={event => setValue('name', event.target.value)}
                        value={values.name}
                    />
                </StyledFormInputWrapper>
                <StyledFormRow
                    errorMessages={[errors.internalNote?.charMinLength]}
                    label={descriptionLabel}
                    showErrors={!isEmptyString(errors.internalNote?.charMinLength ?? '')}
                >
                    <FormTextArea
                        disabled={disableForm}
                        onChange={text => setValue('internalNote', text)}
                        value={values.internalNote}
                        width="250px"
                    />
                </StyledFormRow>
                {showProductLines && (
                    <StyledFormInputWrapper inputWidth="250" label="Product Lines">
                        <Multiselect
                            disabled={disableForm}
                            options={productLineOptions}
                            placeholder="Select"
                            selectedIds={values.productLines}
                            setSelectedOptions={productLineOption =>
                                setValue(
                                    'productLines',
                                    productLineOption.map(option => option.value)
                                )
                            }
                            width={250}
                        />
                    </StyledFormInputWrapper>
                )}
                <StyledFormInputWrapper label="Default Explanation">
                    <Select
                        disabled={defaultExplanationCodeOptions.length === 0 || disableForm}
                        onOptionSelected={option => {
                            setValue('defaultExplanationCode', option.value);
                            // persist the name as well, to retain it if the product lines change and it is no longer available
                            setValue('defaultExplanationName', option.label?.toString() ?? '');
                        }}
                        options={defaultExplanationCodeOptions}
                        placeholder={isLoadingExplanations ? 'Loading...' : 'Select'}
                        value={values.defaultExplanationCode ?? ''}
                        width={250}
                    />
                </StyledFormInputWrapper>
                {values.specificExplanations.map(specificExplanation => (
                    <SpecificExplanation
                        allItems={values.specificExplanations.filter(
                            explanation => explanation.code !== values.defaultExplanationCode
                        )}
                        availableExplanations={getExplanationOptions(
                            specificExplanation.code,
                            specificExplanation.name,
                            explanationExcludingDefault
                        )}
                        defaultExplanationCode={values.defaultExplanationCode}
                        disabled={disableForm}
                        isLoading={isLoadingExplanations || isLoadingProductLines}
                        key={specificExplanation.country + specificExplanation.state}
                        onChange={item => {
                            setArray(
                                values.specificExplanations.map(explanation =>
                                    compareExplanationsCountryAndState(explanation, specificExplanation)
                                        ? ({ ...item, isValid: validateExplanation(item) } as SpecificExplanationItem)
                                        : explanation
                                )
                            );
                        }}
                        onRemove={() =>
                            setArray(
                                values.specificExplanations.filter(
                                    explanation => !compareExplanationsCountryAndState(explanation, specificExplanation)
                                )
                            )
                        }
                        showErrors
                        specificExplanationItem={specificExplanation}
                    />
                ))}
                {dialogType !== DialogType.View && (
                    <StyledInlineButton
                        disabled={
                            values.defaultExplanationCode?.length === 0 ||
                            disableForm ||
                            !areAllSpecificCountriesAndStatesValid(values.specificExplanations)
                        }
                        onClick={() =>
                            setArray([
                                ...values.specificExplanations,
                                {
                                    isValid: false,
                                    country: '',
                                    code: '',
                                    name: '',
                                },
                            ])
                        }
                    >
                        {`+ Add ${values.specificExplanations.length > 0 ? ' another' : ''} state/country-specific explanation`}
                    </StyledInlineButton>
                )}
            </FormWrapper>
        </Dialog>
    );
};
