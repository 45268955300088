import { Select } from '@lemonade-hq/bluis';

import { capitalize } from '@lemonade-hq/ts-helpers';
import { InputWrapper } from '../../../InputWrapper';
import { useManageRuleDialogContext } from '../../ManageRuleDialogContext';
import { Outcome } from './Outcome';
import { CoverageRestrictionValue, RuleOutcomeType } from 'models/LoCo/Insurance/CoverageRule';

export const CoverageRestriction: React.FC<{ readonly coverageName: string }> = ({ coverageName }) => {
    const {
        values: { outcome, entityType },
        dispatch,
    } = useManageRuleDialogContext();
    if (entityType == null) throw new Error('Entity type is required');

    return (
        <Outcome coverageOrSettingName={coverageName} outcomeTitle={'Restriction'} ruleEntityType={entityType}>
            <InputWrapper label="Restriction type">
                <Select
                    onOptionSelected={option => {
                        dispatch({
                            type: 'outcome',
                            value: { type: RuleOutcomeType.CoverageRestrictionValueOutcome, value: option.value },
                        });
                    }}
                    options={[
                        {
                            value: CoverageRestrictionValue.Require,
                            label: capitalize(CoverageRestrictionValue.Require),
                            id: 'require',
                        },
                        {
                            value: CoverageRestrictionValue.Forbid,
                            label: capitalize(CoverageRestrictionValue.Forbid),
                            id: 'forbid',
                        },
                    ]}
                    value={outcome?.type === RuleOutcomeType.CoverageRestrictionValueOutcome ? outcome.value : null}
                />
            </InputWrapper>
        </Outcome>
    );
};
