import { ErrorSection, LoadingSection, PageWrapper } from '@lemonade-hq/bluis';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CoverageInstancesTable } from './Coverages/CoverageInstancesTable';
import { EditionHeader } from 'components/LoCo/common/editions/EditionHeader';
import { isEditionApproved } from 'components/LoCo/common/editions/editionHelpers';
import type { EditionActionData } from 'components/LoCo/common/editions/editionSharedActions';
import { EditionActionsDialogs } from 'components/LoCo/common/editions/editionSharedActions';
import { EditionSummarySection } from 'components/LoCo/common/editions/EditionSummarySection';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import { SettingInstancesTables } from 'components/LoCo/editions/coverage-editions/Settings/SettingInstancesTables';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { useGetCoveragesEdition } from 'queries/LoCo/Insurance/CoveragesEditionQueries';

export const CoveragesEdition: React.FC = () => {
    const product = useGetProductData();
    const [dialogData, setDialogData] = useState<EditionActionData | null>(null);
    const { coveragesEditionCode = '' } = useParams<{
        coveragesEditionCode: string;
    }>();

    const {
        data: coverageEditionData,
        isError: isCoverageEditionError,
        isLoading: isLoadingCoverageEdition,
    } = useGetCoveragesEdition(coveragesEditionCode);
    if (isLoadingCoverageEdition) {
        return <LoadingSection />;
    }

    if (isCoverageEditionError || !coverageEditionData) {
        return <ErrorSection title="No coverages edition :(" />;
    }

    const editionApproved = isEditionApproved(coverageEditionData);

    return (
        <>
            <EditionHeader
                edition={coverageEditionData}
                product={product}
                setDialogData={setDialogData}
                type={EditionType.Coverages}
            />

            <PageWrapper>
                <EditionSummarySection edition={coverageEditionData} product={product} type={EditionType.Coverages} />
                <CoverageInstancesTable coveragesEdition={coverageEditionData} hideActions={editionApproved} />
                <SettingInstancesTables coveragesEdition={coverageEditionData} hideActions={editionApproved} />

                {dialogData != null && (
                    <EditionActionsDialogs
                        dialogData={dialogData}
                        onClose={() => setDialogData(null)}
                        productCode={product.code}
                    />
                )}
            </PageWrapper>
        </>
    );
};
