import { ChevronIcon, InlineButton, Separator } from '@lemonade-hq/bluis';
import { Box, Flex, font, themedColor, useCollapsible } from '@lemonade-hq/cdk';
import last from 'lodash/last';
import type { FC } from 'react';
import { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Medium } from '../../../shared/types';
import type { TicketAiReview, TimelineItem } from '../../../shared/types';
import { findSecondLastMessageIndex } from '../../supervisor.utils';
import { Timeline } from 'components/Llmnd/shared/components/Timeline';

const StyledSeparator = styled(Separator)`
    margin: 24px 4px;
`;

const SubjectHeading = styled.div`
    ${font('main', { fontSize: '18px', fontWeight: 700 })};
    color: ${themedColor('secondaryText')};
`;

const CollapseButton = styled(InlineButton)`
    color: ${themedColor('secondaryText')};
    display: flex;
    align-items: center;
    column-gap: 12px;
    transition: color 150ms;

    &:hover {
        color: ${themedColor('primaryText')};
    }
`;

interface MessagesTimelineProps {
    readonly timeline: TimelineItem[];
    readonly locale: TicketAiReview['interaction']['locale'];
    readonly subject?: string;
    readonly timezone: TicketAiReview['interaction']['timezone'];
    readonly zendeskUrl?: string;
    readonly name?: string;
    readonly medium: Medium;
}

export const MessagesTimeline: FC<MessagesTimelineProps> = ({
    timeline,
    locale,
    subject,
    timezone,
    zendeskUrl,
    name,
    medium,
}) => {
    const collapsibleRef = useRef<HTMLDivElement>(null);

    const { collapsibleWrapperStyles, handleToggleCollapsible, isClosed } = useCollapsible({
        ref: collapsibleRef,
        isCollapsed: true,
        extendedCollapsibleWrapperStyles: (isCollapsed: boolean) =>
            !isCollapsed
                ? {
                      overflow: 'visible',
                      marginBottom: '16px',
                      height: 'auto',
                  }
                : {},
        animationDuration: 300,
    });

    const arrowDirection = isClosed ? 'down' : 'up';
    const toggleButtonText = isClosed ? 'Show' : 'Hide';

    const secondLastMessageIndex = useMemo(() => findSecondLastMessageIndex(timeline), [timeline]);
    const hasPastMessages = secondLastMessageIndex > 0;

    const subjectText =
        medium === Medium.Email ? `Email subject: ${subject}` : `SMS conversation with ${name ?? 'an unknown sender'}`;

    return (
        <div>
            <Box mb={24}>
                <Flex alignItems="center" justifyContent="space-between" mb={1}>
                    <SubjectHeading>{subjectText}</SubjectHeading>
                    {hasPastMessages && (
                        <CollapseButton onClick={handleToggleCollapsible} type="button">
                            {toggleButtonText} ticket history
                            <ChevronIcon color="currentColor" direction={arrowDirection} height={12} width={12} />
                        </CollapseButton>
                    )}
                </Flex>
                {zendeskUrl != null ? (
                    <span>
                        <a href={zendeskUrl} rel="noreferrer" target="_blank">
                            Open in Zendesk
                        </a>
                        &nbsp;({last(zendeskUrl.split('/'))})
                    </span>
                ) : (
                    <span>Link is not yet available</span>
                )}
            </Box>

            {hasPastMessages && (
                <div style={collapsibleWrapperStyles}>
                    <div ref={collapsibleRef}>
                        <Timeline
                            locale={locale}
                            timeline={timeline.slice(0, secondLastMessageIndex)}
                            timezone={timezone}
                        />
                    </div>
                </div>
            )}

            {!isClosed && <StyledSeparator />}

            <Timeline
                locale={locale}
                timeline={timeline.slice(secondLastMessageIndex, timeline.length)}
                timezone={timezone}
            />
        </div>
    );
};
