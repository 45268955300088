import { ListboxButton } from '@headlessui/react';
import { clsx } from 'clsx';
import isEmpty from 'lodash/isEmpty';
import type { BaseSyntheticEvent, ReactElement } from 'react';
import { useCallback } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import * as inputStyles from '../../theme/input.css';
import * as selectStyles from '../../theme/select.css';
import type { SelectionMode } from '../../theme/selection';
import { spacing } from '../../theme/spacing.css';
import { arrowIcon } from '../ComboBox/ComboBox.css';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';
import type { SelectOptionBase, SelectProps } from './Select';
import * as styles from './Select.css';
import { SelectChips } from './SelectChips';

interface SelectOptionsTriggerBarProps<
  TMode extends SelectionMode = 'single',
  TOption extends SelectOptionBase = SelectOptionBase,
> extends Pick<
    SelectProps<TMode, TOption>,
    'cancelable' | 'disabled' | 'hasError' | 'mode' | 'placeholder' | 'selectedValueTemplate' | 'size'
  > {
  readonly selected?: TMode extends 'single' ? TOption : TOption[];
  readonly onChange: (value: (TMode extends 'single' ? TOption : TOption[]) | null) => void;
}

const LabelOrChips = <TMode extends SelectionMode = 'single', TOption extends SelectOptionBase = SelectOptionBase>({
  placeholder,
  selectedValueTemplate,
  onChange,
  selected,
  mode,
  disabled,
}: SelectOptionsTriggerBarProps<TMode, TOption>): JSX.Element => {
  if (selected == null || (Array.isArray(selected) && selected.length === 0)) {
    return (
      <Text className={styles.ellipsisText} color="tertiary" fontWeight="regular">
        {placeholder}
      </Text>
    );
  }

  if (mode === 'single') {
    const { label } = selected as TOption;

    return selectedValueTemplate ? (
      <div>{selectedValueTemplate(label)}</div>
    ) : (
      <Text className={styles.ellipsisText}>{label}</Text>
    );
  }

  return (
    <Flex flexGrow="1" gap={spacing.s04} overflow="hidden">
      <SelectChips
        disabled={disabled}
        onDeselect={value => {
          onChange(
            (selected as TOption[]).filter(option => option.value !== value) as TMode extends 'single'
              ? TOption
              : TOption[],
          );
        }}
        options={selected as TOption[]}
        selectedValueTemplate={selectedValueTemplate}
      />
    </Flex>
  );
};

export const SelectOptionsTriggerBar = <
  TMode extends SelectionMode = 'single',
  TOption extends SelectOptionBase = SelectOptionBase,
>(
  props: SelectOptionsTriggerBarProps<TMode, TOption>,
): ReactElement => {
  const { hasError, placeholder, size, cancelable, onChange, selected, disabled } = props;
  const onClear = useCallback(
    (e: BaseSyntheticEvent): void => {
      onChange(null);
      e.preventDefault();
      e.stopPropagation();
    },
    [onChange],
  );

  return (
    <ListboxButton
      aria-label={placeholder}
      className={clsx(selectStyles.selectTrigger, inputStyles.input({ size }))}
      data-has-error={Boolean(hasError) || undefined}
    >
      {({ open }) => (
        <>
          <LabelOrChips {...props} />
          <Flex alignItems="center" gap="0.4rem">
            {cancelable && !isEmpty(selected) && (
              <IconButton
                as="div"
                color="neutral7"
                disabled={disabled}
                icon="x"
                iconSize="sm"
                onClick={onClear}
                onKeyDown={e => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    onClear(e);
                  }
                }}
                role="button"
                size="sm"
                variant="inline"
              />
            )}
            <Icon
              className={arrowIcon}
              color="neutral7"
              name="arrow-drop-down-solid"
              rotation={open ? '180deg' : undefined}
              size="xs"
            />
          </Flex>
        </>
      )}
    </ListboxButton>
  );
};
