import { Button, ComboBox, Flex, Select, spacing, Text, TextArea } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import type { FC } from 'react';
import { useArchive } from '../ActionHooks/useArchive';
import { useUpdateDetails } from '../ActionHooks/useUpdateDetails';
import type { AttachmentActionType, AttachmentDTO } from '../types';
import { getAttachmentAnalyticsParam } from '../utils';
import { PreviewAssignAttachment } from 'components/Home/Claims/Attachments/AssignAttachmentDialog/AssignToItemDialog';

interface PreviewActionProps {
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly action: AttachmentActionType | undefined;
}

const PreviewUpdateForm: FC<Omit<PreviewActionProps, 'action'>> = ({ attachment, entityPublicId, entityType }) => {
    const {
        types,
        type: selectedType,
        description: selectedDescription,
        isTypesError,
        isTypesLoading,
        isSubmitPending,
        isSubmitError,
        handleSelectionChange,
        setDescription,
        handleSubmit,
    } = useUpdateDetails({
        entityPublicId,
        entityType,
        attachments: [attachment],
        savedType: attachment.type,
        savedDescription: attachment.description,
    });

    const isPolicyPdf = selectedType === 'policy_pdf';
    const isDisabled = selectedType === '' || selectedDescription === '';

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.gallery.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            doc_type: selectedType,
            doc_description: selectedDescription,
            name: 'relabel',
            source: 'preview',
        });
        const attachmentsData = [
            {
                attachmentPublicId: attachment.publicId,
                ...(selectedType !== '' && { type: selectedType }),
                ...(selectedDescription !== '' && { description: selectedDescription }),
            },
        ];
        await handleSubmit({
            attachmentsData,
        });
    };

    return (
        <Flex flexDirection="column" gap={spacing.s08}>
            <Flex flexDirection="column" gap={spacing.s06}>
                <Text color="primary" type="label-sm">
                    Type
                    <Text as="span" color="error">
                        &nbsp;*
                    </Text>
                </Text>
                <Flex flexDirection="column" gap={spacing.s06}>
                    {isPolicyPdf ? (
                        <Text type="label-sm">Policy PDF</Text>
                    ) : (
                        <ComboBox
                            defaultValue={selectedType}
                            disabled={isTypesLoading}
                            items={types}
                            onSelectionChange={handleSelectionChange}
                            placeholder="Select attachment type"
                        />
                    )}
                    {isTypesError && (
                        <Text color="error" type="label-sm">
                            Error loading attachment types
                        </Text>
                    )}
                </Flex>
            </Flex>
            <Flex flexDirection="column" gap={spacing.s06}>
                <Text color="primary" type="label-sm">
                    Description
                    <Text as="span" color="error">
                        &nbsp;*
                    </Text>
                </Text>
                <TextArea
                    autoExpanding
                    maxLength={500}
                    onChange={e => setDescription(e.target.value)}
                    rows={5}
                    value={selectedDescription}
                />
            </Flex>
            {isSubmitError && (
                <Text color="error" type="label-sm">
                    Error updating attachment details
                </Text>
            )}
            <Button
                disabled={isDisabled}
                label="Save"
                loading={isSubmitPending}
                onClick={onSubmit}
                size="sm"
                variant="primary"
            />
        </Flex>
    );
};

const ArchiveForm: FC<Omit<PreviewActionProps, 'action'>> = ({ attachment, entityPublicId, entityType }) => {
    const { suggestedArchive, archivingReason } = attachment;
    const {
        reasons,
        reason,
        otherReason,
        isReasonsLoading: isLoading,
        isReasonsError: isError,
        setReason,
        setOtherReason,
        handleSubmit,
    } = useArchive({
        entityPublicId,
        entityType,
        attachments: [attachment],
        archivingReason,
    });

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.gallery.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            name: 'archive',
            source: 'preview',
        });

        await handleSubmit();
    };

    return (
        <Flex flexDirection="column" gap={spacing.s08}>
            <Flex flexDirection="column" gap={spacing.s06}>
                <Text color="primary" type="label-sm">
                    {suggestedArchive === true ? 'We think you should archive this:' : 'Archive reason'}
                    {suggestedArchive == null ||
                        (!suggestedArchive && (
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                        ))}
                </Text>
                <Select<never, { value: string; label: string }>
                    disabled={isLoading}
                    onChange={value => setReason(value ?? '')}
                    options={reasons}
                    placeholder="Select archive reason"
                    selectedKey={reason}
                />
                {isError && (
                    <Text color="error" type="label-sm">
                        Error loading archive reasons
                    </Text>
                )}
            </Flex>
            {reason === 'other' && (
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        Other reason
                        <Text as="span" color="error">
                            &nbsp;*
                        </Text>
                    </Text>
                    <TextArea
                        autoExpanding
                        maxLength={200}
                        onChange={e => setOtherReason(e.target.value)}
                        placeholder="Add reason"
                        value={otherReason}
                    />
                </Flex>
            )}
            <Button
                disabled={reason === '' || (reason === 'other' && otherReason === '')}
                label="Archive"
                onClick={onSubmit}
                size="sm"
                variant="primary"
            />
        </Flex>
    );
};

export const PreviewActionContent: FC<PreviewActionProps> = ({ action, attachment, entityPublicId, entityType }) => {
    if (action == null) return null;

    switch (action) {
        case 'relabel':
            return (
                <PreviewUpdateForm attachment={attachment} entityPublicId={entityPublicId} entityType={entityType} />
            );
        case 'archive':
            return <ArchiveForm attachment={attachment} entityPublicId={entityPublicId} entityType={entityType} />;
        case 'assign_to_home_claim_item':
            return <PreviewAssignAttachment attachments={[attachment]} entityPublicId={entityPublicId} />;
        default:
            return null;
    }
};
