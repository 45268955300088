import { useLayoutEffect } from 'react';
import { FULLSCREEN_DATA_ATTRIBUTE } from './useFullScreen.css';

// enables fullscreen mode for the current page when mounted by adding global styles

export function useFullscreen(): void {
    useLayoutEffect(() => {
        document.body.setAttribute(FULLSCREEN_DATA_ATTRIBUTE, '');
        return () => {
            document.body.removeAttribute(FULLSCREEN_DATA_ATTRIBUTE);
        };
    }, []);
}
