import { Icon, Select } from '@lemonade-hq/blender-ui';
import { Flex } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import { ExpressionActionType, useExpressionSimpleEditorContext } from './ExpressionSimpleEditorContext';
import type { ExpressionProps } from './ExpressionSimpleEditorShared';
import { ExpressionContainer } from './ExpressionSimpleEditorShared';
import type { LogicalExpressionNode, LogicalOperator } from './expressionTypes';
import { LOGICAL_OPERATORS } from './operators';
import { logicalOperatorSelect } from './RulesBuilder.css';

const operatorMapper: Record<LogicalOperator, string> = {
    '&&': 'And',
    '||': 'Or',
};

const logicalOperators = LOGICAL_OPERATORS.map(operator => ({
    id: operator,
    value: operator,
    label: operatorMapper[operator as keyof typeof operatorMapper],
}));

interface LogicalExpressionProps extends ExpressionProps {
    readonly left?: React.ReactElement;
    readonly right: React.ReactElement;
}

const StyledLogicalOperatorSide = styled(Flex)`
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 8px 15px;

    &:hover {
        background-color: var(--element-background-color);

        > .trash {
            display: flex !important;
        }
    }
`;

const StyledClauseContainer = styled(Flex)`
    align-items: flex-start;
    gap: 10px;
    justify-content: center;
`;

const StyledOperatorContainer = styled(Flex)`
    width: 80px;
    height: 100%;
    display: flex;
    padding-top: 5px;
    align-items: flex-start;
    justify-content: flex-end;
    justify-self: flex-start;
`;

const StyledTrashContainer = styled.div`
    display: none;
    cursor: pointer;
    height: 100%;
`;

export const LogicalExpression: React.FC<LogicalExpressionProps> = ({ id, left, right }) => {
    const { state, dispatch } = useExpressionSimpleEditorContext();

    const node = state.expressionTree[id] as LogicalExpressionNode;

    const isRoot = state.root === id;

    return (
        <ExpressionContainer style={{ flexDirection: 'column', width: '100%' }}>
            {left && (
                <StyledLogicalOperatorSide>
                    <StyledClauseContainer>
                        {isRoot && <StyledOperatorContainer>If</StyledOperatorContainer>}
                        {left}
                    </StyledClauseContainer>
                    {isRoot && (
                        <StyledTrashContainer className="trash">
                            <Icon
                                name="trash-solid"
                                onClick={() =>
                                    dispatch({
                                        type: ExpressionActionType.RemoveLogicalOperator,
                                        payload: { id, isRootDelete: true },
                                    })
                                }
                                size="md"
                            />
                        </StyledTrashContainer>
                    )}
                </StyledLogicalOperatorSide>
            )}
            <StyledLogicalOperatorSide>
                <StyledClauseContainer>
                    {isRoot && ( // this is the AND/OR on the left side when adding a new filter
                        <Select
                            className={logicalOperatorSelect}
                            onChange={value =>
                                dispatch({
                                    type: ExpressionActionType.ChangeOperator,
                                    payload: { id, operator: value as string },
                                })
                            }
                            options={logicalOperators}
                            placeholder="Select operator"
                            selectedKey={node.operator}
                        />
                    )}
                    {!isRoot && <StyledOperatorContainer>{operatorMapper[node.operator]}</StyledOperatorContainer>}
                    {right}
                </StyledClauseContainer>
                <StyledTrashContainer className="trash">
                    <Icon
                        name="trash-solid"
                        onClick={() =>
                            dispatch({
                                type: ExpressionActionType.RemoveLogicalOperator,
                                payload: { id },
                            })
                        }
                    />
                </StyledTrashContainer>
            </StyledLogicalOperatorSide>
        </ExpressionContainer>
    );
};
