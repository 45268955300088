import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import { useCallback, useMemo, useState } from 'react';

import { editNoteExplanation, editNoteWrapper } from './ReleaseDetails.css';
import { StyledTextArea } from 'components/LoCo/LoCoPagesSharedStyles';
import { useUpdateRelease } from 'queries/LoCo/Insurance/ReleasesQueries';

interface ManageReleaseNoteDialogProps {
    readonly onClose: () => void;
    readonly initialNote: string;
    readonly releasePublicId: string;
}

const MAX_NOTE_LENGTH = 1000;

export const ManageReleaseNoteDialog: React.FC<ManageReleaseNoteDialogProps> = ({
    onClose,
    initialNote,
    releasePublicId,
}) => {
    const [note, setNote] = useState(initialNote);

    const { error: errorUpdate, mutateAsync: updateRelease, isPending } = useUpdateRelease(releasePublicId);

    const handleSave = useCallback(async () => {
        await updateRelease(note);
        onClose();
    }, [note, onClose, updateRelease]);

    const actions = useMemo<DialogAction[]>(() => {
        return [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'save',
                type: 'submit',
                disabled: isPending || note.length === 0,
                onClick: handleSave,
            },
        ];
    }, [onClose, isPending, note.length, handleSave]);

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={errorUpdate as string}
            loading={isPending}
            onClose={onClose}
            title={initialNote.length > 0 ? 'Edit Release Note' : 'Add Release Note'}
        >
            <Flex className={editNoteWrapper}>
                <div className={editNoteExplanation}>
                    Please enter a release note to be published with this Release, describing the changes introduced and
                    their motivation.
                </div>
                <StyledTextArea
                    maxLength={MAX_NOTE_LENGTH}
                    onChange={event => setNote(event.target.value)}
                    value={note}
                />
            </Flex>
        </Dialog>
    );
};
