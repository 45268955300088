import React from 'react';
import { ErrorSection, LoadingSection } from '@lemonade-hq/bluis';
import { Navigate } from 'react-router-dom';
import loadable from '@loadable/component';
import { withPageErrorBoundary } from '@lemonade-hq/bluiza';
import { useHomePage, HomePage as HomePageType } from 'queries/ClientSettings';

const AsyncHome = loadable(async () => import(/* webpackChunkName: "Homepage" */ 'components/Homepage/Homepage'), {
    fallback: <LoadingSection />,
});

const AsyncAgentsHomepage = loadable(async () => import('components/Homepage/AgentsHomepage'), {
    fallback: <LoadingSection />,
});

const HomePage: React.FC = () => {
    const { data, isError, isPending: isLoading } = useHomePage();

    if (isError) return <ErrorSection />;

    if (isLoading) return <LoadingSection />;

    if (data === HomePageType.Agent) {
        return <AsyncAgentsHomepage />;
    }

    if (data === HomePageType.QuoteList) {
        return <Navigate replace to="home/quotes" />;
    }
    return <AsyncHome />;
};

export default withPageErrorBoundary(HomePage);
