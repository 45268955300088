import { getCookie } from '@lemonade-hq/cdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { blenderGeneral } from '../../../apiClients';
import type { ImpersonationContext } from '../Context';
import { useStartImpersonation } from './useStartImpersonation';

interface ImpersonationState {
    readonly impersonating: boolean;
    readonly updatedAt: number;
}

export function getImpersonationState(): ImpersonationState {
    const cookie = getCookie('_impersonation');

    if (cookie == null) return { impersonating: false, updatedAt: 0 };

    try {
        const parsed = JSON.parse(cookie) as { updatedAt: string };
        const updatedAt = new Date(parsed.updatedAt).getTime();

        return { impersonating: true, updatedAt };
    } catch (_err: unknown) {
        return { impersonating: false, updatedAt: 0 };
    }
}

export function useImpersonationContext(): ImpersonationContext {
    const [state] = useState<ImpersonationState>(getImpersonationState);
    const { mutate: startImpersonation } = useStartImpersonation();

    useEffect(() => {
        function handleVisibilityChange(): void {
            if (document.visibilityState !== 'visible') return;

            const { updatedAt } = getImpersonationState();

            if (updatedAt !== state.updatedAt) {
                window.location.reload();
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [state]);

    const start = useCallback(
        (attributes: Record<string, string[]>): void => {
            startImpersonation(attributes, {
                onSuccess: () => {
                    window.location.reload();
                },
            });
        },
        [startImpersonation]
    );

    const stop = useCallback(() => {
        const serviceUrl = blenderGeneral.defaults.baseURL;
        const { pathname, search } = window.location;
        const nextUrl = encodeURIComponent(`${pathname}${search}`);

        window.location.assign(new URL(`/api/v1/impersonation/stop?returnToPath=${nextUrl}`, serviceUrl));
    }, []);

    return useMemo(
        () => ({
            isImpersonating: state.impersonating,
            start,
            stop,
        }),
        [state.impersonating, start, stop]
    );
}
