import { Alert, AlertMode } from '@lemonade-hq/bluis';
import type { FC } from 'react';
import * as styles from './toolEditorAlert.css';

// TODO: Alert should be replaced with the new Zest Banner component
export const PublishedAndDeletedAlert: FC = () => (
    <Alert
        className={styles.toolEditorAlert}
        mode={AlertMode.Attention}
        title="This tool was deleted in this tools revision"
    />
);
