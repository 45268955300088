import React, { FC } from 'react';
import { Flex } from '@lemonade-hq/cdk';
import { CoreEntityType, ProductTypes } from '@lemonade-hq/bluiza';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import StatusDot from 'bluis/StatusDot';
import BoldedResult from './BoldedResult';
import { Container, LeftSection, MainInfo, SecondaryInfo, SecondaryInfoCapitalize, EntityDesc } from './shared';
import ProductIcon from './ProductIcon';
import { SearchItemClaim } from './helpers';
import ConditionalLink from './ConditionalLink';

import { formatAddress } from '../../../Commons/AddressUtils';

const ResultClaim: FC<
    React.PropsWithChildren<{
        term: string;
        claim: SearchItemClaim;
        onSearchResultClick: (params: { url: string; entity: CoreEntityType; product: ProductTypes; index: number }) => void;
        index: number;
    }>
> = ({ term, claim, onSearchResultClick, index }) => {
    const { owner, status, product, url, external_id: externalId, address_components: addressComponents } = claim;

    const formattedAddress = formatAddress(addressComponents);

    return (
        <>
            <ConditionalLink
                onClick={() => onSearchResultClick({ url, entity: CoreEntityType.Claim, product, index })}
                url={url}
            >
                <Container>
                    <LeftSection>
                        <Flex>
                            <MainInfo mb="5px" mr="8px">
                                <BoldedResult term={term} value={externalId} />
                            </MainInfo>
                            <SecondaryInfoCapitalize>
                                <StatusDot status={status} />
                                {snakeCaseToReadable(status)}
                            </SecondaryInfoCapitalize>
                        </Flex>
                        <SecondaryInfoCapitalize mb="2px">
                            <BoldedResult term={term} value={owner} />
                        </SecondaryInfoCapitalize>
                        {product === ProductTypes.Home && <SecondaryInfo mb="2px">{formattedAddress}</SecondaryInfo>}
                    </LeftSection>
                    <Flex alignItems="center">
                        <ProductIcon product={product} />
                        <EntityDesc ml="6px" mb="1px">
                            {snakeCaseToReadable(product)}
                        </EntityDesc>
                    </Flex>
                </Container>
            </ConditionalLink>
        </>
    );
};

export default ResultClaim;
