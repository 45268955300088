import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog, Input, Multiselect } from '@lemonade-hq/bluis';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { useCallback, useMemo } from 'react';

import { FormWrapper } from 'components/Header/PrincipalMenu/components/ConnectToRaterDialog/components/shared';
import { ProductLineErrorMessage } from 'components/LoCo/global-registry/coverages-registry/CoveragesAndSettingsRegistry';
import { StyledInputWrapper, StyledTextArea } from 'components/LoCo/LoCoPagesSharedStyles';
import type { CoverageTemplate } from 'models/LoCo/Insurance/Registry';
import { RegistryType } from 'models/LoCo/Insurance/Registry';
import { useGetProductLines } from 'queries/LoCo/Insurance/ProductLineQueries';
import { useCreateRegistryCoverageTemplate, useUpdateRegistryTemplate } from 'queries/LoCo/Insurance/RegistryQueries';

interface ManageTemplateCoverageDialogProps {
    readonly onClose: () => void;
    readonly coverage?: CoverageTemplate;
}

export const ManageTemplateCoverageDialog: React.FC<ManageTemplateCoverageDialogProps> = ({ onClose, coverage }) => {
    const { values, setValue, valid } = useForm({
        fields: {
            coverageName: {
                startValue: coverage?.name ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            description: {
                startValue: coverage?.description ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            productLines: {
                startValue: coverage?.productLines.map(productLine => productLine.code) ?? [],
                validations: {},
            },
        },
    });

    const { isError: productLinesError, isLoading, data: productLines } = useGetProductLines();

    const { error: errorCreate, mutateAsync: createCoverageTemplate } = useCreateRegistryCoverageTemplate();
    const { error: errorUpdate, mutateAsync: updateCoverageTemplate } = useUpdateRegistryTemplate(
        RegistryType.Coverage
    );

    const handleSave = useCallback(async () => {
        if (coverage) {
            await updateCoverageTemplate({
                templateCode: coverage.code,
                data: {
                    description: values.description,
                    productLines: values.productLines,
                },
            });

            onClose();
            return;
        }

        await createCoverageTemplate({
            name: values.coverageName,
            description: values.description,
            productLines: values.productLines,
        });

        onClose();
    }, [coverage, createCoverageTemplate, onClose, updateCoverageTemplate, values]);

    const actions = useMemo<DialogAction[]>(() => {
        return [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            { text: 'save', type: 'submit', disabled: !valid, onClick: handleSave },
        ];
    }, [valid, handleSave, onClose]);

    const productLineOptions =
        productLines?.map(productLine => ({
            id: productLine.code,
            value: productLine.code,
            label: productLine.name,
        })) ?? [];

    const errorMessage = productLinesError
        ? ProductLineErrorMessage
        : errorCreate?.toString() ?? errorUpdate?.toString() ?? undefined;

    const isEdit = coverage !== undefined;

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={errorMessage}
            loading={isLoading}
            onClose={onClose}
            title={isEdit ? 'Edit Coverage' : 'Add Coverage'}
        >
            <FormWrapper style={{ marginTop: 0 }}>
                <StyledInputWrapper label="Coverage Name">
                    <Input
                        disabled={isEdit}
                        onChange={event => setValue('coverageName', event.target.value)}
                        value={values.coverageName}
                    />
                </StyledInputWrapper>
                <StyledInputWrapper label="Product Lines">
                    <Multiselect
                        options={productLineOptions}
                        placeholder="Select"
                        selectedIds={values.productLines}
                        setSelectedOptions={option =>
                            setValue(
                                'productLines',
                                option.map(o => o.value)
                            )
                        }
                    />
                </StyledInputWrapper>
                <StyledInputWrapper label="Description">
                    <StyledTextArea
                        onChange={event => setValue('description', event.target.value)}
                        value={values.description}
                    />
                </StyledInputWrapper>
            </FormWrapper>
        </Dialog>
    );
};
