/* eslint-disable @typescript-eslint/naming-convention */

import { FederatedModule, LoadingSection } from '@lemonade-hq/bluis';
import loadable from '@loadable/component';
import * as Sentry from '@sentry/react';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ExamplePage } from 'components/Attachments/Example';
import { GenericQueueExample } from 'components/GenericQueue/GenericQueueExample';
import HomePage from 'components/Homepage';
import { PlaygroundRoutes as Playground } from 'components/Llmnd/Playground/shared/Routes';
import { PLAYGROUND_PATH_NAMESPACE } from 'components/Llmnd/Playground/shared/routing.consts';
import { Supervisor } from 'components/Llmnd/Supervisor/Supervisor';

const AsyncSearch = loadable(
    async () => await import(/* webpackChunkName: "SearchGeneral" */ 'components/Search/SearchGeneral'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncSearchAddresses = loadable(
    async () => await import(/* webpackChunkName: "SearchAddresses" */ 'components/Search/SearchAddresses'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncCLXAdminDashboard = loadable(
    async () => await import(/* webpackChunkName: "CLXAdminDashboard" */ 'components/CLXAdminDashboard'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncCLXPermissionsDashboard = loadable(
    async () => await import(/* webpackChunkName: "CLXPermissionsDashboard" */ 'components/CLXPermissionsDashboard'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncBlockedAreas = loadable(
    async () => await import(/* webpackChunkName: "BlockedAreas" */ 'components/BlockedAreas'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncBlockedAddress = loadable(
    async () => await import(/* webpackChunkName: "BlockedAreas" */ 'components/BlockedAddresses/BlockedAddress'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncBlockedAddressesList = loadable(
    async () => await import(/* webpackChunkName: "BlockedAreas" */ 'components/BlockedAddresses/BlockedAddressesList'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncWatchTower = loadable(
    async () => await import(/* webpackChunkName: "WatchTower" */ 'components/WatchTower/WatchTower')
);

const AsyncIntegrations = loadable(
    async () => await import(/* webpackChunkName: "Integrations" */ 'components/Integrations'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncFileGenerator = loadable(
    async () => await import(/* webpackChunkName: "FileGenerator" */ 'components/FileGenerator'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncSnippets = loadable(async () => await import(/* webpackChunkName: "Snippets" */ 'components/Snippets'), {
    fallback: <LoadingSection />,
});

const AsyncHOReviews = loadable(async () => await import(/* webpackChunkName: "HOReviews" */ 'components/HOReviews'), {
    fallback: <LoadingSection />,
});

const AsyncOperatorsDashboard = loadable(
    async () => await import(/* webpackChunkName: "OperatorsDashboard" */ './components/OperatorsDashboard'),
    {
        fallback: <LoadingSection />,
    }
);

const PerformanceDashboard = lazy(
    async () =>
        await import(/* webpackChunkName: "PerformanceDashboard" */ './components/PerformanceDashboard').then(
            module => ({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                default: module.PerformanceDashboard,
            })
        )
);

const CauseList = lazy(
    async () =>
        await import(/* webpackChunkName: "CauseList" */ './components/Causes').then(module => ({
            default: module.CauseList,
        }))
);

const Catastrophes = lazy(
    async () =>
        await import(/* webpackChunkName: "Catastrophes" */ './components/Catastrophes').then(module => ({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            default: module.Catastrophes,
        }))
);

const Catastrophe = lazy(
    async () =>
        await import(/* webpackChunkName: "Catastrophe" */ './components/Catastrophes').then(module => ({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            default: module.Catastrophe,
        }))
);

const NewBlockedAreas = lazy(
    async () =>
        await import('./components/BlockedAreas/NewBlockedAreas').then(module => ({
            default: module.TempBlockedAreas,
        }))
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const GeneralRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <SentryRoutes>
            {/* RISK */}

            <Route element={<AsyncBlockedAreas />} path="temp_blocked_areas" />
            <Route
                element={
                    <Suspense fallback={<LoadingSection />}>
                        <NewBlockedAreas />
                    </Suspense>
                }
                path="new_temp_blocked_areas"
            />
            <Route element={<AsyncBlockedAddress />} path="blocked_addresses/:blockedAddressId" />
            <Route element={<AsyncBlockedAddressesList />} path="blocked_addresses" />
            <Route element={<AsyncWatchTower />} path="watch_tower" />

            {/* GENERAL */}

            <Route element={<AsyncSearchAddresses />} path="addresses" />
            <Route
                element={
                    <FederatedModule>
                        <Catastrophes />
                    </FederatedModule>
                }
                path="catastrophes"
            />
            <Route
                element={
                    <FederatedModule>
                        <Catastrophe />
                    </FederatedModule>
                }
                path="catastrophes/:catastropheId"
            />
            <Route element={<AsyncCLXAdminDashboard />} path="claim_adjusters" />
            <Route element={<AsyncCLXPermissionsDashboard />} path="clx_permissions" />
            <Route element={<AsyncOperatorsDashboard />} path="operators" />
            <Route element={<AsyncIntegrations />} path="integrations" />
            <Route element={<AsyncFileGenerator />} path="file_generator" />
            <Route element={<AsyncSnippets />} path="snippets" />
            <Route
                element={
                    <FederatedModule>
                        <PerformanceDashboard />
                    </FederatedModule>
                }
                path="performance_dashboard"
            />
            <Route
                element={
                    <FederatedModule>
                        <PerformanceDashboard />
                    </FederatedModule>
                }
                path="frontend"
            />
            <Route element={<AsyncHOReviews />} path="homeowner_reviews" />
            <Route
                element={
                    <FederatedModule>
                        <CauseList />
                    </FederatedModule>
                }
                path="causes"
            />
            <Route element={<AsyncSearch />} path="search" />

            <Route element={<Supervisor />} path="supervisor/*" />
            <Route element={<Playground />} path={`${PLAYGROUND_PATH_NAMESPACE}/*`} />

            <Route element={<ExamplePage />} path="attachments_gallery" />
            <Route element={<GenericQueueExample />} path="examples/generic_queuing" />
            <Route element={<HomePage />} path="/" />
        </SentryRoutes>
    );
};

// eslint-disable-next-line import/no-default-export
export default GeneralRoutes;
