import React from 'react';
import { Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { LoadingSection } from '@lemonade-hq/bluis';
import * as Sentry from '@sentry/react';

const AsyncLenders = loadable(async () => import(/* webpackChunkName: "Lenders" */ '.'), {
    fallback: <LoadingSection />,
});

const AsyncLender = loadable(async () => import(/* webpackChunkName: "Lender" */ './Lender'), {
    fallback: <LoadingSection />,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const LendersRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <SentryRoutes>
            <Route element={<AsyncLenders />} path="/" />
            <Route element={<AsyncLender />} path="/:lenderId" />
        </SentryRoutes>
    );
};

export default LendersRoutes;
