import { Badge, Flex } from '@lemonade-hq/blender-ui';
import type { ActionOption } from '@lemonade-hq/bluis';
import {
    ActionsMenu,
    copyToClipboard,
    EmptySection,
    ErrorSection,
    LoadingSection,
    MainButton,
    TableTitle,
    toast,
} from '@lemonade-hq/bluis';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useMemo, useState } from 'react';
import { UnderwritingDialogs, useUnderwritingDialog } from '../UnderwritingDialogs';
import { UnderwritingRegistryName } from '../UnderwritingRegistryName';
import {
    ActionType,
    getDecisionTypeText,
    isDraftExplanationVersion,
    UnderwritingEntityType,
} from '../underwritingUtils';
import { VersionActionMenu } from '../VersionActionMenu';
import { ManageExplanationVersionForm } from './ManageExplanationVersionForm';
import { ExpendButton, ExpendedDetailsTable } from 'components/LoCo/common/components/GridTable/ExpandedDetailsTable';
import type { Columns, ColumnsToRow } from 'components/LoCo/common/components/GridTable/GridTable';
import { GridTable } from 'components/LoCo/common/components/GridTable/GridTable';
import { Paginator } from 'components/LoCo/common/components/Pagination/Paginator';
import { getFormattedDate } from 'components/LoCo/common/helpers/dateHelpers';
import { trimText } from 'components/LoCo/common/helpers/inputHelpers';
import { StyledSectionHeader } from 'components/LoCo/LoCoPagesSharedStyles';
import { StyledActionsMenuWrapper } from 'components/LoCo/products/SharedTableConfig';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type {
    Explanation,
    ExplanationVersion,
    PublishedExplanationVersion,
} from 'models/LoCo/Insurance/UnderwritingRegistry';
import { useGetRegistryExplanations } from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

const ExplanationsColumns = [
    { key: 'internalName', title: `Internal Name` },
    { key: 'productLines', title: 'Product Lines' },
    { key: 'expended', title: '', width: '56px' },
] as const satisfies Columns;

// eslint-disable-next-line @lemonade-hq/base/favor-singular-enums
const explanationActionMenu: ActionOption[] = [
    {
        label: 'Edit Product Lines',
        value: ActionType.ManageProductLines,
    },
    {
        label: 'Copy Explanation Code',
        value: ActionType.CopyCode,
    },
];

const getExplanationRow = (
    explanation: Explanation,
    hasDraft: boolean,
    onActionRequested: (action: ActionType) => void
): ColumnsToRow<typeof ExplanationsColumns> => {
    return {
        internalName: {
            value: <UnderwritingRegistryName hasDraft={hasDraft} name={explanation.name}></UnderwritingRegistryName>,
        },
        productLines: { value: explanation.productLines.map(productLine => productLine.name).join(', ') },
        expended: {
            value: (
                <StyledActionsMenuWrapper>
                    <ExpendButton />
                    <ActionsMenu
                        actions={explanationActionMenu}
                        onChange={value => onActionRequested(value as ActionType)}
                        type="dots"
                    />
                </StyledActionsMenuWrapper>
            ),
        },
    };
};

function getExplanationPreview(explanationVersion: ExplanationVersion): string {
    const text = explanationVersion.explanationDetails['en-US']?.chat ?? '';
    return text.length > 0 ? `"${trimText(text, 70, true)}"` : '';
}

interface ExplanationsRegistryProps {
    readonly decisionType: UnderwritingDecisionLifecycleContext;
}

const isPublishedVersion = (version: ExplanationVersion): version is PublishedExplanationVersion => {
    return version.version !== null;
};

export const ExplanationsRegistry: React.FC<ExplanationsRegistryProps> = ({ decisionType }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const {
        data: registryExplanations,
        isLoading,
        isError,
    } = useGetRegistryExplanations(decisionType, { page: pageNumber, size: 20 });

    const { dialogData, setDialogData } = useUnderwritingDialog();

    const onPageClick = (pagIndex: number) => () => {
        setPageNumber(pagIndex);
    };

    const explanationsWithDraft: Set<string> = useMemo(() => {
        const collected = new Set<string>();
        if (!registryExplanations?.data) return collected;

        registryExplanations.data.forEach(explanation => {
            if (explanation.explanationVersions.some(isDraftExplanationVersion)) {
                collected.add(explanation.code);
            }
        });

        return collected;
    }, [registryExplanations]);

    const mostRecentVersions = useMemo(() => {
        const mostRecent: Record<string, number> = {};
        if (!registryExplanations?.data) return {};

        const explanations = registryExplanations.data;

        explanations.forEach(explanation => {
            const sortedVersions = explanation.explanationVersions
                .filter(isPublishedVersion)
                .sort((a, b) => b.version - a.version);
            mostRecent[explanation.code] = sortedVersions[0]?.version ?? '';
        });

        return mostRecent;
    }, [registryExplanations]);

    if (isLoading) {
        return <LoadingSection />;
    }

    if (!registryExplanations || isError) {
        return <ErrorSection />;
    }

    const handleActionRequested =
        (explanation: Explanation, explanationVersion: ExplanationVersion) => (actionType: ActionType) => {
            switch (actionType) {
                case ActionType.CreateOrEditDraft:
                    setDialogData({
                        type: ActionType.CreateOrEditDraft,
                        originalEntity: {
                            type: UnderwritingEntityType.Explanation,
                            entity: explanation,
                            entityVersion: explanationVersion,
                            isEditable: true,
                        },
                    });
                    break;
                case ActionType.ViewPublishedVersion:
                    setDialogData({
                        type: ActionType.ViewPublishedVersion,
                        originalEntity: {
                            type: UnderwritingEntityType.Explanation,
                            entity: explanation,
                            entityVersion: explanationVersion,
                            isEditable: false,
                        },
                    });
                    break;

                case ActionType.PublishDraft:
                    setDialogData({
                        type: actionType,
                        entityVersion: explanationVersion,
                    });
                    break;

                case ActionType.ArchiveDraft:
                    setDialogData({
                        type: actionType,
                        publicId: explanationVersion.publicId,
                    });
                    break;
                default:
                    break;
            }
        };

    if (dialogData?.type === ActionType.CreateOrEditDraft || dialogData?.type === ActionType.ViewPublishedVersion) {
        return (
            <ManageExplanationVersionForm
                decisionType={decisionType}
                onClose={() => setDialogData(null)}
                originalEntity={
                    isDefined(dialogData.originalEntity)
                        ? {
                              type: UnderwritingEntityType.Explanation,
                              entity: dialogData.originalEntity.entity as Explanation,
                              entityVersion: dialogData.originalEntity.entityVersion as ExplanationVersion,
                              isEditable: dialogData.originalEntity.isEditable,
                          }
                        : null
                }
            />
        );
    }

    const { data: explanations, stats } = registryExplanations;

    return (
        <>
            <StyledSectionHeader>
                <TableTitle title={`Global ${getDecisionTypeText(decisionType)} Explanations`} />
                <MainButton
                    onClick={() =>
                        setDialogData({
                            type: ActionType.CreateOrEditDraft,
                        })
                    }
                >
                    Add draft
                </MainButton>
            </StyledSectionHeader>
            {explanations.length === 0 ? (
                <EmptySection>no explanations defined</EmptySection>
            ) : (
                <GridTable
                    columns={ExplanationsColumns}
                    rows={explanations.map(explanation => ({
                        values: getExplanationRow(explanation, explanationsWithDraft.has(explanation.code), action => {
                            if (action === ActionType.ManageProductLines) {
                                setDialogData({
                                    type: ActionType.ManageProductLines,
                                    entity: explanation,
                                });
                            } else if (action === ActionType.CopyCode) {
                                copyToClipboard(explanation.code);
                                toast.info('Copied!', { duration: 2000 });
                            }
                        }),
                        expended: (
                            <ExpendedDetailsTable
                                rows={explanation.explanationVersions.map(explanationVersion => [
                                    {
                                        key: 'explanationVersion',
                                        value: isDraftExplanationVersion(explanationVersion) ? (
                                            'Draft'
                                        ) : (
                                            <Flex alignItems="center" gap="0.4rem">
                                                v{explanationVersion.version}
                                                {explanationVersion.version ===
                                                    mostRecentVersions[explanation.code] && (
                                                    <Badge label="live" variant="positive" />
                                                )}
                                            </Flex>
                                        ),
                                    },
                                    {
                                        key: 'supportedLocales',
                                        value:
                                            Object.keys(explanationVersion.explanationDetails).length > 0
                                                ? `Supported locales: ${Object.keys(explanationVersion.explanationDetails).join(', ')}`
                                                : 'No supported locales',
                                    },
                                    {
                                        key: 'publishDate',
                                        value: isDefined(explanationVersion.publishedAt)
                                            ? `Published ${getFormattedDate(explanationVersion.publishedAt)}`
                                            : 'Not yet published',
                                    },
                                    {
                                        key: 'Preview',
                                        value: getExplanationPreview(explanationVersion),
                                    },
                                    {
                                        key: 'actions',
                                        value: (
                                            <VersionActionMenu
                                                draftExists={explanation.explanationVersions.some(
                                                    isDraftExplanationVersion
                                                )}
                                                isDraftVersion={isDraftExplanationVersion(explanationVersion)}
                                                onActionRequested={handleActionRequested(
                                                    explanation,
                                                    explanationVersion
                                                )}
                                            />
                                        ),
                                    },
                                ])}
                            />
                        ),
                    }))}
                />
            )}
            <Paginator currentPage={pageNumber} onPageClick={onPageClick} totalPages={stats.totalPages} />
            <UnderwritingDialogs
                decisionType={decisionType}
                dialogData={dialogData}
                entityType={UnderwritingEntityType.Explanation}
                onClose={() => {
                    setDialogData(null);
                }}
            />
        </>
    );
};
