import type { ActionOption } from '@lemonade-hq/bluis';
import { ActionsMenu, copyToClipboard, EmptySection, MainButton, TableTitle, toast } from '@lemonade-hq/bluis';
import { useState } from 'react';
import { ManageFlagDialog } from './ManageFlagDialog';
import type { Columns, ColumnsToRow } from 'components/LoCo/common/components/GridTable/GridTable';
import { GridTable } from 'components/LoCo/common/components/GridTable/GridTable';
import { Paginator } from 'components/LoCo/common/components/Pagination/Paginator';
import { StyledSectionHeader } from 'components/LoCo/LoCoPagesSharedStyles';
import { StyledActionsMenuWrapper } from 'components/LoCo/products/SharedTableConfig';
import type { UnderwritingDecisionFlag } from 'models/LoCo/Insurance/UnderwritingRegistry';
import { useSuspenseGetRegistryFlags } from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

const FlagsColumns = [
    { key: 'name', title: 'Flag' },
    { key: 'description', title: 'Description' },
    { key: 'productLines', title: 'Product Lines' },
    { key: 'actions', title: '', width: '32px' },
] as const satisfies Columns;

enum ActionType {
    Edit = 'Edit',
    CopyCode = 'CopyCode',
}

const flagActionMenu: ActionOption[] = [
    {
        label: 'Edit Flag',
        value: ActionType.Edit,
    },
    {
        label: 'Copy Flag Code',
        value: ActionType.CopyCode,
    },
];

const getFlagRow = (
    flag: UnderwritingDecisionFlag,
    onActionRequested: (action: ActionType) => void
): ColumnsToRow<typeof FlagsColumns> => {
    return {
        name: { value: flag.name },
        description: { value: flag.description },
        productLines: { value: flag.productLines.map(productLine => productLine.name).join(', ') },
        actions: {
            value: (
                <StyledActionsMenuWrapper>
                    <ActionsMenu
                        actions={flagActionMenu}
                        onChange={value => onActionRequested(value as ActionType)}
                        type="dots"
                    />
                </StyledActionsMenuWrapper>
            ),
        },
    };
};

export const FlagsRegistry: React.FC = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const { data } = useSuspenseGetRegistryFlags({ page: pageNumber });
    const [dialogData, setDialogData] = useState<{ originalEntity?: UnderwritingDecisionFlag; isOpen: boolean }>({
        isOpen: false,
    });

    const onPageClick = (pagIndex: number) => () => {
        setPageNumber(pagIndex);
    };

    const { data: Flags, stats } = data;

    return (
        <>
            <StyledSectionHeader>
                <TableTitle title={'Flags'} />
                <MainButton
                    onClick={() =>
                        setDialogData({
                            isOpen: true,
                        })
                    }
                >
                    Add Flag
                </MainButton>
            </StyledSectionHeader>
            {Flags.length === 0 ? (
                <EmptySection>no Flags defined</EmptySection>
            ) : (
                <GridTable
                    columns={FlagsColumns}
                    rows={Flags.map(flag => ({
                        values: getFlagRow(flag, action => {
                            if (action === ActionType.Edit) {
                                setDialogData({
                                    isOpen: true,
                                    originalEntity: flag,
                                });
                            } else {
                                copyToClipboard(flag.code);
                                toast.info('Copied!', { duration: 2000 });
                            }
                        }),
                    }))}
                />
            )}
            <Paginator currentPage={pageNumber} onPageClick={onPageClick} totalPages={stats.totalPages} />
            {dialogData.isOpen && (
                <ManageFlagDialog
                    onClose={() => setDialogData({ isOpen: false })}
                    originalEntity={dialogData.originalEntity ?? null}
                />
            )}
        </>
    );
};
