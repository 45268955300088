import axios from 'axios';
import { Product } from '@lemonade-hq/lemonation';
import { EntityTypes, ProductTypes } from '@lemonade-hq/bluiza';
import { AdjusterData, AdjusterAvailability, AdjustersResponse, AdjusterRole } from 'models/Adjuster';
import { EditAdjusterParams } from 'components/CLXAdminDashboard/types';
import { getUrlResolver } from 'commons/UrlResolver';
import { ThreadOwner } from 'components/CommsTimeline/Comments/Comment/Interaction/types';

const blenderGeneralUrlResolver = getUrlResolver('blender-general');

export interface CLXFormOptionsResponse {
    product_types: Product[];
    regions: string[];
    offices: string[];
    roles: string[];
    squads: string[];
}

export interface SquadConfiguration {
    instances: number;
    authorityPaymentLevels: Record<string, number>;
}

export type SquadsConfiguration = Record<string, SquadConfiguration>;

export async function getActiveClaimAdjustersWithStatistics(region: string): Promise<AdjusterData[]> {
    const url = blenderGeneralUrlResolver('/api/v1/adjusters');
    const params = { status: 'active', region: region.toLowerCase(), includeStatistics: true };

    return axios
        .get<{ data: AdjusterData[] }>(url, { params })
        .then(response => response.data.data);
}

export async function createClaimAdjuster({
    productType,
    office,
    squad,
    role,
    isPhotoPublic,
    operatorPublicId,
    squadInstance,
    authorityPaymentLevel,
    eligibleToHandleTopTier,
}: {
    productType: string;
    office: string;
    squad: string;
    role: string;
    isPhotoPublic: boolean;
    operatorPublicId: number;
    squadInstance: number;
    authorityPaymentLevel: number;
    eligibleToHandleTopTier: boolean;
}): Promise<null> {
    const url = blenderGeneralUrlResolver('/api/v1/adjusters');
    const body = {
        operatorPublicId: operatorPublicId.toString(),
        productType,
        office,
        squad,
        role,
        isPhotoPublic,
        squadInstance,
        authorityPaymentLevel,
        eligibleToHandleTopTier,
    };

    return axios.post(url, body);
}

export async function updateClaimAdjusterAvailability({
    adjusterPublicId,
    availability,
}: {
    adjusterPublicId: string;
    availability: AdjusterAvailability;
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/adjusters/${adjusterPublicId}/availability`);

    const body = {
        availability,
    };

    return axios.put(url, body);
}

export async function updateClaimAdjuster({
    adjusterPublicId,
    productType,
    office,
    squad,
    role,
    isPhotoPublic,
    squadInstance,
    authorityPaymentLevel,
    eligibleToHandleTopTier,
}: {
    adjusterPublicId: string;
    productType: string;
    office: string;
    squad: string;
    role: string;
    isPhotoPublic: boolean;
    squadInstance: number;
    authorityPaymentLevel: number;
    eligibleToHandleTopTier: boolean;
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/adjusters/${adjusterPublicId}`);

    const body = {
        productType,
        office,
        squad,
        role,
        isPhotoPublic,
        squadInstance,
        authorityPaymentLevel,
        eligibleToHandleTopTier,
    };

    return axios.put(url, body);
}

export async function removeClaimAdjuster(adjusterPublicId: string): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/adjusters/${adjusterPublicId}`);

    return axios.delete(url);
}

export async function getCLXFormOptions(params: EditAdjusterParams): Promise<{ data: CLXFormOptionsResponse }> {
    const url = blenderGeneralUrlResolver('/api/v1/adjusters/adjuster_options');

    return axios.get(url, { params }).then(response => response.data.data);
}

export async function getAdjustersByProduct(productType: ProductTypes): Promise<AdjustersResponse> {
    const url = blenderGeneralUrlResolver('/api/v1/adjusters');

    return axios.get(url, { params: { productType } }).then(response => response.data.data);
}

export async function getAdjustersByOperatorIds(operatorPublicId: string[]): Promise<AdjustersResponse> {
    const url = blenderGeneralUrlResolver('/api/v1/adjusters');

    return axios.get(url, { params: { operatorPublicId } }).then(response => response.data.data);
}

export async function getSquadConfiguration(squad: string): Promise<SquadConfiguration> {
    const url = blenderGeneralUrlResolver(`/api/v1/adjusters/squads_configuration/${squad}`);

    return axios.get(url).then(response => response.data.data);
}

export async function getSquadsConfiguration(): Promise<SquadsConfiguration> {
    const url = blenderGeneralUrlResolver('/api/v1/adjusters/squads_configuration');

    return axios.get(url).then(response => response.data.data);
}

export interface AssignOperatorArgs {
    interactionGroupPublicId: string;
    updatedOperator: ThreadOwner;
    interactionId: string;
    context: 'inbox' | 'comms_timeline';
}

export async function assignOperator(payload: AssignOperatorArgs): Promise<null> {
    const {
        interactionGroupPublicId,
        updatedOperator: { operatorId },
        context,
    } = payload;
    const url = blenderGeneralUrlResolver('/api/v1/interactions/assign_operator');

    return axios.put(url, {
        interactionGroupPublicId,
        operatorId: operatorId.toString(),
        context,
    });
}

export async function getAssignableAdjusterByRole(
    entityPublicId: string,
    adjusterRole: AdjusterRole,
    entityType: EntityTypes
): Promise<AdjusterData> {
    const url = blenderGeneralUrlResolver('/api/v1/workflows/assignable_operators/adjuster');

    return axios
        .get(url, { params: { entityPublicId, adjusterRole, entityType } })
        .then(response => response.data.adjuster);
}
