import { ErrorSection, isAuthorizationError, LoadingSection } from '@lemonade-hq/bluis';
import { ErrorBoundary } from '@sentry/react';
import type { ComponentProps, PropsWithChildren } from 'react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export const ProductLayout: React.FC<
    PropsWithChildren<{
        readonly errorProps?: ComponentProps<typeof ErrorSection>;
        readonly loadingProps?: ComponentProps<typeof LoadingSection>;
    }>
> = () => {
    return (
        <ErrorBoundary
            fallback={({ error }) => {
                if (isAuthorizationError(error)) throw error;

                return <ErrorSection />;
            }}
        >
            <Suspense fallback={<LoadingSection />}>
                <Outlet />
            </Suspense>
        </ErrorBoundary>
    );
};
