import type { RecipeVariants } from '@vanilla-extract/recipes';
import { clsx } from 'clsx';
import type { ForwardedRef, HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import type { LayoutProps } from '../../base/Layout/Layout';
import { Layout } from '../../base/Layout/Layout';
import { borderRadius as borderRadiusStyles } from '../../theme/radius.css';
import { shadowStyles } from '../../theme/shadows.css';
import * as styles from './Card.css';

export type CardProps = HTMLAttributes<HTMLDivElement> &
  LayoutProps &
  RecipeVariants<typeof borderRadiusStyles> &
  RecipeVariants<typeof shadowStyles> & {
    readonly variant?: 'primary' | 'quaternary' | 'secondary' | 'tertiary';
    readonly withBorder?: boolean;
  };

export const Card = forwardRef(
  <TRef = unknown,>(
    {
      children,
      borderRadius = 'lg',
      shadow = 'ds2',
      variant = 'primary',
      padding = styles.commonCardPadding,
      className: externalClassName,
      withBorder = true,
      ...props
    }: CardProps,
    ref: ForwardedRef<TRef>,
  ): JSX.Element => {
    return (
      <Layout
        className={clsx(
          borderRadiusStyles({ borderRadius }),
          withBorder && shadowStyles({ shadow }),
          styles.card({ variant, withBorder }),
          externalClassName,
        )}
        p={padding}
        ref={ref}
        width="100%"
        {...props}
      >
        {children}
      </Layout>
    );
  },
) as <TRef = unknown>(props: CardProps & { readonly ref?: ForwardedRef<TRef> }) => JSX.Element;
