import { ContentSection, Image, LinkTab, PageWrapper, TabList, Tabs } from '@lemonade-hq/bluis';
import { EntitySummaryHeader, HeaderGeneralDetails } from '@lemonade-hq/bluiza';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ExplanationsRegistry } from './Explanations/ExplanationsRegistry';
import { FlagsRegistry } from './Flags/FlagRegistry';
import { ReasonsRegistry } from './Reasons/ReasonsRegistry';
import { HeaderPortal } from 'components/Header';
import { SuspendableContent } from 'components/LoCo/common/components/SuspendableContent';
import { HeaderImageSizes, ImageContainer } from 'components/LoCo/LoCoPagesSharedStyles';
import { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';

export const ProductLineErrorMessage = 'Failed to load product lines';

const tabs = {
    declineReasons: 'decline-reasons',
    declineExplanations: 'decline-explanations',
    nonRenewalReasons: 'non-renewal-reasons',
    nonRenewalExplanations: 'non-renewal-explanations',
    flags: 'Flags',
};

export const UnderwritingRegistry: React.FC = () => {
    return (
        <>
            <HeaderPortal>
                <EntitySummaryHeader>
                    <ImageContainer>
                        <Image
                            img={{
                                lightSrc: 'appraisal.svg',
                                darkSrc: 'appraisal-dark.svg',
                                ...HeaderImageSizes,
                            }}
                        />
                    </ImageContainer>
                    <HeaderGeneralDetails title="Global Underwriting Registry" />
                </EntitySummaryHeader>
            </HeaderPortal>
            <PageWrapper>
                <ContentSection>
                    <Tabs>
                        <HeaderPortal>
                            <TabList pageTabs>
                                <LinkTab key={tabs.declineReasons} to={tabs.declineReasons}>
                                    Decline Reasons
                                </LinkTab>
                                <LinkTab key={tabs.declineExplanations} to={tabs.declineExplanations}>
                                    Decline Explanations
                                </LinkTab>
                                <LinkTab key={tabs.nonRenewalReasons} to={tabs.nonRenewalReasons}>
                                    Non-Renewal Reasons
                                </LinkTab>
                                <LinkTab key={tabs.nonRenewalExplanations} to={tabs.nonRenewalExplanations}>
                                    Non-Renewal Explanations
                                </LinkTab>
                                <LinkTab key={tabs.flags} to={tabs.flags}>
                                    Flags
                                </LinkTab>
                            </TabList>
                        </HeaderPortal>
                        <Routes>
                            <Route element={<Navigate replace to={tabs.declineReasons} />} path="/" />
                            <Route
                                element={
                                    <SuspendableContent>
                                        <ReasonsRegistry decisionType={RuleLifecycleContext.NewBusiness} />
                                    </SuspendableContent>
                                }
                                key={tabs.declineReasons}
                                path={tabs.declineReasons}
                            />
                            <Route
                                element={<ExplanationsRegistry decisionType={RuleLifecycleContext.NewBusiness} />}
                                key={tabs.declineExplanations}
                                path={tabs.declineExplanations}
                            />
                            <Route
                                element={
                                    <SuspendableContent>
                                        <ReasonsRegistry decisionType={RuleLifecycleContext.Renewal} />
                                    </SuspendableContent>
                                }
                                key={tabs.nonRenewalReasons}
                                path={tabs.nonRenewalReasons}
                            />
                            <Route
                                element={<ExplanationsRegistry decisionType={RuleLifecycleContext.Renewal} />}
                                key={tabs.nonRenewalExplanations}
                                path={tabs.nonRenewalExplanations}
                            />
                            <Route element={<FlagsRegistry />} key={tabs.flags} path={tabs.flags} />
                        </Routes>
                    </Tabs>
                </ContentSection>
            </PageWrapper>
        </>
    );
};
