import type { SelectOption } from '@lemonade-hq/bluis';
import { AlertMode, Dialog, Select } from '@lemonade-hq/bluis';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledTextArea } from '../../SharedTableConfig';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import { getEditionUrl } from 'components/LoCo/common/urlBuilders';
import { StyledInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import type { CreateEditionParams, Edition } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { useGetEditionSummary, useGetLatestMinors } from 'queries/LoCo/Insurance/BaseEditionQueries';
import { useCreateCoveragesEdition } from 'queries/LoCo/Insurance/CoveragesEditionQueries';
import { useCreateDigitalAgentEdition } from 'queries/LoCo/Insurance/DigitalAgentEditionQueries';
import { useGetProductLines } from 'queries/LoCo/Insurance/ProductLineQueries';
import { useGetProducts, useSuspenseGetProduct } from 'queries/LoCo/Insurance/ProductQueries';
import { useCreateUnderwritingFiltersEdition } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

const notices = {
    clone: {
        mode: AlertMode.Info,
        title: 'A new edition will be created with configurations copied from the base edition',
    },
    notLatestMinor: {
        mode: AlertMode.Error,
        title: 'Clones with this base edition will not be approved for this product. Please start from an edition which is the latest minor version of any major series',
    },
};

interface AddDraftEditionDialogProps {
    readonly productCode: string;
    readonly onClose: () => void;
    readonly editionType?: EditionType;
    readonly baseEditionCode?: string;
}

export const CreateDraftEditionDialog: React.FC<AddDraftEditionDialogProps> = ({
    productCode: productCodeFromProps,
    editionType,
    baseEditionCode,
    onClose,
}) => {
    const navigate = useNavigate();

    const {
        mutateAsync: createCoveragesEdition,
        isError: isCoveragesMutationError,
        isPending: isCoveragesMutationLoading,
    } = useCreateCoveragesEdition(productCodeFromProps);

    const {
        mutateAsync: createDigitalAgentEdition,
        isError: isDigitalMutationError,
        isPending: isDigitalMutationLoading,
    } = useCreateDigitalAgentEdition();

    const {
        mutateAsync: createUnderwritingFiltersEdition,
        isError: isUnderwritingFiltersMutationError,
        isPending: isUnderwritingFiltersMutationLoading,
    } = useCreateUnderwritingFiltersEdition(productCodeFromProps);

    const { data: productLines, isLoading: productLinesIsLoading, isError: productLinesIsError } = useGetProductLines();
    const { data: baseEdition, isLoading: isLoadingBaseEdition } = useGetEditionSummary(
        baseEditionCode ?? '',
        editionType ?? null
    );

    const { data: productFromProps } = useSuspenseGetProduct(productCodeFromProps);

    const productLineCodeFromProps = useMemo(
        () => productLines?.find(p => p.code === productFromProps.productLineCode)?.code,
        [productLines, productFromProps.productLineCode]
    );

    const { errors, values, setValue, valid } = useForm({
        fields: {
            editionType: {
                startValue: editionType,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            description: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            product: {
                startValue: productCodeFromProps,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            productLine: {
                startValue: productLineCodeFromProps,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            clone: {
                startValue: baseEditionCode ?? '',
                validations: {},
            },
        },
    });

    const selectClone = useCallback(
        (selectedOption: SelectOption) => {
            setValue('clone', selectedOption.value);
        },
        [setValue]
    );

    const {
        data: latestMinors,
        isLoading: isLoadingLatestMinors,
        isError: isErrorLatestMinors,
    } = useGetLatestMinors(values.product, values.editionType, latestMinorsResponse => {
        if (latestMinorsResponse.length > 0) {
            const valueToSet = isDefined(baseEditionCode) ? baseEditionCode : latestMinorsResponse[0].code;
            setValue('clone', valueToSet);
        }
    });

    useEffect(() => {
        if (values.productLine === undefined && productLineCodeFromProps !== undefined)
            setValue('productLine', productLineCodeFromProps);
    }, [productLineCodeFromProps, values.productLine, setValue]);

    const { data: products, isLoading: productIsLoading, isError: productIsError } = useGetProducts(values.productLine);

    const isError =
        productIsError ||
        isCoveragesMutationError ||
        isDigitalMutationError ||
        isUnderwritingFiltersMutationError ||
        isErrorLatestMinors;

    const onSubmit = useCallback(async () => {
        const createEditionParams: CreateEditionParams = {
            productCode: values.product,
            description: values.description.trim(),
            baseEditionCode: isEmpty(values.clone) ? undefined : values.clone,
        };

        let createdEdition: Edition;

        switch (values.editionType) {
            case EditionType.Coverages:
                createdEdition = await createCoveragesEdition(createEditionParams);
                break;
            case EditionType.DigitalAgent:
                createdEdition = await createDigitalAgentEdition(createEditionParams);
                break;
            case EditionType.UnderwritingFilters:
                createdEdition = await createUnderwritingFiltersEdition(createEditionParams);
                break;
            default:
                throw new Error(`Invalid edition type ${values.editionType}`);
        }

        navigate(getEditionUrl(values.product, createdEdition.code, values.editionType));
        onClose();
    }, [
        values.product,
        values.description,
        values.editionType,
        baseEditionCode,
        navigate,
        onClose,
        createCoveragesEdition,
        createDigitalAgentEdition,
        createUnderwritingFiltersEdition,
    ]);

    const isCloneRequested = isDefined(baseEditionCode);

    const cloneOptions: SelectOption[] = useMemo(() => {
        if (!isDefined(latestMinors)) {
            return [];
        }

        const options = latestMinors.map(latestMinor => ({
            id: latestMinor.code,
            value: latestMinor.code,
            label: `${latestMinor.friendlyName} (${latestMinor.version})`,
        }));

        if (isDefined(baseEditionCode) && !latestMinors.some(latestMinor => latestMinor.code === baseEditionCode)) {
            options.unshift({
                id: baseEditionCode,
                value: baseEditionCode,
                label: isLoadingBaseEdition
                    ? ''
                    : isDefined(baseEdition)
                      ? `${baseEdition.friendlyName} (${baseEdition.version})`
                      : baseEditionCode,
            });
        }

        return options;
    }, [baseEdition, baseEditionCode, isLoadingBaseEdition, latestMinors]);

    const editionTypeOptions: SelectOption[] = useMemo(
        () =>
            [EditionType.Coverages, EditionType.DigitalAgent, EditionType.UnderwritingFilters].map(type => ({
                id: type,
                value: type,
                label: getEditionName(type),
            })),
        []
    );

    const productLineOptions: SelectOption[] = useMemo(
        () =>
            productLines
                ? productLines.map(p => ({
                      id: p.code,
                      value: p.code,
                      label: p.name,
                  }))
                : [],
        [productLines]
    );

    const productOptions: SelectOption[] = useMemo(
        () =>
            products
                ? products
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(p => ({ id: p.code, value: p.code, label: p.name }))
                : [],
        [products]
    );

    const selectEditionType = useCallback(
        (selectedOption: SelectOption) => {
            setValue('editionType', selectedOption.value as EditionType);
        },
        [setValue]
    );

    const selectProductLine = useCallback(
        (selectedOption: SelectOption) => {
            setValue('productLine', selectedOption.value);
        },
        [setValue]
    );

    const selectProduct = useCallback(
        (selectedOption: SelectOption) => {
            setValue('product', selectedOption.value);
        },
        [setValue]
    );

    const isNotLatestMinor =
        productCodeFromProps === values.product && // allow cloning for different products
        isDefined(baseEditionCode) &&
        isDefined(latestMinors) &&
        latestMinors.length > 0 &&
        !latestMinors.some(latestMinor => latestMinor.code === baseEditionCode);

    const isLoading =
        isCoveragesMutationLoading ||
        isDigitalMutationLoading ||
        isUnderwritingFiltersMutationLoading ||
        isLoadingLatestMinors;

    return (
        <Dialog
            actions={[
                {
                    text: 'Cancel',
                    type: 'close',
                    onClick: onClose,
                },
                {
                    text: 'Create',
                    type: 'submit',
                    onClick: onSubmit,
                    disabled: isLoading || isError || !valid || isNotLatestMinor,
                },
            ]}
            closeOnOutsideClick
            error={isError ? GENERAL_ERROR_MSG : undefined}
            loading={isLoading}
            notice={isNotLatestMinor ? [notices.notLatestMinor] : isCloneRequested ? [notices.clone] : undefined}
            onClose={onClose}
            title="Create Edition Draft"
        >
            <StyledInputWrapper label="Product Line" showErrors={productLinesIsError}>
                <Select
                    onOptionSelected={selectProductLine}
                    options={productLineOptions}
                    placeholder={productLinesIsLoading ? 'Loading...' : 'Select product line'}
                    value={values.productLine ?? null}
                />
            </StyledInputWrapper>
            <StyledInputWrapper label="Product" showErrors={productLinesIsError}>
                <Select
                    onOptionSelected={selectProduct}
                    options={productOptions}
                    placeholder={productIsLoading ? 'Loading...' : 'Select product'}
                    value={values.product}
                />
            </StyledInputWrapper>
            <StyledInputWrapper label="Edition Type" showErrors={!isEmpty(errors.editionType)}>
                <Select
                    disabled={isCloneRequested || isLoading}
                    onOptionSelected={selectEditionType}
                    options={editionTypeOptions}
                    placeholder="Select"
                    value={values.editionType ?? null}
                />
            </StyledInputWrapper>
            {isDefined(latestMinors) && latestMinors.length > 0 && (
                <StyledInputWrapper label="Clone Of">
                    <Select
                        disabled={isCloneRequested || isLoading || isLoadingBaseEdition}
                        onOptionSelected={selectClone}
                        options={cloneOptions}
                        placeholder="Select"
                        value={values.clone}
                    />
                </StyledInputWrapper>
            )}
            <StyledInputWrapper label="Description" showErrors={!isEmpty(errors.description)}>
                <StyledTextArea
                    onChange={event => setValue('description', event.target.value)}
                    value={values.description}
                />
            </StyledInputWrapper>
        </Dialog>
    );
};
