import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog, Input, Multiselect } from '@lemonade-hq/bluis';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { FormTextArea } from '../SharedStyles';
import { MIN_INTERNAL_NOTE_CHARS } from '../underwritingUtils';
import { isEmptyString } from 'components/LoCo/common/helpers/inputHelpers';
import { ProductLineErrorMessage } from 'components/LoCo/global-registry/coverages-registry/CoveragesAndSettingsRegistry';
import { StyledFormInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import type { UnderwritingDecisionFlag } from 'models/LoCo/Insurance/UnderwritingRegistry';
import { useGetProductLines } from 'queries/LoCo/Insurance/ProductLineQueries';
import { useCreateFlag, useUpdateFlag } from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledFormRow = styled(StyledFormInputWrapper)`
    > div {
        align-items: flex-start;
        > label {
            padding-top: 10px;
        }
    }
`;

interface ManageFlagDialogProps {
    readonly onClose: () => void;
    readonly originalEntity: UnderwritingDecisionFlag | null;
}

export const ManageFlagDialog: React.FC<ManageFlagDialogProps> = ({ onClose, originalEntity }) => {
    const { values, setValue, valid, errors } = useForm({
        fields: {
            name: {
                startValue: originalEntity?.name ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            internalDescription: {
                startValue: originalEntity?.description ?? '',
                validations: {
                    required: basicRequiredValidation,
                    charMinLength: {
                        errorMessage: `Enter at least ${MIN_INTERNAL_NOTE_CHARS} characters`,
                        test: (value: string) => {
                            return value.trim().length >= MIN_INTERNAL_NOTE_CHARS;
                        },
                        skipCondition: formValues => isEmptyString(formValues.internalDescription),
                    },
                },
            },
            productLines: {
                startValue: originalEntity?.productLines.map(productLine => productLine.code) ?? [],
                validations: {
                    required: {
                        errorMessage: '',
                        test: (value: string[]) => value.length > 0,
                    },
                },
            },
        },
    });

    const { isError: productLinesError, isLoading: isLoadingProductLines, data: productLines } = useGetProductLines();

    const { error: errorCreate, mutateAsync: createFlag, isPending: isCreatingFlag } = useCreateFlag();
    const {
        error: errorUpdate,
        mutateAsync: updateFlag,
        isPending: isUpdatingFlag,
    } = useUpdateFlag(originalEntity?.code);

    const handleSave = useCallback(async () => {
        if (isDefined(originalEntity)) {
            await updateFlag({
                description: values.internalDescription,
                productLineCodes: values.productLines,
            });
        } else {
            await createFlag({
                name: values.name,
                description: values.internalDescription,
                productLineCodes: values.productLines,
            });
        }

        onClose();
    }, [createFlag, onClose, originalEntity, updateFlag, values.internalDescription, values.name, values.productLines]);

    const actions = useMemo<DialogAction[]>(() => {
        const actionsArray: DialogAction[] = [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            { text: 'Save', type: 'submit', disabled: !valid, onClick: handleSave },
        ];

        return actionsArray;
    }, [onClose, valid, handleSave]);

    const productLineOptions = useMemo(
        () =>
            isDefined(productLines)
                ? productLines.map(productLine => ({
                      id: productLine.code,
                      value: productLine.code,
                      label: productLine.name,
                  }))
                : [],
        [productLines]
    );

    const errorMessage = productLinesError
        ? ProductLineErrorMessage
        : errorCreate?.toString() ?? errorUpdate?.toString() ?? undefined;

    const disableForm = isLoadingProductLines || isCreatingFlag || isUpdatingFlag;

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={errorMessage}
            loading={disableForm}
            onClose={onClose}
            size="large"
            title={`${originalEntity ? 'Edit ' : 'Add '} Underwriting Flag`}
        >
            <FormWrapper>
                <StyledFormInputWrapper inputWidth="250px" label={`Flag Name`}>
                    <Input
                        disabled={disableForm || isDefined(originalEntity)}
                        onChange={event => setValue('name', event.target.value)}
                        value={values.name}
                    />
                </StyledFormInputWrapper>
                <StyledFormRow
                    errorMessages={[errors.internalDescription?.charMinLength]}
                    label="Internal Description"
                    showErrors={!isEmptyString(errors.internalDescription?.charMinLength ?? '')}
                >
                    <FormTextArea
                        disabled={disableForm}
                        onChange={text => setValue('internalDescription', text)}
                        value={values.internalDescription}
                        width="250px"
                    />
                </StyledFormRow>
                {
                    <StyledFormInputWrapper inputWidth="250" label="Product Lines">
                        <Multiselect
                            disabled={disableForm}
                            options={productLineOptions}
                            placeholder="Select"
                            selectedIds={values.productLines}
                            setSelectedOptions={productLineOption =>
                                setValue(
                                    'productLines',
                                    productLineOption.map(option => option.value)
                                )
                            }
                            width={250}
                        />
                    </StyledFormInputWrapper>
                }
            </FormWrapper>
        </Dialog>
    );
};
