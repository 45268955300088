import { Badge, Flex } from '@lemonade-hq/blender-ui';

export const ProductLineErrorMessage = 'Failed to load product lines';

interface UnderwritingRegistryNameProps {
    readonly hasDraft: boolean;
    readonly name: string;
}

export const UnderwritingRegistryName: React.FC<UnderwritingRegistryNameProps> = ({ hasDraft, name }) => {
    return (
        <Flex alignItems="center" gap="0.4rem">
            {name} {hasDraft && <Badge label="Has draft" variant="info" />}
        </Flex>
    );
};
