import { useState } from 'react';

type RowId = string;

export interface UseRowSelectionReturnValue {
  readonly isRowSelected: (rowId: RowId) => boolean;
  readonly handleResetAll: () => void;
  readonly handleToggleAll: (rowIds: RowId[]) => void;
  readonly handleToggleRow: (rowId: RowId) => void;
  readonly selectedRows: Set<RowId>;
}

export const useCheckableTable = (selectedRowIds: RowId[]): UseRowSelectionReturnValue => {
  const [selectedRows, setSelectedRows] = useState<Set<RowId>>(new Set(selectedRowIds));

  const handleToggleRow = (rowId: RowId): void => {
    setSelectedRows(prevRows => {
      if (prevRows.has(rowId)) {
        prevRows.delete(rowId);
      } else {
        prevRows.add(rowId);
      }

      return new Set(prevRows);
    });
  };

  const handleToggleAll = (rowIds: RowId[]): void => {
    setSelectedRows(new Set(rowIds));
  };

  const handleResetAll = (): void => {
    setSelectedRows(new Set());
  };

  const isRowSelected = (rowId: RowId): boolean => selectedRows.has(rowId);

  return {
    selectedRows,
    isRowSelected,
    handleToggleRow,
    handleToggleAll,
    handleResetAll,
  };
};
