import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from '../supervisor.const';

const Iframe = styled.iframe`
    border: none;
`;

interface ZendeskWidgetProps {
    readonly email: string;
}

export const ZendeskWidget: React.FC<React.PropsWithChildren<ZendeskWidgetProps>> = ({ email }) => {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    /**
     * Change the iframe's `body` background to transparent when it is loaded.
     * JavaScript is necessary for this operation due to its ability to access and manipulate an iframe's DOM.
     */

    useEffect(() => {
        if (iframeRef.current == null) {
            return;
        }

        iframeRef.current.addEventListener('load', () => {
            iframeRef.current?.style.setProperty('display', 'block', 'important');

            const iframeDocument = iframeRef.current?.contentDocument;
            const iframeBody = iframeDocument?.body;

            if (iframeBody != null) {
                iframeBody.style.background = 'transparent';
            }
        });
    }, []);

    return (
        <Iframe
            ref={iframeRef}
            sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-modals allow-top-navigation-by-user-activation"
            src={`${window.location.origin}/backoffice/zendesk?email=${email}`}
            // Hiding the iframe is necessary to avoid a flickering effect when the iframe is loaded
            style={{ display: 'none' }}
            width={SIDEBAR_WIDTH}
        />
    );
};
