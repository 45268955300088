import { OrderByDirection } from '@lemonade-hq/bluis';
import axios from 'axios';
import { stringify } from 'qs';
import { stringifyUrl } from 'query-string';
import { getUrlResolver } from 'commons/UrlResolver';
import { Alert } from 'models/Alert';
import { Country, Form, HomeQuoteDataResponse, HomeQuoteListItem, PropertyForm, State, Status } from 'models/HomeQuote';
import { HeaderColumn } from 'models/HomeShared';

const homeBlenderUrlResolver = getUrlResolver('home-blender');

export async function getQuote(id: string) {
    const url = `/backoffice/quotes/${id}/data`;

    return axios.get(url).then(response => response.data);
}

export async function simulateVeriskDown(quoteId: string) {
    const url = `/backoffice/quotes/${quoteId}/simulate_verisk_down`;

    return axios.post(url).then(response => response.data);
}

export async function bypassUwFilter(quoteId: string) {
    const url = `/backoffice/quotes/${quoteId}/bypass_uw_filter`;

    return axios.post(url).then(response => response.data);
}

export async function retryStubbed(quoteId: string) {
    const url = `/backoffice/quotes/${quoteId}/retry_stubbed`;

    return axios.post(url).then(response => response.data);
}

interface DeclineQuoteBody {
    reasons: string[];
    quotePublicId: string;
    sendEmail: boolean;
    shouldBlockUser: boolean;
    shouldBlockAddress: boolean;
    emailContent: string;
    description: string;
}

export async function declineQuote({
    reasons,
    description,
    quotePublicId,
    sendEmail,
    emailContent,
    shouldBlockUser,
    shouldBlockAddress,
}: DeclineQuoteBody): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/quotes/${quotePublicId}/decline`);
    const body = {
        reasons,
        emailContent,
        blockAddress: shouldBlockAddress,
        blockUser: shouldBlockUser,
        sendEmail,
        description,
    };

    return axios.post(url, body).then(response => response.data);
}

export type NotificationActionDTO = {
    title: string;
    type: string;
};

export type NotificationDataDTO = {
    agent_email?: string;
    agent_full_name?: string;
    id?: number;
    performed_by?: string;
    reason?: string | string[];
    support_email?: string;
    text?: string;
    type?: string;
    value?: string;
};

export type NotificationDTO = {
    actions?: NotificationActionDTO[];
    category: string;
    context: string;
    data?: string | NotificationDataDTO | NotificationDataDTO[];
    date: Date;
    link?: string;
    priority: string;
    title: string;
    tooltip?: string;
    type: string;
    version: number;
};

export enum SortColumn {
    PolicyPublicId = 'policy_public_id',
    UserFullName = 'user_full_name',
    Country = 'country',
    State = 'state',
    Status = 'status_rank',
    Price = 'premium',
    Source = 'source',
    Type = 'type',
    Timestamp = 'timestamp',
    EffectiveDate = 'effective_date',
}

export interface HomeQuotesSearchParamsResponse {
    forms: { value: PropertyForm; name: Form }[];
    countries: Country[];
    states: State[];
    statuses: Status[];
}

export interface QuoteSearchSort {
    readonly column: SortColumn;
    readonly direction: OrderByDirection;
}

export interface SearchFilter {
    readonly country?: string;
    readonly statuses?: Status[];
    readonly state?: string;
    readonly type?: PropertyForm | 'ALL';
}

export interface HomeQuotesSearchParams {
    page: number;
    limit: number;
    sort?: QuoteSearchSort;
    filter?: SearchFilter;
}

export interface HomeQuotesSearchResponse {
    quotes: HomeQuoteListItem[];
    offset: number;
    pageSize: number;
    total: number;
}
export type HomeQuoteIncludeKeysResponse<K extends keyof HomeQuoteDataResponse> = {
    data: Pick<HomeQuoteDataResponse, K>;
    error: { [key in K]: string | undefined };
};

export async function fetchHomeQuoteIncludeKeys<K extends keyof HomeQuoteDataResponse>(
    quoteId: string,
    keys: K[]
): Promise<HomeQuoteIncludeKeysResponse<K>> {
    const url = stringifyUrl(
        {
            url: homeBlenderUrlResolver(`/api/v1/quotes/${quoteId}`),
            query: {
                include: keys,
            },
        },
        { arrayFormat: 'comma' }
    );

    return axios.get(url).then(response => response.data.data);
}

export async function fetchHomeQuoteData<K extends keyof HomeQuoteDataResponse>(
    quoteId: string,
    keys: K[]
): Promise<Pick<HomeQuoteDataResponse, K>> {
    return (await fetchHomeQuoteIncludeKeys(quoteId, keys)).data;
}

export async function fetchSearchParams(): Promise<HomeQuotesSearchParamsResponse> {
    const url = homeBlenderUrlResolver('/api/v1/quotes/query_params');

    return axios.get<{ data: HomeQuotesSearchParamsResponse }>(url).then(response => response.data.data);
}

export async function searchHomeQuotes(searchParams: HomeQuotesSearchParams): Promise<HomeQuotesSearchResponse> {
    const queryString = stringify(searchParams, { skipNulls: true });

    const url = homeBlenderUrlResolver(`/api/v1/quotes?${queryString}`);

    return axios.get<{ data: HomeQuotesSearchResponse }>(url).then(response => response.data.data);
}

export async function getQuoteNotifications(quotePublicId: string): Promise<{ data: { [key: string]: unknown } }> {
    const url = `/backoffice/home/quotes/notifications?id=${quotePublicId}`;

    return axios.get(url).then(response => response.data);
}

export async function getQuoteAlerts(quoteId: string): Promise<Alert[]> {
    const url = homeBlenderUrlResolver(`/api/v1/quotes/${quoteId}/alerts`);

    return axios.get(url).then(response => response.data.data);
}

export async function getAggregatedNotifications(quoteId: string): Promise<unknown[]> {
    const [notifications, alerts] = await Promise.all([getQuoteNotifications(quoteId), getQuoteAlerts(quoteId)]);

    alerts.forEach(alert => {
        notifications.data[alert.type] = alert;
    });

    return Object.values(notifications.data);
}

export async function getHeaderSettings(): Promise<HeaderColumn[]> {
    const url = homeBlenderUrlResolver('/api/v1/quotes/header_settings');

    return axios.get(url).then(response => response.data.data);
}
