/* eslint-disable jsx-a11y/no-autofocus */
import {
    Badge,
    Flex,
    FormInputGroup,
    FormLayout,
    generateTypedFormComponents,
    spacing,
    Text,
    useForm,
} from '@lemonade-hq/blender-ui';
import { pick } from '@lemonade-hq/maschema-schema';
import { clientToolSchema, ToolInstructionType } from '@lemonade-hq/persisted-tools';
import type { FC } from 'react';
import { useCallback, useRef } from 'react';

interface ToolEditorInstructionProp {
    readonly interpolations?: string[];
}

const instructionShape = pick(clientToolSchema, 'instructions').schema.instructions.shape;

const { Radio, InputGroup, ErrorMessage, TextArea } = generateTypedFormComponents<typeof instructionShape>();

export const ToolEditorInstruction: FC<ToolEditorInstructionProp> = ({ interpolations }) => {
    const {
        values: { type },
    } = useForm<typeof instructionShape>();

    const containerRef = useRef<HTMLDivElement>(null);

    const handleInterpolation = useCallback((interpolation: string) => {
        const focusedTextArea = document.activeElement as HTMLTextAreaElement | null;
        if (
            focusedTextArea != null &&
            Array.from(containerRef.current?.querySelectorAll('textarea') ?? []).includes(focusedTextArea)
        ) {
            // allows to insert text and undo it without too much code
            document.execCommand('insertText', false, interpolation);
        }
    }, []);

    return (
        <Flex flexDirection="column" gap={spacing.s20} ref={containerRef}>
            <FormLayout>
                <FormInputGroup>
                    <Radio aria-label="type" flexDirection="row" schemaKey="type" />
                </FormInputGroup>
                {type === ToolInstructionType.Rule ? (
                    <FormInputGroup>
                        <TextArea
                            aria-label="rule"
                            autoExpanding
                            autoFocus
                            placeholder="Type here..."
                            schemaKey="content"
                        />
                        <ErrorMessage schemaKey="content" />
                    </FormInputGroup>
                ) : (
                    <>
                        <InputGroup
                            aria-label="if-content"
                            autoExpanding
                            autoFocus
                            inputComponent="TextArea"
                            label="If"
                            placeholder="Type here..."
                            schemaKey="ifContent"
                        />
                        <InputGroup
                            aria-label="then-content"
                            autoExpanding
                            inputComponent="TextArea"
                            label="Then"
                            placeholder="Type here..."
                            schemaKey="thenContent"
                        />
                        <InputGroup
                            aria-label="else-content"
                            autoExpanding
                            inputComponent="TextArea"
                            label="Else"
                            placeholder="Type here..."
                            schemaKey="elseContent"
                        />
                    </>
                )}
            </FormLayout>
            {interpolations && (
                <Flex flexWrap="wrap" gap={spacing.s08}>
                    <Text color="secondary">Links:</Text>
                    {interpolations.map(interpolation => (
                        <Badge
                            key={interpolation}
                            label={interpolation}
                            onClick={e => {
                                e.preventDefault();
                                handleInterpolation(interpolation);
                            }}
                        />
                    ))}
                </Flex>
            )}
        </Flex>
    );
};
