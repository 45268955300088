import { BubbleMessageIcon, Tag, TagMode, TagSize } from '@lemonade-hq/bluis';
import { font, themedColor, Tooltip, useAnalytics } from '@lemonade-hq/boutique';
import React from 'react';
import styled from 'styled-components';
import { useNotesContext } from '../../context';

const MAX_AMOUNT_DISPLAY = 100;

const Container = styled.div`
    position: relative;
    padding: 6px;
    display: inline-flex;
    border-radius: 100%;
    transition: background-color 0.2s ease-out;

    &:hover,
    &:active {
        background-color: ${themedColor('lightBackground')};
        cursor: pointer;
    }
`;

const StyledTag = styled(Tag)`
    ${font('main', { fontSize: '11px', lineHeight: '10px', fontWeight: 900 })}
    display: flex;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 13px;
    padding: 2px 4px;
`;

interface NotesContextProps {
    readonly entityPublicId: string;
}

export const NotesAmount: React.FC<React.PropsWithChildren<NotesContextProps>> = ({ entityPublicId }) => {
    const { notesAmount, focusOnNotes, analyticsParams } = useNotesContext(entityPublicId);
    const { trackEvent } = useAnalytics();

    const handleClick = (): void => {
        focusOnNotes();
        if (analyticsParams?.notesIconClicked) trackEvent(analyticsParams.notesIconClicked.name);
    };

    return (
        <Tooltip alignment="bottom" content={notesAmount === 0 ? 'Add a note' : 'View notes'}>
            <Container onClick={handleClick}>
                {notesAmount != null && notesAmount > 0 && (
                    <StyledTag mode={TagMode.Info} tagSize={TagSize.Small}>
                        {notesAmount > MAX_AMOUNT_DISPLAY ? `${MAX_AMOUNT_DISPLAY}+` : notesAmount}
                    </StyledTag>
                )}
                <BubbleMessageIcon height={20} width={20} />
            </Container>
        </Tooltip>
    );
};
