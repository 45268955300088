import type {
  ExtractShapeFromPath,
  Infer,
  RecordLikeShape,
  RecordPath,
  RecordValidationResult,
} from '@lemonade-hq/maschema-schema';
import type { Context } from 'react';
import { createContext, useContext } from 'react';
import type { PartialDeep } from 'type-fest';
import type { Config, FormStatus, Rules, Subscribe, SubscriptionGroup } from './types';

type ActionBase<TType extends string> = {
  readonly type: TType;
};

interface ResetAction extends ActionBase<'reset'> {}

interface SetValueAction<TSchema extends RecordLikeShape, TKey extends RecordPath<TSchema>, TChangePayload>
  extends ActionBase<'setValue'> {
  readonly key: TKey;
  readonly value: Infer<ExtractShapeFromPath<TSchema, TKey>>;
  readonly changePayload?: TChangePayload;
}

interface SetRulesAction<TSchema extends RecordLikeShape, TKey extends RecordPath<TSchema>>
  extends ActionBase<'setRules'> {
  readonly key: TKey;
  readonly rules: Rules<TSchema, TKey>;
}

interface SetStatusAction extends ActionBase<'setShowErrors'> {
  readonly shouldShowErrors: boolean;
}

interface BlurAction<TSchema extends RecordLikeShape> extends ActionBase<'blur'> {
  readonly key: RecordPath<TSchema>;
}

interface SubscribeAction<TSchema extends RecordLikeShape, TChangePayload> extends ActionBase<'subscribe'> {
  readonly key: string;
  readonly subscriptions: SubscriptionGroup<TSchema, TChangePayload>;
}

interface UnsubscribeAction<TSchema extends RecordLikeShape> extends ActionBase<'unsubscribe'> {
  readonly key: string;
  readonly subscriptions: SubscriptionGroup<TSchema>;
}

export type Action<TSchema extends RecordLikeShape, TKey extends RecordPath<TSchema>, TChangePayload> =
  | BlurAction<TSchema>
  | ResetAction
  | SetRulesAction<TSchema, TKey>
  | SetStatusAction
  | SetValueAction<TSchema, TKey, TChangePayload>
  | SubscribeAction<TSchema, TChangePayload>
  | UnsubscribeAction<TSchema>;

export type Dispatch<TSchema extends RecordLikeShape, TChangePayload = never> = <TKey extends RecordPath<TSchema>>(
  action: Action<TSchema, TKey, TChangePayload>,
) => Infer<TSchema>;

export type FormContextData<TSchema extends RecordLikeShape, TChangePayload = never> = {
  readonly schema: TSchema;
  readonly values: PartialDeep<Infer<TSchema, { readonly mergeUnions: true }>>;
  readonly validationResults: RecordValidationResult;
  readonly config: Config<TSchema>;
  readonly dispatch: Dispatch<TSchema, TChangePayload>;
  readonly status: FormStatus<TSchema>;
  readonly subscriptions: Subscribe<TSchema, TChangePayload>;
};

export const FormContext = createContext<FormContextData<RecordLikeShape> | null>(null);

export const useForm = <TSchema extends RecordLikeShape, TChangePayload = never>(): FormContextData<
  TSchema,
  TChangePayload
> => {
  const context = useContext<FormContextData<TSchema, TChangePayload> | null>(
    FormContext as unknown as Context<FormContextData<TSchema, TChangePayload> | null>,
  );

  if (context == null) {
    throw new Error('useForm must be used inside a Form component');
  }

  return context as unknown as FormContextData<TSchema>;
};
