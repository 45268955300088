/* eslint-disable jsx-a11y/no-autofocus */
import { FormInputGroup, FormLayout, generateTypedFormComponents } from '@lemonade-hq/blender-ui';
import { pick } from '@lemonade-hq/maschema-schema';
import { clientToolSchema } from '@lemonade-hq/persisted-tools';
import type { FC } from 'react';

const questionShape = pick(clientToolSchema, 'questions').schema.questions.shape;
const { ErrorMessage, TextArea } = generateTypedFormComponents<typeof questionShape>();

export const ToolEditorQuestion: FC = () => {
    return (
        <FormLayout>
            <FormInputGroup>
                <TextArea
                    aria-label="question"
                    autoExpanding
                    autoFocus
                    placeholder="Type here..."
                    schemaKey="content"
                />
                <ErrorMessage schemaKey="content" />
            </FormInputGroup>
        </FormLayout>
    );
};
