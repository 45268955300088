import { isManageAuthError } from '@lemonade-hq/bluis';
import type React from 'react';
import { GenericErrorBoundary } from './ErrorBoundary';
import { getFallbackComponent } from './ErrorFallBackComponents';
import { handleSentryErrorCapture } from './utils';

interface SectionErrorBoundaryProps {
  readonly children: React.ReactNode;
  readonly title?: string;
}

export class SectionErrorBoundary extends GenericErrorBoundary<SectionErrorBoundaryProps> {
  render(): React.ReactNode {
    const { error } = this.state;
    const { children, title = '' } = this.props;

    if (error == null) return children;
    if (isManageAuthError(error)) throw error;

    handleSentryErrorCapture(error);

    return getFallbackComponent({ error, boundaryArgs: { title, type: 'section' } });
  }
}
export function withSectionErrorBoundary<P>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: React.ComponentType<P>,
  title?: string,
): React.ComponentType<P & object> {
  return (props: P & object) => (
    <SectionErrorBoundary title={title}>
      <Component {...props} />
    </SectionErrorBoundary>
  );
}
