import type { TagMode } from '@lemonade-hq/bluis';
import type { Locale, Region } from '@lemonade-hq/lemonation';
import type { Timezone } from '@lemonade-hq/ts-helpers';
import type { DebugLogs, TitledTable } from '../Playground/simulation/simulations.types';

export enum Tag {
    BadFail = 'bad_fail',
    Fail = 'fail',
    Pass = 'pass',
    Wow = 'wow',
}

export enum PartyInvolvement {
    Cc = 'cc',
    From = 'from',
    To = 'to',
}

export enum InitiatingSide {
    Lemonade = 'lemonade',
    NonLemonade = 'non_lemonade',
}

export interface CommentParty {
    readonly email: string;
    readonly side: string;
    readonly involvement: PartyInvolvement;
}

export interface Message {
    /**
     * Either a Zendesk comment ID or a Sunco message ID
     */
    readonly publicId: string;
    readonly type?: TimelineItemType.Message;
    readonly from: {
        readonly side: InitiatingSide;
        readonly name?: string;
        readonly email?: string;
        readonly phoneNumber?: string;
    };
    readonly sentAt?: string;
    readonly plainTextBody: string;
    /**
     * Is only present for email messages
     */
    readonly htmlBody?: string;
}

export enum TicketAction {
    Escalate = 'escalate',
    Respond = 'respond',
    Close = 'close',
}

export enum TimelineItemType {
    Log = 'log',
    Insight = 'insight',
    Message = 'message',
}

export enum LogActionType {
    Closed = 'closed',
    Escalated = 'escalated',
    Tagged = 'tagged',
    Error = 'error',
    Info = 'info',
}

interface DescriptionElement {
    readonly key: string;
    readonly value?: string;
}

export interface TimelineEvent {
    readonly publicId: string;
    readonly type: TimelineItemType.Insight | TimelineItemType.Log;
    readonly title: string;
    readonly description?: DescriptionElement[] | string;
    readonly data?: TitledTable[];
    readonly debug?: DebugLogs;
    readonly sentAt?: string;
    readonly action: LogActionType;
}

export type TimelineItem = Message | TimelineEvent;

type EmailInteraction = {
    readonly email: string;
    readonly firstName: string;
    readonly locale?: Locale;
    readonly name: string;
    readonly subject: string;
    readonly timezone?: Timezone;
    readonly url: string;
};

type SmsInteraction = {
    readonly email?: string;
    readonly firstName?: string;
    readonly locale?: Locale;
    readonly name?: string;
    readonly subject?: string;
    readonly timezone?: Timezone;
    readonly url?: string;
};

export interface ReviewStats {
    readonly numberOfPassedReviews: number;
    readonly numberOfFailedReviews: number;
    readonly numberOfDistinctReviewsLeft: number;
}

export type ConsensusResult = 'FAIL' | 'PASS' | 'TIE';

export interface TicketAiReview {
    readonly timeline?: TimelineItem[];
    readonly actions: {
        readonly skipAvailable: boolean;
        readonly reviewAvailable: boolean;
        readonly interveneAvailable: boolean;
        readonly noInterventionReason?: string;
        readonly skipAfterReview: boolean;
    };
    readonly classificationParams: {
        readonly entityIds?: string[];
        readonly products: string[];
        readonly toolsList?: Record<string, string>;
    };

    readonly usedPrompts?: Record<string, ParsedPrompt>;
    readonly messages?: Message[];
    readonly htmlSuggestedResponse?: string;
    readonly interaction: EmailInteraction | SmsInteraction;
    readonly entityProduct?: string | null; // Product (e.g. 'home', 'pet')
    readonly entityPublicId?: string | null;
    readonly publicId: string;
    readonly medium: Medium;
    readonly sentAt?: string;
    readonly toolInvoked: string;
    readonly type: AiResponseType;
    readonly action: TicketAction;
    readonly assignee: string | null;
    readonly newerMessageExists: boolean;
    readonly reviewStats?: ReviewStats;
}

export interface Review {
    readonly additionalInfo?: string;
    readonly reason?: string;
    readonly updatedParams?: UpdatedParams;
    readonly tag: Tag;
}

export enum AiResponseType {
    Draft = 'draft',
    Public = 'public',
}

export enum InterveneType {
    AdditionalResponse = 'additional_response',
    Edit = 'edit',
}

export interface ReviewParams {
    readonly additionalInfo?: string;
    readonly reason?: string;
    readonly tag: Tag;
    readonly ticketId: string;
    readonly updatedParams?: UpdatedParams;
}

export interface UpdatedParams {
    readonly api?: string;
    readonly policyId?: string;
    readonly product?: string;
}

export interface InterveneParams {
    readonly ticketId: string;
    readonly intervenedResponse: string;
    readonly tags?: string[];
    readonly type: InterveneType;
}

export interface TicketParam {
    readonly id: string;
    readonly mode: TagMode;
    readonly text: string;
}

export type PromptSection = {
    readonly title: string;
    readonly text: string;
};
export type ParsedPrompt = {
    readonly title: string;
    readonly mainSection?: string;
    readonly subsections?: PromptSection[];
};

export enum FixReason {
    IncorrectParameters = 'incorrectParameters',
    PoorTone = 'poorTone',
    MisleadingInformation = 'misleadingInformation',
    Other = 'other',
}

export enum TagLabel {
    BadFail = 'Bad fail',
    Fail = 'Fail',
    Pass = 'Pass',
}

export type AssignNextTicketResponseDTO = { readonly publicId: string; readonly type: AiResponseType } | null;

interface BaseFilters {
    readonly mediums: Medium[];
    readonly languages: string[];
    readonly regions: Region[];
}

export interface SupervisorTicketFilters extends BaseFilters {
    readonly intents: string[];
    readonly products: string[];
}

export interface SupervisorMetadataResponse extends BaseFilters {
    readonly availableProducts: string[];
    readonly possibleTools: Record<string, string>;
    readonly possibleNonToolsIntents: Record<string, string>;
    readonly pendingReviews: {
        readonly draft: number;
        readonly public: number;
    };
    readonly reviewedTodayCount: number;
}
export interface SupervisorMetadata extends BaseFilters {
    readonly pendingTicketsCount: {
        readonly draft: number;
        readonly public: number;
    };
    readonly intents: {
        readonly tools: Record<string, string>;
        readonly nonTools: Record<string, string>;
    };
    readonly products: string[];
    readonly reviewedTodayCount: number;
}

export enum Medium {
    Email = 'email',
    Sms = 'sms',
}

export interface AssignNextTicketPayload {
    readonly responseType: AiResponseType;
    readonly filters: SupervisorTicketFilters & {
        readonly products?: string[];
        readonly toolTypes?: string[];
    };
}
