export enum TopicInsight {
    Declined = 'declined',
    DifferentPrice = 'different_price',
    MissingRequiredCoverages = 'missing_required_coverages',
    UnsupportedCoverage = 'unsupported_coverage',
    MissingRequiredSettings = 'missing_required_settings',
    UnsupportedSettings = 'unsupported_settings',
    IrrelevantSettings = 'irrelevant_settings',
    UnsupportedSettingValues = 'unsupported_setting_values',
}

export enum RolloutInsightTopic {
    PotentiallyExpiringQuotes = 'potentially_expiring_quotes',
    RenewalPoliciesRefresh = 'renewal_policies_refresh',
    ActiveQuotes = 'active_quotes',
    UpcomingRenewalsPolicies = 'upcoming_renewals_policies',
    ActivePolicies = 'active_policies',
}

export interface Insight {
    readonly insight: TopicInsight;
    readonly metCriteriaCount: number;
    readonly metCriteriaPercent: number;
    readonly failedToAnalyzeCount: number;
    readonly failedToAnalyzePercent: number;
}

export interface Topic {
    readonly topic: RolloutInsightTopic;
    readonly analyzedCount: number;
    readonly metCriteriaCount: number;
    readonly metCriteriaPercent: number;
    readonly insights: Insight[];
    readonly csvLink?: string;
}

export interface Report {
    readonly triggeredAt: string;
    readonly topics: Topic[];
}

export interface ReleaseRolloutInsightsReport {
    readonly reportInProgress: boolean;
    readonly report?: Report;
}
