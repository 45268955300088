import React, { ComponentProps, FC } from 'react';
import { Tab } from '@lemonade-hq/bluis';
import { SearchResults } from 'components/Header/SearchBar/helpers';

export enum TabState {
    Enabled = 'enabled',
    Disabled = 'disabled',
    Hidden = 'hidden',
}

export function getTabState(content: SearchResults['claims' | 'users' | 'quotes' | 'policies']): TabState {
    if (!content) return TabState.Hidden;
    return content.length > 0 ? TabState.Enabled : TabState.Disabled;
}

type SearchTabProps = ComponentProps<typeof Tab> & {
    state: TabState;
};

export const SearchTab: FC<React.PropsWithChildren<SearchTabProps>> = ({ state, ...props }) => {
    switch (state) {
        case TabState.Disabled:
            return <Tab disabled {...props} />;
        case TabState.Hidden:
            return null;
        default:
            return <Tab {...props} />;
    }
};
