import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CommunicationTimelineResponse, getCommunicationTimelineV2 } from 'apis/Communications/CommunicationsAPI';
import { NoteClaimType as CommunicationEntityType, CommunicationTimelineFilters } from 'models/Interaction';

export enum InteractionQueryKeys {
    GetCommunicationTimeline = 'GET_COMMUNICATION_TIMELINE',
}

export function useCommunicationTimeline(
    entityId: string,
    entityType: CommunicationEntityType,
    filters?: CommunicationTimelineFilters
): UseQueryResult<CommunicationTimelineResponse> {
    return useQuery({
        queryKey: [InteractionQueryKeys.GetCommunicationTimeline, entityId, filters],

        queryFn: async () => getCommunicationTimelineV2(entityId, entityType, filters),
    });
}
