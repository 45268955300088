import type { Infer, RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import get from 'lodash/get';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { SelectionMode } from '../../../../theme/selection';
import { ComboBox as BUIComboBox } from '../../../ComboBox/ComboBox';
import type { ComboBoxProps as BUIComboBoxProps } from '../../../ComboBox/ComboBox';
import { useForm } from '../../FormContext';
import { getOptionsFromSchema } from '../../utils';
import { isSchemaKeyRequired, useConnectToForms } from '../common';
import type { CommonAdapterProps } from '../common';
import type { AssertSchemaKeyIsArrayOfTypeAtomic, AssertSchemaKeyIsOfTypeAtomic } from '../shape-assertion.types';

export type ComboBoxProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TMode extends SelectionMode = 'single',
> = CommonAdapterProps<TSchema, TSchemaKey> &
  Omit<BUIComboBoxProps<TMode>, 'cancelable' | 'disabled' | 'items' | 'onSelectionChange' | 'value'> & {
    readonly labels?: Record<Infer<TSchema>[NoInfer<TSchemaKey>], string>;
  };

export type AssertedComboBoxProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TMode extends SelectionMode = 'single',
> = Omit<BUIComboBoxProps<TMode>, 'items' | 'onChange' | 'onSelectionChange' | 'options' | 'value'> &
  (ComboBoxProps<TSchema, TSchemaKey, TMode> extends infer TExtractedProps extends {
    readonly schemaKey: infer TSchemaKeyToCheck extends NoInfer<TSchemaKey>;
  }
    ? NoInfer<TMode> extends 'single'
      ? AssertSchemaKeyIsOfTypeAtomic<TSchema, TSchemaKeyToCheck, TExtractedProps>
      : AssertSchemaKeyIsArrayOfTypeAtomic<TSchema, TSchemaKeyToCheck, TExtractedProps>
    : never);

/**
 * The ComboBoxAdapter component wraps the ComboBox component and integrates it into a form context.
 * It ensures proper binding to form state and schema validation.
 */
export const ComboBox = <
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TMode extends SelectionMode = 'single',
>(
  props: AssertedComboBoxProps<TSchema, TSchemaKey, TMode>,
): ReactNode => {
  const { schemaKey, rules, placeholder, defaultValue: externalDefaultValue, labels, mode, ...restProps } = props;
  const { values: data, dispatch, schema } = useForm<TSchema>();
  const { visible, disabled } = useConnectToForms({ schemaKey, rules });

  const optionsFromSchema = useMemo(() => {
    return getOptionsFromSchema<string>(schema, schemaKey, data, mode);
  }, [schema, schemaKey, data, mode]);

  const isRequired = useMemo(() => {
    return isSchemaKeyRequired(schemaKey, schema, data);
  }, [schema, schemaKey, data]);

  const defaultValue = externalDefaultValue ?? get(data, schemaKey);
  const unTypedLabels = labels as Record<string, string> | undefined;

  return visible ? (
    <BUIComboBox
      cancelable={!isRequired}
      defaultValue={defaultValue}
      disabled={disabled}
      items={optionsFromSchema.map(option => ({
        value: option,
        label: unTypedLabels ? unTypedLabels[option] : option,
      }))}
      mode={mode}
      onSelectionChange={item => {
        dispatch({
          type: 'setValue',
          key: schemaKey as RecordPath<TSchema>,
          value: (Array.isArray(item) ? item.map(i => i.value) : item?.value) as Infer<TSchema>[RecordPath<TSchema>],
        });
      }}
      placeholder={placeholder}
      {...restProps}
    />
  ) : null;
};
