import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { blenderGeneral } from '../../../../../../apiClients';
import { usePessimisticMutation } from "queries/MutationHooks";

enum RaterQueryKey {
    HasAgentPassword = 'has_agent_password',
}

const SET_PASSWORD_URL = '/api/v1/partners/agent_password';

type SetPasswordResponse = {
    readonly setPassword: boolean;
};

export function useHasAgentPassword(): UseQueryResult<boolean> {
    return useQuery({
        queryKey: [RaterQueryKey.HasAgentPassword],
        queryFn: async () => blenderGeneral.get<{ data: SetPasswordResponse }>(SET_PASSWORD_URL),

        select: response => {
            return !response.data.data.setPassword;
        },
    });
}

export function useSetAgentPassword(): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        invalidateKeys: [[RaterQueryKey.HasAgentPassword]],
        mutationFn: async (password: string) => {
            await blenderGeneral.post<SetPasswordResponse>(SET_PASSWORD_URL, { password });
        },
    });
}
