import { clsx } from 'clsx';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';
import { Card } from '../Card/Card';
import type { UseSortableDndResult } from '../SortableDnd/useSortableDnd';
import * as styles from './DynamicList.css';

export interface DynamicListItemProps {
  readonly additionalComponent?: ReactNode;
  readonly variant: 'ordered' | 'unordered';
  readonly className?: string;
  readonly invalid?: boolean;
  readonly sortable?: boolean;
}

export const DynamicListItem: FC<
  PropsWithChildren<
    DynamicListItemProps &
      Pick<Partial<UseSortableDndResult>, 'isDragging' | 'setDragNodeRef' | 'style'> & {
        readonly dragHandle?: JSX.Element;
      }
  >
> = ({
  variant,
  className,
  invalid,
  additionalComponent,
  dragHandle,
  isDragging,
  setDragNodeRef,
  style,
  sortable = false,
  children,
}) => {
  return (
    <Card
      borderRadius="md"
      className={clsx(className, styles.recordListItem({ variant, sortable }))}
      data-dragging={isDragging}
      data-has-error={invalid}
      ref={setDragNodeRef}
      style={style}
      variant="primary"
    >
      <Flex alignItems="center" gap={spacing.s04} width="100%">
        {dragHandle}
        {children}
      </Flex>
      {additionalComponent}
    </Card>
  );
};

export const DynamicListText: FC<PropsWithChildren<{ readonly text: string }>> = ({ text }) => {
  return (
    <Text alignSelf="center" as="li" wordBreak="break-word">
      {text}
    </Text>
  );
};
