import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type { RowItem, TableHeaderCellProps } from '@lemonade-hq/bluis';
import {
    ContentSection,
    EmptySection,
    ErrorSection,
    LoadingSection,
    Table,
    TableHeader,
    TableRow,
    TableTitle,
} from '@lemonade-hq/bluis';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { LabelWithTooltip } from '../coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedLabels';
import { RatingSetting } from './RatingSetting';
import { getSettingTypeDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import type { CoverageResponse, SettingResponse } from 'models/LoCo/Insurance/Rating';
import { useSuspenseGetRatingEditionMetadata } from 'queries/LoCo/Insurance/RatingEditionQueries';

const CoverageRow: React.FC<{
    readonly coverage: CoverageResponse;
}> = ({ coverage }) => {
    const rowItems: RowItem[] = [
        {
            key: 'type',
            value: isDefined(coverage.error) ? (
                <LabelWithTooltip label={coverage.name} tooltipContent={coverage.error} type="error" />
            ) : (
                coverage.name
            ),
        },
    ];

    return <TableRow key={coverage.code} row={rowItems} />;
};

const coveragesHeaderFields: TableHeaderCellProps[] = [{ key: 'name', value: 'Name' }];

const CoveragesTable: React.FC<{ readonly coverages: CoverageResponse[] }> = ({ coverages }) => {
    if (coverages.length === 0) {
        return <EmptySection>No Coverages found for this Rating edition</EmptySection>;
    }

    return (
        <Flex flexDirection="column" gap={spacing.s24}>
            <TableTitle title="Coverages" />
            <Table>
                <TableHeader headers={coveragesHeaderFields} />
                {coverages.map(coverage => (
                    <CoverageRow coverage={coverage} key={coverage.code} />
                ))}
            </Table>
        </Flex>
    );
};

const SettingRow: React.FC<{
    readonly setting: SettingResponse;
}> = ({ setting }) => {
    const rowItems: RowItem[] = [
        {
            key: 'name',
            value: isDefined(setting.error) ? (
                <LabelWithTooltip label={setting.name} tooltipContent={setting.error} type="error" />
            ) : (
                setting.name
            ),
        },
        { key: 'type', value: setting.type ? getSettingTypeDisplayName(setting.type) : '' },
        { key: 'values', value: <RatingSetting setting={setting} /> },
    ];

    return <TableRow key={setting.code} row={rowItems} />;
};

const settingHeaderFields: TableHeaderCellProps[] = [
    { key: 'name', value: 'Name' },
    { key: 'type', value: 'Type' },
    { key: 'values', value: 'Values' },
];

const SettingsTable: React.FC<{ readonly settings: SettingResponse[] }> = ({ settings }) => {
    if (settings.length === 0) {
        return <EmptySection>No Settings found for this Rating edition</EmptySection>;
    }

    return (
        <Flex flexDirection="column" gap={spacing.s24}>
            <TableTitle title="Settings" />
            <Table>
                <TableHeader headers={settingHeaderFields} />
                {settings.map(setting => (
                    <SettingRow key={setting.code} setting={setting} />
                ))}
            </Table>
        </Flex>
    );
};

const MetadataTables: React.FC = () => {
    const { ratingEditionCode = '' } = useParams<{ ratingEditionCode: string }>();
    const { data } = useSuspenseGetRatingEditionMetadata(ratingEditionCode);
    return (
        <Flex flexDirection="column" gap={spacing.s48}>
            <ContentSection>
                <CoveragesTable coverages={data.coverages} />
            </ContentSection>
            <ContentSection>
                <SettingsTable settings={data.settings} />
            </ContentSection>
        </Flex>
    );
};

export const RatingMetadata: React.FC = () => {
    return (
        <ErrorBoundary fallback={<ErrorSection noBorders title="Error fetching schema metadata :(" />}>
            <Suspense fallback={<LoadingSection noBorders />}>
                <MetadataTables />
            </Suspense>
        </ErrorBoundary>
    );
};
