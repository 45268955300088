import { MiniFlow } from '@lemonade-hq/bluis';
import type { ComponentProps, FC } from 'react';

import styled from 'styled-components';

export const StyledMiniFlow: FC<ComponentProps<typeof MiniFlow>> = styled(MiniFlow)`
    height: 100%;

    > div {
        height: 100%;

        > div {
            height: 100%;
        }
    }
`;
