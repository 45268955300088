import { DialogType } from './DigitalAgentShared';
import type { ActionData } from './DigitalAgentShared';
import { ManageRuleDialog } from 'components/LoCo/common/components/CoverageRules/ManageRuleDialog';
import type { HighlightedEntities } from 'components/LoCo/common/components/CoverageRules/Steps/EntitySelection';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';

export interface DigitalAgentEditionDialogsProps {
    readonly editionCode: string;
    readonly dialog: ActionData | null;
    readonly onClose: () => void;
    readonly highlightedEntities?: HighlightedEntities;
}

export const DigitalAgentEditionDialogs: React.FC<DigitalAgentEditionDialogsProps> = ({
    editionCode,
    dialog,
    onClose,
    highlightedEntities,
}) => {
    if (!dialog) return null;

    switch (dialog.type) {
        case DialogType.AddRule:
            return (
                <ManageRuleDialog
                    allVariants={dialog.allVariants}
                    editionCode={editionCode}
                    editionType={EditionType.DigitalAgent}
                    entity={dialog.entity}
                    highlightedEntities={highlightedEntities}
                    lifecycleContexts={dialog.lifecycleContexts}
                    onClose={onClose}
                    ruleType={dialog.ruleType}
                    variantName={dialog.variantName}
                />
            );
        default:
            return null;
    }
};
