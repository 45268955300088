import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';

export const DATE_FNS_FORMATS = {
    ISO_8601_UTC_HUMANIZED: 'MMMM dd, yyyy HH:mm:ss zzz',
    ISO_8601_UTC_HUMANIZED_NO_TIME: 'MMMM dd, yyyy zzz',
    SIMPLE_DATE_MEDIUM: 'MM/dd/yyyy',
    SIMPLE_DATE_MONTH_DAY: 'MMM dd',
    SIMPLE_SERVER_FORMAT: 'yyyy-MM-dd',
};

export const FORMATS = {
    ISO_8601_UTC: 'YYYY-MM-DD HH:mm:ss z',
    ISO_8601_UTC_SHORT: 'M/D/YY HH:mm:ss z',
    ISO_8601_UTC_SHORT_COMMA: 'M/D/YY, HH:mm:ss z',
    ISO_8601_UTC_HUMANIZED: 'MMMM DD, YYYY HH:mm:ss z',
    ISO_8601_UTC_HUMANIZED_NO_SECONDS: 'MMMM DD, YYYY HH:mm z',
    ISO_8601_UTC_HUMANIZED_12H: 'MMMM DD, YYYY H:mmA z',
    ISO_8601_UTC_HUMANIZED_SHORT: 'MMMM DD, YYYY',
    ISO_8601_HUMANIZED: 'MMMM DD, YYYY HH:mm:ss',
    SIMPLE_DATE: 'MMMM DD, YYYY',
    SIMPLE_DATE_EXPLICIT_MONTH: 'MMMM dd, yyyy',
    SIMPLE_DATE_SHORT_MONTH: 'MMM DD, YYYY',
    SIMPLE_DATE_SHORT: 'M/D/YY',
    SIMPLE_DATE_SHORT_FULL_YEAR: 'M/D/YYYY',
    SIMPLE_DATE_MEDIUM: 'MM/DD/YYYY',
    SIMPLE_DATE_MDEIUM_WITH_TIME: 'MM/DD/YYYY HH:mm zz',
    UTC_ONLY_DATE: 'YYYY-MM-DD',
    ANOTHER_SIMPLE_DATE: 'MMMM Do YYYY HH:mm:ss z',
    ANOTHER_SIMPLE_DATE_NO_TIME: 'MMMM Do YYYY',
    ANOTHER_SIMPLE_DATE_ONLY_TIME: 'HH:mm:ss z',
    ANOTHER_SIMPLE_DATE_ONLY_TIME_SHORT: 'HH:mm:ss',
    ANOTHER_SIMPLE_DATE_12_TIME: 'H:mmA z',
    ANOTHER_SIMPLE_DATE_12_TIME_WITH_DATE: 'MMM DD, YYYY H:mm A z',
    ANOTHER_SIMPLE_DATE_12_TIME_WITH_DATE_NO_TZ: 'MMM DD, YYYY H:mm A',
    ANOTHER_SIMPLE_DATE_12_TIME_WITH_DATE_LONG_MONTH: 'h:mm A, MMMM D, YYYY',
    MONTH_YEAR: 'MMM-yyyy',
    LOCAL_AWARE_DATE: 'LL',
    LOCAL_AWARE_DATE_AND_TIME: 'LLL',
    INT: {
        SIMPLE_DATE_SHORT: 'D/M/YY',
        ISO_8601_UTC_SHORT: 'D/M/YY HH:mm:ss z',
        ISO_8601_UTC_SHORT_COMMA: 'D/M/YY, HH:mm:ss z',
    },
    TIMEZONE: 'z',
    TIME: 'h:mm a, MMMM d, YYYY',
};

export const FORMAT_NAMES = Object.keys(FORMATS).reduce((acc, cur) => {
    acc[cur] = cur;
    return acc;
}, {});

export function formatDate(date: Date | string, format: string, timezone?: string): string {
    return timezone ? moment(date).tz(timezone).format(format) : moment(date).format(format);
}

export function formatUtcDate(date: string, format: string, timezone?: string): string {
    return timezone ? moment.utc(date).tz(timezone).format(format) : moment.utc(date).format(format);
}

export function formatUnix(unix: number, format: string, timezone?: string): string {
    return timezone ? moment.unix(unix).tz(timezone).format(format) : moment.unix(unix).format(format);
}

export function formatDateOrUnix(date: Date | number, format: string, timezone?: string): string {
    return Number.isNaN(Number(date))
        ? formatDate(date as Date, format, timezone)
        : formatUnix(Number(date), format, timezone);
}

export function formatTimezone(timezone: string): string {
    return moment.tz(timezone).format(FORMATS.TIMEZONE);
}

export function convertMillisToDateParts(millis: number): {
    readonly days: number;
    readonly hours: number;
    readonly minutes: number;
    readonly seconds: number;
} {
    const s = Math.floor(millis / 1000);
    const m = Math.floor(s / 60);
    const h = Math.floor(m / 60);

    const seconds = s % 60;
    const minutes = m % 60;
    const hours = h % 24;
    const days = Math.floor(h / 24);

    return { days, hours, minutes, seconds };
}

export function dateToUnix(date: Date): number {
    return Math.floor(date.getTime() / 1000);
}

export function dateToMoment(date: string, timezone?: string) {
    return timezone ? moment.tz(date, timezone) : moment(date);
}

export function getFormatByCountry(country: string, format: string) {
    return country === 'US' ? FORMATS[format] : FORMATS.INT[format];
}

export function isInPast(date: Moment, minDate: Moment) {
    return date.isBefore(minDate.startOf('day'));
}

export function isBeyondMax(date: Moment, maxDate: Moment) {
    return !date.isBefore(maxDate.endOf('day'));
}

export const yearsFrom = (date: Date): number => {
    const diff = Date.now() - date.getTime();
    const age = new Date(diff);

    return Math.abs(age.getUTCFullYear() - 1970);
};

export const getBrowserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
