import { isManageAuthError } from '@lemonade-hq/bluis';
import type React from 'react';
import { GenericErrorBoundary } from './ErrorBoundary';
import { getFallbackComponent, PermissionNeededDialog } from './ErrorFallBackComponents';
import { handleSentryErrorCapture } from './utils';

interface RootErrorBoundaryProps {
  readonly errorComponent?: React.ElementType;
}

export class RootErrorBoundary extends GenericErrorBoundary<React.PropsWithChildren<RootErrorBoundaryProps>> {
  constructor(props: RootErrorBoundaryProps) {
    super(props);

    this.onError = this.onError.bind(this);
    this.onClosePermissionDialog = this.onClosePermissionDialog.bind(this);
  }

  onClosePermissionDialog(): void {
    this.setState({ error: undefined });
  }

  onError(event: ErrorEvent | PromiseRejectionEvent): void {
    const error = (event instanceof ErrorEvent ? event.error : event.reason) as Error;
    if (isManageAuthError(error)) this.setState({ error });
  }

  componentDidMount(): void {
    // The following listeners handle the failure of async calls, because it can't catch them with error boundaries.
    // as it happens outside of the React render-cycle.
    window.addEventListener('error', this.onError);
    window.addEventListener('unhandledrejection', this.onError);
  }

  componentWillUnmount(): void {
    window.removeEventListener('error', this.onError);
    window.removeEventListener('unhandledrejection', this.onError);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO:: fix me
  render(): any {
    const { children, errorComponent } = this.props;
    const { error } = this.state;

    if (error == null) return children;

    handleSentryErrorCapture(error);

    if (isManageAuthError(error)) {
      return (
        <>
          {children}
          <PermissionNeededDialog error={error} onClose={this.onClosePermissionDialog} />
        </>
      );
    }

    const fallbackComponent = errorComponent ?? getFallbackComponent({ error });
    return fallbackComponent;
  }
}
