import { ErrorSection, TagMode } from '@lemonade-hq/bluis';
import React from 'react';
import styled from 'styled-components';
import { useImpersonation, useMyAttributes } from '../../hooks';
import { Form } from '../Form';
import { LoadingContainer } from '../Form/styles';
import { Container, Header, Pill, SubTitle, Title, TitleWrapper } from './styles';

const StyledErrorSection = styled(ErrorSection)`
    height: auto;
    margin: 0;
    padding: 0;
`;

export const Dialog: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { isImpersonating: active, start, stop } = useImpersonation();
    const { data: myAttributes, isError, isPending: isLoading } = useMyAttributes();

    return (
        <Container>
            <Header>
                <TitleWrapper>
                    <Title>Impersonate as</Title>
                    <Pill active={active} light mode={active ? TagMode.Success : TagMode.Info}>
                        {active ? 'Active' : 'Inactive'}
                    </Pill>
                </TitleWrapper>
                <SubTitle>This will affect all open Blender tabs</SubTitle>
            </Header>
            {isLoading ? (
                <LoadingContainer />
            ) : isError ? (
                <StyledErrorSection noBorders />
            ) : myAttributes == null ? null : (
                <Form initialValues={myAttributes} onStart={start} onStop={active ? stop : undefined} />
            )}
        </Container>
    );
};
