import type { CoverageRuleDisplay, RuleEntityType } from './CoverageRule';
import { CoverageRuleType } from './CoverageRule';
import type { CoverageInstance, SettingInstance } from './CoveragesEdition';
import { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';

export enum CoverageRuleGroupType {
    NewBusinessInitialValue = 'new_business_initial_value',
    RenewalInitialValue = 'renewal_initial_value',
    MovingInitialValue = 'moving_initial_value',
    Restriction = 'restriction',
}

type VariantType = 'control' | 'test';

export interface Variant {
    readonly rules: CoverageRuleDisplay[];
    readonly variantName: string;
    readonly variantType: VariantType;
}

export enum Mode {
    ABTest = 'ab_test',
    Plain = 'plain',
}

export interface CoverageRuleGroupBase {
    readonly mode: Mode;
    readonly groupDisplayName: string;
    readonly alerts: Alert<AlertType>[];
}

export interface CoverageRuleGroupABTest extends CoverageRuleGroupBase {
    readonly mode: Mode.ABTest;
    readonly experimentName: string;
    readonly variants: Variant[];
}

export interface CoverageRuleGroupPlain extends CoverageRuleGroupBase {
    readonly mode: Mode.Plain;
    readonly rules: CoverageRuleDisplay[];
}

export const isCoverageRuleGroupPlain = (group: CoverageRuleGroupBase): group is CoverageRuleGroupPlain =>
    group.mode === Mode.Plain;

export const isCoverageRuleGroupABTest = (group: CoverageRuleGroupBase): group is CoverageRuleGroupABTest =>
    group.mode === Mode.ABTest;

export type CoverageRulesGroup = CoverageRuleGroupABTest | CoverageRuleGroupPlain;

export interface EntityGroups {
    // All rules for specific entity (e.g base_deductible)
    readonly entityType: RuleEntityType;
    readonly entityCode: string;
    readonly entityName: string;
    readonly rulesGroups: Record<CoverageRuleGroupType, CoverageRulesGroup>;
}

export interface DigitalAgentEdition extends Edition {
    readonly entityGroups: EntityGroups[];
}

export interface UpdateDigitalAgentEditionViewPreferenceParams {
    readonly digitalAgentEditionCode: string;
    readonly coveragesEditionCode: string;
}

interface ABTestRequestBase {
    readonly entityType: RuleEntityType;
    readonly entityCode: string;
    readonly groupType: CoverageRuleGroupType;
}

export interface CreateABTestRequest extends ABTestRequestBase {
    readonly experimentName: string;
    readonly control: string;
    readonly variants: string[];
}

export interface UnbindABTestRequest extends ABTestRequestBase {
    readonly variantToKeep: string;
}

export interface AddRemoveVariantsRequest extends ABTestRequestBase {
    readonly variants: string[];
}

export interface RenameVariantsRequest extends ABTestRequestBase {
    readonly experimentName: string;
    readonly variants?: {
        readonly oldName: string;
        readonly newName: string;
    }[];
}

export interface AttachABTestParams {
    readonly digitalAgentEditionCode: string;
    readonly payload: CreateABTestRequest;
}

export interface UnbindABTestParams {
    readonly digitalAgentEditionCode: string;
    readonly payload: UnbindABTestRequest;
}

export interface AddRemoveVariantsParams {
    readonly digitalAgentEditionCode: string;
    readonly payload: AddRemoveVariantsRequest;
}

export interface RenameVariantsParams {
    readonly digitalAgentEditionCode: string;
    readonly payload: RenameVariantsRequest;
}

export interface GetDigitalAgentEditionViewPreferenceResponse {
    readonly coveragesEditionCode: string;
}

export enum AlertLevel {
    Warning = 'warning',
    Info = 'info',
}

export interface Alert<T> {
    readonly type: T;
    readonly level: AlertLevel;
    readonly title: string;
    readonly message: string;
}

export enum AlertType {
    FallbackValueMissing = 'fallback_value_missing',
    RequiredCoverage = 'required_coverage',
    MissingInstanceInCoverageEdition = 'missing_instance_in_coverage_edition',
    NoNonFiledRules = 'no_non_filed_rules',
    IdenticalVariants = 'identical_variants',
}

export interface DigitalAgentPreviewEntity<T> {
    readonly code: string;
    readonly name: string;
    readonly description: string;
    readonly groupsByType: Record<CoverageRuleGroupType, CoverageRulesGroup>;
    readonly alerts: Alert<AlertType>[];
    readonly instance: T | null;
}

export interface DigitalAgentEditionExtendedPreview {
    readonly coverages: DigitalAgentPreviewEntity<CoverageInstance>[];
    readonly settings: DigitalAgentPreviewEntity<SettingInstance>[];
}

export const CoverageRuleGroupTypeMap: Record<
    CoverageRuleGroupType,
    { readonly lifecycleContext?: RuleLifecycleContext; readonly ruleType: CoverageRuleType }
> = {
    [CoverageRuleGroupType.NewBusinessInitialValue]: {
        lifecycleContext: RuleLifecycleContext.NewBusiness,
        ruleType: CoverageRuleType.InitialValue,
    },
    [CoverageRuleGroupType.RenewalInitialValue]: {
        lifecycleContext: RuleLifecycleContext.Renewal,
        ruleType: CoverageRuleType.InitialValue,
    },
    [CoverageRuleGroupType.MovingInitialValue]: {
        lifecycleContext: RuleLifecycleContext.Moving,
        ruleType: CoverageRuleType.InitialValue,
    },
    [CoverageRuleGroupType.Restriction]: {
        ruleType: CoverageRuleType.Restriction,
    },
};

export function getCoverageRuleGroupType(
    lifecycleContexts: RuleLifecycleContext[],
    ruleType: CoverageRuleType
): CoverageRuleGroupType {
    if (ruleType === CoverageRuleType.Restriction) {
        return CoverageRuleGroupType.Restriction;
    }

    const initialValueLifecycleContext = lifecycleContexts[0];
    switch (initialValueLifecycleContext) {
        case RuleLifecycleContext.NewBusiness:
            return CoverageRuleGroupType.NewBusinessInitialValue;
        case RuleLifecycleContext.Renewal:
            return CoverageRuleGroupType.RenewalInitialValue;
        case RuleLifecycleContext.Moving:
            return CoverageRuleGroupType.MovingInitialValue;
        default:
            throw new Error('Invalid coverage rule group type');
    }
}
