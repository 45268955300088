export const toSentence = (arr: string[]): string => {
    const separator = ', ';
    const shortSentenceTerminal = ' and ';
    const terminal = ', and ';

    if (arr.length === 0) {
        return '';
    }

    if (arr.length === 1) {
        return arr[0];
    }

    if (arr.length === 2) {
        return arr.join(shortSentenceTerminal);
    }

    return `${arr.slice(0, -1).join(separator)}${terminal}${arr.slice(-1)}`;
};
