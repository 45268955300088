import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog, GENERAL_ERROR_MSG } from '@lemonade-hq/bluis';
import { Box, useToggle } from '@lemonade-hq/cdk';
import { useState } from 'react';
import { useSupervisorContext } from '../Context';

interface RemoveDialogProps {
    readonly onClose: () => void;
}

export const RemoveDialog: React.FC<React.PropsWithChildren<RemoveDialogProps>> = ({ onClose }) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { open: onLoad, close: onUnload, isOpen: isLoading } = useToggle(false);

    const {
        actions: { releaseToZendesk },
    } = useSupervisorContext();

    const actions: DialogAction[] = [
        {
            type: 'close',
            text: 'Cancel',
        },
        {
            type: 'submit',
            text: 'Yes, remove',
            onClick: async () => await onSubmit(),
        },
    ];

    async function onSubmit(): Promise<void> {
        setErrorMessage('');
        onLoad();

        try {
            await releaseToZendesk();
            onClose();
        } catch (error: unknown) {
            setErrorMessage(GENERAL_ERROR_MSG);
        } finally {
            onUnload();
        }
    }

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={errorMessage}
            loading={isLoading}
            onClose={onClose}
            size="small"
            title="Remove from Supervisor"
        >
            <Box mb={18}>This ticket will be unassigned from AI Maya and will be visible on Zendesk</Box>
            <div>
                <b>Are you sure?</b>
            </div>
        </Dialog>
    );
};
