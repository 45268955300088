import { ErrorSection, PageWrapper, Separator, Spinner } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import type { FC, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from '../../shared/components/Card';
import { SIDEBAR_WIDTH } from '../supervisor.const';
import { useGetAiResponse } from '../supervisor.queries';
import { buildTicketParams } from '../supervisor.utils';
import { Actions } from './ControlSidebar/Actions';
import { ReviewsSummary } from './ControlSidebar/ReviewsSummary';
import { TicketParams } from './ControlSidebar/TicketParams';
import { BackToSupervisorHomepage } from './FiltersTopBar/BackToSupervisorHomepage';
import { FiltersInfo } from './FiltersTopBar/FiltersInfo';
import { TicketsProgressInfo } from './FiltersTopBar/TicketsProgressInfo';
import { MessagesTimeline } from './MessagesTimeline/MessagesTimeline';
import { ResponseSection } from './ResponseSection';
import { ZendeskWidget } from './ZendeskWidget';
import { HeaderPortal } from 'components/Header';
import { BlenderHeader } from 'components/Header/style';
import { useSupervisorContext } from 'components/Llmnd/Supervisor/components/Context';

const BlenderSecondaryHeader = styled(BlenderHeader)`
    z-index: 0;
`;

const StyledPageWrapper = styled(PageWrapper)`
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
`;

const StyledHeaderBarWrapper = styled(PageWrapper)`
    display: flex;
    flex-direction: column;
`;

const PageLayout = styled(Flex)`
    flex-grow: 1;
`;

const ControlSidebarWrapper = styled.div`
    position: sticky;
    top: 100px;
    height: 100%;
    flex-basis: ${SIDEBAR_WIDTH};
    flex-shrink: 0;
`;

const LoadingRoot = styled.div`
    margin-top: 110px;
    margin-inline: auto;
    text-align: center;
    width: 790px;
`;

const StyledSeparator = styled(Separator)`
    width: 89%;
`;

const LoadingSkeleton: FC<PropsWithChildren<unknown>> = () => (
    <LoadingRoot>
        <Card>
            <Spinner size="large" />
        </Card>
    </LoadingRoot>
);

interface SupervisorPageProps {
    readonly withZDWidget?: boolean;
}

export const SupervisorPage: FC<PropsWithChildren<SupervisorPageProps>> = ({ withZDWidget = true }) => {
    const { ticketId } = useParams<{ ticketId: string }>();
    const {
        actions: supervisorActions,
        state: { ticketData },
    } = useSupervisorContext();

    const { data, isPending, isError } = useGetAiResponse(ticketId ?? '', newData => {
        if (ticketData?.publicId !== newData.publicId) {
            supervisorActions.setMode(newData.type);
            supervisorActions.setTicketData(newData);
        }
    });

    if (isPending) {
        return <LoadingSkeleton />;
    }

    if (isError) {
        return <ErrorSection />;
    }

    const {
        actions,
        messages,
        medium,
        timeline,
        htmlSuggestedResponse,
        type,
        interaction: { email, firstName: userFirstName, locale, name, subject, timezone, url: zendeskUrl },
    } = data;

    const ticketParams = buildTicketParams(data);
    const backwardsCompatibleTimeline = messages ?? timeline;
    const safeName = name ?? userFirstName ?? 'unknown';

    return (
        <>
            <HeaderPortal>
                <BlenderSecondaryHeader>
                    <StyledHeaderBarWrapper>
                        <PageLayout gap="64px">
                            <Flex flexBasis={SIDEBAR_WIDTH} flexShrink={0}>
                                <BackToSupervisorHomepage />
                            </Flex>
                            <span>
                                <TicketsProgressInfo />
                            </span>
                            <Flex flexBasis={300} flexShrink={0}>
                                <FiltersInfo />
                            </Flex>
                        </PageLayout>
                    </StyledHeaderBarWrapper>
                </BlenderSecondaryHeader>
            </HeaderPortal>
            <StyledPageWrapper>
                <PageLayout gap="64px">
                    {withZDWidget && (
                        <Flex flexBasis={SIDEBAR_WIDTH} flexShrink={0} mt={56}>
                            {email != null && email.length > 0 && <ZendeskWidget email={email} />}
                        </Flex>
                    )}
                    <Flex flexDirection="column" flexGrow={1} pb={4} pt={4}>
                        <MessagesTimeline
                            locale={locale}
                            medium={medium}
                            name={safeName}
                            subject={subject}
                            timeline={backwardsCompatibleTimeline!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                            timezone={timezone}
                            zendeskUrl={zendeskUrl}
                        />
                        <ResponseSection
                            actions={actions}
                            email={email}
                            htmlSuggestedResponse={htmlSuggestedResponse}
                            name={safeName}
                            type={type}
                            userFirstName={userFirstName}
                        />
                    </Flex>
                    <ControlSidebarWrapper>
                        <Flex flexDirection="column" gap="24px" mt={56}>
                            <TicketParams params={ticketParams} />
                            <ReviewsSummary />
                            <StyledSeparator />
                            <Actions />
                        </Flex>
                    </ControlSidebarWrapper>
                </PageLayout>
            </StyledPageWrapper>
        </>
    );
};
