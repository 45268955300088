import { useCallback, useMemo } from 'react';
import type { SchemaAutocompleteOption } from '../SchemaAutocomplete';
import { SchemaAutocomplete } from '../SchemaAutocomplete';
import { ExpressionActionType, useExpressionSimpleEditorContext } from './ExpressionSimpleEditorContext';
import type { ExpressionProps } from './ExpressionSimpleEditorShared';
import type { Argument, InputExpressionNode, LiteralNode } from './expressionTypes';
import { ExpressionType, isCallOrBinaryExpression } from './expressionTypes';
import * as styles from './RulesBuilder.css';

interface InputExpressionProps extends ExpressionProps {
    readonly argumentType: Argument;
    readonly placeholder?: string;
}

export const InputExpression: React.FC<InputExpressionProps> = ({ id, argumentType, placeholder }) => {
    const { state, dispatch, productSchema, functions } = useExpressionSimpleEditorContext();

    const node = useMemo(() => state.expressionTree[id] as InputExpressionNode | LiteralNode, [state, id]);
    const parent = useMemo(() => state.expressionTree[node.parent!], [state, node.parent]);

    const isFirstChild = useMemo(() => {
        return isCallOrBinaryExpression(parent) && parent.children[0] === id;
    }, [id, parent]);

    const currValue = useMemo((): SchemaAutocompleteOption => {
        return node.type === ExpressionType.Literal
            ? { value: node.value, type: 'value' }
            : { value: node.callee, argument: node.value, type: 'function' };
    }, [node]);
    const updateValue = useCallback(
        (value: SchemaAutocompleteOption | null): void => {
            if (value === null) {
                dispatch({
                    type: ExpressionActionType.SetValueType,
                    payload: { id, newValue: { value: '', type: ExpressionType.Literal, raw: '' } },
                });
            } else if (value.type === 'function') {
                dispatch({
                    type: ExpressionActionType.ConvertToInput,
                    payload: {
                        id,
                        function: value.value,
                        value: value.argument,
                    },
                });
            } else {
                dispatch({
                    type: ExpressionActionType.SetValueType,
                    payload: { id, newValue: { value: value.value, type: ExpressionType.Literal, raw: value.value } },
                });
            }
        },
        [dispatch, id]
    );

    return (
        <SchemaAutocomplete
            additionalFunctions={functions}
            className={styles.schemaAutocomplete}
            expressionType={isFirstChild ? undefined : argumentType}
            key={id}
            onChange={updateValue}
            placeholder={placeholder}
            productSchema={productSchema}
            value={currValue}
            width={isFirstChild ? 200 : 150}
        />
    );
};
