import { useOptimisticMutation } from '@lemonade-hq/blender-ui';
import type { ProductTypes } from '@lemonade-hq/bluiza';
import type { UseBaseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { produce } from 'immer';
import { InteractionQueryKeys } from './InteractionsQueries';
import type { AssignOperatorArgs, SquadsConfiguration } from 'apis/ClaimAdjustersAPI';
import {
    assignOperator,
    getActiveClaimAdjustersWithStatistics,
    getAdjustersByOperatorIds,
    getAdjustersByProduct,
    getSquadsConfiguration,
} from 'apis/ClaimAdjustersAPI';
import type { SquadListItem, SquadListParams } from 'apis/ClaimsAPITyped';
import { getSquadList } from 'apis/ClaimsAPITyped';
import type { CommunicationTimelineResponse } from 'apis/Communications/CommunicationsAPI';
import type { ZendeskComment } from 'components/CommsTimeline/Comments/Comment/Interaction/types';
import type { AdjusterData, AdjustersResponse } from 'models/Adjuster';
import { usePessimisticMutationLegacy } from 'queries/MutationHooks';

export enum AdjusterQueryKeys {
    GetSquadList = 'GET_SQUAD_LIST',
    GetAdjustersList = 'GET_ADJUSTERS_LIST',
    GetAdjustersByProductType = 'GET_ADJUSTERS_BY_PRODUCT_TYPE',
    GetCurrentAdjuster = 'GET_CURRENT_ADJUSTER',
    GetSquadsConfiguration = 'GET_SQUADS_CONFIGURATION',
}

export function useSquadList({ productType, claimId }: SquadListParams): UseQueryResult<SquadListItem[]> {
    return useQuery({
        queryKey: [AdjusterQueryKeys.GetSquadList, { productType, claimId }],

        queryFn: async () => await getSquadList({ productType, claimId }),
    });
}

export function useGetAdjustersByProduct(productType: ProductTypes): UseQueryResult<AdjustersResponse> {
    return useQuery({
        queryKey: [AdjusterQueryKeys.GetAdjustersByProductType, productType],

        queryFn: async () => await getAdjustersByProduct(productType),
    });
}

export function useGetCurrentAdjuster(operatorPublicId: string): UseQueryResult<AdjustersResponse> {
    return useQuery({
        queryKey: [AdjusterQueryKeys.GetCurrentAdjuster, operatorPublicId],
        queryFn: async () => await getAdjustersByOperatorIds([operatorPublicId]),
        staleTime: Infinity,
        enabled: Boolean(operatorPublicId),
    });
}

export function useAdjustersList({ region }: { readonly region: string }): UseQueryResult<AdjusterData[]> {
    return useQuery({
        queryKey: [AdjusterQueryKeys.GetAdjustersList, region],
        queryFn: async () => await getActiveClaimAdjustersWithStatistics(region),
        staleTime: Infinity,
    });
}

export function useAdjustersMutation<TVariables>(region: string, method: (vars: TVariables) => Promise<null | void>) {
    return usePessimisticMutationLegacy(method, [[AdjusterQueryKeys.GetAdjustersList, region]]);
}

export function useSquadsConfiguration(): UseQueryResult<SquadsConfiguration> {
    return useQuery({
        queryKey: [AdjusterQueryKeys.GetSquadsConfiguration],
        queryFn: async () => await getSquadsConfiguration(),
    });
}

function updateCachedOperator({
    data,
    variables: { updatedOperator, interactionId },
}: {
    readonly data: CommunicationTimelineResponse;
    readonly variables: AssignOperatorArgs;
}) {
    return produce(data, draftData => {
        const interactionIdx = draftData.timeline.findIndex(
            interaction =>
                'ticket' in interaction &&
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                interaction.ticket != null &&
                interaction.ticket.publicId === interactionId
        );

        if (interactionIdx !== -1) {
            const interaction = draftData.timeline[interactionIdx] as ZendeskComment;

            interaction.ticket.threadOwner = updatedOperator;
            draftData.timeline.splice(interactionIdx, 1, interaction);
        }
    });
}

export function useAssignOperatorMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, AssignOperatorArgs, CommunicationTimelineResponse> {
    const queryKey = [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId];

    return useOptimisticMutation({
        mutationFn: assignOperator,
        mutateKey: queryKey,
        mutate: updateCachedOperator,
        invalidateKeys: [queryKey],
    });
}
