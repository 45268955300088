import type { MentionElement, MentionsChangeProps } from '@lemonade-hq/cdk';
import { useStorageState } from '@lemonade-hq/cdk';
import * as sentry from '@sentry/browser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { BaseSelection, Editor } from 'slate';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { useGetMentionUsers } from '../queries/MentionsQueries';
import type { MentionUser } from '../types';

export interface UseMentionsOptions {
  readonly enabled?: boolean;
  readonly selection?: BaseSelection;
}
export interface UseMentionsReturnValue {
  readonly selectedMentionIndex: number;
  readonly mentionProps: MentionsChangeProps;
  readonly setMentionProps: (props: MentionsChangeProps) => void;
  readonly handleMentionsKeyboardNavigation: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  readonly handleMentionsKeyboardUp: () => void;
  readonly users: MentionUser[];
  readonly isLoading: boolean;
  readonly insertMention: (user: MentionUser) => void;
}

export const useMentions = (editor: Editor, options?: UseMentionsOptions): UseMentionsReturnValue => {
  const [mentionProps, setMentionProps] = useState<MentionsChangeProps>({
    baseStyles: {
      top: '0',
      left: '0',
    },
    searchQuery: '',
    isSearching: false,
  });
  const { data: users = [], isLoading } = useGetMentionUsers(mentionProps, options?.enabled);
  const { storedValue = [] } = useStorageState<MentionUser[]>('recentMentions', []);

  const recentMentions = useMemo(
    () => (mentionProps.searchQuery.length === 0 ? storedValue : []),
    [storedValue, mentionProps.searchQuery],
  );

  const uniqueCombinedUsers = useMemo(
    () =>
      [...recentMentions, ...users].filter(
        (user, index, self) => index === self.findIndex(u => u.publicId === user.publicId),
      ),
    [users, recentMentions],
  );

  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [users]);

  const insertMention = useCallback(
    (user: MentionUser): void => {
      setSelectedIndex(-1);
      if (mentionProps.searchQuery) {
        const words = mentionProps.searchQuery.split(' ').reverse();

        words.forEach((word, index) => {
          const isLastIndex = index === words.length - 1;

          if (word.length > 1) {
            editor.deleteBackward('word');
            if (isLastIndex) {
              editor.deleteBackward('character');
            }
          } else {
            editor.deleteBackward('character');
            editor.deleteBackward('character');
          }
        });
      } else {
        editor.deleteBackward('character');
      }

      const mention: MentionElement = {
        type: 'mention',
        name: `${user.firstName} ${user.lastName}`,
        publicId: user.publicId,
        imageUrl: user.imageUrl,
        role: user.attributes != null ? `${user.attributes.department[0]} | ${user.attributes.jobTitle[0]}` : '',
        children: [{ text: `@${user.firstName} ${user.lastName}` }],
      };

      Transforms.insertNodes(editor, mention);
      Transforms.insertFragment(editor, [{ text: ' ' }]);
      Transforms.move(editor);

      if (!ReactEditor.isFocused(editor)) {
        try {
          ReactEditor.focus(editor);
        } catch (error: unknown) {
          sentry.captureException(error);
        }
      }

      setMentionProps(prev => ({
        ...prev,
        isSearching: false,
        searchQuery: '',
      }));
    },
    [editor, setMentionProps, mentionProps],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (mentionProps.isSearching && users.length > 0) {
        setMentionProps(prev => ({
          ...prev,
          baseStyles: {
            ...prev.baseStyles,
            pointerEvents: 'none',
          },
        }));
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex >= uniqueCombinedUsers.length - 1 ? 0 : selectedIndex + 1));
            break;
          case 'ArrowUp':
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex <= 0 ? uniqueCombinedUsers.length - 1 : selectedIndex - 1));
            break;
          case 'Enter':
            // eslint-disable-next-line no-lone-blocks
            {
              event.preventDefault();
              const user = uniqueCombinedUsers[selectedIndex];
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (user != null) {
                insertMention(user);
              }
            }

            break;

          default:
            break;
        }
      }
    },
    [selectedIndex, mentionProps, insertMention, users, uniqueCombinedUsers],
  );

  const handleMentionsKeyboardUp = useCallback(() => {
    setMentionProps(prev => ({
      ...prev,
      baseStyles: {
        ...prev.baseStyles,
        pointerEvents: 'auto',
      },
    }));
  }, []);

  return {
    selectedMentionIndex: selectedIndex,
    mentionProps,
    setMentionProps,
    handleMentionsKeyboardNavigation: onKeyDown,
    handleMentionsKeyboardUp,
    insertMention,
    isLoading,
    users: uniqueCombinedUsers,
  };
};
