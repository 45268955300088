import { Spinner, throwAuthorizationError } from '@lemonade-hq/bluis';
import React, { FC, useCallback, useReducer, useRef } from 'react';
import styled, { css } from 'styled-components';
import { debounce } from 'throttle-debounce';
import { produce } from 'immer';
import { Flex } from '@lemonade-hq/cdk';
import { useAnalytics } from '@lemonade-hq/boutique';
import { ThemedColors } from 'bluis/Colors';
import Input from 'bluis/Input';
import CloseRoundButton from 'components/Bluis/CloseRoundButton';
import { generalSearchEventName } from 'components/Search/searchTrackingHelpers';
import { useClientSettings } from 'queries/ClientSettings';
import SearchResultsPreview from './SearchPreview';
import { SearchResults } from './helpers';
import { unifiedTextSearch } from '../../../APIs/SearchAPITyped';
import * as SearchAPI from '../../../APIs/SearchAPI';

const SearchBarContainer = styled(Flex)`
    flex-flow: column;
    width: auto;
    position: relative;
    margin-right: 18px;
`;

const SearchBarInner = styled.form<{ error: boolean }>`
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    position: relative;

    ${({ error }) =>
        error &&
        css`
            box-shadow: 0 0 5px ${ThemedColors.mainButtonBackground};

            &:before {
                content: '';
                position: absolute;
                right: 9px;
                bottom: 8px;
                background-image: url('${__assets_url}blender_assets/backoffice/warning-pink.png');
                background-size: contain;
                background-repeat: no-repeat;
                height: 18px;
                width: 19px;
            }
        `}

    input {
        height: 35px;
        width: 396px;
        background: ${ThemedColors.componentBackground};
        color: ${ThemedColors.primaryText};
        border-radius: 5px;
        padding: 9px 9px 9px 30px !important;
        display: flex;
        flex-flow: row;
        align-items: center;
        position: relative;

        &:hover {
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
        }
    }
`;

const SearchIcon = styled.button`
    position: absolute;
    left: 6px;
    height: 14px;
    width: 14px;
    z-index: 1;
    background: transparent url('${__assets_url}blender_assets/backoffice/search-icon.png') no-repeat;
    background-size: contain;
    margin: 0 10px 0 4px;
    padding: 0;
    border: none;

    &:focus {
        outline: none;
    }
`;

const RemoveButtonWrapper = styled.div`
    position: absolute;
    right: 12px;
`;

const SpinnerSyled = styled(Spinner)`
    position: absolute;
    right: 12px;
    bottom: 10px;
`;

const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    left: -230px;
    top: 4px;
    background: ${ThemedColors.primaryText};
    height: 26px;
    min-width: 200px;
    padding: 8px;
    color: ${ThemedColors.componentBackground};
    border-radius: 5px;

    &:before {
        content: '';
        position: absolute;
        right: -6px;
        bottom: 5px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid ${ThemedColors.primaryText};
        border-radius: 5px;
    }
`;

const CloseRoundButtonStyled = styled(CloseRoundButton)`
    padding: 2px;
`;

interface SearchState {
    value: string;
    notifyMessage: string;
    isSubmitting: boolean;
    autocomplete: boolean;
    searchResults: null | SearchResults;
    inputFocused: boolean;
    // selectedRegion is sent to the search api as param, but is not used in the backend
    selectedRegion: string;
}

const defaultState: SearchState = {
    value: '',
    notifyMessage: '',
    isSubmitting: false,
    autocomplete: false,
    searchResults: null,
    inputFocused: false,
    selectedRegion: 'ALL',
};

enum SearchInputActionType {
    SetValueShort,
    SetValue,
    SetSubmit,
    SetNotify,
    SetFocus,
    SetResults,
    StopSubmit,
    HidePreview,
    Reset,
}

interface SetValueShortAction {
    type: SearchInputActionType.SetValueShort;
    payload: { value: string };
}

interface SetValueAction {
    type: SearchInputActionType.SetValue;
    payload: { value: string };
}

interface SetSubmitAction {
    type: SearchInputActionType.SetSubmit;
}

interface SetNotifyAction {
    type: SearchInputActionType.SetNotify;
    payload: { value: string };
}

interface SetFocusAction {
    type: SearchInputActionType.SetFocus;
}

interface SetResultsAction {
    type: SearchInputActionType.SetResults;
    payload: { searchResults: SearchResults };
}

interface StopSubmitAction {
    type: SearchInputActionType.StopSubmit;
}

interface HidePreviewAction {
    type: SearchInputActionType.HidePreview;
}

interface ResetAction {
    type: SearchInputActionType.Reset;
}

type SearchInputAction =
    | SetValueShortAction
    | SetValueAction
    | SetSubmitAction
    | SetNotifyAction
    | SetFocusAction
    | SetResultsAction
    | StopSubmitAction
    | HidePreviewAction
    | ResetAction;

function searchInputReducer(state: SearchState, action: SearchInputAction): SearchState {
    switch (action.type) {
        case SearchInputActionType.SetValueShort: {
            const { value } = action.payload;

            return produce(state, draftState => {
                draftState.value = value;
                draftState.notifyMessage = '';
                draftState.isSubmitting = false;
                draftState.autocomplete = false;
            });
        }
        case SearchInputActionType.SetValue: {
            const { value } = action.payload;

            return produce(state, draftState => {
                draftState.value = value;
                draftState.notifyMessage = '';
                draftState.isSubmitting = true;
            });
        }
        case SearchInputActionType.SetSubmit: {
            return produce(state, draftState => {
                draftState.autocomplete = false;
                draftState.isSubmitting = false;
            });
        }
        case SearchInputActionType.SetNotify: {
            const { value } = action.payload;

            return produce(state, draftState => {
                draftState.notifyMessage = value;
            });
        }
        case SearchInputActionType.SetFocus: {
            return produce(state, draftState => {
                draftState.autocomplete = true;
                draftState.inputFocused = true;
            });
        }
        case SearchInputActionType.SetResults: {
            const { searchResults } = action.payload;

            return produce(state, draftState => {
                draftState.searchResults = searchResults;
                draftState.autocomplete = true;
                draftState.isSubmitting = false;
            });
        }
        case SearchInputActionType.StopSubmit: {
            return produce(state, draftState => {
                draftState.autocomplete = false;
                draftState.isSubmitting = false;
            });
        }
        case SearchInputActionType.HidePreview: {
            return produce(state, draftState => {
                draftState.autocomplete = false;
            });
        }
        case SearchInputActionType.Reset: {
            return produce(state, draftState => {
                draftState.notifyMessage = '';
                draftState.value = '';
                draftState.isSubmitting = false;
                draftState.autocomplete = false;
                draftState.searchResults = null;
                draftState.inputFocused = false;
            });
        }
        default: {
            return state;
        }
    }
}

const SearchInput: FC<React.PropsWithChildren<unknown>> = () => {
    const searchBarRef = useRef<null | HTMLInputElement>(null);
    const currentTermRef = useRef<string>('');
    const [
        { value, notifyMessage, isSubmitting, autocomplete, searchResults, inputFocused, selectedRegion },
        dispatch,
    ] = useReducer(searchInputReducer, defaultState);
    const { trackEvent } = useAnalytics();
    const { data: clientSettings } = useClientSettings();

    function onEnterSearch(e: React.KeyboardEvent): void {
        const { which, keyCode, shiftKey } = e;
        const key = which || keyCode;

        if (searchBarRef.current && inputFocused && key === 13) {
            e.preventDefault();

            if (searchBarRef.current.value.length < 2) {
                dispatch({
                    type: SearchInputActionType.SetNotify,
                    payload: { value: 'Please enter at least 2 characters' },
                });
            } else if (shiftKey && clientSettings?.addressSearchEnabled) {
                window.location.href = `/backoffice/addresses?term=${encodeURIComponent(value)}`;
                trackEvent(`${generalSearchEventName}.search_address.clicked`);
            } else {
                dispatch({ type: SearchInputActionType.SetSubmit });

                // remove - search api does not filter results by selectedRegion
                const country = selectedRegion === 'ALL' ? '' : `&country=${selectedRegion}`;

                window.location.href = `/backoffice/search?term=${encodeURIComponent(value)}${country}`;
                trackEvent(`${generalSearchEventName}.show_all_results.clicked`);
            }
        }
    }

    function onInputFocused() {
        if (!inputFocused) {
            dispatch({ type: SearchInputActionType.SetFocus });
        }
    }

    function searchValue(searchTerm: string) {
        // eslint-disable-next-line no-restricted-globals
        if (location.hash.substring(1) === 'new_search') {
            newSearchValue(searchTerm);
        } else {
            oldSearchValue(searchTerm);
        }

        trackEvent(`${generalSearchEventName}.searched`, { term: searchTerm });
    }

    function oldSearchValue(searchTerm: string) {
        const searchOptions = {
            unified: true,
            limit: 1,
            preview: true,
            country: selectedRegion !== 'ALL' && selectedRegion,
        };

        SearchAPI.search(currentTermRef.current, searchOptions)
            .then((results: SearchResults) => {
                if (searchTerm === currentTermRef.current) {
                    dispatch({
                        type: SearchInputActionType.SetResults,
                        payload: { searchResults: results },
                    });
                }
            })
            .catch((e: unknown) => {
                dispatch({ type: SearchInputActionType.StopSubmit });
                throwAuthorizationError(e);
            });
    }

    function newSearchValue(searchTerm: string) {
        const options = {
            limit: 1,
        };

        unifiedTextSearch(currentTermRef.current, options)
            .then((results: SearchResults) => {
                if (searchTerm === currentTermRef.current) {
                    dispatch({
                        type: SearchInputActionType.SetResults,
                        payload: { searchResults: results },
                    });
                }
            })
            .catch((e: unknown) => {
                dispatch({ type: SearchInputActionType.StopSubmit });
                throwAuthorizationError(e);
            });
    }

    const searchValueCallback = useCallback(searchValue, [selectedRegion]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchValueDebounce = useCallback(debounce(500, false, searchValueCallback), []);

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;

        if (value.length < 2) {
            dispatch({ type: SearchInputActionType.SetValueShort, payload: { value } });
        } else {
            dispatch({ type: SearchInputActionType.SetValue, payload: { value } });

            currentTermRef.current = value;
            searchValueDebounce(value);
        }
    }

    function changeSearchBarStatus(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        if (searchBarRef.current) {
            searchBarRef.current.focus();
        }
    }

    function closeAutocomplete() {
        dispatch({ type: SearchInputActionType.HidePreview });
    }

    function onResetInput(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        dispatch({ type: SearchInputActionType.Reset });
    }

    const showAutocomplete = autocomplete && searchResults != null;

    return (
        <SearchBarContainer>
            <SearchBarInner error={!!notifyMessage} onKeyDown={onEnterSearch}>
                <SearchIcon onClick={changeSearchBarStatus} />
                <Input
                    ref={searchBarRef}
                    autoFocus={false}
                    min="2"
                    onChange={onChange}
                    placeholder="Search in Blender"
                    onFocus={onInputFocused}
                    value={value}
                />
                {!isSubmitting && value.length > 0 && (
                    <RemoveButtonWrapper>
                        <CloseRoundButtonStyled onClick={onResetInput} />
                    </RemoveButtonWrapper>
                )}
                {!!notifyMessage && <ErrorContainer>{notifyMessage}</ErrorContainer>}
                {isSubmitting && <SpinnerSyled size={15} />}
            </SearchBarInner>
            {showAutocomplete && (
                <SearchResultsPreview
                    options={searchResults}
                    onClose={closeAutocomplete}
                    addressSearchEnabled={clientSettings?.addressSearchEnabled}
                    onSearchResultClick={() => dispatch({ type: SearchInputActionType.Reset })}
                    value={value}
                    term={value}
                />
            )}
        </SearchBarContainer>
    );
};

export default SearchInput;
