import * as React from 'react';
import { Avatar, OrderByDirection } from '@lemonade-hq/bluis';
import type { SelectOptionBase } from '@lemonade-hq/cdk';
import { Flex } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import type { ReviewerResponse, HomeReviewsSearchFilters, HomeReviewsSearchParams, SearchParam } from './types';

export const ALL_OPTION = { label: 'All', value: 'All', id: 'All' };

export const PAGE_SIZE = 20;

const StyledOption = styled(Flex)`
    height: 40px;
    align-items: center;
    gap: 10px;
`;

export function normalizedParams(searchParams: HomeReviewsSearchFilters): HomeReviewsSearchParams {
    const params = { ...searchParams };

    params.operator_id = params.operator_id === 'Unassigned' ? null : params.operator_id;
    Object.keys(params).forEach(key => params[key] == null && key !== 'operator_id' && delete params[key]);
    params.offset = ((params.page ?? 1) - 1) * PAGE_SIZE;
    params.sort = params.orderByDir ?? OrderByDirection.Asc;

    return params;
}

export function getReviewerOptions(reviewers: ReviewerResponse[]): SelectOptionBase[] {
    return [
        ALL_OPTION,
        ...reviewers
            .sort((reviewerA, reviewerB) => {
                if (reviewerA.name === 'Unassigned') return -1;
                if (reviewerB.name === 'Unassigned') return 1;
                return reviewerA.name.localeCompare(reviewerB.name);
            })
            .map(reviewer => ({
                label: (
                    <StyledOption>
                        <Avatar alt={reviewer.name} size="small" src={reviewer.image_url} />
                        <span>{reviewer.name}</span>
                    </StyledOption>
                ),
                value: reviewer.name === 'Unassigned' ? 'Unassigned' : reviewer.id,
                id: reviewer.name === 'Unassigned' ? 'Unassigned' : reviewer.id,
            })),
    ];
}

export function getGenericSelectOptions(options: SearchParam[], allOptionLabel?: string) {
    return [
        ...(allOptionLabel != null && allOptionLabel !== '' ? [{ ...ALL_OPTION, label: allOptionLabel }] : []),
        ...options
            .map(({ value, title }) => ({
                label: title,
                id: value,
                value,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
    ];
}
