import type { RadioGroupProps as RadioGroupPropsRadix } from '@radix-ui/react-radio-group';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as RadioButton from '@radix-ui/react-radio-group';
import { clsx } from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import type { RequiredDeep } from 'type-fest';
import { Flex } from '../../base/Flex/Flex';
import { spacing } from '../../theme/spacing.css';
import * as styles from './RadioGroup.css';

type StyleParams = RequiredDeep<Parameters<typeof styles.radioGroupWrapper>[0]>;

export interface RadioProps extends React.HTMLAttributes<HTMLButtonElement> {
  readonly value: string;
  readonly defaultValue?: string;
  readonly size?: StyleParams['size'];
  readonly variant?: StyleParams['variant'];
  readonly disabled?: boolean;
}

export const Radio: React.FC<PropsWithChildren<RadioProps>> = ({ value, defaultValue, className, ...props }) => {
  return (
    <Flex alignItems="center">
      <RadioButton.Item className={clsx(styles.radioGroupItem, className)} value={value} {...props}>
        <RadioButton.Indicator className={clsx(styles.radioGroupIndicator)} data-testid="indicator" />
      </RadioButton.Item>
    </Flex>
  );
};

export interface RadioGroupProps extends RadioGroupPropsRadix {
  readonly flexDirection?: 'column' | 'row';
  readonly size?: StyleParams['size'];
  readonly variant?: StyleParams['variant'];
}

export const RadioGroup: FC<PropsWithChildren<RadioGroupProps>> = ({
  children,
  flexDirection = 'column',
  size = 'md',
  variant = 'brand',
  ...props
}) => {
  return (
    <RadioButton.Root {...props} aria-label="radio group">
      <Flex className={styles.radioGroupWrapper({ size, variant })} flexDirection={flexDirection} gap={spacing.s16}>
        {children}
      </Flex>
    </RadioButton.Root>
  );
};
