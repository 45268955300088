import { clsx } from 'clsx';
import type { FC, KeyboardEventHandler, PropsWithChildren } from 'react';
import { FormLayout } from '../Form/FormLayout';
import * as styles from './DynamicList.css';

export const DynamicListContainer: FC<
  PropsWithChildren<{
    readonly as: 'ol' | 'ul';
    readonly onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
    readonly className?: string;
  }>
> = ({ children, onKeyDown, className, as }) => {
  return (
    <FormLayout as={as} className={clsx(styles.list, className)} onKeyDown={onKeyDown}>
      {children}
    </FormLayout>
  );
};
