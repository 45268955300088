import type { UseMutationResult, UseSuspenseQueryResult } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import type { GetEditionsForChangesLogResponse } from './ChangesLogQueries';
import { getEditionsForChangesLog } from './ChangesLogQueries';
import { ProductsQueryKey } from './ProductQueries';
import { useSuspenseQueryWithErrorHandling } from 'components/LoCo/common/hooks/useSuspenseQueryWithErrorHandling';
import type { ChangesLogResponse } from 'models/LoCo/Insurance/ChangesLog';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import type { EditionSetCompatibilityResponse } from 'models/LoCo/Insurance/Product';
import { usePessimisticMutation } from 'queries/MutationHooks';

const EDITION_SETS_BASE_PATH = '/api/v1/edition-sets';

enum EditionSetQueryKey {
    GetEditionSetViolations = 'GET_EDITION_SET_VIOLATIONS',
    ClearEnvAvailability = 'CLEAR_ENV_AVAILABILITY',
    ChangesLog = 'EDITION_SETS_CHANGES_LOG',
}

async function getEditionSetViolations(code: string): Promise<EditionSetCompatibilityResponse> {
    return await insuranceBlender
        .get<{ data: EditionSetCompatibilityResponse }>(`${EDITION_SETS_BASE_PATH}/${code}/violations`)
        .then(response => response.data.data);
}

export function useSuspenseGetEditionSetViolations(
    code: string
): UseSuspenseQueryResult<EditionSetCompatibilityResponse> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [EditionSetQueryKey.GetEditionSetViolations, code],
        queryFn: async () => {
            return await getEditionSetViolations(code);
        },
    });
}

type ClearEnvAvailabilityParams = {
    readonly editionSetCode: string;
    readonly envId: string;
};

async function clearEnvAvailability(request: ClearEnvAvailabilityParams): Promise<void> {
    await insuranceBlender.post(`${EDITION_SETS_BASE_PATH}/${request.editionSetCode}/clear-env-availability`, {
        envId: request.envId,
    });
}

export function useClearEnvAvailability(
    productCode: string
): UseMutationResult<void, unknown, ClearEnvAvailabilityParams, null> {
    return usePessimisticMutation({
        mutationFn: clearEnvAvailability,
        invalidateKeys: [[ProductsQueryKey.GetProductPublishedEditionSets, productCode]],
        mutationKey: [EditionSetQueryKey.ClearEnvAvailability],
    });
}

export async function getEditionSetChangesLog(code: string): Promise<ChangesLogResponse> {
    return await insuranceBlender
        .get<{ data: ChangesLogResponse }>(`${EDITION_SETS_BASE_PATH}/${code}/changes-log`)
        .then(response => response.data.data);
}

export async function getEditionSet(code: string): Promise<EditionSet> {
    return await insuranceBlender
        .get<{ data: EditionSet }>(`${EDITION_SETS_BASE_PATH}/${code}`)
        .then(response => response.data.data);
}

interface UseGetEditionSetChangesLogDataSuspenseResponse extends GetEditionsForChangesLogResponse {
    readonly changesLog: ChangesLogResponse;
    readonly baseEditionSet: EditionSet | null;
}

export function useGetEditionSetChangesLogDataSuspense(
    editionSet: EditionSet
): UseSuspenseQueryResult<UseGetEditionSetChangesLogDataSuspenseResponse> {
    return useSuspenseQuery({
        queryKey: [EditionSetQueryKey, editionSet.code],
        queryFn: async () => {
            const baseEditionSet =
                editionSet.baseEditionSetCode !== null ? await getEditionSet(editionSet.baseEditionSetCode) : null;

            const [changesLog, editions] = await Promise.all([
                getEditionSetChangesLog(editionSet.code),
                getEditionsForChangesLog({
                    coveragesEditionCode: editionSet.coveragesEdition.code,
                    digitalAgentEditionCode: editionSet.digitalAgentEdition.code,
                    underwritingFiltersEditionCode: editionSet.underwritingFiltersEdition.code,
                    baseCoveragesEditionCode: baseEditionSet?.coveragesEdition.code,
                    baseDigitalAgentEditionCode: baseEditionSet?.digitalAgentEdition.code,
                    baseUnderwritingFiltersEditionCode: baseEditionSet?.underwritingFiltersEdition.code,
                }),
            ]);

            return {
                changesLog,
                baseEditionSet,
                ...editions,
            } satisfies UseGetEditionSetChangesLogDataSuspenseResponse;
        },
    });
}
