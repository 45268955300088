import { Flex, FormProvider, generateTypedFormComponents, Grid, spacing, Text, useForm } from '@lemonade-hq/blender-ui';
import type { DialogNoticeType, DialogProps } from '@lemonade-hq/bluis';
import { AlertMode, Dialog } from '@lemonade-hq/bluis';
import { ma } from '@lemonade-hq/maschema-schema';
import { va } from '@lemonade-hq/maschema-validations-ui';
import { ErrorMessage } from 'libs/blender-ui/src/components/Form/adapters/ErrorMessage/ErrorMessage';
import { useCallback, useMemo } from 'react';
import type { RenameVariantsValues } from './utils';
import type { CoverageRuleGroupABTest, Variant } from 'models/LoCo/Insurance/DigitalAgentEdition';
import type { ABTestRuleGroup, VariantRuleSet } from 'models/LoCo/Insurance/UnderwritingFiltersEditionV2';

const renameVariantsSchema = ma.record({
    experimentName: ma.string([va.minChars({ chars: 3 }), va.regExp({ pattern: '^\\S*$' })]),
    variants: ma.array(ma.record({ oldName: ma.string(), newName: ma.string([va.regExp({ pattern: '^\\S*$' })]) }), [
        va.elementsUniqueness({ uniqueBy: 'newName' }),
    ]),
});

const { Input } = generateTypedFormComponents<typeof renameVariantsSchema>();

const RenameVariantsForm: React.FC<{
    readonly sortedVariants?: Variant[] | VariantRuleSet[];
    readonly isLoading: boolean;
    readonly onSubmit: (values: RenameVariantsValues) => Promise<void>;
    readonly onClose: () => void;
}> = ({ sortedVariants, isLoading, onSubmit, onClose }) => {
    const {
        values,
        validationResults: { valid },
    } = useForm<typeof renameVariantsSchema>();

    const submit = useCallback(async () => {
        await onSubmit(values as RenameVariantsValues);
        onClose();
    }, [values, onSubmit, onClose]);

    const alerts = useMemo(() => {
        const notices: DialogNoticeType = [];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        values.variants?.forEach(({ oldName, newName }, index) => {
            if (oldName !== newName) {
                notices.push({
                    title: `Rules previously listed under '${oldName}' will now be listed under '${newName}'${
                        index === 0 ? ' (which will be the new Control)' : ''
                    }`,
                    mode: AlertMode.Info,
                });
            }
        });

        return notices;
    }, [values]);

    const dialogProps: DialogProps = {
        title: 'Rename Variants',
        notice: alerts,
        loading: isLoading,
        actions: [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Save',
                type: 'submit',
                disabled: !valid,
                onClick: submit,
            },
        ],
    };

    return (
        <Dialog {...dialogProps}>
            <Text>Make sure to use the exact details from Unleash:</Text>
            <Grid
                alignItems="center"
                gridTemplateColumns="150px 250px"
                justifyContent="center"
                mb={spacing.s32}
                mt={spacing.s24}
            >
                <Text>Experiment Name</Text>
                <Input schemaKey="experimentName" type="text" />
            </Grid>
            <Text>Change the variant name (from Unleash) that triggers configured rules:</Text>
            <Flex alignItems="center" flexDirection="column" gap={spacing.s10} mt={spacing.s24} width="100%">
                {sortedVariants?.map((variant, index) => (
                    <Grid
                        alignItems="center"
                        gridTemplateColumns="150px 250px"
                        justifyContent="center"
                        key={variant.variantName}
                        width="300px"
                    >
                        <Flex flexDirection="column">
                            {variant.variantType === 'control' && <Text>Control: </Text>}
                            <Text>
                                {variant.variantName} ({variant.rules.length} rules)
                            </Text>
                        </Flex>
                        <Input schemaKey={`variants[${index}].newName`} />
                    </Grid>
                ))}
                <Flex width="300px">
                    <ErrorMessage schemaKey="variants">Two test groups cannot have the same ID</ErrorMessage>
                </Flex>
            </Flex>
        </Dialog>
    );
};

interface RenameVariantsDialogProps {
    readonly item?: ABTestRuleGroup | CoverageRuleGroupABTest;
    readonly isLoading: boolean;
    readonly onSubmit: (values: RenameVariantsValues) => Promise<void>;
    readonly onClose: () => void;
}

export const RenameVariantsDialog: React.FC<RenameVariantsDialogProps> = ({ item, isLoading, onSubmit, onClose }) => {
    const sortedVariants = useMemo(
        () => item?.variants.sort((a, _) => (a.variantType === 'control' ? -1 : 1)),
        [item?.variants]
    );

    const variantsInitialValues =
        sortedVariants?.map(variant => ({
            oldName: variant.variantName,
            newName: variant.variantName,
        })) ?? [];

    return (
        <FormProvider
            initialValues={{ experimentName: item?.experimentName, variants: variantsInitialValues }}
            schema={renameVariantsSchema}
        >
            <RenameVariantsForm
                isLoading={isLoading}
                onClose={onClose}
                onSubmit={onSubmit}
                sortedVariants={sortedVariants}
            />
        </FormProvider>
    );
};
