import { Flex } from '@lemonade-hq/blender-ui';
import type { DialogAction, DialogNoticeType } from '@lemonade-hq/bluis';
import { AlertMode, Dialog } from '@lemonade-hq/bluis';
import { Text } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useMemo } from 'react';
import { BulletListSection } from '../SharedStyles';
import { getDecisionTypeText } from '../underwritingUtils';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type { ReasonVersion } from 'models/LoCo/Insurance/UnderwritingRegistry';
import {
    usePublishReasonDraft,
    useSuspenseGetAffectedProductsForPublishReasonDraft,
    useSuspenseGetReasonViolations,
} from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

interface PublishReasonDraftDialogProps {
    readonly onClose: () => void;
    readonly decisionType: UnderwritingDecisionLifecycleContext;
    readonly reasonVersion: ReasonVersion;
}

export const PublishReasonDraftDialog: React.FC<PublishReasonDraftDialogProps> = ({
    onClose,
    decisionType,
    reasonVersion,
}) => {
    const {
        mutateAsync: publishReasonDraft,
        isPending: isPublishingReasonDraft,
        error: errorPublishReasonDraft,
    } = usePublishReasonDraft();

    const { data: affectedProductsData } = useSuspenseGetAffectedProductsForPublishReasonDraft(reasonVersion.publicId);

    const { data: reasonViolations } = useSuspenseGetReasonViolations(reasonVersion.publicId);

    const onPublishDraft = async (): Promise<void> => {
        await publishReasonDraft(reasonVersion.publicId);

        onClose();
    };

    const actions: DialogAction[] = [
        {
            text: 'Cancel',
            type: 'close',
            onClick: onClose,
        },
        {
            text: 'Publish',
            type: 'submit',
            onClick: onPublishDraft,
            disabled: reasonViolations.hasViolations || isPublishingReasonDraft,
        },
    ];

    const dialogNotice = useMemo<DialogNoticeType | undefined>(() => {
        const apiErrorMessage = errorPublishReasonDraft?.toString();

        if (isDefined(apiErrorMessage)) {
            return [
                {
                    title: apiErrorMessage,
                    mode: AlertMode.Error,
                },
            ];
        }

        if (isDefined(reasonViolations)) {
            const {
                reasonViolations: { unpublishedExplanationNames, draftExplanationNames, defaultExplanationIsNotSet },
            } = reasonViolations;
            if (unpublishedExplanationNames.length > 0) {
                return [
                    {
                        title: (
                            <Flex flexDirection="column">
                                {`${getDecisionTypeText(decisionType)} reason cannot be published:`}
                                <BulletListSection
                                    bullets={unpublishedExplanationNames.map(
                                        explanationName => `'${explanationName}' explanation has no published versions`
                                    )}
                                />
                            </Flex>
                        ),
                        mode: AlertMode.Error,
                    },
                ];
            }

            if (draftExplanationNames.length > 0) {
                return [
                    {
                        title: (
                            <Flex alignItems="flex-start" flexDirection="column">
                                <div>
                                    <b>Please note: </b>
                                    {` This ${getDecisionTypeText(decisionType)} reason uses explanations with unpublished drafts, which must be published separately:`}
                                </div>
                                <BulletListSection
                                    bullets={draftExplanationNames.map(
                                        explanationName => `'${explanationName}' explanation`
                                    )}
                                />
                            </Flex>
                        ),
                        mode: AlertMode.Info,
                    },
                ];
            }

            if (defaultExplanationIsNotSet) {
                return [
                    {
                        title: (
                            <Flex flexDirection="column">
                                {`${getDecisionTypeText(decisionType)} reason cannot be published:`}
                                <BulletListSection bullets={['Default explanation is not set']} />
                            </Flex>
                        ),
                        mode: AlertMode.Error,
                    },
                ];
            }
        }
    }, [decisionType, errorPublishReasonDraft, reasonViolations]);

    const showAffectedProductsNote = isDefined(affectedProductsData) && affectedProductsData.length > 0;

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            loading={isPublishingReasonDraft}
            notice={dialogNotice}
            onClose={onClose}
            title={`Publish ${getDecisionTypeText(decisionType)} Reason`}
        >
            {
                <>
                    <Text variant="body-md">Are you sure you want to publish this draft?</Text>
                    {showAffectedProductsNote && (
                        <Text variant="body-md">
                            <b>Upon publishing, changes will be visible in production for the following products:</b>
                        </Text>
                    )}
                    <BulletListSection bullets={affectedProductsData} />
                </>
            }
        </Dialog>
    );
};
