import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getClientSettings, SideMenuItems, ClientSettingsQueryKey } from 'queries/ClientSettings';

export function useSideMenuSchema(): UseQueryResult<SideMenuItems> {
    return useQuery({
        queryKey: [ClientSettingsQueryKey.GetClientSettings],
        queryFn: getClientSettings,
        select: data => data.menu,
    });
}
