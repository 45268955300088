/* eslint-disable react/function-component-definition */
import { forwardRef } from 'react';
import type { ForwardedRef, PropsWithChildren } from 'react';
import type { ComponentType, IntrinsicType, LayoutProps } from '../Layout/Layout';
import { Layout } from '../Layout/Layout';

export type FlexProps<TElementType extends ComponentType = IntrinsicType> = Omit<
  PropsWithChildren<LayoutProps<TElementType>>,
  'display'
>;

export const Flex = forwardRef(
  <TElementType extends ComponentType = IntrinsicType, TRef = unknown>(
    { children, ...props }: FlexProps<TElementType>,
    ref: ForwardedRef<TRef>,
  ): JSX.Element => {
    return (
      <Layout {...props} display="flex" ref={ref}>
        {children}
      </Layout>
    );
  },
) as <TElementType extends ComponentType = IntrinsicType, TRef = unknown>(
  props: FlexProps<TElementType> & { readonly ref?: ForwardedRef<TRef> },
) => JSX.Element;
