import { capitalize } from '@lemonade-hq/ts-helpers';
import { RuleLifecycleContext, VersionType } from 'models/LoCo/Insurance/BaseEdition';
import { CoverageRuleType } from 'models/LoCo/Insurance/CoverageRule';
import type { SchemaType } from 'models/LoCo/Insurance/Schema';

export function formatRuleType(type: CoverageRuleType): string {
    switch (type) {
        case CoverageRuleType.InitialValue:
            return 'Initial Value';
        case CoverageRuleType.Restriction:
            return 'Restriction';
        default:
            return capitalize(type);
    }
}

export function formatLifeCycleContext(type: RuleLifecycleContext): string {
    switch (type) {
        case RuleLifecycleContext.PolicyEdits:
            return 'Policy Edits';
        case RuleLifecycleContext.Moving:
            return 'Moving';
        case RuleLifecycleContext.NewBusiness:
            return 'New Business';
        case RuleLifecycleContext.Renewal:
            return 'Renewal';
        default:
            return capitalize(type);
    }
}

export function getSchemaTypeName(schemaType: SchemaType): string {
    switch (schemaType) {
        case 'product':
            return 'Product';
        case 'platform':
            return 'Platform';
        default:
            throw new Error('Unknown schema type');
    }
}

export function getVersionTypeDisplayName(versionType: VersionType): string {
    switch (versionType) {
        case VersionType.Major:
            return 'New Business & Renewals';
        case VersionType.Minor:
            return 'Product Update';
        case VersionType.BugFix:
            return 'Bug Fix';
        default:
            return '';
    }
}

export function getMajorVersionSeriesName(versionMajor: number | string): string {
    return `${versionMajor}.X`;
}

export function displayBoolean(value: boolean): string {
    return value ? 'Yes' : 'No';
}
