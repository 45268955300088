import { ServiceName } from '../../../config';
import { getAxiosClient } from '../../../utils';
import type { MentionUser } from '../types';

const blenderGeneral = getAxiosClient(ServiceName.BlenderGeneral);
const BASE_MENTIONS_URL = '/api/v1/mentions';

export async function getMentionOptions(term?: string): Promise<MentionUser[]> {
  return await blenderGeneral
    .get<{ data: MentionUser[] }>(`${BASE_MENTIONS_URL}/options${term != null ? `?term=${term}` : ''}`)
    .then(res => res.data.data);
}
