// eslint-disable-next-line @typescript-eslint/naming-convention
import * as AccordionR from '@radix-ui/react-accordion';
import { clsx } from 'clsx';
import { forwardRef } from 'react';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';
import type { BadgeProps } from '../Badge/Badge';
import { Badge } from '../Badge/Badge';
import { Card } from '../Card/Card';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/types';
import { Tooltip } from '../Tooltip/Tooltip';
import * as styles from './Accordion.css';

export interface AccordionBadge extends BadgeProps {
  readonly tooltip?: string;
}

export interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
  readonly title: string;
  readonly subTitle?: string;
  readonly icon?: IconName;
  readonly disabled?: boolean;
  readonly isOpenByDefault?: boolean;
  readonly badges?: AccordionBadge[];
  readonly hasAsteriskMark?: boolean;
}

const AccordionHeader: FC<Pick<AccordionProps, 'hasAsteriskMark' | 'icon' | 'subTitle' | 'title'>> = ({
  title,
  subTitle,
  icon,
  hasAsteriskMark,
}) => {
  return (
    <Flex alignItems="center" gap={spacing.s08}>
      {icon != null && <Icon color="neutral7" name={icon} size="lg" />}
      <Flex alignItems="center" flexDirection="column">
        <Flex alignItems="center" gap={spacing.s04}>
          <Text fontWeight="semi-bold" type="text-md">
            {title}
          </Text>
          {hasAsteriskMark && (
            <Text color="brand" fontWeight="semi-bold" type="label-md">
              *
            </Text>
          )}
        </Flex>
        {subTitle != null && (
          <Text color="secondary" fontWeight="regular" type="text-sm">
            {subTitle}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

// TODO: Add a optional prop of a JSX.Element/Actions[] at the right, next to the chevron
export const Accordion = forwardRef<HTMLDivElement, PropsWithChildren<AccordionProps>>(
  (
    {
      children,
      isOpenByDefault = false,
      disabled,
      title,
      subTitle,
      icon,
      badges,
      hasAsteriskMark,
      className: externalClassName,
      ...restProps
    }: PropsWithChildren<AccordionProps>,
    ref,
  ) => {
    return (
      <Card
        borderRadius="sm"
        className={clsx(externalClassName, styles.accordionContainer)}
        padding="4px"
        ref={ref}
        variant="secondary"
        {...restProps}
      >
        <AccordionR.Root
          collapsible
          defaultValue={isOpenByDefault ? 'accordion' : undefined}
          disabled={disabled}
          type="single"
        >
          <AccordionR.Item value="accordion">
            <AccordionR.Header>
              <AccordionR.Trigger asChild>
                <button className={styles.headerButton} type="button">
                  <AccordionHeader hasAsteriskMark={hasAsteriskMark} icon={icon} subTitle={subTitle} title={title} />
                  <Flex alignItems="center" gap={spacing.s08}>
                    {badges?.map(badge => (
                      <Tooltip content={badge.tooltip} disabled={badge.tooltip == null} key={String(badge.label)}>
                        <Flex>
                          <Badge {...badge} />
                        </Flex>
                      </Tooltip>
                    ))}
                    <Icon className={styles.headerIcon} color="neutral7" name="chevron-down" />
                  </Flex>
                </button>
              </AccordionR.Trigger>
            </AccordionR.Header>
            <AccordionR.Content className={styles.accordionContent}>{children}</AccordionR.Content>
          </AccordionR.Item>
        </AccordionR.Root>
      </Card>
    );
  },
);
