import type { DialogAction } from '@lemonade-hq/bluis';
import type { Dispatch } from 'react';
import { useCallback, useMemo, useReducer, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useMiniFlowFormDialog<TStep extends string, TData>({
    steps,
    onClose,
    onSubmit,
    onNext,
    clear,
    isNextDisabled,
    initialData,
    initialStep: maybeInitialStep,
}: {
    readonly steps: TStep[];
    readonly onClose: () => void;
    readonly onSubmit: (data: TData) => Promise<void>;
    readonly onNext?: (
        step: TStep,
        dispatch: Dispatch<{
            readonly type: keyof TData;
            readonly value: TData[keyof TData];
        }>
    ) => void;
    readonly clear: (
        step: TStep,
        dispatch: Dispatch<{
            readonly type: keyof TData;
            readonly value: TData[keyof TData];
        }>
    ) => void;
    readonly isNextDisabled: (data: TData, currentStep: TStep) => boolean;
    readonly initialData?: TData;
    readonly initialStep?: TStep;
}) {
    const initialStep = maybeInitialStep ?? steps[0];

    const [state, dispatch] = useReducer(
        <TDataKey extends keyof TData>(
            s: TData,
            action: { readonly type: TDataKey; readonly value: TData[TDataKey] }
        ) => {
            return { ...s, [action.type]: action.value } as TData;
        },
        initialData ?? ({} as TData)
    );

    const [currentStep, setCurrentStep] = useState<TStep>(initialStep);

    const getStepIndex = useCallback(
        (step: TStep) => {
            return steps.indexOf(step);
        },
        [steps]
    );

    const back = useCallback(() => {
        if (initialStep === currentStep) {
            onClose();
            return;
        }

        const currentStepIndex = getStepIndex(currentStep);
        steps.filter(step => getStepIndex(step) >= currentStepIndex).forEach(step => clear(step, dispatch));
        setCurrentStep(steps[currentStepIndex - 1]);
    }, [clear, currentStep, getStepIndex, initialStep, onClose, steps]);

    const next = useCallback(() => {
        onNext?.(currentStep, dispatch);
        if (currentStep === steps[steps.length - 1]) {
            onSubmit(state)
                .then(onClose)
                .catch(error => {
                    throw new Error(`Error while submitting the dialog: ${error}`);
                });

            return;
        }

        setCurrentStep(steps[getStepIndex(currentStep) + 1]);
    }, [currentStep, getStepIndex, onClose, onNext, onSubmit, state, steps]);

    const actions = useMemo((): DialogAction[] => {
        return [
            {
                text: currentStep === steps[0] ? 'Cancel' : 'Back',
                type: 'other',
                onClick: back,
            },
            {
                text: currentStep === steps[steps.length - 1] ? 'Save' : 'Next',
                type: 'submit',
                onClick: next,
                disabled: isNextDisabled(state, currentStep),
            },
        ];
    }, [currentStep, steps, back, next, isNextDisabled, state]);

    return { actions, currentStep, dispatch, state };
}
