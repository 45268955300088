/* eslint-disable func-names */
import { useEffect, useState } from 'react';
import { useCurrentPrincipal } from '@lemonade-hq/boutique';
import { getConfigurations } from '../APIs/IntegrationsAPI';

// Intercom snippet
function initializeIntercom(appId?: string) {
    if (!appId) return;

    const w = window;
    const ic = w.Intercom;

    if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
    } else {
        const d = document;
        const i = function () {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        i.q = [];
        i.c = function (args: unknown) {
            i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
            const s = d.createElement('script');

            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${appId}`;
            // eslint-disable-next-line prefer-destructuring
            const x = d.getElementsByTagName('script')[0];

            x.parentNode?.insertBefore(s, x);
        };

        if (document.readyState === 'complete') {
            l();
        } else if (w.attachEvent !== undefined) {
            w.attachEvent('onload', l);
        } else {
            w.addEventListener('load', l, false);
        }
    }
}

function useHomeownersEnabled(intercomUserHash?: string): boolean {
    const [enabled, setEnabled] = useState(false);

    useEffect((): void | (() => void | undefined) => {
        async function getConfigs() {
            const response = await getConfigurations();

            if (
                response.data.some(
                    config => config.name === 'homeowners' && config.type === 'intercom' && config.status === 'enabled'
                )
            ) {
                // Only initialize Intercom if it hasn't been initialized yet, and user Intercom hash is available
                if (typeof window.Intercom === 'undefined' && intercomUserHash) {
                    initializeIntercom(import.meta.env.VITE_INTERCOM_APP_ID);
                }
                setEnabled(true);
            }
        }

        // If the user is not an agent, don't call getConfigurations and don't initialize Intercom
        if (intercomUserHash == null) return setEnabled(false);

        getConfigs();
    }, [intercomUserHash]);

    return enabled;
}

/**
 * This hook sets up Intercom for the current user if the user is an agent.
 * Currently, only agents have an `intercomUserHash` property
 */
function useIntercom() {
    const {
        principal: { email, intercomUserHash },
    } = useCurrentPrincipal();
    const enabled = useHomeownersEnabled(intercomUserHash);
    const appId = import.meta.env.VITE_INTERCOM_APP_ID as string | undefined;

    if (!appId || typeof window.Intercom === 'undefined' || !intercomUserHash || !enabled) return;

    window.Intercom('boot', {
        app_id: appId,
        user_hash: intercomUserHash,
        email,
    });
}

export default function EnsureIntercom() {
    useIntercom();

    return null;
}
