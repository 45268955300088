import { spacing } from '@lemonade-hq/blender-ui';
import { PaginationBar } from '@lemonade-hq/bluis';
import styled from 'styled-components';

interface PaginatorProps {
    readonly totalPages: number;
    readonly currentPage: number;
    readonly onPageClick: (page: number) => () => void;
}

const StyledPaginationBar = styled(PaginationBar)`
    margin: ${spacing.s32} auto 0;
`;

export const Paginator: React.FC<PaginatorProps> = ({ totalPages, currentPage, onPageClick }) => {
    if (totalPages === 0) return null;
    if (currentPage > totalPages) {
        throw new Error('Current page cannot be greater than total pages');
    }

    return <StyledPaginationBar currentPage={currentPage} onPageClick={onPageClick} pages={totalPages} />;
};
