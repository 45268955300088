import { Flex, FormProvider, generateTypedFormComponents, Grid, spacing, Text, useForm } from '@lemonade-hq/blender-ui';
import type { DialogProps } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { ma } from '@lemonade-hq/maschema-schema';
import { va } from '@lemonade-hq/maschema-validations-ui';
import { useCallback, useMemo } from 'react';
import type { DialogType } from './utils';
import { typeToProps } from './utils';
import type { CoverageRuleGroupABTest } from 'models/LoCo/Insurance/DigitalAgentEdition';
import type { ABTestRuleGroup } from 'models/LoCo/Insurance/UnderwritingFiltersEditionV2';

const manageABTestSchema = ma.record({
    experimentName: ma.string([va.minChars({ chars: 3 }), va.regExp({ pattern: '^\\S*$' })]),
    variants: ma.array(ma.string([va.regExp({ pattern: '^\\S*$' })]), [
        va.elementsUniqueness({ filters: [va.minChars({ chars: 1 })] }),
    ]),
});

const { Input, DynamicList } = generateTypedFormComponents<typeof manageABTestSchema>();

const ManageABTestForm: React.FC<{
    readonly title: string;
    readonly variantsTitle: string;
    readonly isLoading: boolean;
    readonly allowAdd?: boolean;
    readonly onSubmit: (values: Record<string, string[] | string>) => Promise<void>;
    readonly onClose: () => void;
}> = ({ title, variantsTitle, isLoading, allowAdd, onSubmit, onClose }) => {
    const {
        values,
        validationResults: { valid },
    } = useForm();

    const submit = useCallback(async () => {
        if (valid && values != null) {
            await onSubmit(values);
            onClose();
        }
    }, [valid, values, onSubmit, onClose]);

    const dialogProps: DialogProps = {
        title,
        onClose,
        loading: isLoading,
        actions: [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Save',
                type: 'submit',
                disabled: !valid,
                onClick: submit,
            },
        ],
    };

    return (
        <Dialog {...dialogProps}>
            <Text>Make sure to use the exact details from Unleash:</Text>
            <Flex alignItems="center" flexDirection="column" mb={spacing.s32} mt={spacing.s24}>
                <Flex gap={spacing.s08}>
                    <Grid alignItems="center" gap={spacing.s12} gridTemplateColumns="150px 250px">
                        <Text>Experiment Name</Text>
                        <Input schemaKey="experimentName" />
                    </Grid>
                    <Flex height={spacing.s24} width={spacing.s24} />
                </Flex>
            </Flex>
            <Text>{variantsTitle}</Text>
            <Flex justifyContent="center" mt={spacing.s24}>
                <DynamicList
                    canAddAbove={false}
                    canAddItems={allowAdd}
                    newItemTemplate={''}
                    renderItem={({ inputElement, index }) => (
                        <Grid alignItems="center" gap={spacing.s12} gridTemplateColumns="150px 250px">
                            <Text>{index === 0 ? 'Control' : 'Test Group'}</Text>
                            {inputElement}
                        </Grid>
                    )}
                    schemaKey="variants"
                />
            </Flex>
        </Dialog>
    );
};

export interface AddABTestDialogProps {
    readonly type: DialogType;
    readonly item?: ABTestRuleGroup | CoverageRuleGroupABTest;
    readonly isLoading: boolean;
    readonly onSubmit: (values: Record<string, string[] | string>) => Promise<void>;
    readonly onClose: () => void;
}

export const ManageABTestDialog: React.FC<AddABTestDialogProps> = ({ type, item, isLoading, onSubmit, onClose }) => {
    const {
        title = '',
        allowAdd,
        allowRemove,
        disableAll,
        variantsTitle = '',
        disableExistingItems,
    } = typeToProps[type] ?? {};

    const sortedVariants = useMemo(
        () => item?.variants.sort((a, _) => (a.variantType === 'control' ? -1 : 1)),
        [item?.variants]
    );

    const disabledItems = disableExistingItems
        ? sortedVariants?.reduce(
              (acc, _, index) => ({
                  ...acc,
                  [`variants[${index}]`]: { rules: [], disableEditingItem: true, disableRemovingItem: true },
              }),
              {}
          )
        : {};

    return (
        <FormProvider
            initialConfig={{
                schemaKeysRules: {
                    experimentName: { rules: [], disabled: disableAll || disableExistingItems },
                    variants: {
                        rules: [
                            {
                                conditions: { variants: [va.elementsCount({ limit: 'max', count: 2 })] },
                                actions: [{ type: 'setDisableRemovingItems', value: true }],
                            },
                            {
                                conditions: { variants: [va.elementsCount({ limit: 'min', count: 3 })] },
                                actions: [{ type: 'setDisableRemovingItems', value: !allowRemove }],
                            },
                        ],
                        disableRemovingItems: !allowRemove || sortedVariants?.length === 2,
                        disableAddingItem: !allowAdd,
                        disableEditingItems: disableAll,
                    },
                    'variants[0]': {
                        rules: [],
                        disableRemovingItem: true,
                    },
                    ...disabledItems,
                },
            }}
            initialValues={{
                experimentName: item?.experimentName ?? '',
                variants: item == null ? ['', ''] : sortedVariants?.map(variant => variant.variantName),
            }}
            schema={manageABTestSchema}
        >
            <ManageABTestForm
                allowAdd={allowAdd}
                isLoading={isLoading}
                onClose={onClose}
                onSubmit={onSubmit}
                title={title}
                variantsTitle={variantsTitle}
            />
        </FormProvider>
    );
};
