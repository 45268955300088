/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Alert, AlertMode } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import type { FC, RefObject } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { formatDateTime } from '../../Supervisor/supervisor.utils';
import type { Message, TicketAiReview, TimelineEvent, TimelineItem } from '../types';
import { InitiatingSide, TimelineItemType } from '../types';
import { Card } from './Card';
import { CollapsibleLogEvent, LogEvent } from './LogEvent';
import type { MessageLayoutProps } from './MessageLayout';
import { MessageLayout } from './MessageLayout';

const EventWrapper = styled.div`
    padding: 0 16px;
`;

const TimelineItemsContainer = styled(Flex)`
    flex-direction: column;
    gap: 16px;
`;

function buildMessageLayoutProps({
    message,
    datetime,
    showMetadata,
}: {
    readonly message: Message;
    readonly datetime?: string;
    readonly showMetadata: boolean;
}): Omit<MessageLayoutProps, 'locale' | 'timezone'> {
    return {
        datetime,
        email: message.from.email,
        html: message.htmlBody ?? `<div>${message.plainTextBody}</div>`,
        isLemonade: message.from.side === InitiatingSide.Lemonade,
        name: message.from.name ?? '',
        showMetadata: showMetadata && datetime != null,
    };
}

export function timelineComponentsMapper({
    item,
    datetime,
    showMetadata = true,
}: {
    readonly item: TimelineItem;
    readonly datetime?: string;
    readonly showMetadata?: boolean;
}): JSX.Element {
    // Backwards compatibility to support old commentsTimeline, for comments without type
    const { type = TimelineItemType.Message, publicId } = item;

    switch (type) {
        case TimelineItemType.Message:
            return (
                <Card key={publicId}>
                    <MessageLayout {...buildMessageLayoutProps({ message: item as Message, datetime, showMetadata })} />
                </Card>
            );

        case TimelineItemType.Insight:
        case TimelineItemType.Log: {
            const { action, description, title, debug, data } = item as TimelineEvent;
            const RenderedComponent = description == null && debug == null ? LogEvent : CollapsibleLogEvent;

            return (
                <EventWrapper key={publicId}>
                    <RenderedComponent
                        action={action}
                        data={data}
                        debug={debug}
                        description={description}
                        publicId={publicId}
                        sentAt={datetime}
                        title={title}
                        type={type}
                    />
                </EventWrapper>
            );
        }

        default: {
            const unreachable: never = type;

            return (
                <EventWrapper key={publicId}>
                    <Alert mode={AlertMode.Attention} title={`Unknown timeline item type- ${unreachable}`} />
                </EventWrapper>
            );
        }
    }
}

interface TimelineProps {
    readonly timeline: TimelineItem[];
    readonly locale?: TicketAiReview['interaction']['locale'];
    readonly timezone?: TicketAiReview['interaction']['timezone'];
    readonly timelineEndRef?: RefObject<HTMLDivElement>;
}

export const Timeline: FC<TimelineProps> = ({ timeline, locale, timezone, timelineEndRef }) => {
    const mappedTimeline = useMemo(
        () =>
            timeline.map(item => {
                const showDatetime = locale != null && timezone != null && item.sentAt != null;
                return timelineComponentsMapper({
                    item,
                    datetime: showDatetime ? formatDateTime({ date: item.sentAt, locale, timezone }) : undefined,
                });
            }),
        [timeline, locale, timezone]
    );

    return (
        <TimelineItemsContainer>
            {mappedTimeline}
            {timeline.length > 0 && <div ref={timelineEndRef} />}
        </TimelineItemsContainer>
    );
};
