import { InlineButton } from '@lemonade-hq/bluis';
import { themedColor } from '@lemonade-hq/boutique';
import React from 'react';
import styled from 'styled-components';
import { HeaderPortal } from '../../Header';
import { useImpersonation } from '../hooks';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background: ${themedColor('attentionBackground')};
    color: ${themedColor('attention')};
    line-height: 28px;
`;

const Icon = styled.span`
    font-size: 18px;
`;

const Text = styled.p`
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
`;

const Button = styled(InlineButton)`
    color: ${themedColor('link')};
    text-decoration: underline;

    &:hover {
        color: ${themedColor('linkHover')};
    }
`;

export const ImpersonationBanner: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { isImpersonating, stop } = useImpersonation();

    if (!isImpersonating) return null;

    return (
        <HeaderPortal>
            <Container>
                <Icon>🥸</Icon>
                <Text>Impersonation active.</Text>
                <Button onClick={stop} type="button">
                    Deactivate
                </Button>
            </Container>
        </HeaderPortal>
    );
};
