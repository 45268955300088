import { Image } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import React from 'react';
import styled from 'styled-components';

const Content = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    padding: 40px 64px;
    height: 340px;
    align-items: center;
`;

export const CheckConnectionStep: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Content>
            <Image
                img={{
                    lightSrc: 'thumbs_up_animation.gif',
                    darkSrc: 'thumbs_up_animation_dark.gif',
                    width: 70,
                    height: 'auto',
                }}
            />

            <p>Connection established successfully 🎉</p>
        </Content>
    );
};
