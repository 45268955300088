import { CommonLayer, font, themedColor } from '@lemonade-hq/cdk';
import styled from 'styled-components';

export const LightboxWrapper = styled.div`
    .bo-lightbox-bluis {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: ${themedColor('lightBoxBackground')};
        z-index: ${CommonLayer.DialogForeground};
        padding: 20px;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        text-align: center;
        backface-visibility: hidden;

        &.lightbox-desktop {
            position: fixed;

            .lightbox-actions {
                justify-content: center;
                background: transparent;
                border: 0;
                border-radius: 0;
                margin: 0;
                padding: 0;

                li {
                    flex-basis: initial;
                    padding: 0;

                    .btn-standard {
                        padding: 0 45px;
                        height: 47px;
                        line-height: 47px;
                    }

                    + li {
                        border: 0;
                        padding-left: 20px;
                    }
                }
            }
        }

        &.has-error {
            .bo-lightbox-actions {
                .bo-lightbox-action[data-type='close'] {
                    display: none;
                }
            }
        }

        &.embedded {
            width: 100%;
            padding: 0;
            border-radius: 5px;

            > span {
                height: 100vh;
            }

            .lightbox-content {
                width: 100vw;
                height: 100vh;
                border: 1px solid ${themedColor('separator')};

                .lightbox-header {
                    height: 46px;

                    h4 {
                        ${font('main', { fontSize: '18px', lineHeight: '46px', fontWeight: 700 })};
                    }

                    .btn-back {
                        display: block;
                    }
                }
            }
        }

        .ani-lightbox-content-appear {
            opacity: 0;
            transform: scale(0.3);

            &.ani-lightbox-content-appear-active {
                opacity: 1;
                transform: scale(1);
                transition:
                    opacity 0.3s,
                    transform 0.3s;
            }
        }
    }

    .lightbox-content {
        position: relative;
        background: ${themedColor('componentBackground')};
        border-radius: 5px;
        width: 100%;
        height: auto;
        margin: 0 auto;

        .lightbox-header {
            position: relative;
            width: 100%;
            height: 60px;
            padding: 10px 25px;
            border-bottom: 1px solid ${themedColor('separator')};
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;

            h4 {
                ${font('main', { fontSize: '16px', lineHeight: '30px', fontWeight: 700 })};
                text-transform: uppercase;
                margin: 0 !important;
            }

            h5 {
                ${font('main', { fontSize: '14px', lineHeight: '17px' })};
                color: ${themedColor('secondaryText')};
                margin: 0;
            }

            .btn-back {
                position: absolute;
                top: 14px;
                left: 15px;
                height: 18px;
                width: 11px;
                background-image: url('${__assets_url}blender_assets/global/back_sml_icon.png');
                background-size: cover;
                display: none;

                &:hover {
                    cursor: pointer;
                }
            }

            .embedded-error {
                position: absolute;
                top: -59px;
                left: 0;
                width: 100%;
                height: 59px;
                z-index: 101;
                padding: 15px 15px 10px;
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: flex-start;
                background-color: ${themedColor('error')};
                opacity: 0;
                text-align: left;
                white-space: pre-line;
                transition:
                    top 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
                    opacity 0s 0.4s;
                border-radius: 5px 5px 0 0;

                .embedded-error-content {
                    ${font('main', { fontSize: '14px', lineHeight: '17px', fontWeight: 400 })};
                    color: ${themedColor('componentBackground')};
                    margin: 0;
                }

                &.shown {
                    transition:
                        top 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
                        opacity 0s;
                    opacity: 1;
                    top: -10px;
                }
            }
        }

        .lightbox-body {
            width: 100%;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            padding: 0 25px;

            &.scrollable {
                padding: 0;
            }
        }

        .lightbox-notice {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 12px 25px;

            &:only-of-type {
                padding: 12px 50px;
                justify-content: center;
            }

            &:nth-of-type(1) {
                border-top: 1px solid ${themedColor('separator')};
            }

            & > span,
            & .info {
                position: relative;
                ${font('main', { fontSize: '14px', lineHeight: '17px' })};
                color: ${themedColor('secondaryText')};
                padding: 0 0 0 20px;
                top: -1px;

                &:before {
                    content: '';
                    position: absolute;
                    top: 1px;
                    left: 0;
                    width: 16px;
                    height: 16px;
                    background-size: contain;
                    background-image: url('${__assets_url}blender_assets/backoffice/notice-icn.png');
                }
            }
        }

        &.content-centered {
            .lightbox-header {
                justify-content: center;
            }

            .lightbox-body {
                align-items: center;
            }
        }

        .btn-close {
            width: 30px;
            height: 30px;
            border: 1px solid ${themedColor('separator')};
            border-radius: 50%;
            position: absolute;
            background-color: ${themedColor('componentBackground')};
            top: -12.5px;
            right: -12.5px;
            transition: all 250ms;
            outline: none;
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;
            span {
                outline: none;
                opacity: 0.5;
                font-size: 21px;
                font-weight: 700;
                line-height: 1;
                position: absolute;
                top: 2px;
                left: 8.5px;
                pointer-events: none;
            }

            &:hover {
                background-color: ${themedColor('componentBackground')};
                box-shadow:
                    0 3px 1px rgba(0, 0, 0, 0.1),
                    0 1px 1px rgba(0, 0, 0, 0.15);

                span {
                    opacity: 1;
                }

                .close {
                    opacity: 1;
                    outline: none;

                    span {
                        outline: none;
                    }
                }
            }
        }
    }
`;
