import axios from 'axios';
import { stringifyUrl } from 'query-string';
import { getUrlResolver } from 'commons/UrlResolver';
import { SchoolAndPolicyHolderType } from 'models/HomePolicy';

const homeBlenderUrlResolver = getUrlResolver('home-blender');

export async function create({
    endorsement,
}: {
    endorsement: { [key: string]: string | number | boolean | string[] };
}): Promise<null> {
    const url = '/backoffice/endorsements';
    const body = {
        endorsement: {
            ...endorsement,
        },
    };

    return axios.post(url, body).then(response => response.data);
}

export async function cancel({ endorsementId }: { endorsementId: string }): Promise<null> {
    const url = `/backoffice/endorsements/${endorsementId}/cancel`;

    return axios.post(url).then(response => response.data);
}

export async function submitChanges<ChangesType>({
    policyId,
    changes,
    type,
}: {
    policyId: string;
    changes: ChangesType;
    type?: SchoolAndPolicyHolderType;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/additional_policyholders/${policyId}/submit_changes`);

    return axios
        .post(url, {
            type,
            changes,
        })
        .then(response => response.data);
}

export interface PremiumDiffData {
    installmentPremiumDiff: string;
    newAnnualPremium: string;
    newMonthlyPremium: string;
    newInstallmentPremiumDiff: string;
}

export interface SimulatePriceParams {
    policyId: string;
    params: Record<string, string | number>;
    type?: SchoolAndPolicyHolderType;
}

export async function simulatePrice({ policyId, params, type }: SimulatePriceParams): Promise<PremiumDiffData> {
    const url = stringifyUrl(
        {
            url: homeBlenderUrlResolver(`/api/v1/additional_policyholders/${policyId}/simulate_price`),
            query: {
                ...params,
                type,
            },
        },
        { arrayFormat: 'comma' }
    );

    return axios.get(url).then(response => response.data.data);
}
