import { Node } from 'slate';
import { Recipient } from 'models/Recipient';
import { Attachment } from 'models/Attachment';
import { SelectedTab } from '../reducers/communication';

export enum CommunicationActions {
    EMAIL_CREATE = 'EMAIL_CREATE',
    EMAIL_SELECT_RECIPIENT = 'EMAIL_SELECT_RECIPIENT',
    EMAIL_REMOVE_RECIPIENT = 'EMAIL_REMOVE_RECIPIENT',
    EMAIL_CHANGE_SUBJECT = 'EMAIL_CHANGE_SUBJECT',
    EMAIL_CHANGE_CONTENT = 'EMAIL_CHANGE_CONTENT',
    EMAIL_SELECT_ATTACHMENTS = 'EMAIL_SELECT_ATTACHMENTS',
    EMAIL_REMOVE_ATTACHMENT = 'EMAIL_REMOVE_ATTACHMENT',
    EMAIL_RESET = 'EMAIL_RESET',
    EMAIL_BODY_RESET = 'EMAIL_BODY_RESET',
    EMAIL_DELETE = 'EMAIL_DELETE',
    NOTE_CHANGE_CONTENT = 'NOTE_CHANGE_CONTENT',
    SET_TAB = 'SET_TAB',
    RESET_STATE = 'RESET_STATE',
    EMAIL_ADD_CC_RECIPIENT = 'EMAIL_ADD_CC_RECIPIENT',
    EMAIL_REMOVE_CC_RECIPIENT = 'EMAIL_REMOVE_CC_RECIPIENT',
    EMAIL_RESET_CC_RECIPIENTS = 'EMAIL_RESET_CC_RECIPIENTS',
    SHOULD_UPDATE_THREAD_OWNER = 'SHOULD_UPDATE_THREAD_OWNER',
}

interface ShouldUpdateThreadOwnerAction {
    type: CommunicationActions.SHOULD_UPDATE_THREAD_OWNER;
    payload: { interactionId: string; shouldUpdateThreadOwner: boolean };
}

interface EmailResetCcRecipientsAction {
    type: CommunicationActions.EMAIL_RESET_CC_RECIPIENTS;
    payload: { interactionId: string };
}
interface EmailAddCCRecipientAction {
    type: CommunicationActions.EMAIL_ADD_CC_RECIPIENT;
    payload: {
        interactionId: string;
        ccRecipient: Recipient;
    };
}

interface EmailRemoveCCRecipientAction {
    type: CommunicationActions.EMAIL_REMOVE_CC_RECIPIENT;
    payload: {
        interactionId: string;
        ccRecipient: Recipient;
    };
}

interface EmailCreateAction {
    type: CommunicationActions.EMAIL_CREATE;
    payload: {
        interactionId: string;
        recipient: Recipient;
        ccRecipients: Recipient[];
        shouldUpdateThreadOwner?: boolean;
    };
}

interface EmailSelectRecipientAction {
    type: CommunicationActions.EMAIL_SELECT_RECIPIENT;
    payload: { interactionId: string; recipient?: Recipient; isFirstResponseScheduled: boolean };
}

interface EmailRemoveRecipientAction {
    type: CommunicationActions.EMAIL_REMOVE_RECIPIENT;
    payload: { interactionId: string; recipient: Recipient };
}

interface EmailChangeSubjectAction {
    type: CommunicationActions.EMAIL_CHANGE_SUBJECT;
    payload: { interactionId: string; subject: string };
}

interface EmailChangeContentAction {
    type: CommunicationActions.EMAIL_CHANGE_CONTENT;
    payload: { interactionId: string; content: Node[] };
}

interface EmailSelectAttachmentsAction {
    type: CommunicationActions.EMAIL_SELECT_ATTACHMENTS;
    payload: { interactionId: string; attachments: Attachment[] };
}

interface EmailRemoveAttachmentAction {
    type: CommunicationActions.EMAIL_REMOVE_ATTACHMENT;
    payload: { interactionId: string; attachment: Attachment };
}

interface EmailResetAction {
    type: CommunicationActions.EMAIL_RESET;
    payload: { interactionId: string };
}

interface EmailBodyReset {
    type: CommunicationActions.EMAIL_BODY_RESET;
    payload: { interactionId: string };
}

interface EmailDelete {
    type: CommunicationActions.EMAIL_DELETE;
    payload: { interactionId: string };
}

interface NoteChangeContent {
    type: CommunicationActions.NOTE_CHANGE_CONTENT;
    payload: { content: Node[] };
}

interface SetTab {
    type: CommunicationActions.SET_TAB;
    payload: { tab: SelectedTab };
}

interface ResetState {
    type: CommunicationActions.RESET_STATE;
}

export type CommunicationActionTypes =
    | EmailCreateAction
    | EmailSelectRecipientAction
    | EmailRemoveRecipientAction
    | EmailChangeSubjectAction
    | EmailChangeContentAction
    | EmailSelectAttachmentsAction
    | EmailRemoveAttachmentAction
    | EmailResetAction
    | EmailBodyReset
    | EmailDelete
    | NoteChangeContent
    | SetTab
    | ResetState
    | EmailAddCCRecipientAction
    | EmailRemoveCCRecipientAction
    | EmailResetCcRecipientsAction
    | ShouldUpdateThreadOwnerAction;
