import { Avatar, DropdownMenuItem, DropdownMenuWrapper, Spinner } from '@lemonade-hq/bluis';
import type { MentionsChangeProps } from '@lemonade-hq/cdk';
import { addPortalSlot, CommonLayer, ellipsis, Flex, themedColor, useClickOutside } from '@lemonade-hq/cdk';
import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import type { MentionUser } from './types';

const ITEM_HEIGHT_WITH_PADDING = 44;

const StyledDropdownMenuItem = styled(DropdownMenuItem)<{ readonly isSelected: boolean }>`
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? themedColor('disabledBackground') : themedColor('componentBackground')};

  &:hover {
    background-color: ${themedColor('disabledBackground')};
  }
  padding: 8px;
  border-radius: 4px;
  height: 40px;
`;

const StyledDropdownMenuWrapper = styled(DropdownMenuWrapper)`
  width: 343px;
  max-height: 200px;
  overflow: auto;
  z-index: ${CommonLayer.Popover};
  position: absolute;
`;

const Role = styled.div`
  ${ellipsis}
  flex: 1 1 auto;
  font-size: 12px;
  color: ${themedColor('disabledText')};
`;

const Name = styled.div`
  flex: 0 0 auto;
  max-width: 180px;
  padding-left: 2px;
  ${ellipsis}
  font-size: 14px;
  color: ${themedColor('primaryText')};
`;

const DropdownPortal = ({ children }: { readonly children: ReactNode }): JSX.Element =>
  createPortal(children, addPortalSlot('mentions-dropdown'));

interface MentionsDropdownProps {
  readonly baseStyles: MentionsChangeProps['baseStyles'];
  readonly searchQuery: string;
  readonly selectedIndex: number;
  readonly users: MentionUser[];
  readonly isLoading: boolean;
  readonly onSelect: (user: MentionUser) => void;
  readonly onCloseDropdown: () => void;
}

export const MentionsDropdown = ({
  baseStyles,
  selectedIndex,
  searchQuery,
  users,
  isLoading,
  onSelect,
  onCloseDropdown,
}: MentionsDropdownProps): JSX.Element => {
  const scrollPositionRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(scrollPositionRef, true, () => {
    onCloseDropdown();
  });

  useEffect(() => {
    const container = scrollPositionRef.current;
    if (!container) return;

    const containerRect = container.getBoundingClientRect();

    const visibleItemCount = Math.floor(containerRect.height / ITEM_HEIGHT_WITH_PADDING);
    const visibleTop = container.scrollTop;
    const visibleBottom = visibleTop + visibleItemCount * ITEM_HEIGHT_WITH_PADDING;

    const selectedTop = selectedIndex * ITEM_HEIGHT_WITH_PADDING;
    const selectedBottom = selectedTop + ITEM_HEIGHT_WITH_PADDING;

    if (selectedTop < visibleTop) {
      container.scrollTo({ top: selectedTop });
    } else if (selectedBottom > visibleBottom) {
      container.scrollTo({ top: selectedBottom - visibleItemCount * ITEM_HEIGHT_WITH_PADDING });
    }
  }, [selectedIndex]);

  useEffect(() => {
    const handleClose = (e: Event): void => {
      if (scrollPositionRef.current?.contains(e.target as Node)) return;

      onCloseDropdown();
    };

    window.addEventListener('scroll', handleClose, {
      capture: true,
    });

    window.addEventListener('resize', onCloseDropdown);

    return () => {
      window.removeEventListener('scroll', handleClose, {
        capture: true,
      });
      window.removeEventListener('resize', onCloseDropdown);
    };
  }, [onCloseDropdown]);

  useEffect(() => {
    const fullName = searchQuery.toLowerCase();
    const user = users.find(
      ({ firstName, lastName }) => `${firstName.toLowerCase()} ${lastName.toLowerCase()}` === fullName,
    );

    if (user) {
      onSelect(user);
    }
  }, [users, searchQuery, onSelect]);

  return (
    <DropdownPortal>
      <StyledDropdownMenuWrapper isOpen ref={scrollPositionRef} style={baseStyles}>
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center">
            <Spinner size="medium" />
          </Flex>
        ) : (
          users.map((value, idx) => (
            <StyledDropdownMenuItem
              isSelected={selectedIndex === idx}
              key={value.publicId}
              onClick={() => onSelect(value)}
            >
              <Flex alignItems="center" gap="8px">
                <Avatar size="small" src={value.imageUrl as string | undefined} />
                <Name>
                  {value.firstName} {value.lastName}
                </Name>
                <Role>
                  {value.attributes?.department[0]}{' '}
                  {value.attributes?.jobTitle[0] != null && `| ${value.attributes.jobTitle[0]}`}
                </Role>
              </Flex>
            </StyledDropdownMenuItem>
          ))
        )}
      </StyledDropdownMenuWrapper>
    </DropdownPortal>
  );
};
