import type { Infer, RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import type { FC, ReactNode } from 'react';
import * as styles from '../../../DynamicList/DynamicList.css';
import { useForm } from '../../FormContext';
import { useConnectToForms } from '../common';
import { Input } from '../Input/Input';
import { DynamicListItemActionMenu } from './common';
import type { DynamicListProps, PrimitiveShape } from './DynamicList';
import { useDynamicListContext } from './DynamicListContext';
import { Flex } from 'libs/blender-ui/src/base/Flex/Flex';

type DynamicListPrimitiveItemsProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TItem extends Infer<PrimitiveShape>,
> = DynamicListProps<TSchema, TSchemaKey, PrimitiveShape> & {
  readonly items: TItem[];
  readonly schemaKey: TSchemaKey;
};

const PrimitiveListItemInput: FC<{ readonly schemaKey: string }> = ({ schemaKey }) => {
  const { disableEditingItems } = useDynamicListContext();
  const { config } = useForm();

  const disableEditingItem = config.schemaKeysRules?.[schemaKey]?.disableEditingItem;

  return (
    <Input
      className={styles.primitiveListItem}
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      disabled={disableEditingItems || disableEditingItem}
      schemaKey={schemaKey}
    />
  );
};

export const DynamicListPrimitiveItems = <
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TItem extends Infer<PrimitiveShape>,
>({
  items,
  schemaKey,
  renderItem,
  canAddAbove = true,
}: DynamicListPrimitiveItemsProps<TSchema, TSchemaKey, TItem>): ReactNode => {
  const { disabled } = useConnectToForms({ schemaKey });

  return items.map((_, i) => {
    const itemSchemaKey = `${schemaKey}[${i}]`;
    // eslint-disable-next-line react/no-array-index-key
    return (
      <Flex className={styles.primitiveListItemContainer} key={itemSchemaKey}>
        {renderItem != null ? (
          renderItem({
            schemaKey: itemSchemaKey,
            index: i,
            inputElement: <PrimitiveListItemInput schemaKey={itemSchemaKey} />,
          })
        ) : (
          <PrimitiveListItemInput schemaKey={itemSchemaKey} />
        )}
        {disabled ? null : (
          <DynamicListItemActionMenu
            canAddAbove={canAddAbove}
            editable={false}
            index={i}
            items={items}
            schemaKey={schemaKey}
          />
        )}
      </Flex>
    );
  });
};
