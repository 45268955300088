import pluralize from 'pluralize';
import type { FC } from 'react';
import { AiResponseType } from '../../../shared/types';
import { useSupervisorContext } from '../Context';

export const TicketsProgressInfo: FC<React.PropsWithChildren<unknown>> = () => {
    const {
        state: {
            mode,
            metadata: {
                pendingTicketsCount: { public: publicCount, draft },
                reviewedTodayCount,
            },
        },
    } = useSupervisorContext();

    const count = AiResponseType.Public === mode ? publicCount : draft;
    const ticketsCountInfo = pluralize('tickets', reviewedTodayCount, true);
    const reviewedCountInfo =
        reviewedTodayCount > 0 ? (
            <>
                You solved&nbsp;
                <b>{ticketsCountInfo}</b>
                &nbsp;today.
            </>
        ) : (
            <>You haven&apos;t solved any tickets today yet.</>
        );

    return (
        <>
            {reviewedCountInfo}&nbsp;
            <b>{count} tickets</b>&nbsp;left in this queue
        </>
    );
};
