/* eslint-disable @typescript-eslint/naming-convention */
import type { EntityTypes } from '@lemonade-hq/bluiza';
import type { AttachmentActionType, AttachmentDTO, DetectionResult, Indicator, IndicatorType } from './types';

export const MISSING_VALUE = '-';

export const getRaiDetectionResult = (attachment: AttachmentDTO): DetectionResult | undefined =>
    attachment.detections?.find(detection => detection.detectionModel === 'resistant_ai')?.detectionResult;

export const getLmndDetectionResult = (attachment: AttachmentDTO): DetectionResult | undefined =>
    attachment.detections?.find(detection => detection.detectionModel === 'lmnd_ds')?.detectionResult;

export const getFraudDetectionScore = (attachment: AttachmentDTO): string | null =>
    getRaiDetectionResult(attachment)?.score ?? getLmndDetectionResult(attachment)?.score ?? null;

export const getIndicators = (attachment: AttachmentDTO): Partial<Record<IndicatorType, Indicator[]>> | undefined =>
    attachment.detections?.find(detection => detection.detectionResult.indicators != null)?.detectionResult
        .indicators ?? undefined;

export const getTextChanges = (attachment: AttachmentDTO): { readonly [index: string]: string[] } | undefined =>
    attachment.detections?.find(detection => detection.detectionResult.textChanges != null)?.detectionResult
        .textChanges ?? undefined;

export function getProductAndEntity(entityType: EntityTypes): {
    readonly product: string;
    readonly entity: string;
} {
    const [product, entity] = entityType.split('_');

    return { product, entity };
}

export function isFraud(attachment: AttachmentDTO): boolean {
    return Boolean(
        attachment.detections?.some(
            detection =>
                detection.detectionResult.score === 'HIGH_RISK' || detection.detectionResult.score === 'WARNING'
        )
    );
}

export const getInlineUrl = (url?: string | null): string => {
    if (
        !url?.endsWith('?inline=true') &&
        (url?.startsWith('https://files-') ?? url?.startsWith('https://icebox.lemonade'))
    ) {
        if (url.endsWith('?inline=false')) {
            return url.replace('inline=false', 'inline=true');
        }

        return `${url}?inline=true`;
    }

    return url ?? '';
};

export const getAttachmentAnalyticsParam = ({
    attachment,
    entityType,
    entityId,
}: {
    readonly attachment: AttachmentDTO;
    readonly entityType: EntityTypes;
    readonly entityId: string;
}): Record<string, string> => {
    return {
        doc_id: attachment.publicId,
        entity_id: entityId,
        entity_type: entityType,
        modification_score: getLmndDetectionResult(attachment)?.score ?? '',
        content_type: attachment.contentType ?? '',
        doc_name: attachment.fileName ?? '',
        doc_type: attachment.type ?? '',
        doc_description: attachment.description ?? '',
        suggested_archive: attachment.suggestedArchive?.toString() ?? '',
        suggested_archive_reason: attachment.archivingReason ?? '',
        doc_sources: attachment.sources?.map(source => source.description).join(',') ?? '',
        doc_status: attachment.status,
        archive_reason: attachment.archivingReason ?? '',
    };
};

export function getActionLabel(action: AttachmentActionType | undefined): string {
    switch (action) {
        case 'archive':
            return 'Archive';
        case 'unarchive':
            return 'Unarchive';
        case 'relabel':
            return 'Relabel';
        case 'check_for_modifications':
            return 'Check for modifications';
        case 'assign_to_home_claim_item':
            return 'Assign';
        default:
            return '';
    }
}

export enum AttachmentType {
    Audio = 'audio',
    Image = 'image',
    Doc = 'doc',
    Video = 'video',
    Pdf = 'pdf',
    Xlc = 'xlc',
    Unknown = 'unknown',
}

export function getAttachmentType(identifier?: string | null): AttachmentType | null {
    if (identifier == null) {
        return AttachmentType.Unknown;
    }

    if (/pdf/i.test(identifier)) {
        return AttachmentType.Pdf;
    }

    if (/(doc|docx|txt|rtf|xls|xlsx|sheet)/i.test(identifier)) {
        return AttachmentType.Doc;
    }

    if (/(png|jpg|jpeg|gif|bmp|svg|webp)/i.test(identifier)) {
        return AttachmentType.Image;
    }

    if (/(mp4|avi|mov|wmv|flv|mkv|mpeg|webm|quicktime|video)/i.test(identifier)) {
        return AttachmentType.Video;
    }

    if (/(mp3|wav|ogg|m4a|aac)/i.test(identifier)) {
        return AttachmentType.Audio;
    }

    if (/(xls|xlsx)/i.test(identifier)) {
        return AttachmentType.Xlc;
    }

    return AttachmentType.Unknown;
}
