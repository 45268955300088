import type { ImageProps } from '@lemonade-hq/bluis';
import { Image } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ThemedColors } from 'bluis/Colors';

export const WrapperDragAndDrop = styled.div<{
    readonly isEmpty?: boolean;
    readonly onDrop: (e: React.DragEvent<HTMLInputElement>) => void;
    readonly dragAreaSize?: DragAreaSize;
}>`
    position: relative;
    width: ${({ dragAreaSize }) => dragAreaSize?.width ?? '100%'};
    height: ${({ isEmpty, dragAreaSize }) => (isEmpty ? dragAreaSize?.height : '')};
`;

export const DragAndDropArea = styled(Flex)<{ readonly dragActive: boolean }>`
    border: 1px dashed ${ThemedColors.separatorColor};
    border-radius: 5px;
    pointer-events: none;
    transition:
        border-color 250ms ease-in-out,
        background-color 250ms ease-in-out;
    justify-content: center;
    align-items: center;
    height: 100%;

    ${({ dragActive }) =>
        dragActive === true &&
        css`
            border-color: ${ThemedColors.primaryColor};
            background-color: ${ThemedColors.lightAlert};
        `};
`;

export const UploadStyledIcon = styled(Image)<ImageProps>`
    margin-bottom: 16px;
`;

const BrowseFiles = styled.button`
    background: transparent;
    border: 0;
    outline: 0;
    color: ${ThemedColors.primaryColor};
    pointer-events: all;
    padding: 0;
    margin: 0;

    &:hover {
        text-decoration: underline;
    }
`;

export const DragAndDropTextWrapper = styled.div<{ readonly animate: boolean }>`
    opacity: 1;
    height: 40px;
    transition: opacity 100ms ease-in-out;
    text-align: center;
    ${({ animate }) =>
        animate &&
        css`
            opacity: 0;
        `}
`;

type DragAreaSize = {
    readonly width?: string;
    readonly height?: string;
};

const FileInput = styled.input<{ readonly dragAreaSize?: DragAreaSize; readonly isEmpty?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    width: ${({ dragAreaSize }) => dragAreaSize?.width ?? '100%'};
    height: ${({ dragAreaSize }) => dragAreaSize?.height ?? '100%'};
    ${({ isEmpty }) => isEmpty && 'cursor: pointer'};
`;

const DragAndDropText: React.FC<
    React.PropsWithChildren<{
        readonly dragActive: boolean;
        readonly onFilesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    }>
> = ({ dragActive }) => {
    const [animate, setAnimate] = useState(false);
    const [enableAnimation, setEnableAnimation] = useState(false);
    const [showActiveText, setShowActiveText] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    function onBrowseFilesClick() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    const notActiveText = (
        <>
            <span>Drag & drop your files here</span>
            <div>
                <span>&nbsp;or&nbsp;</span>
                <BrowseFiles onClick={onBrowseFilesClick} type="button">
                    browse files
                </BrowseFiles>
            </div>
        </>
    );

    const activeText = <span>You can let go now 😊</span>;

    useEffect(() => {
        if (!enableAnimation) {
            setEnableAnimation(true);
            return;
        }

        setAnimate(true);
        const animateTimeoutId = setTimeout(() => {
            setAnimate(false);
        }, 100);

        const showTextTimeoutId = setTimeout(() => {
            setShowActiveText(dragActive);
        }, 100);

        return () => {
            clearTimeout(animateTimeoutId);
            clearTimeout(showTextTimeoutId);
        };
    }, [dragActive]);

    return (
        <DragAndDropTextWrapper {...{ animate }}>{showActiveText ? activeText : notActiveText}</DragAndDropTextWrapper>
    );
};

interface Props {
    readonly onChange: (files: FileList) => void;
    readonly onDrop?: (e: React.DragEvent<HTMLDivElement>) => void;
    readonly multipleFiles: boolean;
    readonly iconWidth?: number;
    readonly isEmpty?: boolean;
    readonly acceptType: string;
    readonly dragAreaSize?: DragAreaSize;
}

/**
 * @deprecated
 * use DragAndDropFileInput + useFileUploadDragAndDrop from @lemonade-hq/bluis instead
 */
const DragAndDropFileInput: React.FC<React.PropsWithChildren<Props>> = ({
    onChange,
    onDrop,
    multipleFiles,
    iconWidth,
    acceptType,
    isEmpty = false,
    dragAreaSize,
}) => {
    const [dragActive, setDragActive] = useState(false);

    function onDragEnter() {
        setDragActive(true);
    }

    function onDragLeave() {
        setDragActive(false);
    }

    function onFilesChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) return;

        onChange(e.target.files);
        setDragActive(false);
    }

    function handleOnDrop(e: React.DragEvent<HTMLInputElement>) {
        setDragActive(false);

        onDrop?.(e);
    }

    return (
        <WrapperDragAndDrop
            dragAreaSize={dragAreaSize}
            isEmpty={isEmpty}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={handleOnDrop}
        >
            <FileInput
                accept={acceptType}
                dragAreaSize={dragAreaSize}
                isEmpty={isEmpty}
                multiple={multipleFiles}
                onChange={onFilesChange}
                type="file"
            />
            {isEmpty && (
                <DragAndDropArea dragActive={dragActive} flexDirection="column">
                    <UploadStyledIcon
                        img={{ lightSrc: 'attachment-placeholder.png', width: iconWidth ?? '', height: 'auto' }}
                    />
                    <DragAndDropText {...{ dragActive, onFilesChange }} />
                </DragAndDropArea>
            )}
        </WrapperDragAndDrop>
    );
};

export default DragAndDropFileInput;
