import { ErrorSection, isAuthorizationError, LoadingSection } from '@lemonade-hq/bluis';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { ErrorBoundary } from '@sentry/react';
import type { ComponentProps, PropsWithChildren } from 'react';
import { Suspense } from 'react';

function getNoBordersValue(config: { readonly noBorders?: boolean } | undefined): boolean {
    return isDefined(config) && isDefined(config.noBorders) ? config.noBorders : true;
}

export const SuspendableContent: React.FC<
    PropsWithChildren<{
        readonly errorProps?: ComponentProps<typeof ErrorSection>;
        readonly loadingProps?: ComponentProps<typeof LoadingSection>;
    }>
> = ({ children, errorProps, loadingProps }) => {
    return (
        <ErrorBoundary
            fallback={({ error }) => {
                if (isAuthorizationError(error)) throw error;

                return (
                    <ErrorSection
                        noBorders={getNoBordersValue(errorProps)}
                        subtitle={errorProps?.subtitle}
                        title={errorProps?.title ?? error.message}
                    />
                );
            }}
        >
            <Suspense fallback={<LoadingSection noBorders={getNoBordersValue(loadingProps)} />}>{children}</Suspense>
        </ErrorBoundary>
    );
};
