import type { UseSuspenseQueryResult } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import { getCoveragesEdition } from './CoveragesEditionQueries';
import { getDigitalAgentEdition } from './DigitalAgentEditionQueries';
import { getUnderwritingFiltersEdition } from './UnderwritingFiltersEditionQueries';
import type { ChangesLogResponse } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';
import type { DigitalAgentEdition } from 'models/LoCo/Insurance/DigitalAgentEdition';
import type { UnderwritingFiltersEdition } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

const CHANGES_LOG_BASE_PATH = '/api/v1/changes-logs';

enum ChangesLogQueryKey {
    ChangesLog = 'CHANGES_LOG',
}

export interface GetEditionsForChangesLogResponse {
    readonly editionInstances: {
        readonly coveragesEdition: CoveragesEdition | null;
        readonly digitalAgentEdition: DigitalAgentEdition | null;
        readonly underwritingFiltersEdition: UnderwritingFiltersEdition | null;
    };
    readonly baseEditionInstances: {
        readonly coveragesEdition: CoveragesEdition | null;
        readonly digitalAgentEdition: DigitalAgentEdition | null;
        readonly underwritingFiltersEdition: UnderwritingFiltersEdition | null;
    };
}

export interface GetEditionsForChangesLogProps {
    readonly coveragesEditionCode?: string;
    readonly baseCoveragesEditionCode?: string;
    readonly digitalAgentEditionCode?: string;
    readonly baseDigitalAgentEditionCode?: string;
    readonly underwritingFiltersEditionCode?: string;
    readonly baseUnderwritingFiltersEditionCode?: string;
}

export async function getEditionsForChangesLog({
    coveragesEditionCode,
    digitalAgentEditionCode,
    underwritingFiltersEditionCode,
    baseCoveragesEditionCode,
    baseDigitalAgentEditionCode,
    baseUnderwritingFiltersEditionCode,
}: GetEditionsForChangesLogProps): Promise<GetEditionsForChangesLogResponse> {
    const [
        coveragesEdition,
        digitalAgentEdition,
        underwritingFiltersEdition,
        baseCoverageEdition,
        baseDigitalAgentEdition,
        baseUnderwritingFiltersEdition,
    ] = await Promise.all([
        coveragesEditionCode != null ? getCoveragesEdition(coveragesEditionCode) : null,
        digitalAgentEditionCode != null ? getDigitalAgentEdition(digitalAgentEditionCode) : null,
        underwritingFiltersEditionCode != null ? getUnderwritingFiltersEdition(underwritingFiltersEditionCode) : null,
        baseCoveragesEditionCode != null ? getCoveragesEdition(baseCoveragesEditionCode) : Promise.resolve(null),
        baseDigitalAgentEditionCode != null
            ? getDigitalAgentEdition(baseDigitalAgentEditionCode)
            : Promise.resolve(null),
        baseUnderwritingFiltersEditionCode != null
            ? getUnderwritingFiltersEdition(baseUnderwritingFiltersEditionCode)
            : Promise.resolve(null),
    ]);

    return {
        editionInstances: {
            coveragesEdition,
            digitalAgentEdition,
            underwritingFiltersEdition,
        },
        baseEditionInstances: {
            coveragesEdition: baseCoverageEdition,
            digitalAgentEdition: baseDigitalAgentEdition,
            underwritingFiltersEdition: baseUnderwritingFiltersEdition,
        },
    };
}

export interface GetChangesLogForEditionParams {
    readonly coveragesEditionCode?: string;
    readonly digitalAgentEditionCode?: string;
    readonly underwritingFiltersEditionCode?: string;
}

async function getChangesLogForEdition(params: GetChangesLogForEditionParams): Promise<ChangesLogResponse> {
    return await insuranceBlender
        .get<{ data: ChangesLogResponse }>(CHANGES_LOG_BASE_PATH, { params })
        .then(response => response.data.data);
}

interface UseGetChangesLogAndEditionsParams {
    readonly editionCodes: GetChangesLogForEditionParams;
    readonly baseEditionCodes: GetChangesLogForEditionParams;
}

interface UseGetChangesLogAndEditionsResponse extends GetEditionsForChangesLogResponse {
    readonly changesLog: ChangesLogResponse;
}

export function useGetChangesLogAndEditions({
    baseEditionCodes,
    editionCodes,
}: UseGetChangesLogAndEditionsParams): UseSuspenseQueryResult<UseGetChangesLogAndEditionsResponse> {
    return useSuspenseQuery({
        queryKey: [ChangesLogQueryKey.ChangesLog, [baseEditionCodes, editionCodes]],
        queryFn: async () => {
            const [changesLog, editions] = await Promise.all([
                getChangesLogForEdition(editionCodes),
                getEditionsForChangesLog({
                    ...editionCodes,
                    baseCoveragesEditionCode: baseEditionCodes.coveragesEditionCode,
                    baseDigitalAgentEditionCode: baseEditionCodes.digitalAgentEditionCode,
                    baseUnderwritingFiltersEditionCode: baseEditionCodes.underwritingFiltersEditionCode,
                }),
            ]);

            return {
                changesLog,
                ...editions,
            } satisfies UseGetChangesLogAndEditionsResponse;
        },
    });
}
