import { Select } from '@lemonade-hq/blender-ui';
import type { Key } from 'react';
import { useCallback, useMemo } from 'react';
import { ExpressionActionType, useExpressionSimpleEditorContext } from './ExpressionSimpleEditorContext';
import type { ExpressionProps } from './ExpressionSimpleEditorShared';
import { ExpressionType } from './expressionTypes';
import type { EnumArgument, ExpressionTree, LiteralNode } from './expressionTypes';

import * as rulesBuilderStyles from './RulesBuilder.css';

interface EnumExpressionProps extends ExpressionProps {
    readonly argumentType: EnumArgument;
}

const getSiblingsValues = (node: LiteralNode, tree: ExpressionTree): string[] => {
    if (node.parent === undefined) return [];

    const parentNode = tree[node.parent];
    if (parentNode.type !== ExpressionType.ArrayExpression) return [];

    return parentNode.children.map(child => (tree[child] as LiteralNode).value);
};

export const EnumExpression: React.FC<EnumExpressionProps> = ({ id, argumentType }) => {
    const { state, dispatch } = useExpressionSimpleEditorContext();

    const node = useMemo(() => state.expressionTree[id] as LiteralNode, [state, id]);

    const options = useMemo(() => {
        const exclude = getSiblingsValues(node, state.expressionTree);
        return argumentType.symbols
            .filter(symbol => !exclude.includes(symbol) || symbol === node.value)
            .map(symbol => ({
                id: symbol,
                value: symbol,
                label: symbol,
            }));
    }, [argumentType.symbols, node, state.expressionTree]);

    const updateValue = useCallback(
        (value: Key | null): void => {
            if (typeof value === 'string') {
                dispatch({
                    type: ExpressionActionType.SetValueType,
                    payload: { id, newValue: { value } },
                });
            }
        },
        [dispatch, id]
    );

    return (
        <Select
            className={rulesBuilderStyles.basicInputContainer}
            key={id}
            onChange={updateValue}
            options={options}
            placeholder="Select value"
            selectedKey={node.value.toString()}
        />
    );
};
