import sanitizeHtml from 'sanitize-html';
import type { Descendant } from 'slate';
import { Editor, Element as SlateElement, Node as SlateNode, Text, Transforms } from 'slate';
import { defaultElement } from './utils';
import { deserializeHtmlElement } from './utils/deserializer';

export enum SavingIndicatorStatus {
    Saving = 'SAVING',
    Saved = 'SAVED',
    Error = 'ERROR',
    None = 'NONE',
}

export const HTML_LINE_BREAK = '<br/>';

export function getDefaultValue(val?: Descendant[] | null): SlateNode[] {
    return [
        {
            // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
            type: defaultElement,
            // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
            children: val ?? [{ text: '' }],
        },
    ];
}

function parseHTML(el: Element | Node): Descendant[] | SlateElement | Text | string | null {
    if (el.nodeType !== Node.ELEMENT_NODE) return '';

    const htmlElement = el as HTMLElement;
    // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
    return deserializeHtmlElement(htmlElement);
}

export function fromHTML(html: string): SlateNode[] {
    const parser = new DOMParser();
    const parsed = parseHTML(
        parser.parseFromString(html.replace(/>(\r\n|\r|\n)*</g, '><'), 'text/html').body
    ) as Descendant[];

    return parsed;
}

export function toHTML(value: SlateNode[]): string {
    function serialize(node: SlateNode): string {
        if (Text.isText(node)) {
            let openTags = '';
            let closeTags = '';

            if (node.bold === true) {
                openTags = `<strong>${openTags}`;
                closeTags = `${closeTags}</strong>`;
            }

            if (node.italic === true) {
                openTags = `<em>${openTags}`;
                closeTags = `${closeTags}</em>`;
            }

            return `${openTags}${sanitizeHtml(node.text)}${closeTags}`;
        }

        const children = node.children.map((n: SlateNode) => serialize(n)).join('');
        // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
        switch (node.type) {
            case defaultElement:
            case 'default-element':
                if (children === '') {
                    return HTML_LINE_BREAK;
                }

                return `<div>${children}</div>`;
            case 'link':
                return `<a href="${sanitizeHtml(
                    // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
                    node.url as string
                )}" target="_blank" rel="noopener noreferrer">${children}</a>`;
            case 'bulleted-list':
                return `<ul>${children}</ul>`;
            case 'list-item':
                return `<li>${children}</li>`;
            case 'numbered-list':
                return `<ol>${children}</ol>`;
            default:
                return children;
        }
    }

    // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
    return serialize({ children: value });
}

export function focusEditor(editor: Editor): void {
    Transforms.select(editor, {
        anchor: Editor.end(editor, []),
        focus: Editor.end(editor, []),
    });
}

export function isEmpty(value: SlateNode[]): boolean {
    const onlyOneLeafWithNoValue = value.length === 1 && SlateNode.string(value[0]).length === 0;
    // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
    return onlyOneLeafWithNoValue && SlateElement.isElement(value[0]) && value[0].type === defaultElement;
}
