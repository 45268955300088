import { themedColor } from '@lemonade-hq/cdk';
import styled from 'styled-components';

export const LightboxActionsWrapper = styled.div`
    .bo-lightbox-actions-container-bluis {
        width: 100%;
        background: ${themedColor('componentBackground')};
        border-radius: 0 0 5px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid ${themedColor('separator')};
        padding: 0 15px;
        z-index: 1;

        .bo-lightbox-error {
            text-align: left;
            white-space: pre-line;
            color: ${themedColor('primaryText')};
        }

        .bo-lightbox-actions {
            position: relative;
            display: flex;
            flex-flow: row;

            .bo-lightbox-action {
                display: inherit;
                font-weight: bold;
                font-size: 14px;
                padding: 15px 0;
                color: ${themedColor('primaryText')};
                text-transform: uppercase;
                user-select: none;

                + .bo-lightbox-action {
                    margin-left: 10px;
                }

                &.disabled {
                    color: ${themedColor('disabledText')};
                    cursor: initial;
                }
            }
        }

        &.embedded {
            padding: 0;
            overflow: hidden;
            position: absolute;
            bottom: 0;
            left: 0;

            .bo-lightbox-error {
                display: none;
            }

            .bo-lightbox-actions {
                width: 100%;

                .bo-lightbox-action {
                    flex-grow: 1;
                    flex-basis: 0;
                    padding: 0;

                    + .bo-lightbox-action {
                        margin-left: 0;
                    }
                }
            }
        }
    }
`;
