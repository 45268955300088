import { LabelValueTable, SummarySection } from '@lemonade-hq/blender-ui';
import {
    scopeDisplay,
    SettingInstanceDuration,
} from '../coverage-editions/Settings/SettingInstancesTable/SettingsTableShared';
import { SettingInstanceValues } from 'components/LoCo/common/components/CoverageSettings/SettingValues';
import { coInsuranceVariantDisplayName, unitDisplay } from 'components/LoCo/common/display-texts/setting-instance';
import { isSubLimitSettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import type { CoverageInstance, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

const getCustomDetails = (
    instance: SettingInstance
): { readonly label: string; readonly value: JSX.Element | string }[] => {
    let subLimit;
    switch (instance.type) {
        case SettingType.Limit:
            subLimit = isSubLimitSettingInstance(instance)
                ? [{ label: 'Sub-Limit of', value: instance.parentLimitTemplateCode }]
                : [];

            return [
                ...subLimit,
                { label: 'Scope', value: scopeDisplay(instance) },
                { label: 'Duration', value: <SettingInstanceDuration settingInstance={instance} /> },
                { label: 'Unit', value: unitDisplay(instance) },
            ];

        case SettingType.Deductible:
            return [
                { label: 'Scope', value: scopeDisplay(instance) },
                { label: 'Duration', value: <SettingInstanceDuration settingInstance={instance} /> },
                { label: 'Currency Unit', value: instance.currencyUnit },
            ];

        case SettingType.WaitingPeriod:
            return [{ label: 'Duration Unit', value: unitDisplay(instance) }];

        case SettingType.Coinsurance:
            return [{ label: 'Variant', value: coInsuranceVariantDisplayName(instance.variant) }];

        default:
            return [];
    }
};

export const SettingsGeneralDetailsSection: React.FC<{ readonly instance: SettingInstance }> = ({ instance }) => {
    const data = [
        { label: 'Values', value: <SettingInstanceValues settingInstance={instance} /> },
        { label: 'Description', value: instance.description },
        {
            label: 'Related Coverages',
            value: instance.relatedCoverages.map(coverage => coverage.name).join(', '),
        },
        ...getCustomDetails(instance),
    ];
    return (
        <SummarySection title="General Details">
            <LabelValueTable columnCount={1} data={data} />
        </SummarySection>
    );
};

export const CoveragesGeneralDetailsSection: React.FC<{ readonly instance: CoverageInstance }> = ({ instance }) => {
    return (
        <SummarySection title="General Details">
            <LabelValueTable
                columnCount={1}
                data={[
                    { label: 'Description', value: instance.description },
                    {
                        label: 'Related Settings',
                        value: instance.relatedSettings.map(settings => settings.name).join(', '),
                    },
                ]}
            ></LabelValueTable>
        </SummarySection>
    );
};

export const NoInstanceGeneralDetailsSection: React.FC<{ readonly description: string }> = ({ description }) => {
    return (
        <SummarySection title="General Details">
            <LabelValueTable columnCount={1} data={[{ label: 'Description', value: description }]}></LabelValueTable>
        </SummarySection>
    );
};
