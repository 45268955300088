import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';

const homeBlenderUrlResolver = getUrlResolver('home-blender');

export async function resendUpdatedPolicy(policyId: string): Promise<void> {
    const url = `/backoffice/policies/${policyId}/resend`;

    return axios.post(url).then(response => response.data);
}

export async function sendAddItemsEmail(policyId: string): Promise<void> {
    const url = `/backoffice/policies/${policyId}/send_valuable_items_email`;

    return axios.post(url).then(response => response.data);
}

export async function uploadValuableImage(file: FileList): Promise<{ data: { url: string } }> {
    const url = '/backoffice/scheduled_items/upload';
    const params = new FormData();

    params.append('upload', file[0]);

    return axios.post(url, params).then(res => res);
}

export interface AddedItem {
    attachments: { type: string; url: string }[];
    id: string;
    title: string;
    description?: string;
    type: string;
    subtype: string | null;
    value: string;
}

export async function addValuableItems({ items, policyId }: { items: AddedItem[]; policyId: string }): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/${policyId}/scheduled_items/add`);
    const body = {
        items,
    };

    return axios.post(url, body).then(res => res.data);
}

export async function calcValuableItemsPremium({
    items,
    policyId,
}: {
    items: AddedItem[];
    policyId: string;
}): Promise<[{ id: string; premiumDisplay: string }]> {
    const url = homeBlenderUrlResolver(`/api/v1/${policyId}/scheduled_items/calculate`);
    const body = {
        items,
    };

    return axios.post(url, body).then(res => res.data);
}

export async function cancelValuableItem({ itemId }: { itemId: string }): Promise<void> {
    const url = `/backoffice/scheduled_items/${itemId}/cancel`;

    const params = new URLSearchParams();

    params.append('item_id', itemId);

    return axios.post(url, params).then(res => res.data);
}

export async function editValuableItem({
    itemId,
    data,
}: {
    itemId: string;
    data: {
        title: string;
        description: string;
        value: string;
        attachments: {
            type: string;
            url: string;
        }[];
    };
}): Promise<null> {
    const url = `/backoffice/scheduled_items/${itemId}/update`;

    return axios.post(url, data).then(res => res.data);
}

export async function approveValuableItem(itemId: string): Promise<void> {
    const url = `/backoffice/scheduled_items/${itemId}/approve`;

    return axios.post(url).then(res => res.data);
}

export async function rejectValuableItem(itemId: string, reason: string): Promise<void> {
    const url = `/backoffice/scheduled_items/${itemId}/reject`;

    return axios.post(url, { reason }).then(res => res.data);
}

export async function deleteValuableItem(itemId: string): Promise<void> {
    const url = `/backoffice/scheduled_items/${itemId}/delete`;

    return axios.post(url).then(res => res.data);
}
