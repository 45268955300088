import { Dialog } from '@lemonade-hq/bluis';
import { Descendant, RichTextEditor } from '@lemonade-hq/bluiza';
import { font } from '@lemonade-hq/boutique';
import {
    htmlFromSlate,
    slateFromHtml,
    useRichTextEditor,
    useRichTextEditorState,
    
} from '@lemonade-hq/cdk';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useEmailSignature, useUpdateEmailSignature } from '../hooks/useSignatures';
import { getDefaultValue } from 'components/Bluis/RichTextEditor/helpers';

const StyledDialog = styled(Dialog)`
    input {
        border: none !important;
    }
`;

const Label = styled.label`
    text-align: left;
    ${font('main', { fontSize: '14px', fontWeight: 400 })};
    margin-bottom: 10px;
    width: 100%;
    display: block;
`;

const RichTextEditorStyled = styled(RichTextEditor)`
    padding: 20px;
`;

const SIGNATURE_MAX_LENGTH = 240;

interface EditSignatureDialogProps {
    readonly operatorId: string;
    readonly onClose: () => void;
}

const DEFAULT_VALUE = getDefaultValue() as Descendant[];

export const EditSignatureDialog: React.FC<React.PropsWithChildren<EditSignatureDialogProps>> = ({
    onClose,
    operatorId,
}) => {
    const editor = useRichTextEditor();
    const [value, setValue] = useRichTextEditorState({
        editor,
        defaultState: DEFAULT_VALUE,
    });
    const { mutate: updateEmailSignature } = useUpdateEmailSignature();
    const { data, isPending: isLoading } = useEmailSignature(operatorId);

    useEffect(() => {
        if (isLoading || data == null) return;

        const slate = slateFromHtml(data.signature !== '' ? data.signature : '<div></div>');

        setValue(slate);
    }, [data, isLoading, setValue]);

    const onSubmit = useCallback(() => {
        updateEmailSignature({ operatorId, signature: htmlFromSlate(value) });

        onClose();
    }, [operatorId, onClose, value, updateEmailSignature]);

    return (
        <StyledDialog
            actions={[
                { type: 'close', text: 'Cancel' },
                { type: 'submit', text: 'save', onClick: onSubmit },
            ]}
            onClose={onClose}
            showSpinner={isLoading}
            title="Edit Email Signature"
        >
            <Label htmlFor="signature">Email signature</Label>
            <RichTextEditorStyled
                disabled={isLoading}
                editor={editor}
                maxLength={SIGNATURE_MAX_LENGTH}
                onChange={setValue}
                placeholder="Make sure to include your phone extension or any PTO dates…"
                value={value}
            />
        </StyledDialog>
    );
};
