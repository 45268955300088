import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog, GENERAL_ERROR_MSG } from '@lemonade-hq/bluis';
import { Text } from '@lemonade-hq/cdk';
import { getDecisionTypeText, UnderwritingEntityType } from './underwritingUtils';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import { useArchiveExplanationDraft, useArchiveReasonDraft } from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

interface ArchiveUnderwritingDraftDialogProps {
    readonly onClose: () => void;
    readonly publicId: string;
    readonly decisionType: UnderwritingDecisionLifecycleContext;
    readonly entityType: UnderwritingEntityType;
}

export const ArchiveUnderwritingDraftDialog: React.FC<ArchiveUnderwritingDraftDialogProps> = ({
    onClose,
    publicId,
    decisionType,
    entityType,
}) => {
    const {
        mutateAsync: archiveReasonDraft,
        isPending: isLoadingArchiveReasonDraft,
        isError: isErrorArchiveReasonDraft,
    } = useArchiveReasonDraft();

    const {
        mutateAsync: archiveExplanationDraft,
        isPending: isLoadingArchiveExplanationDraft,
        isError: isErrorArchiveExplanationDraft,
    } = useArchiveExplanationDraft();

    const onArchiveDraft = async (): Promise<void> => {
        if (entityType === UnderwritingEntityType.Explanation) {
            await archiveExplanationDraft(publicId);
        } else {
            try {
                await archiveReasonDraft(publicId);
            } catch {
                return;
            }
        }

        onClose();
    };

    const actions: DialogAction[] = [
        {
            text: 'Cancel',
            type: 'close',
            onClick: onClose,
        },
        {
            text: 'Archive',
            type: 'submit',
            onClick: onArchiveDraft,
            disabled:
                isLoadingArchiveExplanationDraft ||
                isLoadingArchiveReasonDraft ||
                isErrorArchiveExplanationDraft ||
                isErrorArchiveReasonDraft,
        },
    ];

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={
                isErrorArchiveExplanationDraft
                    ? GENERAL_ERROR_MSG
                    : isErrorArchiveReasonDraft
                      ? 'Reason cannot be archived because there are 1+ editions which use it in their configuration'
                      : undefined
            }
            loading={isLoadingArchiveExplanationDraft || isLoadingArchiveReasonDraft}
            onClose={onClose}
            title={`Archive ${getDecisionTypeText(decisionType)} ${entityType}`}
        >
            <Text variant="body-md">{`Are you sure you want to archive this draft?`}</Text>
        </Dialog>
    );
};
