import { FederatedModule, LoadingSection } from '@lemonade-hq/bluis';
import loadable from '@loadable/component';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotesProvider } from 'bluis/Notes';

const AsyncCarClaimsList = loadable(
    async () => await import(/* webpackChunkName: "CarClaimsList" */ 'car/CarClaimList'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncCarClaim = loadable(async () => await import(/* webpackChunkName: "CarClaim" */ 'car/CarClaim'), {
    fallback: <LoadingSection />,
});

const CarPoliciesList = React.lazy(
    async () =>
        await import(/* webpackChunkName: CarPoliciesList */ 'car/PoliciesList').then(module => {
            return {
                default: module.PoliciesList,
            };
        })
);

const AsyncCarPolicy = loadable(async () => await import(/* webpackChunkName: "CarPolicy" */ 'car/CarPolicy'), {
    fallback: <LoadingSection />,
});

const AsyncEditCoverages = loadable(
    async () => await import(/* webpackChunkName: "EditCoverages" */ 'car/EditCoverages'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncCarQuote = loadable(async () => await import(/* webpackChunkName: "CarQuote" */ 'car/CarQuote'), {
    fallback: <LoadingSection />,
});

const AsyncCarTelematics = loadable(
    async () => await import(/* webpackChunkName: "CarTelematics" */ 'car/CarTelematics'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncCreateManualCarClaim = loadable(
    async () => await import(/* webpackChunkName: "CreateCarManualClaim" */ 'car/CreateManualCarClaim'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncCreateManualCarRoadsideAssistanceRequest = loadable(
    async () =>
        await import(/* webpackChunkName: "CreateRoadsideAssistanceRequest" */ 'car/CreateRoadsideAssistanceRequest'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncDrpManagementDashboard = loadable(
    async () => await import(/* webpackChunkName: "DrpManagementDashboard" */ 'car/DrpManagementDashboard'),
    {
        fallback: <LoadingSection />,
    }
);

const CarReviewsList = React.lazy(
    async () =>
        await import(/* webpackChunkName: “CarReviewsList” */ 'car/ReviewsList/ReviewsList').then(module => {
            return {
                default: module.ReviewsList,
            };
        })
);

const CarQuotesList = React.lazy(
    async () =>
        await import(/* webpackChunkName: “CarQuotesList” */ 'car/QuotesList').then(module => {
            return {
                default: module.QuotesList,
            };
        })
);

const CarEditions = React.lazy(
    async () =>
        await import(/* webpackChunkName: “Editions" */ 'components/Editions').then(module => {
            return {
                default: module.Editions,
            };
        })
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Car: React.FC<React.PropsWithChildren<unknown>> = () => (
    <NotesProvider
        analyticsParams={{
            notesIconClicked: {
                name: 'workflows.note.icon.clicked',
            },
            notesAccordionOpened: {
                name: 'workflows.note.add_note.clicked',
            },
            notesAccordionClosed: {
                name: 'workflows.note.close_editor.clicked',
            },
            noteSubmitted: {
                name: 'workflows.note.submitted',
            },
        }}
    >
        <SentryRoutes>
            <Route element={<AsyncCreateManualCarClaim />} path="claims/manual_claim" />
            <Route element={<AsyncCarClaim />} path="claims/:claimId/*" />
            <Route element={<AsyncCarClaimsList />} path="claims" />
            <Route
                element={
                    <FederatedModule>
                        <CarPoliciesList />
                    </FederatedModule>
                }
                path="policies"
            />
            <Route element={<AsyncCarPolicy />} path="policies/:policyId/*" />
            <Route element={<AsyncEditCoverages />} path="policies/:policyId/edit_coverages" />
            <Route
                element={
                    <FederatedModule>
                        <CarQuotesList />
                    </FederatedModule>
                }
                path="quotes"
            />
            <Route element={<AsyncCarQuote />} path="quotes/:quoteId/*" />
            <Route
                element={
                    <FederatedModule>
                        <CarEditions />
                    </FederatedModule>
                }
                path="editions"
            />
            <Route element={<AsyncCarTelematics />} path="telematics" />
            <Route
                element={<AsyncCreateManualCarRoadsideAssistanceRequest />}
                path="claims/manual_roadside_assistance"
            />
            <Route element={<AsyncDrpManagementDashboard />} path="claims/drp_management_dashboard" />
            <Route
                element={
                    <FederatedModule>
                        <CarReviewsList />
                    </FederatedModule>
                }
                path="reviews"
            />
        </SentryRoutes>
    </NotesProvider>
);

export default Car;
