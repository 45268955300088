import axios from 'axios';
import { ServiceNames, getUrlResolver } from '@lemonade-hq/bluiza';
import { PaymentProviderType } from 'models/Payment';

export interface PayInvoice {
    invoiceId: string;
    providerType: PaymentProviderType;
}

export async function payInvoice({ invoiceId, providerType: provider_type = PaymentProviderType.Stripe }: PayInvoice) {
    const url = `/backoffice/payouts/${invoiceId}/pay`;

    return axios.post(url, { provider_type }).then(({ data }) => data);
}

export async function cancelInvoice({ invoiceId }: { invoiceId: string }): Promise<void> {
    const url = `/backoffice/billing/invoices/${invoiceId}/cancel`;

    return axios.post(url);
}

export async function cancelTransaction({ transactionId, reason }: { transactionId: string; reason?: string }) {
    const url = `/backoffice/payout_transactions/${transactionId}/cancel`;

    return axios.post(url, { reason }).then(({ data }) => data);
}

export async function getSimulationPayoutsErrorReasons(
    paymentMethodType: string
): Promise<{ code: string; message: string }[]> {
    const url = `/backoffice/payout_transactions/simulate_payout_failure_reasons?payment_method_type=${paymentMethodType}`;

    return axios.get<{ data: { code: string; message: string }[] }>(url).then(({ data: { data } }) => data);
}

export async function simulatePayoutSuccess({ transactionId }: { transactionId: string }): Promise<null> {
    const url = `/backoffice/payout_transactions/${transactionId}/simulate_payout_success`;

    return axios.post(url).then(({ data }) => data);
}

export async function simulatePayoutFailure({
    transactionId,
    paymentMethodType,
    errorCode,
}: {
    transactionId: string;
    errorCode: string;
    paymentMethodType: string;
}): Promise<null> {
    const url = `/backoffice/payout_transactions/${transactionId}/simulate_payout_failure`;

    return axios.post(url, { error_code: errorCode, payment_method_type: paymentMethodType }).then(({ data }) => data);
}

const blenderGeneralUrlResolver = getUrlResolver({ service: ServiceNames.BlenderGeneral, fallbackMap: new Set() });

export async function cancelPayment(invoiceId: string): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/payouts/${invoiceId}/cancel`);

    return axios.post(url).then(({ data }) => data);
}
