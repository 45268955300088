import type * as avro from 'avsc';
import type { EnumArgument } from '../components/ExpressionSimpleEditor/expressionTypes';
import { ArgumentType } from '../components/ExpressionSimpleEditor/expressionTypes';
import type { InputFunction } from '../components/ExpressionSimpleEditor/operators';
import { isPrimitiveType } from '../helpers/schemaHelpers';

export function schemaPrimitiveTypeToTypeScriptType(schemaType: avro.schema.PrimitiveType): string {
    switch (schemaType) {
        case 'boolean':
            return 'boolean';
        case 'int':
        case 'float':
            return 'number';
        case 'string':
            return 'string';
        default:
            throw new Error(`Unsupported schema type: ${schemaType}`);
    }
}

export function schemaEnumTypeToTypescriptType({ name, symbols }: avro.schema.EnumType): string {
    return `enum ${name} {
    ${symbols.map(value => `${value} = '${value}',`).join('\n    ')}
}`;
}

function isEnumType(type: avro.Schema): type is avro.schema.EnumType {
    return typeof type === 'object' && 'type' in type && type.type === 'enum';
}

function getTypeOfRecordField(fieldType: avro.Schema): string {
    if (isEnumType(fieldType)) return fieldType.name;
    else if (isPrimitiveType(fieldType)) return schemaPrimitiveTypeToTypeScriptType(fieldType);
    else return `Unsupported field type`;
}

export function nameToTypescriptSafeName(name: string): string {
    // eslint-disable-next-line unicorn/prefer-string-replace-all
    return name.replace(/[^a-zA-Z0-9]/g, '_');
}

export function schemaRecordTypeToTypescriptType({ name, fields }: avro.schema.RecordType): string {
    const enumDefinitions = fields
        .map(field => field.type)
        .filter(isEnumType)
        .map(e => schemaEnumTypeToTypescriptType(e))
        .join('\n');

    const t = `${enumDefinitions}

type ${nameToTypescriptSafeName(name)} = {
    ${fields.map(({ name: fieldName, type }) => `"${fieldName}": ${getTypeOfRecordField(type)};`).join('\n    ')}
}`;

    return t;
}

export function generateAdditionalFunctions(functions: Record<string, InputFunction>): string {
    const enums: Record<string, EnumArgument> = {};

    let functionsLib = '';

    Object.keys(functions).forEach(functionName => {
        const functionObject = functions[functionName];
        let returnType = functionObject.returnType.type as string;

        if (functionObject.returnType.type === ArgumentType.Enum) {
            returnType = functionObject.returnType.enumName;
            enums[functionObject.returnType.enumName] = functionObject.returnType;
        }

        const argument = functionObject.argument;

        if (argument) {
            enums[argument.enumName] = argument;

            functionsLib += `function ${functionName}(i: ${argument.enumName}): ${returnType} {
                return i as unknown as ${returnType};
            };`;
        } else {
            functionsLib += `function ${functionName}(): ${returnType} {
                return {} as ${returnType};
            };`;
        }
    });

    const enumsLib = generateEnums(enums);

    return `${enumsLib}\n${functionsLib}`;
}

function generateEnums(enums: Record<string, EnumArgument>): string {
    return Object.keys(enums)
        .map(enumName => `type ${enumName} = ${enums[enumName].symbols.map(symbol => `'${symbol}'`).join(' | ')};`)
        .join('\n');
}
