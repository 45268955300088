import type { RuleLifecycleContext } from './BaseEdition';

export enum RuleEntityType {
    Coverage = 'coverage',
    Setting = 'setting',
}

export enum CoverageRuleType {
    InitialValue = 'initial_value',
    Restriction = 'restriction',
}

export interface CoverageRule {
    readonly publicId: string;
    readonly entityType: RuleEntityType;
    readonly entityCode: string;
    readonly ruleType: CoverageRuleType;
    readonly lifecycleContexts: RuleLifecycleContext[];
    readonly expression: string;
    readonly outcome: Outcome;
}

export enum RoundTo {
    Down = 'down',
    Nearest = 'nearest',
    Up = 'up',
}

export type Expression = string;

export enum SettingDefaultValueType {
    Highest = 'highest',
    Lowest = 'lowest',
}

export enum CoverageRestrictionValue {
    Forbid = 'forbid',
    Require = 'require',
}

export enum CoverageDefaultValue {
    Off = 'off',
    On = 'on',
}

export enum Treatment {
    Require = 'require',
    Forbid = 'forbid',
}

export enum RuleOutcomeType {
    CoverageDefaultValueOutcome = 'coverage_default_value_outcome',
    CoverageRestrictionValueOutcome = 'coverage_restriction_value_outcome',
    SettingDefaultValueOutcome = 'setting_default_value_outcome',
    SettingNearestToValueOutcome = 'setting_nearest_to_value_outcome',
    SettingExactValuesOutcome = 'setting_exact_values_outcome',
    SettingRangeValueOutcome = 'setting_range_value_outcome',
    MatchPreviousPolicyOutcome = 'match_previous_policy_outcome',
}

export interface CoverageDefaultValueOutcome {
    readonly type: RuleOutcomeType.CoverageDefaultValueOutcome;
    readonly value: CoverageDefaultValue;
}

export interface CoverageRestrictionValueOutcome {
    readonly type: RuleOutcomeType.CoverageRestrictionValueOutcome;
    readonly value: CoverageRestrictionValue;
}

export interface SettingDefaultValueOutcome {
    readonly type: RuleOutcomeType.SettingDefaultValueOutcome;
    readonly value: SettingDefaultValueType;
}

export interface SettingNearestToOutcome {
    readonly type: RuleOutcomeType.SettingNearestToValueOutcome;
    readonly expression: Expression;
    readonly roundTo?: RoundTo;
}

export interface SettingExactValuesOutcome {
    readonly type: RuleOutcomeType.SettingExactValuesOutcome;
    readonly values: Expression[];
    readonly treatment: Treatment;
}

export interface SettingRangeValueOutcome {
    readonly type: RuleOutcomeType.SettingRangeValueOutcome;
    readonly notBelow?: Expression;
    readonly notAbove?: Expression;
}

export interface MatchPreviousPolicyOutcome {
    readonly type: RuleOutcomeType.MatchPreviousPolicyOutcome;
}

export type Outcome =
    | CoverageDefaultValueOutcome
    | CoverageRestrictionValueOutcome
    | MatchPreviousPolicyOutcome
    | SettingDefaultValueOutcome
    | SettingExactValuesOutcome
    | SettingNearestToOutcome
    | SettingRangeValueOutcome;

export interface CoverageRuleDisplay extends CoverageRule {
    readonly outcomeDisplay: string;
    readonly isFiled: boolean;
}
