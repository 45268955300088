import { Banner, Card, Flex, Icon, spacing, Text, Tooltip } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import type { AttachmentDTO } from 'components/Attachments/types';

const DuplicationItem: FC<{ readonly attachment: AttachmentDTO }> = ({ attachment }) => (
    <Card alignItems="center" display="flex" justifyContent="space-between" variant="tertiary" withBorder={false}>
        <Text as="span" color="secondary" type="text-md">
            <Flex alignItems="center" gap={spacing.s04}>
                <Icon name="document" />
                <span>{attachment.fileName}</span>
            </Flex>
        </Text>
        <Tooltip
            content={
                <Flex flexDirection="column" gap={spacing.s04} minWidth="250px" padding={spacing.s06}>
                    {attachment.type != null && <span>Type: {attachment.type}</span>}
                    {attachment.description != null && <span>Description: {attachment.description}</span>}
                </Flex>
            }
        >
            <Banner
                iconName="info-circle-solid"
                iconSize="sm"
                title={
                    <Text as="span" color="secondary" fontWeight="semi-bold" type="text-sm">
                        File Info
                    </Text>
                }
                variant="info"
            />
        </Tooltip>
    </Card>
);

export const DuplicateAttachments: FC<{ readonly attachments: AttachmentDTO[] }> = ({ attachments }) => (
    <Flex flexDirection="column" gap={spacing.s08} pb={spacing.s24}>
        <Banner
            title="The following attachments already exist on the file so can’t be added:"
            variant="attention"
            withBackground={false}
            withBorder={false}
        />
        {attachments.map(attachment => (
            <DuplicationItem attachment={attachment} key={attachment.publicId} />
        ))}
    </Flex>
);
