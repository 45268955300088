import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import pluralize from 'pluralize';
import { ChangeAccordion } from '../ChangeAccordion';
import { findSetting } from './common';
import { getAttributesForSetting } from './setting-attributes';
import { getSettingTypeDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import type { AttributesUpdatedEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';

const zipOldWithNewAttributes = (
    newAttributes: LabelValueItem[],
    oldAttributes: LabelValueItem[]
): LabelValueItem[] => {
    const filteredNewAttributes: LabelValueItem[] = [];
    const filteredOldAttributes: LabelValueItem[] = [];

    for (let i = 0; i < newAttributes.length; i++) {
        if (newAttributes[i].value !== oldAttributes[i].value) {
            filteredNewAttributes.push(newAttributes[i]);
            filteredOldAttributes.push(oldAttributes[i]);
        }
    }

    return filteredNewAttributes.flatMap((newAttribute, i) => [newAttribute, filteredOldAttributes[i]]);
};

interface SettingAttributesUpdatedProps {
    readonly change: AttributesUpdatedEntry;
    readonly coveragesEdition: CoveragesEdition;
    readonly baseCoveragesEdition: CoveragesEdition;
}

export const SettingAttributesUpdated: React.FC<SettingAttributesUpdatedProps> = ({
    change,
    coveragesEdition,
    baseCoveragesEdition,
}) => {
    const product = useGetProductData();

    const baseSetting = findSetting(baseCoveragesEdition, change.metadata.referenceId);
    const setting = findSetting(coveragesEdition, change.metadata.referenceId);

    const attributes = getAttributesForSetting(setting, product, coveragesEdition.settings).map(
        (item): LabelValueItem => ({ label: `New ${item.label}`, value: item.value })
    );

    const baseAttributes = getAttributesForSetting(baseSetting, product, baseCoveragesEdition.settings).map(
        (item): LabelValueItem => ({ label: `Old ${item.label}`, value: item.value })
    );

    const items = zipOldWithNewAttributes(attributes, baseAttributes);

    return (
        <ChangeAccordion
            generalTabData={items}
            originalRules={null}
            ruleType="coverage"
            title={`${getSettingTypeDisplayName(setting.type)} ${pluralize('attribute', change.metadata.changedAttributes.length)} updated - ${setting.name}`}
            updatedRules={null}
        />
    );
};
