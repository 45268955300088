import { Spinner } from '@lemonade-hq/blender-ui';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Dialog from '@radix-ui/react-dialog';
import type { FC } from 'react';
import { Suspense } from 'react';
import { ChangesSummaryContent } from './ChangesSummaryContent';
import { BUIDialog } from 'components/LoCo/products/ProductMissionControl/ReleasesHistory';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { GetChangesLogForEditionParams } from 'queries/LoCo/Insurance/ChangesLogQueries';
import { useGetChangesLogAndEditions } from 'queries/LoCo/Insurance/ChangesLogQueries';

interface EditionChangesSummaryDialogContentProps {
    readonly editionCode: string;
    readonly baseEditionCode?: string;
    readonly editionType: EditionType;
}

function editionTypeToQueryKey(editionType: EditionType): keyof GetChangesLogForEditionParams {
    // eslint-disable-next-line default-case
    switch (editionType) {
        case EditionType.Coverages:
            return 'coveragesEditionCode';
        case EditionType.DigitalAgent:
            return 'digitalAgentEditionCode';
        case EditionType.UnderwritingFilters:
            return 'underwritingFiltersEditionCode';
        default:
            throw new Error(`Unsupported edition type: ${editionType}`);
    }
}
const EditionChangesSummaryDialogContent: FC<EditionChangesSummaryDialogContentProps> = ({
    editionCode,
    baseEditionCode,
    editionType,
}) => {
    const {
        data: { baseEditionInstances, changesLog, editionInstances },
    } = useGetChangesLogAndEditions({
        baseEditionCodes: { [editionTypeToQueryKey(editionType)]: baseEditionCode },
        editionCodes: { [editionTypeToQueryKey(editionType)]: editionCode },
    });

    return (
        <ChangesSummaryContent
            baseCoveragesEdition={baseEditionInstances.coveragesEdition}
            baseDigitalAgentEdition={baseEditionInstances.digitalAgentEdition}
            basePlatformSchemaRevision={null}
            baseProductSchemaRevision={null}
            baseUnderwritingFiltersEdition={baseEditionInstances.underwritingFiltersEdition}
            changes={changesLog.entries}
            coveragesEdition={editionInstances.coveragesEdition}
            digitalAgentEdition={editionInstances.digitalAgentEdition}
            platformSchemaRevision={null}
            productSchemaRevision={null}
            underwritingFiltersEdition={editionInstances.underwritingFiltersEdition}
        />
    );
};

interface EditionChangesSummaryDialogProps {
    readonly editionCode: string;
    readonly baseEditionCode?: string;
    readonly editionType: EditionType;
    readonly onClose: () => void;
}

export const EditionChangesSummaryDialog: FC<EditionChangesSummaryDialogProps> = ({
    editionCode,
    editionType,
    baseEditionCode,
    onClose,
}) => {
    return (
        <Dialog.Root
            onOpenChange={() => {
                onClose();
            }}
            open
        >
            <BUIDialog description="Changes log for Edition" title="Edition changes">
                <Suspense fallback={<Spinner size="xl" />}>
                    <EditionChangesSummaryDialogContent
                        baseEditionCode={baseEditionCode}
                        editionCode={editionCode}
                        editionType={editionType}
                    />
                </Suspense>
            </BUIDialog>
        </Dialog.Root>
    );
};
