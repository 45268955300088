import { Flex } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import { DialogStep } from '../Dialog/Dialog';
import { StyledInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';

export const CheckBoxContainer = styled.div`
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: 25px;
    gap: 6px;
`;

export const Step = styled(DialogStep)`
    padding: 40px 64px;
`;

export const StyledRadioWrapper = styled(Flex)`
    flex-direction: column;
    gap: 10px;
`;

export const StyledMultilineInputWrapper = styled(StyledInputWrapper)`
    > div {
        align-items: flex-start;
    }
`;

export enum ManageRuleStep {
    Entity = 'entity',
    RuleType = 'ruleType',
    Context = 'context',
    Segment = 'segment',
    Outcome = 'outcome',
}

export const OrderedRuleSteps: ManageRuleStep[] = [
    ManageRuleStep.Entity,
    ManageRuleStep.RuleType,
    ManageRuleStep.Context,
    ManageRuleStep.Segment,
    ManageRuleStep.Outcome,
];
