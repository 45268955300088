import { borderRadius, Button, darkThemeClass, ellipsis, Flex, Icon, spacing, Text } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import * as HoverCard from '@radix-ui/react-hover-card';
import * as Popover from '@radix-ui/react-popover';
import { clsx } from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';
import { DetectionBadge } from '../AttachmentGallery/Carousel/Detection';
import { attachmentPreviewWrapper, popoverActionContent, previewContent } from './Preview.css';
import { PreviewActionContent } from './PreviewActionsContent';
import { OpenInNewTab } from 'components/Attachments/AttachmentGallery/Carousel/OpenInNewTab';
import { MediaPreview } from 'components/Attachments/AttachmentGallery/MediaComponents/GalleryMedia';
import type { AttachmentActionType, AttachmentDTO } from 'components/Attachments/types';
import { getActionLabel, getAttachmentAnalyticsParam } from 'components/Attachments/utils';

interface PreviewProps {
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
}

const PreviewActions: FC<PreviewProps> = ({ attachment, entityPublicId, entityType }) => {
    const { actions } = attachment;
    const [currentAction, setCurrentAction] = useState<AttachmentActionType | undefined>();

    if (actions == null || actions.length === 0) return null;

    const filteredActions = actions.filter(action => action !== 'check_for_modifications' && action !== 'unarchive');

    return (
        <Flex gap={spacing.s08} position="relative" zIndex={3}>
            {filteredActions.map(action => (
                <Popover.Root key={action}>
                    <Popover.Trigger asChild>
                        <Button
                            label={getActionLabel(action)}
                            onClick={() => setCurrentAction(action)}
                            variant="secondary"
                        />
                    </Popover.Trigger>
                    <Popover.Content className={popoverActionContent} sideOffset={8}>
                        <Flex justifyContent="flex-end">
                            <Popover.Close asChild>
                                <Icon color="neutral7" name="x" size="sm" />
                            </Popover.Close>
                        </Flex>
                        <PreviewActionContent
                            action={currentAction}
                            attachment={attachment}
                            entityPublicId={entityPublicId}
                            entityType={entityType}
                        />
                    </Popover.Content>
                </Popover.Root>
            ))}
        </Flex>
    );
};

const PreviewData: FC<PreviewProps> = ({ attachment, entityPublicId, entityType }) => {
    return (
        <Flex gap={spacing.s08} justifyContent="space-between">
            <Flex alignItems="center" gap={spacing.s08}>
                <Text className={ellipsis} maxWidth="200px" type="text-md">
                    {attachment.fileName}
                </Text>
                <OpenInNewTab url={attachment.url} />
            </Flex>
            <DetectionBadge attachment={attachment} isScanning={false} />
            <PreviewActions attachment={attachment} entityPublicId={entityPublicId} entityType={entityType} />
        </Flex>
    );
};

const HoverCardPortal: FC<PreviewProps> = ({ attachment, entityPublicId, entityType }) => {
    return (
        <HoverCard.Portal>
            <HoverCard.Content className={previewContent} side="right">
                <Flex
                    className={clsx(darkThemeClass, attachmentPreviewWrapper, borderRadius({ borderRadius: 'sm' }))}
                    flexDirection="column"
                    gap={spacing.s08}
                >
                    <PreviewData attachment={attachment} entityPublicId={entityPublicId} entityType={entityType} />
                    <MediaPreview attachment={attachment} entityPublicId={entityPublicId} entityType={entityType} />
                </Flex>
            </HoverCard.Content>
        </HoverCard.Portal>
    );
};

export const AttachmentPreview: FC<PropsWithChildren<PreviewProps>> = ({
    children,
    attachment,
    entityPublicId,
    entityType,
}) => {
    const onOpen = (open: boolean): void => {
        if (open) {
            trackEvent('docs.gallery.hover', {
                ...getAttachmentAnalyticsParam({
                    attachment,
                    entityType,
                    entityId: entityPublicId,
                }),
                name: 'preview',
            });
        }
    };

    return (
        <HoverCard.Root onOpenChange={onOpen}>
            <HoverCard.Trigger>{children}</HoverCard.Trigger>
            <HoverCardPortal attachment={attachment} entityPublicId={entityPublicId} entityType={entityType} />
        </HoverCard.Root>
    );
};
