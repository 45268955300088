import { Card, Flex, Select, spacing, Spinner, Text } from '@lemonade-hq/blender-ui';
import { EmptySection } from '@lemonade-hq/bluis';
import type { FC } from 'react';
import { Suspense, useState } from 'react';
import * as styles from './ChangesSummary.css';
import { ChangesSummaryContent } from './ChangesSummaryContent';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import type { Release } from 'models/LoCo/Insurance/Release';
import { ReleaseStatus } from 'models/LoCo/Insurance/Release';
import { useGetEditionSetChangesLogDataSuspense } from 'queries/LoCo/Insurance/EditionSetQueries';
import { useGetReleaseEditionSetsSuspense } from 'queries/LoCo/Insurance/ReleasesQueries';

const ChangesByEditionSet: FC<{
    readonly editionSet: EditionSet;
}> = ({ editionSet }) => {
    const {
        data: {
            changesLog: { entries: changes },
            baseEditionSet,
            editionInstances: { coveragesEdition, digitalAgentEdition, underwritingFiltersEdition },
            baseEditionInstances: {
                coveragesEdition: baseCoveragesEdition,
                digitalAgentEdition: baseDigitalAgentEdition,
                underwritingFiltersEdition: baseUnderwritingFiltersEdition,
            },
        },
    } = useGetEditionSetChangesLogDataSuspense(editionSet);

    return (
        <ChangesSummaryContent
            baseCoveragesEdition={baseCoveragesEdition}
            baseDigitalAgentEdition={baseDigitalAgentEdition}
            basePlatformSchemaRevision={baseEditionSet?.platformSchemaRevision ?? null}
            baseProductSchemaRevision={baseEditionSet?.productSchemaRevision ?? null}
            baseUnderwritingFiltersEdition={baseUnderwritingFiltersEdition}
            changes={changes}
            coveragesEdition={coveragesEdition}
            digitalAgentEdition={digitalAgentEdition}
            platformSchemaRevision={editionSet.platformSchemaRevision}
            productSchemaRevision={editionSet.productSchemaRevision}
            underwritingFiltersEdition={underwritingFiltersEdition}
        />
    );
};

export const EditionSetsChangesSummaryContent: React.FC<{ readonly release: Release }> = ({ release }) => {
    const { data: editionSets } = useGetReleaseEditionSetsSuspense(release.publicId);
    const [selectedEditionSetCode, setSelectedEditionSetCode] = useState<string | null>(editionSets[0]?.code ?? null);
    const editionSet = editionSets.find(e => e.code === selectedEditionSetCode);

    if (editionSet === undefined) {
        throw new Error(`${selectedEditionSetCode} not found in edition sets`);
    }

    const message =
        editionSet.baseEditionSetCode == null
            ? `Edition set ${editionSet.version} is the first edition set for this product and introduces the following functionality:`
            : editionSet.baseEditionSetVersion != null
              ? `Edition set ${editionSet.version} introduces the following changes, when compared to edition set ${editionSet.baseEditionSetVersion}:`
              : `Edition set ${editionSet.version} introduces the following changes:`;

    return (
        <Flex flexDirection="column" gap={spacing.s08}>
            <div className={styles.selectWrapper}>
                <Select
                    label="Select Edition Set"
                    onChange={value => setSelectedEditionSetCode(value)}
                    options={editionSets.map(e => ({ label: `Edition set ${e.version}`, value: e.code }))}
                    placeholder={'Select edition set'}
                    selectedKey={selectedEditionSetCode}
                />
            </div>
            <Text>{message}</Text>
            {selectedEditionSetCode !== null && <ChangesByEditionSet editionSet={editionSet} />}
        </Flex>
    );
};

export const EditionSetsChangesSummary: React.FC<{ readonly release: Release }> = ({ release }) => {
    if (release.status === ReleaseStatus.Cancelled) {
        return <EmptySection>Release was cancelled, no changes to display</EmptySection>;
    }

    return (
        <Flex flexDirection="column" gap={spacing.s32}>
            <div>
                <Text type="h3">Changes Summary</Text>
                <Text>Review the changes being introduced with this release:</Text>
            </div>
            <Card>
                <Suspense fallback={<Spinner size="sm" />}>
                    <EditionSetsChangesSummaryContent release={release} />
                </Suspense>
            </Card>
        </Flex>
    );
};
