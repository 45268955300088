import { FormInputWrapper, InlineButton, themedColor } from '@lemonade-hq/bluis';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { RatersDialogContent } from './RatersDialogContent';
import { FormWrapper, Input } from './shared';

type ShowAgentPasswordProps = {
    readonly username: string;
    readonly onReset: () => void;
};

const Description = styled.p`
    margin-bottom: 0;
    text-align: center;
`;

const ResetPasswordSection = styled.div`
    margin-top: 24px;
    text-align: center;
    color: ${themedColor('secondaryText')};
`;

export const ShowAgentPassword: React.FC<React.PropsWithChildren<ShowAgentPasswordProps>> = ({ username, onReset }) => {
    return (
        <RatersDialogContent>
            <Description>
                Use your Lemonade username and password to connect a rater.
                <br />
                If you forgot your password, you can reset it.
            </Description>
            <FormWrapper>
                <FormInputWrapper
                    htmlFor="connect-rater-form-username"
                    id="connect-rater-form-username"
                    label="Username"
                >
                    <Input id="connect-rater-form-username" name="username" readOnly value={username} />
                </FormInputWrapper>
                <FormInputWrapper
                    htmlFor="connect-rater-form-password"
                    id="connect-rater-form-password"
                    label="Password"
                >
                    <Input id="connect-rater-form-password" name="password" readOnly type="password" value="********" />
                </FormInputWrapper>
            </FormWrapper>
            <ResetPasswordSection>
                Forgot your password? <InlineButton onClick={onReset}>Reset password</InlineButton>
            </ResetPasswordSection>
        </RatersDialogContent>
    );
};

type SetAgentPasswordProps = {
    readonly onSuccess: (password: string) => void;
    readonly onError: (message: string) => void;
    readonly disabled?: boolean;
};

export const SetAgentPassword: React.FC<React.PropsWithChildren<SetAgentPasswordProps>> = ({
    onError,
    onSuccess,
    disabled,
}) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        if (newPassword === confirmPassword) {
            onError('');
            onSuccess(newPassword);
        } else {
            onError('Passwords do not match.');
        }
    }, [newPassword, confirmPassword, onError, onSuccess]);

    return (
        <RatersDialogContent>
            <Description>
                Please choose a new passsword.
                <br />
                Once you&apos;ve saved it, you&apos;ll be able to connect a rater.
            </Description>
            <FormWrapper>
                <FormInputWrapper
                    htmlFor="connect-rater-form-new-password"
                    id="connect-rater-form-new-password"
                    label="New Password"
                >
                    <Input
                        autoComplete="new-password"
                        disabled={disabled}
                        name="new-password"
                        onChange={e => setNewPassword(e.target.value)}
                        type="password"
                        value={newPassword}
                    />
                </FormInputWrapper>
                <FormInputWrapper
                    htmlFor="connect-rater-form-confirm-password"
                    id="connect-rater-form-confirm-password"
                    label="Confirm Password"
                >
                    <Input
                        autoComplete="new-password"
                        disabled={disabled}
                        name="confirm-password"
                        onChange={e => setConfirmPassword(e.target.value)}
                        type="password"
                        value={confirmPassword}
                    />
                </FormInputWrapper>
            </FormWrapper>
        </RatersDialogContent>
    );
};
