import { clsx } from 'clsx';
import type { FC, HTMLAttributes } from 'react';
import { forwardRef, useRef } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import type { TextVariants } from '../../base/Text/Text.css';
import * as checkboxStyles from '../Checkbox/Checkbox.css';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/types';
import { Tooltip } from '../Tooltip/Tooltip';
import * as styles from './ListItem.css';
import type { Variants as CheckboxVariants } from '@lemonade-hq/blender-ui';
import { spacing, useIsOverflowing } from '@lemonade-hq/blender-ui';

export interface ListItemProps<TItemIdentifier extends number | string = number | string>
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  readonly label: JSX.Element | string;
  readonly sideLabel?: JSX.Element | string;
  readonly secondaryLabel?: JSX.Element | string;
  readonly id: TItemIdentifier;
  readonly icon?: IconName;
  readonly disabled?: boolean;
  readonly onSelect?: () => void;
  readonly color?: Exclude<TextVariants, undefined>['color'];
  readonly checked?: boolean;
  readonly variant?: CheckboxVariants;
}

export const ListItemCheckbox: FC<{
  readonly checked: boolean;
  readonly variant: CheckboxVariants;
}> = ({ checked, variant }) => {
  return (
    <div className={styles.listItemCheckbox({ variant })} data-selected={checked ? true : undefined}>
      {checked && <Icon className={checkboxStyles.icon()} name="check" size="sm" />}
    </div>
  );
};

export const ListItemContent: FC<
  Pick<ListItemProps, 'checked' | 'color' | 'icon' | 'label' | 'secondaryLabel' | 'sideLabel' | 'variant'>
> = ({ icon, label, sideLabel, secondaryLabel, color, checked, variant = 'neutral' }) => {
  const ref = useRef<HTMLElement>(null);
  const isOverflowing = useIsOverflowing(ref.current);

  return (
    <Flex flexDirection="column" gap={spacing.s04} width="100%">
      <Flex alignItems="center" gap={spacing.s08} justifyContent="flex-start" width="100%">
        {checked !== undefined && <ListItemCheckbox checked={checked} variant={variant} />}
        {icon && <Icon className={styles.listItemIcon} name={icon} size="sm" />}
        <Tooltip content={label} disabled={!isOverflowing}>
          <Text className={styles.listItemLabel} color={color} ref={ref}>
            {label}
          </Text>
        </Tooltip>
        {sideLabel != null && (
          <Text className={styles.listItemSideLabel} marginInlineStart="auto">
            {sideLabel}
          </Text>
        )}
      </Flex>
      {secondaryLabel != null && (
        <Flex alignItems="center" gap={spacing.s08} justifyContent="flex-start" width="100%">
          {icon && <Icon className={styles.listItemIcon} name="empty" size="sm" />}
          <Text className={styles.listItemLabel} color="secondary">
            {secondaryLabel}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export const ListItem = forwardRef<HTMLDivElement, ListItemProps>(
  (
    {
      disabled,
      label,
      onSelect,
      sideLabel,
      secondaryLabel,
      icon,
      id,
      className: externalClassName,
      color,
      checked,
      variant = 'neutral',
      ...props
    },
    ref,
  ) => {
    return (
      <Flex
        className={clsx(styles.listItem, externalClassName)}
        id={id.toString()}
        key={id}
        onChange={onSelect}
        ref={ref}
        {...props}
      >
        <ListItemContent
          checked={checked}
          color={color}
          icon={icon}
          label={label}
          secondaryLabel={secondaryLabel}
          sideLabel={sideLabel}
          variant={variant}
        />
      </Flex>
    );
  },
);
