export class SignalAbortError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'SignalAbortError';
    }
}

export async function sleepWithSignal(ms: number, { signal }: { readonly signal?: AbortSignal } = {}): Promise<void> {
    await new Promise((resolve, reject) => {
        const timeoutId = window.setTimeout(resolve, ms);
        signal?.addEventListener('abort', () => {
            window.clearTimeout(timeoutId);
            reject(new SignalAbortError('The operation was aborted.'));
        });
    });
}
