import type { FormProps } from '@lemonade-hq/blender-ui';
import { Flex, FormLayout, FormProvider, generateTypedFormComponents, spacing, useForm } from '@lemonade-hq/blender-ui';
import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { pick } from '@lemonade-hq/maschema-schema';
import { va } from '@lemonade-hq/maschema-validations-ui';
import type { Tool, ToolsRevision } from '@lemonade-hq/persisted-tools';
import { clientToolSchema, ToolMode } from '@lemonade-hq/persisted-tools';
import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useCreateTool } from '../../persisted_tools.queries';
import { displayError } from '../../shared/tool.helpers';

interface CreateToolDialogProps {
    readonly onClose: () => void;
    readonly onToolCreated: (newToolPublicId: string) => void;
    readonly toolsRevision?: ToolsRevision<Tool>;
    readonly baseTool?: Tool;
}

const createToolSchema = pick(clientToolSchema, 'name');

const formConfig = (existingToolsNames: string[]): FormProps<typeof createToolSchema>['initialConfig'] => ({
    additionalValidations: {
        name: [va.noneOf(existingToolsNames, { error: 'Tool name already exist' })],
    },
});

const { InputGroup } = generateTypedFormComponents<typeof createToolSchema>();

const DialogContent: FC<PropsWithChildren<CreateToolDialogProps>> = ({
    onClose,
    onToolCreated: onSubmit,
    toolsRevision,
    baseTool,
}) => {
    const { mutateAsync: createTool, isPending } = useCreateTool({
        toolsRevisionPublicId: toolsRevision?.publicId ?? '',
    });
    const {
        values,
        validationResults: { valid },
    } = useForm<typeof createToolSchema>();

    const isCloneTool = baseTool != null;
    const handleSubmit = async (): Promise<void> => {
        try {
            const newToolData: Tool | object = isCloneTool
                ? structuredClone({ ...baseTool, mode: ToolMode.InProgress, publicId: undefined })
                : {};
            const newTool = await createTool({ ...newToolData, name: values.name ?? '' });
            onSubmit(newTool.name);
        } catch (e) {
            displayError('Error creating tool')(e);
        }
    };

    const actions: DialogAction[] = [
        {
            type: 'close',
            text: 'cancel',
        },
        {
            type: 'submit',
            disabled: !valid,
            text: 'Create Tool',
            onClick: handleSubmit,
        },
    ];

    const dialogProps = {
        actions,
        closeOnOutsideClick: true,
        loading: isPending,
        onClose,
        title: isCloneTool ? 'Clone tool' : 'Create new tool',
    };

    return (
        <Dialog {...dialogProps}>
            <Flex flexDirection="column" gap={spacing.s20} height="7.2rem">
                <FormLayout>
                    <InputGroup
                        inputComponent="Input"
                        label={`Give your ${isCloneTool ? 'cloned' : 'new'} tool a clear, distinct name`}
                        placeholder="Tool name"
                        schemaKey="name"
                    />
                </FormLayout>
            </Flex>
        </Dialog>
    );
};

export const ToolNamingDialog: FC<CreateToolDialogProps> = ({ onClose, onToolCreated, toolsRevision, baseTool }) => {
    const existingToolsNames = useMemo(() => toolsRevision?.tools.map(t => t.name) ?? [], [toolsRevision]);

    return (
        <FormProvider initialConfig={formConfig(existingToolsNames)} schema={createToolSchema}>
            <DialogContent
                baseTool={baseTool}
                onClose={onClose}
                onToolCreated={onToolCreated}
                toolsRevision={toolsRevision}
            />
        </FormProvider>
    );
};
