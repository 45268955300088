import type { DragEndEvent } from '@dnd-kit/core';
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useCallback } from 'react';

interface SortableDndContextProps {
  readonly children: React.ReactNode;
  readonly itemIds: string[];
  readonly onDragEnd: (oldIndex: number, newIndex: number, items: string[]) => void;
  readonly direction?: 'horizontal' | 'vertical';
  readonly disabled?: boolean;
}

function reorderItems(
  items: string[],
  event: DragEndEvent,
): {
  readonly reorderedItemsIds: string[];
  readonly oldIndex: number;
  readonly newIndex: number;
} | null {
  const { active, over } = event;

  if (over == null || active.id === over.id) {
    return null;
  }

  const oldIndex = items.indexOf(active.id.toString());
  const newIndex = items.indexOf(over.id.toString());

  return {
    reorderedItemsIds: arrayMove(items, oldIndex, newIndex),
    oldIndex,
    newIndex,
  };
}

export const SortableDndContext = ({
  children,
  itemIds,
  onDragEnd,
  direction = 'vertical',
  disabled,
}: SortableDndContextProps): React.ReactNode => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        tolerance: 100,
        delay: 150,
      },
    }),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const reorderResult = reorderItems(itemIds, event);
      if (reorderResult == null) return;

      const { oldIndex, newIndex, reorderedItemsIds } = reorderResult;

      onDragEnd(oldIndex, newIndex, reorderedItemsIds);
    },
    [itemIds, onDragEnd],
  );

  const config = {
    collisionDetection: closestCenter,
    onDragEnd: handleDragEnd,
    sensors,
    modifiers: [restrictToParentElement],
    strategy: direction === 'horizontal' ? horizontalListSortingStrategy : verticalListSortingStrategy,
  };

  if (disabled) return children;

  return (
    <DndContext {...config}>
      <SortableContext disabled={disabled} items={itemIds} strategy={config.strategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};
