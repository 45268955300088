import type { FC } from 'react';
import { NonExpandableChange } from '../NonExpandableChange';
import { findCoverage } from './common';
import type { ReferenceIdEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';

interface CoverageConditionStatusProps {
    readonly baseCoveragesEdition: CoveragesEdition;
    readonly change: ReferenceIdEntry;
    readonly condition: 'optional' | 'required';
}

export const CoverageConditionStatus: FC<CoverageConditionStatusProps> = ({
    change,
    baseCoveragesEdition,
    condition,
}) => {
    const changedCoverage = findCoverage(baseCoveragesEdition, change.metadata.referenceId);

    return <NonExpandableChange title={`Coverage set to ${condition} - ${changedCoverage.name}`} />;
};
