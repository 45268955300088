/* eslint-disable jsx-a11y/anchor-is-valid */
import { isServerNav } from '@lemonade-hq/bluiza';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ConditionalLinkProps {
    url: string;
    onClick?: () => void;
}

const StyledLink = styled(Link)`
    text-decoration: none !important;
    color: inherit !important;

    &:last-child {
        * {
            border-bottom: none !important;
        }
    }
`;

const StyleAnchor = styled.a`
    text-decoration: none !important;
    color: inherit !important;

    &:last-child {
        * {
            border-bottom: none !important;
        }
    }
`;

const ConditionalLink: React.FC<React.PropsWithChildren<ConditionalLinkProps>> = ({ children, url, onClick }) => {
    const isSN = isServerNav(url);

    return isSN ? (
        <StyleAnchor href={url} onClick={onClick}>
            {children}
        </StyleAnchor>
    ) : (
        <StyledLink to={url} onClick={onClick}>
            {children}
        </StyledLink>
    );
};

export default ConditionalLink;
