export enum PlaygroundURLParam {
    ToolsRevisionPublicId = 'toolsRevisionPublicId',
    ToolName = 'toolName',
    OlderToolsRevisionPublicId = 'olderToolsRevisionPublicId',
    NewerToolsRevisionPublicId = 'newerToolsRevisionPublicId',
}

export type PlaygroundURLParams = Record<PlaygroundURLParam, string>;

// base
export const PLAYGROUND_PATH_NAMESPACE = 'playground';
export const PLAYGROUND_FULL_PATH_PREFIX = `/backoffice/${PLAYGROUND_PATH_NAMESPACE}`;

// comparison
export const PLAYGROUND_COMPARE_BASE_PATH = '/compare';
export const PLAYGROUND_COMPARE_PATH_WITH_PARAMS = `${PLAYGROUND_COMPARE_BASE_PATH}/:${PlaygroundURLParam.OlderToolsRevisionPublicId}/with/:${PlaygroundURLParam.NewerToolsRevisionPublicId}`;
export const PLAYGROUND_COMPARE_PATH_WITH_PARAMS_AND_TOOL = `${PLAYGROUND_COMPARE_PATH_WITH_PARAMS}/:${PlaygroundURLParam.ToolName}`;
export const MISSING_TOOLS_REVISION_ID_MARKER = '<MISSING>';

// editor
export const PLAYGROUND_PATH_WITH_TOOL = `/:${PlaygroundURLParam.ToolsRevisionPublicId}/:${PlaygroundURLParam.ToolName}?`;
export const PLAYGROUND_PATH_WITHOUT_TOOL = `/:${PlaygroundURLParam.ToolsRevisionPublicId}`;
