import axios from 'axios';
import { EntityTypes } from '@lemonade-hq/bluiza';
import { Note, NoteClaimType, NoteCreateRequest, NoteType, PaginatedNotes } from 'models/Interaction';
import { Recipient } from 'models/Recipient';
import { getUrlResolver } from 'commons/UrlResolver';

const blenderGeneralUrlResolver = getUrlResolver('blender-general');

export async function assignInteraction({
    interactionPublicId,
    entityType,
    entityId,
}: {
    interactionPublicId: string;
    entityType: EntityTypes;
    entityId: string;
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/interactions/mark_as_related`);

    return await axios.post(url, { entityType, entityId, interactionPublicId }).then(response => response.data.data);
}

export async function dismissInteraction({
    interactionPublicId,
    entityType,
    entityId,
}: {
    interactionPublicId: string;
    entityType: EntityTypes;
    entityId: string;
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/interactions/mark_as_not_related`);

    return await axios.post(url, { entityType, entityId, interactionPublicId }).then(response => response.data.data);
}

export async function manualResponseInteraction({
    interactionPublicId,
    entityId,
    entityType,
    medium,
    body,
}: {
    interactionPublicId: string;
    entityType: EntityTypes;
    entityId: string;
    medium: string;
    body: string;
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/interactions/manual_responses`);

    return await axios
        .post(url, { entityType, entityId, interactionPublicId, medium, body })
        .then(response => response.data.data);
}

export async function addCustomRecipient(
    recipient: Pick<Recipient, 'email' | 'name' | 'role'>,
    entityType: EntityTypes,
    entityId: string
): Promise<Recipient> {
    const url = blenderGeneralUrlResolver(`/api/v1/interactions/entity_parties`);

    return await axios
        .post(url, {
            entityType,
            entityPublicId: entityId,
            name: recipient.name,
            role: recipient.role,
            email: recipient.email,
        })
        .then(response => response.data.data);
}

export async function removeCustomRecipient({ recipientId }: { recipientId: string }): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/interactions/entity_parties/${recipientId}`);

    return await axios.delete(url);
}

// Notes Section
const NOTES_BASE_URL = blenderGeneralUrlResolver('/api/v1/notes/');

export async function createNote({ note }: { note: NoteCreateRequest }): Promise<null> {
    return await axios.post(NOTES_BASE_URL, note).then(response => response.data.data);
}

export async function getNote(noteId: string): Promise<Note> {
    const url = `${NOTES_BASE_URL}${noteId}`;

    return await axios.get<{ data: Note }>(url).then(response => response.data.data);
}

export async function saveNote(noteId: string, content: string): Promise<null> {
    const url = `${NOTES_BASE_URL}${noteId}`;

    return await axios.patch(url, { content }).then(response => response.data.data);
}

export async function getNoteByClaim({
    claimId,
    entityType,
    noteType,
}: {
    claimId: string;
    entityType: NoteClaimType;
    noteType?: NoteType;
}): Promise<PaginatedNotes> {
    const params = {
        entityPublicId: claimId,
        entityType,
        ...(Boolean(noteType) && { noteType }),
    };

    return await axios.get(NOTES_BASE_URL, { params }).then(response => response.data.data);
}
