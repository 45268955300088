import { VersionType } from 'models/LoCo/Insurance/BaseEdition';
import type { Insight } from 'models/LoCo/Insurance/ReleaseRolloutInsightsReport';
import { RolloutInsightTopic, TopicInsight } from 'models/LoCo/Insurance/ReleaseRolloutInsightsReport';

export const topicsTitlesMap: Record<RolloutInsightTopic, string> = {
    [RolloutInsightTopic.PotentiallyExpiringQuotes]: 'active quotes from older edition sets',
    [RolloutInsightTopic.RenewalPoliciesRefresh]: 'renewal policies have already been created from older edition sets',
    [RolloutInsightTopic.ActiveQuotes]: 'active quotes should receive this minor update',
    [RolloutInsightTopic.UpcomingRenewalsPolicies]: 'upcoming renewals should receive this minor update',
    [RolloutInsightTopic.ActivePolicies]:
        'existing policies (excluding upcoming renewals) should receive this minor update',
};

export const topicsSubtitlesMap: Record<RolloutInsightTopic, string[]> = {
    [RolloutInsightTopic.PotentiallyExpiringQuotes]: [
        'There are no active quotes with effective dates later than the new business effective date of the release.',
        'These quotes have effective dates later than the new business effective date of the release. Unless they are expired or edited, they may be purchased on older edition set.',
    ],
    [RolloutInsightTopic.RenewalPoliciesRefresh]: [
        'No Renewal Policies have been created yet with effective dates later than the renewal effective date of the release.',
        'These renewals have effective dates after the renewal effective date of the release, but will remain on their older edition set. The renewal may or may not have been communicated already to the policyholder.',
    ],
    [RolloutInsightTopic.ActiveQuotes]: [
        'There are no active quotes which would be directly impacted by this release.',
        'These quotes are from earlier minor versions of the same major edition set series for which a new minor version is being released.',
    ],
    [RolloutInsightTopic.UpcomingRenewalsPolicies]: [
        'There are no upcoming renewals which would be directly impacted by this release.',
        'These upcoming renewals are from earlier minor versions of the same major edition set series for which a new minor version is being released.',
    ],
    [RolloutInsightTopic.ActivePolicies]: [
        'There are no existing policies which would be directly impacted by this release.',
        'These policies are from earlier minor versions of the same major edition set series for which a new minor version is being released.',
    ],
};

export const getTopics2ndTitle = (
    topic: RolloutInsightTopic,
    metCriteriaCount: number,
    metCriteriaPercent: number
): JSX.Element | string => {
    switch (topic) {
        case RolloutInsightTopic.PotentiallyExpiringQuotes:
            if (metCriteriaCount === 0) {
                return 'None of these quotes would receive a different price or be declined on the new edition set.';
            } else {
                return (
                    <>
                        <b>
                            {metCriteriaCount} ({metCriteriaPercent}%)
                        </b>
                        &nbsp; of them would receive a different price or be declined on the new edition set.
                    </>
                );
            }

        case RolloutInsightTopic.RenewalPoliciesRefresh:
            if (metCriteriaCount === 0) {
                return 'None of these renewals would receive a different price or be non-renewed on the new edition set.';
            } else {
                return (
                    <>
                        <b>
                            {metCriteriaCount} ({metCriteriaPercent}%)
                        </b>
                        &nbsp; of them would receive a different price or be non-renewed on the new edition set.
                    </>
                );
            }

        case RolloutInsightTopic.ActiveQuotes:
            if (metCriteriaCount === 0) {
                return 'All of these quotes are currently compatible and can be transitioned seamlessly to the new Edition Set once it is published and effective.';
            } else {
                return (
                    <>
                        <b>
                            {metCriteriaCount} ({metCriteriaPercent}%)
                        </b>
                        &nbsp; of them have incompatibilities which must be resolved before they can receive the update:
                    </>
                );
            }

        case RolloutInsightTopic.UpcomingRenewalsPolicies:
            if (metCriteriaCount === 0) {
                return 'All of these upcoming renewals are currently compatible and can be transitioned seamlessly to the new Edition Set once it is published and effective.';
            } else {
                return (
                    <>
                        <b>
                            {metCriteriaCount} ({metCriteriaPercent}%)
                        </b>
                        &nbsp; of them have incompatibilities which must be resolved before they can receive the update:
                    </>
                );
            }

        case RolloutInsightTopic.ActivePolicies:
            if (metCriteriaCount === 0) {
                return 'All of these policies are currently compatible and can be transitioned seamlessly to the new Edition Set once it is published and effective.';
            } else {
                return (
                    <>
                        <b>
                            {metCriteriaCount} ({metCriteriaPercent}%)
                        </b>
                        &nbsp; of them have incompatibilities which must be resolved before they can receive the update:
                    </>
                );
            }

        default:
            return '';
    }
};

export const insightsMap: Record<RolloutInsightTopic, Partial<Record<TopicInsight, string>>> = {
    [RolloutInsightTopic.PotentiallyExpiringQuotes]: {
        [TopicInsight.Declined]: 'would be declined per the new underwriting filters',
        [TopicInsight.DifferentPrice]: 'would receive a different price per the new rates',
    },
    [RolloutInsightTopic.RenewalPoliciesRefresh]: {
        [TopicInsight.Declined]: 'would be declined per the new Underwriting Filters edition',
        [TopicInsight.DifferentPrice]: 'would receive a different price from the new Rating edition',
    },
    [RolloutInsightTopic.ActiveQuotes]: {
        [TopicInsight.DifferentPrice]: 'would receive a different price per the new rates',
        [TopicInsight.MissingRequiredCoverages]:
            'are missing a coverage which is required per the new Coverages edition',
        [TopicInsight.UnsupportedCoverage]: 'have a coverage which is not supported in the new Coverages edition',
        [TopicInsight.MissingRequiredSettings]:
            'are missing a value for a setting they are required to have in the new Coverages edition',
        [TopicInsight.UnsupportedSettings]: 'have a setting which is not supported in the new Coverages edition',
        [TopicInsight.IrrelevantSettings]: 'have a setting which is not related to any of their selected Coverages',
        [TopicInsight.UnsupportedSettingValues]:
            'have a setting value which is not supported in the new Coverages edition',
    },
    [RolloutInsightTopic.UpcomingRenewalsPolicies]: {
        [TopicInsight.DifferentPrice]: 'would receive a different price per the new rates',
        [TopicInsight.MissingRequiredCoverages]:
            'are missing a coverage which is Required per the new Coverages edition',
        [TopicInsight.UnsupportedCoverage]: 'have a coverage which is not supported in the new Coverages edition',
        [TopicInsight.MissingRequiredSettings]:
            'are missing a value for a setting they are required to have in the new Coverages edition',
        [TopicInsight.UnsupportedSettings]: 'have a setting which is not supported in the new Coverages edition',
        [TopicInsight.IrrelevantSettings]: 'have a setting which is not related to any of their selected Coverages',
        [TopicInsight.UnsupportedSettingValues]:
            'have a setting value which is not supported in the new Coverages edition',
    },
    [RolloutInsightTopic.ActivePolicies]: {
        [TopicInsight.DifferentPrice]: 'would receive a different price per the new rates',
        [TopicInsight.MissingRequiredCoverages]:
            'are missing a coverage which is Required per the new Coverages edition',
        [TopicInsight.UnsupportedCoverage]: 'have a coverage which is not supported in the new Coverages edition',
        [TopicInsight.MissingRequiredSettings]:
            'are missing a value for a setting they are required to have in the new Coverages edition',
        [TopicInsight.UnsupportedSettings]: 'have a setting which is not supported in the new Coverages edition',
        [TopicInsight.IrrelevantSettings]: 'have a setting which is not related to any of their selected Coverages',
        [TopicInsight.UnsupportedSettingValues]:
            'have a setting value which is not supported in the new Coverages edition',
    },
};

export const getTopicBannerText = (
    topic: RolloutInsightTopic,
    insights: Insight[],
    rolloutStrategy: VersionType
): string | null => {
    switch (topic) {
        case RolloutInsightTopic.PotentiallyExpiringQuotes:
            if (insights.length > 0) {
                return 'To expire these quotes upon/after this release, or to take other actions, please coordinate with R&D. No such actions will be taken automatically when this release is published - the quotes will remain active on their prior edition sets, until expiration.';
            }

            return null;
        case RolloutInsightTopic.RenewalPoliciesRefresh:
            if (insights.length > 0) {
                return 'To refresh renewal policies upon/after this release, or to take other actions, please coordinate with R&D. No such actions will be taken automatically when this release is published - the policies will remain on their prior edition sets until updated.';
            }

            return null;
        case RolloutInsightTopic.ActiveQuotes:
            if (insights.length > 0) {
                if (rolloutStrategy === VersionType.Minor) {
                    return 'Quotes that are compatible will receive the update as soon as it is published and effective. Quotes with incompatibilities must be addressed by R&D in order to receive the update. ';
                }

                if (rolloutStrategy === VersionType.BugFix) {
                    return 'Policies that are compatible will receive the update as soon as it is published and effective. Policies with incompatibilities must be addressed by R&D in order to receive the update.';
                }
            }

            return null;
        case RolloutInsightTopic.UpcomingRenewalsPolicies:
            if (insights.length > 0) {
                if (rolloutStrategy === VersionType.Minor) {
                    return 'Once the Release is Published, R&D can apply the fix to these upcoming renewals. Renewals with incompatibilities require special handling.';
                }

                if (rolloutStrategy === VersionType.BugFix) {
                    return 'Once the Release is Published, R&D can apply the fix to these upcoming renewals. Renewals with incompatibilities require special handling.';
                }
            }

            return null;
        case RolloutInsightTopic.ActivePolicies:
            if (insights.length > 0) {
                if (rolloutStrategy === VersionType.Minor) {
                    return 'Once the Release is Published, R&D can apply the fix to these Policies. Policies with incompatibilities require special handling.';
                }

                if (rolloutStrategy === VersionType.BugFix) {
                    return 'Once the Release is Published, R&D can apply the fix to these Policies. Policies with incompatibilities require special handling.';
                }
            }

            return null;
        default:
            return null;
    }
};
