import type { ProductTypes } from '../ProductTypes';
import { CoreEntityType } from '../ProductTypes';

export function createClaimLink(product: ProductTypes, entityPublicId?: string): string {
  return entityPublicId !== undefined
    ? `/backoffice/${product}/claims/${entityPublicId}`
    : `/backoffice/${product}/claims`;
}

export function createTaskLink(product: ProductTypes, claimPublicId: string, taskPublicId: string): string {
  return `/backoffice/${product}/claims/${claimPublicId}/workflows/#${taskPublicId}`;
}

export function createPolicyLink(product: ProductTypes, entityPublicId?: string): string {
  return entityPublicId !== undefined
    ? `/backoffice/${product}/policies/${entityPublicId}`
    : `/backoffice/${product}/policies`;
}

export function createQuoteLink(product: ProductTypes, entityPublicId?: string): string {
  return entityPublicId !== undefined
    ? `/backoffice/${product}/quotes/${entityPublicId}`
    : `/backoffice/${product}/quotes`;
}

export function createUserLink(id?: string): string {
  return id !== undefined ? `/backoffice/users/${id}` : `/backoffice/users`;
}

export function createEditPolicyCoveragesLink(product: ProductTypes, policyPublicId: string): string {
  return `/backoffice/${product}/policies/${policyPublicId}/edit_coverages`;
}

export function getLinkByEntity({
  product,
  entity,
}: {
  readonly product: ProductTypes;
  readonly entity: CoreEntityType;
}): string {
  switch (entity) {
    case CoreEntityType.Policy:
      return createPolicyLink(product);
    case CoreEntityType.Quote:
      return createQuoteLink(product);
    case CoreEntityType.Claim:
      return createClaimLink(product);
    case CoreEntityType.User:
      return createUserLink();
    default:
      return '';
  }
}
