import { useForm } from '@lemonade-hq/blender-ui';

import type { DialogAction, DialogNoticeType } from '@lemonade-hq/bluis';
import { Alert, AlertMode, Dialog, GENERAL_ERROR_MSG } from '@lemonade-hq/bluis';

import type { Infer } from '@lemonade-hq/maschema-schema';
import type { FC, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

import { ManageSettingDialogForm } from './ManageSettingDialogFrom';
import { ManageSettingDialogProvider } from './ManageSettingDialogProvider';
import type { manageSettingDialogSchema } from './manageSettingDialogSchema';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';

import type { CoverageInstance, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import type { InsurableEntity } from 'models/LoCo/Insurance/Product';
import type { SettingTemplate } from 'models/LoCo/Insurance/Registry';
import { RegistryType } from 'models/LoCo/Insurance/Registry';
import type { SettingInstancePayload } from 'models/LoCo/Insurance/SettingInstanceRequests';
import { useGetRegistryByProductLine } from 'queries/LoCo/Insurance/RegistryQueries';

export interface ManageSettingDialogFormProps {
    readonly editionCoverageInstances: CoverageInstance[];
    readonly editionSettingInstances: SettingInstance[];
    readonly insurableEntities: InsurableEntity[];
    readonly settingsRegistry: SettingTemplate[];
}

const ConnectedManageSettingDialog: FC<
    PropsWithChildren<
        Pick<ManageSettingDialogProps, 'isLoadingMutation' | 'isMutationError' | 'onClose' | 'onSubmit'> & {
            readonly isEdit: boolean;
        }
    >
> = ({ isLoadingMutation, isMutationError, onClose, isEdit, onSubmit, children }) => {
    const {
        values,
        validationResults: { valid },
    } = useForm<typeof manageSettingDialogSchema>();

    const submit = useCallback(async () => {
        await onSubmit(values as unknown as SettingInstancePayload);
    }, [values, onSubmit]);

    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Save',
                type: 'submit',
                onClick: submit,
                disabled: isLoadingMutation || !valid,
            },
        ],
        [isLoadingMutation, onClose, submit, valid]
    );

    const notices = useMemo(() => {
        const noticesList: DialogNoticeType = [];

        if (isEdit) {
            noticesList.push(
                <Alert
                    key="1"
                    mode={AlertMode.Info}
                    title={<span>Please review that any rules related to this setting still use available values</span>}
                />
            );
        }

        return noticesList;
    }, [isEdit]);

    return (
        <Dialog
            actions={actions}
            error={isMutationError ? GENERAL_ERROR_MSG : undefined}
            loading={isLoadingMutation}
            notice={notices}
            onClose={onClose}
            size="large"
            title={isEdit ? 'Edit Setting' : 'Add Setting'}
        >
            {children}
        </Dialog>
    );
};

export interface ManageSettingDialogProps {
    readonly editionCode: string;
    readonly onSubmit: (settingInstance: SettingInstancePayload) => Promise<void>;
    readonly isMutationError: boolean;
    readonly isLoadingMutation: boolean;
    readonly editionCoverages: CoverageInstance[];
    readonly editionSettingInstances: SettingInstance[];
    readonly onClose: () => void;
    readonly editedSettingInstance?: SettingInstance;
}

export const ManageSettingDialog: FC<ManageSettingDialogProps> = ({
    editionCoverages,
    editionSettingInstances,
    isLoadingMutation,
    isMutationError,
    editedSettingInstance,
    onClose,
    onSubmit,
}) => {
    const product = useGetProductData();
    const [{ data: settingsRegistry }] = useGetRegistryByProductLine(product.productLineCode, [RegistryType.Setting]);

    const formProps = {
        editionCoverageInstances: editionCoverages,
        editionSettingInstances,
        insurableEntities: product.insurableEntities,
        settingsRegistry: settingsRegistry,
    };

    return (
        <ManageSettingDialogProvider
            {...formProps}
            editedSettingInstance={
                editedSettingInstance
                    ? ({
                          ...editedSettingInstance,
                          relatedCoverages: editedSettingInstance.relatedCoverages.map(r => r.templateCode),
                      } as Infer<typeof manageSettingDialogSchema>)
                    : undefined
            }
        >
            <ConnectedManageSettingDialog
                isEdit={editedSettingInstance !== undefined}
                isLoadingMutation={isLoadingMutation}
                isMutationError={isMutationError}
                onClose={onClose}
                onSubmit={async settingInstance => {
                    await onSubmit(settingInstance);
                    onClose();
                }}
            >
                <ManageSettingDialogForm {...formProps} />
            </ConnectedManageSettingDialog>
        </ManageSettingDialogProvider>
    );
};
