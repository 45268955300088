import * as types from '../actions/actionTypes';
import { PolicyActions } from '../actions/policyActionsTypes';

const initialState = {
    loadFailed: false,
    user: false,
    coverages: false,
    referralProgram: {
        referralProgramData: null,
    },
    timeline: false,
    notifications: false,
    authorizationError: null,
};

export default function user(state = initialState, action = {}) {
    const { type, data } = action;

    switch (type) {
        case types.SET_USER: {
            const notifications = Object.keys(data.notifications).map(key => data.notifications[key]);

            return {
                ...state,
                ...data,
                notifications,
            };
        }
        case types.SET_USER_TIMELINE: {
            return {
                ...state,
                timeline: data,
            };
        }
        case types.SET_USER_LOAD_FAILED: {
            return {
                ...state,
                loadFailed: true,
            };
        }
        case PolicyActions.SET_POLICY:
        case types.SET_CLAIM_DATA: {
            const updatedUser = state.user || {};

            updatedUser.id = data.user.id;
            return {
                ...state,
                user: updatedUser,
            };
        }
        case types.SET_USER_AUTHORIZATION_ERROR: {
            return {
                ...state,
                loadFailed: true,
                authorizationError: data,
            };
        }
        default:
            return state;
    }
}
