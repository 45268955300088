import { ChevronIcon } from '@lemonade-hq/bluis';
import type { BLuisTheme } from '@lemonade-hq/bluis-atomic';
import { Flex } from '@lemonade-hq/cdk';
import styled from 'styled-components';

export type Direction = 'Down' | 'Up';

const StyledArrowsContainer = styled(Flex)`
    flex-direction: column;
    gap: 4px;
`;

interface StyledChevronIconProps {
    readonly isOpen: boolean;
    readonly disabled: boolean;
}

const StyledChevronIcon = styled(ChevronIcon)<StyledChevronIconProps>`
    transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
    cursor: ${({ disabled }: { readonly disabled: boolean }) => (disabled ? 'not-allowed' : 'pointer')};
    > path {
        stroke: ${({ theme, disabled }) => (disabled ? '' : (theme as BLuisTheme).colors.mainButtonBackground)};
        :hover {
            stroke-width: ${({ disabled }) => (disabled ? '' : '3px')};
        }
    }
`;

const Arrow: React.FC<{
    readonly disabled: boolean;
    readonly direction: Direction;
}> = ({ disabled, direction }) => {
    return <StyledChevronIcon disabled={disabled} isOpen={direction === 'Up'} />;
};

export const Arrows: React.FC<{
    readonly disableCursor: boolean;
    readonly disabledDirections: Direction[];
    readonly onClick: (direction: Direction) => void;
}> = ({ disableCursor, disabledDirections, onClick }) => {
    return (
        <StyledArrowsContainer>
            {['Up', 'Down'].map(arrowDirection => {
                const isActionDisabled = disabledDirections.includes(arrowDirection as Direction);
                return (
                    <Flex
                        key={arrowDirection}
                        onClick={() => {
                            if (!isActionDisabled) onClick(arrowDirection as Direction);
                        }}
                    >
                        <Arrow direction={arrowDirection as Direction} disabled={disableCursor || isActionDisabled} />
                    </Flex>
                );
            })}
        </StyledArrowsContainer>
    );
};
