import { useParams } from 'react-router-dom';
import type { Product } from 'models/LoCo/Insurance/Product';
import { useSuspenseGetProduct } from 'queries/LoCo/Insurance/ProductQueries';

export function useGetProductCode(): string {
    const { productCode } = useParams<{ productCode: string }>();
    if (productCode === undefined) {
        throw new Error("Product code is missing, are you sure you're on a product page?");
    }

    return productCode;
}

export const useGetProductData = (): Product => {
    const productCode = useGetProductCode();

    const { data: product } = useSuspenseGetProduct(productCode);

    return product;
};
