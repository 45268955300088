import { useQueryParams } from '@lemonade-hq/cdk';
import { useCallback, useState } from 'react';

// helpers

const TICKET_ID_LOCAL_STORAGE_KEY = 'ticketId';
const DEFAULT_TICKET_ID = '12837493';

function storeTicketIdInLocalStorage(ticketId: string): void {
    localStorage.setItem(TICKET_ID_LOCAL_STORAGE_KEY, ticketId);
}

function loadTicketIdFromLocalStorage(): string {
    const cachedTicketId = localStorage.getItem(TICKET_ID_LOCAL_STORAGE_KEY);

    if (cachedTicketId != null) {
        return cachedTicketId;
    } else {
        storeTicketIdInLocalStorage(DEFAULT_TICKET_ID);
        return DEFAULT_TICKET_ID;
    }
}

// interfaces

interface PlaygroundTicketIdResult {
    readonly setTicketId: (value: string) => void;
    readonly ticketId: string;
}

// hook

export function usePlaygroundTicketId(): PlaygroundTicketIdResult {
    const {
        queryParams: { ticketId: ticketIdFromQueryParam },
    } = useQueryParams<{ readonly ticketId?: string | undefined }>();
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-nullish-coalescing -- we want to also nullify if empty string
    const [ticketId, internalSetTicketId] = useState<string>(ticketIdFromQueryParam || loadTicketIdFromLocalStorage());

    const setTicketId = useCallback((value: string): void => {
        storeTicketIdInLocalStorage(value);
        internalSetTicketId(value);
    }, []);

    return { ticketId, setTicketId };
}
