import { MultiSwitch } from '@lemonade-hq/bluis';
import { InputWrapper } from '../../../InputWrapper';
import { useManageRuleDialogContext } from '../../ManageRuleDialogContext';
import { Outcome } from './Outcome';
import { CoverageDefaultValue, RuleOutcomeType } from 'models/LoCo/Insurance/CoverageRule';

const values = [CoverageDefaultValue.Off, CoverageDefaultValue.On];

export const CoverageDefault: React.FC<{ readonly coverageName: string }> = ({ coverageName }) => {
    const {
        values: { outcome, entityType },
        dispatch,
    } = useManageRuleDialogContext();
    if (entityType == null) throw new Error('Entity type is required');

    return (
        <Outcome coverageOrSettingName={coverageName} outcomeTitle={'Set Default'} ruleEntityType={entityType}>
            <InputWrapper label="Default Value">
                <MultiSwitch
                    name={'coverage default value'}
                    onSwitch={value => {
                        if (value?.target.value === undefined) throw new Error('Value is undefined');

                        dispatch({
                            type: 'outcome',
                            value: {
                                type: RuleOutcomeType.CoverageDefaultValueOutcome,
                                value: values[parseInt(value.target.value)],
                            },
                        });
                    }}
                    options={[
                        { label: 'Off', value: values.indexOf(CoverageDefaultValue.Off) },
                        { label: 'On', value: values.indexOf(CoverageDefaultValue.On) },
                    ]}
                    selected={
                        outcome?.type === RuleOutcomeType.CoverageDefaultValueOutcome
                            ? values.indexOf(outcome.value)
                            : undefined
                    }
                />
            </InputWrapper>
        </Outcome>
    );
};
