import {
    bulletList,
    Flex,
    FormLayout,
    FormProvider,
    generateTypedFormComponents,
    Hr,
    Layout,
    spacing,
    Spinner,
    Text,
    useForm,
} from '@lemonade-hq/blender-ui';
import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import type { Infer } from '@lemonade-hq/maschema-schema';
import { ma, merge, pick, required } from '@lemonade-hq/maschema-schema';
import { va } from '@lemonade-hq/maschema-validations-ui';
import type {
    SerializableTool as Tool,
    ToolSchemaKey,
    SerializableToolsRevision as ToolsRevision,
} from '@lemonade-hq/persisted-tools';
import { ToolChangeStatus, ToolMode, toolsRevisionSchema } from '@lemonade-hq/persisted-tools';
import { useMemo } from 'react';
import type { FC } from 'react';
import type { PartialDeep } from 'type-fest';
import { useGetToolsRevision, usePublishToolsRevision } from '../../persisted_tools.queries';
import { actuallyDeletedTool, displayError, prettifyField, prettifyToolMode } from '../../shared/tool.helpers';
import { toSentence } from 'components/pet/stringsHelper';

const publishingSchema = merge(
    required(pick(toolsRevisionSchema, 'publishNotes')),
    ma.record({
        toolSpecificPublishingNotes: ma.map(ma.string([va.required(), va.minWords({ words: 3 })])),
    })
);

function formatPublishingNotes(values: PartialDeep<Infer<typeof publishingSchema>>): string {
    const notes = [values.publishNotes];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    for (const [toolName, toolNotes] of Object.entries(values?.toolSpecificPublishingNotes ?? {})) {
        notes.push(`\n${toolName}'s change reason: ${toolNotes}`);
    }

    return notes.join('\n');
}

function isChangedFromPublic(tool: Tool, parent?: Tool): boolean {
    return tool.mode !== ToolMode.Public && parent?.mode === ToolMode.Public;
}

function doesToolRequireChangeReason(tool: Tool, parent?: Tool): boolean {
    return tool.changeStatus === ToolChangeStatus.Deleted || isChangedFromPublic(tool, parent);
}

const { InputGroup } = generateTypedFormComponents<typeof publishingSchema>();

interface PublishToolsRevisionDialogProps {
    readonly onClose: () => void;
    readonly onToolsRevisionPublished: () => void;
    readonly toolsRevision: ToolsRevision;
    readonly parentToolsRevision: ToolsRevision;
}

const ToolChangeItem: FC<{ readonly tool: Tool; readonly parentTool?: Tool }> = ({ tool, parentTool }) => {
    const showChanges = tool.changeStatus === ToolChangeStatus.Edited;
    const didModeChange = parentTool?.mode !== tool.mode;
    return (
        <li key={tool.name}>
            <Layout display="inline-flex" flexDirection="column" gap={spacing.s06} width="100%">
                <Text>
                    <u>{tool.name}</u>
                </Text>
                <Text>
                    Status:&nbsp;
                    {tool.changeStatus === ToolChangeStatus.Deleted ? (
                        <>
                            <Text fontWeight="bold">{prettifyToolMode(tool.mode)}</Text>
                            &nbsp;→&nbsp;
                            <Text color="error" fontWeight="bold">
                                Deleted
                            </Text>
                        </>
                    ) : didModeChange && parentTool != null ? (
                        <>
                            <Text fontWeight="bold">{prettifyToolMode(parentTool.mode)}</Text>
                            &nbsp;→&nbsp;
                            <Text fontWeight="bold">{prettifyToolMode(tool.mode)}</Text>
                        </>
                    ) : (
                        <Text fontWeight="bold">{prettifyToolMode(tool.mode)}</Text>
                    )}
                </Text>
                {showChanges && (
                    <Text>
                        Changes:&nbsp;
                        <strong>
                            {toSentence(
                                (tool.dirtyFields != null ? Object.keys(tool.dirtyFields) : []).map(f =>
                                    prettifyField(f as ToolSchemaKey)
                                )
                            )}
                        </strong>
                    </Text>
                )}
                {tool.changeStatus === ToolChangeStatus.Deleted ? (
                    <InputGroup
                        inputComponent="Input"
                        placeholder="Why was this tool deleted?"
                        schemaKey={`toolSpecificPublishingNotes.${tool.name}`}
                    />
                ) : (
                    didModeChange &&
                    isChangedFromPublic(tool, parentTool) && (
                        <InputGroup
                            inputComponent="Input"
                            placeholder="Why is this tool no longer Public?"
                            schemaKey={`toolSpecificPublishingNotes.${tool.name}`}
                        />
                    )
                )}
            </Layout>
        </li>
    );
};

const PublishToolsRevisionInternal: FC<PublishToolsRevisionDialogProps> = ({
    onClose,
    onToolsRevisionPublished,
    toolsRevision,
    parentToolsRevision,
}) => {
    const {
        values,
        validationResults: { valid },
    } = useForm<typeof publishingSchema>();

    const { data: upToDateToolsRevision, isFetching: isLoadingUpToDateToolsRevision } = useGetToolsRevision(
        toolsRevision.publicId
    );

    const { mutateAsync: publishToolsRevision, isPending } = usePublishToolsRevision();

    const handlePublish = async (): Promise<void> => {
        try {
            await publishToolsRevision({
                toolsRevisionPublicId: toolsRevision.publicId,
                publishNotes: formatPublishingNotes(values),
            });
            onToolsRevisionPublished();
        } catch (e) {
            displayError('Error publishing')(e);
        }
    };

    const validForPublishing = upToDateToolsRevision?.syncStatus?.canSync;

    const actions: DialogAction[] = [
        {
            type: 'close',
            text: 'cancel',
        },
        {
            type: 'submit',
            disabled: !validForPublishing || !valid,
            text: 'Publish Changes',
            onClick: handlePublish,
        },
    ];

    const dialogProps = {
        actions,
        closeOnOutsideClick: true,
        loading: isPending || isLoadingUpToDateToolsRevision,
        onClose,
        title: 'Publish Changes',
    };

    const parentToolsByName = useMemo(() => {
        return parentToolsRevision.tools.reduce<Record<string, Tool>>(
            (acc, tool) => ({ ...acc, [tool.name]: tool }),
            {}
        );
    }, [parentToolsRevision.tools]);

    const updatedTools = useMemo(
        () =>
            toolsRevision.tools
                .filter(
                    tool =>
                        tool.changeStatus === ToolChangeStatus.Edited ||
                        tool.changeStatus === ToolChangeStatus.New ||
                        actuallyDeletedTool(tool)
                )
                // sort tools so those that require input appear first
                .sort(tool => (doesToolRequireChangeReason(tool, parentToolsByName[tool.name]) ? -1 : 1)) ?? [],
        [toolsRevision.tools, parentToolsByName]
    );

    return (
        <Dialog {...dialogProps}>
            <Flex
                flexDirection="column"
                gap={spacing.s20}
                justifyContent={isLoadingUpToDateToolsRevision ? 'center' : 'flex-start'}
                minHeight="30rem"
                textAlign="left"
            >
                {isLoadingUpToDateToolsRevision ? (
                    <Flex justifyContent="center">
                        <Spinner size="xl" />
                    </Flex>
                ) : validForPublishing ? (
                    <FormLayout>
                        <InputGroup
                            autoExpanding
                            inputComponent="TextArea"
                            label="General update purpose"
                            placeholder="Briefly describe why you're making this update (e.g. bug fix, improving precision, maintenance)"
                            schemaKey="publishNotes"
                        />
                        <Hr />
                        <Text fontWeight="bold">The following tools will be updated in production:</Text>
                        <ul className={bulletList}>
                            {updatedTools.map(tool => (
                                <ToolChangeItem key={tool.name} parentTool={parentToolsByName[tool.name]} tool={tool} />
                            ))}
                        </ul>
                    </FormLayout>
                ) : (
                    <>
                        <Text>Unable to publish changes. </Text>
                        <Text>
                            Some of the tools you edited have newer versions in production. Create a new revision to
                            continue your work.
                        </Text>
                        <Text>Affected tools:</Text>
                        <ul>
                            {upToDateToolsRevision?.syncStatus?.errors?.map(error => (
                                <li key={error.toolName}>
                                    <Text>{error.toolName}</Text>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </Flex>
        </Dialog>
    );
};

export const PublishToolsRevisionDialog: FC<PublishToolsRevisionDialogProps> = ({
    onClose,
    onToolsRevisionPublished,
    toolsRevision,
    parentToolsRevision,
}) => {
    // required in-order for form builder to show correct errors on specific tool input fields
    const toolsThatRequireChangeReason =
        toolsRevision.tools
            .filter(tool =>
                doesToolRequireChangeReason(
                    tool,
                    parentToolsRevision.tools.find(t => t.name === tool.name)
                )
            )
            .map(t => t.name) ?? [];

    return (
        <FormProvider
            initialValues={{
                toolSpecificPublishingNotes: Object.fromEntries(toolsThatRequireChangeReason.map(t => [t, ''])),
            }}
            schema={publishingSchema}
        >
            <PublishToolsRevisionInternal
                onClose={onClose}
                onToolsRevisionPublished={onToolsRevisionPublished}
                parentToolsRevision={parentToolsRevision}
                toolsRevision={toolsRevision}
            />
        </FormProvider>
    );
};
