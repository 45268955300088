import { Accordion, Card, ErrorSection, InlineButton } from '@lemonade-hq/bluis';
import type { MacrosInitProps, MacrosParamsPartialObject, MentionUser } from '@lemonade-hq/bluiza';
import {
    EntityTypes as EntityType,
    getProductTypeFromUrl,
    MacrosSupportedEntity,
    MacrosSupportedProductType,
    ProductTypes as ProductType,
    SectionErrorBoundary,
} from '@lemonade-hq/bluiza';
import { useAnalytics } from '@lemonade-hq/boutique';
import { extractMentionsFromHtml, Flex, htmlFromSlate, useStorageState } from '@lemonade-hq/cdk';
import type { Locale } from '@lemonade-hq/lemonation';
import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { NoteEditorProps, NoteListProps } from './components';
import { NoteEditor, NoteList } from './components';
import { NotesSkeletons } from './components/NoteSkeletons/NoteSkeletons';
import { useNotesContext } from './context';
import { useAddNote, useGetMentionedUsersMutation, useGetNotes, useMentionUsers } from './queries';
import type { AdditionalEntity, AddNoteBodyArgs, DraftValue } from './types';

const buildMacrosProps = (
    entityType: EntityType,
    productType: ProductType,
    locale: Locale,
    params?: MacrosParamsPartialObject
): MacrosInitProps => {
    const entityTypeToMacrosSupportedEntity = {
        [EntityType.HomeClaim]: MacrosSupportedEntity.Claims,
        [EntityType.CarClaim]: MacrosSupportedEntity.Claims,
        [EntityType.PetClaim]: MacrosSupportedEntity.Claims,
        [EntityType.HomeEuClaim]: MacrosSupportedEntity.Claims,
        [EntityType.UnderwritingReview]: MacrosSupportedEntity.Underwriting,
    };

    const productTypeToMacrosSupportedProductType = {
        [ProductType.Home]: MacrosSupportedProductType.Home,
        [ProductType.Car]: MacrosSupportedProductType.Car,
        [ProductType.Pet]: MacrosSupportedProductType.Pet,
    };

    // Change to the new satisfies when eslint is updated
    const macrosSupportedEntity = entityTypeToMacrosSupportedEntity[entityType] as MacrosSupportedEntity;
    const macrosSupportedProductType = productTypeToMacrosSupportedProductType[
        productType
    ] as MacrosSupportedProductType;

    return {
        productType: macrosSupportedProductType,
        entityType: macrosSupportedEntity,
        locale,
        params,
    };
};

const StyledErrorSection = styled(ErrorSection)`
    padding: 0;
    height: auto;
`;

const AccordionProvider = styled(Accordion)`
    border: none;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background: none;
`;

const MAX_RECENT_USERS = 4;

export interface NotesProps {
    readonly entityPublicId: string;
    readonly entityType: EntityType;
    readonly onNoteAdded?: () => void;
    readonly additionalEntity?: AdditionalEntity;
    // In case of task notes, we need to know the subject
    readonly subject?: string;
    readonly productType: ProductType;
    readonly locale: Locale;
    readonly analyticsParams?: Record<string, string>;
    readonly timezone?: string;
    readonly renderNoteEditorFooter?: NoteEditorProps['renderFooter'];
    readonly renderNoteEditorSubmitButton?: NoteEditorProps['renderSubmitButton'];
    readonly renderNoteFooter?: NoteListProps['renderNoteFooter'];
    readonly macrosParams?: MacrosParamsPartialObject;
}

export const Notes: FC<React.PropsWithChildren<NotesProps>> = ({
    entityPublicId,
    entityType,
    productType,
    locale,
    additionalEntity,
    subject,
    timezone = 'UTC',
    onNoteAdded,
    renderNoteEditorFooter,
    renderNoteEditorSubmitButton,
    renderNoteFooter,
    macrosParams,
}) => {
    const { initNotes, notesAmount, updateNoteDraft, updateNotesAmount, analyticsParams } =
        useNotesContext(entityPublicId);
    const { setValue: setRecentMentions, storedValue: recentMentions = [] } = useStorageState<MentionUser[]>(
        'recentMentions',
        []
    );
    const [draftValue, setDraftValue] = useState<DraftValue | null>(null);
    const { mutateAsync: addNote, isPending: isAdding } = useAddNote();
    const { mutateAsync: getMentionedUsers } = useGetMentionedUsersMutation();
    const { mutateAsync: addMentions, isPending: isAddingMentions } = useMentionUsers();
    const isMentionsFeatureEnabled = getProductTypeFromUrl(window.location.pathname) !== ProductType.Car;

    const { trackEvent } = useAnalytics();
    const { data, isPending: isLoading, error, refetch } = useGetNotes(entityPublicId, entityType);
    const isEditorOpenByDefault = data?.notes.length === 0;
    const macrosInitProps = useMemo(() => {
        if (additionalEntity == null) return undefined;

        const { entityType: additionalEntityType } = additionalEntity;

        return buildMacrosProps(additionalEntityType, productType, locale, macrosParams);
    }, [locale, productType, additionalEntity, macrosParams]);

    useEffect(() => {
        if (draftValue || !data?.notes) return;

        const defaultDraftValue = initNotes(data.notes.length);

        setDraftValue(defaultDraftValue);
    }, [draftValue, data, initNotes]);

    const handleSubmit = async (
        value: DraftValue,
        revertEditorValue: () => void,
        entities: AdditionalEntity[] = [],
        analyticsProps?: Record<string, string>
    ): Promise<void> => {
        const currentNotesAmount = notesAmount ?? 0;

        setDraftValue(null);
        const content = htmlFromSlate(value);

        const addNoteData: AddNoteBodyArgs = {
            entities: [
                {
                    entityType,
                    entityPublicId,
                },
                ...entities,
            ],
            subject,
            content,
        };

        if (additionalEntity != null) {
            addNoteData.entities.push(additionalEntity);
        }

        const mentionedOperatorsPublicIds = extractMentionsFromHtml(content);

        const note = await addNote(addNoteData, {
            onSuccess: () => {
                updateNoteDraft([]);
                updateNotesAmount(currentNotesAmount + 1);
                if (analyticsParams?.noteSubmitted) {
                    trackEvent(analyticsParams.noteSubmitted.name, {
                        ...analyticsProps,
                        hasMentions:
                            isMentionsFeatureEnabled && mentionedOperatorsPublicIds.length > 0 ? 'true' : 'false',
                        mentions: String(mentionedOperatorsPublicIds.length),
                    });
                }

                onNoteAdded?.();
            },
            onError: () => {
                updateNoteDraft(value);
                setDraftValue(value);
                revertEditorValue();
            },
        });

        if (isMentionsFeatureEnabled && mentionedOperatorsPublicIds.length > 0) {
            getMentionedUsers(mentionedOperatorsPublicIds, {
                onSuccess: (mentions: MentionUser[] | undefined = []) => {
                    setRecentMentions([
                        ...mentions.slice(0, MAX_RECENT_USERS),
                        ...recentMentions.slice(0, MAX_RECENT_USERS),
                    ]);
                },
            });
            const urlWithoutParams = window.location.href.replace(window.location.search, '');

            const linkArray = urlWithoutParams.split('#');
            const [link, taskId] = linkArray;

            await addMentions({
                entityLink: `${link}?noteId=${note.publicId}#${taskId}`,
                entityPublicId,
                mentionedOperatorsPublicIds,
                entityType: subject ?? 'N/A',
            });
        }
    };

    if (isLoading) return <NotesSkeletons />;
    if (error != null) {
        return (
            <SectionErrorBoundary>
                <Card>
                    <Flex alignItems="center" flexDirection="column" justifyContent="center">
                        <StyledErrorSection noBorders />
                        <InlineButton onClick={async () => await refetch()}>Try again</InlineButton>
                    </Flex>
                </Card>
            </SectionErrorBoundary>
        );
    }

    if (data == null || notesAmount == null) return null;

    return (
        <SectionErrorBoundary>
            <div>
                <AccordionProvider openedByDefault={isEditorOpenByDefault}>
                    <NoteEditor
                        draftValue={draftValue}
                        entityId={entityPublicId}
                        isAdding={isAdding || isAddingMentions}
                        macrosInitProps={macrosInitProps}
                        onAddNote={handleSubmit}
                        renderFooter={renderNoteEditorFooter}
                        renderSubmitButton={renderNoteEditorSubmitButton}
                        showMentions={isMentionsFeatureEnabled}
                    />
                </AccordionProvider>
                <NoteList notes={data.notes} renderNoteFooter={renderNoteFooter} timezone={timezone} />
            </div>
        </SectionErrorBoundary>
    );
};
