import type { Task } from '@lemonade-hq/bluiza';
import type { EntityTypes } from 'models/Products';

export enum HomeCustomTask {
    Custom = 'other',

    // legacy custom tasks, can no longer be created
    FollowUp = 'follow_up',
    ObtainClaimHistoryIso = 'obtaim_claim_history_iso',
    ObtainDocumentReport = 'obtain_document_report',
    ObtainFactsOfLoss = 'obtain_facts_of_loss',
    ObtainInvoices = 'obtain_invoices',
    ObtainTlo = 'obtain_tol',
    CreateDemand = 'create_demand',
    CreateEstimate = 'create_estimate',
    CreateTranslation = 'create_translation',
    CreateItemInventory = 'create_item_inventory',
    Siu = 'siu',
    Escalation = 'escalation',
    ReviewFile = 'review_file',
    Subrogation = 'subrogation',
    ReviewDemans = 'review_demand',
    ReviewEstimate = 'review_estimate',
    ReviewDocuments = 'review_documents',
    LeadCoachReview = 'lead_coach_review',
    RequestAuthority = 'request_authority',
    ReviewLiabilityCoverage = 'review_liability_coverage',
    ReviewItemPriceEvaluation = 'review_item_price_evaluation',
    ReviewEstimationsComparison = 'review_esstimations_comparison',
    Payment = 'payment',
    DenyClaim = 'deny_claim',
    ReassignClaim = 'reassign_claim',
    DispatchVendor = 'dispatch_vendor',
    CloseForNoResponse = 'close_for_no_response',
}

export enum HomeVerificationTask {
    ThirdPartyVerification = 'verify_with_third_party',
    LandlordVerification = 'verify_with_landlord',
    PoliceReportVerification = 'verify_police_report',
    FireReportVerification = 'verify_fire_report',
    AppleProductVerification = 'apple_product_verification',
    BikeVerification = 'bike_verification',
    CarBreakInVerification = 'car_break_in_verification',
    PhoneVerification = 'phone_verification',
}

export enum HomeTask {
    // Claim Review Group
    Home = 'home',
    Alerts = 'alerts',
    ClaimVideos = 'claim_videos',
    ClaimLifecycle = 'claim_lifecycle',
    ClaimHistory = 'claim_history',
    Coverages = 'coverages',
    IncidentDetails = 'incident_details',
    UpdateReserves = 'update_reserves',
    AdvanceLouPayment = 'advance_loss_of_use_payment',
    EventSummary = 'event_summary',

    // Verification Group
    ObtainMissingInfo = 'obtain_missing_info',

    // Determination + Payment Groups
    InventoryReview = 'valuated_review_inventory',
    ValuatedItemsPayment = 'valuated_items_payment',
    ReviewOwnership = 'valuated_review_ownership',
    ReviewReplacement = 'valuated_review_replacement',
    FeatureReview = 'feature_review',
    FeaturePayment = 'feature_payment',
    DetermineCoverage = 'determine_coverage',
    LinkEndorsements = 'link_endorsements',
    ReviewPaymentRequest = 'review_payment_request',
    IssuePayment = 'issue_payment',
    ClaimantDetails = 'claimant_details',

    // Vendor Management Group
    GetEstimation = 'get_estimation',
    CauseAndOriginApproval = 'cause_and_origin_approval',
    DispatchExpenseVendor = 'dispatch_expense_vendor',
    PayVendors = 'pay_vendors',

    // Decline Group
    DeclineReason = 'decline_reason',
    CallUser = 'call_user',
    DenialEmail = 'denial_email',

    // To Do Group
    ClaimStatusUpdate = 'claim_status_update',

    // legacy tasks
    CoveragesAndDamages = 'coverages_and_damages',
    VerificationSummary = 'verification_summary',
    ItemsReview = 'items_review',
    FinishedAndApproved = 'finished_and_approved',
    FinishedAndDenied = 'finished_and_denied',

    // Subrogation
    SubrogationDetection = 'subrogation_detection',
    SubrogationReferral = 'subrogation_referral',
    SubrogationReview = 'subrogation_review',
    ManageSubrogationV2 = 'manage_subrogation_v2',
    SubrogationLog = 'subrogation_log',
}

export const HomeTaskType = { ...HomeTask, ...HomeCustomTask, ...HomeVerificationTask };

export type HomeTaskType = HomeCustomTask | HomeTask | HomeVerificationTask;

export const dismissibleTaskTypes: HomeTaskType[] = [
    HomeTaskType.ThirdPartyVerification,
    HomeTaskType.LandlordVerification,
    HomeTaskType.PoliceReportVerification,
    HomeTaskType.FireReportVerification,
    HomeTaskType.AppleProductVerification,
    HomeTaskType.BikeVerification,
    HomeTaskType.CarBreakInVerification,
    HomeTaskType.PhoneVerification,
    HomeTaskType.IssuePayment,
    HomeTaskType.ReviewPaymentRequest,
    HomeTaskType.GetEstimation,
    HomeTaskType.ClaimStatusUpdate,
    HomeTaskType.AdvanceLouPayment,
    HomeTaskType.CallUser,
    HomeTaskType.SubrogationReferral,
    HomeTaskType.ReviewReplacement,
    HomeTaskType.ManageSubrogationV2,
    HomeTaskType.ClaimantDetails,
    ...Object.values(HomeCustomTask),
];

export const nonReopenableTaskTypes: HomeTaskType[] = [];

export interface HomeTaskProps {
    readonly task: Task<typeof HomeTaskType>;
}

export const VerificationStatus = {
    FAILED: 'failed',
    SUCCESS: 'success',
};

export const TaskGroupReferenceType = {
    FAILED: 'failed',
    SUCCESS: 'success',
};

export enum Decision {
    Approved = 'approved',
    Declined = 'declined',
    Ignored = 'ignored',
}

export interface CoverageDecision {
    readonly decision: 'approved' | 'declined' | 'ignored';
    readonly id: number;
    readonly reason: string;
    readonly supporting_data: unknown;
}

export type VerificationType = Record<string, boolean | string | null | undefined>;

export const VerificationHomeTaskTitles = {
    [HomeTaskType.LandlordVerification]: 'Landlord',
    [HomeTaskType.ThirdPartyVerification]: 'Third Party',
    [HomeTaskType.PoliceReportVerification]: 'Police Report',
    [HomeTaskType.FireReportVerification]: 'Fire Report',
    [HomeTaskType.AppleProductVerification]: 'Apple Products Theft',
    [HomeTaskType.BikeVerification]: 'Bike Theft',
    [HomeTaskType.CarBreakInVerification]: 'Car Break-In',
    [HomeTaskType.PhoneVerification]: 'Phone Theft',
};

export enum ReferenceType {
    ClaimReview = 'claim_review',
    VerifyFactsOfLoss = 'verify_facts_of_loss',
    Denial = 'denial',
    ItemsReviewAndPayment = 'items_review_and_payment',
}

export enum DialogType {
    Decline = 'decline',
    VerificationFlags = 'verification_flags',
    AddTask = 'add_task',
}

export interface CreatableWorkflowTaskType {
    readonly type: string;
    readonly displayName: string;
    readonly category: string;
    readonly disabled?: boolean;
}

export type HomeWorkflowAvailable = {
    readonly available: boolean;
    readonly isContentSyncEnabled: boolean;
    readonly is_legacy_workflow: boolean;
    readonly workflowType: 'legacy' | 'regular' | 'v2';
};

export interface NewCustomTaskContent {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly referenceType: string;
    readonly title: string;
    readonly description: string;
    readonly operatorPublicId: string;
    readonly actionDate: string;
    readonly forcedSquad?: string;
}

export interface RecommendedTaskType {
    readonly type: string;
    readonly displayName: string;
    readonly preSelected: boolean;
}
