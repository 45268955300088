/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

import { Button, TextArea, Tooltip } from '@lemonade-hq/blender-ui';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useRef } from 'react';
import type { FC, KeyboardEvent } from 'react';
import * as styles from './PlaygroundChat.css';

interface PlaygroundChatTextAreaProps {
    readonly onChange: (value: string) => void;
    readonly onSubmit: () => void;
    readonly value: string;
    readonly disabled?: boolean;
    readonly invalidToolsNames: string[];
}

export const PlaygroundChatTextArea: FC<PlaygroundChatTextAreaProps> = ({
    disabled,
    value,
    onChange,
    onSubmit,
    invalidToolsNames,
}) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleSubmit = useCallback(
        (e?: React.FormEvent<HTMLFormElement>) => {
            if (isEmpty(value) || disabled) return;

            e?.preventDefault();
            onSubmit();
            textAreaRef.current?.focus();
        },
        [onSubmit, value, disabled]
    );

    const handlePossibleSubmit = useCallback(
        (e: KeyboardEvent<HTMLFormElement>) => {
            if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                handleSubmit();
            }
        },
        [handleSubmit]
    );

    return (
        <form className={styles.chatTextAreaContainer} onKeyDown={handlePossibleSubmit} onSubmit={handleSubmit}>
            <TextArea
                autoExpanding
                className={styles.chatTextArea}
                disabled={disabled}
                onChange={e => onChange(e.target.value)}
                placeholder={disabled ? 'Reload simulation to enable sending a new message' : 'Type here...'}
                ref={textAreaRef}
                required
                value={value}
            />
            <Tooltip
                content={
                    invalidToolsNames.length > 0
                        ? `Please fix invalid tools before simulating: ${invalidToolsNames.join(', ')}`
                        : undefined
                }
                disabled={invalidToolsNames.length === 0}
            >
                <Button
                    className={styles.chatTextAreaSubmitButton}
                    disabled={disabled || invalidToolsNames.length > 0}
                    endIcon="send"
                    label="Simulate"
                    size="sm"
                    type="submit"
                    variant="primary"
                />
            </Tooltip>
        </form>
    );
};
