import { Image, Radio, Tag, TagMode, TagSize } from '@lemonade-hq/bluis';
import { Box, Flex, font, themedColor } from '@lemonade-hq/cdk';
import type { Maybe } from '@lemonade-hq/ts-helpers';
import React, { useCallback } from 'react';

import styled from 'styled-components';
import { useRaters } from '../queries/useRaters';
import type { Rater } from '../queries/useRaters';
import { RatersDialogContent } from './RatersDialogContent';

const Item = styled.label<{ readonly selected: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    border-radius: 5px;
    padding: 16px 24px;
    box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.02);
    border: 1px solid ${themedColor('separator')};
    background-color: ${({ selected }) =>
        selected ? themedColor('elementBackground') : themedColor('componentBackground')};

    &:not(:last-child) {
        margin-bottom: 8px;
    }
`;

const Title = styled.div`
    ${font('main', { fontSize: '16px', fontWeight: 700 })}
`;

const StyledTag = styled(Tag)<{ readonly connected: boolean }>`
    position: relative;
    padding-left: 20px;

    &:before {
        content: '';
        position: absolute;
        left: 8px;
        width: 8px;
        height: 8px;
        background: ${({ connected }) => (connected ? themedColor('success') : themedColor('secondaryText'))};
        border-radius: 8px;
    }
`;

interface SelectRaterStepProps {
    readonly rater?: string;
    readonly setRater: React.Dispatch<React.SetStateAction<Maybe<Rater>>>;
}

function sortRatersByPrivacyAndAlphabetically(agentA: Rater, agentB: Rater): number {
    if (agentA.isPrivate && !agentB.isPrivate) {
        return -1;
    } else if (!agentA.isPrivate && agentB.isPrivate) {
        return 1;
    }

    return agentA.name.localeCompare(agentB.name);
}

export const SelectRaterStep: React.FC<React.PropsWithChildren<SelectRaterStepProps>> = ({ rater, setRater }) => {
    const { data } = useRaters();
    const isActive = useCallback((publicId: string) => rater === publicId, [rater]);
    const getTagMode = useCallback((lastConnected?: Date) => (lastConnected ? TagMode.Success : TagMode.Info), []);
    const getTagText = useCallback((lastConnected?: Date) => (lastConnected ? 'Connected' : 'Not connected'), []);

    return (
        <RatersDialogContent>
            <p>Choose a rater</p>

            {data?.sort(sortRatersByPrivacyAndAlphabetically).map(raterItem => (
                <Item
                    htmlFor={raterItem.publicId}
                    key={raterItem.publicId}
                    onClick={() => setRater(raterItem)}
                    selected={isActive(raterItem.publicId)}
                >
                    <Flex alignItems="center" justifyContent="flex-start">
                        <Radio checked={isActive(raterItem.publicId)} id={raterItem.publicId} name="rater" />
                        <Box ml={15} mr={20}>
                            <Image
                                img={{
                                    lightSrc: raterItem.logoUrl,
                                    darkSrc: raterItem.logoUrl,
                                    width: 40,
                                    height: 'auto',
                                }}
                            />
                        </Box>
                        <Title>{raterItem.name}</Title>
                    </Flex>

                    <Flex alignItems="center" justifyContent="flex-start">
                        <StyledTag
                            connected={Boolean(raterItem.lastConnected)}
                            light
                            mode={getTagMode(raterItem.lastConnected)}
                            tagSize={TagSize.Small}
                        >
                            {getTagText(raterItem.lastConnected)}
                        </StyledTag>
                    </Flex>
                </Item>
            ))}
        </RatersDialogContent>
    );
};
