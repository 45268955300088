import { Accordion, LabelValueTable } from '@lemonade-hq/blender-ui';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@lemonade-hq/bluis';
import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import * as styles from './ChangesSummary.css';
import { CoveragesRulesTable } from './CoveragesRulesTable';
import type { CoverageRuleDisplayLog } from './types';
import type { UnderwritingRules } from './UnderwritingRulesTable';
import { UnderwritingRulesTable } from './UnderwritingRulesTable';
import type { UWRuleType } from 'components/LoCo/common/display-texts/underwriting-rules';

type RuleType = UWRuleType | 'coverage';

type Rules<TRuleType extends RuleType> = TRuleType extends UWRuleType
    ? UnderwritingRules<TRuleType>
    : CoverageRuleDisplayLog[];

interface ChangeAccordionProps<TRuleType extends RuleType> {
    readonly title: string;
    readonly ruleType: TRuleType;
    readonly originalRules: Rules<TRuleType> | null;
    readonly updatedRules: Rules<TRuleType> | null;
    readonly generalTabData: LabelValueItem[] | null;
}

export const ChangeAccordion = <TRuleType extends RuleType>({
    title,
    updatedRules,
    originalRules,
    generalTabData,
    ruleType,
}: ChangeAccordionProps<TRuleType>): JSX.Element => {
    const showUpdateRules = updatedRules && updatedRules.length > 0;
    const showOriginalRules = originalRules && originalRules.length > 0;

    const hasSingleRulesTable = showUpdateRules !== showOriginalRules;
    const getRulesTableTitle = (prefix: string): string => (hasSingleRulesTable ? 'Rules' : `${prefix} Rules`);

    return (
        <Accordion title={title}>
            <Tabs startAtTabIndex={generalTabData === null ? (updatedRules === null ? 2 : 1) : 0}>
                <TabList tabSize="small">
                    {generalTabData && generalTabData.length > 0 && <Tab key="general">General</Tab>}
                    {showUpdateRules && <Tab key="updated">{getRulesTableTitle('Updated')}</Tab>}
                    {showOriginalRules && <Tab key="original">{getRulesTableTitle('Original')}</Tab>}
                </TabList>
                <TabPanels>
                    {generalTabData && (
                        <TabPanel>
                            <LabelValueTable
                                className={styles.generalTableWrapper}
                                columnCount={1}
                                data={generalTabData}
                                labelWidth="22rem"
                            />
                        </TabPanel>
                    )}
                    {showUpdateRules && (
                        <TabPanel>
                            {ruleType === 'coverage' ? (
                                <CoveragesRulesTable rules={updatedRules as CoverageRuleDisplayLog[]} />
                            ) : (
                                <UnderwritingRulesTable
                                    ruleType={ruleType}
                                    rules={updatedRules as UnderwritingRules<UWRuleType>}
                                />
                            )}
                        </TabPanel>
                    )}
                    {showOriginalRules && (
                        <TabPanel>
                            {ruleType === 'coverage' ? (
                                <CoveragesRulesTable rules={originalRules as CoverageRuleDisplayLog[]} />
                            ) : (
                                <UnderwritingRulesTable
                                    ruleType={ruleType}
                                    rules={originalRules as UnderwritingRules<UWRuleType>}
                                />
                            )}
                        </TabPanel>
                    )}
                </TabPanels>
            </Tabs>
        </Accordion>
    );
};
