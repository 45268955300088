import { Badge, Flex } from '@lemonade-hq/blender-ui';
import type { ActionOption } from '@lemonade-hq/bluis';
import { ActionsMenu, copyToClipboard, EmptySection, MainButton, TableTitle, toast } from '@lemonade-hq/bluis';
import { isDefined } from '@lemonade-hq/ts-helpers';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { UnderwritingDialogs, useUnderwritingDialog } from '../UnderwritingDialogs';
import { UnderwritingRegistryName } from '../UnderwritingRegistryName';
import type { ReasonEntityType } from '../underwritingUtils';
import { ActionType, getDecisionTypeText, isDraftReasonVersion, UnderwritingEntityType } from '../underwritingUtils';
import { VersionActionMenu } from '../VersionActionMenu';
import { ExpendButton, ExpendedDetailsTable } from 'components/LoCo/common/components/GridTable/ExpandedDetailsTable';
import type { Columns, ColumnsToRow } from 'components/LoCo/common/components/GridTable/GridTable';
import { GridTable } from 'components/LoCo/common/components/GridTable/GridTable';
import { Paginator } from 'components/LoCo/common/components/Pagination/Paginator';
import { getFormattedDate } from 'components/LoCo/common/helpers/dateHelpers';
import { StyledSectionHeader } from 'components/LoCo/LoCoPagesSharedStyles';
import { StyledActionsMenuWrapper } from 'components/LoCo/products/SharedTableConfig';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type { PublishedReasonVersion, Reason, ReasonVersion } from 'models/LoCo/Insurance/UnderwritingRegistry';
import { useSuspenseGetRegistryReasons } from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

const ReasonsColumns = [
    { key: 'internalName', title: `Internal Name` },
    { key: 'productLines', title: 'Product Lines' },
    { key: 'expend', title: '', width: '56px' },
] as const satisfies Columns;

// eslint-disable-next-line @lemonade-hq/base/favor-singular-enums

const reasonActionMenu: ActionOption[] = [
    {
        label: 'Edit Product Lines',
        value: ActionType.ManageProductLines,
    },
    {
        label: 'Copy Reason Code',
        value: ActionType.CopyCode,
    },
];

const isReasonPublishedVersion = (reason: ReasonVersion): reason is PublishedReasonVersion => reason.version != null;

const getReasonRow = (
    reason: Reason,
    hasDraft: boolean,
    onActionRequested: (action: ActionType) => void
): ColumnsToRow<typeof ReasonsColumns> => {
    return {
        internalName: {
            value: <UnderwritingRegistryName hasDraft={hasDraft} name={reason.name}></UnderwritingRegistryName>,
        },
        productLines: { value: reason.productLines.map(productLine => productLine.name).join(', ') },
        expend: {
            value: (
                <StyledActionsMenuWrapper>
                    <ExpendButton />
                    <ActionsMenu
                        actions={reasonActionMenu}
                        onChange={value => onActionRequested(value as ActionType)}
                        type="dots"
                    />
                </StyledActionsMenuWrapper>
            ),
        },
    };
};

const getSpecificExplanationSummary = (reasonVersion: ReasonVersion): string => {
    const specificExplanationCount = reasonVersion.specificExplanations.length;
    const specificExplanation =
        specificExplanationCount > 0
            ? `${specificExplanationCount} State-specific ${pluralize('explanation', specificExplanationCount)}`
            : `No state-specific explanations`;
    return specificExplanation;
};

interface ReasonsRegistryProps {
    readonly decisionType: UnderwritingDecisionLifecycleContext;
}

export const ReasonsRegistry: React.FC<ReasonsRegistryProps> = ({ decisionType }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const {
        data: { stats, data: registryReasons },
    } = useSuspenseGetRegistryReasons(decisionType, { page: pageNumber, size: 20 });
    const { dialogData, setDialogData } = useUnderwritingDialog<ReasonEntityType>();

    const onPageClick = (pagIndex: number) => () => {
        setPageNumber(pagIndex);
    };

    const reasonsWithDraft: Set<string> = useMemo(() => {
        const collected = new Set<string>();

        registryReasons.forEach(reason => {
            if (reason.reasonVersions.some(isDraftReasonVersion)) {
                collected.add(reason.code);
            }
        });

        return collected;
    }, [registryReasons]);

    const mostRecentVersions = useMemo(() => {
        const mostRecent: Record<string, number> = {};

        registryReasons.forEach(reason => {
            const sortedVersions = reason.reasonVersions
                .filter(isReasonPublishedVersion)
                .sort((a, b) => b.version - a.version);
            mostRecent[reason.code] = sortedVersions[0]?.version ?? '';
        });

        return mostRecent;
    }, [registryReasons]);

    const handleActionRequested = (reason: Reason, reasonVersion: ReasonVersion) => (actionType: ActionType) => {
        switch (actionType) {
            case ActionType.CreateOrEditDraft:
                setDialogData({
                    type: ActionType.CreateOrEditDraft,
                    originalEntity: {
                        type: UnderwritingEntityType.Reason,
                        entity: reason,
                        entityVersion: reasonVersion,
                        isEditable: true,
                    },
                });
                break;
            case ActionType.ViewPublishedVersion:
                setDialogData({
                    type: ActionType.ViewPublishedVersion,
                    originalEntity: {
                        type: UnderwritingEntityType.Reason,
                        entity: reason,
                        entityVersion: reasonVersion,
                        isEditable: false,
                    },
                });
                break;
            case ActionType.PublishDraft:
                setDialogData({
                    type: actionType,
                    entityVersion: reasonVersion,
                });
                break;
            case ActionType.ArchiveDraft:
                setDialogData({
                    type: actionType,
                    publicId: reasonVersion.publicId,
                });
                break;
            default:
                break;
        }
    };

    return (
        <>
            <StyledSectionHeader>
                <TableTitle title={`Global ${getDecisionTypeText(decisionType)} Reasons`} />
                <MainButton
                    onClick={() =>
                        setDialogData({
                            type: ActionType.CreateOrEditDraft,
                        })
                    }
                >
                    Add draft
                </MainButton>
            </StyledSectionHeader>
            {registryReasons.length === 0 ? (
                <EmptySection>no reasons defined</EmptySection>
            ) : (
                <GridTable
                    columns={ReasonsColumns}
                    rows={registryReasons.map(reason => ({
                        values: getReasonRow(reason, reasonsWithDraft.has(reason.code), action => {
                            if (action === ActionType.ManageProductLines) {
                                setDialogData({
                                    type: ActionType.ManageProductLines,
                                    entity: reason,
                                });
                            } else if (action === ActionType.CopyCode) {
                                copyToClipboard(reason.code);
                                toast.info('Copied!', { duration: 2000 });
                            }
                        }),
                        expended: (
                            <ExpendedDetailsTable
                                rows={reason.reasonVersions.map(reasonVersion => [
                                    {
                                        key: 'version',
                                        value: isDraftReasonVersion(reasonVersion) ? (
                                            'Draft'
                                        ) : (
                                            <Flex alignItems="center" gap="0.4rem">
                                                v{reasonVersion.version}
                                                {reasonVersion.version === mostRecentVersions[reason.code] && (
                                                    <Badge label="live" variant="positive" />
                                                )}
                                            </Flex>
                                        ),
                                    },
                                    {
                                        key: 'defaultExplanation',
                                        value: `Default explanation: ${reasonVersion.defaultExplanationName ?? ''}`,
                                    },
                                    {
                                        key: 'stateSpecificExplanations',
                                        value: getSpecificExplanationSummary(reasonVersion),
                                    },
                                    {
                                        key: 'publishDate',
                                        value: isDefined(reasonVersion.publishedAt)
                                            ? `Published ${getFormattedDate(reasonVersion.publishedAt)}`
                                            : 'Not yet published',
                                    },
                                    {
                                        key: 'actions',
                                        value: (
                                            <VersionActionMenu
                                                draftExists={reason.reasonVersions.some(isDraftReasonVersion)}
                                                isDraftVersion={isDraftReasonVersion(reasonVersion)}
                                                onActionRequested={handleActionRequested(reason, reasonVersion)}
                                            />
                                        ),
                                    },
                                ])}
                            />
                        ),
                    }))}
                />
            )}
            <Paginator currentPage={pageNumber} onPageClick={onPageClick} totalPages={stats.totalPages} />
            <UnderwritingDialogs
                decisionType={decisionType}
                dialogData={dialogData}
                entityType={UnderwritingEntityType.Reason}
                onClose={() => {
                    setDialogData(null);
                }}
            />
        </>
    );
};
