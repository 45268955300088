import {
    initializeFaro as faroInit,
    getWebInstrumentations,
    ReactIntegration,
    ReactRouterVersion,
} from '@grafana/faro-react';
import type { Faro } from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { matchRoutes } from 'react-router-dom';

interface InitializeFaroProps {
    readonly stage: string;
    readonly ignoreStages?: string[];
    readonly projectKey: string;
    readonly projectName: string;
    readonly projectVersion: string;
}

export function initializeFaro({
    stage,
    ignoreStages = [],
    projectKey,
    projectName,
    projectVersion,
}: InitializeFaroProps): Faro | undefined {
    if (ignoreStages.includes(stage)) return;

    const faro = faroInit({
        url: `https://faro-collector-prod-us-central-0.grafana.net/collect/${projectKey}`,
        app: {
            name: projectName,
            version: projectVersion,
            environment: stage,
        },
        instrumentations: [
            ...getWebInstrumentations(),
            new TracingInstrumentation(),
            new ReactIntegration({
                router: {
                    version: ReactRouterVersion.V6_data_router,
                    dependencies: {
                        matchRoutes,
                    },
                },
            }),
        ],
    });

    return faro;
}
