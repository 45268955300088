import type { DialogAction, FormElementConfigurableProps, FormState } from '@lemonade-hq/bluis';
import {
    AlertMode,
    createFormRow,
    Dialog,
    FormBuilder,
    formBuilderCreator,
    InputAndLabel,
    Switch,
} from '@lemonade-hq/bluis';
import type { FormBuilderProps, SelectOptionBase } from '@lemonade-hq/cdk';
import { createForm, Flex } from '@lemonade-hq/cdk';
import type { Product } from '@lemonade-hq/lemonation';
import { getProductByPolicyId } from '@lemonade-hq/lemonation';
import { camelCaseToReadable, capitalize, required } from '@lemonade-hq/ts-helpers';
import type { SchemaData } from '@lemonade-hq/ts-helpers';
import { useIsMutating } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AiResponseType, FixReason, Tag } from '../../../shared/types';
import { useSupervisorContext } from '../Context';

const N_A = 'N/A';
const FIELD_WIDTH = 250;
const N_A_OPTION = { id: N_A, label: N_A, value: N_A };

const { input } = formBuilderCreator;

const toSimpleOption = (value: string): SelectOptionBase => ({
    id: value,
    label: value,
    value,
});

const toReadableOption = (value: string): SelectOptionBase => ({
    id: value,
    label: camelCaseToReadable(value),
    value,
});
const reasonOptions = [
    FixReason.IncorrectParameters,
    FixReason.PoorTone,
    FixReason.MisleadingInformation,
    FixReason.Other,
].map(toReadableOption);

const FormDialogWrapper = styled.div`
    [role='main'] {
        flex: none;
        height: 80px;
        width: 250px;
    }

    ${InputAndLabel}:has([for='additionalInfo']) {
        align-items: baseline;
    }
`;

const MarkAsBadFailLabel = styled.div`
    flex-grow: 1;
`;

const SwitchWrapper = styled.div`
    width: 250px;
`;

interface FixFormDialogProps {
    readonly onClose: () => void;
}

// TODO: replace with the new form dialog once it's available
export const FixFormDialog: React.FC<React.PropsWithChildren<FixFormDialogProps>> = ({ onClose }) => {
    const [isMarkAsBadFail, setIsMarkAsBadFail] = useState<boolean>(false);
    const [formState, setFormState] = useState<FormState<SchemaData>>();
    const {
        actions: { reviewAiResponse },
        state: {
            ticketData,
            isLoadingTicket,
            metadata: {
                intents: { nonTools, tools },
                products,
            },
            mode,
        },
        actions: { loadNextTicket },
    } = useSupervisorContext();
    const isMutating = Boolean(useIsMutating());

    const policiesToLob: { [key: string]: Product } = useMemo(() => {
        return (ticketData?.classificationParams.entityIds ?? []).reduce(
            (accumulator, e) => {
                const product = getProductByPolicyId(e);
                if (product) {
                    accumulator[e] = product;
                }

                return accumulator;
            },
            {} as Record<string, Product>
        );
    }, [ticketData]);

    useEffect(() => {
        if (formState?.answers.policyId != null && formState.answers.policyId !== N_A) {
            formState.updateAnswers({
                lob: policiesToLob[formState.answers.policyId as string],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState?.answers.policyId, policiesToLob]);

    const intentsOptions = useMemo(() => {
        const nonToolsOptions = Object.keys(nonTools).map(value => ({
            id: value,
            label: nonTools[value],
            value,
        }));
        const toolsOptions = Object.keys(tools).map(value => ({
            id: value,
            label: tools[value],
            value,
        }));

        return [...nonToolsOptions, ...toolsOptions];
    }, [nonTools, tools]);

    if (ticketData == null) return null;

    const title = mode === AiResponseType.Draft ? 'Fix Comment' : 'Fail Comment';

    const lobOptions: SelectOptionBase[] = [...products, N_A].map(value => ({
        id: value,
        label: capitalize(value),
        value,
    }));

    const initialValues = {
        intent: ticketData.toolInvoked,
        policyId: ticketData.entityPublicId ?? N_A,
        lob: ticketData.entityProduct ?? N_A,
    };

    const formConfig = (answers: Partial<SchemaData>): FormBuilderProps<FormElementConfigurableProps> =>
        createForm({
            content: [
                createFormRow({
                    elements: [
                        input.select({
                            attribute: 'reason',
                            label: mode === AiResponseType.Draft ? 'Fix reason' : 'Fail reason',
                            options: reasonOptions,
                            required: true,
                            width: FIELD_WIDTH,
                            placeholder: 'Select',
                        }),
                    ],
                }),
                ...(answers.reason === FixReason.IncorrectParameters
                    ? [
                          createFormRow({
                              elements: [
                                  input.autocomplete({
                                      attribute: 'intent',
                                      label: 'Intent',
                                      options: intentsOptions,
                                      width: FIELD_WIDTH,
                                  }),
                              ],
                          }),
                          createFormRow({
                              elements: [
                                  input.select({
                                      attribute: 'policyId',
                                      label: 'Policy ID',
                                      options: Object.keys(policiesToLob).map(toSimpleOption).concat(N_A_OPTION),
                                      width: FIELD_WIDTH,
                                  }),
                              ],
                          }),
                          createFormRow({
                              elements: [
                                  input.select({
                                      attribute: 'lob',
                                      label: 'LOB',
                                      options: lobOptions,
                                      width: FIELD_WIDTH,
                                      disabled: answers.policyId !== N_A,
                                  }),
                              ],
                          }),
                      ]
                    : []),
                createFormRow({
                    elements: [
                        input.textArea({
                            attribute: 'additionalInfo',
                            label: 'Additional info',
                            placeholder: 'What is wrong and how could GPT do better',
                            required: true,
                        }),
                    ],
                }),
            ],
        });

    const validationSchema = {
        additionalInfo: [required()],
        reason: [required()],
    };

    async function onSubmit(): Promise<void> {
        await reviewAiResponse({
            ...(formState?.answers.additionalInfo != null && {
                additionalInfo: formState.answers.additionalInfo as string,
            }),
            tag: isMarkAsBadFail ? Tag.BadFail : Tag.Fail,
            reason: formState?.answers.reason as string,
            ticketId: ticketData?.publicId ?? '',
            ...(formState?.answers.reason === FixReason.IncorrectParameters && {
                updatedParams: {
                    api: formState.answers.intent as string,
                    ...(formState.answers.policyId === N_A
                        ? {
                              policyId: undefined,
                              product:
                                  (formState.answers.lob as string) === N_A
                                      ? undefined
                                      : (formState.answers.lob as string),
                          }
                        : {
                              policyId: formState.answers.policyId as string,
                              product: policiesToLob[formState.answers.policyId as string],
                          }),
                },
            }),
        });

        if (ticketData?.actions.skipAfterReview) {
            await loadNextTicket();
        }

        onClose();
    }
    const disableSubmitForIncorrectParameters = (): boolean => {
        if (formState?.answers.reason !== FixReason.IncorrectParameters) {
            return false;
        }

        const intentNotChanged = formState.answers.intent === initialValues.intent;
        const policyIdNotChanged = formState.answers.policyId === initialValues.policyId;
        const LobNotChanged = formState.answers.lob === initialValues.lob;

        return intentNotChanged && policyIdNotChanged && LobNotChanged;
    };

    const formActions: DialogAction[] = [
        {
            type: 'submit',
            text: 'Confirm',
            onClick: onSubmit,
            disabled:
                Boolean(formState?.submitDisabled) ||
                disableSubmitForIncorrectParameters() ||
                isMutating ||
                isLoadingTicket,
        },
    ];

    function onToggleMarkAsBadFail(event?: React.ChangeEvent<HTMLInputElement>): void {
        setIsMarkAsBadFail(Boolean(event?.target.checked));
    }

    const missingParamsNotice = {
        title: 'Please change either the Intent, Policy ID, or LOB to proceed',
        mode: AlertMode.Info,
    };

    return (
        <Dialog
            actions={formActions}
            loading={isMutating || isLoadingTicket}
            notice={formState?.answers.reason === FixReason.IncorrectParameters ? [missingParamsNotice] : []}
            onClose={onClose}
            textAlign="left"
            title={title}
        >
            <FormDialogWrapper>
                <FormBuilder
                    form={formConfig}
                    initialValues={initialValues}
                    onFormStateChange={setFormState}
                    validationSchema={validationSchema}
                />
            </FormDialogWrapper>
            <Flex alignItems="center" gap="20px" mt={20}>
                <MarkAsBadFailLabel>Mark as bad fail</MarkAsBadFailLabel>
                <SwitchWrapper>
                    <Switch onSwitch={onToggleMarkAsBadFail} selected={isMarkAsBadFail} />
                </SwitchWrapper>
            </Flex>
        </Dialog>
    );
};
