import { Avatar } from '@lemonade-hq/bluis';
import { Flex, font, themedColor } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import { HtmlBody } from './HtmlBody';

export const mayaAvatarImg = `${__assets_url}blender_assets/backoffice/Maya.jpg`;
export const lemonadeLogoSvg = `${__assets_url}blender_assets/backoffice/lemonade_logo.svg`;

export const MAYA_AI_NAME = 'Maya AI';

export interface MessageLayoutProps {
    readonly datetime?: string;
    readonly email?: string;
    readonly html: string;
    readonly isAi?: boolean;
    readonly isLemonade?: boolean;
    readonly name?: string;
    readonly showMetadata?: boolean;
}

const Root = styled(Flex)`
    padding: 8px 8px 8px 0;
`;

const Name = styled.span`
    font-weight: 700;
    margin-right: 4px;
    word-break: break-all;
`;

const DateFormatted = styled.span`
    text-align: right;
`;

function getAvatarImg({
    isAi,
    isLemonade,
    name = MAYA_AI_NAME,
}: Pick<MessageLayoutProps, 'isAi' | 'isLemonade' | 'name'>): string | undefined {
    if (isLemonade) {
        if (name === MAYA_AI_NAME || isAi) {
            return mayaAvatarImg;
        }

        return lemonadeLogoSvg;
    }

    return undefined;
}

export const SectionContent = styled(Flex)`
    flex-direction: column;
    height: 100%;
    gap: 20px;
`;

const Metadata = styled(Flex)`
    ${font('main', { fontSize: '14px' })};
    color: ${themedColor('secondaryText')};
`;

export const MessageLayout: React.FC<MessageLayoutProps> = ({
    datetime,
    email,
    html,
    isAi,
    isLemonade,
    name = MAYA_AI_NAME,
    showMetadata = true,
}) => {
    const avatarImg = getAvatarImg({ isAi, name, isLemonade });

    return (
        <Root gap="24px">
            <div>
                <Avatar name={name} size="large" src={avatarImg} />
            </div>
            <Flex flexDirection="column" flexGrow={1} gap="16px">
                {showMetadata && (
                    <Metadata gap="12px" justifyContent="space-between">
                        <div>
                            <Name>{name}</Name>
                            {email != null && <span>({email})</span>}
                        </div>
                        <DateFormatted>{datetime}</DateFormatted>
                    </Metadata>
                )}
                <HtmlBody html={html} />
            </Flex>
        </Root>
    );
};
