import { CountryFlag, themedColor } from '@lemonade-hq/bluis';
import type React from 'react';
import styled from 'styled-components';
import { HeaderSection } from './common';
import type { HeaderSectionProps } from './common';

const Container = styled(HeaderSection)`
  flex-direction: column;
  height: 100%;
  gap: 5px;
`;

const StyledFlag = styled(CountryFlag)`
  font-size: 20px;
  margin-top: -4px;
`;

export const EntityHeaderLocationState = styled.span`
  text-align: center;
  color: ${themedColor('primaryText')};
  font-weight: 400;
`;

export interface HeaderLocationProps extends HeaderSectionProps {
  readonly country?: string;
  readonly state?: string;
}

export const HeaderLocation: React.FC<HeaderLocationProps> = ({ country, state }) =>
  country != null || state != null ? (
    <Container>
      {country != null && <StyledFlag countryCode={country} />}
      {state != null && <EntityHeaderLocationState>{state}</EntityHeaderLocationState>}
    </Container>
  ) : null;
