/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React, { Component } from 'react';
import classNames from 'classnames';
import './style.scss';

export const HeaderInfoBarContext = React.createContext({}) as any;

export const HeaderInfoBarConsumer = HeaderInfoBarContext.Consumer as any;

export default class HeaderInfoBarProvider extends Component<React.PropsWithChildren<unknown>> {
    state = {
        isVisible: false,
        mainText: '',
        subText: '',
        withSpinner: false,
        isError: false,
        style: {},
    };

    componentDidMount() {
        window.addEventListener('show-blender-error', this.onError);
    }

    componentWillUnmount() {
        window.removeEventListener('show-blender-error', this.onError);
    }

    onError = (settings: any) => {
        const {
            autoHideAfter = 3500,
            mainText = 'OOPS... SOMETHING WENT WRONG.',
            subText = 'Report a bug if this continues',
            isError = true,
        } = settings || {};

        this.showHeaderInfo({
            autoHideAfter,
            mainText,
            subText,
            isError,
        });
    };

    showHeaderInfo = (settings: any) => {
        if ((this.state as any).isActive) {
            return false;
        }

        const { mainText, subText, style, withSpinner, autoHideAfter, isError } = settings;

        this.setState({ isVisible: true, mainText, subText, isError, style, withSpinner }, () => {
            if (autoHideAfter) {
                setTimeout(this.hideHeaderInfo, autoHideAfter);
            }
        });

        return undefined;
    };

    hideHeaderInfo = () => {
        this.setState({ isVisible: false });
    };

    render() {
        const {
            showHeaderInfo,
            hideHeaderInfo,
            onError,
            props: { children },
            state: { isError, mainText, subText, style = {}, withSpinner, isVisible },
        } = this;
        const containerClassNames = classNames('header-info-bar', { error: isError, visible: isVisible });
        const largeTextClassNames = classNames('header-info-bar-primary-text', { 'with-spinner': withSpinner });

        return (
            <HeaderInfoBarContext.Provider value={{ showHeaderInfo, hideHeaderInfo, onError }}>
                <div className={containerClassNames} {...{ style }}>
                    <div>
                        <span className={largeTextClassNames}> {mainText} </span>
                        <span className="header-info-bar-sub-text"> {subText} </span>
                    </div>
                </div>
                {children}
            </HeaderInfoBarContext.Provider>
        );
    }
}
