import { ViewActions, ViewActionsTypes } from '../actions/viewActionsTypes';

export interface ViewState {
    isPrinting: boolean;
    printLayout: boolean;
}

const INITIAL_VIEW_STATE: ViewState = {
    isPrinting: false,
    printLayout: false,
};

export default function viewReducer(state = INITIAL_VIEW_STATE, action: ViewActionsTypes): ViewState {
    switch (action.type) {
        case ViewActions.SET_PRINT_MODE: {
            const { isPrinting } = action.payload;

            return { ...state, isPrinting };
        }
        case ViewActions.TOGGLE_PRINT_LAYOUT: {
            const { printLayout } = action.payload;

            return { ...state, printLayout };
        }
        case ViewActions.RESET_PRINT_MODE: {
            return { ...state, isPrinting: false, printLayout: false };
        }
        default: {
            return state;
        }
    }
}
