import { useForm, validateConditions } from '@lemonade-hq/blender-ui';
import type {
    ExtractRecordSchema,
    ExtractShapeFromPath,
    Infer,
    RecordLikeShape,
    RecordPath,
    RecordPathValidations,
    RecordSchemaPath,
} from '@lemonade-hq/maschema-schema';
import type { DefaultInferenceOptions } from '@lemonade-hq/maschema-schema/src/shape/shape.inference.options.types';
import get from 'lodash/get';
import type { PropsWithChildren, ReactNode } from 'react';
import { Children, isValidElement, useEffect } from 'react';
import type { PartialDeep } from 'type-fest';

export const VisibilityWrapper = <TSchema extends RecordLikeShape>({
    conditions,
    children,
}: PropsWithChildren<{
    readonly conditions: RecordPathValidations<TSchema>;
}>): ReactNode => {
    const { values, dispatch } = useForm<TSchema>();
    const shouldShow = validateConditions<TSchema>(conditions, values as PartialDeep<Infer<TSchema>>);

    useEffect(() => {
        if (!shouldShow)
            Children.forEach(children, child => {
                if (isValidElement(child) && 'schemaKey' in child.props) {
                    const key = (child.props as { schemaKey: RecordPath<TSchema> }).schemaKey;
                    const value = get(values, key);
                    if (value !== undefined) {
                        dispatch({
                            type: 'setValue',
                            key,
                            value: undefined as Infer<
                                ExtractShapeFromPath<TSchema, RecordSchemaPath<ExtractRecordSchema<TSchema>, string>>,
                                DefaultInferenceOptions
                            >,
                        });
                    }
                }
            });
    }, [dispatch, children, shouldShow, values]);

    return shouldShow ? children : null;
};
