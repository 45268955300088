import type { UseBaseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { blenderGeneral } from '../../../../apiClients';
import type { TimelineItem } from '../../shared/types';
import { usePessimisticMutation } from 'queries/MutationHooks';

const BASE_URL = '/api/v1/ai-playground';

export enum PlaygroundQueryKey {
    GetTicketData = 'TICKET_DATA',
    GetSimulationMessages = 'GET_SIMULATION_MESSAGES',
}

export interface TicketData {
    readonly customerCard: string;
    readonly preloadedMessage?: string;
}

export function useGetTicketData(
    ticketId: string,
    {
        onSuccess,
        onError,
    }: {
        readonly onSuccess?: (ticketData: TicketData) => void;
        readonly onError?: (error: unknown) => void;
    } = {}
): UseQueryResult<TicketData, unknown> {
    return useQuery({
        retry: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        queryKey: [PlaygroundQueryKey.GetTicketData, ticketId],
        queryFn: async () => {
            try {
                const url = `${BASE_URL}/ticket/${ticketId}`;
                const response = await blenderGeneral.get<{ readonly data: TicketData }>(url);
                onSuccess?.(response.data.data);
                return response.data.data;
            } catch (error) {
                onError?.(error);
                throw error;
            }
        },
    });
}

export interface SendMessageBody {
    readonly timeline: TimelineItem[];
    readonly ticketId: string;
    readonly toolsRevisionPublicId: string;
}

export function useSendSimulationMessage(): UseBaseMutationResult<TimelineItem[], unknown, SendMessageBody> {
    return usePessimisticMutation({
        mutationFn: async (body: SendMessageBody) => {
            const { ticketId, ...restBody } = body;

            const url = `${BASE_URL}/replays/${ticketId}`;

            const response = await blenderGeneral.post<{ readonly data: TimelineItem[] }>(url, restBody, {
                timeout: 600_000,
            });

            return response.data.data;
        },
        invalidateKeys: [[PlaygroundQueryKey.GetSimulationMessages]],
    });
}
