import { Card } from '@lemonade-hq/bluis';
import { themedColor } from '@lemonade-hq/boutique';
import { Flex } from '@lemonade-hq/cdk';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.8;
  }
`;
const Skeleton = styled.div<{ readonly width?: string; readonly isTitle?: boolean }>`
    background-color: ${themedColor('elementBackground')};
    border-radius: 24px;
    height: ${({ isTitle }) => (isTitle ? '18px' : '12px')};
    width: ${({ width }) => `${width}` || '100%'};
    animation: ${pulse} 0.7s ease-in-out infinite;
`;

const AvatarSkeleton = styled.div`
    background-color: ${themedColor('elementBackground')};
    border-radius: 50%;
    height: 32px;
    width: 32px;
    animation: ${pulse} 0.7s ease-in-out infinite;
`;

const StyledCard = styled(Card)`
    padding: 20px 24px;
`;

export const NotesSkeletons = (): JSX.Element => {
    return (
        <>
            <StyledCard>
                <Skeleton isTitle width="10%" />
            </StyledCard>
            {[1, 2, 3].map(i => (
                <StyledCard key={i}>
                    <Flex alignItems="center" gap="12px">
                        <AvatarSkeleton />
                        <Skeleton isTitle width="60%" />
                    </Flex>
                    <Flex flexDirection="column" gap="12px" ml="44px" mt="12px">
                        <Skeleton width="80%" />
                        <Skeleton width="80%" />
                        <Skeleton width="80%" />
                        <Skeleton width="80%" />
                        <Skeleton width="80%" />
                        <Skeleton width="40%" />
                    </Flex>
                </StyledCard>
            ))}
        </>
    );
};
