import { Currency } from '@lemonade-hq/lemonation';
import { ma, merge, va } from '@lemonade-hq/maschema-schema';
import {
    CoinsuranceVariant,
    DurationType,
    DurationUnit,
    EntityScopeValueSelectionMethod,
    InsuranceScopeType,
    SettingUnit,
    ValueType,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

const listValues = ma.record({
    type: ma.is(ValueType.List),
    values: ma.array(ma.number(), [va.elementsCount({ limit: 'min', count: 1 })]),
    includeUnlimited: ma.optional.boolean(),
});

const rangeValues = ma.record({
    type: ma.is(ValueType.Range),
    min: ma.number(),
    max: ma.number(),
    step: ma.number(),
});

const coinsuranceAttributes = ma.record({
    type: ma.is(SettingType.Coinsurance),
    variant: ma.oneOf(Object.values(CoinsuranceVariant)),
});

const deductibleAttributesData = {
    scope: ma.record({
        type: ma.oneOf([InsuranceScopeType.Policy, InsuranceScopeType.ExternalEntity]),
        name: ma.optional.string(),
        insuredEntityCode: ma.optional.string([
            va.if<string>({
                conditions: { 'scope.type': [va.is(InsuranceScopeType.InsuredEntity)] },
                validations: [va.required()],
            }),
        ]),
        valueSelectionMethod: ma.optional.oneOf(
            [EntityScopeValueSelectionMethod.PerEntity, EntityScopeValueSelectionMethod.Stacked],
            [
                va.if<EntityScopeValueSelectionMethod.PerEntity | EntityScopeValueSelectionMethod.Stacked>({
                    conditions: { 'scope.type': [va.is(InsuranceScopeType.InsuredEntity)] },
                    validations: [va.required()],
                }),
            ]
        ),
    }),
    unit: ma.oneOf(Object.values(SettingUnit)),
    currencyUnit: ma.optional.oneOf(Object.values(Currency), [
        va.if<Currency>({
            conditions: { unit: [va.is(SettingUnit.Currency)] },
            validations: [va.required()],
        }),
    ]),
    duration: ma.record({
        type: ma.oneOf(Object.values(DurationType)),
        amount: ma.optional.number([
            va.if<number>({
                conditions: { 'duration.type': [va.is(DurationType.Timespan)] },
                validations: [va.required()],
            }),
        ]),
        unit: ma.optional.oneOf(Object.values(DurationUnit), [
            va.if<DurationUnit>({
                conditions: { 'duration.type': [va.is(DurationType.Timespan)] },
                validations: [va.required()],
            }),
        ]),
    }),
};

const deductibleAttributes = ma.record({
    type: ma.is(SettingType.Deductible),
    ...deductibleAttributesData,
});

const limitAttributes = ma.record({
    type: ma.is(SettingType.Limit),
    ...deductibleAttributesData,
    parentLimitTemplateCode: ma.optional.string(),
});

const waitingPeriodAttributes = ma.record({
    type: ma.is(SettingType.WaitingPeriod),
    durationUnit: ma.oneOf(Object.values(DurationUnit)),
});

export const base = ma.record({
    templateCode: ma.string(),
    values: ma.union([listValues, rangeValues]),
    coupledSetting: ma.optional.record({
        coupledSettingTemplateCode: ma.string(),
        values: ma.array(
            ma.record({ currentSettingValue: ma.optional.number(), coupledSettingValue: ma.optional.number() })
        ),
    }),
    relatedCoverages: ma.array(ma.string()),
});

export const manageSettingDialogSchema = ma.union([
    merge(deductibleAttributes, base),
    merge(limitAttributes, base),
    merge(waitingPeriodAttributes, base),
    merge(coinsuranceAttributes, base),
]);
