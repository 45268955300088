import { useViewMode as useBoutiqueViewMode, ViewMode } from '@lemonade-hq/boutique';
import { handleThemeBodyClass, useViewMode as useBuiViewMode } from '@lemonade-hq/blender-ui';
import useViewModeEnabled from './useViewModeEnabled';
import { useEffect } from 'react';

export default function useViewMode(): ReturnType<typeof useBoutiqueViewMode> {
    const [viewMode, toggleViewMode] = useBoutiqueViewMode();
    const { toggle: toggleBuiViewMode } = useBuiViewMode();

    const viewModeEnabled = useViewModeEnabled();

    useEffect(() => {
        if (!viewModeEnabled) handleThemeBodyClass(ViewMode.Light);
    }, [viewModeEnabled]);

    const handleToggleViewMode = () => {
        toggleViewMode();
        toggleBuiViewMode();
    };

    return [viewModeEnabled ? viewMode : ViewMode.Light, handleToggleViewMode];
}
