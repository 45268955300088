export enum ViewActions {
    SET_PRINT_MODE = 'SET_PRINT_MODE',
    TOGGLE_PRINT_LAYOUT = 'TOGGLE_PRINT_LAYOUT',
    RESET_PRINT_MODE = 'RESET_PRINT_MODE',
}

interface SetPrintModeAction {
    type: ViewActions.SET_PRINT_MODE;
    payload: { isPrinting: boolean };
}

interface TogglePrintLayoutAction {
    type: ViewActions.TOGGLE_PRINT_LAYOUT;
    payload: { printLayout: boolean };
}

interface ResetPrintModeAction {
    type: ViewActions.RESET_PRINT_MODE;
}

export type ViewActionsTypes = SetPrintModeAction | TogglePrintLayoutAction | ResetPrintModeAction;
