import type { SettingType } from 'models/LoCo/Insurance/SettingType';

interface ProductLineTemplate {
    readonly code: string;
    readonly name: string;
}

export interface BaseRegistryTemplate {
    readonly code: string;
    readonly name: string;
    readonly description: string;
    readonly productLines: ProductLineTemplate[];
}

export type CoverageTemplate = BaseRegistryTemplate;

export interface SettingTemplate extends BaseRegistryTemplate {
    readonly type: SettingType;
}

export enum RegistryType {
    Coverage = 'coverages',
    Setting = 'settings',
}
