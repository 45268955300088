import { Tag as bluisTag, TagMode } from '@lemonade-hq/bluis';
import { font, themedColor } from '@lemonade-hq/cdk';
import styled from 'styled-components';

const StyledTag = styled(bluisTag)`
    ${font('main', { fontSize: '14px', fontWeight: 400 })};
    text-transform: unset;
    border-color: ${themedColor('separator')};
    background-color: ${themedColor('elementBackground')};
`;

export const Tag: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <StyledTag light mode={TagMode.Info} withStroke>
            {children}
        </StyledTag>
    );
};
