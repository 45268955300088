import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import { Text } from '../../base/Text/Text';
import { borderRadius } from '../../theme/radius.css';
import { spacing } from '../../theme/spacing.css';
import { Icon } from '../Icon/Icon';
import type { TooltipProps } from '../Tooltip/Tooltip';
import { Tooltip } from '../Tooltip/Tooltip';
import { highlightSlider, tab, tabList } from './Tabs.css';

interface TabButton {
  readonly label: string;
  readonly value: string;
  readonly tooltip?: Omit<TooltipProps, 'children'>;
  readonly onClick?: () => void;
}

interface TabButtonsProps {
  readonly buttons: TabButton[];
  readonly variant?: 'fill' | 'inline' | 'segmented';
  readonly size?: 'md' | 'sm';
  readonly startAtTabIndex?: number;
  readonly className?: string;
}

export const TabButtons: FC<TabButtonsProps> = ({
  buttons,
  variant = 'fill',
  size = 'md',
  startAtTabIndex = 0,
  className,
}) => {
  const [selected, setSelected] = useState(startAtTabIndex);
  const refs = useRef<HTMLButtonElement[]>([]);
  const [sliderStyle, setSliderStyle] = useState({
    left: refs.current[selected]?.offsetLeft,
    width: refs.current[selected]?.offsetWidth,
  });

  useEffect(() => {
    setSliderStyle({ left: refs.current[selected]?.offsetLeft, width: refs.current[selected]?.offsetWidth });
  }, [refs, selected]);

  const onTabClick = (index: number): void => {
    setSelected(index);
    buttons[index].onClick?.();
  };

  return (
    <Flex className={clsx(tabList({ variant, size }), className)}>
      {buttons.map(({ label, value, tooltip }, index) => (
        <button
          aria-selected={selected === index}
          className={tab({
            variant,
            isSelected: String(selected === index),
            size,
          })}
          key={value}
          onClick={() => onTabClick(index)}
          ref={(node: HTMLButtonElement) => {
            refs.current[index] = node;
          }}
          type="button"
        >
          <Text>{label}</Text>
          {tooltip != null && (
            <Tooltip content={tooltip.content}>
              <Flex px={spacing.s04}>
                <Icon color="neutral7" name="info-circle" />
              </Flex>
            </Tooltip>
          )}
        </button>
      ))}
      <Layout
        className={clsx(
          highlightSlider({ variant, size }),
          borderRadius({ borderRadius: variant === 'inline' ? undefined : 'sm' }),
        )}
        left={`${sliderStyle.left}px`}
        width={`${sliderStyle.width}px`}
      />
    </Flex>
  );
};
