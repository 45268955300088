import type { MultiSwitchOption } from '@lemonade-hq/bluis';
import type { Region } from '@lemonade-hq/lemonation';
import { FR_REGION, US_REGION } from '@lemonade-hq/lemonation';
import { AiResponseType } from '../shared/types';
import type { SupervisorMetadata, SupervisorTicketFilters } from '../shared/types';

export const SIDEBAR_WIDTH = '248px';

export const supervisorRegions: Region[] = [US_REGION, FR_REGION];

export const defaultFilters: SupervisorTicketFilters = {
    products: [],
    intents: [],
    mediums: [],
    languages: [],
    regions: [US_REGION],
};

export const defaultMetadata: SupervisorMetadata = {
    ...defaultFilters,
    intents: {
        tools: {},
        nonTools: {},
    },
    pendingTicketsCount: { draft: -1, public: -1 },
    reviewedTodayCount: 0,
    regions: supervisorRegions,
};

export const aiModeTypes: MultiSwitchOption[] = [
    {
        label: 'Draft',
        value: AiResponseType.Draft,
    },
    {
        label: 'Public',
        value: AiResponseType.Public,
    },
];
