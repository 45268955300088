import { spacing } from '@lemonade-hq/blender-ui';
import { TextArea } from '@lemonade-hq/bluis';
import { Flex, Text } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import styled from 'styled-components';
import { MAX_EXPLANATION_CHARS } from './underwritingUtils';

const StyledTextArea = styled(TextArea)<{ readonly width: string }>`
    width: 100%;
    max-width: ${({ width }) => width};
    height: 100px;
`;

interface FormTextAreaProps {
    readonly width: string;
    readonly disabled: boolean;
    readonly value: string;
    readonly onChange: (value: string) => void;
}

export const FormTextArea: React.FC<FormTextAreaProps> = ({ width, disabled, value, onChange }) => {
    return (
        <StyledTextArea
            charLimit={disabled ? undefined : MAX_EXPLANATION_CHARS}
            disabled={disabled}
            onChange={({ target }) => onChange(target.value)}
            value={value}
            width={width}
        />
    );
};

const StyledListItem = styled.li`
    text-align: start;
`;

interface BulletListSectionProps {
    readonly title?: JSX.Element | string;
    readonly content?: string;
    readonly bullets?: string[];
    readonly align?: 'center' | 'start';
}

export const BulletListSection: React.FC<BulletListSectionProps> = ({ title, content, bullets, align }) => {
    return (
        <>
            {isDefined(title) && <Text variant="body-md">{title}</Text>}
            {isDefined(content) && (
                <Text variant="body-md">
                    <b>{content}</b>
                </Text>
            )}
            {isDefined(bullets) && bullets.length > 0 && (
                <Flex justifyContent={align ?? 'center'} marginTop={spacing.s04}>
                    <ul>
                        {bullets.map(bullet => (
                            <StyledListItem key={bullet}>{bullet}</StyledListItem>
                        ))}
                    </ul>
                </Flex>
            )}
        </>
    );
};
