import { ContentSection, MainButton, Tab, TableTitle, TabList, TabPanel, TabPanels, Tabs } from '@lemonade-hq/bluis';
import { useState } from 'react';
import { LimitTable } from './SettingInstancesTable/LimitTable';
import { SuspendableContent } from 'components/LoCo/common/components/SuspendableContent';
import { getSettingTypeDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import { SettingInstancesDialogs } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/SettingInstancesDialogs';
import { CoInsuranceTable } from 'components/LoCo/editions/coverage-editions/Settings/SettingInstancesTable/CoInsuranceTable';
import { DeductibleTable } from 'components/LoCo/editions/coverage-editions/Settings/SettingInstancesTable/DeductibleTable';
import { WaitingPeriodTable } from 'components/LoCo/editions/coverage-editions/Settings/SettingInstancesTable/WaitingPeriodTable';
import { StyledSectionHeader } from 'components/LoCo/LoCoPagesSharedStyles';
import type { SettingActionData } from 'components/LoCo/products/SharedTableConfig';
import { DialogType } from 'components/LoCo/products/SharedTableConfig';
import type {
    CoinsuranceSettingInstance,
    CoveragesEdition,
    DeductibleSettingInstance,
    LimitSettingInstance,
    SettingInstance,
    WaitingPeriodSettingInstance,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

interface SettingInstancesTablesProps {
    readonly coveragesEdition: CoveragesEdition;
    readonly hideActions: boolean;
}

const tabStyles = { style: { marginTop: '30px' } };

export const SettingInstancesTables: React.FC<SettingInstancesTablesProps> = ({ coveragesEdition, hideActions }) => {
    const [dialog, setDialog] = useState<SettingActionData | null>(null);

    const settingByType: Map<SettingType, SettingInstance[]> = new Map();

    [SettingType.Limit, SettingType.Deductible, SettingType.Coinsurance, SettingType.WaitingPeriod].map(type =>
        settingByType.set(
            type,
            coveragesEdition.settings
                .filter(setting => setting.type === type)
                .sort((a, b) => a.name.localeCompare(b.name))
        )
    );

    const sharedTabProps = { hideActions, coveragesEdition, setDialog };

    return (
        <ContentSection>
            <StyledSectionHeader>
                <TableTitle title="Settings" />
                {!hideActions && (
                    <MainButton
                        onClick={() =>
                            setDialog({
                                type: DialogType.Add,
                                data: coveragesEdition.settings,
                                editionCoverages: coveragesEdition.coverages,
                            })
                        }
                    >
                        Add
                    </MainButton>
                )}
            </StyledSectionHeader>
            <Tabs>
                <TabList>
                    <Tab key={SettingType.Limit}>{getSettingTypeDisplayName(SettingType.Limit)}</Tab>
                    <Tab key={SettingType.Deductible}>{getSettingTypeDisplayName(SettingType.Deductible)}</Tab>
                    <Tab key={SettingType.Coinsurance}>{getSettingTypeDisplayName(SettingType.Coinsurance)}</Tab>
                    <Tab key={SettingType.WaitingPeriod}>{getSettingTypeDisplayName(SettingType.WaitingPeriod)}</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel {...tabStyles}>
                        <LimitTable
                            settingInstances={(settingByType.get(SettingType.Limit) ?? []) as LimitSettingInstance[]}
                            {...sharedTabProps}
                        />
                    </TabPanel>
                    <TabPanel {...tabStyles}>
                        <DeductibleTable
                            settingInstances={
                                (settingByType.get(SettingType.Deductible) ?? []) as DeductibleSettingInstance[]
                            }
                            {...sharedTabProps}
                        />
                    </TabPanel>

                    <TabPanel {...tabStyles}>
                        <CoInsuranceTable
                            settingInstances={
                                (settingByType.get(SettingType.Coinsurance) ?? []) as CoinsuranceSettingInstance[]
                            }
                            {...sharedTabProps}
                        />
                    </TabPanel>
                    <TabPanel {...tabStyles}>
                        <WaitingPeriodTable
                            settingInstances={
                                (settingByType.get(SettingType.WaitingPeriod) ?? []) as WaitingPeriodSettingInstance[]
                            }
                            {...sharedTabProps}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {dialog != null && (
                <SuspendableContent>
                    <SettingInstancesDialogs
                        dialog={dialog}
                        editionCode={coveragesEdition.code}
                        editionSettingInstances={coveragesEdition.settings}
                        onClose={() => setDialog(null)}
                    />
                </SuspendableContent>
            )}
        </ContentSection>
    );
};
