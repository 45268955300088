import { Image, themedColor } from '@lemonade-hq/bluis';
import { useCurrentPrincipal } from '@lemonade-hq/boutique';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { AdjusterAvailability, useUpdateAdjusterAvailability } from '../hooks';

const Container = styled.div`
    display: flex;
    gap: 14px;
    padding: 20px 20px 0;
`;

const Option = styled.label<{ readonly active: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 133px;
    height: 175px;
    margin: 0;
    padding: 16px 0 0;
    background: ${({ active }) => (active ? themedColor('elementBackground') : '')};
    border: 1px solid ${themedColor('separator')};
    border-radius: 5px;
    cursor: pointer;
`;

const ImageWrapper = styled.figure`
    display: flex;
    align-items: center;
    height: 55px;
    margin: 0;
`;

const Title = styled.span`
    margin-top: 11px;
    color: ${themedColor('primaryText')};
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
`;

const Subtitle = styled.span`
    margin: 2px 0 6px;
    padding: 0 14px;
    color: ${themedColor('secondaryText')};
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    white-space: pre;
`;

const StyledRadio = styled.span.attrs({ 'aria-hidden': true })`
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transition: transform 120ms ease-out, background 200ms ease-out;
    z-index: 1;
    transform: translate(-50%, -50%) scaleX(0) scaleY(0);
`;

const RadioContainer = styled.span`
    overflow: hidden;
    position: relative;
    width: 20px;
    height: 20px;
    background: ${themedColor('componentBackground')};
    border: 1px solid ${themedColor('inputBorder')};
    border-radius: 50%;

    & > input[type='radio'] {
        opacity: 0;
        appearance: none;

        &:checked,
        &:disabled {
            & + ${StyledRadio} {
                transform: translate(-50%, -50%) scaleX(1) scaleY(1);
            }
        }

        &:checked {
            & + ${StyledRadio} {
                background-color: ${themedColor('mainButtonBackground')};
            }
        }
    }
`;

const availabilityOptions = [
    {
        availability: AdjusterAvailability.Available,
        label: 'Available',
        subtitle: `You'll be assigned\nto new claims`,
        value: true,
        image: {
            darkSrc: '/blender_assets/backoffice/operator-availability/available-dark.svg',
            lightSrc: '/blender_assets/backoffice/operator-availability/available.png',
            width: 51,
            height: 38,
        },
    },
    {
        availability: AdjusterAvailability.Unavailable,
        label: 'Unavailable',
        subtitle: 'Sit back and\nrelax',
        value: false,
        image: {
            darkSrc: '/blender_assets/backoffice/operator-availability/unavailable-dark.svg',
            lightSrc: '/blender_assets/backoffice/operator-availability/unavailable.png',
            width: 48,
            height: 48,
        },
    },
];

interface AvailabilityProps {
    readonly adjusterId: string;
    readonly isAvailable: boolean;
}

export const Availability: React.FC<React.PropsWithChildren<AvailabilityProps>> = ({ adjusterId, isAvailable }) => {
    const { operator } = useCurrentPrincipal();
    const { mutate } = useUpdateAdjusterAvailability(operator?.region);
    const [available, setAvailable] = useState(isAvailable);

    const handleClick = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const value = ev.currentTarget.value as AdjusterAvailability;

            setAvailable(value === AdjusterAvailability.Available);
            mutate({ adjusterId, availability: value });
        },
        [adjusterId, mutate]
    );

    return (
        <Container>
            {availabilityOptions.map(option => (
                <Option active={option.value === available} key={option.availability}>
                    <ImageWrapper>
                        <Image img={option.image} />
                    </ImageWrapper>
                    <Title>{option.label}</Title>
                    <Subtitle>{option.subtitle}</Subtitle>
                    <RadioContainer>
                        <input
                            checked={option.value === available}
                            name="availability"
                            onChange={handleClick}
                            type="radio"
                            value={option.availability}
                        />
                        <StyledRadio />
                    </RadioContainer>
                </Option>
            ))}
        </Container>
    );
};
