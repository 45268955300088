import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import capitalize from 'lodash/capitalize';
import {
    formatSettingValuesList,
    formatSettingValuesRange,
} from 'components/LoCo/common/components/CoverageSettings/SettingValues';
import {
    coInsuranceVariantDisplayName,
    getDurationTimespan,
    getDurationTypeDisplayName,
    getInsuranceScopeTypeDisplayName,
    getSettingTypeDisplayName,
} from 'components/LoCo/common/display-texts/setting-instance';

import type {
    DeductibleSettingInstance,
    LimitSettingInstance,
    SettingInstance,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { DurationType, InsuranceScopeType, SettingUnit, ValueType } from 'models/LoCo/Insurance/CoveragesEdition';
import type { Product } from 'models/LoCo/Insurance/Product';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

export function getAttributesForSetting(
    setting: SettingInstance,
    product: Product,
    allSettingsInEdition: SettingInstance[]
): LabelValueItem[] {
    const items: LabelValueItem[] = [{ label: 'Type', value: getSettingTypeDisplayName(setting.type) }];

    switch (setting.type) {
        case SettingType.Limit: {
            items.push(...getUnit(setting));
            items.push(getScope(setting, product));
            items.push(getDuration(setting));
            const parentLimitSettingName = allSettingsInEdition.find(
                s => s.templateCode === setting.parentLimitTemplateCode
            )?.name;
            items.push({
                label: 'Parent Limit',
                value: parentLimitSettingName ?? '-',
            });
            break;
        }

        case SettingType.Deductible: {
            items.push({
                label: 'Currency',
                value: setting.currencyUnit,
            });

            items.push(getScope(setting, product));
            items.push(getDuration(setting));
            break;
        }

        case SettingType.WaitingPeriod: {
            items.push({ label: 'Duration unit', value: capitalize(setting.durationUnit) });
            break;
        }

        case SettingType.Coinsurance: {
            items.push({ label: 'Variant', value: coInsuranceVariantDisplayName(setting.variant) });
            break;
        }

        default:
            throw new Error(`Setting type ${(setting as SettingInstance).type} not supported`);
    }

    return items;
}

function getScope(setting: DeductibleSettingInstance | LimitSettingInstance, product: Product): LabelValueItem {
    let value: string;
    const scopeType = getInsuranceScopeTypeDisplayName(setting.scope.type);

    if (setting.scope.type === InsuranceScopeType.ExternalEntity) {
        value = `${scopeType} (${setting.scope.name})`;
    } else if (setting.scope.type === InsuranceScopeType.InsuredEntity) {
        const insuredEntityCode = setting.scope.insuredEntityCode;
        const insurableEntityName = product.insurableEntities.find(e => e.code === insuredEntityCode)?.name;
        if (insurableEntityName === undefined) {
            throw new Error(`Insurable entity with code ${insuredEntityCode} not found`);
        }

        value = `${scopeType} (${insurableEntityName})`;
    } else {
        value = scopeType;
    }

    return { label: 'Scope', value };
}

function getDuration(setting: DeductibleSettingInstance | LimitSettingInstance): LabelValueItem {
    const { duration } = setting;

    const valueSuffix = duration.type === DurationType.Timespan ? ` (${getDurationTimespan(duration)})` : '';

    return { label: 'Duration', value: getDurationTypeDisplayName(duration.type) + valueSuffix };
}

export function getUnit(setting: LimitSettingInstance): LabelValueItem[] {
    const items: LabelValueItem[] = [];
    if (setting.unit === SettingUnit.ClaimLossPercentage) {
        items.push({
            label: 'Unit',
            value: '% of Claim Loss Amount',
        });
    } else {
        items.push({
            label: 'Unit',
            value: capitalize(setting.unit),
        });
    }

    if (setting.currencyUnit) {
        items.push({
            label: 'Currency',
            value: setting.currencyUnit,
        });
    }

    return items;
}

export function settingValuesDisplay(settingInstance: SettingInstance): string {
    const values = settingInstance.values;
    const currency =
        settingInstance.type === SettingType.Limit || settingInstance.type === SettingType.Deductible
            ? settingInstance.currencyUnit
            : undefined;

    const valueSuffix =
        settingInstance.type === SettingType.Coinsurance ||
        (settingInstance.type === SettingType.Limit && settingInstance.unit === SettingUnit.ClaimLossPercentage)
            ? '%'
            : undefined;
    if (values.type === ValueType.List) {
        return formatSettingValuesList(values.values, values.includeUnlimited, valueSuffix, currency);
    }

    return formatSettingValuesRange(values, valueSuffix, currency).join(', ');
}
