import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type { RowItem, TableHeaderCellProps } from '@lemonade-hq/bluis';
import {
    ContentSection,
    EmptySection,
    ErrorSection,
    LoadingSection,
    Table,
    TableHeader,
    TableRow,
    TableTitle,
} from '@lemonade-hq/bluis';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { LabelWithTooltip } from '../coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedLabels';
import type { FieldEntry, FieldMetadata } from 'models/LoCo/Insurance/Schema';
import { useSuspenseGetRatingEditionSchema } from 'queries/LoCo/Insurance/RatingEditionQueries';

const AttributeRow: React.FC<{
    readonly field: Pick<FieldEntry, 'doc' | 'name'>;
    readonly fieldMetadata: FieldMetadata;
}> = ({ field, fieldMetadata }) => {
    const rowItems: RowItem[] = [
        { key: 'name', value: field.name },
        {
            key: 'type',
            value: isDefined(fieldMetadata.error) ? (
                <LabelWithTooltip label={fieldMetadata.displayType} tooltipContent={fieldMetadata.error} type="error" />
            ) : (
                fieldMetadata.displayType
            ),
        },
    ];

    return <TableRow key={field.name} row={rowItems} />;
};

const headerFields: TableHeaderCellProps[] = [
    { key: 'name', value: 'Name' },
    { key: 'type', value: 'Type' },
];

const AttributesTable: React.FC = () => {
    const { ratingEditionCode = '' } = useParams<{ ratingEditionCode: string }>();

    const { data } = useSuspenseGetRatingEditionSchema(ratingEditionCode);

    if (data.schema.fields.length === 0) {
        return <EmptySection>No schema found for this Rating edition</EmptySection>;
    }

    return (
        <Flex margin={`${spacing.s24} 0 0 0`}>
            <Table>
                <TableHeader headers={headerFields} />
                {data.schema.fields.map(field => (
                    <AttributeRow field={field} fieldMetadata={data.fieldsMetadata[field.name]} key={field.name} />
                ))}
            </Table>
        </Flex>
    );
};

export const RatingSchema: React.FC = () => {
    return (
        <ContentSection>
            <TableTitle title="Schema Attributes" />
            <ErrorBoundary fallback={<ErrorSection noBorders title="Error fetching schema attributes :(" />}>
                <Suspense fallback={<LoadingSection noBorders />}>
                    <AttributesTable />
                </Suspense>
            </ErrorBoundary>
        </ContentSection>
    );
};
