import { Flex, Text } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { resultsText } from './Attachments.css';
import type { AttachmentStats } from 'components/Attachments/types';

export const Results: FC<{ readonly stats: AttachmentStats }> = ({ stats }) => {
    const { totalRecords, currentPage, pageSize } = stats;
    const getResultsRange = (): string => {
        const start = (currentPage - 1) * pageSize + 1;
        const end = totalRecords / pageSize < currentPage ? totalRecords : currentPage * pageSize;
        return `${start}-${end}`;
    };

    if (totalRecords === 0) return null;

    return (
        <Flex alignItems="center">
            <Text className={resultsText} color="secondary">
                {getResultsRange()} of {totalRecords} Results
            </Text>
        </Flex>
    );
};
