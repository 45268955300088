import { Flex, IconButton, spacing, Spinner, Text, Tooltip } from '@lemonade-hq/blender-ui';
import { useToggle } from '@lemonade-hq/cdk';
import type { FC } from 'react';
import { ChangeTicketIdDialog } from './dialogs/ChangeTicketIdDialog';
import { CustomerCardDialog } from './dialogs/CustomerCardDialog';
import * as styles from './PlaygroundChat.css';
import type { TicketData } from './simulation.queries';

interface PlaygroundChatHeaderProps {
    readonly ticketId: string;
    readonly ticketData: TicketData | undefined;
    readonly isLoading: boolean;
    readonly onTicketReload: () => Promise<unknown>;
    readonly onTicketChange: (ticketId: string) => Promise<void>;
    readonly isTicketChangeError: boolean;
    readonly isTicketChangeSuccess: boolean;
}

export const PlaygroundChatHeader: FC<PlaygroundChatHeaderProps> = ({
    ticketId,
    ticketData,
    isLoading,
    onTicketReload,
    onTicketChange,
    isTicketChangeError,
    isTicketChangeSuccess,
}) => {
    const {
        open: handleOpenCustomerCardDialog,
        close: handleCloseCustomerCardDialog,
        isOpen: isCustomerCardDialogOpen,
    } = useToggle(false);
    const {
        open: handleOpenChangeTicketDialog,
        close: handleCloseChangeTicketDialog,
        isOpen: isChangeTicketIdDialogOpen,
    } = useToggle(false);

    return (
        <>
            <Flex alignItems="center" flexDirection="row" gap={spacing.s12} position="relative">
                {/* Titles */}
                <Flex flexDirection="column">
                    <Text textTransform="none" type="h5">
                        Chat
                    </Text>
                    <Flex alignItems="center" flexDirection="row" gap={spacing.s08}>
                        <Text color="secondary" type="text-md">
                            Ticket ID: {ticketId}
                        </Text>
                        <IconButton
                            className={styles.changeTicketButton}
                            color="neutral7"
                            disabled={isLoading}
                            icon="pencil-solid"
                            onClick={handleOpenChangeTicketDialog}
                            size="sm"
                            variant="inline"
                        />
                        {isLoading && <Spinner size="sm" />}
                    </Flex>
                </Flex>
                {/* Side Actions */}
                <Flex alignItems="center" gap={spacing.s04} marginInlineStart="auto">
                    <Tooltip content="Start over">
                        <IconButton
                            color="neutral7"
                            disabled={isLoading}
                            icon="restart"
                            onClick={() => void onTicketReload()}
                            size="lg"
                            variant="inline"
                        />
                    </Tooltip>
                    <Tooltip content="Customer card">
                        <IconButton
                            color="neutral7"
                            disabled={ticketData?.customerCard == null}
                            icon="user-profile"
                            onClick={handleOpenCustomerCardDialog}
                            size="lg"
                            variant="inline"
                        />
                    </Tooltip>
                </Flex>
            </Flex>
            {isCustomerCardDialogOpen && (
                <CustomerCardDialog content={ticketData?.customerCard} onClose={handleCloseCustomerCardDialog} />
            )}
            {isChangeTicketIdDialogOpen && (
                <ChangeTicketIdDialog
                    className={styles.changeTicketDialog}
                    isError={isTicketChangeError}
                    isSuccess={isTicketChangeSuccess}
                    onClose={handleCloseChangeTicketDialog}
                    onSubmit={onTicketChange}
                    ticketId={ticketId}
                />
            )}
        </>
    );
};
