import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { ThemedColors } from 'bluis/Colors';

export const Wrapper = styled.button<{ readonly size: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: 0;
    padding: 0;
    background-color: transparent;

    ${({ size }) => css`
        width: ${size}px;
        height: ${size}px;
    `}
`;

const Inner = styled.div<{ readonly size: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0;
    outline: 0;
    background-color: ${ThemedColors.secondaryButtonBackground};
    border-radius: 50%;
    border: 1px solid ${ThemedColors.secondaryButtonBorder};

    ${({ size }) => css`
        width: ${size}px;
        height: ${size}px;
    `}

    &:hover {
        background-color: ${ThemedColors.secondaryButtonBackgroundHover};
    }
`;

interface CloseRoundButtonProps {
    readonly size?: number;
    readonly onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const CloseRoundButton: FC<React.PropsWithChildren<CloseRoundButtonProps>> = ({ size = 14, onClick }) => {
    return (
        <Wrapper className="close-round-button" onClick={onClick} size={size}>
            <Inner size={size}>
                <svg height={size / 2 - 1} viewBox="0 0 9 9" width={size / 2 - 1} xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M8.305 1L1 8.305"
                                    fill="none"
                                    stroke={ThemedColors.secondaryText}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="20"
                                    strokeWidth="1.8"
                                />
                            </g>
                            <g>
                                <path
                                    d="M1 1l7.305 7.305"
                                    fill="none"
                                    stroke={ThemedColors.secondaryText}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="20"
                                    strokeWidth="1.8"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </Inner>
        </Wrapper>
    );
};

export default CloseRoundButton;
