import { Flex, Select, spacing, Text, TextArea } from '@lemonade-hq/blender-ui';
import { trackEvent } from '@lemonade-hq/boutique';
import type { FC } from 'react';
import type { AttachmentDTO } from '../../types';
import { AttachmentDialogBase } from './AttachmentDialogBase';
import { useArchive } from 'components/Attachments/ActionHooks/useArchive';
import { useAttachmentsData } from 'components/Attachments/context';
import { getAttachmentAnalyticsParam } from 'components/Attachments/utils';

export const ArchiveDialog: FC<{
    readonly attachments: AttachmentDTO[];
    readonly onClose: () => void;
}> = ({ attachments, onClose }) => {
    const { entityType, entityPublicId } = useAttachmentsData();
    const {
        reasons,
        reason,
        otherReason,
        isArchivePending,
        isArchiveError,
        isReasonsError,
        isReasonsLoading,
        setReason,
        setOtherReason,
        handleSubmit,
    } = useArchive({
        entityPublicId,
        entityType,
        attachments,
        archivingReason: attachments[0].archivingReason,
        onClose,
    });

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.gallery.clicked', {
            ...(attachments.length === 1 && {
                ...getAttachmentAnalyticsParam({
                    attachment: attachments[0],
                    entityType,
                    entityId: entityPublicId,
                }),
            }),
            name: 'archive',
            source: 'button',
            ...(attachments.length > 1 && { is_bulk: 'true' }),
        });
        await handleSubmit();
    };

    return (
        <AttachmentDialogBase
            attachments={attachments}
            error={
                isArchiveError
                    ? 'Error archiving attachments'
                    : isReasonsError
                      ? 'Error loading archive reasons'
                      : undefined
            }
            isLoading={isReasonsLoading || isArchivePending}
            isSubmitDisabled={reason === '' || (reason === 'other' && otherReason === '') || isArchivePending}
            onClose={onClose}
            onSubmit={onSubmit}
            submitLabel="Archive"
            title="Archive"
        >
            <Flex flexDirection="column" gap={spacing.s12}>
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        {attachments[0].suggestedArchive === true ? 'Suggested archive reason' : 'Archive reason'}
                        <Text as="span" color="error">
                            &nbsp;*
                        </Text>
                    </Text>
                    <Select<never, { value: string; label: string }>
                        disabled={isReasonsLoading}
                        onChange={value => setReason(value ?? '')}
                        options={reasons}
                        placeholder="Select archive reason"
                        selectedKey={reason}
                    />
                </Flex>
                {reason === 'other' && (
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Other reason
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                        </Text>
                        <TextArea
                            autoExpanding
                            maxLength={500}
                            onChange={e => setOtherReason(e.target.value)}
                            rows={5}
                            value={otherReason}
                        />
                    </Flex>
                )}
            </Flex>
        </AttachmentDialogBase>
    );
};
