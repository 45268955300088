import { DateTimePicker, FormInputWrapper, InfoIcon } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import moment from 'moment';
import { useCallback } from 'react';
import styled from 'styled-components';
import { dialogStepItemsWrapper, NoticeWrapper, stepTitle, stepWrapper } from './ManageReleaseDialog.css';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import { DialogStep, DialogStepWrapper } from 'components/LoCo/common/components/Dialog/Dialog';
import { VersionType } from 'models/LoCo/Insurance/BaseEdition';

export type EffectiveDatesStepType = {
    readonly newBusiness: Date | null;
    readonly renewals: Date | null;
    readonly isValid: boolean | null;
};

const StyledFormInputWrapper = styled(FormInputWrapper)`
    width: 360px;
`;

function validateStep(versionType: VersionType | null, newBusiness: Date | null, renewals: Date | null): boolean {
    if (versionType === VersionType.BugFix) {
        return true;
    }

    return [newBusiness, renewals].every(date => {
        const res = date !== null && moment(date).isBetween(moment(), moment().add(2, 'month'), 'day', '[]');
        return res;
    });
}

function getNoticeText(versionType: VersionType | null): JSX.Element | null {
    switch (versionType) {
        case VersionType.BugFix:
            return (
                <>
                    As a <b>bug fix</b>, the effective dates will be set automatically to match those of the edition
                    set(s) being targeted for the fix. After the release is published, impacted quotes and policies
                    should be migrated to the fixed configurations via a separate process.
                </>
            );
        case VersionType.Minor:
            return (
                <>
                    As a <b>product update release</b>, the new business effective date is also when the release becomes
                    available for existing quotes and policies if they are edited.
                </>
            );
        default:
            return null;
    }
}

interface EffectiveDatesProps {
    readonly selectedStrategy: VersionType | null;
    readonly effectiveDates: DeepNullable<EffectiveDatesStepType>;
    readonly onChange: (newEffectiveDates: DeepNullable<EffectiveDatesStepType>) => void;
}

export const EffectiveDates: React.FC<EffectiveDatesProps> = ({ selectedStrategy, onChange, effectiveDates }) => {
    const bannerText = getNoticeText(selectedStrategy);

    const handleDateChange = useCallback(
        (key: 'newBusiness' | 'renewals', date: Date) => {
            const dates = {
                ...effectiveDates,
                [key]: date,
            };
            onChange({
                ...dates,
                isValid: validateStep(selectedStrategy, dates.newBusiness, dates.renewals),
            });
        },
        [effectiveDates, onChange, selectedStrategy]
    );

    const minDate = moment().add(-1, 'day').toDate();
    const maxDate = moment().add(2, 'month').toDate();

    return (
        <DialogStepWrapper>
            <DialogStep padding="40px 64px">
                <Flex className={stepWrapper}>
                    <Flex className={stepTitle}>When should these changes take effect?</Flex>
                    <Flex className={dialogStepItemsWrapper}>
                        <StyledFormInputWrapper label="New Business">
                            <DateTimePicker
                                disabled={selectedStrategy === VersionType.BugFix}
                                maxDate={maxDate}
                                minDate={minDate}
                                onChange={date => handleDateChange('newBusiness', date)}
                                value={effectiveDates.newBusiness ?? undefined}
                            />
                        </StyledFormInputWrapper>
                        <StyledFormInputWrapper label="Renewals">
                            <DateTimePicker
                                disabled={selectedStrategy === VersionType.BugFix}
                                maxDate={maxDate}
                                minDate={minDate}
                                onChange={date => handleDateChange('renewals', date)}
                                value={effectiveDates.renewals ?? undefined}
                            />
                        </StyledFormInputWrapper>
                    </Flex>
                </Flex>
            </DialogStep>
            {bannerText != null && (
                <Flex className={NoticeWrapper}>
                    <InfoIcon />
                    <div>{bannerText}</div>
                </Flex>
            )}
        </DialogStepWrapper>
    );
};
