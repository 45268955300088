import { colors } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';

export const Doc: FC = () => {
    return (
        <svg fill="none" height="26" viewBox="0 0 20 26" width="20" xmlns="http://www.w3.org/2000/svg">
            <rect fill={colors.light} height="26" rx="4" width="20" />
            <rect fill={colors.neutral5} height="2" rx="1" width="13" x="3" y="7" />
            <rect fill={colors.neutral5} height="2" rx="1" width="13" x="3" y="11" />
            <rect fill={colors.neutral5} height="2" rx="1" width="7" x="3" y="15" />
        </svg>
    );
};

export const Xlc: FC = () => {
    return (
        <svg fill="none" height="26" viewBox="0 0 20 26" width="20" xmlns="http://www.w3.org/2000/svg">
            <rect fill={colors.light} height="26" rx="4" width="20" />
            <path
                d="M14 11H6C4.89543 11 4 11.8954 4 13V21.5C4 22.3284 4.67157 23 5.5 23H14.5C15.3284 23 16 22.3284 16 21.5V13C16 11.8954 15.1046 11 14 11Z"
                stroke={colors.neutral5}
                strokeWidth="2"
            />
            <path
                d="M4 14V15H5H15H16V14V13C16 11.8954 15.1046 11 14 11H6C4.89543 11 4 11.8954 4 13V14Z"
                stroke={colors.neutral5}
                strokeWidth="2"
            />
            <path
                d="M4 18V19H5H15H16V18V12.5C16 11.6716 15.3284 11 14.5 11H5.5C4.67157 11 4 11.6716 4 12.5V18Z"
                stroke={colors.neutral5}
                strokeWidth="2"
            />
            <path
                d="M9 12V11H8H6C4.89543 11 4 11.8954 4 13V21C4 22.1046 4.89543 23 6 23H8H9V22V12Z"
                stroke={colors.neutral5}
                strokeWidth="2"
            />
            <rect fill={colors.neutral5} height="2" rx="1" width="8" x="3" y="5" />
        </svg>
    );
};

export const Zip: FC = () => {
    return (
        <svg fill="none" height="22" viewBox="0 0 27 22" width="27" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 2.99898C0 1.56058 1.16605 0.394531 2.60445 0.394531H7.09252C7.93137 0.394531 8.74899 0.658252 9.42974 1.14839L10.953 2.24512C11.6337 2.73526 12.4513 2.99898 13.2902 2.99898H22.3428C24.5631 2.99898 26.3585 4.80725 26.3427 7.02746L26.2644 18.0275C26.2487 20.2255 24.4625 21.999 22.2645 21.999H4C1.79086 21.999 0 20.2082 0 17.999V2.99898Z"
                fill={colors.light}
            />
            <rect fill={colors.neutral5} height="7" rx="1" width="4" x="19" y="5" />
            <line stroke={colors.neutral5} stroke-dasharray="2 2" strokeWidth="2" x1="21" x2="21" y1="3" y2="22" />
            <rect fill={colors.light} height="5" rx="0.5" width="2" x="20" y="6" />
            <path
                d="M14.0735 14.1232L14.0735 14.1232L14.0751 14.1237C14.2128 14.1663 14.3227 14.2276 14.4078 14.3056C14.4938 14.3844 14.5584 14.4799 14.602 14.5936C14.6459 14.7082 14.6687 14.8384 14.6687 14.9856C14.6687 15.1413 14.6457 15.2802 14.6014 15.4036C14.5577 15.5224 14.4915 15.6232 14.4026 15.7073C14.317 15.7882 14.2066 15.8534 14.0686 15.9012C13.9318 15.9458 13.7678 15.9693 13.575 15.9693H12.8561V14.0548H13.575C13.7711 14.0548 13.9366 14.0784 14.0735 14.1232ZM15.8148 15.8092L15.8149 15.8088C15.9151 15.5568 15.9646 15.282 15.9646 14.9856C15.9646 14.7 15.9165 14.4348 15.8191 14.1911C15.7236 13.9454 15.5759 13.7341 15.3768 13.5581C15.1771 13.3815 14.9265 13.2464 14.6281 13.1507C14.3279 13.0544 13.9762 13.0074 13.575 13.0074H11.6602H11.5602V13.1074V18.9985V19.0985H11.6602H12.7561H12.8561V18.9985V17.0248H13.575C13.9658 17.0248 14.311 16.9764 14.6087 16.8771C14.9073 16.7785 15.159 16.6392 15.3611 16.4573C15.5625 16.276 15.714 16.0597 15.8148 15.8092Z"
                fill={colors.neutral5}
                stroke={colors.neutral5}
                strokeWidth="0.2"
            />
            <path
                d="M10.0155 19.0985H10.1155V18.9985V13.1074V13.0074H10.0155H8.91553H8.81553V13.1074V18.9985V19.0985H8.91553H10.0155Z"
                fill={colors.neutral5}
                stroke={colors.neutral5}
                strokeWidth="0.2"
            />
            <path
                d="M7.68951 13.9108L7.68953 13.9108L7.69043 13.9094C7.77242 13.7896 7.81383 13.6559 7.81383 13.5108V13.1074V13.0074H7.71383H3.35457H3.25457V13.1074V13.9793V14.0793H3.35457H6.10295L3.18327 18.2116L3.18326 18.2116L3.18253 18.2126C3.14791 18.263 3.1198 18.3177 3.09807 18.3765C3.07463 18.4371 3.06309 18.5007 3.06309 18.5667V18.9985V19.0985H3.16309H7.64457H7.74457V18.9985V18.1226V18.0226H7.64457H4.77827L7.68951 13.9108Z"
                fill={colors.neutral5}
                stroke={colors.neutral5}
                strokeWidth="0.2"
            />
            <rect fill={colors.neutral5} height="1" rx="0.5" width="4" x="19" y="9" />
        </svg>
    );
};

export const Video: FC = () => {
    return (
        <svg fill="none" height="20" viewBox="0 0 28 20" width="28" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V16C28 18.2091 26.2091 20 24 20H4C1.79086 20 0 18.2091 0 16V4Z"
                fill={colors.light}
            />
            <rect fill={colors.neutral5} height="3" rx="0.5" width="3" x="3" y="3" />
            <rect fill={colors.neutral5} height="3" rx="0.5" width="3" x="3" y="8.5" />
            <rect fill={colors.neutral5} height="3" rx="0.5" width="3" x="3" y="14" />
            <rect fill={colors.neutral5} height="3" rx="0.5" width="3" x="22" y="3" />
            <rect fill={colors.neutral5} height="3" rx="0.5" width="3" x="22" y="8.5" />
            <rect fill={colors.neutral5} height="3" rx="0.5" width="3" x="22" y="14" />
            <path
                d="M17.6617 9.53268C18.0019 9.75137 18.0019 10.2486 17.6617 10.4673L11.4669 14.4497C11.0971 14.6874 10.6109 14.4219 10.6109 13.9824L10.6109 6.01759C10.6109 5.57805 11.0971 5.31259 11.4669 5.55027L17.6617 9.53268Z"
                fill={colors.neutral5}
            />
        </svg>
    );
};

export const Audio: FC = () => {
    return (
        <svg fill="none" height="20" viewBox="0 0 28 20" width="28" xmlns="http://www.w3.org/2000/svg">
            <rect fill={colors.light} height="20" rx="4" width="28" />
            <rect fill={colors.neutral5} height="14" rx="1" width="2" x="13" y="3" />
            <rect fill={colors.neutral5} height="10" rx="1" width="2" x="17" y="5" />
            <rect fill={colors.neutral5} height="4" rx="1" width="2" x="21" y="8" />
            <rect fill={colors.neutral5} height="4" rx="1" width="2" x="5" y="8" />
            <rect fill={colors.neutral5} height="10" rx="1" width="2" x="9" y="5" />
        </svg>
    );
};

export const Unknown: FC = () => {
    return (
        <svg fill="none" height="26" viewBox="0 0 20 26" width="20" xmlns="http://www.w3.org/2000/svg">
            <rect fill={colors.light} height="26" rx="4" width="20" />
            <path
                clip-rule="evenodd"
                d="M10.2366 6.90039V6.90044C10.9975 6.90044 11.8013 7.20735 12.42 7.73335C13.056 8.27398 13.548 9.09405 13.548 10.124C13.548 10.9202 13.3263 11.5521 12.9713 12.0687C12.6377 12.5541 12.2077 12.9004 11.8975 13.1495C11.2174 13.6957 11.0355 13.872 11.0537 14.3436C11.075 14.8955 10.6448 15.3601 10.093 15.3813C9.54109 15.4026 9.07646 14.9725 9.05519 14.4206C8.99666 12.9021 9.95748 12.1375 10.5562 11.6611C10.5869 11.6367 10.6166 11.613 10.6452 11.5901C10.9684 11.3305 11.1767 11.1488 11.3231 10.9358C11.448 10.754 11.548 10.5174 11.548 10.124C11.548 9.77533 11.391 9.48362 11.1246 9.25712C10.8426 9.01739 10.4932 8.90177 10.241 8.90045C9.86584 8.90502 9.60092 9.00306 9.3835 9.15905C9.15035 9.32633 8.9158 9.60142 8.68216 10.0391C8.42206 10.5263 7.81626 10.7104 7.32905 10.4503C6.84185 10.1902 6.65774 9.58436 6.91784 9.09715C7.24213 8.48972 7.65659 7.93653 8.21762 7.53402C8.79334 7.12097 9.46627 6.90791 10.2269 6.90049L10.2366 6.90039Z"
                fill={colors.neutral5}
                fillRule="evenodd"
            />
            <path
                d="M10.0364 18.9C10.704 18.9 11.2452 18.3588 11.2452 17.6912C11.2452 17.0236 10.704 16.4824 10.0364 16.4824C9.36883 16.4824 8.82764 17.0236 8.82764 17.6912C8.82764 18.3588 9.36883 18.9 10.0364 18.9Z"
                fill={colors.neutral5}
            />
        </svg>
    );
};
