import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import {
    getNonFiledRules,
    getRelatedCoveragesAndRulesFromSetting,
    getRelatedSettingsAndRulesFromCoverage,
} from '../getCoveragesAndRules';
import type {
    CoverageRelatedSettingsAndRules,
    CoverageRuleDisplayLog,
    SettingsRelatedCoveragesAndRules,
} from '../types';
import { titlize, toReadable } from 'commons/StringUtils';
import type { CoverageRulesUpdatedEntry } from 'models/LoCo/Insurance/ChangesLog';
import { RuleEntityType } from 'models/LoCo/Insurance/CoverageRule';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';
import type { DigitalAgentEdition } from 'models/LoCo/Insurance/DigitalAgentEdition';

interface CoverageRulesUpdatedProps {
    readonly change: CoverageRulesUpdatedEntry;
    readonly coveragesEdition: CoveragesEdition | null;
    readonly digitalAgentEdition: DigitalAgentEdition | null;
    readonly baseCoveragesEdition: CoveragesEdition | null;
    readonly baseDigitalAgentEdition: DigitalAgentEdition | null;
}

export const CoverageRulesUpdated: FC<CoverageRulesUpdatedProps> = ({
    baseCoveragesEdition,
    baseDigitalAgentEdition,
    change,
    coveragesEdition,
    digitalAgentEdition,
}) => {
    let originalRules: CoverageRuleDisplayLog[] | null;
    let updatedRules: CoverageRuleDisplayLog[];
    let name: string;

    if (coveragesEdition !== null) {
        const getter =
            change.metadata.entityType === RuleEntityType.Coverage
                ? getRelatedSettingsAndRulesFromCoverage
                : getRelatedCoveragesAndRulesFromSetting;

        const updated = getter({
            code: change.metadata.entityCode,
            coveragesEdition,
            digitalAgentEdition,
        });

        updatedRules = updated.rules;
        name = isCoverageRelatedSettingsAndRules(updated) ? updated.coverage.name : updated.setting.name;

        originalRules =
            baseCoveragesEdition && baseDigitalAgentEdition
                ? getter({
                      code: change.metadata.entityCode,
                      coveragesEdition: baseCoveragesEdition,
                      digitalAgentEdition: baseDigitalAgentEdition,
                  }).rules
                : null;
    } else {
        // If coverages edition is null, that means this is a change from a digital agent edition
        // that does not come from an edition set but from a standalone edition
        assertDigitalAgentEditionIsNotNull(digitalAgentEdition);

        updatedRules = getNonFiledRules(
            change.metadata.entityType,
            change.metadata.entityCode,
            digitalAgentEdition.entityGroups
        );

        originalRules = getNonFiledRules(
            change.metadata.entityType,
            change.metadata.entityCode,
            baseDigitalAgentEdition?.entityGroups ?? []
        );

        name = change.metadata.entityCode;
    }

    const rows: LabelValueItem[] = [];

    const { filed, nonFiled } = change.metadata;

    const addRow = (value: number, label: string): void => {
        if (value > 0) rows.push({ label, value });
    };

    addRow(filed.restriction.added, 'Filed restriction rules added');
    addRow(filed.initial_value.added, 'Filed default rules added');
    addRow(nonFiled.restriction.added, 'Non-filed restriction rules added');
    addRow(nonFiled.initial_value.added, 'Non-filed default rules added');
    addRow(filed.restriction.removed, 'Filed restriction rules removed');
    addRow(filed.initial_value.removed, 'Filed default rules removed');
    addRow(nonFiled.restriction.removed, 'Non-filed restriction rules removed');
    addRow(nonFiled.initial_value.removed, 'Non-filed default rules removed');
    addRow(filed.restriction.updated, 'Filed restriction rules edited');
    addRow(filed.initial_value.updated, 'Filed default rules edited');
    addRow(nonFiled.restriction.updated, 'Non-filed restriction rules edited');
    addRow(nonFiled.initial_value.updated, 'Non-filed default rules edited');

    if (coveragesEdition !== null && change.metadata.entityType === RuleEntityType.Setting) {
        const setting = coveragesEdition.settings.find(s => s.templateCode === change.metadata.entityCode);
        if (setting?.coupledSetting?.coupledSettingTemplateCode !== undefined) {
            rows.push({ label: 'Coupled with', value: setting.coupledSetting.coupledSettingName });
        }
    }

    return (
        <ChangeAccordion
            generalTabData={rows}
            originalRules={originalRules}
            ruleType="coverage"
            title={`Coverage rules updated - ${titlize(toReadable(name))}`}
            updatedRules={updatedRules}
        />
    );
};

function isCoverageRelatedSettingsAndRules(
    value: CoverageRelatedSettingsAndRules | SettingsRelatedCoveragesAndRules
): value is CoverageRelatedSettingsAndRules {
    return 'coverage' in value;
}

function assertDigitalAgentEditionIsNotNull(
    digitalAgentEdition: DigitalAgentEdition | null
): asserts digitalAgentEdition is DigitalAgentEdition {
    if (digitalAgentEdition === null) {
        throw new Error('Digital agent edition is null');
    }
}
