import { forwardRef } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import { emptySectionWrapper } from './EmptySection.css';

interface Props {
  readonly children: string;
  readonly subtitle?: string;
}

export const EmptySection = forwardRef<HTMLDivElement, Props>(({ children, subtitle }, ref) => {
  return (
    <Flex className={emptySectionWrapper({ titleOnly: subtitle == null || subtitle === '' })} ref={ref}>
      <Text type="h2">{children}</Text>
      {subtitle != null && <Text type="text-sm">{subtitle}</Text>}
    </Flex>
  );
});
