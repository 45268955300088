export function serializeSerializers<T>(serializers: T[]): string {
    return serializers.sort().join(',');
}

export type SnakeCaseToCamelCase<T> = T extends `${infer P}_${infer Q}`
    ? `${P}${Capitalize<SnakeCaseToCamelCase<Q>>}`
    : T;

export function typeSafeSnakeCaseToCamelCase<T extends string>(str: T): SnakeCaseToCamelCase<T> {
    return str.replace(/(_\w)/g, m => m[1].toUpperCase()) as SnakeCaseToCamelCase<T>;
}
