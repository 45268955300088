import { ActionsMenu, copyToClipboard, toast } from '@lemonade-hq/bluis';

export enum TemplateAction {
    Edit = 'edit',
    CopyText = 'copyText',
}

interface RegistryTemplateActionsProps {
    readonly onACtion: (action: TemplateAction) => void;
    readonly entityName: string;
    readonly copyText: string;
}

export const RegistryTemplateActions: React.FC<RegistryTemplateActionsProps> = ({ onACtion, entityName, copyText }) => {
    return (
        <ActionsMenu
            actions={[
                {
                    label: 'Edit',
                    value: 'edit',
                    onClick: () => onACtion(TemplateAction.Edit),
                },
                {
                    label: `Copy ${entityName} Code`,
                    value: 'copyText',
                    onClick: () => {
                        copyToClipboard(copyText);
                        toast.info(`Copied!`, { duration: 2000 });
                    },
                },
            ]}
            type="dots"
        />
    );
};
