export enum ProductTypes {
    Home = 'home',
    Pet = 'pet',
    Car = 'car',
    Life = 'life',
}

export enum EntityTypes {
    HomeClaim = 'home_claim',
    HomeEuClaim = 'home_eu_claim',
    HomePolicy = 'home_policy',
    PetClaim = 'pet_claim',
    PetPreapproval = 'pet_preapproval',
    PetPolicy = 'pet_policy',
    CarClaim = 'car_claim',
    CarPolicy = 'car_policy',
    UnderwritingReview = 'uw_review',
    User = 'user',
}

export enum BundledProductTypes {
    Home = 'home',
    Renters = 'renters',
    Pet = 'pet',
    Car = 'car',
    Life = 'life',
    HomesiteHomeowners = 'homesite_homeowners',
}

export enum CoreEntityType {
    Policy = 'policy',
    Quote = 'quote',
    Claim = 'claim',
    User = 'user',
}
