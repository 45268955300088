/* eslint-disable react/no-array-index-key */
import { spacing } from '@lemonade-hq/blender-ui';
import { Card, Dialog } from '@lemonade-hq/bluis';
import type { FC, PropsWithChildren } from 'react';
import * as styles from '../../../shared/debugLogs.css';
import type { ParsedPrompt } from '../../../shared/types';
import { PromptRenderer } from 'components/Llmnd/shared/components/PromptRenderer';

interface PromptDetailsDialogProps {
    readonly usedPrompts: Record<string, ParsedPrompt>;
    readonly toolInvoked: string;
    readonly onClose: () => void;
}

export const PromptDetailsDialog: FC<PropsWithChildren<PromptDetailsDialogProps>> = ({
    usedPrompts,
    toolInvoked,
    onClose,
}) => {
    return (
        <Dialog closeOnOutsideClick onClose={onClose} size="x-large" title={`Prompt Details - ${toolInvoked}`}>
            <Card className={styles.debugLogContainer} padding={spacing.s12}>
                {Object.values(usedPrompts).map((prompt, i) => (
                    <PromptRenderer key={i} prompt={prompt} />
                ))}
            </Card>
        </Dialog>
    );
};
