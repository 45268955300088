import { ArgumentType } from './expressionTypes';
import type { InputFunction } from './operators';
import { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';

/**
 * Creates additional functions for the schema of expressions.
 * It uses coverages and settings instances from the context of the current Coverages Edition to generate
 * additional functions
 *
 * ### Example
 *
 * a `selectedCoverage` function which gets a coverage instance as an argument and returns the value of that coverage
 *
 * ```typescript
 * function selectedCoverage(coverage: Coverages): 'On' | 'Off'
 * ```
 *
 * @param coveragesNames - coverages instances on the current Coverages Edition
 * @param settingsNames - settings instances on the current Coverages Edition
 * @param lifecycleContexts - lifecycle contexts of the current Coverages Edition
 * @returns
 */
export function getAdditionalFunctions(
    coveragesNames: string[],
    settingsNames: string[],
    lifecycleContexts: RuleLifecycleContext[]
): Record<string, InputFunction> {
    const showPrevious = [RuleLifecycleContext.Renewal, RuleLifecycleContext.Moving].some(context =>
        lifecycleContexts.includes(context)
    );

    const settingInputBase = {
        argument: {
            type: ArgumentType.Enum,
            symbols: settingsNames,
            enumName: 'Settings',
        },
        returnType: {
            type: ArgumentType.Number,
        },
    } as InputFunction;

    const coverageInputBase = {
        argument: {
            type: ArgumentType.Enum,
            symbols: coveragesNames,
            enumName: 'Coverages',
        },
        returnType: {
            type: ArgumentType.Enum,
            symbols: ['On', 'Off'],
            enumName: 'CoverageStatus',
        },
    } as InputFunction;

    let functions: Record<string, InputFunction> = {
        selectedCoverage: {
            ...coverageInputBase,
            display: 'Selected Coverage',
        },
        selectedSetting: {
            ...settingInputBase,
            display: 'Selected Value',
        },
    };

    if (showPrevious) {
        functions = {
            ...functions,
            previousCoverage: {
                ...coverageInputBase,
                display: 'Previous Selected Coverage',
            },
            previousSetting: {
                ...settingInputBase,
                display: 'Previous Selected Value',
            },
        };
    }

    return functions;
}
