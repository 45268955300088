import axios from 'axios';
import { Cause } from 'models/User';

export async function getAllCauses(): Promise<Cause[]> {
    const url = '/backoffice/causes/';

    return axios.get<{ data: Cause[] }>(url).then(response => response.data.data);
}

export interface UpdateCauseParams {
    userId: string;
    causeId: string;
}

export async function updateCause({ userId, causeId }: UpdateCauseParams): Promise<null> {
    const url = `/backoffice/user_causes/${userId}`;

    const body = {
        attribute: 'cause_id',
        cause_id: causeId,
    };

    return axios.put(url, body).then(res => res.data);
}
