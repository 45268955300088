import { AnalyticsProvider, useCurrentPrincipal } from '@lemonade-hq/boutique';
import React, { PropsWithChildren } from 'react';

export const EnsureAnalytics: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { operator, principal } = useCurrentPrincipal();
    const operatorId = operator?.id ?? principal.subject;

    return (
        <AnalyticsProvider segmentKey={import.meta.env.VITE_SEGMENT_KEY} operatorId={operatorId}>
            {children}
        </AnalyticsProvider>
    );
};
