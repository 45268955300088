import type { Edition } from './BaseEdition';
import type { UnderwritingDeclineRule, UnderwritingFlagRule } from './UnderwritingFiltersEdition';

export enum VariantType {
    Control = 'control',
    Test = 'test',
}

export interface VariantRuleSet {
    readonly variantName: string;
    readonly rules: UnderwritingFlagRule[];
    readonly variantType: VariantType;
}

export enum Mode {
    ABTest = 'ab_test',
    Plain = 'plain',
}

interface RuleGroupBase {
    readonly flagCode: string;
    readonly flagName: string;
    readonly flagDescription: string;
    readonly mode: Mode;
}

export interface ABTestRuleGroup extends RuleGroupBase {
    readonly mode: Mode.ABTest;
    readonly variants: VariantRuleSet[];
    readonly experimentName: string;
}

interface PlainRuleGroup extends RuleGroupBase {
    readonly mode: Mode.Plain;
    readonly rules: UnderwritingFlagRule[];
}

export const isABTestRuleGroup = (group: RuleGroupBase): group is ABTestRuleGroup => group.mode === Mode.ABTest;

export type UnderwritingFlagRulesGroup = ABTestRuleGroup | PlainRuleGroup;

export interface LifecycleContextDeclineRules {
    readonly newBusinessDeclineRules: UnderwritingDeclineRule[];
    readonly renewalDeclineRules: UnderwritingDeclineRule[];
}

export interface UnderwritingFiltersEdition extends Edition {
    readonly declineRules: LifecycleContextDeclineRules;
    readonly flagRulesGroup: UnderwritingFlagRulesGroup[];
}

export interface CreateABTestRequest {
    readonly experimentName: string;
    readonly control: string;
    readonly variants: string[];
}

export interface UnbindABTestRequest {
    readonly variantToKeep: string;
}

export interface AddRemoveVariantsRequest {
    readonly variantNames: string[];
}

export interface RenameVariantsRequest {
    readonly experimentName: string;
    readonly variants?: {
        readonly oldName: string;
        readonly newName: string;
    }[];
}

interface ABTestParamsBase {
    readonly uwFiltersEditionCode: string;
    readonly flagCode: string;
}

export interface AttachABTestParams extends ABTestParamsBase {
    readonly payload: CreateABTestRequest;
}

export interface UnbindABTestParams extends ABTestParamsBase {
    readonly payload: UnbindABTestRequest;
}

export interface AddRemoveVariantsParams extends ABTestParamsBase {
    readonly payload: AddRemoveVariantsRequest;
}

export interface RenameVariantsParams extends ABTestParamsBase {
    readonly payload: RenameVariantsRequest;
}
