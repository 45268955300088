import React from 'react';
import styled, { css } from 'styled-components';
import { ThemedColors } from './Colors';

const StyledToggle = styled.div<{ readonly checked: boolean }>`
    cursor: pointer;
    box-sizing: border-box;
    background: ${ThemedColors.lightBackground};
    border: 1px solid ${ThemedColors.separatorColor};
    appearance: none;
    outline: none;
    margin: 0;
    padding: 0;
    transition: all 0.2s ease-out;
    will-change: opacity, border-color, background-color;
    width: 40px;
    height: 25px;
    border-radius: 100.8px;

    &:before {
        content: '';
        display: block;
        left: 2px;
        top: 1px;
        background-color: ${ThemedColors.componentBackground};
        position: relative;
        transition: left 0.2s ease-out;
        will-change: left;
        width: 21px;
        height: 21px;
        border-radius: 100.8px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    ${props =>
        props.checked &&
        css`
            background-color: ${ThemedColors.primaryColor};

            &:before {
                left: 16px;
            }
        `}

    &[disabled] {
        opacity: 0.7;

        &:before {
            box-shadow: none;
        }
    }
`;

interface ToggleProps {
    readonly checked: boolean;
    readonly onChange?: (checked: boolean) => void;
    readonly disabled?: boolean;
    readonly onToggleCallback?: (e: React.MouseEvent<HTMLElement>) => void;
}

/**
 * @deprecated
 * use Switch from @lemonade-hq/bluis instead
 */
const Toggle: React.FC<React.PropsWithChildren<ToggleProps>> = ({
    checked = false,
    onChange,
    onToggleCallback,
    ...restProps
}) => {
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        onToggleCallback?.(e);

        if (!restProps.disabled) {
            onChange?.(!checked);
        }
    };

    return <StyledToggle checked={checked} onClick={onClick} {...restProps} />;
};

export default Toggle;
