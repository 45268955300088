import type { Infer, RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import get from 'lodash/get';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import type { TextAreaProps as BUITextAreaProps } from '../../../TextArea/TextArea';
import { TextArea as BUITextArea } from '../../../TextArea/TextArea';
import { useForm } from '../../FormContext';
import type { CommonAdapterProps } from '../common';
import { useConnectToForms } from '../common';
import type { AssertSchemaKeyIsOfTypeString } from '../shape-assertion.types';

type BaseProps = Omit<BUITextAreaProps, 'onChange' | 'value'>;

export type AssertedTextAreaProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = BaseProps &
  (TextAreaProps<TSchema, TSchemaKey> extends infer TExtractedProps extends {
    readonly schemaKey: infer TSchemaKeyToCheck extends TSchemaKey;
  }
    ? AssertSchemaKeyIsOfTypeString<TSchema, TSchemaKeyToCheck, TExtractedProps>
    : never);

type TextAreaProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = BaseProps &
  CommonAdapterProps<TSchema, TSchemaKey>;

export const TextArea = <TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(props): ReactNode => {
  const { schemaKey, rules, ...restProps } = props as TextAreaProps<TSchema, TSchemaKey>;
  const { values: data, dispatch } = useForm<TSchema>();
  const { disabled, visible } = useConnectToForms({ schemaKey, rules });

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch({
        type: 'setValue',
        key: schemaKey as RecordPath<TSchema>,
        value: e.target.value as Infer<TSchema>[RecordPath<TSchema>],
      });
    },
    [dispatch, schemaKey],
  );

  return visible ? (
    <BUITextArea
      disabled={disabled}
      id={schemaKey}
      onBlur={() => dispatch({ type: 'blur', key: schemaKey })}
      onChange={onChange}
      value={get(data, schemaKey)}
      {...restProps}
    />
  ) : null;
};
