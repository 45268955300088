import { Flex, Layout, spacing, Text } from '@lemonade-hq/blender-ui';
import type { FC, PropsWithChildren } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { centerThumbnails, thumbnail, thumbnailsList, thumbnailsWrapper } from './Carousel.css';
import { useCarousel } from './CarouselProvider';
import { AttachmentThumbnail } from 'components/Attachments/AttachmentThumbnail';
import { useAttachmentsData } from 'components/Attachments/context';

const ScrollToSelectedListItem: FC<
    PropsWithChildren<{ readonly iseSelected: boolean; readonly className: string }>
> = ({ iseSelected, className, children }) => {
    const itemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (iseSelected && itemRef.current) {
            itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [iseSelected]);

    return (
        <Layout className={className} ref={itemRef}>
            {children}
        </Layout>
    );
};

export const Thumbnails: FC = () => {
    const { attachments, entityPublicId, entityType } = useAttachmentsData();
    const { currentIndex, goToSlide } = useCarousel();

    const handleClick = useCallback(
        (index: number) => {
            goToSlide(index);
        },
        [goToSlide]
    );

    return (
        <Flex alignItems="center" className={thumbnailsWrapper}>
            <Text as="span" display="block" flex="0 0 75px" px={spacing.s16} type="text-sm">
                {currentIndex + 1} / {attachments.length}
            </Text>
            <Layout className={centerThumbnails}>
                <Flex className={thumbnailsList}>
                    {attachments.map((attachment, index) => (
                        <ScrollToSelectedListItem
                            className={thumbnail({ isSelected: index === currentIndex })}
                            iseSelected={index === currentIndex}
                            key={attachment.publicId}
                        >
                            <AttachmentThumbnail
                                attachment={attachment}
                                entityPublicId={entityPublicId}
                                entityType={entityType}
                                key={attachment.publicId}
                                onClick={() => handleClick(index)}
                                showPreview={false}
                            />
                        </ScrollToSelectedListItem>
                    ))}
                </Flex>
            </Layout>
        </Flex>
    );
};
