import type { OrderByDirection } from '@lemonade-hq/bluis';
import axios from 'axios';
import { stringify } from 'qs';
import { stringifyUrl } from 'query-string';
import { getUrlResolver } from 'commons/UrlResolver';
import type { Alert } from 'models/Alert';
import type { createLandlordParams } from 'models/HomeClaim';
import type {
    Country,
    Form,
    HomePolicyData,
    HomePolicyListItem,
    Mortgage,
    PolicyRatingAttribute,
    PropertyForm,
    ReplacementCostData,
    Source,
    State,
    Status,
} from 'models/HomePolicy';
import type { HeaderColumn } from 'models/HomeShared';
import { EntityTypes } from 'models/Products';
import type { HomePolicyRes } from 'queries/HomePolicyQueries';

const homeBlenderUrlResolver = getUrlResolver('home-blender');

export interface HomePoliciesSearchParamsResponse {
    readonly forms: { readonly value: PropertyForm; readonly name: Form }[];
    readonly countries: Country[];
    readonly states: State[];
    readonly statuses: Status[];
    readonly sources?: Source[];
}

export enum SortColumn {
    UserFullName = 'user_full_name',
    Price = 'premium',
    Status = 'logical_status_rank',
    Type = 'type',
    State = 'state',
    Country = 'country',
    PolicyCreationDate = 'policy_creation_date',
    EffectiveDate = 'effective_date',
}

export interface PolicySearchSort {
    readonly column: SortColumn;
    readonly direction: OrderByDirection;
}

export interface SearchFilter {
    readonly country?: string;
    readonly statuses?: Status[];
    readonly state?: string;
    readonly type?: PropertyForm | 'ALL';

    readonly source?: Source | 'ALL';
}

export interface HomePoliciesSearchParams {
    readonly page: number;
    readonly limit: number;
    readonly sort?: PolicySearchSort;
    readonly filter?: SearchFilter;
}

export interface HomePoliciesSearchResponse {
    readonly policies: HomePolicyListItem[];
    readonly offset: number;
    readonly page_size: number;
    readonly size: number;
    readonly total: number;
}

const OLD_BASE_URL = '/backoffice/policies';

export type HomePolicyIncludeKeysResponse<K extends keyof HomePolicyData> = {
    readonly data: Pick<HomePolicyData, K>;
    readonly error: { readonly [key in K]: string | undefined };
};

export async function fetchHomePolicyIncludeKeys<K extends keyof HomePolicyData>(
    publicPolicyId: string,
    keys: K[]
): Promise<HomePolicyIncludeKeysResponse<K>> {
    const url = stringifyUrl(
        {
            url: homeBlenderUrlResolver(`/api/v1/policies/${publicPolicyId}`),
            query: {
                include: keys,
            },
        },
        { arrayFormat: 'comma' }
    );

    return await axios.get(url).then(response => response.data.data);
}

export async function fetchHomePolicyData<K extends keyof HomePolicyData>(
    publicPolicyId: string,
    keys: K[]
): Promise<Pick<HomePolicyData, K>> {
    return (await fetchHomePolicyIncludeKeys(publicPolicyId, keys)).data;
}

export async function fetchSearchParams(): Promise<HomePoliciesSearchParamsResponse> {
    const url = homeBlenderUrlResolver('/api/v1/policies/query_params');

    return await axios.get<{ data: HomePoliciesSearchParamsResponse }>(url).then(response => response.data.data);
}

export async function searchHomePolicies(searchParams: HomePoliciesSearchParams): Promise<HomePoliciesSearchResponse> {
    const queryString = stringify(searchParams, { skipNulls: true });
    const url = homeBlenderUrlResolver(`/api/v1/policies?${queryString}`);

    return await axios.get<{ data: HomePoliciesSearchResponse }>(url).then(response => response.data.data);
}

export async function setTestPolicy({
    policyId,
    testPolicy,
}: {
    readonly policyId: string;
    readonly testPolicy: boolean;
}): Promise<null> {
    const url = `/backoffice/policies/${policyId}/set_test_state`;
    const data = {
        test: testPolicy,
    };

    return await axios.post(url, data).then(response => response.data);
}

interface SimulateRes {
    readonly annual_difference: string;
    readonly monthly_premium_diff: string;
    readonly new_annual_premium: string;
    readonly new_monthly_premium: string;
    readonly new_installment_premium: string;
    readonly installment_premium_diff: string;
}

export const BaseDeductibleAttr = 'base_deductible';

export async function simulatePolicyChange({
    policyId,
    value,
    attr,
    day_care_children_amount,
    limit,
    first_floor_or_lower,
}: {
    readonly policyId: number | string;
    readonly value: string[] | boolean | number;
    readonly attr: string;
    readonly day_care_children_amount?: number;
    readonly limit?: number | string;
    readonly first_floor_or_lower?: boolean;
}): Promise<SimulateRes> {
    const url = `/backoffice/policies/simulate`;
    const data = {
        id: policyId,
        attribute: attr,
        value,
        limit,
        day_care_children_amount,
        first_floor_or_lower,
    };

    return await axios.post(url, data).then(response => response.data);
}

export type ScheduleNonrenewalData = {
    readonly policyId: string;
    readonly reasonCodes: string[];
    readonly emailContent: string;
    readonly note: string;
    readonly blockAddress: boolean;
    readonly blockUser: boolean;
};

export async function scheduleNonrenewal({
    policyId,
    reasonCodes,
    emailContent,
    note,
    blockUser,
    blockAddress,
}: ScheduleNonrenewalData): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/policy_nonrenewal`);
    const body = {
        blockAddress,
        blockUser,
        description: note,
        emailContent,
        reasonCodes,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function policyNonrenewalRevert({ policyId }: { readonly policyId: string }): Promise<null> {
    const url = `/backoffice/policies/${policyId}/policy_nonrenewal`;

    return await axios.delete(url).then(response => response.data);
}

export async function updatePolicy({
    id,
    value,
    attr,
}: {
    readonly id: number | string;
    readonly value: number | string;
    readonly attr: string;
}): Promise<null> {
    const url = `/backoffice/policies/${id}`;
    const body = {
        attribute: attr,
        value,
    };

    return await axios.put(url, body).then(res => res.data);
}

export async function updatePropertyDetails({
    policyId,
    updatedAttributes,
}: {
    readonly policyId: string;
    readonly updatedAttributes: Partial<Record<PolicyRatingAttribute, string[] | boolean | number | string>>;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/property`);

    return await axios.put(url, updatedAttributes).then(response => response.data);
}

export async function getReplacementCostsCalc(
    policyId: string,
    updatedAttributes: Partial<Record<PolicyRatingAttribute, string[] | boolean | number | string>>
): Promise<ReplacementCostData> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/calculate_replacement_cost`);

    return (await axios.get(url, { params: updatedAttributes })).data.data;
}

export async function getPolicyPDF(policyId: string): Promise<Pick<HomePolicyRes, 'policy_pdf'>> {
    return await fetchHomePolicyData(policyId, ['policy_pdf']);
}

export async function getPolicyEnPDF(policyId: string): Promise<Pick<HomePolicyRes, 'policy_en_pdf'>> {
    return await fetchHomePolicyData(policyId, ['policy_en_pdf']);
}

export async function getDeclarationPagePDF(policyId: string): Promise<Pick<HomePolicyRes, 'declaration_page_pdf'>> {
    return await fetchHomePolicyData(policyId, ['declaration_page_pdf']);
}

export async function getRatingData(policyId: string): Promise<Pick<HomePolicyRes, 'rating_data'>> {
    return await fetchHomePolicyData(policyId, ['rating_data']);
}

export async function getDataExport(policyId: string): Promise<void> {
    const url = `/backoffice/exports`;

    const body = {
        entity_type: EntityTypes.HomePolicy,
        entity_ids: [policyId],
        filename: null,
        sections: null,
    };

    return await axios.post(url, body).then(response => response.data);
}

export interface PaymentPlanData {
    readonly title: string;
    readonly payment_plan: string;
    readonly premium_change: number;
    readonly discount: number;
    readonly last_installment?: boolean;
}

export interface PaymentPlansData {
    readonly current: string;
    readonly options: PaymentPlanData[];
}

export async function getPaymentPlans(policyId: string): Promise<PaymentPlansData> {
    const url = `/backoffice/home/policies/${policyId}/payment_plans`;

    return await axios.get(url).then(response => response.data);
}

export async function removeProtectiveDevice({
    policyId,
    category,
    type,
}: {
    readonly policyId: string;
    readonly category: string;
    readonly type: string;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/remove_protective_device`);
    const body = {
        category,
        type,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function addProtectiveDevice({
    policyId,
    category,
    type,
}: {
    readonly policyId: string;
    readonly category: string;
    readonly type: string;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/add_protective_device`);
    const body = {
        category,
        type,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function removeFactor(policyId: string, factor: string): Promise<void> {
    const url = `/backoffice/policies/${policyId}/factor/${factor}`;

    return await axios.put(url).then(response => response.data);
}

interface PreviewDocAttributes {
    readonly name: string;
    readonly isaoa: string | null;
    readonly poBox: string | null;
    readonly address: string | null;
    readonly clauseName: string | null;
    readonly loanNumber: string | null;
    readonly automatedChecks: boolean;
    readonly invoiceNumber: string | null;
    readonly invoiceAddress: {
        readonly line1?: string | null;
        readonly line2?: string | null;
        readonly city?: string | null;
        readonly state?: string | null;
        readonly zip?: string | null;
    } | null;
}

export async function mortgagePreviewDoc(
    policyId: string,
    attributes: PreviewDocAttributes,
    docType: string
): Promise<{
    readonly data: {
        readonly doc_type: string;
        readonly presigned_url: string;
    };
}> {
    const url = '/backoffice/mortgage_docs/preview';

    const { name, clauseName, isaoa, address, poBox, loanNumber, automatedChecks, invoiceNumber, invoiceAddress } =
        attributes;

    const isInvoice = docType === 'invoice';
    const templateParams = {
        mortgagee_name: name,
        mortgagee_clause_name: clauseName,
        mortgagee_isaoa: isaoa,
        mortgagee_po_box: poBox,
        mortgagee_address: address,
        loan_number: loanNumber,
    };

    const invoiceParams = isInvoice
        ? {
              automated_checks: automatedChecks,
              invoice_number: invoiceNumber,
          }
        : {};

    const invoiceAddressParams =
        isInvoice && Object.keys(invoiceAddress ?? {}).length > 0
            ? {
                  invoice_address_line1: invoiceAddress?.line1,
                  invoice_address_line2: invoiceAddress?.line2,
                  invoice_address_city: invoiceAddress?.city,
                  invoice_address_state: invoiceAddress?.state,
                  invoice_address_zip: invoiceAddress?.zip,
              }
            : {};

    const body = {
        policy_id: policyId,
        doc_type: docType,
        template_params: { ...templateParams, ...invoiceParams, ...invoiceAddressParams },
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function mortgageGenerateInvoiceOrReceipt(policyId: string): Promise<{ readonly data: string }> {
    const url = `/backoffice/policies/${policyId}/payment_invoice_receipt`;

    return await axios.get(url).then(response => response.data);
}

export async function getLenderPortalLink(mortgageeId: number): Promise<{ readonly data: string }> {
    const url = `/backoffice/mortgages/${mortgageeId}/lender_portal_link`;

    return await axios.get(url).then(response => response.data);
}

export async function removeMortgagee({
    mortgageeId,
    policyId,
}: {
    readonly mortgageeId: number;
    readonly policyId: string;
}): Promise<null> {
    const url = `/backoffice/mortgages/${mortgageeId}?policy_id=${policyId}`;

    return await axios.delete(url);
}

export async function removeSecondaryInsured({ policyId }: { readonly policyId: string }): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/secondary_name`);

    const body = {
        secondaryFirstName: '',
        secondaryLastName: '',
        secondaryEmail: '',
    };

    return await axios.post(url, body);
}

export async function removeUserAdditionalContact({
    userPublicId,
    contactPublicId,
}: {
    readonly userPublicId: string;
    readonly contactPublicId: string;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/users/${userPublicId}/contacts/${contactPublicId}`);

    return await axios.delete(url);
}

export async function mortgageSubmitDocsToLender(mortgageId: number): Promise<void> {
    const url = `/backoffice/mortgages/${mortgageId}/submit`;

    return await axios.post(url).then(response => response.data);
}

export async function getMortgage(mortgageId: number): Promise<Mortgage> {
    const url = `/backoffice/mortgages/${mortgageId}`;

    return await axios.get(url).then(response => response.data);
}

export async function removeInterestedParty({
    id,
    policyId,
    requestedBy,
    removalReason,
}: {
    readonly id: number;
    readonly policyId: string;
    readonly requestedBy: string;
    readonly removalReason: string;
}): Promise<null> {
    const url = `/backoffice/interested_parties/${id}`;

    const body = {
        interested_party: {
            requested_by: requestedBy,
            reason: removalReason,
            policy_id: policyId,
        },
    };

    return await axios.delete(url, { data: body });
}

export async function updateSecondaryInsured({
    policyId,
    firstName,
    lastName,
    email,
}: {
    readonly policyId: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/secondary_name`);

    const data = {
        secondaryFirstName: firstName,
        secondaryLastName: lastName,
        secondaryEmail: email,
    };

    return await axios.post(url, data);
}

export async function addInterestedParty({
    policyId,
    name,
    email,
    address,
}: {
    readonly policyId: string;
    readonly name: string;
    readonly email: string;
    readonly address: string;
}): Promise<null> {
    const url = `/backoffice/interested_parties/`;

    const body = {
        interested_party: {
            name,
            email,
            address,
            policy_id: policyId,
        },
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function editInterestedParty({
    memberId,
    policyId,
    name,
    email,
    address,
}: {
    readonly memberId: number;
    readonly policyId: string;
    readonly name: string;
    readonly email: string;
    readonly address: string;
}): Promise<null> {
    const url = `/backoffice/interested_parties/${memberId}`;

    const body = {
        interested_party: {
            name,
            email,
            address,
            policy_id: policyId,
        },
    };

    return await axios.put(url, body).then(response => response.data);
}

export interface EditMortgageeAttributes {
    readonly name: string;
    readonly clause: string;
    readonly isaoa: string;
    readonly poBox: string;
    readonly address: string;
    readonly loanNumber: string;
    readonly primaryContactMethod: string;
    readonly email?: string;
    readonly faxNumber?: string;
    readonly phone?: string;
    readonly lenderId?: number | undefined;
}

export async function editMortgagee({
    mortgageId,
    policyId,
    attributes,
    notifyLender,
}: {
    readonly mortgageId: number;
    readonly policyId: string;
    readonly attributes: EditMortgageeAttributes;
    readonly notifyLender: string;
}): Promise<null> {
    const url = `/backoffice/mortgages/${mortgageId}`;
    const { name, clause, isaoa, poBox, address, loanNumber, primaryContactMethod, email, faxNumber, phone } =
        attributes;

    const body = {
        mortgagee: {
            name,
            clause,
            isaoa,
            po_box: poBox,
            address,
            loan_number: loanNumber,
            policy_id: policyId,
            primary_contact_method: primaryContactMethod || null,
            email: email ?? null,
            fax_number: faxNumber ?? null,
            phone: phone ?? null,
            override_lender_notification: notifyLender,
        },
    };

    return await axios.put(url, body).then(response => response.data);
}

export async function editVerifiedMortgagee({
    mortgageeId,
    lenderId,
    loanNumber,
    notifyLender,
}: {
    readonly mortgageeId: number;
    readonly lenderId: number;
    readonly loanNumber: string;
    readonly notifyLender: string;
}): Promise<null> {
    const url = `/backoffice/mortgages/${mortgageeId}`;

    const body = {
        mortgagee: {
            lender_id: lenderId,
            loan_number: loanNumber,
            override_lender_notification: notifyLender,
        },
    };

    return await axios.put(url, body).then(response => response.data);
}

export async function addMortgage({
    policyId,
    attributes,
    notifyLender,
}: {
    readonly policyId: string;
    readonly attributes: EditMortgageeAttributes;
    readonly notifyLender: string;
}): Promise<null> {
    const url = '/backoffice/mortgages';

    const { name, clause, isaoa, poBox, address, loanNumber, primaryContactMethod, email, faxNumber, phone, lenderId } =
        attributes;

    const body = {
        context: 'mortgage_added',
        mortgagee: {
            name,
            clause,
            isaoa,
            po_box: poBox,
            address,
            loan_number: loanNumber,
            policy_id: policyId,
            primary_contact_method: primaryContactMethod || null,
            email: email ?? null,
            fax_number: faxNumber ?? null,
            phone: phone ?? null,
            lender_id: lenderId,
            override_lender_notification: notifyLender,
        },
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function createLandlord({
    policyPublicId,
    landlord,
}: {
    readonly policyPublicId: string;
    readonly landlord: createLandlordParams;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyPublicId}/upsert_landlord`);

    return await axios.post(url, landlord).then(response => response.data);
}

export async function resendPolicyToCustomer(policyId: string): Promise<void> {
    const url = `/backoffice/policies/${policyId}/resend`;

    return await axios.post(url).then(response => response.data);
}

export async function resendTermsOfService(policyId: string): Promise<void> {
    const url = `/backoffice/policies/${policyId}/resend_user_tos_sign_email`;

    return await axios.post(url).then(response => response.data);
}

export async function createRenewal({ policyId }: { readonly policyId: string }): Promise<null> {
    const url = `/backoffice/policies/${policyId}/create_renewal`;

    return await axios.post(url).then(response => response.data);
}

export async function processRenewalForNotice(publicPolicyId: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${publicPolicyId}/process_renewal_for_notice`);

    return await axios.post(url).then(response => response.data);
}

export async function revertSchedulePolicyCancellation({ policyId }: { readonly policyId: string }): Promise<null> {
    const url = `/backoffice/policies/${policyId}/scheduled_cancellations`;

    return await axios.delete(url).then(response => response.data);
}

export type CancelPolicyData = {
    readonly type: string;
    readonly reason?: string;
    readonly subtype?: string;
    readonly silent?: boolean;
    readonly flatCancel?: boolean;
    readonly blockUser?: boolean;
    readonly blockAddress?: boolean;
    readonly cancellationOrigin?: string;
};

export async function cancelPolicy({
    policyId,
    data,
}: {
    readonly policyId: string;
    readonly data: CancelPolicyData;
}): Promise<null> {
    const { type, reason, subtype, silent, flatCancel, blockUser, blockAddress, cancellationOrigin } = data;

    if (blockUser) {
        await uwBlockPolicyUser(policyId);
    }

    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/cancel`);
    const body = {
        type,
        reason,
        subtype,
        silent,
        flatCancel,
        blockAddress,
        cancellationOrigin,
    };

    return await axios.post(url, body).then(response => response.data);
}

async function uwBlockPolicyUser(policyId: string): Promise<null> {
    const url = `/backoffice/policies/${policyId}/uw_block_user`;

    return await axios.post(url).then(response => response.data);
}

export type UwCancellationPayload = {
    readonly blockAddress: boolean;
    readonly blockUser: boolean;
    readonly description: string;
    readonly emailContent: string;
    readonly reasonCodes: string[];
};

export async function uwCancellation({
    payload,
    policyId,
}: {
    readonly payload: UwCancellationPayload;
    readonly policyId: string;
}): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/uw_cancellation`);

    await axios.post(url, payload);
}

export async function uwCancellationRevert({ policyId }: { readonly policyId: string }): Promise<null> {
    const url = `/backoffice/policies/${policyId}/uw_cancellation`;

    return await axios.delete(url).then(response => response.data);
}

export async function forceUpdatePDF(policyId: string): Promise<null> {
    const url = `/backoffice/policies/${policyId}/update_pdf`;

    return await axios.post(url).then(response => response.data);
}

export async function checkDateEligibility(
    policyId: string,
    dueDate: string
): Promise<{ readonly data: { readonly eligible: boolean; readonly error?: string } }> {
    const url = `/backoffice/policies/${policyId}/scheduled_cancellations/get_date_eligibility?due_date=${dueDate}`;

    return await axios.get(url).then(response => response.data);
}

export type SchedulePolicyCancellation = {
    readonly reason: string;
    readonly subreason?: string;
    readonly description?: string;
    readonly due_date: string;
    readonly cancellation_origin?: string;
};

export async function schedulePolicyCancellation({
    policyId,
    data,
}: {
    readonly policyId: string;
    readonly data: SchedulePolicyCancellation;
}): Promise<null> {
    const url = `/backoffice/policies/${policyId}/scheduled_cancellations`;

    return await axios.post(url, { scheduled_cancellation: data }).then(response => response.data);
}

export async function previewBackdateCancellation(policyId: string, date: string) {
    const url = `${OLD_BASE_URL}/${policyId}/backdate_cancellation_preview`;
    const body = {
        new_cancellation_date: date,
    };

    return await axios.post(url, body).then(response => response.data);
}

export type BackdateCancellationData = {
    readonly newCancellationDate: string;
    readonly backdateReason: string;
    readonly cancellationReason: string;
    readonly cancellationSubReason: string;
};

export async function backdateCancellation({
    policyId,
    cancellationDetails,
}: {
    readonly policyId: string;
    readonly cancellationDetails: BackdateCancellationData;
}): Promise<null> {
    const url = `${OLD_BASE_URL}/${policyId}/backdate_cancellation`;
    const { newCancellationDate, backdateReason, cancellationReason, cancellationSubReason } = cancellationDetails;
    const body = {
        new_cancellation_date: newCancellationDate,
        backdate_reason: backdateReason,
        cancellation_reason: cancellationReason,
        cancellation_subreason: cancellationSubReason,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function getPolicyChangeUrl(publicPolicyId: string, changeId: number): Promise<string> {
    const url = stringifyUrl({
        url: homeBlenderUrlResolver(`/api/v1/policy_changes/${changeId}/pdf`),
        query: {
            publicPolicyId,
        },
    });

    return await axios.get(url).then(response => response.data);
}

export async function editUnitNumber({
    addressId,
    unit,
}: {
    readonly addressId: number;
    readonly unit: string;
}): Promise<null> {
    const url = `/backoffice/addresses/${addressId}`;

    const body = {
        attribute: 'unit',
        value: unit,
    };

    return await axios.put(url, body).then(response => response.data);
}

export async function resendPolicy(policyId: string, interestedPartyId: number): Promise<null> {
    const url = `/backoffice/policies/${policyId}/resend_to_interested_party`;

    return await axios.post(url, { interested_party_id: interestedPartyId }).then(response => response.data);
}

export async function getPolicyAlerts(policyId: string): Promise<Alert[]> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/alerts`);

    return await axios.get(url).then(response => response.data.data);
}

export async function getSchoolInsuranceCertificate(policyId: string): Promise<string> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/school_insurance_certificate_pdf`);

    return await axios.get(url).then(response => response.data);
}

export async function getInsuranceCertificate(policyId: string): Promise<string> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/insurance_certificate_pdf`);

    return await axios.get(url).then(response => response.data);
}

export async function getPolicyNotifications(
    id: string
): Promise<{ readonly data: { readonly [key: string]: unknown } }> {
    const url = `/backoffice/policies/${id}/notifications`;

    return await axios.get(url).then(response => response.data);
}

export async function getAggregatedNotifications(policyId: string): Promise<unknown[]> {
    const [notifications, alerts] = await Promise.all([getPolicyNotifications(policyId), getPolicyAlerts(policyId)]);

    alerts.forEach(alert => {
        // @ts-expect-error mutable code
        notifications.data[alert.type] = alert;
    });

    return Object.values(notifications.data);
}

export async function convertPolicy({
    policyId,
    region,
}: {
    readonly policyId: string;
    readonly region: string;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/policies/${policyId}/convert_policy`);

    return await axios.post(url, { region });
}

export async function getHeaderSettings(): Promise<HeaderColumn[]> {
    const url = homeBlenderUrlResolver('/api/v1/policies/header_settings');

    return await axios.get(url).then(response => response.data.data);
}
