import type { ActionsMenuItem } from '@lemonade-hq/blender-ui';
import { ActionsMenu, Flex, spacing, Text, useForm } from '@lemonade-hq/blender-ui';
import { formatOccurredAt } from '@lemonade-hq/bluiza';
import type { Tool, ToolsRevision } from '@lemonade-hq/persisted-tools';
import { ToolChangeStatus, ToolsRevisionStatus } from '@lemonade-hq/persisted-tools';
import { useCallback, useMemo } from 'react';
import { InvalidToolAlert } from '../shared/alerts/InvalidToolAlert';
import { PublishedAndCreatedAlert } from '../shared/alerts/PublishedAndCreatedAlert';
import { PublishedAndDeletedAlert } from '../shared/alerts/PublishedAndDeleteAlert';
import { UndoDeleteAlert } from '../shared/alerts/UndoDeleteAlert';
import { getToolsThatRerouteToTool } from '../shared/tool.helpers';

interface PlaygroundToolEditorMainHeaderProps {
    readonly isReadonly: boolean | undefined;
    readonly tool: Tool;
    readonly toolsRevision: ToolsRevision | undefined;
    readonly handleDiscardToolChanges: (publicId: string) => void;
    readonly handleDeleteTool: (publicId: string) => void;
    readonly handleUpdateTool: (publicId: string, update: Partial<Tool>) => void;
    readonly handleCloneTool: (publicId: string) => void;
}

function generateDeleteBlockedTooltip(tools: Tool[]): React.ReactNode | null {
    if (tools.length === 0) return null;

    return (
        <Flex flexDirection="column" gap={spacing.s04}>
            <b>Delete blocked. First remove this tool from sub-classifiers in:</b>
            <ul>
                {tools.map(t => (
                    <li key={t.publicId}>{t.name}</li>
                ))}
            </ul>
        </Flex>
    );
}

export const PlaygroundToolEditorMainTopBar: React.FC<PlaygroundToolEditorMainHeaderProps> = ({
    isReadonly,
    tool,
    toolsRevision,
    handleDiscardToolChanges,
    handleDeleteTool,
    handleUpdateTool,
    handleCloneTool,
}) => {
    const { validationResults } = useForm();
    const isInvalidTool = !validationResults.valid;
    const isDeletedTool = tool.changeStatus === ToolChangeStatus.Deleted;

    const handleUndoDelete = useCallback(() => {
        handleUpdateTool(tool.publicId, { changeStatus: ToolChangeStatus.Edited });
    }, [tool, handleUpdateTool]);

    const toolsThatRerouteToThisTool = useMemo(
        () => getToolsThatRerouteToTool(tool, toolsRevision),
        [tool, toolsRevision]
    );

    const actions: ActionsMenuItem[] = useMemo(
        () => [
            {
                label: 'Discard changes',
                id: 'discard',
                onClick: () => handleDiscardToolChanges(tool.publicId),
            },
            {
                label: 'Delete tool',
                id: 'delete',
                onSelect: () => handleDeleteTool(tool.publicId),
                disabled: isDeletedTool || toolsThatRerouteToThisTool.length > 0,
                tooltip: generateDeleteBlockedTooltip(toolsThatRerouteToThisTool),
            },
            {
                label: 'Clone tool',
                id: 'clone',
                onSelect: () => {
                    handleCloneTool(tool.publicId);
                },
            },
        ],
        [tool, isDeletedTool, toolsThatRerouteToThisTool, handleDeleteTool, handleDiscardToolChanges, handleCloneTool]
    );

    const alertComponent = useMemo(() => {
        if (toolsRevision != null && isDeletedTool) {
            if (toolsRevision.status === ToolsRevisionStatus.Pending) {
                return <UndoDeleteAlert onUndoDelete={handleUndoDelete} />;
            }

            return <PublishedAndDeletedAlert />;
        } else if (
            toolsRevision?.status === ToolsRevisionStatus.Pending &&
            tool.changeStatus === ToolChangeStatus.New
        ) {
            return <PublishedAndCreatedAlert />;
        } else if (isInvalidTool) {
            return <InvalidToolAlert invalidFields={Object.keys(validationResults.failingValidations)} />;
        }

        return null;
    }, [
        handleUndoDelete,
        isDeletedTool,
        isInvalidTool,
        tool.changeStatus,
        toolsRevision,
        validationResults.failingValidations,
    ]);

    return (
        <>
            {alertComponent}
            <Flex alignItems="center" gap={spacing.s12} justifyContent="space-between" role="banner">
                <Text textTransform="none" type="h4">
                    {tool.name}
                </Text>
                <Flex alignItems="center" gap={spacing.s08}>
                    {tool.lastEditedAt != null && (
                        <Text color="secondary" textTransform="none" type="text-md">
                            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unnecessary-type-assertion -- due to losing the type info for some reason */}
                            {`Updated ${(formatOccurredAt(tool.lastEditedAt) as string).toLowerCase()}`}
                        </Text>
                    )}
                    {!isReadonly && <ActionsMenu items={actions} />}
                </Flex>
            </Flex>
        </>
    );
};
