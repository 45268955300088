import { Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import chicken from '../../../../../../public/blender_assets/backoffice/chicken.gif';

export const CompareBothEmptyToolRevisions: FC = () => {
    return (
        <Flex alignItems="center" flexDirection="column" flexGrow="1" gap={spacing.s16} justifyContent="center">
            <Text textAlign="center" type="h4" width="100%">
                Choose two tools revisions for comparison
            </Text>
            <img alt="bak bak" height={100} src={chicken} width={100} />
        </Flex>
    );
};
