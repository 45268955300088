import { getEnv, getUrlResolver, ServiceNames as ServiceName } from '@lemonade-hq/bluiza';
import axios from 'axios';
import {
    Interaction,
    NoteClaimType as CommunicationEntityType,
    CommunicationTimelineFilterOptions,
    CommunicationTimelineFilters,
} from 'models/Interaction';

export interface CommunicationTimelineResponse {
    timeline: Interaction[];
    filtersOptions: CommunicationTimelineFilterOptions;
}

const fallbackMap: Set<string> = new Set<string>();

const blenderGeneralUrlResolver = getUrlResolver({
    envName: getEnv(),
    service: ServiceName.BlenderGeneral,
    fallbackMap,
    basePath: '/api/v1',
});

export async function getCommunicationTimelineV2(
    entityPublicId: string,
    entityType: CommunicationEntityType,
    filters?: CommunicationTimelineFilters
): Promise<CommunicationTimelineResponse> {
    const plainUrl = blenderGeneralUrlResolver(`/communications_timeline/${entityType}/${entityPublicId}`);
    const searchParams = new URLSearchParams();

    if (!filters) {
        return axios.get(plainUrl).then(response => response.data.data);
    }
    Object.entries(filters).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
    });
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : '';

    return await axios.get(`${plainUrl}${queryParams}`).then(response => response.data.data);
}
