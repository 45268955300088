import type { ActionOption } from '@lemonade-hq/bluis';
import { ActionsMenu, ErrorSection, Image, LoadingSection, PageWrapper } from '@lemonade-hq/bluis';
import { EntitySummaryHeader, HeaderGeneralDetails } from '@lemonade-hq/bluiza';
import { Box } from '@lemonade-hq/cdk';
import { useState } from 'react';
import { ProductLinesTables } from './product-lines/ProductLinesTables';
import { CreateProductDialog } from './ProductMissionControl/dialogs/CreateProductDialog';
import { CreateProductLineDialog } from './ProductMissionControl/dialogs/CreateProductLineDialog';
import { HeaderPortal } from 'components/Header';
import { HeaderImageSizes, ImageContainer } from 'components/LoCo/LoCoPagesSharedStyles';
import { useGetProductLines } from 'queries/LoCo/Insurance/ProductLineQueries';

export const LoCoProducts: React.FC = () => {
    const [showProductDialog, setShowProductDialog] = useState(false);
    const [showProductLineDialog, setShowProductLineDialog] = useState(false);

    const { data: productLineData, isError, isPending: isLoading } = useGetProductLines();

    if (isLoading) {
        return <LoadingSection />;
    }

    if (isError || !productLineData) {
        return <ErrorSection />;
    }

    const actions: ActionOption[] = [
        {
            label: 'Create Product Line',
            value: 'create-product-line',
            onClick: () => setShowProductLineDialog(true),
        },
        {
            label: 'Create Product',
            value: 'create-product',
            onClick: () => setShowProductDialog(true),
        },
    ];

    return (
        <>
            <HeaderPortal>
                <EntitySummaryHeader>
                    <ImageContainer>
                        <Image
                            img={{
                                lightSrc: 'appraisal.svg',
                                darkSrc: 'appraisal-dark.svg',
                                ...HeaderImageSizes,
                            }}
                        />
                    </ImageContainer>
                    <HeaderGeneralDetails title="Lemonade Product Lines" />
                    <Box ml="auto">
                        <ActionsMenu actions={actions} buttonText="Actions" />
                    </Box>
                </EntitySummaryHeader>
            </HeaderPortal>

            <PageWrapper>
                <ProductLinesTables productLines={productLineData} />
                {showProductDialog && (
                    <CreateProductDialog onClose={() => setShowProductDialog(false)} productLines={productLineData} />
                )}
                {showProductLineDialog && <CreateProductLineDialog onClose={() => setShowProductLineDialog(false)} />}
            </PageWrapper>
        </>
    );
};
