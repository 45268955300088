import { clsx } from 'clsx';
import type { FC } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';
import { Button } from '../Button/Button';
import { IconButton } from '../IconButton/IconButton';
import { iconButtonStyles } from '../IconButton/IconButton.css';
import { pageButton, paginationTotal } from './Pagination.css';

interface CommonPaginationProps {
  readonly currentPage: number;
  readonly totalPages: number;
  readonly onClickNext: () => void;
  readonly onClickPrev: () => void;
  readonly onClickFirst: () => void;
  readonly onClickLast: () => void;
}

interface ArrowButtonsPaginationProps extends CommonPaginationProps {
  readonly variant?: 'arrowButtons';
  readonly onPageClick?: never;
}

interface PageButtonsPaginationProps extends CommonPaginationProps {
  readonly variant: 'pageButtons';
  readonly onPageClick: (page: number) => void;
}

export type PaginationProps = ArrowButtonsPaginationProps | PageButtonsPaginationProps;

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onClickPrev,
  onClickFirst,
  onClickLast,
  onClickNext,
  onPageClick,
  variant = 'arrowButtons',
}) => {
  if (totalPages === 0) return null;

  return (
    <Flex alignItems="center" gap={spacing.s08}>
      {variant === 'arrowButtons' && (
        <Text className={paginationTotal}>
          Page {currentPage}/{totalPages}
        </Text>
      )}
      {totalPages > 1 && (
        <Flex gap={spacing.s08}>
          <IconButton
            color="neutral7"
            disabled={currentPage === 1}
            icon="chevron-double-left"
            onClick={onClickFirst}
            title="First page"
            variant="secondary"
          />
          <IconButton
            color="neutral7"
            disabled={currentPage === 1}
            icon="chevron-left"
            onClick={onClickPrev}
            title="Previous"
            variant="secondary"
          />

          {variant === 'pageButtons' &&
            Array.from({ length: totalPages }, (_, idx) => idx + 1).map(page => (
              <Button
                className={clsx(iconButtonStyles({ variant: 'secondary', size: 'md' }), pageButton)}
                key={page}
                label={page.toString()}
                onClick={() => onPageClick?.(page)}
                variant={currentPage === page ? 'primary' : 'secondary'}
              />
            ))}

          <IconButton
            color="neutral7"
            disabled={currentPage === totalPages}
            icon="chevron-right"
            onClick={onClickNext}
            title="Next"
            variant="secondary"
          />
          <IconButton
            color="neutral7"
            disabled={currentPage === totalPages}
            icon="chevron-double-right"
            onClick={onClickLast}
            title="Last page"
            variant="secondary"
          />
        </Flex>
      )}
    </Flex>
  );
};
