import type { SelectOptionBase } from '@lemonade-hq/blender-ui';
import { Select } from '@lemonade-hq/blender-ui';
import type { FC, Key } from 'react';
import * as styles from './RulesBuilder.css';

interface SelectOperatorProps {
    readonly operators: SelectOptionBase[];
    readonly selectedOperator: string;
    readonly updateOperator: (value: Key | null) => void;
}

export const SelectOperator: FC<SelectOperatorProps> = ({ operators, selectedOperator, updateOperator }) => {
    return (
        <Select
            className={styles.selectOperator}
            onChange={updateOperator}
            options={operators}
            placeholder="Operator"
            selectedKey={selectedOperator}
        />
    );
};
