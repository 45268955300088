import { Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import { Dialog } from '@lemonade-hq/bluis';
import { useCallback } from 'react';
import { useCancelRelease } from 'queries/LoCo/Insurance/ReleasesQueries';

interface CancelDialogProps {
    readonly releasePublicId: string;
    readonly onClose: () => void;
}

export const CancelDialog: React.FC<CancelDialogProps> = ({ releasePublicId, onClose }) => {
    const { mutateAsync, isPending, error } = useCancelRelease(releasePublicId);

    const publish = useCallback(async () => {
        await mutateAsync(releasePublicId);
        onClose();
    }, [mutateAsync, onClose, releasePublicId]);

    return (
        <Dialog
            actions={[
                { type: 'close', text: 'Never mind', onClick: onClose },
                { type: 'submit', text: 'Cancel release', onClick: publish },
            ]}
            error={error === null ? undefined : (error as Error).message}
            loading={isPending}
            title="Cancel Release"
        >
            <Flex flexDirection="column" gap={spacing.s08}>
                <Text fontWeight="bold">Are you sure you are ready to cancel?</Text>
                <Text>After being cancelled, a release can not be published to production. This cannot be undone.</Text>
                <Text>
                    Upon cancellation, the edition sets of the release will be removed from all staging environments.
                </Text>
            </Flex>
        </Dialog>
    );
};
