import { Flex, Layout, Spinner } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import * as styles from './PlaygroundChat.css';
import { Timeline } from 'components/Llmnd/shared/components/Timeline';
import type { TimelineItem } from 'components/Llmnd/shared/types';

interface PlaygroundChatTimelineProps {
    readonly timeline: TimelineItem[];
    readonly isLoading?: boolean;
}

export const PlaygroundChatTimeline: FC<PlaygroundChatTimelineProps> = ({ timeline, isLoading }) => {
    return (
        <Layout className={styles.chatTimelineContainer} flexGrow={1}>
            <Timeline timeline={timeline} />
            {isLoading && (
                <Flex justifyContent="center">
                    <Spinner size="md" />
                </Flex>
            )}
        </Layout>
    );
};
