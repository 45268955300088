import type { AuthErrorResponseData } from '@lemonade-hq/bluis';
import { isAuthorizationError } from '@lemonade-hq/bluis';
import { HttpCode } from '@lemonade-hq/ts-helpers';
import * as Sentry from '@sentry/browser';
import type { AxiosError } from 'axios';

function isErrorWithMessage(error: unknown): error is Error {
  return Boolean((error as Error).message);
}

export function isLoadingChunkError(error: unknown): boolean {
  if (!isErrorWithMessage(error)) return false;

  const { message } = error;
  return message.includes('Loading chunk');
}

export function getAuthorizationPolicyName(error?: AxiosError<AuthErrorResponseData>): string {
  return error?.response?.data.data?.policyName ?? 'Unknown Policy Name';
}

export function handleSentryErrorCapture(error: unknown, tags?: Record<string, string>): void {
  if (!isAuthorizationError(error) && !isLoadingChunkError(error)) Sentry.captureException(error, { tags });
}

export const viewAuthErrorMock: {
  readonly response: { readonly data: AuthErrorResponseData; readonly status: HttpCode };
} = {
  response: {
    data: {
      message: 'Error Message Test',
      data: {
        policyName: 'TEST POLICY NAME',
        action: 'view',
      },
    },
    status: HttpCode.Forbidden,
  },
};

export const manageAuthErrorMock: {
  readonly response: { readonly data: AuthErrorResponseData; readonly status: HttpCode };
} = {
  response: {
    data: {
      message: 'Error Message Test',
      data: {
        policyName: 'TEST POLICY NAME',
        action: 'manage',
      },
    },
    status: HttpCode.Forbidden,
  },
};
