import axios from 'axios';

const apiBaseUrl = import.meta.env.VITE_FILES_SERVICE_URL;

interface UploadFileResponse {
    publicId: string;
}

export const uploadFile = async (file: File, shard: string): Promise<UploadFileResponse> => {
    const url = `${apiBaseUrl}/files?shard=${shard}&contentType=${file.type}`;
    const body = new FormData();

    body.append('file', file);

    return await axios
        .post<{ data: UploadFileResponse }>(url, body, { timeout: 600000, withCredentials: false })
        .then(response => response.data.data);
};
