/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style, react/no-array-index-key */
import type { Config } from '@lemonade-hq/blender-ui';
import { FormProvider } from '@lemonade-hq/blender-ui';
import { va } from '@lemonade-hq/maschema-schema';
import {
    clientToolSchema,
    getToolDynamicValidations,
    ToolChangeStatus,
    transformedServerToolIntoClientTool,
} from '@lemonade-hq/persisted-tools';
import type {
    SerializableTool as Tool,
    ToolSchemaValues,
    SerializableToolsRevision as ToolsRevision,
} from '@lemonade-hq/persisted-tools';
import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';

interface PlaygroundToolEditorFormProps {
    readonly isReadonly?: boolean;
    readonly tool: Tool | undefined;
    readonly toolsRevision: ToolsRevision | undefined;
    readonly toolSchemaValues?: ToolSchemaValues;
}

export const PlaygroundToolEditorForm: FC<PropsWithChildren<PlaygroundToolEditorFormProps>> = ({
    isReadonly,
    tool,
    children,
    toolsRevision,
    toolSchemaValues,
}) => {
    const initialConfig: Config<typeof clientToolSchema> = useMemo(
        () => ({
            schemaKeysRules: {
                subtopic: {
                    rules: [
                        {
                            conditions: { topic: [va.nullish()] },
                            actions: [{ type: 'setDisabled', value: true }],
                        },
                        {
                            conditions: { topic: [va.required()] },
                            actions: [{ type: 'setDisabled', value: false }],
                        },
                    ],
                },
            },
            additionalValidations: getToolDynamicValidations({ tool, toolsRevision, toolSchemaValues }),
        }),
        [toolSchemaValues, tool, toolsRevision]
    );

    const isToolDeleted = tool?.changeStatus === ToolChangeStatus.Deleted;

    return (
        <FormProvider
            globallyDisabled={isToolDeleted || isReadonly}
            initialConfig={initialConfig}
            initialValues={transformedServerToolIntoClientTool(tool)}
            schema={clientToolSchema}
        >
            {children}
        </FormProvider>
    );
};
