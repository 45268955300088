import { InlineInputEditor } from '@lemonade-hq/bluis';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { useEffect, useMemo, useRef } from 'react';

interface ChangeTicketIdDialogProps {
    readonly ticketId: string;
    readonly onClose: () => void;
    readonly onSubmit: (value: string) => Promise<void> | void;
    readonly className?: string;
    readonly isError: boolean;
    readonly isSuccess: boolean;
}

// TODO: rewrite this using a new dialog system once introduced by blender-ui
export const ChangeTicketIdDialog: FC<ChangeTicketIdDialogProps> = ({
    onSubmit,
    onClose,
    className,
    isError,
    isSuccess,
    ticketId,
}) => {
    // HACK ALERT: this is a terrible hack due to how `InlineInputEditor` handles it own state and error handling
    // we need to await an artificial promise and plug it to react-query hooks to know the ticket query status
    // Will be removed once we have proper dialogs in Blender-ui
    const resolveRef = useRef<(value: unknown) => void>();
    const rejectRef = useRef<(value: unknown) => void>();
    const submitted = useRef(false);

    const promise = useMemo(async () => {
        return await new Promise((resolve, reject) => {
            resolveRef.current = resolve;
            rejectRef.current = reject;
        });
    }, []);

    useEffect(() => {
        if (isSuccess && submitted.current) {
            resolveRef.current?.(undefined);
        } else if (isError && submitted.current) {
            rejectRef.current?.({ message: 'Invalid ticket ID' });
        }
    }, [isSuccess, isError, ticketId]);

    const handleSubmitted = async (value: string): Promise<void> => {
        submitted.current = true;
        await onSubmit(value);
        await promise;
        onClose();
    };

    return (
        <InlineInputEditor
            className={className}
            disableSubmit={isEmpty}
            editorVisible
            onClose={onClose}
            onSubmit={handleSubmitted}
            position="bottom"
            showErrorMessage
            value=""
        />
    );
};
