import { useState } from 'react';
import { ArchiveUnderwritingDraftDialog } from './ArchiveUnderwritingDraftDialog';
import { PublishExplanationDraftDialog } from './Explanations/PublishExplanationDraftDialog';
import { ManageUnderwritingProductLinesDialog } from './ManageUnderwritingProductLinesDialog';
import { ManageReasonVersionDialog } from './Reasons/ManageReasonVersionDialog';
import { PublishReasonDraftDialog } from './Reasons/PublishReasonDraftDialog';
import type { ReasonEntityType, UnderwritingEntityTypes } from './underwritingUtils';
import { ActionType, UnderwritingEntityType } from './underwritingUtils';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type { ExplanationVersion, ReasonVersion } from 'models/LoCo/Insurance/UnderwritingRegistry';

export type DialogTypes<T extends UnderwritingEntityTypes> =
    | {
          readonly type: ActionType.ArchiveDraft;
          readonly publicId: string;
      }
    | {
          readonly type: ActionType.CreateOrEditDraft;
          readonly originalEntity?: T;
      }
    | {
          readonly type: ActionType.ManageProductLines;
          readonly entity: T['entity'];
      }
    | {
          readonly type: ActionType.PublishDraft;
          readonly entityVersion: T['entityVersion'];
      }
    | {
          readonly type: ActionType.ViewPublishedVersion;
          readonly originalEntity: T;
      };

// too verbose return type
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useUnderwritingDialog<T extends UnderwritingEntityTypes>() {
    const [dialogData, setDialogData] = useState<DialogTypes<T> | null>(null);

    return {
        dialogData,
        setDialogData,
    };
}

interface UnderwritingDialogsProps<T extends UnderwritingEntityTypes> {
    readonly dialogData: DialogTypes<T> | null;
    readonly entityType: UnderwritingEntityType;
    readonly decisionType: UnderwritingDecisionLifecycleContext;
    readonly onClose: () => void;
}

export const UnderwritingDialogs = <T extends UnderwritingEntityTypes>({
    decisionType,
    entityType,
    dialogData,
    onClose,
}: UnderwritingDialogsProps<T>): JSX.Element | null => {
    if (dialogData === null) {
        return null;
    }

    switch (dialogData.type) {
        case ActionType.ManageProductLines:
            return (
                <ManageUnderwritingProductLinesDialog
                    code={dialogData.entity.code}
                    decisionType={decisionType}
                    entityType={entityType}
                    onClose={onClose}
                    productLines={dialogData.entity.productLines.map(pl => pl.code)}
                />
            );
        case ActionType.ArchiveDraft:
            return (
                <ArchiveUnderwritingDraftDialog
                    decisionType={decisionType}
                    entityType={entityType}
                    onClose={onClose}
                    publicId={dialogData.publicId}
                />
            );
        case ActionType.CreateOrEditDraft:
        case ActionType.ViewPublishedVersion:
            if (entityType === UnderwritingEntityType.Reason) {
                return (
                    <ManageReasonVersionDialog
                        decisionType={decisionType}
                        onClose={onClose}
                        originalEntity={dialogData.originalEntity as ReasonEntityType}
                    />
                );
            } else {
                return null; // unreachable since explanation has a form instead of a dialog
            }

        case ActionType.PublishDraft:
            if (entityType === UnderwritingEntityType.Reason) {
                return (
                    <PublishReasonDraftDialog
                        decisionType={decisionType}
                        onClose={onClose}
                        reasonVersion={dialogData.entityVersion as ReasonVersion}
                    />
                );
            } else {
                return (
                    <PublishExplanationDraftDialog
                        decisionType={decisionType}
                        explanationVersion={dialogData.entityVersion as ExplanationVersion}
                        onClose={onClose}
                    />
                );
            }

        default:
            return null;
    }
};
