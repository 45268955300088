import { TabPanel as HeadlessTabPanel } from '@headlessui/react';
import type { ForwardRefRenderFunction, HTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';
import { useTabs } from './context';
import { tabPanel } from './Tabs.css';

interface TabPanelProps extends HTMLAttributes<HTMLDivElement> {
  readonly children: ReactNode;
  readonly index?: number;
}

const TabPanelComponent: ForwardRefRenderFunction<HTMLDivElement, TabPanelProps> = (
  { children, index, ...props },
  ref,
) => {
  const { uuid } = useTabs();

  return (
    <HeadlessTabPanel
      aria-labelledby={`${uuid}--tab--${index}`}
      className={tabPanel}
      id={`${uuid}--panel--${index}`}
      ref={ref}
      {...props}
    >
      {children}
    </HeadlessTabPanel>
  );
};

export const TabPanel = forwardRef(TabPanelComponent);
