import type { EntityTypes } from '../../utils/ProductTypes';

export type TaskTypesEnum = Record<string, string>;
export type TagEnum = Record<string, string>;
export type ReferenceTypeEnum = Record<string, string>;

export enum TaskStatus {
  Done = 'done',
  Open = 'open',
  Dismissed = 'dismissed',
  Active = 'active',
  Pending = 'pending',
}
export enum PendingReason {
  WaitingOnCustomer = 'Waiting on Customer',
  WaitingOnThirdParty = 'Waiting on 3rd Party',
  Manual = 'Manual',
}

export enum ReadonlyReason {
  OperatorNotMatch = 'OperatorNotMatch',
  ClaimClosed = 'ClaimClosed',
  Default = 'Default',
}

export interface Task<TaskTypes extends TaskTypesEnum> {
  readonly publicId: string;
  readonly groupPublicId?: string;
  readonly static: boolean;
  readonly status?: TaskStatus;
  readonly type: TaskTypes[keyof TaskTypes];
  readonly title: string;
  readonly operatorPublicId?: string | null;
  readonly referenceId?: string;
  readonly groupReferenceId?: string;
  readonly disabled?: boolean;
  readonly readonly?: boolean;
  readonly readonlyReason?: ReadonlyReason;
  readonly canBeReopened?: boolean;
  readonly dismissReason?: string | null;
  readonly pendingReason?: string | null;
  readonly lastCompleted?: string;
  readonly lastDismissed?: string;
  readonly lastPended?: string;
  readonly groupReferenceType?: string;
  readonly actionDate?: string;
  readonly notificationsCount?: number;
  readonly isLastMandatoryOpenTask?: boolean;
  readonly isMandatoryTask?: boolean;
  readonly dismissible?: boolean;
  readonly canBeMarkedAsDone?: boolean;
  readonly performedBy?: string;
  readonly automated?: boolean;
  readonly statusDescription?: {
    readonly content: string;
    readonly publicId: string;
    readonly timestamp: string;
  };
  readonly statusReason?: string;
  readonly overdue?: boolean;

  readonly preventSettlementOnDismiss?: boolean;
}

export interface TaskElement<TaskTypes extends TaskTypesEnum> {
  readonly type: 'task';
  readonly task: Task<TaskTypes>;
}
export interface ListElement<
  TaskTypes extends TaskTypesEnum,
  TagTypes extends TagEnum | undefined,
  ReferenceType extends ReferenceTypeEnum | undefined,
> {
  readonly type: 'list';
  readonly title: string;
  readonly tags?: TagTypes[keyof TagTypes][];
  readonly elements: WorkflowElement<TaskTypes, TagTypes, ReferenceType>[];
  readonly referenceId?: string | null;
  readonly referenceType?: ReferenceType[keyof ReferenceType] | null;
}

export type WorkflowElement<
  TaskTypes extends TaskTypesEnum,
  TagTypes extends TagEnum | undefined,
  ReferenceType extends ReferenceTypeEnum | undefined,
> = ListElement<TaskTypes, TagTypes, ReferenceType> | TaskElement<TaskTypes>;

export type WorkflowSegment<
  TaskTypes extends TaskTypesEnum,
  TagTypes extends TagEnum | undefined,
  ReferenceType extends ReferenceTypeEnum | undefined,
> = WorkflowElement<TaskTypes, TagTypes, ReferenceType>[];

export interface TaskAssignee {
  readonly publicId: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly photoUrl?: string;
}

export interface TaskListItem {
  readonly taskPublicId: string;
  readonly taskType: string;
  readonly taskStatus: string;
  readonly entityType: EntityTypes;
  readonly entityPublicId: string;
  readonly createdAt: string;
  readonly actionDate: string;
  readonly assignee?: SearchTaskAssignee;
}

export interface SearchTaskAssignee {
  readonly publicId: string;
  readonly operatorPublicId: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly photoUrl?: string;
  readonly description?: string;
}

export interface TaskListFilters {
  readonly taskType: string[];
  readonly taskStatuses: TaskStatus[];
  readonly taskAssignees: SearchTaskAssignee[];
}
