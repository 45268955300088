import { COLORS } from '@lemonade-hq/tokens';

const BCOLORS = {
    ...COLORS,
    gray: '#8C8C8C',
    mineShaft: '#333333',
    alto: '#E0E0E0',
    darkRed: '#EC092F',
    darkerRed: '#D82140',
    darkestRed: '#C60442',
    scarlet: '#f83157',
    orange: '#FFB01C',
    buttercup: '#f5a623',
    green: '#2AD5BD',
    malachite: '#00CB5B',
    listerine: '#27D8B5',
    blue: '#2A76E4',
    darkBlue: '#225AAF',
    darkerBlue: '#174991',
    havelockBlue: '#4a90e2',
    blueGray: '#6c87a8',
    blueSky: '#319bf5',
    void: '#262626',
    darkerNight: '#282828',
    solidGray: '#d9d9d9',
    lighterCloud: '#fbfbfb',
    pearl: '#fdfdfd',
    orangePill: '#FF9900',
    whiteSmoke: '#F5F5F5',
    gainsboro: '#dcdcdc',
    mouseGray: '#9B9B9B',
    yellow: '#FFE071',
    lightYellow: '#fff3c7',
    mediumSea: '#2ECC71',
    nero: '#141414',
    mirage: '#171930',
    ebonyClay: '#222540',
    rhino: '#2F335B',
    eastBay: '#42508A',
    chetwodeBlue: '#92ABDC',
    strikemaster: '#9E6D8F',
    eggplant: '#643B5B',
    hemlock: '#64583B',
    shadow: '#847654',
    turquoise: '#2AD5BD',
    poloBlue: '#90A8D9',
    cottonCandy: '#FEAED8',
    tutu: '#FFF7FB',
    hoki: '#6380A3',
    barleyWhite: '#fff3c7',
    blond: '#FFE071',
    meteor: '#D18313',
    sandyBeach: '#FFEECE',
    bostonBlue: '#4391BD',
    zumthor: '#EBF5FF',
};

/**
 * @deprecated
 * please use import {themedColor} from '@lemonade-hq/bluis' instead
 */
export const ThemedColors = {
    generalBackground: `var(--main-background-color, ${BCOLORS.whiteSmoke})`,
    componentBackground: `var(--component-background-color, ${BCOLORS.white})`,
    elementBackground: `var(--element-background-color, ${BCOLORS.cloud})`,
    lightBackground: `var(--light-background-color, ${COLORS.glass})`,
    disabledBackground: `var(--disabled-background-color, ${BCOLORS.cloud})`,
    primaryText: `var(--primary-text-color, ${BCOLORS.mineShaft})`,
    secondaryText: `var(--secondary-text-color, ${BCOLORS.gray})`,
    disabledText: `var(--disabled-text-color, ${BCOLORS.gray})`,
    primaryColor: `var(--primary-color, ${BCOLORS.pink})`,
    separatorColor: `var(--separator-color, ${BCOLORS.alto})`,
    successColor: `var(--success-color, ${BCOLORS.green})`,
    doneColor: `var(--done-color, ${BCOLORS.listerine})`,
    errorColor: `var(--error-color, ${BCOLORS.scarlet})`,
    heavyAlert: `var(--heavy-alert-color, ${BCOLORS.cottonCandy})`,
    lightAlert: `var(--light-alert-color, ${BCOLORS.tutu})`,
    mainButtonBackground: `var(--main-button-background-color, ${BCOLORS.pink})`,
    mainButtonText: `var(--main-button-text-color, ${BCOLORS.white})`,
    mainButtonBackgroundDisabled: `var(--main-button-background-disabled-color, ${BCOLORS.gray})`,
    mainButtonDisabledText: `var(--main-button-text-disabled-color, ${BCOLORS.alto})`,
    mainButtonBackgroundHover: `var(--main-button-background-hover-color, ${BCOLORS.darkPink})`,
    mainButtonBackgroundActive: `var(--main-button-background-active-color, ${BCOLORS.darkerPink})`,
    inverseButtonText: `var(--inverse-button-text-color, ${BCOLORS.pink})`,
    inverseButtonBackgroundHover: `var(--inverse-button-background-hover-color, ${BCOLORS.pink})`,
    inverseButtonTextHover: `var(--inverse-button-text-hover-color, ${BCOLORS.white})`,
    inverseButtonBackgroundActive: `var(--inverse-button-background-active-color, ${BCOLORS.darkPink})`,
    inverseButtonTextActive: `var(--inverse-button-text-active-color, ${BCOLORS.white})`,
    secondaryButtonBackground: `var(--secondary-button-background-color, ${BCOLORS.cloud})`,
    secondaryButtonText: `var(--secondary-button-text-color, ${BCOLORS.mineShaft})`,
    secondaryButtonBorder: `var(--secondary-button-border-color, ${BCOLORS.alto})`,
    secondaryButtonBackgroundDisabled: `var(--secondary-button-background-disabled-color, ${BCOLORS.cloud})`,
    secondaryButtonDisabledText: `var(--secondary-button-text-disabled-color, ${BCOLORS.gray})`,
    secondaryButtonBackgroundHover: `var(--secondary-button-background-hover-color, ${BCOLORS.alto})`,
    secondaryButtonBorderHover: `var(--secondary-button-border-hover-color, ${BCOLORS.light})`,
    secondaryButtonBackgroundActive: `var(--secondary-button-background-active-color, ${BCOLORS.gainsboro})`,
    userTimelineNoteBackground: `var(--user-timeline-note-background-color, ${BCOLORS.lightYellow})`,
    userTimelineNoteBorder: `var(--user-timeline-note-border, ${BCOLORS.yellow})`,
    lightboxBackground: `var(--lightbox-background-color, rgba(38, 38, 38, 0.8))`,
    tooltipBackground: `var(--tooltip-background-color, ${BCOLORS.mineShaft})`,
    tooltipText: `var(--tooltip-text-color, ${BCOLORS.white})`,
    linkColor: `var(--link-color, ${BCOLORS.hoki})`,
    scrollbarColor: `var(--scrollbar-color, ${BCOLORS.gray})`,
    lightPrimaryColor: `var(--light-primary-color, rgba(255,0,131,0.1))`,
};

export default BCOLORS;
