import { Node } from 'slate';
import { EmailState } from 'hooks/useEmail';
import { FirstResponse } from 'models/Interaction';
import { getDefaultValue } from '../components/Bluis/RichTextEditor/helpers';
import { CommunicationActions, CommunicationActionTypes } from '../actions/communicationActionTypes';

export const CREATE_EMAIL_STATE = 'CREATE_EMAIL_STATE';
export const CREATE_NOTE_STATE = 'CREATE_NOTE_STATE';

export enum SelectedTab {
    INTERNAL_NOTE = 0,
    EMAIL = 1,
}

export interface NoteState {
    content: Node[];
}

export interface CommunicationState {
    emails: {
        [interactionId: string]: EmailState;
        [CREATE_EMAIL_STATE]: EmailState;
    };
    internalNote: NoteState;
    selectedTab: SelectedTab;
    firstResponse?: FirstResponse;
}

const INITIAL_COMMUNICATION_STATE: CommunicationState = {
    emails: {
        [CREATE_EMAIL_STATE]: {
            content: getDefaultValue(),
            subject: '',
            attachments: [],
            recipient: undefined,
            ccRecipients: [],
        },
    },
    internalNote: {
        content: getDefaultValue(),
    },
    selectedTab: SelectedTab.INTERNAL_NOTE,
};

export default function communicationReducer(
    state = INITIAL_COMMUNICATION_STATE,
    action: CommunicationActionTypes
): CommunicationState {
    switch (action.type) {
        case CommunicationActions.EMAIL_CREATE: {
            const { interactionId, recipient, ccRecipients, shouldUpdateThreadOwner } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        subject: recipient.subject ?? '',
                        ccRecipients,
                        content: getDefaultValue(),
                        attachments: [],
                        recipient,
                        shouldUpdateThreadOwner,
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_SELECT_RECIPIENT: {
            const { recipient, interactionId, isFirstResponseScheduled } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...state.emails[interactionId],
                        subject:
                            isFirstResponseScheduled && recipient?.subject != null
                                ? recipient.subject
                                : state.emails[interactionId].subject,
                        recipient,
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_REMOVE_RECIPIENT: {
            const { interactionId } = action.payload;
            const email = state.emails[interactionId];

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...email,
                        subject: '',
                        recipient: undefined,
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_CHANGE_SUBJECT: {
            const { subject, interactionId } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...state.emails[interactionId],
                        subject,
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_CHANGE_CONTENT: {
            const { content, interactionId } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...state.emails[interactionId],
                        content,
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_SELECT_ATTACHMENTS: {
            const { attachments, interactionId } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...state.emails[interactionId],
                        attachments,
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_REMOVE_ATTACHMENT: {
            const { attachment, interactionId } = action.payload;

            const email = state.emails[interactionId] as EmailState;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...email,
                        attachments: email.attachments.filter(att => att.download_url !== attachment.download_url),
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_RESET: {
            const { interactionId } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        content: getDefaultValue(),
                        subject: '',
                        attachments: [],
                        recipient: undefined,
                        ccRecipients: [],
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_BODY_RESET: {
            const { interactionId } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...state.emails[interactionId],
                        content: getDefaultValue(),
                        attachments: [],
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_DELETE: {
            const { interactionId } = action.payload;

            const newEmailsState = { ...state.emails };

            delete newEmailsState[interactionId];
            return {
                ...state,
                emails: newEmailsState,
            };
        }
        case CommunicationActions.NOTE_CHANGE_CONTENT: {
            const { content } = action.payload;

            return {
                ...state,
                internalNote: {
                    content,
                },
            };
        }
        case CommunicationActions.SET_TAB: {
            const { tab } = action.payload;

            return {
                ...state,
                selectedTab: tab,
            };
        }
        case CommunicationActions.RESET_STATE: {
            return { ...INITIAL_COMMUNICATION_STATE };
        }

        case CommunicationActions.EMAIL_ADD_CC_RECIPIENT: {
            const { interactionId, ccRecipient } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...state.emails[interactionId],
                        ccRecipients: [...state.emails[interactionId].ccRecipients, ccRecipient],
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_REMOVE_CC_RECIPIENT: {
            const { interactionId, ccRecipient } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...state.emails[interactionId],
                        ccRecipients: state.emails[interactionId].ccRecipients.filter(
                            r => r.email !== ccRecipient.email
                        ),
                    },
                },
            };
        }
        case CommunicationActions.EMAIL_RESET_CC_RECIPIENTS: {
            const { interactionId } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...state.emails[interactionId],
                        ccRecipients: [],
                    },
                },
            };
        }
        case CommunicationActions.SHOULD_UPDATE_THREAD_OWNER: {
            const { shouldUpdateThreadOwner, interactionId } = action.payload;

            return {
                ...state,
                emails: {
                    ...state.emails,
                    [interactionId]: {
                        ...state.emails[interactionId],
                        shouldUpdateThreadOwner,
                    },
                },
            };
        }

        default:
            return state;
    }
}
