/* eslint-disable react/no-array-index-key */
import type { IconName } from '@lemonade-hq/blender-ui';
import {
    Accordion,
    badgeStyles,
    Card,
    DynamicListContainer,
    DynamicListItem,
    FormInputGroup,
    Icon,
    Text,
} from '@lemonade-hq/blender-ui';
import type {
    SerializableTool as Tool,
    ToolSchemaKey,
    SerializableToolsRevision as ToolsRevision,
} from '@lemonade-hq/persisted-tools';
import { ToolChangeStatus } from '@lemonade-hq/persisted-tools';
import type { Change } from 'diff';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import * as styles from '../PlaygroundComparePage.css';
import { PublishedAndCreatedAlert } from '../shared/alerts/PublishedAndCreatedAlert';
import { PublishedAndDeletedAlert } from '../shared/alerts/PublishedAndDeleteAlert';
import { PlaygroundToolStructuredContainer } from '../shared/PlaygroundToolStructuredContainer';
import { prettifyField } from '../shared/tool.helpers';
import type { ComparisonType } from './compare.helpers';
import { NEWER_TOOL, OLDER_TOOL } from './compare.helpers';
import type { ExtendedArrayChange, ToolsDiff } from './useToolsDiff';

function comparisonTypeSpanStatus(comparisonType: ComparisonType | undefined): 'added' | 'removed' | undefined {
    if (comparisonType === NEWER_TOOL) {
        return 'added';
    } else if (comparisonType === OLDER_TOOL) {
        return 'removed';
    } else {
        return undefined;
    }
}
const ComparableSpan: FC<{
    readonly status?: 'added' | 'removed';
    readonly value: string;
}> = ({ status, value }) => {
    return (
        <span className={styles.comparedSpan({ status })} data-diff={status}>
            {value}
        </span>
    );
};

const ComparableText: FC<{
    readonly diff: Change[] | null | undefined;
    readonly comparisonType?: ComparisonType;
}> = ({ diff, comparisonType }) => {
    return (
        diff?.map((diffItem, i) => {
            if (comparisonType === NEWER_TOOL && diffItem.added) {
                return (
                    <ComparableSpan key={i} status={comparisonTypeSpanStatus(comparisonType)} value={diffItem.value} />
                );
            } else if (comparisonType === OLDER_TOOL && diffItem.removed) {
                return (
                    <ComparableSpan key={i} status={comparisonTypeSpanStatus(comparisonType)} value={diffItem.value} />
                );
            } else if (
                (comparisonType === OLDER_TOOL && !diffItem.added) ||
                (comparisonType === NEWER_TOOL && !diffItem.removed)
            ) {
                return <ComparableSpan key={i} value={diffItem.value} />;
            } else {
                return null;
            }
        }) ?? null
    );
};

const ComparableBadge: FC<{
    readonly diff: Change[] | null | undefined;
    readonly comparisonType: ComparisonType;
    readonly icon?: IconName;
}> = ({ diff, comparisonType, icon }) => {
    return (
        <span className={badgeStyles({ clickable: false, size: 'sm', variant: 'info' })}>
            {icon && <Icon data-testid="badge-icon" name={icon} size="sm" />}
            <ComparableText comparisonType={comparisonType} diff={diff} />
        </span>
    );
};

const ComparableToolField: FC<{
    readonly field: ToolSchemaKey;
    readonly diff: Change[] | null | undefined;
    readonly simple?: boolean;
    readonly comparisonType: ComparisonType;
}> = ({ field, diff, simple, comparisonType }) => {
    if (simple)
        return (
            <Text alignSelf="center" wordBreak="break-word">
                <ComparableText comparisonType={comparisonType} diff={diff} />
            </Text>
        );

    return (
        <FormInputGroup>
            <Text type="label-sm">{prettifyField(field)}</Text>
            <DynamicListItem variant="unordered">
                <Text alignSelf="center" as="li" wordBreak="break-word">
                    <ComparableText comparisonType={comparisonType} diff={diff} />
                </Text>
            </DynamicListItem>
        </FormInputGroup>
    );
};

const ComparableListEmptyItem: FC = () => {
    return (
        <DynamicListItem variant="unordered">
            <Text alignSelf="center" as="li" wordBreak="break-word">
                {'<NONE>'}
            </Text>
        </DynamicListItem>
    );
};

// supports either a complete diff-valued (via the `value` prop) or an elaborate `diff` object if exists
const ComparableListItem: FC<{
    readonly comparisonType?: ComparisonType;
    readonly value: string;
    readonly diff?: Change[] | undefined;
}> = ({ comparisonType, diff, value }) => {
    return (
        <DynamicListItem variant="ordered">
            <Text
                alignSelf="center"
                as="li"
                className={styles.comparedSpan({
                    // do not highlight the entire text if we have a concrete diff
                    status: diff != null ? undefined : comparisonTypeSpanStatus(comparisonType),
                })}
                wordBreak="break-word"
            >
                {diff != null ? <ComparableText comparisonType={comparisonType} diff={diff} /> : value}
            </Text>
        </DynamicListItem>
    );
};

const ComparableList: FC<{
    readonly isEmpty: boolean;
    readonly field: ToolSchemaKey;
    readonly diff: ExtendedArrayChange<string>[] | null | undefined;
    readonly comparisonType: ComparisonType;
}> = ({ field, isEmpty: empty, diff, comparisonType }) => {
    return (
        <Accordion isOpenByDefault title={prettifyField(field)}>
            <Card borderRadius="sm" padding="1.2rem" variant="primary">
                {empty ? (
                    <ComparableListEmptyItem />
                ) : (
                    <DynamicListContainer as="ol">
                        {diff?.map((diffItem, i) => {
                            if (comparisonType === NEWER_TOOL && diffItem.added) {
                                return diffItem.value.map((vv, j) => (
                                    <ComparableListItem
                                        comparisonType={comparisonType}
                                        diff={diffItem.contentDiffs?.[j]}
                                        key={`${i}-${j}`}
                                        value={vv}
                                    />
                                ));
                            } else if (comparisonType === OLDER_TOOL && diffItem.removed) {
                                return diffItem.value.map((vv, j) => (
                                    <ComparableListItem
                                        comparisonType={comparisonType}
                                        diff={diffItem.contentDiffs?.[j]}
                                        key={`${i}-${j}`}
                                        value={vv}
                                    />
                                ));
                            } else if (
                                (comparisonType === OLDER_TOOL && !diffItem.added) ||
                                (comparisonType === NEWER_TOOL && !diffItem.removed)
                            ) {
                                return diffItem.value.map((vv, j) => (
                                    <ComparableListItem key={`${i}-${j}`} value={vv} />
                                ));
                            } else {
                                return null;
                            }
                        }) ?? null}
                    </DynamicListContainer>
                )}
            </Card>
        </Accordion>
    );
};

export const CompareTool: FC<{
    readonly tool: Tool;
    readonly diff: ToolsDiff | null;
    readonly toolsRevision: ToolsRevision;
    readonly comparisonType: ComparisonType;
}> = ({ tool, diff, comparisonType }) => {
    return (
        <PlaygroundToolStructuredContainer
            renderBadges={{
                type: isEmpty(tool.type) ? null : <ComparableBadge comparisonType={comparisonType} diff={diff?.type} />,
                topic: isEmpty(tool.topic) ? null : (
                    <ComparableBadge comparisonType={comparisonType} diff={diff?.topic} icon="tags" />
                ),
                userGroups: isEmpty(tool.userGroups) ? null : (
                    <ComparableBadge comparisonType={comparisonType} diff={diff?.userGroups} icon="user" />
                ),
            }}
            renderDescription={
                <ComparableToolField
                    comparisonType={comparisonType}
                    diff={diff?.description}
                    field="description"
                    simple
                />
            }
            renderInstructions={
                <ComparableList
                    comparisonType={comparisonType}
                    diff={diff?.instructions}
                    field="instructions"
                    isEmpty={isEmpty(tool.instructions)}
                />
            }
            renderMacros={<ComparableToolField comparisonType={comparisonType} diff={diff?.macros} field="macros" />}
            renderMode={<ComparableBadge comparisonType={comparisonType} diff={diff?.mode} />}
            renderNotes={<ComparableToolField comparisonType={comparisonType} diff={diff?.notes} field="notes" />}
            renderParams={<ComparableToolField comparisonType={comparisonType} diff={diff?.params} field="params" />}
            renderProducts={
                <ComparableToolField comparisonType={comparisonType} diff={diff?.products} field="products" />
            }
            renderQuestions={
                <ComparableList
                    comparisonType={comparisonType}
                    diff={diff?.questions}
                    field="questions"
                    isEmpty={isEmpty(tool.questions)}
                />
            }
            renderRegion={<ComparableToolField comparisonType={comparisonType} diff={diff?.region} field="region" />}
            renderSubClassifiers={
                <ComparableList
                    comparisonType={comparisonType}
                    diff={diff?.subClassifiers}
                    field="subClassifiers"
                    isEmpty={isEmpty(tool.subClassifiers)}
                />
            }
            renderSubtopic={
                <ComparableToolField comparisonType={comparisonType} diff={diff?.subtopic} field="subtopic" />
            }
            renderTicketsIdsExamples={
                <ComparableToolField
                    comparisonType={comparisonType}
                    diff={diff?.ticketsIdsExamples}
                    field="ticketsIdsExamples"
                />
            }
            renderTopBar={
                <>
                    {tool.changeStatus === ToolChangeStatus.Deleted && <PublishedAndDeletedAlert />}
                    {tool.changeStatus === ToolChangeStatus.New && <PublishedAndCreatedAlert />}
                    <Text textTransform="none" type="h4">
                        {tool.name}
                    </Text>
                </>
            }
            renderTopic={<ComparableToolField comparisonType={comparisonType} diff={diff?.topic} field="topic" />}
            renderType={<ComparableToolField comparisonType={comparisonType} diff={diff?.type} field="type" />}
            renderUserGroups={
                <ComparableToolField comparisonType={comparisonType} diff={diff?.userGroups} field="userGroups" />
            }
        />
    );
};
