import type { FC } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { TabsSize, TabsVariant } from './types';

interface TabsContextType {
  readonly uuid?: string;
  readonly size: TabsSize;
  readonly variant: TabsVariant;
}

const TabsContext = createContext<TabsContextType | null>(null);

interface TabsProviderProps {
  readonly uuid: string;
  readonly size: TabsSize;
  readonly variant: TabsVariant;
}

export const TabsProvider: FC<React.PropsWithChildren<TabsProviderProps>> = ({ uuid, size, variant, children }) => {
  const value = useMemo(() => ({ uuid, size, variant }), [size, uuid, variant]);

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

export const useTabs = (): TabsContextType => {
  const tabsContext = useContext(TabsContext);

  if (tabsContext == null) {
    throw new Error('useTabs must be used in a component under a <Tabs /> component.');
  }

  return tabsContext;
};
