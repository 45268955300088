import { ProductsTable } from './ProductsTable';
import type { ProductLine } from 'models/LoCo/Insurance/ProductLine';

export interface ProductLineTableProps {
    readonly productLines: ProductLine[];
}

export const ProductLinesTables: React.FC<ProductLineTableProps> = ({ productLines }) => (
    <>
        {productLines.map(productLine => (
            <ProductsTable key={productLine.code} productLine={productLine} />
        ))}
    </>
);
