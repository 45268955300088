import { NonExpandableChange } from '../NonExpandableChange';
import { findCoverage, findSetting } from './common';
import type { BindingEntry } from 'models/LoCo/Insurance/ChangesLog';
import { ChangeType } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';

interface CoverageBindingChangedProps {
    readonly change: BindingEntry;
    readonly coverageEdition: CoveragesEdition;
}

export const CoverageBindingChanged: React.FC<CoverageBindingChangedProps> = ({ change, coverageEdition }) => {
    const coverage = findCoverage(coverageEdition, change.metadata.coverageTemplateCode);
    const setting = findSetting(coverageEdition, change.metadata.settingTemplateCode);
    const action = change.changeType === ChangeType.CoverageBindingAdded ? 'connected to' : 'disconnected from';
    return <NonExpandableChange title={`"${setting.name}" setting ${action} "${coverage.name}" coverage`} />;
};
