import axios from 'axios';
import { HomeOwnersReviewStatus } from 'models/HomeOwnersReview';

export interface UpdateReviewAttributes {
    reviewId: number;
    properties: UpdateReviewProperties;
}
export interface UpdateReviewProperties {
    reviewer_id?: string | null;
    status?: HomeOwnersReviewStatus;
    notes?: string;
}

export async function updateReview({ reviewId, properties }: UpdateReviewAttributes) {
    const url = '/backoffice/underwriting_reviews/update';
    const data = { review_id: reviewId, ...properties };

    return axios.put(url, data).then(response => response.data);
}
