import { isManageAuthError } from '@lemonade-hq/bluis';
import type React from 'react';
import { GenericErrorBoundary } from './ErrorBoundary';
import { getFallbackComponent } from './ErrorFallBackComponents';
import { handleSentryErrorCapture } from './utils';

export interface PageErrorBoundaryProps {
  readonly children: React.ReactNode;
}
export class PageErrorBoundary extends GenericErrorBoundary<PageErrorBoundaryProps> {
  render(): React.ReactNode {
    const { error } = this.state;
    const { children } = this.props;

    if (error == null) {
      return children;
    }

    if (isManageAuthError(error)) throw error;

    handleSentryErrorCapture(error, { message: 'page unavailable' });

    return getFallbackComponent({ error, boundaryArgs: { type: 'page' } });
  }
}

export function withPageErrorBoundary<P>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: React.ComponentType<P>,
): React.ComponentType<P & object> {
  return (props: P & object) => (
    <PageErrorBoundary>
      <Component {...props} />
    </PageErrorBoundary>
  );
}
