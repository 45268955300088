import { StyledInput } from '@lemonade-hq/bluis';
import styled from 'styled-components';

export const FormWrapper = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Input = styled(StyledInput)`
    max-width: 270px;
`;
