import { RealtimeProvider } from '@lemonade-hq/realtime';
import type { FC, PropsWithChildren } from 'react';
import { useClientSettings } from 'queries/ClientSettings';

export const EnsureRealtime: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { data: clientConfig } = useClientSettings();

    return (
        <RealtimeProvider pubnubSubscribeKey={clientConfig?.realtime?.pubnubSubscribeKey}>{children}</RealtimeProvider>
    );
};
