import isEmpty from 'lodash/isEmpty';
import { useMemo, useRef } from 'react';

function escapeRegExp(str: string): string {
  return str.replaceAll(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function buildFuzzyMatchPatternRegExp(pattern: string): RegExp {
  const transformedPattern = pattern
    .toLowerCase()
    .split('')
    .map(l => `${escapeRegExp(l)}.*`)
    .join('')
    .replaceAll(' ', '[_ :]');
  return new RegExp(transformedPattern);
}

export function fuzzyMatch(pattern: string, str: string): boolean {
  const re = buildFuzzyMatchPatternRegExp(pattern);
  return re.test(str.toLowerCase());
}

export function fuzzyMatchWithPrecalculatedRegex(patternRegex: RegExp, str: string): boolean {
  return patternRegex.test(str.toLowerCase());
}

export function useQuickFuzzyFilter<T>(query: string, items: T[], getItemTextValue: (item: T) => string): T[] {
  const lastQuery = useRef<string>('');
  const lastItems = useRef<T[]>(items);

  const filteredItems = useMemo(() => {
    if (!isEmpty(query)) {
      const pattern = buildFuzzyMatchPatternRegExp(query);
      const itemsToFilterFrom: T[] = query.startsWith(lastQuery.current) ? lastItems.current : items;

      const result = itemsToFilterFrom.filter(item =>
        fuzzyMatchWithPrecalculatedRegex(pattern, getItemTextValue(item)),
      );

      lastQuery.current = query;
      lastItems.current = result;
      return result;
    }

    return items;
  }, [items, query, getItemTextValue]);

  return filteredItems;
}
