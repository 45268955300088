import { Card, Text } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';

interface NonExpandableChangeProps {
    readonly title: string;
}

export const NonExpandableChange: FC<NonExpandableChangeProps> = ({ title }) => {
    return (
        <Card borderRadius="sm" padding="10px 12px" variant="secondary">
            <Text fontWeight="semi-bold" type="text-md">
                {title}
            </Text>
        </Card>
    );
};
