import { Pagination, usePagination } from '@lemonade-hq/blender-ui';
import { trackEvent } from '@lemonade-hq/boutique';
import { useCallback, useEffect } from 'react';
import type { FC } from 'react';
import { useAttachmentsData } from '../context';
import type { AttachmentStats } from '../types';
import type { HubFilters } from './Filters';

interface HubPaginationProps {
    readonly stats: AttachmentStats;
    readonly updateFilters: (newFilters: Partial<HubFilters>) => void;
}

export const HubPagination: FC<HubPaginationProps> = ({ stats, updateFilters }) => {
    const { entityPublicId, entityType } = useAttachmentsData();
    const { totalPages, currentPage } = stats;
    const { selectedPage, handleNextClick, handlePrevClick, handleFirstClick, handleLastClick } = usePagination({
        currentPage,
        totalPages,
    });

    useEffect(() => {
        if (selectedPage !== currentPage) {
            updateFilters({ page: selectedPage });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPage]);

    const onClickPagination = useCallback(
        (callback: () => void, name: string): void => {
            trackEvent('docs.gallery.clicked', {
                entity_id: entityPublicId,
                entity_type: entityType,
                name: 'pagination',
                arrow_type: name,
            });
            callback();
        },
        [entityPublicId, entityType]
    );

    return (
        <Pagination
            currentPage={selectedPage}
            onClickFirst={() => onClickPagination(handleFirstClick, 'first')}
            onClickLast={() => onClickPagination(handleLastClick, 'last')}
            onClickNext={() => onClickPagination(handleNextClick, 'next')}
            onClickPrev={() => onClickPagination(handlePrevClick, 'prev')}
            totalPages={totalPages}
        />
    );
};
