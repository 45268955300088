import type { Argument, EnumArgument, ExpressionNode, LiteralNode } from './expressionTypes';
import { ArgumentType, ExpressionType, LogicalOperator } from './expressionTypes';

export interface RuleFunction {
    readonly display: string;
    readonly arguments: {
        readonly type: ArgumentType;
        readonly array?: boolean;
        readonly placeholder?: string;
    }[];
    readonly isCallable: boolean;
    readonly validate?: (args: ExpressionNode[]) => boolean;
}

export interface InputFunction {
    readonly display: string;
    readonly argument?: EnumArgument;
    readonly validate?: (args: ExpressionNode[]) => boolean;
    readonly collapse?: boolean;
    readonly returnType: Argument;
}

export const LOGICAL_OPERATORS = ['&&', '||'];
export const BOOLEAN_VALUES = ['true', 'false'];

const commonNumberOperators: Pick<RuleFunction, 'arguments' | 'isCallable'> = {
    isCallable: false,
    arguments: [
        {
            type: ArgumentType.Number,
        },
    ],
};

const isDefinedOperator: RuleFunction = {
    isCallable: true,
    display: 'is defined',
    arguments: [],
};

export const INPUT_OPERATORS: Record<string, InputFunction> = {
    input: {
        display: 'Input',
        collapse: true,
        returnType: { type: ArgumentType.Unknown },
    },
};

export const COMPARE_OPERATORS: Record<ArgumentType, Record<string, RuleFunction>> = {
    [ArgumentType.Number]: {
        '!=': {
            ...commonNumberOperators,
            display: '≠',
        },
        isBetween: {
            isCallable: true,
            display: 'is between',
            arguments: [
                {
                    type: ArgumentType.Number,
                    placeholder: 'min',
                },
                {
                    type: ArgumentType.Number,
                    placeholder: 'max',
                },
            ],
            validate: (args: ExpressionNode[]) => {
                return (
                    args.length > 1 &&
                    parseInt((args[0] as LiteralNode).value) < parseInt((args[1] as LiteralNode).value)
                );
            },
        },
        '==': {
            ...commonNumberOperators,
            display: '=',
        },
        '>': {
            ...commonNumberOperators,
            display: '>',
        },
        '>=': {
            ...commonNumberOperators,
            display: '≥',
        },
        '<': {
            ...commonNumberOperators,
            display: '<',
        },
        '<=': {
            ...commonNumberOperators,
            display: '≤',
        },
        isDefined: isDefinedOperator,
    },
    [ArgumentType.String]: {
        isContainedIn: {
            isCallable: true,
            display: 'is one of',
            arguments: [
                {
                    type: ArgumentType.String,
                    placeholder: 'value',
                    array: true,
                },
            ],
        },
        '==': {
            isCallable: false,
            display: 'equals',
            arguments: [
                {
                    type: ArgumentType.String,
                },
            ],
        },
        isDefined: isDefinedOperator,
    },
    [ArgumentType.Enum]: {
        '==': {
            isCallable: false,
            display: 'is',
            arguments: [
                {
                    type: ArgumentType.Enum,
                },
            ],
        },
        isContainedIn: {
            isCallable: true,
            display: 'is one of',
            arguments: [
                {
                    type: ArgumentType.Enum,
                    array: true,
                },
            ],
        },
        isDefined: isDefinedOperator,
    },
    [ArgumentType.Boolean]: {
        '==': {
            isCallable: false,
            display: 'is',
            arguments: [
                {
                    type: ArgumentType.Boolean,
                },
            ],
        },
        isDefined: isDefinedOperator,
    },
    [ArgumentType.Unknown]: {},
};

export function getLogicalOperator(node: ExpressionNode): LogicalOperator {
    if (node.type === ExpressionType.LogicalExpression) {
        return node.operator;
    }

    return LogicalOperator.AND;
}
