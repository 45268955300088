import { Flex, pageWrapper, spacing } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import * as styles from './PlaygroundPage.css';
import { PlaygroundChat } from './simulation/PlaygroundChat';
import { PlaygroundToolEditor } from './toolEditor/PlaygroundToolEditor';
import { useForceBlenderUI } from 'hooks/useForceBlenderUI';
import { useFullscreen } from 'hooks/useFullScreen';

export const PlaygroundPage: FC = () => {
    useFullscreen();
    useForceBlenderUI();

    return (
        <Flex className={pageWrapper} gap={spacing.s24} height="100%" padding={spacing.s24}>
            <PlaygroundToolEditor className={styles.playgroundEditorMainArea} />
            <PlaygroundChat className={styles.playgroundEditorChatArea} />
        </Flex>
    );
};
