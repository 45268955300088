import type { Infer, RecordLikeShape, RecordOrRecordReferentialShape, RecordPath } from '@lemonade-hq/maschema-schema';
import { getJoinedRecordPathString, validateRecordPartially } from '@lemonade-hq/maschema-schema';
import omit from 'lodash/omit';
import toPath from 'lodash/toPath';
import { useEffect } from 'react';
import { useForm } from '../FormContext';
import type { Config, Rules } from '../types';

export type CommonAdapterProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = {
  readonly schemaKey: TSchemaKey;
  readonly rules?: Rules<TSchema, TSchemaKey>;
};

export function useConnectToForms<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>({
  schemaKey,
  rules,
}: CommonAdapterProps<TSchema, TSchemaKey>): {
  readonly visible: boolean;
  readonly disabled: boolean;
} {
  const { dispatch, config } = useForm<RecordLikeShape>();

  useEffect(
    () => {
      if (rules) {
        dispatch({
          type: 'setRules',
          key: schemaKey,
          rules: rules as Rules<RecordLikeShape, RecordPath<RecordLikeShape>>,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we need to deeply compare the rules object
    [dispatch, JSON.stringify(rules), schemaKey],
  );

  return isVisibleOrDisabled(schemaKey, config);
}

export function isSchemaKeyRequired<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(
  schemaKey: TSchemaKey,
  schema: TSchema,
  values: Partial<Infer<TSchema>>,
): boolean {
  // we check if path has required validation by running a validation on it with an empty object,
  // so it'll trigger a failing required validation if it's a miss.
  // this way if the required validation is nested deep within a complex conditional validation, we'll find it regardless,
  // thus relying on maschema validation logic rather than trying to dig the validation ourselves
  const result = validateRecordPartially(schema, [schemaKey], {}, omit(values, schemaKey) as Partial<Infer<TSchema>>);
  return !result.valid;
}

/**
 * Given a key of the shape `a.b.c`, it will check if any of sub-keys (`a`, `a.b`, `a.b.c`) are visible.
 *
 * @param schemaKey key of the shape `a.b.c`
 * @param config the form's config
 * @returns false if any of the sub-keys are set to not visible, true otherwise
 */
export function isVisibleOrDisabled<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(
  schemaKey: TSchemaKey,
  config: Config<RecordOrRecordReferentialShape>,
): { readonly visible: boolean; readonly disabled: boolean } {
  if (config.schemaKeysRules === undefined) return { visible: true, disabled: false };

  let visible = true,
    disabled = config.globallyDisabled === true;

  const splittedKey = toPath(schemaKey);

  for (let i = 0; i < splittedKey.length; i++) {
    const path = getJoinedRecordPathString(splittedKey.slice(0, i + 1));

    if (path in config.schemaKeysRules) {
      if (visible && config.schemaKeysRules[path]?.visible === false) {
        visible = false;
      }

      if (!disabled && config.schemaKeysRules[path]?.disabled === true) {
        disabled = true;
      }
    }
  }

  return { visible, disabled };
}
