import { clsx } from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Icon } from '../Icon/Icon';
import * as draggableStyles from '../SortableDnd/DraggableItem.css';
import type { UseSortableDndResult } from '../SortableDnd/useSortableDnd';
import { useSortableDnd } from '../SortableDnd/useSortableDnd';
import * as styles from './DynamicList.css';
import type { DynamicListItemProps } from './DynamicListItem';
import { DynamicListItem } from './DynamicListItem';

export const DragHandle: FC<Pick<UseSortableDndResult, 'attributes' | 'isDragging' | 'listeners'>> = ({
  isDragging,
  attributes,
  listeners,
}) => {
  return (
    <Flex
      alignItems="center"
      className={clsx(draggableStyles.draggleItem({ isDragging }), styles.iconVisibleOnHover)}
      {...attributes}
      {...listeners}
    >
      <Icon color="neutral7" name="drag-and-drop-handle" size="lg" />
    </Flex>
  );
};

export const DraggableDynamicListItem: FC<
  PropsWithChildren<
    DynamicListItemProps & {
      readonly id: string;
    }
  >
> = ({ id, additionalComponent, variant, className, invalid, sortable, children }) => {
  const { attributes, listeners, setDragNodeRef, isDragging, style, data } = useSortableDnd({
    id,
    disabled: !sortable,
  });

  const disabled = !sortable || data.sortable.items.length === 1;
  const dragHandle = disabled ? undefined : (
    <DragHandle attributes={attributes} isDragging={isDragging} listeners={listeners} />
  );

  return (
    <DynamicListItem
      additionalComponent={additionalComponent}
      className={className}
      dragHandle={dragHandle}
      invalid={invalid}
      isDragging={isDragging}
      setDragNodeRef={setDragNodeRef}
      sortable={!disabled}
      style={style}
      variant={variant}
    >
      {children}
    </DynamicListItem>
  );
};
