import React, { FC } from 'react';
import { Flex } from '@lemonade-hq/cdk';
import { CoreEntityType } from '@lemonade-hq/bluiza';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import { ProductTypes } from 'models/Products';
import StatusDot from 'bluis/StatusDot';
import BoldedResult from './BoldedResult';
import { Container, LeftSection, MainInfo, SecondaryInfo, SecondaryInfoCapitalize, EntityDesc } from './shared';
import ProductIcon from './ProductIcon';
import { SearchItemQuote } from './helpers';
import ConditionalLink from './ConditionalLink';

import { formatAddress } from '../../../Commons/AddressUtils';

const ResultQuote: FC<
    React.PropsWithChildren<{
        term: string;
        quote: SearchItemQuote;
        onSearchResultClick: (params: { url: string; entity: CoreEntityType; product: ProductTypes; index: number }) => void;
        index: number;
    }>
> = ({ term, quote, onSearchResultClick, index }) => {
    const { owner, status, external_id: externalId, url, product, address_components: addressComponents } = quote;
    const formattedAddress = formatAddress(addressComponents);

    return (
        <>
            <ConditionalLink
                onClick={() => onSearchResultClick({ url, entity: CoreEntityType.Quote, product, index })}
                url={url}
            >
                <Container>
                    <LeftSection>
                        <Flex>
                            <MainInfo mb="5px" mr="8px">
                                <BoldedResult term={term} value={externalId} />
                            </MainInfo>
                            <SecondaryInfoCapitalize>
                                <StatusDot status={status} />
                                {snakeCaseToReadable(status)}
                            </SecondaryInfoCapitalize>
                        </Flex>
                        <SecondaryInfo mb="2px">
                            <BoldedResult term={term} value={owner} />
                        </SecondaryInfo>
                        {product === ProductTypes.Home && <SecondaryInfo mb="2px">{formattedAddress}</SecondaryInfo>}
                    </LeftSection>
                    <Flex alignItems="center">
                        <ProductIcon product={quote.product} />
                        <EntityDesc ml="6px" mb="1px">
                            {snakeCaseToReadable(quote.product)}
                        </EntityDesc>
                    </Flex>
                </Container>
            </ConditionalLink>
        </>
    );
};

export default ResultQuote;
