import { Dialog } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)<{ readonly minWidth?: string; readonly minHeight?: string }>`
    height: 100%;
    > div {
        height: 100%;

        > div {
            min-width: ${({ minWidth }) => minWidth ?? ''};
            min-height: ${({ minHeight }) => minHeight ?? ''};
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;

            > :nth-child(2) {
                height: 100%;
                justify-content: space-between;

                > div {
                    padding: 0;
                    overflow-x: hidden;

                    &:first-child {
                        flex-grow: 1;
                    }

                    > div {
                        height: 100%;
                    }
                }
            }
        }
    }
`;

export const DialogStep = styled(Flex)<{ readonly width?: string; readonly gap?: string; readonly padding?: string }>`
    padding: ${({ padding }) => padding ?? ''};
    width: ${({ width }) => width ?? '100%'};
    gap: ${({ gap }) => gap ?? ''};
    justify-content: flex-start;
    height: 100%;
`;

export const DialogStepWrapper = styled(Flex)`
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
