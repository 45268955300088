import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';
import type { FeatureSubrogation, PreCloseData } from 'models/Subrogation';

const baseUrlClaimSubrogation = '/backoffice/claim_subrogations';
const baseUrlFeatureSubrogation = '/backoffice/feature_subrogations';

const blenderGeneralUrlResolver = getUrlResolver('blender-general');
const featureSubrogationsBaseUrl = blenderGeneralUrlResolver('/api/v1/feature_subrogations');

export async function activateSubrogation({ claimId }: { readonly claimId: string }): Promise<null> {
    return await axios.post(`${baseUrlClaimSubrogation}/${claimId}/activate`);
}

export async function deactivateSubrogation({ claimId }: { readonly claimId: string }): Promise<null> {
    return await axios.post(`${baseUrlClaimSubrogation}/${claimId}/deactivate`);
}

export async function preCloseSubrogation({ claimId }: { readonly claimId: string }): Promise<PreCloseData> {
    return await axios
        .get<{ data: PreCloseData }>(`${baseUrlClaimSubrogation}/${claimId}/pre_close`)
        .then(res => res.data.data);
}

export async function closeSubrogation({
    claimId,
    closeReason,
}: {
    readonly claimId: string;
    readonly closeReason?: string;
}): Promise<null> {
    return await axios.post(`${baseUrlClaimSubrogation}/${claimId}/close`, { closeReason });
}

export async function reopenSubrogation({ claimId }: { readonly claimId: string }): Promise<null> {
    return await axios.post(`${baseUrlClaimSubrogation}/${claimId}/reopen`);
}

export async function updateSubrogationFeature({
    featureSubrogationId,
    featureSubrogation,
}: {
    readonly featureSubrogationId: string;
    readonly featureSubrogation: Partial<FeatureSubrogation>;
}): Promise<null> {
    return await axios.patch(`${baseUrlFeatureSubrogation}/${featureSubrogationId}`, featureSubrogation);
}

export async function preCloseFeatureSubrogation({
    featureSubrogationId,
}: {
    readonly featureSubrogationId: string;
}): Promise<PreCloseData> {
    return await axios
        .get<{ data: PreCloseData }>(`${featureSubrogationsBaseUrl}/${featureSubrogationId}/pre_close`)
        .then(res => res.data.data);
}

export async function closeFeatureSubrogation({
    featureSubrogationId,
    closeReason,
}: {
    readonly featureSubrogationId: string;
    readonly closeReason?: string;
}): Promise<void> {
    await axios.post(`${featureSubrogationsBaseUrl}/${featureSubrogationId}/close`, { closeReason });
}

export async function reopenFeatureSubrogation({
    featureSubrogationId,
}: {
    readonly featureSubrogationId: string;
}): Promise<void> {
    await axios.post(`${featureSubrogationsBaseUrl}/${featureSubrogationId}/reopen`);
}

export async function getSubroReferralTask({ claimPublicId }: { readonly claimPublicId: string }): Promise<string> {
    return await axios
        .get<{ data: string }>(`${baseUrlClaimSubrogation}/${claimPublicId}/subro_referral_task`)
        .then(res => res.data.data);
}

export async function getSubrogationAllowCloseClaim({ claimId }: { readonly claimId: string }): Promise<boolean> {
    return await axios
        .get<{
            data: boolean;
        }>(`${blenderGeneralUrlResolver('/api/v1/claim_subrogations')}/${claimId}/allow_close_claim`)
        .then(res => res.data.data);
}
