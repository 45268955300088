import {
    Accordion,
    ContentSectionTitle,
    FormInputWrapper,
    InputContainer,
    SummarySection,
    TextArea,
} from '@lemonade-hq/bluis';
import { Flex, Text } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import form from 'bluis/Form';
import { ThemedColors } from 'components/Bluis/Colors';

export const HeaderImageSizes = { width: 50, height: 50 };

export const ImageContainer = styled(Flex)`
    padding-left: 20px;
    align-items: center;
    justify-content: center;
`;

export const StyledSection = styled(SummarySection)`
    margin-top: 20px;
`;

export const StyledSectionHeader = styled(ContentSectionTitle)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`;

export const StyledAccordionSection = styled(Accordion)`
    margin-top: 20px;
    padding: 28px 23px 23px 23px;
    background-color: ${ThemedColors.componentBackground};
`;

const StyledStackValues = styled(Flex)`
    flex-direction: column;
`;

export const StackedValues: React.FC<{ readonly values: string[] }> = ({ values }) => {
    return (
        <StyledStackValues>
            {values.map(value => (
                <Text key={value}>{value}</Text>
            ))}
        </StyledStackValues>
    );
};

export const StyledFormInputWrapper = styled(FormInputWrapper)<{ readonly inputWidth?: string }>`
    ${InputContainer} {
        width: ${({ inputWidth }) => inputWidth ?? '300px'};
    }
`;

export const StyledFormWrapper = styled(Flex)`
    flex-direction: column;
    padding-bottom: 20px;
`;

export const StyledSectionWrapper = styled(Flex)`
    flex-direction: column;
    border-top: 1px solid ${ThemedColors.separatorColor};
    border-bottom: 1px solid ${ThemedColors.separatorColor};
    padding: 20px 0;
    margin-bottom: 20px;
`;

export const StyledInputWrapper = styled(form.InputWrapper)`
    > div {
        display: grid;
        grid-template-columns: 2fr 6fr;
        grid-gap: 10px;
        white-space: nowrap;
        place-items: center start;

        > div {
            width: 300px;
            margin-left: auto;
        }

        > label {
            font-weight: 500;
        }
    }
`;

export const StyledInputWrapperNoStretch = styled(StyledInputWrapper)`
    > div {
        > div {
            margin-left: auto;
            width: auto;
        }
    }
`;

export const StyledTextArea = styled(TextArea)`
    width: 100%;
`;

export const RuleDialogDimensions = { minHeight: '506.25px', minWidth: '950px' };

export const StyledClickable = styled.span<{ readonly fontSize?: string }>`
    color: var(--main-button-background-color);
    cursor: pointer;
    font-size: ${({ fontSize }) => fontSize ?? '1.5rem'};
    white-space: nowrap;
`;
