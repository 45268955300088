import { clsx } from 'clsx';
import { forwardRef } from 'react';
import type { TextareaHTMLAttributes } from 'react';
import { TextArea as AriaTextArea } from 'react-aria-components';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import { baseInputError, baseInputErrorContainer, input } from '../../theme/input.css';
import { spacing } from '../../theme/spacing.css';
import * as styles from './TextArea.css';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  readonly value?: string | undefined;
  readonly error?: string;
  readonly readOnly?: boolean;
  readonly autoExpanding?: boolean;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ value, maxLength, error, autoExpanding, className: externalClassName, ...props }, textAreaRef) => {
    const hasCounter = maxLength != null && maxLength > 0;
    const reachedMaxChars = hasCounter && value != null && value.length >= maxLength;

    return (
      <Flex className={styles.textAreaContainer}>
        <AriaTextArea
          className={clsx(input(), styles.textarea, externalClassName, { [styles.autoExpanding]: autoExpanding })}
          ref={textAreaRef}
          {...props}
          data-has-error={Boolean(error) || undefined}
          maxLength={maxLength}
          value={value}
        />

        {(Boolean(error) || hasCounter) && (
          <Flex className={baseInputErrorContainer} gap={spacing.s08}>
            <Text className={baseInputError}>{error}</Text>

            {hasCounter && (
              <Text className={styles.charCounter} color={reachedMaxChars ? 'error' : 'secondary'} type="text-sm">
                {value?.length ?? 0}/{maxLength}
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    );
  },
);
