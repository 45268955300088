import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

// internal hook used to toggle a gradient scroll indicator when content is larger than container.

interface UseListMenuScrollProps {
  readonly scrollContainer: React.RefObject<HTMLDivElement>;
  readonly contentContainer: React.RefObject<HTMLDivElement>;
  readonly toggleableBottomStyle: string;
  readonly toggleableTopStyle: string;
}

interface UseListMenuScrollResult {
  readonly updateContainerScroll: () => void;
}

const SCROLL_THRESHOLD = 5;

export function useListMenuScroll({
  scrollContainer,
  contentContainer,
  toggleableBottomStyle,
  toggleableTopStyle,
}: UseListMenuScrollProps): UseListMenuScrollResult {
  const timeoutRef = useRef<number | undefined>(undefined);

  const updateContainerScroll = useCallback(() => {
    const containerHeight = scrollContainer.current?.clientHeight ?? 0;
    const contentHeight = contentContainer.current?.clientHeight ?? 0;
    const contentScroll = scrollContainer.current?.scrollTop ?? 0; // not a mistake, it's taken from the container
    const hasTopScroll = contentScroll > SCROLL_THRESHOLD;
    const hasBottomScroll = contentHeight - containerHeight - contentScroll > SCROLL_THRESHOLD;
    scrollContainer.current?.classList.toggle(toggleableBottomStyle, hasBottomScroll);
    scrollContainer.current?.classList.toggle(toggleableTopStyle, hasTopScroll);
  }, [toggleableBottomStyle, toggleableTopStyle, contentContainer, scrollContainer]);

  const triggerUpdateContainerScroll = useCallback(() => {
    timeoutRef.current = window.setTimeout(updateContainerScroll, 1); // in order to have right content height
  }, [updateContainerScroll]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (contentContainer.current != null) {
      const observer = new ResizeObserver(updateContainerScroll);
      observer.observe(contentContainer.current);
      return () => observer.disconnect();
    }
  }, [updateContainerScroll, contentContainer]);

  useLayoutEffect(() => {
    const container = scrollContainer.current;
    window.addEventListener('resize', updateContainerScroll);
    container?.addEventListener('scroll', updateContainerScroll);
    triggerUpdateContainerScroll();
    return () => {
      container?.removeEventListener('scroll', updateContainerScroll);
      window.removeEventListener('resize', updateContainerScroll);
    };
  }, [scrollContainer, triggerUpdateContainerScroll, updateContainerScroll]);

  return { updateContainerScroll: triggerUpdateContainerScroll };
}
