import type { DialogProps } from '@lemonade-hq/bluis';
import { Dialog, FormInputWrapper } from '@lemonade-hq/bluis';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { StyledInput, StyledTextArea } from '../../SharedTableConfig';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { useCreateProductLine } from 'queries/LoCo/Insurance/ProductLineQueries';

interface AddProductLineDialogProps {
    readonly onClose: () => void;
}

export const CreateProductLineDialog: React.FC<React.PropsWithChildren<AddProductLineDialogProps>> = ({ onClose }) => {
    const { mutateAsync: createProductLine, isError, isPending: isLoading } = useCreateProductLine();

    const { errors, values, setValue, valid } = useForm({
        fields: {
            name: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            description: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
        },
    });

    const onSubmit = async () => {
        await createProductLine({ ...values, name: values.name.trim(), description: values.description.trim() });
        onClose();
    };

    const dialogProps: DialogProps = {
        title: 'Create Product Line',
        onClose,
        loading: isLoading,
        error: isError ? GENERAL_ERROR_MSG : undefined,
        actions: [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Create',
                type: 'submit',
                onClick: onSubmit,
                disabled: isLoading || isError || !valid,
            },
        ],
        closeOnOutsideClick: true,
    };

    return (
        <Dialog {...dialogProps}>
            <FormInputWrapper label="Name" showErrors={!isEmpty(errors.name)}>
                <StyledInput
                    onChange={event => setValue('name', event.target.value)}
                    placeholder="Homeowners"
                    value={values.name}
                />
            </FormInputWrapper>
            <FormInputWrapper label="Description" showErrors={!isEmpty(errors.description)}>
                <StyledTextArea
                    onChange={event => setValue('description', event.target.value)}
                    value={values.description}
                />
            </FormInputWrapper>
        </Dialog>
    );
};
