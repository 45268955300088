import { NoticeIcon } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import pluralize from 'pluralize';
import type { FC } from 'react';
import styled from 'styled-components';
import type { HighPriorityRule } from './types';

const StyledTooltip = styled.div`
  &&&:after {
    text-align: left;
    white-space: pre;
    left: 30px;
  }
`;

const Paper = styled.div`
  position: relative;
  width: max-content;
`;

export function formatOccurredAt(time?: string | null): string {
  if (time == null) return '';

  const occurred = new Date(time);
  const now = new Date();

  const hoursDiff = differenceInHours(now, occurred);
  const daysDiff = differenceInDays(now, occurred);
  const minutesDiff = differenceInMinutes(now, occurred);

  if (daysDiff >= 1) return `${Math.ceil(daysDiff)} ${pluralize('day', daysDiff)} ago`;
  if (hoursDiff >= 1) return `${Math.ceil(hoursDiff)} ${pluralize('hour', hoursDiff)} ago`;
  if (minutesDiff > 10) return 'Less than an hour ago';

  return 'Just now';
}

export const AdditionalInfo: FC<{ readonly rules: HighPriorityRule[] }> = ({ rules = [] }) => {
  if (!rules.length) return null;

  const topRuleOccurredAt = rules[0] ? rules[0].occurredAt : '';
  const formattedOccurredAt = formatOccurredAt(topRuleOccurredAt);

  if (rules.length === 1) {
    return <Paper>{formattedOccurredAt}</Paper>;
  }

  const tooltipMessage = rules
    .map(({ type, occurredAt }) => {
      const formatted = formatOccurredAt(occurredAt);

      if (type === 'new_claim') {
        return `• New claim submitted ${formatted}`;
      }

      if (type === 'idle_claim') {
        const daysDiff = differenceInDays(new Date(), new Date(occurredAt));

        return `• Claim idle for ${Math.ceil(daysDiff)} ${pluralize('day', daysDiff)}`;
      }

      return `• Unanswered message received ${formatted}`;
    })
    .join('\n');

  return (
    <Paper>
      <StyledTooltip
        data-testid="additional-info-tooltip"
        data-tooltip={tooltipMessage}
        data-tooltip-alignment="bottom-center"
      >
        <Flex alignItems="center" gap="5px">
          <NoticeIcon />
          {formattedOccurredAt}
        </Flex>
      </StyledTooltip>
    </Paper>
  );
};
