import { Form as BluisForm, Spinner } from '@lemonade-hq/bluis';
import { font, themedColor } from '@lemonade-hq/boutique';
import { Flex } from '@lemonade-hq/cdk';
import type { ReactElement } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { ThemedColors } from 'bluis/Colors';

export const DropdownSpinner = styled(Spinner)`
    position: absolute;
    top: 8px;
    right: -25px;
`;

export const InputAndLabel = styled(Flex)`
    align-items: center;
    justify-content: space-between;
`;

export const Label = styled.label`
    margin: 0;
    ${font('main', { fontSize: '14px', fontWeight: 700 })}
    color: ${ThemedColors.primaryText};
`;

export const StyledErrorMessage = styled.span`
    color: ${ThemedColors.errorColor};
    ${font('main', { fontSize: '12px', fontWeight: 400, lineHeight: 'normal' })}
    text-align: left;
    margin-top: 8px;
    width: 255px;
    align-self: flex-end;
`;

const SpanWithAsterisk = styled.span`
    font-weight: 400;
    white-space: nowrap;

    &:after {
        content: ' *';
        color: ${themedColor('error')};
    }
`;

export const MandatoryIndicator: React.FC<React.PropsWithChildren<unknown>> = ({ children, ...props }) => {
    return <SpanWithAsterisk {...props}>{children}</SpanWithAsterisk>;
};

export const ConditionalMandatory: React.FC<React.PropsWithChildren<{ readonly mandatory?: boolean }>> = ({
    mandatory,
    children,
}) => {
    return mandatory === true ? <MandatoryIndicator>{children}</MandatoryIndicator> : <>{children}</>;
};

export interface ErrorMessageProps {
    readonly errorMessages?: (string | undefined)[];
    readonly showErrors?: boolean;
}

export const ErrorMessage: React.FC<React.PropsWithChildren<ErrorMessageProps>> = ({
    errorMessages = [],
    showErrors,
}) => {
    if (!showErrors) return null;

    const errorMessage = errorMessages.find(e => Boolean(e));

    if (!errorMessage) return null;

    return <StyledErrorMessage>{errorMessage}</StyledErrorMessage>;
};

export type InputWrapperProps = ErrorMessageProps & {
    readonly label: JSX.Element | string;
    readonly children: ReactElement<HTMLInputElement & { readonly withError?: boolean }>;
    readonly className?: string;
    readonly loading?: boolean;
    readonly htmlFor?: string;
    readonly withAsterisk?: boolean;
};

export const InputWrapper = styled(Flex)<{ readonly loading?: boolean }>`
    ${({ loading }) =>
        loading === true &&
        css`
            position: relative;
        `}
    flex-direction: column;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

/**
 * @deprecated
 * Use FormInputWrapper (Form.tsx) from @lemonade-hq/bluis instead
 */
const FormInputWrapper: React.FC<React.PropsWithChildren<InputWrapperProps>> = ({
    label,
    errorMessages = [],
    showErrors,
    children,
    loading,
    className,
    htmlFor,
    withAsterisk = false,
}) => {
    const withError = showErrors && errorMessages.some(e => Boolean(e));
    const clonedInput = React.cloneElement(children, { withError, id: label });

    return (
        <InputWrapper className={className}>
            <InputAndLabel>
                <Label htmlFor={htmlFor}>
                    <ConditionalMandatory mandatory={withAsterisk}>{label}</ConditionalMandatory>
                </Label>
                {clonedInput}
            </InputAndLabel>
            <ErrorMessage errorMessages={errorMessages} showErrors={showErrors} />
            {loading && <DropdownSpinner size={15} />}
        </InputWrapper>
    );
};

const LightboxFormInputWrapper = styled(FormInputWrapper)`
    &:not(:last-child) {
        margin-bottom: 20px;
    }

    ${Label} {
        font-weight: normal;
    }
`;

const FieldSet = styled.fieldset`
    margin-bottom: 48px;
`;

const Legend = styled.legend`
    ${font('main', { fontSize: '14px', fontWeight: 700 })}

    display: block;
    width: 100%;
    padding: 0;
    padding-bottom: 8px;
    margin-bottom: 20px;
    color: ${themedColor('primaryText')};
    border: 0;
    border-bottom: 1px solid ${themedColor('separator')};
`;

type BluisFormType = typeof BluisForm;

interface FormComponents extends BluisFormType {
    FieldSet: React.FC<React.PropsWithChildren<unknown>>;
    Legend: React.FC<React.PropsWithChildren<unknown>>;
    InputWrapper: React.FC<React.PropsWithChildren<InputWrapperProps>>;
    LightboxFormInputWrapper: React.FC<React.PropsWithChildren<InputWrapperProps>>;
}

const Form = BluisForm as FormComponents;

Form.FieldSet = FieldSet;
Form.Legend = Legend;
Form.InputWrapper = FormInputWrapper;
Form.LightboxFormInputWrapper = LightboxFormInputWrapper;

export default Form;
