import type { BaseTool, SerializableTool as Tool } from '@lemonade-hq/persisted-tools';
import { COMPARABLE_TOOL_FIELDS, instructionToString, subclassifierToString } from '@lemonade-hq/persisted-tools';
import type { ArrayChange, Change } from 'diff';
import { diffArrays, diffWords } from 'diff';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useMemo } from 'react';
import type { Writable } from 'type-fest';
import {
    prettifyString,
    prettifyToolMode,
    prettifyToolParam,
    prettifyToolProduct,
    prettifyToolRegion,
    prettifyToolType,
    safeDisplayAs,
} from '../shared/tool.helpers';

export const DIFF_EMPTY_VALUE = '<N/A>';

export interface ExtendedArrayChange<T> extends ArrayChange<T> {
    readonly contentDiffs?: (Change[] | undefined)[];
}

export type ToolsDiff = Required<
    {
        readonly [K in Exclude<keyof BaseTool, 'params'>]: Exclude<BaseTool[K], undefined> extends object[]
            ? ArrayChange<string>[]
            : Change[];
    } & { readonly params: Change[] } // params getting a different treatment since we mostly ignore it as a record
>;

export type ToolAsStrings = {
    readonly [K in Exclude<keyof BaseTool, 'params'>]?: Exclude<BaseTool[K], undefined> extends object[]
        ? string[] | undefined
        : string | undefined;
} & { readonly params?: string | undefined };

function isUnchanged<T extends ArrayChange<unknown> | Change>(diff: T | undefined): diff is T {
    if (diff == null) return false;

    return !diff.added && !diff.removed;
}

function formatToolAsStrings(tool: Tool): ToolAsStrings {
    return {
        ...tool,
        instructions: tool.instructions?.map(safeDisplayAs(instructionToString)),
        macros: tool.macros != null ? [...tool.macros].sort().join('\n') : undefined,
        mode: prettifyToolMode(tool.mode),
        params: tool.params
            ?.map(p => prettifyToolParam(p.name))
            .sort()
            .join(', '),
        products: tool.products
            ?.map(p => prettifyToolProduct(p))
            .sort()
            .join(', '),
        questions: tool.questions?.map(q => q.content),
        region: prettifyToolRegion(tool.region),
        subClassifiers: tool.subClassifiers?.map(safeDisplayAs(subclassifierToString)),
        ticketsIdsExamples:
            tool.ticketsIdsExamples != null ? [...tool.ticketsIdsExamples].sort().join(', ') : undefined,
        type: prettifyToolType(tool.type),
        userGroups: isEmpty(tool.userGroups)
            ? ''
            : tool.userGroups
                  .map(u => prettifyString(u))
                  .sort()
                  .join(', '),
    };
}

function getContentDiffsFromArrayDiffs(
    older: ArrayChange<string>,
    newer: ArrayChange<string>,
    skip: number = 0
): (Change[] | undefined)[] {
    const olderCount = older.count ?? 0;
    const newerCount = newer.count ?? 0;
    const maxItemsCount = Math.max(olderCount, newerCount);
    const editedItemsCount = Math.min(olderCount, newerCount);
    const olderSkip = newerCount < olderCount ? skip : 0;
    const newerSkip = olderCount < newerCount ? skip : 0;

    const contentDiffs: (Change[] | undefined)[] = new Array<Change[] | undefined>(maxItemsCount).fill(undefined);
    for (let j = 0; j < editedItemsCount; j++) {
        const oldValue = older.value[j + olderSkip];
        const newValue = newer.value[j + newerSkip];
        const contentDiff = diffWords(
            isEmpty(oldValue) ? DIFF_EMPTY_VALUE : oldValue,
            isEmpty(newValue) ? DIFF_EMPTY_VALUE : newValue
        );
        // start filling the diffs from the index matching the skipped items
        contentDiffs[j + Math.max(olderSkip, newerSkip)] = contentDiff;
    }

    return contentDiffs;
}

function extendArrayChanges(arrayDiff: ArrayChange<string>[]): ExtendedArrayChange<string>[] {
    const result: ExtendedArrayChange<string>[] = [];
    let i = 0;

    while (i < arrayDiff.length) {
        const diffSet: ArrayChange<string> = arrayDiff[i];
        const nextDiffSet = arrayDiff[i + 1] as ArrayChange<string> | undefined;
        const nextNextDiffSet = arrayDiff[i + 2] as ArrayChange<string> | undefined;

        if (diffSet.removed && nextDiffSet?.added) {
            // this means one item has been edited, rather than something completely removed then another added.
            // there's a possibility the entire content of these items has changed rather than merely edited partially,
            // but we'll still treat it as edited.
            const contentDiffs = getContentDiffsFromArrayDiffs(diffSet, nextDiffSet);
            result.push({ ...diffSet, contentDiffs });
            result.push({ ...nextDiffSet, contentDiffs });

            i += 2; // skip also the added next diff, since we just modified it above
        } else if (diffSet.removed && isUnchanged(nextDiffSet) && nextNextDiffSet?.added) {
            // the diff algorithm might return an unchanged collection in between the removed and added one, this takes care of it
            const contentDiffs = getContentDiffsFromArrayDiffs(diffSet, nextNextDiffSet, nextDiffSet.count); // skip the amount of unchanged values
            result.push({ ...diffSet, contentDiffs });
            result.push(nextDiffSet); // we know this is unchanged, so we just push it
            result.push({
                ...nextNextDiffSet,
                // make the diff fit the size of the change set, since it might be smaller than the diff
                contentDiffs: contentDiffs.slice(
                    nextDiffSet.count,
                    nextDiffSet.count ?? 0 + (nextNextDiffSet.count ?? 0)
                ),
            });

            i += 3; // skip all three
        } else if (diffSet.added && nextDiffSet?.removed) {
            // the same as the first condition, just when added is first.
            // usually the algorithm will return the `removed` ones first, but in some edge-cases this changes
            const contentDiffs = getContentDiffsFromArrayDiffs(nextDiffSet, diffSet); // notice how we reverse the arguments in order to get the right diff
            result.push({ ...diffSet, contentDiffs });
            result.push({ ...nextDiffSet, contentDiffs });

            i += 2; // skip also the removed next diff, since we just modified it
        } else if (diffSet.added && isUnchanged(nextDiffSet) && nextNextDiffSet?.removed) {
            // again, same as the 2nd condition, just when added collections are first
            const contentDiffs = getContentDiffsFromArrayDiffs(nextNextDiffSet, diffSet, nextDiffSet.count); // again, reversed arguments. also skip the amount of unchanged values
            result.push({ ...diffSet, contentDiffs });
            result.push(nextDiffSet); // we know this is unchanged, so we just push it
            result.push({
                ...nextNextDiffSet,
                // make the diff fit the size of the change set, since it might be smaller than the diff
                contentDiffs: contentDiffs.slice(
                    nextDiffSet.count,
                    (nextDiffSet.count ?? 0) + (nextNextDiffSet.count ?? 0)
                ),
            });

            i += 3; // skip all three
        } else {
            // otherwise, push result as normal to the result
            result.push(diffSet);
            i += 1;
        }
    }

    return result;
}

// used to calculate the diff between 2 tools.
// unlike `useComparedTools`, this hook gives the actual diff object
export function useToolsDiff(
    tool: Tool | undefined,
    counterpartTool: Tool | undefined,
    options?: { readonly disabled?: boolean }
): {
    readonly diff: ToolsDiff | null;
    readonly toolToCompare: ToolAsStrings;
    readonly counterpartToolToCompare: ToolAsStrings;
} {
    const toolToCompare = useMemo(() => formatToolAsStrings(pick(tool, COMPARABLE_TOOL_FIELDS) as Tool), [tool]);
    const counterpartToolToCompare = useMemo(
        () => formatToolAsStrings(pick(counterpartTool, COMPARABLE_TOOL_FIELDS) as Tool),
        [counterpartTool]
    );

    const diff = useMemo(() => {
        if (options?.disabled) return null;

        const internalDiff = {} as Writable<ToolsDiff>;

        COMPARABLE_TOOL_FIELDS.forEach(key => {
            const toolValue = toolToCompare[key] as string[];
            const counterpartValue = counterpartToolToCompare[key] as string[];
            if (Array.isArray(toolValue) || Array.isArray(counterpartValue)) {
                const arrayDiff = diffArrays<string, string>(
                    isEmpty(toolValue) ? [] : toolValue,
                    isEmpty(counterpartValue) ? [] : counterpartValue
                );
                internalDiff[key] = extendArrayChanges(arrayDiff) as ArrayChange<string>[] & Change[];
            } else {
                internalDiff[key] = diffWords(
                    isEmpty(toolValue) ? DIFF_EMPTY_VALUE : toolValue,
                    isEmpty(counterpartValue) ? DIFF_EMPTY_VALUE : counterpartValue
                ) as ArrayChange<string>[] & Change[];
            }
        });
        return internalDiff;
    }, [counterpartToolToCompare, toolToCompare, options?.disabled]);

    return { diff, toolToCompare: tool == null ? counterpartToolToCompare : toolToCompare, counterpartToolToCompare };
}
