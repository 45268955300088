import { CardGrid, CardGridArea, Flex, spacing } from '@lemonade-hq/blender-ui';
import { toast } from '@lemonade-hq/bluis';
import { ToolsRevisionStatus } from '@lemonade-hq/persisted-tools';
import isEmpty from 'lodash/isEmpty';
import type { FC, HTMLAttributes } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCurrentToolsRevision } from '../persisted_tools.queries';
import { PlaygroundArea } from '../shared/playground.layout';
import type { PlaygroundURLParams } from '../shared/routing.consts';
import { getInvalidToolsNamesFromToolsRevision } from '../shared/tool.helpers';
import { sanitizeSimulationTicketContent } from './chat.helpers';
import * as styles from './PlaygroundChat.css';
import { PlaygroundChatHeader } from './PlaygroundChatHeader';
import { PlaygroundChatTextArea } from './PlaygroundChatTextArea';
import { PlaygroundChatTimeline } from './PlaygroundChatTimeline';
import { useGetTicketData } from './simulation.queries';
import { usePlaygroundSimulation } from './usePlaygroundSimulation';
import { usePlaygroundTicketId } from './usePlaygroundTicketId';

export const PlaygroundChat: FC<HTMLAttributes<HTMLDivElement>> = props => {
    const { toolsRevisionPublicId = '' } = useParams<PlaygroundURLParams>();
    const [editableMessageValue, setEditableMessageValue] = useState<string>('');
    const { ticketId, setTicketId: handleChangeTicketId } = usePlaygroundTicketId();
    const { data: toolsRevision } = useGetCurrentToolsRevision();
    const {
        addSimulationMessage,
        resetSimulation,
        isLoading: isLoadingNewMessage,
        simulationTimeline,
        wasMessageSentInThisTimeline,
    } = usePlaygroundSimulation({
        toolsRevisionPublicId,
        ticketId,
    });
    const {
        data: ticketData,
        isLoading: isLoadingTicket,
        refetch: handleReloadTicketData,
        isError: isTicketChangeError,
        isSuccess: isTicketChangeSuccess,
        isRefetching: isTicketRefetching,
    } = useGetTicketData(ticketId, {
        onSuccess: fetchedTicketData => {
            resetSimulation();
            setEditableMessageValue(sanitizeSimulationTicketContent(fetchedTicketData.preloadedMessage));
        },
        onError: () => {
            toast.error('Failed to load ticket');
        },
    });

    const handleMessageSubmit = useCallback(() => {
        if (isEmpty(editableMessageValue)) return;

        void addSimulationMessage(editableMessageValue);
        setEditableMessageValue('');
    }, [addSimulationMessage, editableMessageValue]);

    const onTicketChange = useCallback(
        async (value: string) => {
            if (value === ticketId) {
                await handleReloadTicketData();
            } else {
                handleChangeTicketId(value);
            }
        },
        [handleReloadTicketData, handleChangeTicketId, ticketId]
    );

    const invalidToolsNames = useMemo(() => getInvalidToolsNamesFromToolsRevision(toolsRevision), [toolsRevision]);

    const isDisabled =
        wasMessageSentInThisTimeline ||
        isLoadingNewMessage ||
        isLoadingTicket ||
        toolsRevision?.status === ToolsRevisionStatus.Archived ||
        toolsRevision?.status === ToolsRevisionStatus.Sunsetted;

    return (
        <CardGrid areas={[[PlaygroundArea.ChatHeader], [PlaygroundArea.ChatMain]]} showSeparators {...props}>
            <CardGridArea areaName={PlaygroundArea.ChatHeader} className={styles.preventExpansion}>
                <PlaygroundChatHeader
                    isLoading={isLoadingTicket}
                    isTicketChangeError={isTicketChangeError}
                    isTicketChangeSuccess={!isTicketRefetching && isTicketChangeSuccess}
                    onTicketChange={onTicketChange}
                    onTicketReload={handleReloadTicketData}
                    ticketData={ticketData}
                    ticketId={ticketId}
                />
            </CardGridArea>
            <CardGridArea areaName={PlaygroundArea.ChatMain} className={styles.preventExpansion} padding={spacing.s12}>
                <Flex flexDirection="column" gap={spacing.s12} height="100%">
                    <PlaygroundChatTimeline isLoading={isLoadingNewMessage} timeline={simulationTimeline} />
                    <PlaygroundChatTextArea
                        disabled={isDisabled}
                        invalidToolsNames={invalidToolsNames}
                        onChange={setEditableMessageValue}
                        onSubmit={handleMessageSubmit}
                        value={editableMessageValue}
                    />
                </Flex>
            </CardGridArea>
        </CardGrid>
    );
};
