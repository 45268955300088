import { Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import { DateTimePicker, Dialog, FormInputWrapper } from '@lemonade-hq/bluis';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { VersionType } from 'models/LoCo/Insurance/BaseEdition';
import { useUpdateEffectiveDates } from 'queries/LoCo/Insurance/ReleasesQueries';

interface UpdateEffectiveDatesProps {
    readonly releasePublicId: string;
    readonly rolloutStrategy: VersionType;
    readonly newBusinessEffectiveAt: Date;
    readonly renewalEffectiveAt: Date;
    readonly onClose: () => void;
}

export const UpdateEffectiveDates: React.FC<UpdateEffectiveDatesProps> = ({
    releasePublicId,
    onClose,
    rolloutStrategy,
    newBusinessEffectiveAt,
    renewalEffectiveAt,
}) => {
    const [updatedNewBusinessEffectiveAt, setNewBusinessEffectiveAt] = useState<Date>(newBusinessEffectiveAt);
    const [updatedRenewalEffectiveAt, setRenewalEffectiveAt] = useState<Date>(renewalEffectiveAt);

    const { mutateAsync, isPending, error } = useUpdateEffectiveDates(
        releasePublicId,
        updatedNewBusinessEffectiveAt,
        updatedRenewalEffectiveAt
    );

    const update = useCallback(async () => {
        await mutateAsync(releasePublicId);
        onClose();
    }, [mutateAsync, onClose, releasePublicId]);

    const minDate = moment().add(-1, 'day').toDate();
    const maxDate = moment().add(2, 'month').toDate();

    return (
        <Dialog
            actions={[
                { type: 'close', text: 'Cancel', onClick: onClose },
                { type: 'submit', text: 'Save', onClick: update },
            ]}
            error={error === null ? undefined : (error as Error).message}
            loading={isPending}
            title="Change effective dates of release"
        >
            <Flex flexDirection="column" gap={spacing.s16}>
                <Text margin="0 0 1.6rem 0">
                    When the Effective Dates of a Release are updated, the system will recalculate the needed Edition
                    Sets, recheck validations, and generate rollout insights accordingly.
                </Text>
                <FormInputWrapper label="New Business">
                    <DateTimePicker
                        disabled={rolloutStrategy === VersionType.BugFix}
                        maxDate={maxDate}
                        minDate={minDate}
                        onChange={date => setNewBusinessEffectiveAt(date)}
                        value={updatedNewBusinessEffectiveAt}
                    />
                </FormInputWrapper>
                <FormInputWrapper label="Renewals">
                    <DateTimePicker
                        disabled={rolloutStrategy === VersionType.BugFix}
                        maxDate={maxDate}
                        minDate={minDate}
                        onChange={date => setRenewalEffectiveAt(date)}
                        value={updatedRenewalEffectiveAt}
                    />
                </FormInputWrapper>
            </Flex>
        </Dialog>
    );
};
