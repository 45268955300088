import type { ActionOption } from '@lemonade-hq/bluis';
import { ActionsMenu } from '@lemonade-hq/bluis';
import { StyledActionsMenuWrapper } from '../../products/SharedTableConfig';
import { ActionType } from './underwritingUtils';

interface VersionActionMenuProps {
    readonly isDraftVersion: boolean;
    readonly draftExists: boolean; // the reason/explanation response has a draft version
    readonly onActionRequested: (actionType: ActionType) => void;
}

export const VersionActionMenu: React.FC<VersionActionMenuProps> = ({
    onActionRequested,
    isDraftVersion,
    draftExists,
}) => {
    let actionItems: ActionOption[] = [];

    if (isDraftVersion) {
        actionItems = [
            {
                label: 'Edit draft',
                value: ActionType.CreateOrEditDraft,
            },
            {
                label: 'Publish draft',
                value: ActionType.PublishDraft,
            },
            {
                label: 'Archive draft',
                value: ActionType.ArchiveDraft,
            },
        ];
    } else {
        actionItems.push({
            label: 'View version',
            value: ActionType.ViewPublishedVersion,
        });

        if (!draftExists) {
            actionItems.push({
                label: 'Clone as draft',
                value: ActionType.CreateOrEditDraft,
            });
        }
    }

    return (
        <StyledActionsMenuWrapper>
            <ActionsMenu actions={actionItems} onChange={value => onActionRequested(value as ActionType)} type="dots" />
        </StyledActionsMenuWrapper>
    );
};
