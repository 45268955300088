// cspell:disable

export const supervisorMacrosMock = {
    data: {
        guruId: 'zendesk',
        title: 'zendesk',
        boards: [
            {
                guruId: 'pet',
                title: 'Pet',
                items: [
                    {
                        guruId: 'pet_account_management',
                        title: 'Account Management',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_7885135244183',
                                title: 'Update Address [ Pet Moved / Change Address / Moving ] ( STEP 2 ( Premium Decrease )) ( FL Only )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18022323913367',
                                title: 'Update Address [ Pet Moved / Change Address / Moving ] ( STEP 2 ( Premium Decrease ))(Renewal HAS Been Generated)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_7885050323223',
                                title: 'Update Address [ Pet Moved / Change Address / Moving ] ( STEP 2 ( Premium Increase )) ( FL Only )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_7885079343895',
                                title: 'Update Address [ Pet Moved / Change Address / Moving ] ( STEP 2 ( Same Premium )) ( FL Only )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_16641839314967',
                                title: "Why Didn't You Immediately Cancel? ( Pushback )",
                            },
                        ],
                    },
                    {
                        guruId: 'pet_underwriting',
                        title: 'Underwriting',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_9293693776535',
                                title: 'Multiple Pets on One Policy ( Step 1 )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_9293796293271',
                                title: 'Multiple Pets on One Policy ( Step 2 )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_9372929907351',
                                title: 'Multiple Pets on One Policy ( Step 3 ) ( Underwriting Team Only )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_10271370569751',
                                title: 'Triage Pet Medical Records',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_13823875036823',
                                title: 'Multiple Pets on One Policy ( PH Has Policies For Each Pet )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12441984070039',
                                title: 'Response to Form Errors Email [Typos] ( WA, IL, NY, MI, OR, NJ, TX and CA )( Specific Outline Request ) ( Step 1 )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_13648392223127',
                                title: 'Response to Form Errors Email [Typos] ( WA, IL, NY, MI, OR, NJ, TX and CA )( Specific Outline Request ) ( Step 2 )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1500051989262',
                                title: 'Response to Quote Expiration Email ( Coverage Confusion ) ( PH Has Active Policy )',
                            },
                        ],
                    },
                    {
                        guruId: 'zendesk_macro_en-US_25471990807319',
                        title: 'Address Confirmation (CXme)',
                    },
                    {
                        guruId: 'zendesk_macro_en-US_25453716731671',
                        title: 'Can you cancel/revert my Future Cancellation? (CXme)',
                    },
                    {
                        guruId: 'zendesk_macro_en-US_25671256701975',
                        title: 'Cancelation/Refund Policy Pushback (Cxme)',
                    },
                    {
                        guruId: 'zendesk_macro_en-US_25788301043223',
                        title: 'Follow up Response to a 3-star or less (CXme)',
                    },
                    {
                        guruId: 'zendesk_macro_en-US_24994300982039',
                        title: 'Follow-up (CXme)',
                    },
                    {
                        guruId: 'zendesk_macro_en-US_25452881162519',
                        title: 'How is the quote price calculated? (CXme)',
                    },
                    {
                        guruId: 'zendesk_macro_en-US_25475868056599',
                        title: 'PH Name Verification (CXme)',
                    },
                    {
                        guruId: 'zendesk_macro_en-US_24485052947863',
                        title: 'Renters & Pet Address Mismatch  (CXme)',
                    },
                    {
                        guruId: 'zendesk_macro_en-US_25485887852951',
                        title: 'Simple Dental Coverage Options Explanation (CXme)',
                    },
                    {
                        guruId: 'pet_billing',
                        title: 'Billing',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_17159774254103',
                                title: 'CA Renewal Offer Email Bug (outbound)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_13639562156695',
                                title: 'Can I Have a Breakdown of My Premium?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360164463934',
                                title: 'Can I Pay the Annual Premium? [ Pre-purchase ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_16615142806679',
                                title: 'Can I Switch My Payment Plan? [ Monthly / Annually ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360163917993',
                                title: 'Can I Use a Different Credit Card for This Policy?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_10199230038679',
                                title: 'Do You Offer Discounts for Chewy Employees?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360172823034',
                                title: 'Does This Price Include All My Policies?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_15527950195735',
                                title: 'Payment Plan Options ( FL )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14493048461079',
                                title: 'PH Sent Check for Full Annual Premium ( HoOps Flagged CX )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14493219358487',
                                title: 'PH Sent Check for Monthly or Partial Premium ( HoOps Flagged CX )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_5426331109655',
                                title: 'Potential Premium Increase Follow-up ( New Jersey / NJ Only )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_9197207622167',
                                title: 'Rate Increase Talking Points ( Phones )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14387333336727',
                                title: 'Why Did I Get a Refund for My Policy? ( Benji Bug )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14387437478423',
                                title: 'Why Is the Price of My New Quote Different? ( Benji )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360163930753',
                                title: 'Will All the Payments Be Taken Out on the Same Day?',
                            },
                        ],
                    },
                    {
                        guruId: 'pet_business_basics',
                        title: 'Business Basics',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_360172365653',
                                title: 'Are You Available in My State? [ Other State / Launch / Expansion ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360172588914',
                                title: 'Can I Choose a Pet Related Giveback Cause or Charity?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14032095915287',
                                title: 'Connect With a Vet Video Chat ( Lemonade-direct PH )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_16615460576791',
                                title: 'Connect With a Vet Video Chat (Chewy PH )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14032253834007',
                                title: 'I Only Purchased  a Preventative Care Package for the Medical Advice Chat',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14667897788183',
                                title: 'Spanish Speaker',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14838565614231',
                                title: "Why Is Lemonade's BBB Rating So Low? [ Bad ]",
                            },
                        ],
                    },
                    {
                        guruId: 'pet_chewy',
                        title: 'Chewy',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_14178200067351',
                                title: 'Can I Downgrade My Policy? ( No )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14178244646807',
                                title: 'Can I Downgrade My Policy? ( Yes )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14178335202711',
                                title: 'Can I Upgrade My Policy? ( No ) ( Step 1 ) ( General Response )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14178377973143',
                                title: 'Can I Upgrade My Policy? ( No ) ( Step 2 ) ( Pushback Response )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14176481178647',
                                title: 'Chewy Contact Information',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_19147870962711',
                                title: 'Confirm Policy Address [OUTBOUND]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14177984460695',
                                title: 'Connect With a Vet ( Chewy-Lemonade PH )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14031794240151',
                                title: 'Connect With a Vet ( Lemonade-direct PH )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14065500864663',
                                title: 'Hide from view',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14177515008279',
                                title: 'How to Add Another Pet',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14176414557079',
                                title: 'Re-route a Customer to Chewy',
                            },
                        ],
                    },
                    {
                        guruId: 'pet_claims',
                        title: 'Claims',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_18200355670167',
                                title: 'A/I Where’s My Payment',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18200655954967',
                                title: 'Instant claim error',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18200621622167',
                                title: 'New Invoice/Additional Invoice',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18200778234775',
                                title: 'No  Physical Therapy',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18200894695191',
                                title: 'No Behavioral Add On',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360178713754',
                                title: 'Can I File More Than One Claim at a Time?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360195920653',
                                title: 'Can I Have A Blank Claim Form For My Vet?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1500007006282',
                                title: 'Claim Is Pending [ Checking on Status / Claim Update ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_4996138326423',
                                title: 'Claiming Medication from an Online Retailer [ Chewy / Amazon ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_10984413127959',
                                title: 'Claims Status Notes',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360164466694',
                                title: 'How Long Do I Have to File a Claim?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_4638016516119',
                                title: 'How Long Will Reimbursement Take? ( After Filing Claim ) ( Claim Status )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18200517597463',
                                title: 'Lack of Information (Closure)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1500036669381',
                                title: 'Manual Claim Questions ( Post Answers in Comms Timeline of Claim )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360183182973',
                                title: 'My Pet Is Hurt or Sick [ Emergency ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360201690253',
                                title: 'My Pet Might Be Sick or Hurt ( Not Emergency )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18201017978775',
                                title: 'No Exam',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18243904567703',
                                title: 'Pet Manual Claim Inquiry (After App Attempt)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18200716027671',
                                title: 'Policy Not In Effect',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_13994727150359',
                                title: 'Transfer Checklist',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18200757335831',
                                title: 'Waiting Period Denial',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18200328408727',
                                title: 'PH Wants To Appeal, No Claim #',
                            },
                        ],
                    },
                    {
                        guruId: 'pet_claims::reimbursement',
                        title: 'Claims::Reimbursement',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_18085616353687',
                                title: 'Prev - Partial Payment',
                            },
                        ],
                    },
                    {
                        guruId: 'pet_coverage',
                        title: 'Coverage',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_12937095119895',
                                title: 'Can I Add a Preventative Package After the 14 Day Window? ( No )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1900006768985',
                                title: 'Can I Upgrade My Preventative Package? ( YES ) ( Within 14 Days )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_15104471548823',
                                title: 'Do I Have to Take My Pet to the Vet Every Year?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1500038423882',
                                title: 'Do You Cover Boarding?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_5359064984599',
                                title: 'Do You Cover End of Life Costs? ( YES ) ( End of Life Package ) [ EOL ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1900028144885',
                                title: 'Do You Cover Gastropexy? [ Stomach Tacking / Stomach Stapling / Bloat ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360192199773',
                                title: 'Do You Cover Holistic Vets? [ Experimental / Alternative ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360172402614',
                                title: 'Do You Cover Hybrids? [ Wolfdogs / Savannah Cat ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360163931493',
                                title: 'Do You Cover if My Pet Hurts Someone Else? ( Pet Liability )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1500031408922',
                                title: 'Do You Cover Spay / Neuter or Microchipping? ( YES ) ( Pre-purchase ) ( Pets Under 2 )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360172820714',
                                title: 'Do You Cover Titer Tests? ( Vaccine )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1500051721481',
                                title: 'Do You Cover Virtual or Online Vet Visits? ( YES ) ( PH Has Visit Fees Package )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360163918693',
                                title: 'Do You Insure Working Dogs? [ Service Animal / Therapy Animal / Emotional Support Animal ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_19763566971799',
                                title: 'Do You Need Wellness Records Every Visit?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_11753372781975',
                                title: 'Do You Offer Coverage For Dog Walkers or Care Providers ( Not Co-owner )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_19148770312471',
                                title: 'I Already Paid My Deductible Why Do I Have to Pay it Again?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1500009204321',
                                title: 'I Have A Foster Pet',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_17032453319447',
                                title: 'Medical Records Review Talking Points ( Phones )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_13492581437847',
                                title: 'Preventative Care Limit Pushback',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_6978430079767',
                                title: 'Preventative Care Options and Limits',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12937144343703',
                                title: 'Preventative Care Packages Talking Points ( Phones )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_15868235982615',
                                title: 'Upgrade Denial Phone Talking Points',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1500031599342',
                                title: 'What Are the Differences Between the Preventative Packages? ( Pre-purchase )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_21579917996567',
                                title: 'What Behaviorist / Consultant Can I Go To? ( Has Behavioral Conditions Add-on ) [ CAAB , CDBC , CBCC ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360182265293',
                                title: 'What’s a Cruciate Ligament Event?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_5303656900247',
                                title: 'Which Preventative Medications Are Covered? [ Flea , Tick , Heartworm ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_22012377871639',
                                title: 'Will You Cover an Annual Wellness Exam? ( General Coverage Question )',
                            },
                        ],
                    },
                    {
                        guruId: 'pet_onboarding',
                        title: 'Onboarding',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_14991060432279',
                                title: 'Blender Bug ( General Response )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12936997871255',
                                title: 'Can I Add a Preventative Care Package? ( Step 1 )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12936990755479',
                                title: 'Can I Add a Preventative Care Package? ( Step 2 )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_19877340323607',
                                title: 'Cannot Locate Vet Clinc / Stuck on Screen ( Pre-purchase )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_16612551761303',
                                title: 'Dental Illness Not Offered During Sign-up ( Within 14 days )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_23084359152919',
                                title: 'Do I Have to Submit Medical Records Every Time I File a Claim?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_20014136092439',
                                title: 'How Can I Waive My Waiting Periods? ( Eligible ) [ Cian only ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_4531068488343',
                                title: 'I Already Purchased a Policy ( Received Quote Reminder )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18199936734103',
                                title: "I Couldn't Add My Vet Info? [ Vet , Office , Records ]",
                            },
                            {
                                guruId: 'zendesk_macro_en-US_21741827039767',
                                title: "I Couldn't Add My Vet Info?- Follow Up ( Vet , Office , Records )",
                            },
                            {
                                guruId: 'zendesk_macro_en-US_11313545614743',
                                title: "I've Already Submitted My Pet's Records, Why Are You Asking Me For Them Again? (December 2022 Bug)",
                            },
                            {
                                guruId: 'zendesk_macro_en-US_7263919684119',
                                title: 'I’m a Vet, Can I Get a Discount?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1500018541061',
                                title: 'Lemonade as Secondary Pet Insurance ( Pre-purchase )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_4521336027543',
                                title: 'Other Coverage Options ( Risk Buckets )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_7357305265303',
                                title: "PH is Pet's Vet",
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1500037508022',
                                title: 'Quote Higher Than $12 [ More Expensive / Advertised / Advertisement ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_10096636153111',
                                title: 'Speak to a Human [ Email Help ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360196918734',
                                title: 'Switch to Lemonade',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_19762699998999',
                                title: 'Vet Office Requesting Pet Owner To Reach out, Outbound Request [ Outbound / Vet Records ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18318072699543',
                                title: 'Vet Records Received Cannot Locate Policy, Outbound Request [ Outbound / Vet Records ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_20015209746967',
                                title: 'Waiting Period Waiver Form Not Attached [ Cian only ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_20015315512215',
                                title: 'Waiting Period Waiver Form Not Yet Processed [ Cian only ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_20014918450071',
                                title: 'Waiting Period Waiver Form Submitted ( Thanks ) [ Cian only ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_20015900229527',
                                title: 'Waiting Period Waiver Was Denied [ Cian only ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_20015601146007',
                                title: 'Waiting Period Was Waived ( Approved ) [ Cian only ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360172822334',
                                title: 'Waive Waiting Periods [ Currently Insured ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12937034192535',
                                title: 'Wants to Change Quote Coverage (A/B tests)(Not Available on Quote) (Step 1 /General explanation)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12937065627287',
                                title: 'Wants to Change Quote Coverage (A/B tests)(Not Available on Quote) (Step 2)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_4520568222103',
                                title: 'We Got Your Medical Records ( Vet Sent Records With SOAP Notes )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360176494113',
                                title: 'What Breed Should I Choose? ( Designer Breed / Dog )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_4521036548119',
                                title: 'What Breed Should I Choose? ( Rare Breed )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18400967440407',
                                title: 'What Breed Should I Choose? (FL) [ Florida , mixed breed ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_14203956581143',
                                title: 'What if I Sign Up Just to Use Preventative Care Then Cancel?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_10960872362391',
                                title: 'Where to Find a Sample Policy',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_20014455768727',
                                title: "Why Can't I Waive My Waiting Periods? ( Policy Purchased Before Launch )",
                            },
                            {
                                guruId: 'zendesk_macro_en-US_20014685138199',
                                title: "Why Can't I Waive My Waiting Periods? ( State Not Available )",
                            },
                            {
                                guruId: 'zendesk_macro_en-US_1900013380185',
                                title: 'Why Do You Need My Address and Birthdate?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_360171922053',
                                title: 'Why Should I Choose Lemonade Instead of Another Company? [ Competitor Inquiry / Quote Matching ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_13298290656151',
                                title: 'Wrong Policy Document Attached ( March 2023 )',
                            },
                        ],
                    },
                    {
                        guruId: 'pet_pre-approvals',
                        title: 'Pre-approvals',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_12074528462359',
                                title: 'Can I Request a Pre-approval for Preventative Care Costs?',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12071083757335',
                                title: 'Collect Required Information (Contractor)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12074992122903',
                                title: 'How Do I Claim for a Pre-approved Treatment? (Contractor)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12135534267415',
                                title: 'How to Request a Pre-approval (Contractor)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12075186760215',
                                title: "I Don't Have an Estimate From My Vet",
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12075230621847',
                                title: 'Manual Pre-approval Call (Contractor)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12107288112791',
                                title: 'Pre-approval Request ( Triage to CLX Pet ) ( Step Two )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_19857848747031',
                                title: 'Pre-approval Request Does Not Meet $3,500',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12075422234519',
                                title: 'Waiting Periods Haven’t Ended (Contractor)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_12132809999767',
                                title: 'We Still Need Your Estimate ( Follow-up ) (Contractor)',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_17527238342679',
                                title: 'Denied or Sent Back from Contractor',
                            },
                        ],
                    },
                    {
                        guruId: 'pet_renewals',
                        title: 'Renewals',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_16079566669719',
                                title: 'CO Renewal Bug ( Renewal Already Activated ) ( Multiple Pets )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_16079456627607',
                                title: 'CO Renewal Bug ( Renewal Already Activated ) ( Single Pet )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_16079776589719',
                                title: 'CO Renewal Bug ( Renewal Not Activated, But Less Than 30 Day Notice ) ( Multiple Pets )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_16079673234711',
                                title: 'CO Renewal Bug ( Renewal Not Activated, But Less Than 30 Day Notice ) ( Single Pet )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_18860240218903',
                                title: 'Has Puppy/Kitten Preventative Package Requesting Adjustment [RENEWAL AVAILABLE]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_21684043919511',
                                title: 'Premium / Rate Increase (Template)',
                            },
                        ],
                    },
                    {
                        guruId: 'pet_tag',
                        title: 'Tag',
                        cards: [
                            {
                                guruId: 'zendesk_macro_en-US_18764926825495',
                                title: 'Duplicate Medical Records Received [ NRN ]',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_19116265597335',
                                title: 'IL Renewal Rate Increase Notice BUG',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_9457530783639',
                                title: 'Outbound Customer Care Request ( Help Filing Claim )',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_16792626117271',
                                title: 'Survey Response Intro',
                            },
                            {
                                guruId: 'zendesk_macro_en-US_10443088106903',
                                title: 'Trupanion Comparison Misinformation',
                            },
                        ],
                    },
                ],
            },
            {
                guruId: 'apx_servicing',
                title: 'APX Servicing',
                items: [
                    {
                        guruId: 'zendesk_macro_en-US_19762217763991',
                        title: 'Customer Reaching Out To Finalize Agent Generated Quote',
                    },
                ],
            },
        ],
    },
};

export const supervisorMacroCardMock = {
    data: {
        guruId: 'zendesk_macro_en-US_1900010779125',
        title: 'Triage Inbound Car Mail to Mailroom',
        content:
            '<p>I\'m using this macro because I\'m moving a Zendesk Ticket to the mailroom team for a request</p><p><br></p><ul dir="auto"><li>I\'ll <b>apply</b> this macro on a Ticket</li><li>Select the appropriate <b>Requester</b> <i>(so this is tied to the customer timeline)</i> where possible <i>(leave blank if there is no customer tied to request)</i></li><li>Then click "<b>Submit</b>" to move the ticket</li></ul><p><br></p><p><b>Request:</b></p><p><br></p><p>Hi there!</p><p><br></p><p>We received the following document and need to route it to Car for handling.&nbsp; Can you please upload to the appropriate claim in Blender and notify the appropriate advocate?</p><p><br></p><ul dir="auto"><li>Source of Notice:&nbsp;<code>____________</code></li><li>Blender Link:&nbsp;<code>____________</code><br></li><li>Other Details: <code>____________</code><br></li></ul><p><br></p><p><br></p><p><br></p><p>Thanks!</p><p><br></p><p><br></p>',
        tags: ['clx-mailroom', 'triage-to-mailroom-from-clx_car'],
    },
};

export const macrosList = [
    'APX Servicing::About Our Partnership and Communication Methods',
    'APX Servicing::Address Change (Moving)',
    'APX Servicing::Address Change Approved (Manual Update, EST follow up)',
    "APX Servicing::Address Typo (Can't be found)",
    'APX Servicing::Address Typo (Manual Entry)',
    'APX Servicing::Agent Asking for Receipt Before Effective Date',
    'APX Servicing::Agent Request to Add More States (Approved)',
    'APX Servicing::Agent Request to Add More States (Denied)',
    'APX Servicing::Agent Responding To TX New Business Pause',
    'APX Servicing::Appalachian Underwriting Login Request',
    'APX Servicing::Application Received',
    'APX Servicing::Better Mortgage Loan Transfer Acknowledgement',
    'APX Servicing::Broker on Record Change (Agent has 10+ Policies)',
    'APX Servicing::Broker on Record Change Denied (Less than 10 policies)',
    'APX Servicing::Bugs::Texas Rate Change UW Review Bug - Bug Refund (Quote Page Reflecting Lower Premium)',
    'APX Servicing::Buried Utilities Coverage (CO Rate Change)',
    'APX Servicing::California Pause (Declined, New Customers)',
    'APX Servicing::Call Follow Up (Customer Care Requests)',
    'APX Servicing::Can I add a Limited Partnership? (LPs, additional insured) ',
    'APX Servicing::Can I add a Trust?',
    'APX Servicing::Can I add an LLC?',
    "APX Servicing::Can I pay for my client's policy?",
    'APX Servicing::Can I Quote Car Insurance? (Auto Affinity Discount)',
    'APX Servicing::Can I quote HO4? (All States, Select Agencies) ',
    'APX Servicing::Can I Quote HO4? (Yes)',
    'APX Servicing::Can I Quote Pet?',
    'APX Servicing::Cancellation Confirmation (Escrow Paid, Refund Due) ',
    'APX Servicing::Cancellation Confirmation, Backdate Required (Escrow Only)',
    'APX Servicing::Cancellation Request: Step 1 (Agent Customers Only)',
    'APX Servicing::Cancellation Request: Step 2 (Agent Customers Only, Agent-outreach)',
    'APX Servicing::Change Effective Date (EST Required)',
    'APX Servicing::Change Effective Date on a Quote',
    'APX Servicing::Changing Payment Plan (Escrow to Direct)',
    'APX Servicing::Claims History',
    'APX Servicing::Coverage Changes before Renewal',
    'APX Servicing::Death of Insured',
    'APX Servicing::Deductibles (1%, CO Rate Change)',
    'APX Servicing::Dwelling Requirements (OR Rate Change)',
    'APX Servicing::Earthquake Coverage (Palomar)',
    'APX Servicing::Effective Date Change (Closing)',
    'APX Servicing::Equipment Breakdown Coverage',
    'APX Servicing::Escrow Billing Grace Period [ agents, billing , escrow, cancellation ] ',
    'APX Servicing::Escrow Payment Status (Unpaid, check details provided)',
    'APX Servicing::Escrow Payment Status (Unpaid, Dunning)',
    'APX Servicing::Evidence of Insurance Documents (EOI)',
    'APX Servicing::Extra Coverage Explained (Valuable Items)',
    'APX Servicing::Financial Rating (AM Best rating)',
    'APX Servicing::Fireline Eligibility (OR Rate Change)',
    'APX Servicing::Flood Insurance [ FEMA ]',
    'APX Servicing::General Follow Up (Additional Support Required)',
    'APX Servicing::How is the home covered?',
    'APX Servicing::Loan Transfer Customer Outreach',
    'APX Servicing::Minimum Dwelling Requirement (HO3 Only, TX Rate Change)',
    'APX Servicing::Ordinance or Law',
    'APX Servicing::Pending Policies (Unsuccessful EST to Charge Card, Rewrite Required) ',
    'APX Servicing::Personal Injury Coverage (All Other States)',
    'APX Servicing::Personal Injury Coverage (Unavailable, CA and MO) ',
    'APX Servicing::Placeholder Email (customer name changed in Blender, rewrite required)',
    'APX Servicing::Please Remove my Agent',
    'APX Servicing::Please use card ending in xxxx message ( Missing customer info )',
    'APX Servicing::Please use card ending in xxxx message ( Update Card Link )',
    'APX Servicing::Policy Canceled for Dunning (not blocked)',
    'APX Servicing::Policy holder Lender Confirmation',
    'APX Servicing::Portal Premium Change (Rater to Dashboard)',
    'APX Servicing::Post-Bridge Premium Change',
    'APX Servicing::Price Change on Quote/Policy (Rate Changes)',
    'APX Servicing::Quote in Review (Underwriting Process)',
    'APX Servicing::Reinstatement of Canceled Policy',
    'APX Servicing::Request to add Mailing Address',
    "APX Servicing::Requesting to Pay on Customer's Behalf",
    'APX Servicing::Rescind Agent Appointment',
    'APX Servicing::Scheduling Extra Coverage',
    'APX Servicing::Stella Recovery::General Follow Up (Negative Review, 1 Star)',
    'APX Servicing::Terms of Service Explained',
    'APX Servicing::TOS Confirmation',
    'APX Servicing::Townhomes - HO6 or HO3?',
    'APX Servicing::Unable To Make Address Change (Rewrite Required)',
    'APX Servicing::Update Email, Resend TOS',
    'APX Servicing::Update Email, TOS Accepted ',
    'APX Servicing::Updated Mortgagee/Loan Number',
    'APX Servicing::Updating Address with EST',
    'APX Servicing::UW Decline Emails (Why are you emailing my client?)',
    'APX Servicing::UW Declined for Cast Iron/Galvanized Plumbing',
    'APX Servicing::UW Process Follow Up [Negative Feedback]',
    'APX Servicing::UW Process Follow Up [Positive Feedback]',
    'APX Servicing::UW Review Explained (Home Photos, No Inspection Report)',
    'APX Servicing::UW Review Explained (Inspection Report)',
    'APX Servicing::UW Review Explained (TX Only, Roof Inspection) ',
    "APX Servicing::Why Can't I Quote Renters? (Ineligible AmSuisse Agents)",
    'APX Servicing::Wrong Name Showing for Agent (name error, update Zendesk)',
    'Business Policies::Cancellation - Customer confirms residence is rented out',
    'Business Policies::Cancellation - Customer confirms residence is used for business purposes',
    'Business Policies::Cancellation - Customer hasn’t responded after two followups',
    'Business Policies::Cancellation - Organization/third party created account on behalf of PH',
    'Business Policies::Cancellation - Policy is in name of business or LLC',
    'Business Policies::Customer hasn’t responded',
    'Business Policies::Customer hasn’t responded after followup',
    'Business Policies::Discrepancy between name and email',
    'Business Policies::Email in name of business',
    'Business Policies::No Outreach',
    'Business Policies::Website belongs to company',
    'Car PPM::Account Management::Can I Switch to Lemonade Before MM Policy Renewal?',
    'Car PPM::Account Management::Device Failure',
    'Car PPM::Account Management::Device Failure ( Replacement / New Device )',
    'Car PPM::Account Management::Device Troubleshooting ( First Response )',
    'Car PPM::Account Management::Device Troubleshooting ( No Light / Error )',
    'Car PPM::Account Management::I Already Returned My Device',
    'Car PPM::Account Management::Reported Battery Trouble',
    'Car PPM::Billing::My Vehicle Is Being Transported (Mile Removal Requested)',
    'Car PPM::Billing::Payment Details ( Lime ) [ Receipt ]',
    'Car PPM::Billing::Questions About Days in the Month and Base Rate',
    'Car PPM::Billing::Why Did My Rate Increase? [ OR ][ PPM ]',
    'Car PPM::Onboarding::How Do I Plug in My Device?',
    'Car PPM::Onboarding::How Does the Device Work ( Why Do I Need to Keep It Plugged In? )',
    'Car PPM::Onboarding::I Haven’t Received My Device [ Am I Covered? / Waiting for Device ]',
    'Car PPM::Onboarding::Why Is This Quote More Expensive Than My Metromile Policy? ( OR ) [ Price Issues ]',
    'Car PPM::Policy Changes and Info::Can I Have a Copy of My Reports? [ FCRA / Transunion ]',
    'Car PPM::Policy Changes and Info::How Can I Cancel My Policy? [ App ]',
    'Car PPM::Policy Changes and Info::Roadside Assistance (PPM add on)',
    'Car PPM::Policy Changes and Info::Why does my car policy say Metromile? ( OR )',
    'Car::Account Management::Backdate My Policy [ Premium / Refund ]',
    'Car::Account Management::Can You Cancel My Previous Insurance?',
    'Car::Account Management::Car Verification [ Lienholder / Policyholder / Verify / Coverage ]',
    'Car::Account Management::Chase Cycle Trouble Shooting ( iPhone )',
    'Car::Account Management::Chase Cycle Troubleshooting ( Android )',
    'Car::Account Management::Do You Do a Credit Check? ( Yes ) ( Not CA )',
    'Car::Account Management::Follow Up to a No Response',
    'Car::Account Management::Legal Name [ Name Change / Facebook / Typo ]',
    'Car::Account Management::Migration Difference in App Features (MIC)',
    'Car::Account Management::Missing Lender Information',
    'Car::Account Management::Need More Information',
    'Car::Account Management::Reinstatement ( Company Error )',
    'Car::Account Management::Will Driving a Different Car Count Towards My Rating? [ Driver / Rental Car / Rate / Telematics / Renewal / Vacation ]',
    'Car::Billing::Can I Switch My Payment Plan? [ Monthly / Semi-annual / Switching / Payment Plan ]',
    'Car::Billing::Can I Use a Different Credit Card for This Policy?',
    'Car::Billing::Does This Price Include All My Policies?',
    'Car::Billing::Future Dunning [ Dunning / Not Yet in Dunning / Payments ]',
    'Car::Billing::Motor Vehicle Crime Prevention Authority ( TX ) [ Fee / $2 / Charge ]',
    'Car::Billing::Payment Dispute ( Final Email )',
    'Car::Billing::Payment Dispute ( First Email )',
    'Car::Billing::Payment Dispute ( Second Email )',
    'Car::Billing::Price Increase ( Location Services Discount Removed )',
    'Car::Billing::Price Issues ( Too Expensive ) ( Pre-purchase )',
    'Car::Billing::Renewals ( Will My Price Change? )',
    'Car::Billing::What Is an Installment Fee? ( Why? )',
    'Car::Billing::What Is My Premium?',
    'Car::Billing::Will All the Payments Be Taken Out on the Same Day?',
    'Car::Billing::Will My Price Go Up After an Accident? [ Claim / Accident / Premium / Price / Increase ]',
    'Car::Business Basics::Has a Lemonade Policy ( PA / VA ) [ Metromile ]',
    'Car::Business Basics::How Do You Offset My Carbon Footprint?',
    'Car::Business Basics::Spanish Speaker',
    'Car::Claims::Car Manual Claim Inquiry (After App Attempt)',
    'Car::Claims::Do I Need to File a Police Report After an Accident?',
    'Car::Claims::Does Roadside Assistance Count Towards a Claim? [ Claim / App / Telematics / Location Services ]',
    'Car::Claims::Filing a Claim ( How Do I Get Reimbursed for Ridesharing Credits ) [ Uber / Lyft ]',
    'Car::Claims::Filing a Claim ( Key Replacement ) [ Roadside Assistance ]',
    'Car::Claims::Filing a Claim ( Towing ) [ Tow / Roadside Assistance / Claim ]',
    'Car::Claims::How to File a Claim ( Not at the Scene )',
    'Car::Claims::If Someone Who Is Not on My Policy Gets Into an Accident Am I Covered? [ Permissive Use , Other , Driver , Friend ] ',
    'Car::Claims::What Is the Recovery Process for a Stolen Vehicle? [ Claim / Theft / Police ]',
    'Car::Coverage::Am I covered out of state? [ Canada / US Territory ]',
    'Car::Coverage::Are Wall Chargers Covered? [ Tesla / Electric Cars ]',
    'Car::Coverage::Can You Cover Anyone Under 18? ( Yes )',
    'Car::Coverage::Comparing Quotes ( Is This My Final Price? ) [ Quote / Pricing / Policy ]',
    'Car::Coverage::Comparing Quotes ( Why Did My Price Change? ) [ Final Price / Vehicle History Score / Activate ]',
    'Car::Coverage::Coverage Rejection Form ( IL / OH / TN )',
    'Car::Coverage::Covered Perils [ Acts of God / Comprehensive / Coverage / Weather / Animals ]',
    'Car::Coverage::Covered Perils [ Collision / Coverage / Car / Accident / Hit and Run ]',
    'Car::Coverage::Covered Perils [ Theft / Personal Belongings / Stolen ]',
    'Car::Coverage::Custom Parts',
    'Car::Coverage::Do I Have “Full Coverage”? [ Liability / Comprehensive / Collision ]',
    'Car::Coverage::Do I Have the Coverages I Need for a Lease? [ Financing / Leasing / Car Payment / Quote Only ]',
    'Car::Coverage::Do You Cover Non-private Passenger Vehicles? ( Catch All ) [ Semi-trucks / ATV / Moped / Dune Buggy / Motorcycle ]',
    'Car::Coverage::Do You Offer Coverage for My Trailer? [ RV / Moving Trailer ]',
    'Car::Coverage::Extended Glass Coverage ( OH ) [ Collision , Comprehensive ]',
    'Car::Coverage::Extended Glass Coverage ( TN ) [ Collision , Comprehensive ]',
    'Car::Coverage::Gap Coverage',
    'Car::Coverage::Glass ( IL / TX ) [ Windshield / Side View Mirror / Broken / Cracked ]',
    'Car::Coverage::How Much Is My Vehicle Covered For? [ Claim / Repair / Accident / Coverage / ACV ]',
    'Car::Coverage::Insurable Interest ( How Is My Significant Other Covered? ) [ Significant Other / Girlfriend / Boyfriend ]',
    'Car::Coverage::Insurable Interest ( Short-Term Policy ) ( Non-owner ) [ Vacation / Temporary ]',
    'Car::Coverage::Insurable Interest (Spouse or Domestic Partner)',
    'Car::Coverage::Is Equipment Breakdown Covered? [ Radio  / AC  / Electrical equipment / Breakdown ]',
    'Car::Coverage::Natural Disaster Risk ( TX ) [ Hail / Covered Parking ]',
    'Car::Coverage::Personal Injury Protection ( TX ) [ PIP ]',
    'Car::Coverage::Removing Coverage ( Collision )',
    'Car::Coverage::Removing Coverage ( Comprehensive )',
    'Car::Coverage::Rental Car ( Pre-Purchase )',
    'Car::Coverage::Renting a Moving Truck [ U-haul / Moving / Truck ]',
    'Car::Coverage::Tow Reimbursement [ Towing / Claim / Payment / Accident / Breakdown ]',
    'Car::Coverage::Wear and Tear [ Maintenance / Oil Change / Brake Pads / Tires / Engine ]',
    'Car::Coverage::What Are My Coverages? [ Policy / Liability / Comprehensive / Collision / UMBI / Med Pay / Temporary Transportation ]',
    'Car::Coverage::What Does Car Insurance Cover? [ Coverage / Quote / Policy / Liability ]',
    'Car::Coverage::What Is a Deductible?',
    'Car::Onboarding::Can I Change My Effective Date? ( No ) [ high risk user , minimum ]',
    'Car::Onboarding::Can I Change My Start Date to Today? [ New Policy / Effective Date ]',
    'Car::Onboarding::Can I Get a Quote for a Fixed Policy? ( PPM Only , OR , Oregon )',
    'Car::Onboarding::Can I Have a Copy of My Quote?',
    'Car::Onboarding::Do I Get a Bundle Discount on My Car Quote? [ Existing / Policy ]',
    'Car::Onboarding::How Can I Switch to Lemonade? [ Quote / Onboarding / Policy / Switching ]',
    'Car::Onboarding::How Do I Get Insurance for My New Car? [ Quote / Policy / Onboarding ]',
    'Car::Onboarding::How Do You Obtain Information About Me and My Car? [ Personal Details / Quote ]',
    'Car::Onboarding::I Do Not Have a Smartphone [ iPhone / Android / App / Website ]',
    'Car::Onboarding::Same Day Coverage [ Start Date / Today / Change Date / Dealership ]',
    'Car::Onboarding::Starting a New Quote ( Annual Mileage )',
    'Car::Onboarding::Starting a New Quote ( Foreign License / International )',
    'Car::Onboarding::Starting a New Quote ( OEM Parts )',
    'Car::Onboarding::Starting a New Quote ( Price per Vehicle ) [ Post Purchase / Quote ]',
    'Car::Onboarding::Starting a New Quote ( Price per Vehicle ) [ Pre-purchase ]',
    'Car::Onboarding::Starting a New Quote ( VIN Number )',
    'Car::Onboarding::Starting a New Quote ( Who Are the Extra People or Cars on My Quote? ) [ Drivers / Vehicle / Extra Car ]',
    'Car::Onboarding::Suspended or Expired License',
    'Car::Onboarding::What Would My Price Be Without an Added Driver or Car ( Quote Only ) [ Coverage / Changes ]',
    'Car::Onboarding::When Should My Policy Start? [ Start Date / Switching ]',
    'Car::Onboarding::Why Do I See My Name Twice? [ Quote / Onboarding / Policy ]',
    'Car::Onboarding::Why Don’t All the Drivers Appear on the ID Cards? [ ID Cards / Significant Other / Other Driver / Secondary Driver / Listed Drivers ]',
    'Car::Onboarding::Why Should I Choose You Instead of Another Company? [ Can I Trust You? ]',
    'Car::Policy Changes and Info::20-Day Newly Acquired Vehicle ( TX ) [ Exception / Risk ]',
    'Car::Policy Changes and Info::Can I Have a Copy of My Reports? [ FCRA / Lexis Nexis ]',
    'Car::Policy Changes and Info::Can I Just Use Location Services When the App Is in Use? [ Telematics / Roadside / Open App / Driving ]',
    'Car::Policy Changes and Info::Does the Make & Model of My Car Affect My Insurance? [ Trim / Year / Model / Size ]',
    'Car::Policy Changes and Info::Home State County Mutual Insurance Company ( TX )',
    'Car::Policy Changes and Info::Ineligible Vehicle And Ineligible Operator Surcharge ( TX ) [ Exception / Risk ]',
    'Car::Policy Changes and Info::People on My Policy ( Who Needs to Be Included? ) [ Family / Secondary Insured / Spouse / Kids / 19 ]',
    'Car::Policy Changes and Info::Questions About Location Services ( Post Purchase ) [ Onboarding Flow / Telematics / Account Setup / Activation ]',
    'Car::Policy Changes and Info::Remove Car [ Vehicle / Edit Policy ]',
    'Car::Policy Changes and Info::Remove Driver ( Part 2 )',
    'Car::Policy Changes and Info::Update Policy Address ( Step 1 ) [ Change / Premium / Moving ]',
    'Car::Renewal::General Renewal Price Decrease',
    'Car::Renewal::General Renewal Price Increase',
    'Car::Renewal::Rate Increase PUSHBACK (Migration CA)',
    'Car::Renewal::Renewal Price Decrease With Reason',
    'Car::Renewal::Renewal Price Increase With Reason',
    'Car::Renewal::Renewal Price Increase With Reason (Template) ',
    'Car::Telematics::Crash Detection ( Why Did I Get a Phone Call? ) [ Crash Detection / Emergency / Call / Accident ]',
    'Car::Underwriting: Statement of No Loss',
    'Car::Underwriting::Accident Dispute ( First Contact ) [ Fault / Claim / CLUE ]',
    'Car::Underwriting::Add Hidden or Undisclosed Driver [ Unlisted ]',
    'Car::Underwriting::Blocked [ Risk Network ]',
    'Car::Underwriting::Can You Re-Run My Credit Report?',
    'Car::Underwriting::Canceled Policy [ Due to Rideshare Dispute ]',
    'Car::Underwriting::Canceled Policy Due to Failure to Provide [ UW Cancelation ]',
    'Car::Underwriting::Car Usage ( Response )',
    'Car::Underwriting::Decline - Coastal Zip Code ( TX ) [ UW ]',
    'Car::Underwriting::Defensive Driver Discount ( First Contact ) ( Proof Needed )  [ DDC / Certificate / Defense ]',
    'Car::Underwriting::Driver Exclusion ( First Contact ) [ Remove Driver / Delete Driver ]',
    'Car::Underwriting::Drivers License Not Valid ( Expired Out of State License ) [ Out of State / Not Valid ]',
    'Car::Underwriting::Ineligible Car or Multi-Car Policy  [ Claims / Risk / Delete / Remove ]',
    'Car::Underwriting::Ineligible Car or Single Car Policy  [ Claims / Risk / Delete / Remove ]',
    'Car::Underwriting::Ineligible Driver',
    'Car::Underwriting::Missing Spouse ( First Contact ) [ Spouse / Unlisted / Husband / Wife / Partner / Domestic / Civil / Union ]',
    'Car::Underwriting::New Business UW Referral ( Combo ) ( Follow-up )',
    'Car::Underwriting::New Business UW Referral ( First Response )',
    'Car::Underwriting::No Hit License [ MVR / New Business / Quote / Eligibility ]',
    'Car::Underwriting::NOC Courtesy Letter [ MVR / New Business / Quote / Eligibility / License / Cancellation ]',
    'Car::Underwriting::Open Claim [ CLUE / Accident / Pricing / Surcharge ]',
    'Car::Underwriting::Possible Related Claims ( Final Contact ) [ Dispute ]',
    'Car::Underwriting::Possible Related Claims ( First Contact ) [ Dispute ]',
    'Car::Underwriting::Rated location discrepancy ( First Contact ) [ Claim / Rideshare / Business / Delivery / Uber / Lyft / Turo / Getaround ]',
    'Car::Underwriting::Rated Location Discrepancy ( Renewal or Midterm )',
    'Car::Underwriting::Registration Name Concern ( First Contact ) [ Garaging / Address / Location / Ownership / State ]',
    'Car::Underwriting::Severe Damage [ VIN / 3PD ]',
    'Car::Underwriting::SR22',
    'Car::Underwriting::Temporary Block [ Moratorium ]',
    'Car::Underwriting::TX Driver Exclusion Required ( Conditional Renewal )',
    'Car::Underwriting::UM election form  [ Uninsured / Signature / Application / Lower / Rejection / Reject ]',
    'Car::Underwriting::Underwriting Change  [ Driver / Exposure / Risk / Endorsement ]',
    'Car::Underwriting::Undisclosed Driver ( First Contact ) [ Undisclosed / Unlisted ]',
    'Car::Underwriting::VIN Confirmation  [ DMV / Ownership / Registration / Quote ]',
    'Claims::Third Party Claims',
    "CLX Car Mailroom::Mailroom Team's Confirmation & Response",
    'CLX Car Mailroom::Triage Inbound Car Mail to Mailroom',
    'CLX Pet::CLX Pet Claims Appeals Survey',
    'CLX Vendors ::Solve Medical Record',
    'CLX::CLX Service Recovery 1 (More Context Needed)',
    'CLX::CLX Service Recovery 2 (Issue can be resolved)',
    'CLX::CLX Service Recovery 3 ( Issue can not be resolved )',
    'CLX::Formal Compliance Language for CA',
    'CLX::Formal Compliance Language for IL',
    'CLX::Formal Compliance Language for NY',
    'CLX::Pet::accidental-public-comment_2-5-21-replied',
    'CX::Property::Account Management::Schedule Cancelation Confirmation',
    'CX::APX::Servicing::Chat Follow Up (Intercom)',
    "CX::APX::Servicing::Can't Find Quote/Policy ( Info not provided )",
    'CX::APX::Servicing::Cancellation Confirmation (Direct Payments)',
    'CX::APX::Servicing::Cancellation Confirmation, Backdate Required (Direct Pay)',
    'CX::APX::Servicing::Login Issues (Agent Dashboard Access)',
    'CX::APX::Servicing::Payment Status (Paid in full)',
    'CX::APX::Servicing::UW Review Docs (quote not submitted)',
    'CX::APX::Servicing::UW Review Docs Received (inspection, photos, appraisal)',
    'CX::Car PPM::Account Management::Cancel Renewal (Renewal Not Generated Yet- Migration)',
    'CX::Car PPM::Account Management::Car is in Shop/PH on Vacation ( Device Connection Grace Period )',
    'CX::Car PPM::Account Management::Device Connection Restored (No Penalty)',
    'CX::Car PPM::Billing::Can I Get a Policy Receipt ( Billing Breakdown ) ',
    'CX::Car PPM::Billing::How Does the Pre-Payment Plan Work?',
    'CX::Car PPM::Onboarding::CA Non-Cross Sell New Customer (PART 1)',
    'CX::Car PPM::Onboarding::CA Non-Cross Sell New Customer (PART 2- ANDROID) ',
    'CX::Car PPM::Onboarding::CA Non-Cross Sell New Customer (PART 2- APPLE)  ',
    'CX::Car PPM::Onboarding::Why Do I Need To Download The Lemonade App (AZ / OR / WA / CA)',
    'CX::Car::Account Management::Carrier Initiated Cancellation/Backdate (Outbound) ',
    'CX::Car::Account Management::Migration Reinstatements ( Outbound )',
    'CX::Car::Account Management::Telematics Failure [ Troubleshooting , Missing ]',
    'CX::Car::Billing::Active Dunning [ lapse in coverage ]',
    'CX::Car::Billing::Price Issues [ Too Expensive , Pre-purchase ]',
    'CX::Car::Billing::Wants to Pay Monthly, Only Offered Semi-Annual',
    'CX::Car::Business Basics::Are You Available in My State?',
    'CX::Car::Business Basics::Why Did My Rate Increase? ( IL , TN, OH )',
    'CX::Car::Coverage::Does This Policy Cover Rental Cars? [ Turo , Lyft , Uber , Temporary , Transportation ]',
    'CX::Car::Coverage::How Do I Change / Edit My Policy Coverages?',
    'CX::Car::Coverage::Roadside Assistance [ Tow / Jumpstart / Keys ]',
    "CX::Car::Coverage::Why Won't You Insure My Car? [ High Value , Performance , Luxury ]",
    'CX::Car::Coverage::Why Wont You Insure My Car? [ Over 20 Years Old , Car Age , Car Too Old ]',
    'CX::Car::Onboarding::Can I Bundle For a Discount?',
    'CX::Car::Onboarding::Can’t Find My Car [ Quote , Policy , Make , Model ]',
    'CX::Car::Onboarding::CARCO Inspection',
    'CX::Car::Onboarding::How Does Telematics Work ( Why Do I Need to Allow It? ) [ Driver , Passenger , Privacy ]',
    'CX::Car::Onboarding::How Much Does Car Insurance Cost? [ Quote , Cost , Policy ]',
    'CX::Car::Onboarding::Making Changes to a Quote [ Edit , Driver , Vehicle , Policy , Effective Date , Address ]',
    'CX::Car::Onboarding::NJ Paper ID Cards Requested [ New Jersey , Insurance Card ] ',
    'CX::Car::Onboarding::Vehicle Not Registered in Garaging State [ another state ] ',
    'CX::Car::Onboarding::Where Can I Find My Car Insurance ID Cards? [ Policy / App / Website / Insurance Card ]',
    'CX::Car::Partnerships::Bolt Agency Claims Support',
    'CX::Car::Partnerships::Bolt Agency Pre-Purchase Inquiry',
    'CX::Car::Partnerships::Bolt Agency Service on Existing Policy',
    'CX::Car::Payments and Billing::All Discounts Applied ( No Available Discounts) ',
    'CX::Car::Policy Changes and Info::Can I Change My Effective Date? [ future start date , policy , quote ]',
    'CX::Car::Policy Changes and Info::Can I Exclude a Driver From a Specific Vehicle? ',
    'CX::Car::Policy Changes and Info::Can I Get a Copy of My Car Policy? [ documents , declarations page ]',
    'CX::Car::Policy Changes and Info::How Can I Cancel My Policy? [ App ]',
    'CX::Car::Policy Changes and Info::How Do I Add a Car to My Policy? [ Add Car , Vehicle , Edit Policy ]',
    'CX::Car::Policy Changes and Info::How Do I Add a Driver to My Policy? [ Edit Policy ]',
    'CX::Car::Policy Changes and Info::I Need to Update or Add the Lienholder Information',
    'CX::Car::Policy Changes and Info::License Has Been Taken Response',
    'CX::Car::Policy Changes and Info::Moving to Non-covered State ( Address Change )',
    'CX::Car::Policy Changes and Info::Remove Driver ( Part 1 )',
    'CX::Car::Policy Changes and Info::Replace Car [ Vehicle , Edit Policy , Add and Remove ]',
    'CX::Car::Policy Changes and Info::What Does Business Use Mean? [ quote , decline , denied , work , Uber , Lyft , delivery ]',
    'CX::Car::Policy Changes and Info::Who Needs to Be the Registered Owner? [ Family , Spouse , Significant Other ]',
    'CX::Car::Renewal::General Migration Rate Increase ( PUSHBACK )',
    'CX::Car::Telematics::Force Add Driver [ Telematics , Force Add , Force Add Driver , Sync ] ',
    'CX::Car::Underwriting::Decline to Reinstate or Purchase New Policy [ Cancellation , Reinstatement , Company Error , Lapse ]',
    'CX::Car::Underwriting::Declined Due to Claims History',
    'CX::Car::Underwriting::Declined Due to Violation [ Driving History , Fault ]',
    'CX::Car::Underwriting::Drivers License Not Valid (System Update)',
    'CX::Car::Underwriting::Lapse of Coverage ( Gap ) [ UW Decline , No Continuous Insurance ]',
    'CX::Car::Underwriting::No Car ( NNO , Named Owner ) [ UW Decline , Registered Vehicle ]',
    'CX::Car::Underwriting::Not a Lapse of Coverage ( Newly Purchased Car ) [ Lapse , Decline , UW , New Car ]',
    'CX::Car::Underwriting::Quote In Review ( Step 1 ) [ Underwriting Review ]',
    'CX::Car::Underwriting::Quote In Review ( Step 2 ) [ Underwriting Review ]',
    'CX::Car::Underwriting::Salvage [ UW Decline , Damage , Severe ]',
    'CX::General::Account Management::Can I transfer my policy to someone else? [ Name Change , Switch , Ownership ]',
    'CX::General::Account Management::Delete my account ( Not GA / CA )',
    'CX::General::Account Management::Delete My Account Information ( GA & CA ) [ SSN ]',
    'CX::General::Account Management::How Do I Log In to My Account?',
    'CX::General::Account Management::Legal Name [ Name Change / Facebook / Typo ]',
    'CX::General::Account Management::Logged Into Wrong Account [ Switch ]',
    'CX::General::Account Management::PH Meant To Cancel at a Later Date ( Too Early )',
    'CX::General::Account Management::Update My Email Address',
    'CX::General::Account Management::Update My Phone Number',
    'CX::General::Account Management::What’s up With My Password? [ Sign In , Account , OTP ]',
    'CX::General::Account Management::Who is my agent?',
    'CX::General::Backdate::Confirmation',
    "CX::General::Backdate::I've sent the request",
    'CX::General::Backdate::New Lease Document Backdate ( Step 2 )',
    'CX::General::Backdate::New Lemonade Policy',
    'CX::General::Billing::Accidental Dispute ( Step 1 )',
    'CX::General::Billing::Can I Change My Payment Date?',
    'CX::General::Billing::Can I Pay Early? [ Pay Day , Billing , Due Date ]',
    'CX::General::Billing::Can I Pay My Balance? ( Post-purchase )',
    'CX::General::Billing::Can I Schedule a Payment? ( Pay My Bill ) [ Payment Date , Change , Billing ]',
    'CX::General::Billing::Can I See My Payment History? [ Past Charges , Invoice , Receipt ]',
    'CX::General::Billing::Can You Send Me a Receipt? [ Statement , Payment History ]',
    'CX::General::Billing::Can You Take Me off Auto Pay?',
    'CX::General::Billing::Do You Offer Discounts? [ Military , Senior , Student , AAA , AARP ]',
    'CX::General::Billing::Payment Not Authorized ( Knows PH ) [ Unauthorized Charges , Relative , Friend , Dispute ]',
    'CX::General::Billing::Pre-paid Cards ( Post-purchase )',
    'CX::General::Billing::Pre-paid Cards ( Pre-purchase )',
    'CX::General::Billing::Update Credit Card [ Payment Details / Payment Method / Billing Details ]',
    'CX::General::Billing::What charges should I expect to see and when? ( Pre-Purchase ) [ Deposit , Charges , Fee ]',
    'CX::General::Billing::When Is My Due Date?',
    'CX::General::Billing::Where Is My Refund Going? ( Does Not Have Bank Account Anymore ) [ Closed , Card ]',
    'CX::General::Billing::Why Did You Charge Me Already? ( Post-purchase ) [ Effective , Hasn’t Started , Confused ]',
    'CX::General::Billing::What is an Installment Fee? Why?',
    'CX::General::Bug::App Troubleshooting [ Bug , Error , Stuck , Frozen ]',
    'CX::General::Bug::Website is Down',
    'CX::General::Business Basics::Apple to Apple Comparison ( Compare Insurance Products )',
    'CX::General::Business Basics::Are You Supporting Palestinian Humanitarian Efforts? [ Israel , Hamas , Palestine , Gaza , War ] ',
    'CX::General::Business Basics::Customer No Longer Interested in Quote',
    'CX::General::Business Basics::Customer Received Suspicious Security Email',
    'CX::General::Business Basics::Do You Support Veterans Charities? ( Giveback )',
    'CX::General::Business Basics::Does Lemonade Support Israel? (General Question) [ Israel , Hamas , Palestine , Gaza , War ]',
    'CX::General::Business Basics::Fax Number',
    'CX::General::Business Basics::How Can I Help? Can I Donate My Giveback? [ Israel , Hamas , Palestine , Gaza , War ] ',
    'CX::General::Business Basics::Is Lemonade Supporting its Team Members?  [ Israel , Hamas , Palestine , Gaza , War ] ',
    'CX::General::Claims::How Do I File A Claim on Your Website?',
    'CX::General::Dunning::I’m Waiting for a New Card From the Bank',
    'CX::General::Dunning::I’m Waiting to Get Paid [ Insufficient Funds ]',
    'CX::General::Dunning::PH Wants to Set Up New Policy After Dunning Cancelation',
    'CX::General::Dunning::PH Wants to Set Up New Policy After Dunning Cancelation ( Blocked )',
    'CX::General::Dunning::Success ( Confirmation )',
    'CX::General::Dunning::Why Do I Have an Outstanding Balance? ( PH Replied to Credit Score Email )',
    'CX::General::Dunning::Why Is There an Additional Charge to Come Back? ( Old Dunning Balance Collected ) [ Previous , Create New , Reinstate ]',
    'CX::General::Follow Up::Do You Still Need Help? [ CX AI Maya ]',
    'CX::General::Follow Up::General Email Follow Up to SMS',
    'CX::General::Follow Up::I Left a Voicemail',
    "CX::General::Follow Up::I've Submitted The Request ( Outbound ) [ EST ]",
    'CX::General::Follow Up::No Response [ Nudge ]',
    "CX::General::Follow Up::PH Doesn't Need Help Anymore [ Self-Service ]",
    'CX::General::Medallia::General Follow-up [ 1 Star ]',
    'CX::General::Medallia::Help or Clarify [ 1 Star ]',
    'CX::General::Medallia::Unable to Assist Further [ 1 Star ]',
    'CX::General::Onboarding::Can I Have a Copy of My Quote?',
    'CX::General::Onboarding::Can You Give Me a Quote? [ Getting Started ]',
    'CX::General::Onboarding::Can’t Download App ( Does Not Have Smartphone )',
    'CX::General::Onboarding::How Do I Set Up a Policy to Start in the Future? ( NOT PET , NOT FL )',
    'CX::General::Onboarding::How To Adjust Quote Coverages',
    'CX::General::Onboarding::Manual Address Workaround',
    'CX::General::Policy Changes and Info::Can I Cancel My Policy At Any Time?',
    'CX::General::Policy Changes and Info::Can You Cancel My Renewal Policy? ( Already Drafted )',
    'CX::General::Policy Changes and Info::Can You Cancel My Renewal Policy? ( Too Far Out )',
    'CX::General::Policy Changes and Info::Can You Resend My Policy Document?',
    'CX::General::Policy Changes and Info::Can You Send a Paper Copy of My Policy in the Mail? ( NOT FL )',
    "CX::General::Policy Changes and Info::Can't Find Policy ( Step 1 )",
    "CX::General::Policy Changes and Info::Can't Find Policy ( Step 2 )",
    'CX::General::Policy Changes and Info::Change Start Date ( Policy Already Active )',
    'CX::General::Policy Changes and Info::Duplicate Policy ( Step 1 - Why Am I Being Charged Twice? )',
    "CX::General::Policy Changes and Info::Family Member Canceling Deceased PH's Account [ Passed Away , Death Certificate , Executor of Estate ]",
    'CX::General::Policy Changes and Info::Here Is Your Renewal Policy  [ Locate / Account / PDF / Download ]',
    "CX::General::Policy Changes and Info::I've Updated Your Address ( Confirmation )",
    'CX::General::Policy Changes and Info::Manual Change Follow-up ( Confirmation )',
    'CX::General::Policy Changes and Info::Renewal Pushback [ Refund , Backdate , Cancel ]',
    'CX::General::Policy Changes and Info::Where Can I Find My Policy?',
    "CX::General::Policy Changes and Info::Why Wasn't My Policy Canceled?",
    "CX::General::Policy Changes and Info::Why Wasn't My Policy Canceled? (No Previous Request Found)",
    'CX::General::Policy Changes and Info::Will My Policy Renew Automatically?',
    'CX::General::Referrals and Promotions::Can I Refer a Friend? ( Yes ) ( User Is in Ambassador Program )',
    'CX::General::Referrals and Promotions::I Referred a Friend, Where Is My Amazon Gift Card? [ Ambassador ] ',
    'CX::General::Referrals and Promotions::Referral Not Available',
    'CX::General::Template::Escalation Response',
    'CX::General::Underwriting::Decline ( Payment History )',
    'CX::General::Where Are You Live?::Car Not Available in Your State ( Not IL, OH, TN, or TX)',
    'CX::General::Where Are You Live?::Not Available in Your Country',
    'CX::General::Where Are You Live?::Products in AZ / CA / CT / CO / DC / GA / IA / IN / MD / MI / MO / NJ / NV / OH / OK / OR / PA / TX / VA / WI',
    'CX::Homeowners::Account Management::Why Did I Get This? ( RCE Email ) ( Not CA )',
    'CX::Homeowners::Backdate::Backdate My Policy',
    'CX::Homeowners::Billing::Manual Check Refund Timeline',
    'CX::Homeowners::Billing::My Lender Sent a Check ( Why Am I Being Canceled? )',
    'CX::Homeowners::Billing::Pay Directly and Not Escrow',
    'CX::Homeowners::Billing::Policy Paid in Full ( Receipt )',
    'CX::Homeowners::Billing::Refunding HO3 PH After a Move ( Need Updated Address )',
    'CX::Homeowners::Business Basics::Lender Requires RCE - What Is That?',
    'CX::Homeowners::Business Basics::Replacement Cost vs. Market Value',
    'CX::Homeowners::Business Basics::Too Expensive Goodbye',
    'CX::Homeowners::Coverage::Active Policy / Plans to Rent Out Property ( CA Specific Workaround , No Upcoming Renewal )',
    'CX::Homeowners::Coverage::Active Policy / Plans to Rent Out Property ( CA Specific Workaround , Upcoming Renewal ) ',
    'CX::Homeowners::Coverage::Do You Cover Accidental Damage / Equipment Breakdown? ( CHECK STATE )[ EBC , Warranty ]',
    'CX::Homeowners::Coverage::Do You Cover Solar Panels? ( HO3 )',
    'CX::Homeowners::Coverage::Dwelling 100% of RCE [coverage, Dwelling,100%, RCE, (HO6 ALL , HO3 AR/ AZ/ CA/ CO/ CT/ DC/ GA/ IA/ IL/ IN/ MA/ MD/ MI/ NJ/ NY/ OH/ OK/ OR/ PA/ RI/ TN/ TX/ VA/ WA/ and WI)',
    'CX::Homeowners::Coverage::Flood Insurance [ FEMA ]',
    'CX::Homeowners::Coverage::Is Coverage A Replacement Cost or Actual Cash Value?',
    'CX::Homeowners::Coverage::What Does This Cover? (Pre-Purchase)',
    'CX::Homeowners::Earthquake::New Palomar Purchase ( No Lemonade Policy )',
    'CX::Homeowners::Earthquake::Payment Received Send Palomar Policy ',
    'CX::Homeowners::Lender Requests::Asking for EOI ( Need More Info )',
    'CX::Homeowners::Lender Requests::Asking for EOI for Policy That Has Not Been Paid For',
    'CX::Homeowners::Lender Requests::Asking for EOI For Quote in Review',
    'CX::Homeowners::Lender Requests::Loan Officer Confirmation ( No Action Required )',
    'CX::Homeowners::Mortgage Servicing::Need Loan Number [ Missing Loan Number ]',
    'CX::Homeowners::Onboarding::California DIC (I don’t want a DIC Policy) [ PUSHBACK ]',
    'CX::Homeowners::Onboarding::Can I Have a Quote?',
    'CX::Homeowners::Onboarding::How Do I Sign Up? ( Link Back to Quote )',
    'CX::Homeowners::Onboarding::Why is My Dwelling Coverage So High?',
    'CX::Homeowners::Onboarding::Why is My Dwelling Coverage So Low?',
    'CX::Homeowners::Policy Changes and Info::Adjusting Coverages on Renewal ( Not Active Yet - Simplified Version )',
    'CX::Homeowners::Policy Changes and Info::Asking Customer Approval to add Lender Info',
    'CX::Homeowners::Policy Changes and Info::Asking Customer Approval to Make Policy Changes Requested by Lender',
    'CX::Homeowners::Policy Changes and Info::Can I Get a Discount for My New Roof?',
    'CX::Homeowners::Policy Changes and Info::Can I Remove a Required Coverage?',
    'CX::Homeowners::Policy Changes and Info::Can You Update my Mortgagee / Loan Number?',
    'CX::Homeowners::Policy Changes and Info::Customer Needs To Add/Change Mortgage Company',
    'CX::Homeowners::Policy Changes and Info::Evidence of Insurance [ HOI / EOI / closing ]',
    'CX::Homeowners::Policy Changes and Info::I Need to Update my Effective Date ( Closing Follow-up )',
    'CX::Homeowners::Policy Changes and Info::Increase/Add Coverages on Escrow Policy ( Step 1 )',
    'CX::Homeowners::Policy Changes and Info::Pet Bundle Discount',
    'CX::Homeowners::Policy Changes and Info::Update my Effective Date ( Closing )',
    'CX::Homeowners::Renewals::California Premium Increase',
    'CX::Homeowners::Renewals::Premium Increase Due to Increase in Replacement Cost (Cov A)',
    'CX::Homeowners::Renewals::Premium Increase Due to Rate Change',
    'CX::Homeowners::Renewals::Premium Increase Due to Rate Change ( Pushback )',
    'CX::Homeowners::Underwriting::Decline - Roof Age',
    'CX::Homeowners::Underwriting::Non-Renewal - Agency Termination',
    'CX::HOOps::MCI::Create New ZD Ticket',
    'CX::HOOps::Accounts Receivable: Customer Chose Another Provider [dunning, escrow, cancel]',
    'CX::HOOps::Accounts Receivable::Ask customer for help w/ Unverified Lender [unverified lender, escrow, dunning ]',
    'CX::HOOps::Accounts Receivable::Customer is not paying via escrow [ escrow , dunning, non escrow balance due , add card ]',
    'CX::HOOps::Accounts Receivable::General Follow up (to loan officer) [escrow, mortgage, payment due, loan officer, cancellation risk]',
    'CX::HOOps::Accounts Receivable::Lender Underpayment (No Payments received)',
    'CX::HOOps::Accounts Receivable::Payment Received No Payment Associated',
    'CX::HOOps::Accounts Receivable::Payment Received Policy Reinstated',
    'CX::HOOps::Accounts Receivable::Payment Received Policy Reinstated Refund Owed',
    'CX::HOOps::Accounts Receivable::Payment Received Resulted in Underpayment',
    'CX::HOOps::Accounts Receivable::Payment Received Scheduled Payment Canceled',
    'CX::HOOps::Accounts Receivable::Premium Refund Check Returned (Customer- STEP 1) [ escrow , balance due , outbound]',
    'CX::HOOps::Accounts Receivable::Premium Refund Check Returned (Customer- STEP 2) [ escrow , balance due , outbound]',
    'CX::HOOps::Accounts Receivable::Underpayment (loan officer) [underpayment, loan officer]',
    'CX::HOOps::Accounts Receivable::Charge Failure [ payment failed ,  outstanding balance , policy change ]',
    'CX::HOOps::Accounts Receivable::Underpayment - 1st Follow Up (Customer) [ escrow , balance due ]',
    'CX::HOOps::Mortgage Servicing: Need Lender Info [ unverified lender ]',
    'CX::HOOps::Mortgage Servicing: Need Lender Info Renewal ( multiple mortgage companies listed on policy )',
    'CX::HOOps::Mortgage Servicing: Need Lender Info Renewal [unverified lender, renewal]',
    'CX::HOOps::Mortgage Servicing::Request to Remove Lender [authorization, update lender info ]',
    'CX::HOOps::Mortgage Servicing::Cancellation Notice [cancellation notice, homeowners, lender]',
    'CX::Internal Notes::Resolved on SMS',
    'CX::Life::Account Management::Collecting Outstanding Balance After Reinstatement Approval ( Outbound )',
    'CX::Life::Account Management::Reinstatement ',
    'CX::Life::Account Management::Cancellation (1st response AFTER free look, NOT dunning)',
    'CX::Life::Account Management::Undeliverable Mail / Non-Payment Grace Notice [OUTBOUND] Step 1',
    'CX::Life::Coverage::Can I Purchase Life Insurance for a Family Member?',
    'CX::Life::General::LGA::Forward Customer to LGA ( NEW BUSINESS Email Follow-up )',
    'CX::Life::LGA::Account Management::Reinstatement',
    'CX::Life::Onboarding::How to Get a Quote?',
    'CX::Life::Underwriting::Response to Ineligible Duplicate SSN (Bestow)',
    'CX::Life::Underwriting::Response to Ineligible Duplicate SSN (Lemonade - Declined, Pushback)',
    'CX::Life::Underwriting::Response to Ineligible Duplicate SSN (Lemonade - Declined)',
    'CX::Life::Underwriting::Response to Ineligible Dv_identity',
    'CX::Pet::Account Management::Add a Co-owner ( Partner , Spouse , Family Member )',
    'CX::Pet::Account Management::Address Change from Model Law to Non ( Check State ) ( Decrease ) [ Cian ]',
    'CX::Pet::Account Management::Address Change from Model Law to Non ( Check State ) ( Increase ) [ Cian ]',
    'CX::Pet::Account Management::Address Change from Non to Model Law ( Check State ) ( Decrease ) [ Cian ]',
    'CX::Pet::Account Management::Address Change from Non to Model Law ( Check State ) ( Increase ) [ Cian ]',
    'CX::Pet::Account Management::Can I Cancel Anytime? ( Cancelation Policy )',
    "CX::Pet::Account Management::Can I Transfer My Pet's Policy to Someone Else? ( Confirmation ) ( Step 4 )",
    "CX::Pet::Account Management::Can I Transfer My Pet's Policy to Someone Else? ( Step 1 ) [ Partner , Spouse , Family Member ]",
    "CX::Pet::Account Management::Can I Transfer My Pet's Policy to Someone Else? ( Step 2 ) ( UW Approved )",
    "CX::Pet::Account Management::Can I Transfer My Pet's Policy to Someone Else? ( Step 3 ) ( New Parent , TOS Agreement )",
    "CX::Pet::Account Management::Can I Transfer My Pet's Policy to Someone Else? (Step 1) [ No , Switch , Ownership ]",
    'CX::Pet::Account Management::Cancel Duplicate Policy ( Step 1 )',
    'CX::Pet::Account Management::Cancel Duplicate Policy ( Step 2 )',
    'CX::Pet::Account Management::Cancel Policy (Step 1)',
    'CX::Pet::Account Management::Cancel Policy (Step 2)',
    'CX::Pet::Account Management::Canceled Policy Confirmation',
    "CX::Pet::Account Management::Change Pet's Breed ( Step 1 )",
    "CX::Pet::Account Management::Change Pet's Last Name [ Co-Owner , Co-Parent ]",
    "CX::Pet::Account Management::Change Pet's Name or Sex ( Gender )",
    'CX::Pet::Account Management::Co-owner Requesting Log-in ( Partner , Spouse , Family Member )',
    'CX::Pet::Account Management::No Active Policy Associated With Email Address',
    'CX::Pet::Account Management::Pet Passed Away ( Has End of Life Package  ) [ EoL , Death , Deceased ] ',
    'CX::Pet::Account Management::Pet Passed Away [ Death , Deceased ]',
    'CX::Pet::Account Management::Update Address ( Address Mismatch Due to Pet Address Update )',
    'CX::Pet::Account Management::Update Address ( STEP 2 ( Premium Decrease )) (Renewal HAS NOT Been Generated)  [ Pet Moved , Change Address , Moving ] ',
    'CX::Pet::Account Management::Update Address ( STEP 2 ( Premium Increase )) ( Renewal Has NOT Been Generated ) [ Pet Moved , Change Address , Moving ]',
    'CX::Pet::Account Management::Update Address ( STEP 2 ( Same Premium )) [ Pet Moved , Change Address , Moving ] ',
    'CX::Pet::Account Management::Update Address ( Unsupported State , State Not Covered )',
    'CX::Pet::Account Management::Update Address [ Pet Moved / Change Address / Moving ] ( STEP 1 )',
    'CX::Pet::Account Management::Update Address [ Pet Moved / Change Address / Moving ] ( STEP 2 ( Premium Increase )) ( Renewal HAS Been Generated )',
    'CX::Pet::Account Management::Update Address Via Customer Care Request',
    'CX::Pet::Billing::Payment Plan Options [ Monthly , Annual ]',
    'CX::Pet::Billing::Switch to Annual Payment at Renewal [ Renewal , Annual ]',
    "CX::Pet::Billing::What's an Installment Fee?",
    "CX::Pet::Billing::What's an Installment Fee? [IL , VA , SC , TX, $2 ]",
    'CX::Pet::Business Basics::Do You Have Any Brochures for Vet Offices? [ Partnership Program ]',
    'CX::Pet::Chewy::Can I Upgrade My Policy? ( Yes )',
    'CX::Pet::Chewy::Electronic Consent Withdrawal Confirmation ( FL, CA, and IL Only )',
    'CX::Pet::Chewy::What Does My Policy Cover?',
    'CX::Pet::Claims::Can I Claim a Wellness Package From My Vet? ( PH Has a Preventative Package ) [ Puppy , Kitten , Preventative ]',
    'CX::Pet::Claims::Claims Overview ( Claims Process )',
    'CX::Pet::Claims::How Do Claim Payments Work? ( Any Vet )',
    'CX::Pet::Claims::How Do I File a Claim for Medication? ( Custom Entry )',
    'CX::Pet::Claims::How Do I File a Preventative Care Claim?',
    'CX::Pet::Claims::How Do I File an Accident or Illness Claim? ( Event Already Happened )',
    'CX::Pet::Claims::What if I Cannot Pay the Vet First? ( Reimbursement Basis ) [ Scratch Pay , Care Credit ]',
    'CX::Pet::Coverage::Are Hereditary and Congenital Conditions Covered?',
    'CX::Pet::Coverage::Can I Add a Preventative Package? ( Yes )',
    'CX::Pet::Coverage::Can I Downgrade My Policy? ( YES )',
    'CX::Pet::Coverage::Can I Upgrade My Policy? ( NO ) ( After First 14 Days )',
    'CX::Pet::Coverage::Can I Upgrade My Policy? ( NO ) ( PH Pushback Response )',
    'CX::Pet::Coverage::Can I Upgrade My Policy? ( YES ) ( During First 14 Days and No Claims Filed )',
    'CX::Pet::Coverage::Can I Upgrade My Preventative Package? ( YES ) ( Within 14 Days )',
    'CX::Pet::Coverage::Do You Cover Allergy Tests?',
    'CX::Pet::Coverage::Do You Cover Behavioral Conditions? ( NO ) [ Training , Behavior Correction , Medication ]',
    'CX::Pet::Coverage::Do You Cover Behavioral Conditions? ( YES ) [ Training , Behavior Correction , Medication ]',
    'CX::Pet::Coverage::Do You Cover Costs Related to Breeding? [ Pregnancy , Ultrasound , Complications ]',
    'CX::Pet::Coverage::Do You Cover Curable Conditions? ( YES )',
    'CX::Pet::Coverage::Do You Cover Dental Cleanings or Dental Illnesses? ( NO ) [ Post-purchase , FL ]',
    'CX::Pet::Coverage::Do You Cover Dental Cleanings? ( NO ) ( Pets Under 2 )',
    'CX::Pet::Coverage::Do You Cover Dental Cleanings? ( NO ) ( Post-purchase ) ( Did Not Add Preventative+ )',
    'CX::Pet::Coverage::Do You Cover Dental Cleanings? ( YES ) ( Post-purchase ) ( Has Preventative+ But Not Dental Illness )',
    'CX::Pet::Coverage::Do You Cover Dental Cleanings? ( YES ) ( Pre-purchase ) ( Pets Over 2 )',
    'CX::Pet::Coverage::Do You Cover Dental Illnesses? ( YES ) ( Add-on , Pre-purchase , Renewal )',
    'CX::Pet::Coverage::Do You Cover End of Life Costs? ( NO ) [ Euthanasia , Cremation , Burial , Necropsy ]',
    'CX::Pet::Coverage::Do You Cover Grooming?',
    'CX::Pet::Coverage::Do You Cover Pre-existing Conditions?',
    'CX::Pet::Coverage::Do You Cover Prescription Food?',
    'CX::Pet::Coverage::Do You Cover Prescription Medication? ( NO ) ( Post-purchase ) [ Flea , Tick , Heartworm ]',
    'CX::Pet::Coverage::Do You Cover Prescription Medication? ( YES ) ( Pre-purchase ) [ Flea , Tick , Heartworm ]',
    'CX::Pet::Coverage::Do You Cover Preventative Care? ( Pre-purchase )',
    'CX::Pet::Coverage::Do You Cover Spay / Neuter or Microchipping? ( NO ) ( Post-purchase ) ( Did Not Choose Puppy or Kitten Package )',
    'CX::Pet::Coverage::Do You Cover Spay / Neuter or Microchipping? ( YES ) ( Post-purchase ) ( Has Puppy or Kitten Package )',
    'CX::Pet::Coverage::Do You Cover Spay / Neuter or Microchipping? [ NO ] [ Post-purchase ] [ Was Not Eligible For Puppy or Kitten Package ]',
    'CX::Pet::Coverage::Does the Base Policy Provide Preventative Coverage? ( Pre-purchase )',
    'CX::Pet::Coverage::How Does the Annual Limit Work?',
    'CX::Pet::Coverage::How Does the Co-insurance Work?',
    'CX::Pet::Coverage::How Does the Deductible Work?',
    'CX::Pet::Coverage::Is This Considered Pre-Existing?',
    'CX::Pet::Coverage::Policy Change Confirmation ( Step 2 ) [ I Have Added This to Your Policy ]',
    'CX::Pet::Coverage::Quoting Multiple Changes ( Deductible , Co-insurance , Annual Limit)',
    'CX::Pet::Coverage::Recurring Conditions ( Cian Form ) [ Foreign Body Ingestion , Pet Swallowed Something ]',
    'CX::Pet::Coverage::Recurring Conditions ( Original Form ) [ Foreign Body Ingestion , Pet Swallowed Something ]',
    'CX::Pet::Coverage::What Does the Physical Therapy Package Cover?',
    'CX::Pet::Coverage::What Does the Visit Fee Package Cover? [ vet visit ]',
    'CX::Pet::Coverage::What Happens to My Puppy or Kitten Package Once My Pet Turns 2?',
    "CX::Pet::Coverage::What's Covered? ( Pre-purchase ) [ Overview , Plans ]",
    'CX::Pet::Coverage::What’s Not Covered? ( Exclusions )',
    'CX::Pet::Coverage::Which Vaccines Am I Eligible For? ( Has Preventative Package )',
    'CX::Pet::Coverage::Will This Cover My Pet While Traveling?',
    'CX::Pet::Coverage::Will You Cover an Annual Wellness Exam? ( NO ) ( Does Not Have Preventative Package )',
    'CX::Pet::Coverage::Will You Ever Drop My Pet When They Get Old? [ Aging Pet , Age ]',
    'CX::Pet::Coverage::Will You Reimburse Me for an Annual Wellness Exam Right After I Buy the Policy? ( Pre-purchase )',
    'CX::Pet::Onboarding::Bundle Discount [ Multi-Policy / Has Quote ]',
    'CX::Pet::Onboarding::Can I Purchase Just a Preventative Package?',
    'CX::Pet::Onboarding::Do You Offer a Multi-Pet Discount?',
    'CX::Pet::Onboarding::Edit Quote (Can you edit my quote?)',
    'CX::Pet::Onboarding::Fax Pet Medical Records',
    'CX::Pet::Onboarding::Help Getting a Quote ( Pre-purchase ) ( Quote Confusion )',
    'CX::Pet::Onboarding::How Does Lemonade Define Accidents and Illnesses?',
    'CX::Pet::Onboarding::How Is Preventative Care Paid For?',
    'CX::Pet::Onboarding::I Need a Quote for Multiple Pets ( Pre-purchase )',
    'CX::Pet::Onboarding::I Need to Cover Another Pet ( Post-purchase ) ( Multi-Pet )',
    'CX::Pet::Onboarding::Insurance Card [ Reimbursement Basis , Any Vet ]',
    'CX::Pet::Onboarding::Is My Policy Active? ( Post-Purchase ) [ Waiting Periods / Medical Records ]',
    'CX::Pet::Onboarding::Medical Record Upload Link ( Phone Follow-up )',
    'CX::Pet::Onboarding::Medical Records Bug ( June 2023 )',
    'CX::Pet::Onboarding::My Pet Doesn’t Have a Full 12 Months of Medical History',
    'CX::Pet::Onboarding::PH Sent Invoice or Vaccines ( No Soap Notes ) [ Medical Records ]',
    'CX::Pet::Onboarding::Request Medical Records on Behalf of a PH  ( Outbound Vet Request ) [ Email , Fax , Ring Central ]',
    'CX::Pet::Onboarding::Review Medical Records and Tell Me What is Pre-existing ( Pre-purchase )',
    "CX::Pet::Onboarding::Vet Can't Locate Medical Records under PH Name ( Has co-owner , co-parent )",
    "CX::Pet::Onboarding::Vet Doesn't Have Medical Records ( Notify the PH )",
    "CX::Pet::Onboarding::Vet Doesn't Have Medical Records ( Response to Vet )",
    'CX::Pet::Onboarding::Waiting Periods Explanation',
    'CX::Pet::Onboarding::Waiting Periods Explanation [ Cian Only ]',
    'CX::Pet::Onboarding::We Got Your Medical Records ( PH Sent Correct Records With SOAP Notes )',
    'CX::Pet::Onboarding::What Are the Coverage Options and Limits? ( Pre-purchase )',
    'CX::Pet::Onboarding::When Will My Policy Activate? ( Pre-purchase )',
    'CX::Pet::Onboarding::Why Do You Need My Pet’s Medical Records? ( I Do Not Have Medical Records )',
    'CX::Pet::Onboarding::Will You Cover Expenses From Before My Policy Starting? [ Cian Only ]',
    'CX::Pet::Onboarding::Your Policies Are Too Expensive',
    'CX::Pet::Pre-Approvals::Do You Provide Pre-approvals? ( Step 1 )',
    'CX::Pet::Pre-Approvals::Pre-approval Request ( Weekend Response )',
    'CX::Pet::Renewals::Can I Change My Coverage?',
    'CX::Pet::Renewals::Can I Change My Coverage? ( Options List )',
    'CX::Pet::Renewals::Can I Downgrade My Policy? ( NO ) ( After 14 Days )',
    'CX::Pet::Renewals::Can I Downgrade My Policy? ( YES )',
    'CX::Pet::Renewals::Can I Upgrade My Renewal Policy? ( NO - VF , PT , Coverage Limits ) ( YES - Preventative Care )',
    'CX::Pet::Renewals::Can I Upgrade My Renewal Policy? ( NO )',
    'CX::Pet::Renewals::Can I Upgrade My Renewal Policy? ( YES )',
    'CX::Pet::Renewals::Premium / Rate Increase ( Coverage Limit , $100k , $100 , 90% )',
    'CX::Pet::Renewals::Premium / Rate Increase [ State-wide / Re-filed ]',
    'CX::Pet::Renewals::Premium Increase Follow-up ( Pushback )',
    'CX::Pet::Renewals::Why Did My Waiting Periods Restart? ( Renewal ) ( Did Not Restart )',
    'CX::Pet::Tag::No Medical Records Attached No Reply Needed [ NRN ]',
    'CX::Pet::Tag::Outbound Customer Care Request ( Help Filing Claim )',
    'CX::Pet::Underwriting::Why Can’t You Insure My Pet? ( Too Old )',
    'CX::Pet::Underwriting::Why Can’t You Insure My Pet? ( Too Young )',
    'CX::Property::Underwriting::Quote Decline During Temporary Area Block -  [ postal code , weather , catastrophe ]',
    'CX::Property::Billing::Can I Have a Policy That Lasts More Than 12 Months? [ Annual , 13 Months , Month to Month ]',
    'CX::Property::Billing::Why Was I Double Charged? ( Renewal ) ( Cancelation is Scheduled )',
    'CX::Property::Billing::Will I be Charged Again When I Move? ( Not Escrow Paid )',
    'CX::Property::Business Basics::Does My Burglar Alarm Count? ( Not FL )',
    'CX::Property::Business Basics::How Can I Edit my Policy in the App? [ live policy ]',
    'CX::Property::Business Basics::Is This Hazard Insurance?',
    'CX::Property::Claims::Can I File a Claim?',
    'CX::Property::Claims::Do I Have to Make a List of All My Things?',
    'CX::Property::Coverage::Am I Covered Away From Home? [ Traveling ]',
    'CX::Property::Coverage::Base Policy Water Damage',
    'CX::Property::Coverage::Can I Insure A Storage Unit Alone? ( No Base Policy )',
    'CX::Property::Coverage::Can I Insure my Office / Art Studio / Photo Studio / Commercial Space?',
    'CX::Property::Coverage::Collectibles [ Rare , Signed , Autographed ]',
    'CX::Property::Coverage::Do You Offer Umbrella Coverage?',
    'CX::Property::Coverage::Dog Bite Coverage ( IL , NJ , PA , MI , VA , CT ) [ liability , pet ]',
    'CX::Property::Coverage::Dog Bite Coverage ( NY / CA / TX / RI / NV / OH / DC / MD / IA / WI / AZ / NM / OR / AR / CO / TN / IN / OK / MA / MO / WA / KY / MT / ND ) [ liability , pet ]',
    'CX::Property::Coverage::E-bike Pushback',
    'CX::Property::Coverage::E-bikes',
    'CX::Property::Coverage::Event Insurance ( Wedding )',
    'CX::Property::Coverage::Exercise Bikes [ Peloton ]',
    'CX::Property::Coverage::Firearms ( Check State ) [ Gun ]',
    'CX::Property::Coverage::How Is my Family covered?',
    'CX::Property::Coverage::How Is My Significant Other Covered?',
    'CX::Property::Coverage::Is this covered? Hypothetical or Already Happened? ( Step 1 )',
    'CX::Property::Coverage::Luxury Items [ Shoes , Purses , Designer , Fur ]',
    'CX::Property::Coverage::Mobile, Manufactured, or Tiny Homes [ Trailer / Camper / RV/ Tiny Homes/ Manufactured  ]',
    'CX::Property::Coverage::Mold ( All Other States )',
    'CX::Property::Coverage::Pet Damage To Home',
    'CX::Property::Coverage::Replacement Cost vs. Actual Cash Value',
    'CX::Property::Coverage::Spoiled Food ( Not CA )',
    'CX::Property::Coverage::Storage Units ( All Other States )',
    'CX::Property::Coverage::Theft Away From Home',
    'CX::Property::Coverage::Theft From My Car',
    'CX::Property::Coverage::Unable To Change Liability/Deductible Active Policy [ 300k , 300,000 , Capped , Active ]',
    'CX::Property::Coverage::Utility Interruption ( Not CA ) [ LOU , loss of use , power outage , heat , air conditioning , AC , A/C , gas ]',
    'CX::Property::Coverage::What is Loss of Use?',
    'CX::Property::Coverage::What is Personal Liability?',
    'CX::Property::Coverage::What is Water Backup?',
    "CX::Property::Extra Coverage::Cameras::What's Covered?",
    'CX::Property::Extra Coverage::Can I Just Have Extra Coverage? [ Without a Policy ]',
    'CX::Property::Extra Coverage::How To Schedule Items [ Post Purchase ]',
    "CX::Property::Extra Coverage::I Don't Have a Receipt",
    'CX::Property::Extra Coverage::What is Temporary Coverage? [ Not Escrow Paid Policy ]',
    "CX::Property::Extra Coverage::What's Covered?",
    "CX::Property::Extra Coverage::Musical Instruments::What's Covered?",
    'CX::Property::Giveback::Can’t Get Past Giveback Screen [ Stuck , App Trouble , Set-up Issue , Account Activation ]',
    "CX::Property::Onboarding::Google Can't Find Address",
    'CX::Property::Onboarding::Stubbed Quote ( BCEGS is 0 ) [ Stubbed , BCEGS ]',
    'CX::Property::Onboarding::Stubbed Quote ( Verisk Down )',
    'CX::Property::Onboarding::Typo In Quote Address',
    'CX::Property::Policy Changes and Info::Can You Change my Address Before my Policy Goes Into Effect? ( Future Policy )',
    'CX::Property::Policy Changes and Info::How Do I Cancel my Policy?',
    'CX::Property::Policy Changes and Info::How Do I Edit My Policy On The Web?',
    'CX::Property::Policy Changes and Info::Switching Sig. Other to Spouse',
    'CX::Property::Policy Changes and Info::Typo in Active Policy Address',
    'CX::Property::Underwriting::Coastal Zip Code Decline',
    'CX::Property::Underwriting::Decline - Blocked Postal Code',
    'CX::Renters::Account Management::Cancel My Policy Immediately',
    "CX::Renters::Account Management::Crash Services-False Positive (I Wasn't in an Accident) [ Telematics Trial ]",
    "CX::Renters::Account Management::I Don't Have a Car Why Did I Get This? [ Telematics Trial ]",
    'CX::Renters::Account Management::I Need a Tow Can I Use My Free Emergency Services [ Telematics Trial ]',
    "CX::Renters::Account Management::I'm Getting Stuck in The Settings [ Telematics Trial ]",
    'CX::Renters::Account Management::This is Draining My Phone Battery Can I Turn it Off? [ Telematics Trial ]',
    'CX::Renters::Account Management::What are Crash Services? Is This Roadside? [ Telematics Trial ]',
    'CX::Renters::Account Management::Why Do I Need to Allow Motion and Fitness? (CARBON CLEANUP) [ Telematics Trial ]',
    'CX::Renters::Account Management::Why Should I Allow These Settings? (CARBON CLEANUP) [ Telematics Trial ]',
    'CX::Renters::Account Management::Why Should I Allow These Settings? (CRASH SERVICES) [ Telematics Trial ]',
    'CX::Renters::Backdate::Backdate My Policy',
    'CX::Renters::Billing::Can You Switch me to a Monthly Payment Plan?',
    'CX::Renters::Billing::Payment Options ( Not Florida )',
    'CX::Renters::Billing::Wants to Pay Monthly, Only Offered Annual ',
    'CX::Renters::Billing::What is an Installment Fee? ( Check State )',
    'CX::Renters::Billing::Where is my refund? ( Just Canceled )',
    'CX::Renters::Business Basics::Can I Add my Roommates?',
    'CX::Renters::Business Basics::Can You Start my Policy on a Date in the Past?',
    'CX::Renters::Business Basics::Do You Offer $1Million in Liability? ( Check Blender )',
    "CX::Renters::Business Basics::Parents Set Up Policy For Child Using Parent's DOB, Name, Etc.",
    'CX::Renters::Business Basics::Parents Setting Up Policy For Child ( Pre-Purchase )',
    'CX::Renters::Business Basics::Property Manager Requesting Partnership Info [ Flyers , Information , Link ]',
    'CX::Renters::Business Basics::Spanish Speaker',
    'CX::Renters::Business Basics::What Is a Deductible? ( All Other States )',
    'CX::Renters::Business Basics::Your Prices Are Higher [ too expensive ]',
    'CX::Renters::Claims::How Do I File a Loss Of Use Claim? ( Event Typically Covered )',
    'CX::Renters::Coverage::Can I Remove a Required Coverage?',
    'CX::Renters::Coverage::Difference Between Water Back Up and Tenant Water Damage Endorsements',
    "CX::Renters::Coverage::Do You Cover Water Damage to my Landlord's Property? ( Check Blender ) ( Available , Not Yet Added )",
    'CX::Renters::Coverage::Flood Insurance ( FEMA )',
    'CX::Renters::Coverage::Personal Liability Requirements  ( Post Purchase ) [ 100k ]',
    'CX::Renters::Coverage::Pests ( Bed Bugs , Vermin , Termites , Tenting , Rats , Mouse , Infested )',
    'CX::Renters::Coverage::Short Term Renter',
    'CX::Renters::Coverage::What Does This Cover?',
    'CX::Renters::Onboarding::How Do I Add Others To My Policy?',
    'CX::Renters::Onboarding::Personal Liability Requirements',
    'CX::Renters::Onboarding::Why is my Quote More Than $5',
    'CX::Renters::Policy Changes and Info::Adding Significant Other ( NY, CA, IL, WA, NJ, TX, RI, NV, OH, GA, PA, DC, MD, IA, WI, AZ, NM, MI, OR, AR )',
    'CX::Renters::Policy Changes and Info::Asking Permission to Add Interested Party',
    'CX::Renters::Policy Changes and Info::Can I have a Copy of my Policy?',
    'CX::Renters::Policy Changes and Info::Can I Start my Policy Sooner than 12:01am? ( Step 1 for High Risk Users)',
    'CX::Renters::Policy Changes and Info::Can I Start my Policy Sooner than 12:01am? ( Step 1 for NON- HRU )',
    'CX::Renters::Policy Changes and Info::Can I Start my Policy Sooner than 12:01am? ( Step 2 - Approved )',
    'CX::Renters::Policy Changes and Info::Difference Between Interested Party and Additional Insured ( Post-Purchase )',
    'CX::Renters::Policy Changes and Info::How Do I Add an Interested Party?',
    "CX::Renters::Policy Changes and Info::I've Added Your Interested Party ( Confirmation )",
    'CX::Renters::Policy Changes and Info::Landlord Asking to be Added as Interested Party',
    'CX::Renters::Policy Changes and Info::Moving ( Unit Numbers )',
    'CX::Renters::Policy Changes and Info::Requesting PH Permission to Cancel Policy',
    'CX::Renters::Policy Changes and Info::Why Does My Policy Start in 7 Days? ( Approved )',
    'CX::Renters::Policy Changes and Info::Why Does My Policy Start in 7 Days? ( First Step )',
    "CX::Renters::Renewals::I've Requested Your Renewal Policy",
    'CX::Renters::Renewals::Inflation Guard [ Coverages and Premium Increase at Renewal ]',
    'CX::Renters::Underwriting::Why is the Building Rating so Low?',
    'CX::Underwriting::Homeowners::Decline - Lapse In Coverage',
    'CX::Underwriting::Property::Decline ( Fire Department , High PPC Value )',
    'CX:Renters::Account Management::Why Do I Need to Allow Motion and Fitness? (CRASH SERVICES) [ Telematics Trial ]',
    'Engineering Support::* HoOps/clxEU: Create New ZD Ticket',
    'Engineering Support::** ES Response: Invalid Transfer (triage)',
    'Engineering Support::** ES Response: Mark Pending (triage)',
    'Engineering Support::** ES Response: Mark Pending Dev',
    'Engineering Support::** ES Response: Mark Solved (triage)',
    'Engineering Support::** Reply to Engineering Support (triage)',
    'Engineering Support::Accident AF to NAF',
    'Engineering Support::Add Check Refund',
    'Engineering Support::Add Monthly Check Payment',
    'Engineering Support::Backdate Cancellation',
    'Engineering Support::Cancel Check',
    'Engineering Support::CC Refund',
    'Engineering Support::Change Address - Home Only',
    'Engineering Support::Change Address- Pet, Life, Car ',
    'Engineering Support::Change Check Amount',
    'Engineering Support::Change Coverage With Form',
    'Engineering Support::Change Driver Status',
    'Engineering Support::Change Legal Name of Additional Insured',
    'Engineering Support::Change Marital Status',
    'Engineering Support::Change Payment Plan',
    'Engineering Support::Charge Full Year',
    'Engineering Support::Coverages',
    'Engineering Support::Edit Sec Driver Name',
    'Engineering Support::Get Rates',
    'Engineering Support::Inside Sales',
    'Engineering Support::Missing Discount',
    'Engineering Support::Other',
    'Engineering Support::PPM Grace per',
    'Engineering Support::Rating',
    'Engineering Support::Refund PPM Penalty miles',
    'Engineering Support::Reinstate Policy',
    'Engineering Support::Reset dunning period',
    'Engineering Support::Stripe Check Error',
    'Engineering Support::Suppress UW decline endorsement',
    'Engineering Support::telematics',
    'Engineering Support::Update CA License',
    'Engineering Support::Update Driver License and Rerun MVR',
    'Engineering Support::Update Effective Date',
    'Engineering Support::Update Lienholder Information',
    "Engineering Support::Update Pet's Breed or Age ( Pet Only )",
    'Engineering Support::Update Policy',
    'Engineering Support::Update User Details',
    'Engineering Support::Wind Mitigation Credits',
    'Engineering Support::Wind Mitigation Credits Approval',
    'Engineering Support::Wind Mitigation Credits Rejection',
    "Extra Coverage Approval::e-Bike What's Already Covered [ebike, motor, pedal assist]",
    'Extra Coverage::Fine Art::Whats Covered?',
    "Extra Coverage::Guns::Why Can't I Schedule Guns?",
    'Extra Coverage::Jewelry::How Do You Replace Jewelry?',
    'Extra Coverage::Bikes::Clarify Professional Use',
    'Extra Coverage::Bikes::e-Bike Capabilities',
    'Extra Coverage::Bikes::Inventory Sheet',
    'Extra Coverage::Bikes::Timestamp',
    'Extra Coverage::Cameras::Is This for Personal or Professional Use?',
    'Extra Coverage::Cameras::Lens with no Camera Body',
    'Extra Coverage::Cameras::Timestamp',
    'Extra Coverage::Fine Art::Timestamp',
    'Extra Coverage::General::All Items Approved',
    'Extra Coverage::General::Multiple Policies Check',
    'Extra Coverage::General::Multiple Policies Follow-Up',
    'Extra Coverage::General::No Card on File',
    'Extra Coverage::General::PH Needs More Time',
    'Extra Coverage::General::Potential Professional Use',
    'Extra Coverage::General::Suggested Value Different Than Receipt',
    'Extra Coverage::General::Update Payment Info [dunning]',
    'Extra Coverage::Jewelry::Appraisal/Receipt Differing Values',
    'Extra Coverage::Jewelry::Engagement or Non-Engagement Jewelry?',
    'Extra Coverage::Jewelry::PH Has Not Proposed Yet (engagement ring)',
    'Extra Coverage::Jewelry::Spouse/SO Does NOT Live With Insured',
    'Extra Coverage::Jewelry::Timestamp',
    'Extra Coverage::Micro::Holiday',
    'Extra Coverage::Micro::Ineligible Art Material',
    'Extra Coverage::Micro::Link/Screenshot',
    'Extra Coverage::Micro::Medical Device',
    'Extra Coverage::Micro::Standard Process',
    "Extra Coverage::Musical Instruments::Modern Music Equipment - What's Covered?",
    'Extra Coverage::Musical Instruments::Accessories with no Parent Instrument',
    'Extra Coverage::Musical Instruments::Is This for Personal or Professional Use?',
    'Extra Coverage::Musical Instruments::Timestamp',
    'Extra Coverage::Need More Info::Additional Pages',
    'Extra Coverage::Need More Info::Amazon Receipt',
    'Extra Coverage::Need More Info::Clarify Professional Use',
    'Extra Coverage::Need More Info::Confirm No Losses',
    'Extra Coverage::Need More Info::Cropped Document',
    'Extra Coverage::Need More Info::Discount',
    'Extra Coverage::Need More Info::Docs Sent via Email',
    'Extra Coverage::Need More Info::General',
    'Extra Coverage::Need More Info::Name on Docs Does Not Match Insured',
    'Extra Coverage::Need More Info::Online Appraisal',
    'Extra Coverage::Need More Info::Proof of Payment',
    'Extra Coverage::Need More Info::Request Receipt',
    'Extra Coverage::Need More Info::Time Gap Between Policy Date & Date on Receipt',
    'Extra Coverage::Need More Info::Verify Retailer/Appraiser',
    'Extra Coverage::Pushback::Claims History Denial Pushback',
    'Extra Coverage::Pushback::Collectibles / Memorabilia Pushback',
    'Extra Coverage::Pushback::Dunning Denial Pushback',
    'Extra Coverage::Pushback::Engagement or Non-Engagement Pushback',
    'Extra Coverage::Pushback::Exceeds UW Limits Pushback',
    'Extra Coverage::Pushback::Ineligible Appraisal Pushback',
    'Extra Coverage::Pushback::Temp Block (Request Sent after the Block)',
    'Extra Coverage::Pushback::Timestamp / Metadata Confusion',
    'Extra Coverage::Rejection::Art Created by Insured',
    'Extra Coverage::Rejection::Can’t Verify Retailer/Appraiser',
    'Extra Coverage::Rejection::Charm Bracelets',
    'Extra Coverage::Rejection::Claims History Denial',
    'Extra Coverage::Rejection::Collectibles / Memorabilia',
    'Extra Coverage::Rejection::Computer not a Camera',
    'Extra Coverage::Rejection::Docs Older Than 5 Years',
    'Extra Coverage::Rejection::Drones (everywhere else)',
    'Extra Coverage::Rejection::Drones (NY/CA/VA only!)',
    'Extra Coverage::Rejection::Dunning Denial',
    'Extra Coverage::Rejection::e-Bike Denial',
    'Extra Coverage::Rejection::eBay Receipt',
    'Extra Coverage::Rejection::Exercise Bike (peloton)',
    'Extra Coverage::Rejection::Foreign Docs',
    'Extra Coverage::Rejection::Ineligible Appraiser',
    'Extra Coverage::Rejection::Item is in Third Party Care',
    'Extra Coverage::Rejection::Loose Stones/Diamonds',
    'Extra Coverage::Rejection::Multiple Items [needs to be separated]',
    'Extra Coverage::Rejection::On Campus Housing',
    'Extra Coverage::Rejection::Online Appraisal',
    'Extra Coverage::Rejection::Open Claim (Item Involved)',
    'Extra Coverage::Rejection::Professional Items',
    'Extra Coverage::Rejection::Proof of Ownership',
    'Extra Coverage::Rejection::Purses/Bags not Eligible for EC',
    'Extra Coverage::Rejection::Rental Items',
    'Extra Coverage::Rejection::Special Limits of Liability [money, bullion, etc]',
    'Extra Coverage::Rejection::Statement of Value',
    'Extra Coverage::Rejection::Temp Block (Request Sent after the Block)',
    'Extra Coverage::Rejection::Under $350 (Bikes, Cameras, Instruments)',
    'Extra Coverage::Rejection::Under $350 (Fine Art)',
    'Extra Coverage::Rejection::Under $350 (Jewelry)',
    'Extra Coverage::Rejection::Underwriting Decline [not compulsory]',
    'Extra Coverage::Rejection::Underwriting Denial [exceeds UW limit]',
    'Extra Coverage::Response::Base Policy vs Extra Coverage Confusion',
    'Extra Coverage::Response::Can We Get on a Call?',
    'Extra Coverage::Response::Cancel My Policy',
    'Extra Coverage::Response::How Much Does the Extra Coverage Cost?',
    'Extra Coverage::Response::PH Did Not Receive Follow-up',
    'Extra Coverage::Support::PTO Action Plan',
    'General Services::Account Management::ACORD Form Requests [ ACORD, Proof of Insurance ] ',
    'General Services::Account Management::Add Mailing Address [ Physical Mail ]',
    'General Services::Account Management::Delete My Account Information ( CA METROMILE ) [ SSN, METRO MILE, 31- ]',
    'General Services::Account Management::Delete My Account Pushback ( All Other States )',
    'General Services::Account Management::Do You Have a Website?',
    'General Services::Account Management::Does the App Work on iPad? [ tablet ]',
    'General Services::Account Management::Email Typo',
    'General Services::Account Management::How Do I Log Out of My Account? [ Log Off ]',
    'General Services::Account Management::Mobile Phone Verification ( Replied With Phone Number )',
    'General Services::Account Management::Mobile Phone Verification [ Phishing ]',
    'General Services::Account Management::Power of Attorney [ Legal Representative / POA ]',
    'General Services::Account Management::Unsubscribe From Marketing Mail [ physical mail , snail mail ]',
    'General Services::Account Management::VA User Call Follow Up - Written Permission to Cancel',
    'General Services::Account Management::Why Do You Need My Date of Birth? [ Birthday , DOB ]',
    'General Services::Account Management::Withdraw Consent Confirmation ( WFH Mail Team Use Only )',
    "General Services::Backdate::Can't backdate past renewal rule ( Blurbs )",
    'General Services::Backdate::Colorado ( Licensed Agents Only )',
    'General Services::Backdate::Why Can’t You Backdate Past the Renewal? ( PH Never Requested Cancelation )',
    'General Services::Backdate::Why Can’t You Backdate Past the Renewal? ( Previously Requested to Cancel but Never Responded to CX )',
    'General Services::Billing::Accidental Dispute ( Step 2 )',
    'General Services::Billing::Can I Change My Payment Date ( Pushback )',
    'General Services::Billing::Can I Pay Annually? ( Pre-Purchase )',
    'General Services::Billing::Can I Pay By Check? ( All Other States )',
    'General Services::Billing::Can I Pay By Check? ( NJ )',
    'General Services::Billing::Can You Send Me a Tax Statement? [ 1098 / 1095 / Tax Forms / Return / End of Year Statement ]',
    'General Services::Billing::Check With Your Bank [ Stripe , Do Not Honor , Generic Decline ]',
    'General Services::Billing::Confirming Charge for Payment Plan Change',
    'General Services::Billing::How Did You Get My New Card Info? [ Billing / Dunning / Update ]',
    'General Services::Billing::Mastercard Error (Payment Processor Issue)',
    'General Services::Billing::Overcharged Premium Bug (WA)',
    'General Services::Billing::Payment Not Authorized ( Cardholder Knows Policyholder ) [ Suspicious Charges / Unauthorized / Fraud / Friend ]',
    'General Services::Billing::Payment Not Authorized ( Cardholder Wants Policy Canceled and Card Removed ) [ Dispute / Remove Card ]',
    'General Services::Billing::Why Did You Charge Me for the Full Year? ( Pre-authorization )',
    'General Services::Billing::Why Is Each Policy Charged Separately?',
    'General Services::Bug::Dunning Balance Collected Late',
    'General Services::Bug::Leap Year Bug',
    'General Services::Business Basics::Abusive Customer Template ( Requires Lead / Senior Manager Approval Before Using )',
    'General Services::Business Basics::API Inquiries',
    'General Services::Business Basics::I Think Your Email is Wrong? ( Outbound )',
    'General Services::Business Basics::Job Seeker General Response [ Resume ]',
    'General Services::Business Basics::PR & Media ( Third Party Contact ) [ Marketing / Speech / Conference / Social Media ]',
    'General Services::Business Basics::Pushback on General Denial Macro',
    'General Services::Business Basics::Review Us [ App Stores ]',
    'General Services::Business Basics::Spanish Speaker',
    'General Services::Business Basics::Stock Inquiry [ Financial Records ]',
    'General Services::Business Basics::Wants a Call [ Representative , Agent , Phone , Speak ]',
    'General Services::Business Basics::Wants More Details - Pushback on Denial',
    'General Services::Business Basics::Who is Lemonade [ Legit / Scam / Trust ]',
    'General Services::Chewy::Forward Customer to Chewy ( Email Follow-up )',
    'General Services::Claims::Claims History',
    'General Services::Claims::How Do I File a Claim if I Don’t Have Access to a Smartphone? ( Part 1 ) [ Claims / No Phone ]',
    'General Services::Claims::How Do I File a Claim if I Don’t Have Access to a Smartphone? ( Part 2 ) [ Claims / No Phone ]',
    'General Services::Claims::My Phone Was Stolen, How Do I File a Claim? [ Lost / Stolen Phone ] ',
    'General Services::Claims::Why Do You Need My SSN to File a Claim?',
    'General Services::Claims::Why Have I Not Heard Back? [ Pending Claim ]',
    'General Services::Claims::Will You Always Have Money to Pay My Claim? [ Financial Stability / Rated / Safe / Trusted ]',
    'General Services::Dunning::Do you report to credit agencies?',
    'General Services::Dunning::Dunning Balance Auto-collected',
    'General Services::Dunning::PH Wants to Set Up New Policy After Dunning Cancelation Due to Dispute [ Billing / Reinstate ]',
    'General Services::Dunning::Temporary Area Block When PH Wants to Set Up New Policy After Dunning Cancelation [ weather , catastrophe ]',
    'General Services::Dunning::You Caused an Overdraft of My Account',
    'General Services::Escalations::Escalation Documentation',
    'General Services::Escalations::Return To Specialist',
    'General Services::Giveback::Breakdown Rainy Day Fund',
    'General Services::Giveback::General Giveback Questions [ FAQ ]',
    'General Services::Giveback::How Can I Suggest a Cause? [ Charity ]',
    'General Services::Giveback::How Do I Change My Giveback Cause? [ Charity / Switch Cause / List / Causes ]',
    'General Services::Giveback::I Changed Your Cause for You! ( Confirmation )',
    'General Services::Giveback::I Didn’t Select This Giveback Cause',
    'General Services::Giveback::I Don’t Want to Participate in the Giveback Program',
    'General Services::Giveback::Wants the Unused Premium Refunded Instead [ Direct Relief / COVID-19 / Giveback ]',
    'General Services::LGA::Forward Customer to LGA ( Email Follow-up )',
    'General Services::Metromile::Forward Customer to Metromile ( Email Follow-up )',
    'General Services::Metromile::Has both Lemonade and Metromile Policies ( Metromile Bundle )',
    'General Services::Onboarding::Can’t Download App ( Has Smartphone )',
    'General Services::Payments::Customer Blocked by Stripe (ONLY IF USER IS BLOCKED IN STRIPE - check stripe first) [ payment , flagged , fraud ]',
    'General Services::Policy Changes and Info::Can You Send a Paper Copy of My Policy in the Mail? ( Step 1 ) ( FL )',
    'General Services::Policy Changes and Info::Can You Send a Paper Copy of My Policy in the Mail? ( Step 2  ) ( FL )',
    'General Services::Policy Changes and Info::Duplicate Policy [ Why Am I Being Charged Twice? ] [ Step 2 ]',
    'General Services::Policy Changes and Info::Follow-up Help Cancel Policy',
    "General Services::Policy Changes and Info::I've Submitted The Request [ EST ]",
    'General Services::Policy Changes and Info::Rating Change',
    "General Services::Policy Changes and Info::Why Haven't I Received My Policy In the Mail ( After Renewal ) ( FL )",
    'General Services::Product Awareness::Car Discount',
    'General Services::Product Awareness::Home Product Discount',
    'General Services::Product Awareness::Pet Discount',
    'General Services::Referrals and Promotions::Amazon Gift Card Promotion ( Employer Is a Partner, Didn’t Use the Link to Sign Up ) ',
    'General Services::Referrals and Promotions::Amazon Gift Card Promotion [ Partner ]',
    'General Services::Referrals and Promotions::My Friend Referred Me, Where Is Their Gift Card? ',
    'General Services::Request Tracking::Bug Reports- High Priority Pending Request ( 1st Follow-up ) [ON HOLD]',
    'General Services::Request Tracking::Bug Reports- High Priority Pending Request ( 2nd Follow-up, Over SLA ) [ON HOLD]',
    'General Services::Request Tracking::Bug Reports- Low Priority Pending Request ( 1st Follow-up ) [ON HOLD]',
    'General Services::Request Tracking::Bug Reports- Low Priority Pending Request ( 2nd Follow-up, Over SLA )  [ON HOLD]',
    'General Services::Request Tracking::Bug Reports- Medium Priority Pending Request ( 1st Follow-up )  [ON HOLD]',
    'General Services::Request Tracking::Bug Reports- Medium Priority Pending Request ( 2nd Follow-up, Over SLA )',
    'General Services::Request Tracking::Compliance Inbox Pending Request ( 1st Follow-up )',
    'General Services::Request Tracking::Compliance Inbox Pending Request ( 2nd Follow-up, Over SLA )',
    'General Services::Request Tracking::Compliance PII Pending Request ( 1st Follow-up )',
    'General Services::Request Tracking::Compliance PII Pending Request ( 2nd Follow-up, Over SLA )',
    'General Services::Request Tracking::EST Pending Request ( 1st Follow-up )',
    'General Services::Request Tracking::EST Pending Request ( 2nd Follow-up, Over SLA ) ',
    'General Services::Request Tracking::I’ve Submitted Your Request ( Bug Reports - High Priority )',
    'General Services::Request Tracking::I’ve Submitted Your Request ( Bug Reports - Low Priority )',
    'General Services::Request Tracking::I’ve Submitted Your Request ( Bug Reports - Medium Priority )',
    'General Services::Request Tracking::I’ve Submitted Your Request ( Compliance Inbox )',
    'General Services::Request Tracking::I’ve Submitted Your Request ( Compliance PII )',
    'General Services::Request Tracking::I’ve Submitted Your Request ( EST ) [ON HOLD]',
    'General Services::Request Tracking::I’ve Submitted Your Request ( UW )  [ON HOLD]',
    'General Services::Request Tracking::UW Pending Request ( 1st Follow-up ) [ON HOLD]',
    'General Services::Request Tracking::UW Pending Request ( 2nd Follow-up, Over SLA )  [ON HOLD]',
    'General Services::Undercharge::PA Undercharge',
    'General Services::Where Are You Live?::Homeowners Not Available In Your State ( RI / IA / NM / AR )',
    'General Services::Where Are You Live?::Lemonade Not Live in Your State',
    'General Services::Where Are You Live?::Products in AK / DE / HI / ID / KS / KY / LA / ME / MN / SD / VT /  WV / WY',
    'General Services::Where Are You Live?::Products in AL / MS / MT / ND / NE / NC / NH / SC / UT',
    'General Services::Where Are You Live?::Products in FL',
    'General Services::Where Are You Live?::Products in IL and TN',
    'General Services::Where Are You Live?::Products in MA',
    'General Services::Where Are You Live?::Products in NM and AR',
    'General Services::Where Are You Live?::Products in NY',
    'General Services::Where Are You Live?::Products in RI and WA',
    'GPT Email Agent::Escalated Contacts::Incorrect Answer',
    'GPT Fail::Made up Info',
    'GPT Fail::Misunderstood Intent',
    'GPT Fail::Tone/Brand',
    'GPT Fail::Unexpected Behavior',
    'GPT Pass',
    'Home Product::Underwriting::Why Lemonade Has Temporary Area Blocks ( Pushback ) [ weather , catastrophe ]',
    'Home Product::Billing::Bug Reports Refund',
    'Home Product::Billing::Why is My Premium So High? ( Michigan )',
    'Home Product::Billing::Why Was I Charged Twice? [ Wrong CVC / Expiration Date ]',
    'Home Product::Billing::Why Was I Double Charged? ( Refund Is Already On The Way )',
    'Home Product::Blog Posts::Deductibles',
    'Home Product::Business Basics::Can You Re-Run My Credit report?',
    'Home Product::Business Basics::Colorado Hazard Mitigation Fee [ CO , Fee , $2 ] ',
    'Home Product::Business Basics::Do You Check Credit? ( CA )',
    'Home Product::Business Basics::Do You Check Credit? ( NO ) ( WA / MD / MA )',
    'Home Product::Business Basics::Do You Check Credit? ( YES ) ( All Other States )',
    'Home Product::Business Basics::Do You Offer Rental Car / Boat Liability Policies?',
    'Home Product::Business Basics::Do You Provide An Insurance Card?',
    'Home Product::Business Basics::Does My Burglar Alarm Count? ( FL )',
    'Home Product::Business Basics::What Is an Emergency Management Preparedness Fee? ( FL ) [ 2 ]',
    "Home Product::Claims::Can I File a Claim if it's Below My Deductible?",
    'Home Product::Claims::Can I File a Claim? ( Ongoing Hurricane / Tornado / Snow Storm )',
    'Home Product::Claims::How Can I File a Claim If I Have a Lot Of Damaged Items?',
    'Home Product::Claims::Is This Covered? Hypothetical ( Step 2 )',
    'Home Product::Claims::Property Manual Claim Inquiry (After App Attempt)',
    'Home Product::Claims::Why Did My Claim Get Flagged? ( SIU )',
    'Home Product::Coverage::Are My Things covered While Traveling? [ Checked Bag / Carry On / Luggage / Flight ]',
    'Home Product::Coverage::Are The Things I Keep Outside Covered? [ Slides / Swings / Barbecue / Furniture / Lawn / Tools / Patio ]',
    'Home Product::Coverage::Art Created by Insured',
    'Home Product::Coverage::Business Equipment [ All States - Not VA ]',
    'Home Product::Coverage::Business Equipment? [ VA ]',
    'Home Product::Coverage::Can I Add Water Back Up Coverage? ( YES )',
    'Home Product::Coverage::Can I Add Water Backup Coverage? ( No FL - Renters Only )',
    'Home Product::Coverage::Can I have $0 deductible? [ zero ]',
    'Home Product::Coverage::Can I Rent my Place on AirBnB?',
    'Home Product::Coverage::Can you Cover Anyone Under 18?',
    'Home Product::Coverage::Corona Virus / Monkey Pox',
    'Home Product::Coverage::Damage From Riot or Civil Commotion? [ Protests / Looting ]',
    'Home Product::Coverage::Do I Need Extra Coverage?',
    'Home Product::Coverage::Do You Cover Auto Parts? [ Car / Truck / Vehicle /Tires / Catalytic Converter ]',
    'Home Product::Coverage::Do You Cover Theft or Damage by Maintenance/Contractors/Property Employees ?',
    'Home Product::Coverage::Dog Breeds NV',
    'Home Product::Coverage::Dogs ( GA )',
    'Home Product::Coverage::Drones ( All Other States )',
    'Home Product::Coverage::Drones ( NY , CA , VA )',
    'Home Product::Coverage::Electric Scooter or Skateboard ( Other Electric Vehicles )',
    'Home Product::Coverage::Firearms ( VA )',
    'Home Product::Coverage::Firearms Owner Unhappy With Coverage Options',
    'Home Product::Coverage::How Do I Add an Endorsement',
    'Home Product::Coverage::How is my Child Covered While Away At School?',
    'Home Product::Coverage::How Is My Watercraft Covered? [ Boats / Canoe / Pontoon / Rowboat ] ( All States - Not VA )',
    'Home Product::Coverage::How Is My Watercraft Covered? [ Boats / Canoe / Pontoon / Rowboat ] ( VA )',
    'Home Product::Coverage::Insuring a Business Name',
    'Home Product::Coverage::Is My Bike Rack Covered? [ Bicycle ]',
    'Home Product::Coverage::Locked Out / Locksmith',
    'Home Product::Coverage::Loss of Employment',
    'Home Product::Coverage::Medical Devices and Medicines [ Chair / Scooter / Cochlear / Glasses / Sleep Apnea ]',
    'Home Product::Coverage::Mold ( WA & NJ )',
    'Home Product::Coverage::Nanny / Babysitter and Workers Comp ( All Other States )',
    'Home Product::Coverage::Nanny / Babysitter and Workers Comp ( CA )',
    'Home Product::Coverage::Nanny / Babysitter and Workers Comp ( NJ )',
    'Home Product::Coverage::Nanny / Babysitter and Workers Comp ( NY )',
    'Home Product::Coverage::Spoiled Food ( CA )',
    'Home Product::Coverage::Stolen Packages',
    'Home Product::Coverage::Tornado',
    'Home Product::Coverage::Trampolines ( AR , AZ , CA , GA , IA , MD , MA , NM , NV , OH , OK , OR , PA , TN , TX , WA )',
    'Home Product::Coverage::Trampolines ( FL )',
    'Home Product::Coverage::Utility Interruption ( Pushback - Wants to Know if Their Situation is Covered ) [ LOU , loss of use , power outage , heat , air conditioning , AC , A/C , gas ]',
    'Home Product::Coverage::Utility Interruption and Food Loss ( CA Only ) [ LOU , loss of use , power outage , heat , air conditioning , AC , A/C , gas ]',
    "Home Product::Coverage::Vacant Land ( Builder's Risk )",
    'Home Product::Coverage::What Does Fortuitous Mean?',
    'Home Product::Coverage::What Is Personal Property Coverage?',
    'Home Product::Coverage::What is the Difference Between Medical Payments to Others and Liability Coverage?',
    "Home Product::Coverage::Why Can't You Add My Property Manager/Landlord As Additional Insured? [ Pushback , Additional Insured , Landlord ]",
    "Home Product::Coverage::Why Don't You Cover My Dog? ( Restricted Breed Pushback )",
    'Home Product::Coverage::Wildfires',
    'Home Product::Earthquake::After Hours Palomar ( CA )',
    'Home Product::Earthquake::Can I add Palomar To My Lemonade Bill? ( CA )',
    'Home Product::Earthquake::Can I Change The Coverage Limits on my Lemonade EQ Coverage?',
    'Home Product::Earthquake::Canceling Palomar EQ',
    'Home Product::Earthquake::Customer Unhappy With Palomar Options',
    'Home Product::Earthquake::Homeowners::New Palomar Purchase ( No Lemonade Policy )',
    'Home Product::Earthquake::How Can I Stick With Palomar EQ?',
    'Home Product::Earthquake::Lemonade EQ Deductibles',
    'Home Product::Earthquake::Not available In Your State',
    'Home Product::Earthquake::Palomar Policy Confirmed Cancel',
    'Home Product::Earthquake::Palomar vs. Lemonade EQ comparison',
    'Home Product::Earthquake::Postal Code Temp Blocked',
    'Home Product::Earthquake::Renters::How Do I Add EQ Coverage? ( Post Purchase - Does Not Have Palomar ) ( CA )',
    'Home Product::Earthquake::Renters::How Do I Add EQ Coverage? ( Post Purchase - Has Palomar ) ( CA )',
    'Home Product::Earthquake::Renters::What Does This Cover? ( CA )',
    'Home Product::Earthquake::Update Address on Palomar Policy',
    'Home Product::Extra Coverage::How Much Does it Cost to Schedule an Item?',
    'Home Product::Policy Changes and Info::Can You Add an Address to My Additional Insured? ( No )',
    "Home Product::Policy Changes and Info::Can You Add my Pet's Name to my Policy?",
    'Home Product::Policy Changes and Info::How Do I Add My Domestic Partner?',
    "Home Product::Policy Changes and Info::I've Added Your Additional Insured ( Confirmation )",
    'Home Product::Policy Changes and Info::Moving ( Future Date )',
    "Home Product::Switching::Customer's Old Policy is With Apartment Complex",
    'Home Product::Switching::How Does This Work?',
    'Home Product::Switching::How Long Will This Take?',
    'Home Product::Switching::Incorrect Use ( Accident )',
    'Home Product::Switching::Incorrect Use ( CX Accidentally Canceled )',
    'Home Product::Switching::Incorrect Use ( Missing Info - Step 2 )',
    'Home Product::Switching::Incorrect Use ( Missing Info )',
    'Home Product::Switching::Incorrect Use ( Moving )',
    'Home Product::Switching::Incorrect Use ( Multiple Policies )',
    'Home Product::Switching::Incorrect Use ( Request Came From Someone Other Than Named Insured )',
    'Home Product::Switching::Incorrect Use ( Trying to Cancel Current Policy , Not Switch )',
    'Home Product::Switching::My Old Insurer Denied Cancelation Request',
    "Home Product::Switching::My Old Policy Didn't Cancel",
    "Home Product::Switching::Previous Insurer Will Not Accept Lemonade's Cancelation Request",
    'Homeowners::Account Management::Insurance Score Inquiry',
    "Homeowners::Agent Servicing::Agent Can't Access Client Quote or Policy",
    'Homeowners::Agent Servicing::Agent Would Like a Call',
    'Homeowners::Backdate::Escrow Refund Confirmation',
    'Homeowners::Billing::Auto Bundle Discount ( Not Available ) ( All Other States  )',
    'Homeowners::Billing::Auto Bundle Discount ( Yes ) ( Agent & Illinois Only ) [ Bundle / 10% / Onboarding / New Customer ]',
    'Homeowners::Billing::Closing Customer Wants to Pay Monthly, Not Annually',
    "Homeowners::Billing::Customer Doesn't Have Closing Date",
    'Homeowners::Billing::Dunning Canceled / Escrow True',
    'Homeowners::Billing::Escrow Dunning Canceled / Customer Wants Reinstatement Pushback',
    'Homeowners::Billing::Full Payment Received / Refund Sent',
    'Homeowners::Billing::How Do I Pay?',
    "Homeowners::Billing::Need a Receipt / Hasn't Paid Yet",
    'Homeowners::Billing::Policy Cancelation for Policies Paid Via Escrow',
    'Homeowners::Billing::Title Company Check',
    'Homeowners::Bugs::Dunning Email Bug',
    'Homeowners::Bugs::Dunning Email Bug (Escalation)',
    'Homeowners::Bugs::Refund Renewal Premium - You’re Welcome ( Dec Page Reflecting Lower Premium )',
    'Homeowners::Bugs::Renewal Premium - General Response ( Dec Page Reflecting Lower Premium )',
    'Homeowners::Bugs::Renewal Premium - Refund Renewal Bug - How much is my refund ( Dec Page Reflecting Lower Premium )',
    'Homeowners::Bugs::Renewal Premium Change ( Renovation Age Issue )',
    'Homeowners::Bugs::Renewal Premium Change ( Renovation Age Issue ) ( Second Response )',
    'Homeowners::Business Basics::Apple to Apple Comparison',
    'Homeowners::Business Basics::Can I Have a Full Refund? ( Pending UW Cancelation )',
    "Homeowners::Business Basics::I'm Not Pete",
    'Homeowners::Business Basics::Replacement Cost vs. Market Value',
    'Homeowners::Business Basics::Replacement Cost vs. Market Value',
    'Homeowners::Business Basics::Spanish Speaker',
    'Homeowners::Business Basics::What Is the Healthy Homes Fee? ( Connecticut , CT ) [ 12 ]',
    'Homeowners::Business Basics::Where Can I Send EOI Request?',
    'Homeowners::Claims::How Do I File A Loss Of Use Claim?',
    'Homeowners::Coverage::Am I Covered During a Leaseback?',
    'Homeowners::Coverage::Can I Add Loss Assessment Coverage? ( YES )',
    'Homeowners::Coverage::Can You Select My Other Structures Coverage Limit For Me? (CA)',
    'Homeowners::Coverage::Do You Cover Accidental Damage / Equipment Breakdown? ( NO , EBC NOT AVAILABLE ) [ EBC / Warranty ]',
    'Homeowners::Coverage::Do You Cover Fallen Trees?',
    'Homeowners::Coverage::Do You Cover Home Flips [ Investment Property ]',
    'Homeowners::Coverage::Do You Cover Pools? ( CT, MI, NJ )',
    'Homeowners::Coverage::Do You Cover Service Lines? (AZ, CO, CT, DC, GA, IA, IL, IN, MD, MI, MO, NJ, NY, OH, OK, OR PA, TN, TX, VA, and WI) [ Utility Line, Buried Utilities ]',
    'Homeowners::Coverage::Do you Cover the Foundation? ( Not TX )',
    'Homeowners::Coverage::Do you Cover the Foundation? ( TX )',
    'Homeowners::Coverage::Do You Coverage Service Lines? ( All Other States )  [ Utility Line / Buried Utilities ]',
    'Homeowners::Coverage::Do You Offer Extended Replacement Coverage? ( HO3 & HO6 - All Other States )',
    'Homeowners::Coverage::Do You Offer Extended Replacement Coverage? ( HO3 & HO6 - VA )',
    'Homeowners::Coverage::Dwelling and Personal Property Coverage [ Open Perils / Named Perils ]',
    'Homeowners::Coverage::How Do I Determine Coverage and Value for Other Structures (CA) ',
    'Homeowners::Coverage::How Is My Roof Covered?',
    'Homeowners::Coverage::Is My Fence Covered?',
    'Homeowners::Coverage::Is My Pool Covered ( IL , VA, NY )',
    'Homeowners::Coverage::Is My Pool Covered? ( AZ, CA, GA, IA, MD, MA, NV, OH, OK, OR, PA, TN, TX )',
    'Homeowners::Coverage::Is My Pool Covered? ( CO , DC , IN , MO , WI )',
    'Homeowners::Coverage::Is My Trampoline Covered? ( CO , DC , IN , MO , RI (HO6 Only) , WI )',
    'Homeowners::Coverage::Is My Trampoline Covered? ( CT , IL , MI , NJ , NY , RI )',
    'Homeowners::Coverage::Ordinance or Law Coverage [ Improvements / Remodel / Upgrades ]',
    'Homeowners::Coverage::Other Structures Coverage (Pre-Purchase Explanation) [ Garage , Shed , Fence ] (All Other States)',
    'Homeowners::Coverage::Other Structures Coverage [ Garage / Shed ]',
    'Homeowners::Coverage::Pests [ Bed Bugs / Vermin / Termites / Tenting / Rats / Mouse / Infested ]',
    "Homeowners::Coverage::What If I Don't Have Any Other Structures (CA) ",
    'Homeowners::Coverage::What If I Install a New Other Structure (CA)',
    'Homeowners::Coverage::What If I Remove One of My Other Structures (CA)',
    'Homeowners::Coverage::What is a Deductible? ( All Other States )',
    'Homeowners::Coverage::What is a Deductible? ( CA ( non-rental property - check Blender ) and VA )',
    'Homeowners::Coverage::What is a Deductible? ( CA rental property - check Blender )',
    'Homeowners::Coverage::What is Long Term Water Damage',
    'Homeowners::Coverage::What is Loss Assessment Coverage?',
    'Homeowners::Coverage::Why are Wind and Hail Excluded? ( TX )',
    "Homeowners::Coverage::Why Don't You Offer Windstorm Coverage In My Area? ( TX )",
    'Homeowners::Lender Requests::Asking for 438 BFU Endorsement',
    'Homeowners::Lender Requests::Asking for EOI for UW Declined Quote',
    'Homeowners::Lender Requests::Asking for Paid Receipt',
    'Homeowners::Lender Requests::Asking for Receipt Before Effective Date',
    'Homeowners::Lender Requests::Asking Unlicensed Agent for Increase in Cov A',
    "Homeowners::Lender Requests::Lender Request Change but it's Not Completed ( Need Customer Permission )",
    'Homeowners::Lender Requests::Quote Not Bound ( Call Follow-up )',
    'Homeowners::Lender Requests::Reaching Out About Agent Bound Quote',
    'Homeowners::Lender Requests::Reaching Out About Agent Generated Quote',
    'Homeowners::Onboarding::Adjust Reconstruction Cost on Quote',
    'Homeowners::Onboarding::Can I Add my Estate? ( NO )',
    'Homeowners::Onboarding::Can I add my Trust? ( NO )',
    'Homeowners::Onboarding::Can I add my Trust? ( YES )',
    'Homeowners::Onboarding::Can I Have a Binder? ( Pre-purchase )',
    'Homeowners::Onboarding::Customer Has Questions',
    'Homeowners::Onboarding::Decline - California Pause ( New Customer )',
    'Homeowners::Onboarding::Difference Between Interested Party and Additional Insured Condo HO6 ( Pre-Purchase )',
    'Homeowners::Onboarding::EOI Follow Up',
    'Homeowners::Onboarding::HO3 Roof Questions ( PA / IL / GA / MA / CT / TN / OR / MO / OH / IN / WI / AR / RI / CO )',
    'Homeowners::Onboarding::Townhome - HO3 or HO6?',
    'Homeowners::Onboarding::Why Do You Need My SSN? ( Not CA )',
    'Homeowners::Onboarding::Will You Do a Physical Inspection?',
    'Homeowners::Onboarding::Wood Frame or Masonry?',
    'Homeowners::Onboarding::Your Prices are Higher [ too expensive ]',
    'Homeowners::Policy Changes and Info::Add Additional Insured ( WA )',
    'Homeowners::Policy Changes and Info::Adjusting Coverages on Renewal ( Detailed Version )',
    'Homeowners::Policy Changes and Info::Are my Discounts Already Included? (Different Premium) [Discounts / Dec Page  ]',
    'Homeowners::Policy Changes and Info::Asking to Add Unit Number to HO6 ( Step 1 )',
    'Homeowners::Policy Changes and Info::Asking to Add Unit Number to HO6 ( Step 2 )',
    'Homeowners::Policy Changes and Info::Can I add my LLC to my Condo Policy? ( Not Rented )',
    'Homeowners::Policy Changes and Info::Can I Add My LLC To My Rental Property? ( HO6 ONLY ) ( DC, IL, NJ )',
    'Homeowners::Policy Changes and Info::Can I Add My LLC To My Rental Property? ( HO6 ONLY ) ( Not CA, NY, TX )',
    'Homeowners::Policy Changes and Info::Can I add my LLC? ( HO3 ONLY )',
    'Homeowners::Policy Changes and Info::Can I get a Discount for Storm Shutters?',
    'Homeowners::Policy Changes and Info::Can I Increase Other Structures Coverage? ( YES ) (Post Purchase ) ( Check State )',
    'Homeowners::Policy Changes and Info::Can you reinstate my policy? ( Agent Bound )',
    "Homeowners::Policy Changes and Info::Can't Change Address in Blender",
    "Homeowners::Policy Changes and Info::Can't Change Address in Blender ( Can Submit EST if Small Change )",
    'Homeowners::Policy Changes and Info::Change Coverage / Add Endorsement on Escrow Policy ( Step 2 )',
    'Homeowners::Policy Changes and Info::Customer Refinancing',
    'Homeowners::Policy Changes and Info::Customer Wants to Change Coverages Post Escrow Payment',
    'Homeowners::Policy Changes and Info::Difference Between Interested Party and Additional Insured Condo HO6 ( Post-Purchase )',
    'Homeowners::Policy Changes and Info::Evidence of Cancelation Documents',
    'Homeowners::Policy Changes and Info::How Do I Cancel my Policy? [ Cancelation , Homeowners , Lapse in Coverage ]',
    'Homeowners::Policy Changes and Info::I Need Less Dwelling Coverage',
    'Homeowners::Policy Changes and Info::Lower/Remove Coverages on Escrow Policy ( Step 1 )',
    'Homeowners::Policy Changes and Info::Mortgagee / Lender Asking to Add Additional Insured',
    'Homeowners::Policy Changes and Info::Needs Policy Effective Date Changes to Past Date',
    'Homeowners::Policy Changes and Info::Update effective date to today ( Closing )',
    'Homeowners::Policy Changes and Info::Where is my Structure Discount?',
    'Homeowners::Policy Changes and Info::Where is my Structure Discount? ( Pushback )',
    "Homeowners::Policy Changes and Info::Why Can't I Change my Effective Date?",
    'Homeowners::Renewals::Manual Renewal Review- Gathering Documentation',
    'Homeowners::Renewals::Premium Rose After Renewal ( Insurance Score )',
    'Homeowners::Renewals::Self Inspection - CX Handoff',
    "Homeowners::Renewals::Self Inspection - Do I Have To Do This? / I Don't Want to Download an App",
    'Homeowners::Renewals::Self Inspection - Gathering Documentation',
    'Homeowners::Renewals::Self Inspection - I Finished, Now What?',
    'Homeowners::Renewals::Self Inspection - Troubleshooting',
    'Homeowners::Renewals::Self Inspection - Will I Renew?',
    'HOOps::Accounts Receivable::Follow up on adding card for escrow dunning [escrow , dunning, non escrow balance due , add card , follow up ]',
    'HOOps::Mortgage Servicing: Possible Private Lender [ private lender]',
    'Internal Notes::Failed Faxes',
    'Internal Notes::Incomplete Licensed Triage ( Renters )',
    'Internal Notes::MCI',
    'Internal Notes::Phone Call Template',
    'Life CX Macro Template',
    'Life::Coverage::Are there exclusions to this policy?',
    'Life::Coverage::How do cancellations work?',
    'Life::Account Management:: Bestow-only change request [trust bene, irrevocable bene change/release, collateral assignment release] (Step 1 of 2)',
    "Life::Account Management::Account Change - Blender User Name (doesn't match Life policy)",
    'Life::Account Management::Account Change - Email Address',
    'Life::Account Management::Account Change - Owner Name (typo)',
    'Life::Account Management::Account Change- Phone Number',
    'Life::Account Management::Address Change - Dropbox Sign (Step 2 of 3)',
    'Life::Account Management::Address Change - Final Confirmation (Step 3 of 3)',
    'Life::Account Management::Address Change - Verification (Step 1 of 3)',
    'Life::Account Management::Bestow-only change request [trust beneficiary, irrevocable beneficiary change/release, collateral assignment release] (Step 2 of 2)',
    'Life::Account Management::Cancel Policy [1st response DURING Free Look]',
    'Life::Account Management::Cancel Policy [2nd response, follow up, DURING or AFTER free look, policy ACTIVE]',
    'Life::Account Management::Cancellation (1st response AFTER free look, IN DUNNING)',
    'Life::Account Management::Changing Beneficiaries - Dropbox Sign ( 2 of 3)',
    'Life::Account Management::Changing Beneficiaries Verification ( 1 of 3)',
    'Life::Account Management::Changing Beneficiary - Final Confirmation ( 3 of 3)',
    'Life::Account Management::Changing Secondary Contact - Bestow Triage (2 of 3)',
    'Life::Account Management::Changing Secondary Contact - Final Confirmation (3 of 3)',
    'Life::Account Management::Changing Secondary Contact - Verification (1 of 3)',
    'Life::Account Management::How to access my Term Life policy docs',
    'Life::Account Management::How to log in to Life account',
    'Life::Account Management::How to update primary beneficiaries',
    'Life::Account Management::Life Dispute (1st Contact)',
    'Life::Account Management::Life Dispute (2nd Contact)',
    'Life::Account Management::Name Change - Dropbox Sign (Step 2 of 3) [legal name change, marriage]',
    'Life::Account Management::Name Change - Final Confirmation (Step 3 of 3) [legal name change, marriage]',
    'Life::Account Management::Name Change - Verification (Step 1 of 3) [legal name change, marriage]',
    'Life::Account Management::Policy Owner Assignment Verification (1 of 2)',
    'Life::Account Management::Policy Owner Assignment- Confirmation (2 of 2)',
    'Life::Account Management::Snail Mail- Request Confirmation (1 of 2)',
    'Life::Account Management::Snail Mail- Triage Confirmation (2 of 2)',
    'Life::Account Management::Undeliverable Mail / Non-Payment Grace Notice [OUTBOUND] Step 2: address incorrect',
    'Life::Account Management::Verifying beneficiaries/ secondary contact',
    'Life::Billing::Can I pay annually?',
    'Life::Billing::Does my premium increase over time?',
    'Life::Billing::How do payments work?',
    'Life::Billing::How is life insurance paid out?',
    'Life::Billing::If I cancel my policy do I get a refund?',
    'Life::Billing::Is the death benefit taxable?',
    'Life::Business Basics::Spanish Speaker',
    'Life::Coverage::Can a minor be my beneficiary?',
    'Life::Coverage::Can I have more than one life insurance policy?',
    'Life::Coverage::Can I increase coverage on this policy? (post-purchase, AFTER free look)',
    'Life::Coverage::Can I increase coverage on this policy? (post-purchase, DURING free look)',
    'Life::Coverage::Can I increase coverage on this policy? (pre-purchase)',
    'Life::Coverage::Can I name a beneficiary outside of the US?',
    'Life::Coverage::Can I purchase a life insurance policy for another person?',
    'Life::Coverage::Cash value (policy loan, partial surrender)',
    'Life::Coverage::Does this policy auto-renew when my term is over?',
    'Life::Coverage::Does this policy cover death by suicide?',
    'Life::Coverage::How much does a Lemonade life insurance policy cost?',
    'Life::Coverage::How much life insurance do I need?',
    'Life::Coverage::I already have a life insurance policy with another company, am I allowed to keep it? [replacement]',
    'Life::Coverage::What does this policy cover (death benefit)? (V3 ONLY)',
    'Life::Coverage::What happens if both my beneficiary and I are in the same tragic accident, who would then receive the death benefit?',
    'Life::Coverage::What is a beneficiary?',
    'Life::Coverage::What is needed to file a claim?',
    'Life::Coverage::What is term life insurance?',
    'Life::Coverage::What is the difference between permanent life and term life? (V3 ONLY)',
    'Life::Coverage::What is the free look period?',
    'Life::Coverage::What terms and death benefits do you offer? (V2 ONLY)',
    'Life::Coverage::What terms and death benefits do you offer? (V3 ONLY)',
    'Life::Coverage::When is my coverage effective?',
    'Life::Coverage::Who are Bestow and North American? (Bestow, North American, SFG, partners)',
    'Life::LGA::Billing::Can I pay annually?',
    'Life::LGA::Billing::How do Payments Work?',
    'Life::Log::Bestow Originated Call - CX Life [ Life Policy, CX US-Life, Phone ]',
    'Life::Log::Bestow Originated Ticket - CX Life [ Life Policy, CX US-Life, Email ]',
    'Life::Macro Template',
    'Life::Onboarding::Can I have a higher face / coverage amount? (V3 ONLY)',
    'Life::Onboarding::Can I have a longer term?',
    'Life::Onboarding::Can I see a policy before purchasing? (life sample policy)',
    'Life::Onboarding::Does my credit score go into consideration?',
    'Life::Onboarding::I purchased the wrong term policy, am I allowed to cancel (20 to 10)? (V2 ONLY)',
    'Life::Onboarding::I purchased the wrong term policy, am I allowed to cancel (30 to 10)? (V3 ONLY)',
    'Life::Onboarding::I’m not a U.S. citizen. Am I eligible? (V3 ONLY)',
    'Life::Onboarding::There is no medical exam required!',
    'Life::Onboarding::What gender do I put in the application?',
    "Life::Onboarding::What if I don't know the answers to the medical questions?",
    'Life::Onboarding::What info do you need from me in the quote process?',
    'Life::Onboarding::Why do you need my SSN / visa number / beneficiary information just for a quote? (PUSHBACK, 2ND REPLY) [data privacy, indicative quote, application]',
    'Life::Onboarding::Why do you need my SSN / visa number / beneficiary information just for a quote? [data privacy, indicative quote, application]',
    'Life::Onboarding::Why do you need my SSN?',
    'Life::Onboarding::Why does an INCREASE in coverage result in a DECREASE in premium? (pricing bands)',
    'Life::Tagging::Non-Life related incoming call',
    'Life::Underwriting::Are you discriminating against me?',
    'Life::Underwriting::Does this mean I’ve been declined? (COVID, ineligible, stop application)',
    'Life::Underwriting::Escalation - Answered Question Incorrectly - Already Have A Policy (Step 2)',
    'Life::Underwriting::Escalation - Answered Question Incorrectly - Approved But Have Not Purchased (Step 2)',
    'Life::Underwriting::Escalation - Answered Question Incorrectly - Approved But Have Not Purchased / Already Have A Policy (Step 1)',
    'Life::Underwriting::Escalation - Answered Question Incorrectly - Asking For A Reset',
    'Life::Underwriting::Formal Complaints (BBB, Department of Insurance, legal action)',
    'Life::Underwriting::Response to Approved',
    'Life::Underwriting::Response to Bound',
    'Life::Underwriting::Response to Decline_sd_medical',
    'Life::Underwriting::Response to Declined dv_medical',
    'Life::Underwriting::Response to Declined dv_nonmedical',
    'Life::Underwriting::Response to Declined dv_rx',
    'Life::Underwriting::Response to Declined sd_[string]',
    'Life::Underwriting::Response to Declined sd_citizen',
    'Life::Underwriting::Response to Declined sd_lifestyle',
    'Life::Underwriting::Response to Declined sd_max_line',
    'Life::Underwriting::Response to Dv_incomplete',
    'Life::Underwriting::Response to Ineligible Duplicate SSN (Lemonade - Approved)',
    'Life::Underwriting::Response to Open',
    'Life::Underwriting::Response to Open (age)',
    'Life::Underwriting::Response to Open (BMI)',
    'Life::Underwriting::Response to Open (COVID)',
    'Life::Underwriting::Response to Open (height)',
    'Life::Underwriting::Response to Open (NY)',
    'Life::Underwriting::Response to Open (ownership)',
    'Life::Underwriting::Response to Open (Replacement)',
    'Life::Underwriting::Response to Pending (Data Vendor Reflexive) [no, email not sent]',
    'Life::Underwriting::Response to Pending (Data Vendor Reflexive) [yes, email sent]',
    'Life::Underwriting::Response to Reapply Post-UW Decline (24 months)',
    'Life::Underwriting::Response to Refer to Underwriting',
    'Life::Underwriting::Response to Stale',
    'Life::Underwriting::Response to Submitted (failed to retrieve data)',
    'Life::Underwriting::Response to Submitted (retrying data vendor)',
    'Life::Underwriting::Why can’t I purchase a policy because of my age? (V2 ONLY)',
    'Life::Underwriting::Why can’t I purchase a policy because of my age? (V3 ONLY)',
    'Life::Underwriting::Why can’t I purchase a policy because of where I live? (NY Only)',
    'Life::Underwriting::Why is my price higher than $9/mo?',
    'Life::Underwriting::Your prices are too high (expensive, pricing, feedback)',
    'Pet::Account Management::Cancel Policy (Automatic Renewal)',
    'Pet::Account Management::Cancel Policy (Claims Related)',
    'Pet::Account Management::Cancel Policy (Claims Related) [follow up steps]',
    'Pet::Account Management::Cancel Policy (Dunning - Renewal)',
    'Pet::Account Management::Cancel Policy (Renewal Increase)',
    'Pet::Account Management::Cancel Policy (Step 1.5, No Response Follow Up)',
    'Pet::Account Management::Cancel Policy (UW Decline - Coverage)',
    "Pet::Account Management::Change Age or Pet's Breed ( Step 3 ( Confirm After Change Made ))",
    'Pet::Account Management::Change Pet’s Age ( Step 2 ( Premium Increase )) ( Renewal HAS Been Generated )',
    'Pet::Account Management::Change Pet’s Age ( Step 2 ( Premium Increase )) ( Renewal Has NOT Been Generated )',
    "Pet::Account Management::Change Pet's Age or Breed ( Step 2 ( Premium Decrease or Same ))",
    'Pet::Account Management::Change Pet’s Age or Breed ( Step 2 ( Premium Increase ))',
    "Pet::Account Management::Change Pet's Breed ( Step 2 ( Premium Decrease or Same ))",
    "Pet::Account Management::Change Pet's Breed ( Step 2 ( Premium Increase )) ( Renewal HAS Been Generated )",
    'Pet::Account Management::Pet Dispute ( 1st Contact )',
    'Pet::Account Management::Pet Dispute ( 2nd Contact )',
    'Pet::Account Management::Pet Dispute ( Final Contact )',
    'Pet::Account Management::Update Address ( DIY Pet Moving Flow )',
    'Pet::Account Management::Update Address ( Homeowners or Renters Address Mismatch ) ( Apartment Number )',
    'Pet::Account Management::Update Address ( Homeowners or Renters Address Mismatch ) ( Full Address )',
    'Pet::Account Management::Update Address (Abandoned Pet Moving Flow)',
    'Pet::Account Management::Update Address [ Pet Moved / Change Address / Moving ] ( Pricing Pushback ) ( Step 3 )',
    'Pet::Account Management::Update Address [ Pet Moved / Change Address / Moving ] ( STEP 2 ( Premium Decrease )) ( FL Only )',
    'Pet::Account Management::Update Address [ Pet Moved / Change Address / Moving ] ( STEP 2 ( Premium Decrease ))(Renewal HAS Been Generated)',
    'Pet::Account Management::Update Address [ Pet Moved / Change Address / Moving ] ( STEP 2 ( Premium Increase )) ( FL Only )',
    'Pet::Account Management::Update Address [ Pet Moved / Change Address / Moving ] ( STEP 2 ( Same Premium )) ( FL Only )',
    "Pet::Account Management::Why Didn't My Pet's Age Update on Their Birthday?",
    "Pet::Account Management::Why Didn't You Immediately Cancel? ( Pushback )",
    'Pet::Billing::61 Day Dunning Period Issue [ Balance Due / Dunning Canceled ]',
    'Pet::Billing::CA Renewal Offer Email Bug (outbound)',
    'Pet::Billing::Can I Have a Breakdown of My Premium?',
    'Pet::Billing::Can I Pay the Annual Premium? ( 5% Annual Discount ) [ Pre-purchase ]',
    'Pet::Billing::Can I Switch My Payment Plan? [ Monthly / Annually ]',
    'Pet::Billing::Can I Use a Different Credit Card for This Policy?',
    'Pet::Billing::Do You Offer Discounts for Chewy Employees?',
    'Pet::Billing::Does This Price Include All My Policies?',
    'Pet::Billing::Payment Plan Options ( FL )',
    'Pet::Billing::PH Sent Check for Full Annual Premium ( HoOps Flagged CX )',
    'Pet::Billing::PH Sent Check for Monthly or Partial Premium ( HoOps Flagged CX )',
    'Pet::Billing::Potential Premium Increase Follow-up ( New Jersey / NJ Only )',
    'Pet::Billing::Rate Increase Talking Points ( Phones )',
    'Pet::Billing::Why Did I Get a Refund for My Policy? ( Benji Bug )',
    'Pet::Billing::Will All the Payments Be Taken Out on the Same Day?',
    'Pet::Blog Posts::Demystifying Pet Insurance Coverages',
    'Pet::Blog Posts::Good for your pet. Good for the world. [ Giveback ]',
    'Pet::Blog Posts::How Much Does a Vet Visit Cost?',
    'Pet::Blog Posts::How Much Does it Cost to Spay or Neuter a Cat?',
    'Pet::Blog Posts::How to Buy Lemonade Pet Insurance [ Finalize Policy ]',
    'Pet::Blog Posts::How to File a Pet Insurance Claim',
    'Pet::Blog Posts::Lemonade Pet Renewals',
    "Pet::Blog Posts::Lemonade's Preventative Care Options, Explained",
    "Pet::Blog Posts::Lemonade's Preventative Package for Puppies and Kittens, Explained",
    'Pet::Blog Posts::Pre-Existing Condition',
    'Pet::Blog Posts::SOAP Notes',
    'Pet::Blog Posts::The Ultimate Lemonade Pet FAQ',
    'Pet::Blog Posts::Ultimate Guide to Pet Insurance',
    'Pet::Blog Posts::Waiting Periods',
    'Pet::Blog Posts::What Pet Insurance Does and Doesn’t Cover',
    'Pet::Blog Posts::What to Do After Getting Your Pet Insurance Policy',
    'Pet::Business Basics::Are You Available in My State? [ Other State / Launch / Expansion ]',
    'Pet::Business Basics::Can I Choose a Pet Related Giveback Cause or Charity?',
    'Pet::Business Basics::Connect With a Vet Video Chat ( Lemonade-direct PH )',
    'Pet::Business Basics::Connect With a Vet Video Chat (Chewy PH )',
    'Pet::Business Basics::Delayed Response Apology [ Late Reply ]',
    'Pet::Business Basics::I Only Purchased  a Preventative Care Package for the Medical Advice Chat',
    'Pet::Business Basics::Spanish Speaker',
    "Pet::Business Basics::Why Is Lemonade's BBB Rating So Low? [ Bad ]",
    'Pet::Chewy::Can I Downgrade My Policy? ( No )',
    'Pet::Chewy::Can I Downgrade My Policy? ( Yes )',
    'Pet::Chewy::Can I Have A Physical Copy of the Privacy Pledge or Terms of Service?',
    'Pet::Chewy::Can I Upgrade My Policy? ( No ) ( Step 1 ) ( General Response )',
    'Pet::Chewy::Can I Upgrade My Policy? ( No ) ( Step 2 ) ( Pushback Response )',
    'Pet::Chewy::Chewy Contact Information',
    'Pet::Chewy::Connect With a Vet ( Chewy-Lemonade PH )',
    'Pet::Chewy::Connect With a Vet ( Lemonade-direct PH )',
    'Pet::Chewy::Hide from view',
    'Pet::Chewy::How to Add Another Pet',
    'Pet::Chewy::Re-route a Customer to Chewy',
    'Pet::Claims:: New Invoice/Additional Invoice',
    'Pet::Claims:: No  Physical Therapy',
    'Pet::Claims:: No Behavioral Add On',
    'Pet::Claims::Can I Have A Blank Claim Form For My Vet?',
    'Pet::Claims::Claim Is Pending [ Checking on Status / Claim Update ]',
    'Pet::Claims::Claiming Medication from an Online Retailer [ Chewy / Amazon ]',
    'Pet::Claims::Claims Status Notes',
    'Pet::Claims::How Long Do I Have to File a Claim?',
    'Pet::Claims::How Long Will Reimbursement Take? ( After Filing Claim ) ( Claim Status )',
    'Pet::Claims::Lack of Information (Closure)',
    'Pet::Claims::Manual Claim Questions ( Post Answers in Comms Timeline of Claim )',
    'Pet::Claims::My Pet Is Hurt or Sick [ Emergency ]',
    'Pet::Claims::My Pet Might Be Sick or Hurt ( Not Emergency )',
    'Pet::Claims::No Exam',
    'Pet::Claims::Pet Manual Claim Inquiry (After App Attempt)',
    'Pet::Claims::Policy Not In Effect',
    'Pet::Claims::Transfer Checklist',
    'Pet::Claims::Waiting Period Denial',
    'Pet::Coverage::Can I Add a Preventative Package After the 14 Day Window? ( No )',
    'Pet::Coverage::Do I Have to Take My Pet to the Vet Every Year?',
    'Pet::Coverage::Do You Cover Bilateral Conditions? [ ACL / Cherry Eye / Etc. ]',
    'Pet::Coverage::Do You Cover Boarding?',
    'Pet::Coverage::Do You Cover Emergencies or Emergency Vet Visits?',
    'Pet::Coverage::Do You Cover End of Life Costs? ( YES ) ( End of Life Package ) [ EOL ]',
    'Pet::Coverage::Do You Cover Gastropexy? [ Stomach Tacking / Stomach Stapling / Bloat ]',
    'Pet::Coverage::Do You Cover Hybrids? [ Wolfdogs / Savannah Cat ]',
    'Pet::Coverage::Do You Cover if My Pet Hurts Someone Else? ( Pet Liability )',
    'Pet::Coverage::Do You Cover Spay / Neuter or Microchipping? ( YES ) ( Pre-purchase ) ( Pets Under 2 )',
    'Pet::Coverage::Do You Cover Titer Tests? ( Vaccine )',
    'Pet::Coverage::Do You Cover Virtual or Online Vet Visits? ( YES ) ( PH Has Visit Fees Package )',
    'Pet::Coverage::Do You Insure Working Dogs? [ Service Animal / Therapy Animal / Emotional Support Animal ]',
    'Pet::Coverage::Do You Offer Coverage For Dog Walkers or Care Providers ( Not Co-owner )',
    'Pet::Coverage::I Have A Foster Pet',
    'Pet::Coverage::Medical Records Review Talking Points ( Phones )',
    'Pet::Coverage::Preventative Care Limit Pushback',
    'Pet::Coverage::Preventative Care Options and Limits',
    'Pet::Coverage::Preventative Care Packages Talking Points ( Phones )',
    'Pet::Coverage::Upgrade Denial Phone Talking Points',
    'Pet::Coverage::What Are the Differences Between the Preventative Packages? ( Pre-purchase )',
    'Pet::Coverage::What Behaviorist / Consultant Can I Go To? ( Has Behavioral Conditions Add-on ) [ CAAB , CDBC , CBCC ]',
    'Pet::Coverage::What’s a Cruciate Ligament Event?',
    'Pet::Coverage::Which Preventative Medications Are Covered? [ Flea , Tick , Heartworm ]',
    'Pet::Onboarding::Can I Add a Preventative Care Package? ( Step 1 )',
    'Pet::Onboarding::Can I Add a Preventative Care Package? ( Step 2 )',
    'Pet::Onboarding::Can I Sign Up for a Policy Before I Get My Pet?',
    'Pet::Onboarding::Dental Illness Not Offered During Sign-up ( Within 14 days )',
    'Pet::Onboarding::How Can I Waive My Waiting Periods? ( Eligible ) [ Cian only ]',
    'Pet::Onboarding::I Already Purchased a Policy ( Received Quote Reminder )',
    'Pet::Onboarding::I’m a Vet, Can I Get a Discount?',
    "Pet::Onboarding::I've Already Submitted My Pet's Records, Why Are You Asking Me For Them Again? (December 2022 Bug)",
    'Pet::Onboarding::Is My Policy Active? ( Post-Purchase ) [ Waiting Periods / Medical Records ][ Cian only ] ',
    'Pet::Onboarding::No Medical Records on File',
    'Pet::Onboarding::Quote Higher Than $12 [ More Expensive / Advertised / Advertisement ]',
    'Pet::Onboarding::Speak to a Human [ Email Help ]',
    'Pet::Onboarding::Switch to Lemonade',
    'Pet::Onboarding::Vet Records Received Cannot Locate Policy, Outbound Request [ Outbound / Vet Records ]',
    'Pet::Onboarding::Waive Waiting Periods [ Currently Insured ]',
    'Pet::Onboarding::Wants to Change Quote Coverage (A/B tests)(Not Available on Quote) (Step 1 /General explanation)',
    'Pet::Onboarding::Wants to Change Quote Coverage (A/B tests)(Not Available on Quote) (Step 2)',
    'Pet::Onboarding::We Got Your Medical Records ( Vet Sent Records With SOAP Notes )',
    'Pet::Onboarding::What Breed Should I Choose? ( Designer Breed / Dog )',
    'Pet::Onboarding::What Breed Should I Choose? ( Mixed Breed )',
    'Pet::Onboarding::What Breed Should I Choose? ( Rare Breed )',
    'Pet::Onboarding::What Breed Should I Choose? (FL) [ Florida , mixed breed ]',
    'Pet::Onboarding::What if I Sign Up Just to Use Preventative Care Then Cancel?',
    'Pet::Onboarding::Where to Find a Sample Policy',
    'Pet::Onboarding::Why Do You Need My Address and Birthdate?',
    'Pet::Onboarding::Why Should I Choose Lemonade Instead of Another Company? [ Competitor Inquiry / Quote Matching ]',
    'Pet::Onboarding::Wrong Policy Document Attached ( March 2023 )',
    'Pet::Pre-approvals::Can I Request a Pre-approval for Preventative Care Costs?',
    'Pet::Pre-Approvals::Collect Required Information ( TTEC )',
    'Pet::Pre-Approvals::How Do I Claim for a Pre-approved Treatment? ( TTEC )',
    'Pet::Pre-Approvals::How to Request a Pre-approval ( TTEC )',
    "Pet::Pre-Approvals::I Don't Have an Estimate From My Vet",
    'Pet::Pre-Approvals::Manual Pre-approval Call ( TTEC )',
    'Pet::Pre-Approvals::Pre-approval Request ( Triage to CLX Pet ) ( Step Two )',
    'Pet::Pre-Approvals::Waiting Periods Haven’t Ended ( TTEC )',
    'Pet::Pre-Approvals::We Still Need Your Estimate ( Follow-up ) ( TTEC )',
    'Pet::Pre-Approvals::Denied or Sent Back from TTEC',
    'Pet::Renewals::CO Renewal Bug ( Renewal Already Activated ) ( Multiple Pets )',
    'Pet::Renewals::CO Renewal Bug ( Renewal Already Activated ) ( Single Pet )',
    'Pet::Renewals::CO Renewal Bug ( Renewal Not Activated, But Less Than 30 Day Notice ) ( Multiple Pets )',
    'Pet::Renewals::CO Renewal Bug ( Renewal Not Activated, But Less Than 30 Day Notice ) ( Single Pet )',
    'Pet::Renewals::Has Puppy/Kitten Preventative Package Requesting Adjustment [RENEWAL AVAILABLE]',
    'Pet::Renewals::Premium / Rate Increase (Template)',
    'Pet::Tag::Duplicate Medical Records Received [ NRN ]',
    'Pet::Tag::Outbound Customer Care Request',
    'Pet::Tag::Survey Response Intro',
    'Pet::Tag::Trupanion Comparison Misinformation',
    'Pet::Underwriting::Multiple Pets on One Policy ( PH Has Policies For Each Pet )',
    'Pet::Underwriting::Multiple Pets on One Policy ( Step 1 )',
    'Pet::Underwriting::Multiple Pets on One Policy ( Step 2 )',
    'Pet::Underwriting::Multiple Pets on One Policy ( Step 3 ) ( Underwriting Team Only )',
    'Pet::Underwriting::Response to Form Errors Email [Typos] ( WA, IL, NY, MI, OR, NJ, TX and CA )( General Response ) ',
    'Pet::Underwriting::Response to Form Errors Email [Typos] ( WA, IL, NY, MI, OR, NJ, TX and CA )( Specific Outline Request ) ( Step 1 )',
    'Pet::Underwriting::Response to Form Errors Email [Typos] ( WA, IL, NY, MI, OR, NJ, TX and CA )( Specific Outline Request ) ( Step 2 )',
    'Pet::Underwriting::Response to Quote Expiration Email ( Coverage Confusion ) ( PH Has Active Policy )',
    'Pet::Underwriting::Triage Pet Medical Records',
    'Phone Team::APX Script [Fwd to APX]',
    'Phone Team::Notes Template [ Call Notes]',
    'Renters::Account Management::Why Does My Policy Say Homeowners On It?',
    'Renters::Billing::Payment Plan Options ( FL )',
    'Renters::Blog Posts::Everything You Need to Know About Renters Insurance',
    'Renters::Blog Posts::Five Things',
    'Renters::Blog Posts::How Much Insurance Do I Need?',
    'Renters::Blog Posts::Loss of Use Coverage',
    'Renters::Blog Posts::Named Perils',
    'Renters::Blog Posts::Personal Liability Coverage',
    'Renters::Blog Posts::Personal Property',
    'Renters::Blog Posts::Replacement Cost / ACV',
    'Renters::Blog Posts::Theft Coverage',
    'Renters::Blog Posts::What Does This Cover?',
    'Renters::Blog Posts::What to do After You Get Insured',
    "Renters::Business Basics::Can I Purchase Insurance if I'm Not On The Lease? [ Family / Parents / Mom / Dad ]",
    "Renters::Business Basics::Can I Receive Updates on my Child's Policy? ( Add Parent as IP )",
    'Renters::Business Basics::Do You Do a Credit Score Check? ( FL )',
    'Renters::Business Basics::Is My Significant Other Considered my Roommate?',
    "Renters::Business Basics::Parent's Email is on File but Other Info Looks Right",
    'Renters::Business Basics::Parents Say Email is Shared With Child',
    'Renters::Business Basics::Parents Set Up Policy For Child ( Step 2 )',
    'Renters::Business Basics::Pushback From Parents ( But I Pay For It!! )',
    'Renters::Business Basics::What Is a Deductible? ( CA & VA )',
    "Renters::Business Basics::Why Is Lemonade's BBB Rating So Low? [ Bad ]",
    'Renters::Claims::Can I File a Claim For A Loss That Occurred Before I Canceled My Policy?',
    'Renters::Claims::Can I File a Claim for Sinkhole Damage?',
    'Renters::Claims::Can I File a Claim For Something That Happened After My Policy Canceled Due To Nonpayment?',
    'Renters::Claims::How Do I File a Loss Of Use Claim ( No Damaged Items )',
    'Renters::Claims::How Do I File a Loss Of Use Claim? ( Event Typically Not Covered )',
    'Renters::Claims::Transfer Checklist',
    'Renters::Coverage::Can I Add the Tenant Pet Damage Endorsement if My Dog Is a Restricted Breed? [ Carpet / Scratches ]',
    'Renters::Coverage::Can I Exclude Wind and Hail Coverage?  ( FL )',
    'Renters::Coverage::Can You Sign This Liability Form? ( NO )',
    'Renters::Coverage::Can You Sign This Liability Form? ( YES )',
    'Renters::Coverage::Do You Cover Furnished Apartments?',
    'Renters::Coverage::Do You Cover Rented Items? [ CORT ]',
    'Renters::Coverage::Do You Cover Sinkholes? ( FL )',
    "Renters::Coverage::Do You Cover Water Damage to my Landlord's Property? ( Check Blender ) ( NO )",
    "Renters::Coverage::Do You Cover Water Damage to my Landlord's Property? ( Check Blender ) ( YES )",
    "Renters::Coverage::Do You Cover Water Damage to my Landlord's Property? ( Pre-Purchase )",
    'Renters::Coverage::Do You Cover Windstorms? ( FL ) [ Hail ]',
    'Renters::Coverage::Do You Covered Fallen Trees or Branches?',
    'Renters::Coverage::Is Equipment Breakdown Covered? ( NO ) (AR / AZ / CT / GA / IL / IN / MI / NJ / NM / OH / OK / OR / PA / RI / TN / VA / WI )',
    'Renters::Coverage::Is Equipment Breakdown Covered? ( Not Yet )',
    'Renters::Coverage::Is My Garage Covered?',
    'Renters::Coverage::Is My Pool Covered? ( FL )',
    'Renters::Coverage::Is My Pool Covered? ( NO )',
    'Renters::Coverage::Is My Trampoline Covered? ( VA )',
    'Renters::Coverage::Is This Aggregate or Per Occurrence?',
    'Renters::Coverage::Mold ( FL )',
    'Renters::Coverage::Mold ( GA ) [ Fungus / Fungi / Mildew / Rot / Bacteria ]',
    'Renters::Coverage::Pet Coverage ( FL )',
    'Renters::Coverage::Secured Building Discount ( FL ) [ Doorman ]',
    'Renters::Coverage::What are the Landlord Property Damage Endorsements?',
    'Renters::Coverage::What is Building Code Coverage? Can I add it?',
    'Renters::Coverage::What is the Deductible for Equipment Breakdown?',
    'Renters::Coverage::What’s the difference between Sinkhole and Catastrophic Ground Collapse? ( FL )',
    'Renters::Coverage::Why are Wind and Hail Excluded? ( FL )',
    'Renters::Coverage::Why Is Water Backup More Expensive For The First Floor?',
    'Renters::Earthquake::Do You Offer EQ Coverage? ( CA )',
    'Renters::Onboarding::Can I get a Quote Without an SSN?',
    'Renters::Onboarding::Difference Between Interested Party and Additional Insured ( Pre-Purchase )',
    'Renters::Onboarding::How Far In Advance Can I Create my Policy?',
    'Renters::Onboarding::Pending Policy',
    'Renters::Policy Changes and Info::Add Significant Other ( FL )',
    'Renters::Policy Changes and Info::How Do I Add a Cosigner?',
    'Renters::Policy Changes and Info::How Do I Edit my Interested Party?',
    "Renters::Policy Changes and Info::Landlord Doesn't Accept Lemonade",
    'Renters::Policy Changes and Info::Moving ( Effective Immediately )',
    'Renters::Policy Changes and Info::Moving Pushback ( Wants You to Edit the Unit Number )',
    'Renters::Policy Changes and Info::Waiver of Subrogation',
    'Renters::Policy Changes and Info::Why Does My Policy Start in 7 Days? ( Move In , Confirm No Loss ) [ moving , get keys ]',
    'Renters::Policy Changes and Info::Why Does My Policy Start in 7 Days? ( Not Eligible to Expedite )',
    'Renters::Policy Changes and Info::Your Landlord Says Your Address is Wrong - Outbound',
    'Tagging::Brokerinbox8',
    'Tagging::Bug Report',
    'Tagging::Call Follow-up Email',
    'Tagging::Misdirected Phone Call',
    'Tagging::No Macro',
    'Tagging::Outbound',
    'Tagging::Phone Number ( Over 45 )',
    'Tagging::SMS Misdirected Communication',
    'Tagging::Greeting::Great to Hear From You',
    'Tagging::Greeting::Hey',
    'Tagging::Greeting::Hi',
    'Tagging::Greeting::Maya Unable to Help',
    'Tagging::Greeting::Mondays',
    'Tagging::Greeting::Ticket Takeover',
    "Tagging::Greetings::I'm from Lemonade",
    'Tagging::Greetings::Late Reply Apology',
    'Tagging::Link to quote flow::Car and Pet',
    'Tagging::Link to quote flow::Car and Renters',
    'Tagging::Link to quote flow::Car, Renters, and Pet',
    'Tagging::Link to quote flow::Car, Renters, and Pet',
    'Tagging::Link to quote flow::Home and Car',
    'Tagging::Link to quote flow::Home and Pet',
    'Tagging::Link to quote flow::Home, Car, and Pet',
    'Tagging::Link to quote flow::Renters and Pet',
    'Tagging::Sign Off::Kind Regards',
    'Tagging::Sign Off::Thank you',
    'Tagging::Sign Off::Thanks',
    "Tagging::Sign Off::You're Welcome",
    'Template::Greeting::HO Unlicensed Follow Up',
    'Triage Outbound Car Mail to Mailroom',
    'Triage Outbound HOOP Mail to Mailroom',
    'Triage Outbound Pet-Mail to Mailroom',
    'Triage Outbound Property Mail to Mailroom',
    'Triage::APX ( From HO and Ren)',
    'Triage::Bestow CX Life: 1/2 NOTATE [ Life Policy, CX US-Life Bestow , triage life ]',
    'Triage::Bestow CX Life: 2/2 ASSIGN (ONLY FOR DV_IDENTITY)[ Life Policy, CX US-Life Bestow , triage life ]',
    'Triage::Bestow CX Life: 2/2 ASSIGN [ Life Policy, CX US-Life Bestow , triage life ]',
    'Triage::Claims Ticket Assignee',
    'Triage::CLX Car',
    'Triage::CLX Home',
    'Triage::CLX Pet',
    'Triage::CLX TTEC (File a claim) ::Temps',
    'Triage::CLX TTEC (File a claim) (SMS) ::Temps',
    'Triage::CLX TTEC Champs ::Request call',
    'Triage::CLX TTEC to CX US Triage',
    'Triage::CX Car',
    'Triage::CX EU',
    'Triage::CX HO',
    'Triage::CX HO to HOOPs',
    'Triage::CX Licensed Homeowners',
    'Triage::CX Licensed Renters',
    'Triage::CX Life [ Life Policy, CX US-Life , triage life ]',
    'Triage::CX Pet',
    'Triage::CX Pet Chewy',
    'Triage::CX Renters',
    'Triage::Hide from Records View CX Pet',
    'Triage::Homeowners Coverage Change Request ( Licensed Queue )',
    'Triage::HOOPs to CX HO',
    'Triage::Lemonade CX Life [ Life Policy, CX US-Life Lemonade , triage life ]',
    'Triage::Life to CX Renters [ Renting , CX US, triage renters ]',
    'Triage::Mailroom to HOOPs',
    'Triage::Petdocs@ ::CLX Pet',
    'Triage::Renters Coverage Change Request ( Licensed Queue )',
    'Triage::SMS to TTEC',
    'Triage::Underwriting Car',
    'Triage::Underwriting Home',
    'Triage::UW - HO CTB Renewal Review',
    'Triage::Verified Life Request [ Life Policy, CX US-Life , triage Life + Visibility ]',
    'Triage::CLX TTEC (Instant / Reopening Unassigned Claim )::Temps',
    'Triage::Escalations::Customer Behavior',
    'Triage::Escalations::Extra Support',
    'Triage::Escalations::No Resolution',
    'Triage::Escalations::Repeat Contacts',
    'Underwriting Team::UW Cancellation -- not primary residence [Notice]',
    'Underwriting Team::Business at Home UW Cancellation [Notice]',
    'Underwriting Team::First Message General [verify details , cancellation process ]',
    'Underwriting Team::Fraud UW Cancellation [Notice]',
    'Underwriting Team::Future Policy Cancellation [Notice]',
    'Underwriting Team::General',
    'Underwriting Team::Non-Renewal Notice',
    'Underwriting Team::UW Cancellation -- General [Notice]',
    'Underwriting Team::UW Cancellation -- Home in Disrepair [Notice]',
    'Underwriting Team::UW Cancellation -- Mobile Home [Notice]',
    'Underwriting Team::UW Cancellation -- Non Payment [Notice] (copy)',
    'Underwriting Team::UW Cancellation — Customer History [ Notice ] ( Copy )',
    'Underwriting Team::UW Cancellation 2nd Step -- (Day of Cancelation) [notice of cancellation, policy cancelled]',
    'Underwriting Team::UW Non-Renew -- General [Notice] (Copy)',
    'Underwriting Team::WildFire Cancellations',
    'Underwriting::Home Product::Are You Going to Cancel My Policy? [ Post Purchase ]',
    'Underwriting::Home Product::Are You Going to Cancel My Policy? [ Pre Purchase ]',
    'Underwriting::Home Product::Decline - California Wildfire',
    'Underwriting::Home Product::Decline - Claims History',
    'Underwriting::Home Product::Decline - Dog With Attack / Bite History',
    'Underwriting::Home Product::Decline - Dwelling Max Over $750,000  ( TX, COASTAL WINDSTORM ) [ UW ] ',
    'Underwriting::Home Product::Decline - Swimming Pool Fence',
    'Underwriting::Home Product::Decline - Trampoline',
    'Underwriting::Home Product::Do You Cover Wildfire / Tornado / Hurricane ( Ongoing Catastrophe - Policy < 3 Days Old )',
    'Underwriting::Home Product::Quote Decline - No Fire / Smoke Alarm',
    'Underwriting::Home Product::Why is the Fire Department Rating So Low ( Star Ratings )',
    'Underwriting::Homeowners::Decline - Property Portfolio Limit',
    'Underwriting::UW Non-Renewal Follow Up::General Non-Renewal Response [ Renewal , Underwriting Decline , Cancel , Review ]',
    'Underwriting::UW Non-Renewal Follow Up::Wildfire (Fireline & Special Hazardous Interface Area) [ fire, SHIA, area ]',
    'Underwriting::UW Non-Renewal: Disrepair Non-Renewal Outreach',
    'Underwriting::UW Non-Renewal: Non-Renewal Outreach: General',
    'Underwriting::UW Non-Renewal: Roof Age Non-Renewal Outreach',
    'Underwriting::UW Non-Renewal: Roof Type Non-Renewal Outreach',
    'Underwriting::UW Non-Renewal: Texas Claims Notice',
    'Underwriting::UW Outreach::Business Policies Check',
    'Underwriting::UW Outreach::Escalations Outreach',
    'Underwriting::UW Outreach::Multiple Policies Check Channel',
    'Underwriting::UW Outreach::RRAP Check',
    'Underwriting::Block Review::No Outreach',
    'Underwriting::CA Claims Review -- Request Details For Claims History [CA, claim check]',
    'Underwriting::CA Connected User Identity Check',
    'Underwriting::CA Connected User Identity Check Follow-Up',
    'Underwriting::CA Replacement Cost Review at Renewal [RCE, California, nonrenewal, review at renewal]',
    'Underwriting::Cancellations::Risk Network [ blocked , cancelled , suspicious claims , compliance approved ]',
    'Underwriting::Clean the Book::Closing Message Informal Non-Renewal',
    'Underwriting::Clean the Book::Closing Message No Response - 30 day mark [nonrenewal, renewal review, last follow up]',
    'Underwriting::Clean the Book::Closing Message No Response - 30 day mark AGENTS ONLY [nonrenewal, renewal review, last follow up]',
    'Underwriting::Clean the Book::Closing Message Retaining and Updating Policy [renewal review, final message]',
    'Underwriting::Clean the Book::Follow Up [fourth touch, nonresponse, nonrenewal, renewal review, follow up]',
    'Underwriting::Clean the Book::Follow Up [third touch, nonrenewal, renewal review, follow up]',
    'Underwriting::Clean the Book::Follow Up AGENTS ONLY [fourth touch, nonresponse, nonrenewal, renewal review, follow up] (copy)',
    'Underwriting::Clean the Book::Follow Up AGENTS ONLY [third touch, nonrenewal, renewal review, follow up] (copy)',
    'Underwriting::Clean the Book::Follow-up [ Second Touch, Nonresponse, Nonrenewal, Renewal Review]',
    'Underwriting::Clean the Book::Follow-up AGENTS ONLY [ Second Touch, Nonresponse, Nonrenewal, Renewal Review]',
    'Underwriting::Clean the Book::Future Eligibility',
    'Underwriting::Clean the Book::Informal NR (Failure to Provide)',
    'Underwriting::Clean the Book::Internal Notes',
    'Underwriting::Clean the Book::Micro: Credits',
    'Underwriting::Clean the Book::Micro: Electrical',
    'Underwriting::Clean the Book::Micro: Photos',
    'Underwriting::Clean the Book::Micro: Plumbing',
    'Underwriting::Clean the Book::Micro: Quality Grade',
    'Underwriting::Clean the Book::Micro: Retrofitting (CA 1945 or older)',
    'Underwriting::Clean the Book::Micro: Roof Age Beyond Lifespan',
    'Underwriting::Clean the Book::Micro: Roof Age Unknown/Younger',
    'Underwriting::Clean the Book::Micro: Square Footage',
    'Underwriting::Clean the Book::Micro: Water Heater',
    'Underwriting::Clean the Book::Micro: Year Built',
    'Underwriting::Clean the Book::Micro:Prior Claims',
    'Underwriting::Clean the Book::Micro:Tree Proximity',
    'Underwriting::Clean the Book::Outreach [ Nonrenewal, Renewal Review]',
    'Underwriting::Clean the Book::Outreach [All-in-One]',
    'Underwriting::Clean the Book::Outreach AGENTS ONLY[ Nonrenewal, Renewal Review]',
    'Underwriting::Clean the Book::Outreach Quality Grade',
    'Underwriting::Clean the Book::Pushback (general)',
    'Underwriting::Clean the Book::Pushback after NR',
    'Underwriting::Clean the Book::Rescind NR',
    'Underwriting::Clean the Book::Soft Touch Micro [nonrenewal, renewal review]',
    'Underwriting::General::Decline - General Denial Statement',
    'Underwriting::Home Product::Confused About Prior Claims History',
    'Underwriting::Home Product::Quote Decline - Secondary / Vacation Home',
    'Underwriting::Homeowners::Decline - Business Property',
    'Underwriting::Homeowners::Decline - Cast Iron Galvanized Plumbing',
    'Underwriting::Homeowners::Decline - Disrepair',
    'Underwriting::Homeowners::Decline - HO3 Flat Roof ( PA / IL / GA / MA / CT / TN / OR / MO / OH / IN / WI / AR / RI / CO / TX )',
    'Underwriting::Homeowners::Decline - House Too Expensive',
    'Underwriting::Homeowners::Decline - Insufficient Information',
    'Underwriting::Homeowners::Decline - Low MV/RC',
    'Underwriting::Homeowners::Decline - Minimum Dwelling',
    'Underwriting::Homeowners::Decline - Multi Family Home ( MI )',
    'Underwriting::Homeowners::Decline - No Mortgage on House ( New Customer )',
    'Underwriting::Homeowners::Decline - Oil Tank ( NJ )',
    'Underwriting::Homeowners::Decline - Private Lender',
    'Underwriting::Homeowners::Decline - Rental Property / Owner-Occupied Homes',
    'Underwriting::Homeowners::California Blocked User/Address Outreach [CA, policy offer]',
    'Underwriting::Homeowners::California UW Cancellation [CA cancel] [8/15/2023]',
    'Underwriting::ID Check::No Outreach',
    'Underwriting::Inspection Report Quote Review::Additional Questions About Report [inspection, in-review, HIR, repairs]',
    'Underwriting::Inspection Report Quote Review::Declining Quote [inspection, in-review, HIR, decline]',
    'Underwriting::Inspection Report Quote Review::Declining Quote For Disrepair [disrepair, in-review, HIR, decline]',
    'Underwriting::Inspection Report Quote Review::Report Upload Failed [inspection, in-review, HIR]',
    'Underwriting::Inspection Report Review::Policy Activated [HIR, in-review, UW review, accepted, activated]',
    'Underwriting::ISO Replacement Cost Review -- Approved [ISO, replacement cost change, UW review]',
    'Underwriting::ISO Replacement Cost Review -- Bug, Amount Approved [bindable quote, accepted, approved]',
    'Underwriting::ISO Replacement Cost Review -- Outreach 1 [ISO, replacement cost change, UW review]',
    'Underwriting::ISO Replacement Cost Review -- Outreach 2 [ISO, replacement cost change, more details]',
    'Underwriting::ISO Replacement Cost Review -- Policy Activated [ISO, replacement cost change, UW review, accepted, activated]',
    'Underwriting::ISO Replacement Cost Review -- Reject Requested Amount',
    'Underwriting::Mobile Homes::No Outreach',
    'Underwriting::Multiple Policies::No Outreach',
    'Underwriting::Other In-Review Quote -- Outreach 1 [UW review, answered other, quote review]',
    'Underwriting::Pet::Medical Record Review - Multiple Pets ( First Touch )',
    'Underwriting::Pet::Medical Record Review - Single Pet ( First Touch ) V2',
    'Underwriting::Pet::Medical Record Review ( Final Reminder )',
    'Underwriting::Pet::Medical Record Review ( Incomplete Notice )',
    'Underwriting::Pet::Medical Record Review ( Reminder )',
    'Underwriting::Policy Alerts::1st Follow-up',
    'Underwriting::Policy Alerts::2nd Follow-up',
    'Underwriting::Policy Alerts::Apartment Number',
    'Underwriting::Policy Alerts::Business Check',
    'Underwriting::Policy Alerts::Campus Housing Check',
    'Underwriting::Policy Alerts::Mobile Home Check',
    'Underwriting::Policy Alerts::Multi-family Check',
    'Underwriting::Policy Alerts::No response',
    'Underwriting::Post-Issuance Review',
    'Underwriting::UW Cancellation -- Disrepair Follow Up (underwriting, follow-up)',
    'Underwriting::UW Cancellation::Non-renewal Reinstatement Offer',
    'Underwriting::UW Cancellation::Policy Set To Non-Renew [ renewal , underwriting decline , cancel , review ]',
    'Underwriting::UW Home Review -- 2 Primary Residences [multiple policies, primary, secondary]',
    'Underwriting::UW Home Review -- Business At Home [business use, confirm residential]',
    'Underwriting::UW Home Review -- Cancel Follow Up [second chance, no reply]',
    'Underwriting::UW Home Review -- Claim Check [claim details, confirm repairs]',
    'Underwriting::UW Home Review -- Closing Message [final reply, closer]',
    'Underwriting::UW Home Review -- Confirm Renovation [first contact, renovation age]',
    'Underwriting::UW Home Review -- Declined Return -- High Risk Dog [denied, quote declined, returned]',
    'Underwriting::UW Home Review -- Declined Return -- Homeowners Rental Property [denied, quote declined, returned]',
    'Underwriting::UW Home Review -- Declined Return -- Homeowners Without Mortgage [denied, quote declined, returned]',
    'Underwriting::UW Home Review -- Declined Return -- Insurance Policy Canceled [denied, quote declined, returned]',
    'Underwriting::UW Home Review -- Declined Return -- Short Term Rental Property [denied, quote declined, returned]',
    'Underwriting::UW Home Review -- Declined Return -- Subletting House [denied, quote declined, returned]',
    'Underwriting::UW Home Review -- Declined Return -- Unregulated Renovation [denied, quote declined, returned]',
    'Underwriting::UW Home Review -- Explanation of UW Process [customer concern, explain]',
    'Underwriting::UW Home Review -- Follow Up 1 -- Business At Home [second outreach, confirm residential]',
    'Underwriting::UW Home Review -- Follow Up 1 -- Claim Check [second outreach, claim details, confirm repairs]',
    'Underwriting::UW Home Review -- Follow Up 1 -- Confirm Renovation [second outreach, renovation age]',
    'Underwriting::UW Home Review -- Follow Up 1 -- General [second outreach, generic]',
    'Underwriting::UW Home Review -- Follow Up 1 -- General Condo/Co-op [second outreach, generic, condo/co-op]',
    'Underwriting::UW Home Review -- Follow Up 1 -- Primary Residence [second outreach, confirm primary, rental check, for sale]',
    'Underwriting::UW Home Review -- Follow Up 1 -- Unit Number [second request, request unit, confirm address]',
    'Underwriting::UW Home Review -- Follow Up 2 -- Business At Home [final outreach, possible cancel]',
    'Underwriting::UW Home Review -- Follow Up 2 -- Claim Check [final outreach]',
    'Underwriting::UW Home Review -- Follow Up 2 -- Confirm Renovation [final outreach, possible credit removal]',
    'Underwriting::UW Home Review -- Follow Up 2 -- General [final outreach, possible cancel]',
    'Underwriting::UW Home Review -- Follow Up 2 -- General Condo/Co-op [final outreach, possible cancellation]',
    'Underwriting::UW Home Review -- Follow Up 2 -- Primary Residence [final outreach, rental limitations, for sale]',
    'Underwriting::UW Home Review -- Follow Up 2 -- Unit Number [final request]',
    'Underwriting::UW Home Review -- General [first contact, generic]',
    'Underwriting::UW Home Review -- General Condo/Co-op [first contact, generic]',
    'Underwriting::UW Home Review -- General for CA Claims Review [first contact, generic, CA Claim Check]',
    'Underwriting::UW Home Review -- Planned Repairs, Policy Will Be Cancelled [planned renovation, disrepair, cancellation]',
    'Underwriting::UW Home Review -- Primary Residence [confirm primary, rental check, for sale]',
    'Underwriting::UW Home Review -- QUOTE -- General [first contact, generic, pre-purchase]',
    'Underwriting::UW Home Review -- Unit Number [request unit, confirm address]',
    'Underwriting::UW Home Review::Can I Speak With A Representative? [phone , call , number , agent , rep , live , UW]',
    'Underwriting::UW Non-Renewal Follow Up::Uneven Payment History [ on time, billing, card, cancel ]',
    'Underwriting::UW Non-Renewal Follow Up::Mobile Home ( Underwriting, Follow-up, Nonrenewal, Mobile, Manufactured )',
    'Underwriting::UW Quote Review -- Cooper -- Unresponsive Follow Up [Prediction model, quote in review]',
    'Underwriting::UW Review -- Private Mortgage Cancellation Notice [private loan, UW cancel]',
    'Underwriting::Verisk Bug -- REVERT Agent',
    'Underwriting::Verisk Bug -- REVERT Customer',
    'UW Approval::UW Requests: Additional Insured',
    'UW Approval::UW Requests: Address Change',
    'UW Approval::UW Requests: Auto Declined Quote Review',
    'UW Approval::UW Requests: Block/Unblock Request',
    'UW Approval::UW Requests: Cancel Review',
    'UW Approval::UW Requests: Coverage Change Limited',
    'UW Approval::UW Requests: Documents for Review',
    'UW Approval::UW Requests: Extra Coverage: Additional Documentation Submission',
    'UW Approval::UW Requests: Extra Coverage: Denial Inquiry',
    'UW Approval::UW Requests: Extra Coverage: Expedite Review Request',
    'UW Approval::UW Requests: Extra Coverage: Item eligibility question',
    'UW Approval::UW Requests: Extra Coverage: Item value change request',
    'UW Approval::UW Requests: Extra Coverage: Moving/Transferring an item',
    'UW Approval::UW Requests: New Business Review',
    'UW Approval::UW Requests: Triage Instructions for New Business Request',
    'UW Approval::UW Requests: User Disagrees with Declined Reasoning',
    'UW Approval::UW Requests: UW Request Approval',
    'UW Approval::UW Requests: UW Request Denied',
    'UW Approval::UW Requests: UW Request Received',
    'UW Approval::UW Requests: UW Review Request (AirBnb, mobile home, private mortgage, renovations)',
];
