import { getCookie } from '@lemonade-hq/cdk';

import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';

import type { EnvName, ServiceName } from '../config';
import { getEnv, serviceUrls } from '../config';
import { getIsDevKubernetes } from '../config/kubernetes';
import { getBlenderDomain, getEnvId } from './getEnvParameters';
import type { ResponseData } from './shouldAuthenticate';
import { shouldAuthenticate } from './shouldAuthenticate';
import { shouldFallbackToMaster } from './shouldFallbackToMaster';

// eslint-disable-next-line functional/prefer-readonly-type
export const clientCache: { [service in ServiceName]?: AxiosInstance } = {};

export function getAxiosClient(service: ServiceName, env: EnvName = getEnv()): AxiosInstance {
  const cachedClient = clientCache[service];
  if (cachedClient != null) return cachedClient;

  const serviceUrl = serviceUrls(env)[service];

  const resolvedEnv = getEnvId(env, getIsDevKubernetes());
  const blenderDomain = getBlenderDomain(env);
  let resolvedUrl = serviceUrl.replace('{{BLENDER_DOMAIN}}', blenderDomain);
  if (resolvedEnv !== null) {
    resolvedUrl = resolvedUrl.replace('{{ENV_ID}}', resolvedEnv);
  }

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const csrfToken = getCookie('_lmnd_blender_csrf_auth');

  const client = axios.create({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    baseURL: resolvedUrl,
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // we want to set this explicitly to undefined, not null
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      timezone: timeZone ?? undefined,
      ...(csrfToken != null ? { 'x-lmnd-blender-csrf-auth': csrfToken } : {}),
    },
    timeout: 30_000,
    withCredentials: true,
  });

  client.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers['X-LEMONADE-PAGE'] = window.location.pathname;
    }

    return config;
  });

  client.interceptors.response.use(undefined, (err: AxiosError<ResponseData>) => {
    if (shouldAuthenticate(err)) {
      const currentPath = encodeURIComponent(window.location.pathname);
      window.location.href = `/operators/sign_in?skip_page=true&return_to_path=${currentPath}`;
      return;
    }

    if (shouldFallbackToMaster(env, err)) {
      const masterUrl = serviceUrl.replace('{{BLENDER_DOMAIN}}', blenderDomain).replace('{{ENV_ID}}', 'master');
      client.defaults.baseURL = masterUrl;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return { ...err.config, baseURL: masterUrl };
    }

    throw err;
  });

  clientCache[service] = client;
  return client;
}
