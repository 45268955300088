import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import { findFlag } from './common';
import type { FlagRuleStats } from 'models/LoCo/Insurance/ChangesLog';
import type { UnderwritingFiltersEdition } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

interface FlagRulesUpdatedProps {
    readonly baseUnderwritingFiltersEdition: UnderwritingFiltersEdition | null;
    readonly underwritingFiltersEdition: UnderwritingFiltersEdition;
    readonly ruleStats: FlagRuleStats;
}

export const FlagRulesUpdated: FC<FlagRulesUpdatedProps> = ({
    baseUnderwritingFiltersEdition,
    underwritingFiltersEdition,
    ruleStats,
}) => {
    const flag = findFlag(underwritingFiltersEdition, ruleStats.flagCode);
    const originalFlag = baseUnderwritingFiltersEdition?.flagRulesGroup.find(f => f.flagCode === ruleStats.flagCode);

    const rows: LabelValueItem[] = [];
    const addRow = (value: number, label: string): void => {
        if (value > 0) rows.push({ label, value });
    };

    addRow(ruleStats.added, 'Added');
    addRow(ruleStats.removed, 'Removed');
    addRow(ruleStats.updated, 'Edited');

    return (
        <ChangeAccordion
            generalTabData={rows}
            originalRules={originalFlag?.rules ?? null}
            ruleType={'flag'}
            title={`Flag rules updated - ${flag.flagName}`}
            updatedRules={flag.rules}
        />
    );
};
