import { Select } from '@lemonade-hq/bluis';
import { InputWrapper } from '../../../InputWrapper';
import { useManageRuleDialogContext } from '../../ManageRuleDialogContext';
import { Outcome } from './Outcome';
import { SettingAdditionalOptions } from './SettingAdditionalOptions';
import { isEnumValue } from 'components/LoCo/common/helpers/typeHelpers';
import { RuleOutcomeType, SettingDefaultValueType, Treatment } from 'models/LoCo/Insurance/CoverageRule';

export const SettingDefault: React.FC<{ readonly settingName: string }> = ({ settingName }) => {
    const {
        values: { outcome, entityType },
        dispatch,
    } = useManageRuleDialogContext();

    if (entityType == null) throw new Error('Entity type is required');

    return (
        <Outcome coverageOrSettingName={settingName} outcomeTitle={'Set Default'} ruleEntityType={entityType}>
            <InputWrapper label="Default Value">
                <Select
                    onOptionSelected={option => {
                        if (isEnumValue(SettingDefaultValueType, option.value)) {
                            dispatch({
                                type: 'outcome',
                                value: { type: RuleOutcomeType.SettingDefaultValueOutcome, value: option.value },
                            });
                        } else if (option.value === RuleOutcomeType.SettingNearestToValueOutcome) {
                            dispatch({
                                type: 'outcome',
                                value: { type: RuleOutcomeType.SettingNearestToValueOutcome, expression: '' },
                            });
                        } else {
                            dispatch({
                                type: 'outcome',
                                value: {
                                    type: RuleOutcomeType.SettingExactValuesOutcome,
                                    values: [],
                                    treatment: Treatment.Forbid,
                                },
                            });
                        }
                    }}
                    options={[
                        { id: 'lowest', label: 'Lowest Available', value: SettingDefaultValueType.Lowest },
                        { id: 'highest', label: 'Highest Available', value: SettingDefaultValueType.Highest },
                        { id: 'nearest', label: 'Nearest To', value: RuleOutcomeType.SettingNearestToValueOutcome },
                    ]}
                    value={
                        outcome?.type === RuleOutcomeType.SettingDefaultValueOutcome
                            ? outcome.value
                            : outcome?.type === RuleOutcomeType.SettingNearestToValueOutcome ||
                                outcome?.type === RuleOutcomeType.SettingExactValuesOutcome
                              ? outcome.type
                              : null
                    }
                />
            </InputWrapper>
            <SettingAdditionalOptions />
        </Outcome>
    );
};
