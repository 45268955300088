import React from 'react';
import styled from 'styled-components';
import { ThemedColors } from './Colors';

const StyledSvg = styled.svg`
    width: 14px;
    height: 14px;
    .circle {
        fill: #d9d9d9;
    }
    &:hover {
        .circle {
            fill: ${ThemedColors.primaryText};
        }
    }
`;

const ClearButtonSvg = () => (
    <StyledSvg
        height="14px"
        version="1.1"
        viewBox="0 0 14 14"
        width="14px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <title>delete</title>
        <g fill="none" fillRule="evenodd" id="Adjuster" stroke="none" strokeWidth="1">
            <g id="Unassigned-Adjuster,-No-Results" transform="translate(-1214.000000, -130.000000)">
                <g id="delete" transform="translate(1214.000000, 130.000000)">
                    <circle className="circle" cx="7" cy="7" id="Oval-3" r="7" />
                    <path
                        d="M4.32756271,4.10711593 C4.52282485,3.91185378 4.83940734,3.91185378 5.03466949,4.10711593 L5.03466949,4.10711593 L7,6.073 L8.96533051,4.10711593 C9.16059266,3.91185378 9.47717515,3.91185378 9.67243729,4.10711593 L9.89288407,4.32756271 C10.0881462,4.52282485 10.0881462,4.83940734 9.89288407,5.03466949 L7.927,7 L9.89288407,8.96533051 C10.0664504,9.13889686 10.0857356,9.40832126 9.95073952,9.6031894 L9.89288407,9.67243729 L9.67243729,9.89288407 C9.47717515,10.0881462 9.16059266,10.0881462 8.96533051,9.89288407 L8.96533051,9.89288407 L7,7.927 L5.03466949,9.89288407 C4.83940734,10.0881462 4.52282485,10.0881462 4.32756271,9.89288407 L4.10711593,9.67243729 C3.91185378,9.47717515 3.91185378,9.16059266 4.10711593,8.96533051 L6.073,7 L4.10711593,5.03466949 C3.93354958,4.86110314 3.91426443,4.59167874 4.04926048,4.3968106 L4.10711593,4.32756271 Z"
                        fill={ThemedColors.componentBackground}
                        id="Combined-Shape"
                    />
                </g>
            </g>
        </g>
    </StyledSvg>
);

export default styled.button.attrs(({ children = <ClearButtonSvg /> }) => ({ children }))`
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
