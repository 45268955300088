import { clsx } from 'clsx';
import type { ElementType, ForwardedRef, PropsWithChildren, PropsWithoutRef } from 'react';
import { forwardRef } from 'react';
import type { LayoutSprinklesProps } from './Layout.css';
import { useLayoutProps } from './Layout.css';

export type IntrinsicType = keyof JSX.IntrinsicElements;
export type ComponentType<TType = unknown> = IntrinsicType | React.ComponentType<TType>;

export type LayoutComponentProps<TElementType extends ComponentType = IntrinsicType> =
  TElementType extends IntrinsicType
    ? Omit<JSX.IntrinsicElements[TElementType], keyof LayoutSprinklesProps>
    : Omit<React.ComponentProps<TElementType>, keyof LayoutSprinklesProps>;

export type LayoutProps<TElementType extends ComponentType = IntrinsicType> = LayoutSprinklesProps &
  PropsWithoutRef<LayoutComponentProps<TElementType>> & {
    readonly as?: TElementType;
  };

export const Layout = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  <TRef extends unknown = unknown>(
    {
      children,
      as,
      className: externalClassName,
      style: externalStyle,
      ...props
    }: PropsWithChildren<LayoutProps<IntrinsicType>>,
    ref: ForwardedRef<TRef>,
  ): JSX.Element => {
    const { className: internalClassName, style, otherProps } = useLayoutProps(props);
    const className = clsx(internalClassName, externalClassName);
    const Component = (as ?? 'div') as ElementType;

    return (
      <Component className={className} style={{ ...style, ...externalStyle }} {...otherProps} ref={ref}>
        {children}
      </Component>
    );
  },
) as <TElementType extends ComponentType = IntrinsicType, TRef = unknown>(
  props: PropsWithChildren<LayoutProps<TElementType>> & { readonly ref?: ForwardedRef<TRef> },
) => JSX.Element;
