import { ArrowIcon, Avatar } from '@lemonade-hq/bluis';
import { PrincipalType, useAnalytics, useCurrentPrincipal } from '@lemonade-hq/boutique';
import { Popover, useToggle } from '@lemonade-hq/cdk';
import type { Options as PopperOptions } from '@popperjs/core';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { ConnectToRaterDialog } from './ConnectToRaterDialog';
import { AdjusterAvailability, useAdjuster } from '../hooks';
import { AccountDropdownMenu } from './DropDownMenu';
import { EditSignatureDialog } from './EditSignatureDialog';

const Container = styled.button`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0;
    border: 0;
    background: transparent;
`;

const popoverOptions: PopperOptions = {
    modifiers: [{ name: 'offset', options: { offset: [-40, 6] } }],
    placement: 'bottom-end',
    strategy: 'fixed',
};

export const Toggle: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { close: onCloseSignatureDialog, open: onOpenSignatureDialog, isOpen: isSignatureDialogOpen } = useToggle(
        false
    );

    const { trackEvent } = useAnalytics();

    const { principal, operator } = useCurrentPrincipal();

    const { data: adjuster } = useAdjuster(principal.type === PrincipalType.Workforce, operator?.id);

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [connectToRaterDialogOpen, setConnectToRaterDialogOpen] = useState(false);

    const openConnectToRaterDialog = useCallback(() => {
        setConnectToRaterDialogOpen(true);
        trackEvent('connect_rater.opened', {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            agent_id: principal.subject,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            partner_id: principal.partnerPublicId ?? '',
        });
    }, [principal.subject, principal.partnerPublicId, trackEvent]);

    const closeConnectToRaterDialog = useCallback(() => {
        trackEvent('dialogue_closed', {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            agent_id: principal.subject,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            partner_id: principal.partnerPublicId ?? '',
        });
        setConnectToRaterDialogOpen(false);
    }, [principal.subject, principal.partnerPublicId, trackEvent]);

    const handleClick = useCallback(() => {
        setOpen(o => !o);
    }, []);

    function handleBackdropClick(): void {
        if (isSignatureDialogOpen) return;

        handleClick();
    }

    return (
        <>
            <Container onClick={handleClick} ref={anchorRef} type="button">
                <span>
                    {principal.firstName} {principal.lastName}
                </span>
                <ArrowIcon height={8} width={8} />
                <Avatar src={operator?.imageUrl} />
            </Container>
            <Popover
                anchorEl={anchorRef}
                isOpen={open}
                onBackdropClick={handleBackdropClick}
                options={popoverOptions}
                zIndex={1}
            >
                <AccountDropdownMenu
                    adjusterId={adjuster?.publicId}
                    isAvailable={adjuster?.availability === AdjusterAvailability.Available}
                    onConnectRaterClick={openConnectToRaterDialog}
                    onEditSignatureClick={onOpenSignatureDialog}
                />
            </Popover>
            {isSignatureDialogOpen && operator != null && (
                <EditSignatureDialog onClose={onCloseSignatureDialog} operatorId={operator.id} />
            )}

            {connectToRaterDialogOpen && <ConnectToRaterDialog onClose={closeConnectToRaterDialog} />}
        </>
    );
};
