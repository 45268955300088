import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import { findSetting } from './common';
import { getUnit, settingValuesDisplay } from './setting-attributes';
import { getCouplingValuePairs } from './SettingCoupled';
import { getSettingTypeDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import type { ReferenceIdEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

interface SettingValuesUpdatedProps {
    readonly change: ReferenceIdEntry;
    readonly coveragesEdition: CoveragesEdition;
    readonly baseCoveragesEdition: CoveragesEdition;
}

export const SettingValuesUpdated: FC<SettingValuesUpdatedProps> = ({
    change,
    coveragesEdition,
    baseCoveragesEdition,
}) => {
    const setting = findSetting(coveragesEdition, change.metadata.referenceId);
    const baseSetting = findSetting(baseCoveragesEdition, change.metadata.referenceId);
    const generalTabData: LabelValueItem[] = [
        { label: 'New Setting Values', value: settingValuesDisplay(setting) },
        { label: 'Old Setting Values', value: settingValuesDisplay(baseSetting) },
        ...(setting.type === SettingType.Limit ? getUnit(setting) : []),
    ];

    if (setting.coupledSetting?.coupledSettingTemplateCode !== undefined) {
        generalTabData.push(
            getCouplingValuePairs({
                settingName: setting.name,
                coupling: setting.coupledSetting,
            })
        );
    }

    return (
        <ChangeAccordion
            generalTabData={generalTabData}
            originalRules={null}
            ruleType="coverage"
            title={`${getSettingTypeDisplayName(setting.type)} values updated - ${setting.name}`}
            updatedRules={null}
        />
    );
};
