import { colors as blenderUIColors, Button, Card, Icon, LabelValueTable, wordBreak } from '@lemonade-hq/blender-ui';
import type { AlertProps } from '@lemonade-hq/bluis';
import { Alert, AlertMode, SparklesIcon, themedColor } from '@lemonade-hq/bluis';
import { useTheme } from '@lemonade-hq/boutique';
import { Flex, useCollapsible, useToggle } from '@lemonade-hq/cdk';
import { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { DebugLogsDialog } from '../../Playground/simulation/dialogs/DebugLogsDialog';
import type { TimelineEvent } from '../types';
import { LogActionType, TimelineItemType } from '../types';

const Timestamp = styled.span`
    white-space: nowrap;
`;

const StyledAlert = styled(Alert)`
    width: 100%;
    padding: 0;

    path {
        fill: ${blenderUIColors.neutral7};
    }
`;

const TextContainer = styled(Flex)`
    align-items: center;
    gap: 8px;
`;

const LogEventContainer = styled(Flex)`
    flex-direction: column;
    color: ${themedColor('secondaryText')};
    width: 100%;
    align-items: center;
    gap: 4px;
`;

const EventTitle = styled(Flex)<{ readonly isCollapsible?: boolean }>`
    cursor: ${({ isCollapsible }) => (isCollapsible === true ? 'pointer' : 'default')};
    color: ${themedColor('secondaryText')};
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const EventDescription = styled(Flex)`
    color: ${themedColor('primaryText')};
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    padding: 0px 32px;
`;

function generateModeFromAction(action: LogActionType): AlertMode {
    switch (action) {
        case LogActionType.Closed:
            return AlertMode.Success;
        case LogActionType.Escalated:
            return AlertMode.Update;
        case LogActionType.Error:
            return AlertMode.Attention;
        case LogActionType.Info:
            return AlertMode.Info;
        default:
            return AlertMode.Info;
    }
}

export const LogEvent: React.FC<TimelineEvent> = ({ action, sentAt, title, type }) => {
    const { colors } = useTheme();
    const isAiInsight = type === TimelineItemType.Insight;

    return (
        <EventTitle>
            <StyledAlert
                icon={isAiInsight ? <SparklesIcon color={colors.primary} /> : undefined}
                mode={generateModeFromAction(action)}
                noBackground
                title={
                    <TextContainer>
                        <div dangerouslySetInnerHTML={{ __html: title }} />
                    </TextContainer>
                }
            />
            {sentAt != null && <Timestamp>{sentAt}</Timestamp>}
        </EventTitle>
    );
};

export const CollapsibleLogEvent: React.FC<TimelineEvent> = ({
    action,
    sentAt,
    description = [],
    data,
    title,
    debug: debugLogs,
}) => {
    const collapsibleRef = useRef<HTMLDivElement>(null);

    const { collapsibleWrapperStyles, handleToggleCollapsible, isClosed } = useCollapsible({
        ref: collapsibleRef,
        isCollapsed: false,
        extendedCollapsibleWrapperStyles: (isCollapsed: boolean) =>
            !isCollapsed
                ? {
                      overflow: 'visible',
                      height: 'auto',
                      padding: '8px 0',
                  }
                : {},
        animationDuration: 200,
    });
    const { open: openDebugDialog, isOpen: isDebugDialogOpen, close: closeDebugDialog } = useToggle();

    const arrowRotation = isClosed ? undefined : '180deg';
    const mode = generateModeFromAction(action);

    const descriptionContent = useMemo(() => {
        if (data != null && data.length > 0) {
            return data.map(({ title: t, table }) => {
                return (
                    <Card className={wordBreak} key={t} variant="quaternary">
                        <LabelValueTable
                            columnCount={1}
                            data={table.map(({ key, value }) => ({ label: key, value }))}
                            subtitle={t}
                        />
                    </Card>
                );
            });
        }

        if (typeof description === 'string') return description;

        // Will be removed once all data is migrated to the new table format, as in the above if block
        return (
            <ul>
                {description.map(({ key, value }) => {
                    return (
                        <li key={key}>
                            <b>{key}:</b> {value}
                        </li>
                    );
                })}
            </ul>
        );
    }, [description, data]);

    const alertProps: AlertProps = {
        mode,
        noBackground: true,
        title: (
            <TextContainer>
                <div dangerouslySetInnerHTML={{ __html: title }} />
                {description.length > 0 && <Icon name="chevron-down" rotation={arrowRotation} size="sm" />}
            </TextContainer>
        ),
    };

    return (
        <>
            <LogEventContainer>
                <EventTitle isCollapsible onClick={handleToggleCollapsible}>
                    <StyledAlert {...alertProps} />
                    <Timestamp>{sentAt}</Timestamp>
                </EventTitle>
                <EventDescription ref={collapsibleRef} style={collapsibleWrapperStyles}>
                    {descriptionContent}
                    {debugLogs != null && (
                        <Button label="Debug Log" onClick={openDebugDialog} size="sm" variant="secondary" />
                    )}
                </EventDescription>
            </LogEventContainer>
            {isDebugDialogOpen && debugLogs != null && (
                <DebugLogsDialog debugLogs={debugLogs} onClose={closeDebugDialog} />
            )}
        </>
    );
};
