import { Button, Flex, IconButton, spacing, Text, Tooltip } from '@lemonade-hq/blender-ui';
import type { SerializableToolsRevision as ToolsRevision, ToolsRevisionSyncStatus } from '@lemonade-hq/persisted-tools';
import { ToolChangeStatus } from '@lemonade-hq/persisted-tools';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PLAYGROUND_FULL_PATH_PREFIX } from '../shared/routing.consts';
import { actuallyDeletedTool, getInvalidToolsNamesFromToolsRevision } from '../shared/tool.helpers';
import { PlaygroundToolEditorHeaderNotification } from './PlaygroundToolEditorHeaderNotification';

export interface PlaygroundToolEditorHeaderProps {
    readonly toolsRevision?: ToolsRevision;
    readonly isLoading?: boolean;
    readonly isReadonly?: boolean;
    readonly isPlaceholder?: boolean;
    readonly onPublish: () => void;
}

function getCannotPublishMessage(
    syncStatus: ToolsRevisionSyncStatus | undefined,
    invalidToolsNames: string[],
    toolsEdited: number
): string | undefined {
    if (invalidToolsNames.length > 0) {
        return `Please fix invalid tools before publishing: ${invalidToolsNames.join(', ')}`;
    } else if (syncStatus?.errors != null) {
        return `Production prompts have conflicts with the current tools revision in the following tools: ${syncStatus.errors
            .map(e => e.toolName)
            .join(', ')}`;
    } else if (toolsEdited === 0) {
        return 'Tools have to be edited before publishing';
    }

    return undefined;
}

export const PlaygroundToolEditorHeader: FC<PlaygroundToolEditorHeaderProps> = ({
    toolsRevision,
    isLoading,
    isPlaceholder,
    isReadonly,
    onPublish: handlePublish,
}) => {
    const navigate = useNavigate();
    const toolsEdited = useMemo(
        () =>
            toolsRevision?.tools.filter(
                t =>
                    t.changeStatus === ToolChangeStatus.New ||
                    t.changeStatus === ToolChangeStatus.Edited ||
                    actuallyDeletedTool(t)
            ).length ?? 0,
        [toolsRevision]
    );
    const invalidToolsNames = useMemo(() => getInvalidToolsNamesFromToolsRevision(toolsRevision), [toolsRevision]);

    const canSync = toolsRevision?.syncStatus?.canSync && toolsEdited > 0;
    const canPublish = canSync && invalidToolsNames.length === 0 && !isPlaceholder && !isReadonly;

    return (
        <Flex alignItems="center" gap={spacing.s12} role="status">
            <Tooltip content="Back to playground index" side="bottom">
                <IconButton
                    aria-label="back"
                    icon="chevron-down"
                    onClick={() => navigate(PLAYGROUND_FULL_PATH_PREFIX)}
                    rotation="cw90deg"
                    size="md"
                    variant="inline"
                />
            </Tooltip>
            <Flex flexDirection="column" gap={spacing.s02}>
                <Text type="h5">{`Tools Revision ${toolsRevision?.publicId}`}</Text>
                <Text color="tertiary" type="text-sm">
                    {toolsEdited} tools edited
                </Text>
            </Flex>
            <Flex alignItems="center" gap={spacing.s08} marginInlineStart="auto">
                {!isPlaceholder && (
                    <PlaygroundToolEditorHeaderNotification isLoading={isLoading} toolsRevision={toolsRevision} />
                )}
                {!isReadonly && (
                    <Tooltip
                        content={getCannotPublishMessage(toolsRevision?.syncStatus, invalidToolsNames, toolsEdited)}
                        disabled={canPublish}
                        side="bottom"
                    >
                        <Button
                            disabled={!canPublish}
                            label="Publish changes"
                            onClick={() => handlePublish()}
                            size="md"
                            variant="primary"
                        />
                    </Tooltip>
                )}
            </Flex>
        </Flex>
    );
};
