import React from 'react';
import { InlineButton, Separator, themedColor } from '@lemonade-hq/bluis';
import { PrincipalType, useCurrentPrincipal } from '@lemonade-hq/boutique';
import styled from 'styled-components';
import { Availability } from './Availability';

const Link = styled.a`
    width: 100%;
`;

const StyledSeparator = styled(Separator)`
    width: 100%;
    margin: 4px;
`;

const Container = styled.div`
    overflow: hidden;
    background: ${themedColor('componentBackground')};
    border: 1px solid ${themedColor('separator')};
    border-radius: 10px;
    box-shadow: 0px 2px 9px 6px rgba(51, 51, 51, 0.07);
    gap: 8px;
`;

const ActionButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
`;

const MenuItemButton = styled(InlineButton)`
    background-color: ${themedColor('componentBackground')};
    color: ${themedColor('primaryText')};
    padding: 8px;
    width: 100%;
    text-align: left;
    transition: background-color 0.15s ease;
    height: 36px;
    min-width: 170px;

    &:hover {
        color: ${themedColor('primaryText')};
        background-color: ${themedColor('elementBackground')};
        border-radius: 4px;
    }

    &:active {
        color: ${themedColor('primaryText')};
    }
`;

const LogOutButton = styled(MenuItemButton)`
    color: ${themedColor('primary')};

    &:hover {
        color: ${themedColor('primaryLinkHover')};
        background: ${themedColor('attentionBackground')};
    }
`;

interface AccountDropdownMenuProps {
    readonly adjusterId?: string;
    readonly isAvailable?: boolean;
    readonly onEditSignatureClick: () => void;
    readonly onConnectRaterClick: () => void;
}

export const AccountDropdownMenu: React.FC<React.PropsWithChildren<AccountDropdownMenuProps>> = ({
    adjusterId,
    isAvailable = false,
    onEditSignatureClick,
    onConnectRaterClick,
}) => {
    const showAvailabilityMenu = adjusterId != null;
    const { operator, principal } = useCurrentPrincipal();

    const showEditSignatureButton = Boolean(operator?.editableEmailSignature);

    return (
        <Container>
            {showAvailabilityMenu && <Availability adjusterId={adjusterId} isAvailable={isAvailable} />}
            <ActionButtonsContainer>
                {showEditSignatureButton && (
                    <MenuItemButton onClick={onEditSignatureClick}>Edit email signature</MenuItemButton>
                )}
                {showAvailabilityMenu && <StyledSeparator />}
                {principal.type === PrincipalType.Agent && (
                    <MenuItemButton onClick={onConnectRaterClick}>Connect a rater</MenuItemButton>
                )}
                <Link href="/api/v1/auth/sign_out">
                    <LogOutButton>Log out</LogOutButton>
                </Link>
            </ActionButtonsContainer>
        </Container>
    );
};
