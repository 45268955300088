import { InfoOutlinedIcon, NoticeFilledIcon } from '@lemonade-hq/bluis';
import { themedColor, Tooltip } from '@lemonade-hq/boutique';
import { Flex } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import { StyledFormInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';

export const SplitStyledFormInputWrapper = styled(StyledFormInputWrapper)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    > div {
        width: 100%;
    }

    .inner {
        width: 300px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 12px;

        > div {
            width: 100%;

            > button {
                width: 100% !important;
            }
        }
    }
`;

const StyledInfoIcon = styled(InfoOutlinedIcon)`
    color: ${themedColor('inputBorder')};
`;

const StyledErrorIcon = styled(NoticeFilledIcon)`
    color: ${themedColor('inputBorder')};
`;

export const LabelWithTooltip: React.FC<{
    readonly label: string;
    readonly tooltipContent: JSX.Element | string;
    readonly type: 'error' | 'info';
}> = ({ label, tooltipContent, type }) => (
    <Flex alignItems="center" gap="12px">
        {label}
        <Tooltip alignment="top" content={tooltipContent}>
            <Flex>{type === 'error' ? <StyledErrorIcon /> : <StyledInfoIcon />}</Flex>
        </Tooltip>
    </Flex>
);

export const InsurableEntityLabel: React.FC = () => (
    <LabelWithTooltip
        label="Insurable Entity"
        tooltipContent={
            <Flex alignItems="flex-start" flexDirection="column">
                <span>Which entity should this Setting apply to?</span>
            </Flex>
        }
        type="info"
    />
);
