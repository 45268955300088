import { Flex, Icon, spacing, Text, Tooltip } from '@lemonade-hq/blender-ui';
import { formatOccurredAt } from '@lemonade-hq/bluiza';
import { DatetimeFormat, formatDatetime } from '@lemonade-hq/lemonation';
import type { SerializableToolsRevision as ToolsRevision } from '@lemonade-hq/persisted-tools';
import type { FC } from 'react';
import { ToolRevisionPublishNotesTooltip } from '../toolEditor/PlaygroundToolEditorHeaderNotification';
import type { ComparisonType } from './compare.helpers';
import { getComparisonIcon } from './compare.helpers';

export const PlaygroundComparableToolsRevisionTopBar: FC<{
    readonly toolsRevision?: ToolsRevision | undefined;
    readonly comparisonType: ComparisonType;
    readonly className?: string;
}> = ({ toolsRevision, comparisonType, className }) => {
    return (
        <Flex
            className={className}
            flexShrink={0}
            justifyContent="space-between"
            paddingInline={spacing.s08}
            role="banner"
        >
            <Flex alignItems="center" gap={spacing.s04}>
                <Icon color="textTertiary" name={getComparisonIcon(comparisonType)} />
                <Text textTransform="none" type="text-md">
                    {toolsRevision?.publicId ?? 'LOADING...'}
                </Text>
            </Flex>
            {toolsRevision?.publishedAt != null && (
                <Flex alignItems="center" gap={spacing.s06} justifyContent="flex-end">
                    <Tooltip
                        content={formatDatetime(new Date(toolsRevision.publishedAt), {
                            datetimeFormat: DatetimeFormat.FullDateWithTime,
                        })}
                    >
                        <Text color="secondary" textAlign="right" type="text-md">
                            Published by {toolsRevision.publishedBy}&nbsp;
                            {formatOccurredAt(toolsRevision.publishedAt).toLowerCase()}
                        </Text>
                    </Tooltip>
                    <ToolRevisionPublishNotesTooltip toolsRevision={toolsRevision} />
                </Flex>
            )}
        </Flex>
    );
};
