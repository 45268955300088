import type { RecordLikeShape, RecordPath, Shape } from '@lemonade-hq/maschema-schema';
import get from 'lodash/get';
import type { CheckboxProps as BUICheckboxProps } from '../../../Checkbox/Checkbox';
import { Checkbox as BUICheckbox } from '../../../Checkbox/Checkbox';
import { useForm } from '../../FormContext';
import type { CommonAdapterProps } from '../common';
import { useConnectToForms } from '../common';
import type { AssertSchemaKeyIsOfTypeBoolean } from '../shape-assertion.types';

export type CheckboxProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = BUICheckboxProps &
  CommonAdapterProps<TSchema, TSchemaKey>;

export type AssertedCheckboxProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = Omit<
  BUICheckboxProps,
  'checked' | 'disabled' | 'onCheckedChange'
> &
  (CheckboxProps<TSchema, TSchemaKey> extends infer TExtractedProps extends {
    readonly schemaKey: infer TSchemaKeyToCheck extends TSchemaKey;
  }
    ? AssertSchemaKeyIsOfTypeBoolean<TSchema, TSchemaKeyToCheck, TExtractedProps>
    : never);

export const Checkbox: React.FC<CheckboxProps<RecordLikeShape, RecordPath<RecordLikeShape>>> = ({
  schemaKey,
  rules,
  onBlur,
  ...restProps
}) => {
  const { values: data, dispatch } = useForm<RecordLikeShape>();
  const { disabled, visible } = useConnectToForms({ schemaKey, rules });

  return visible ? (
    <BUICheckbox
      checked={get(data, schemaKey) as boolean}
      disabled={disabled}
      onBlur={e => {
        dispatch({ type: 'blur', key: schemaKey });
        onBlur?.(e);
      }}
      onCheckedChange={value =>
        dispatch({
          type: 'setValue',
          key: schemaKey,
          value: value as unknown as Shape,
        })
      }
      {...restProps}
    />
  ) : null;
};
