import { Flex, Tooltip } from '@lemonade-hq/blender-ui';
import type { ActionOption, TableHeaderCellProps } from '@lemonade-hq/bluis';
import {
    ActionsMenu,
    EmptySection,
    ErrorSection,
    LoadingSection,
    Table,
    TableHeader,
    TableRow,
} from '@lemonade-hq/bluis';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { EditionLink, getFlatEditions } from './EditionsTableShared';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import { getFormattedDate } from 'components/LoCo/common/helpers/dateHelpers';
import type { MissionControlActionData } from 'components/LoCo/products/ProductMissionControl/dialogs/MissionControlDialogs';
import { DialogType } from 'components/LoCo/products/ProductMissionControl/dialogs/MissionControlDialogs';
import type { Edition, EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { useGetProductEditionsSummary } from 'queries/LoCo/Insurance/ProductQueries';

interface DraftEditionsTableProps {
    readonly productCode: string;
    readonly setDialogData: Dispatch<SetStateAction<MissionControlActionData | null>>;
}

const StyledActionsMenuWrapper = styled(Flex)`
    position: relative;
    justify-content: flex-end;
`;

const headerFields: TableHeaderCellProps[] = [
    { key: 'type', value: 'Type', width: 300 },
    { key: 'edition', value: 'Edition', width: 300 },
    { key: 'description', value: 'Description', width: 300 },
    { key: 'created', value: 'Created', width: 200 },
    { key: 'actions', value: '', width: 100 },
];

const EditionToRow: React.FC<{
    readonly type: EditionType;
    readonly edition: Edition;
    readonly onChange: (value: string) => void;
    readonly productCode: string;
}> = ({ type, edition, onChange, productCode }) => {
    const actions: ActionOption[] = useMemo(() => {
        return [
            {
                label: 'Archive',
                value: 'archive',
            },
        ];
    }, []);

    const addedAtDisplay = getFormattedDate(edition.addedAt);

    return (
        <TableRow
            key={edition.code}
            row={[
                { key: 'type', value: getEditionName(type) },
                { key: 'edition', value: <EditionLink edition={edition} productCode={productCode} type={type} /> },
                { key: 'description', value: edition.description },
                { key: 'created', value: <Tooltip content={`By ${edition.addedByName}`}>{addedAtDisplay}</Tooltip> },

                {
                    key: 'actions',
                    value: (
                        <StyledActionsMenuWrapper>
                            <ActionsMenu actions={actions} onChange={onChange} type="dots" />
                        </StyledActionsMenuWrapper>
                    ),
                },
            ]}
        />
    );
};

export const DraftEditionsTable: React.FC<React.PropsWithChildren<DraftEditionsTableProps>> = ({
    productCode,
    setDialogData,
}) => {
    const {
        data: editionsSummary,
        isError,
        isLoading,
    } = useGetProductEditionsSummary(productCode, { approved: false });

    if (isLoading) {
        return <LoadingSection />;
    }

    if (!editionsSummary || isError) {
        return <ErrorSection title="Failed fetching edition drafts :(" />;
    }

    const dialogDataOptions = {
        archive: (edition: Edition & { type: string }) => ({
            type: DialogType.ArchiveDraftEdition,
            editionType: edition.type as EditionType,
            editionCode: edition.code,
            editionFriendlyName: edition.friendlyName,
        }),
    };

    const joinedEditions = getFlatEditions(editionsSummary);

    return joinedEditions.length === 0 ? (
        <EmptySection>No edition drafts for this product</EmptySection>
    ) : (
        <Table>
            <TableHeader headers={headerFields} />
            {joinedEditions.map(edition => (
                <EditionToRow
                    edition={edition}
                    key={edition.code}
                    onChange={value =>
                        setDialogData(dialogDataOptions[value as keyof typeof dialogDataOptions](edition))
                    }
                    productCode={productCode}
                    type={edition.type}
                />
            ))}
        </Table>
    );
};
