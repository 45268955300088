import { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

const RULES_TITLE: Record<UnderwritingDecisionLifecycleContext, string> = {
    [RuleLifecycleContext.NewBusiness]: 'Decline Quotes',
    [RuleLifecycleContext.Renewal]: 'Non-Renew Policy',
};

export function getRulesTitle(lifecycleContext: UnderwritingDecisionLifecycleContext): string {
    return RULES_TITLE[lifecycleContext];
}

const OUTCOME_TEXTS: Record<UnderwritingDecisionLifecycleContext, string> = {
    [RuleLifecycleContext.NewBusiness]: 'Decline',
    [RuleLifecycleContext.Renewal]: 'Non-renew',
};

export function getOutcomeText(lifecycleContext: UnderwritingDecisionLifecycleContext): string {
    return OUTCOME_TEXTS[lifecycleContext];
}

export type UWRuleType = 'decline' | 'flag';
