import { Flex, Icon, spacing, Text, Tooltip } from '@lemonade-hq/blender-ui';
import { formatCurrency } from '@lemonade-hq/lemonation';
import type { Currency } from '@lemonade-hq/lemonation';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { formatNumericField } from '../../helpers/inputHelpers';
import { StackedValues } from 'components/LoCo/LoCoPagesSharedStyles';
import type { CoupledSetting, RangeValue, SettingInstance, Values } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingUnit, ValueType } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

function formatValue(value: number, valueSuffix: string | undefined, currency: string | undefined): string {
    if (isDefined(valueSuffix)) return `${value}${valueSuffix}`;
    if (isDefined(currency)) return formatCurrency(value, { currency: currency.toUpperCase() as Currency });

    return formatNumericField(value.toString());
}

export function formatSettingValuesList(
    valueList: number[],
    includeUnlimited: boolean,
    valueSuffix: string | undefined,
    currency: string | undefined
): string {
    return (
        [...valueList]
            .sort((a, b) => a - b)
            .map(value => formatValue(value, valueSuffix, currency))
            .join(', ') + (includeUnlimited ? ', Unlimited' : '')
    );
}

export function formatSettingValuesRange(
    rangeValues: RangeValue,
    valueSuffix: string | undefined,
    currency: string | undefined
): string[] {
    const { min, max, step } = rangeValues;

    return [
        `Interval: ${formatValue(step, valueSuffix, currency)}`,
        `Minimum: ${formatValue(min, valueSuffix, currency)}`,
        `Maximum: ${formatValue(max, valueSuffix, currency)}`,
    ];
}

export const SettingValues: React.FC<{
    readonly type: SettingType;
    readonly values: Values;
    readonly currencyUnit?: Currency | undefined;
    readonly unit?: SettingUnit;
    readonly coupledSetting?: CoupledSetting;
    readonly settingName: string;
}> = ({ type, values, currencyUnit, unit, coupledSetting, settingName }) => {
    let currency: Currency | undefined;
    let valueSuffix: string | undefined;

    switch (type) {
        case SettingType.Limit: {
            if (unit === SettingUnit.ClaimLossPercentage) {
                valueSuffix = '%';
            } else {
                currency = currencyUnit;
            }

            break;
        }

        case SettingType.Deductible:
            currency = currencyUnit;
            break;

        case SettingType.Coinsurance:
            valueSuffix = '%';
            break;
        case SettingType.WaitingPeriod:
        default:
            break;
    }

    if (values.type === ValueType.List) {
        const valuesMarkup = (
            <span>{formatSettingValuesList(values.values, values.includeUnlimited, valueSuffix, currency)}</span>
        );

        return coupledSetting ? (
            <Flex flexDirection="column" width="100%">
                {valuesMarkup}
                <Flex alignItems="center" gap={spacing.s04} justifyContent="flex-start">
                    <Text color="tertiary" type="label-sm">
                        {`Coupled with ${coupledSetting.coupledSettingName}`}
                    </Text>
                    <Tooltip
                        content={
                            <Flex>
                                <Text color="light">{`Value pairs of Coupled Settings are:
                                ${coupledSetting.coupledSettingName} / ${settingName}
                                ${coupledSetting.values.map(({ coupledSettingValue, currentSettingValue }) => `${coupledSettingValue} / ${currentSettingValue}`).join('\n')}`}</Text>
                            </Flex>
                        }
                    >
                        <Icon color="textTertiary" name="alert-circle-solid" />
                    </Tooltip>
                </Flex>
            </Flex>
        ) : (
            valuesMarkup
        );
    }

    return <StackedValues values={formatSettingValuesRange(values, valueSuffix, currency)} />;
};

export const SettingInstanceValues: React.FC<{ readonly settingInstance: SettingInstance }> = ({ settingInstance }) => {
    return (
        <SettingValues
            coupledSetting={settingInstance.coupledSetting}
            currencyUnit={
                settingInstance.type === SettingType.Limit || settingInstance.type === SettingType.Deductible
                    ? settingInstance.currencyUnit
                    : undefined
            }
            settingName={settingInstance.name}
            type={settingInstance.type}
            unit={settingInstance.type === SettingType.Limit ? settingInstance.unit : undefined}
            values={settingInstance.values}
        />
    );
};
