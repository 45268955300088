import type { ActionsMenuItem } from '@lemonade-hq/blender-ui';
import { ActionsMenu, Button, Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import { useToggle } from '@lemonade-hq/cdk';
import { useIsMutating } from '@tanstack/react-query';
import { useState } from 'react';
import { Card } from '../../../shared/components/Card';
import type { TicketAiReview } from '../../../shared/types';
import { AiResponseType, TicketAction } from '../../../shared/types';
import { useSupervisorContext } from '../Context';
import { FixFormDialog } from './FixFormDialog';
import { FixIntentDialog } from './FixIntentDialog';
import { PromptDetailsDialog } from './PromptDetailsDialog';
import { RemoveDialog } from './RemoveDialog';
import { SkipDialog } from './SkipDialog';

interface ActionButtonsConfigArgs {
    readonly mode: AiResponseType;
    readonly ticketAction: TicketAiReview['action'];
    readonly onPass: () => void;
    readonly onOpenFixDialog: () => void;
    readonly onOpenIntentDialog: () => void;
}

function getActionButtonsConfig({
    mode,
    ticketAction,
    onPass,
    onOpenFixDialog,
    onOpenIntentDialog,
}: ActionButtonsConfigArgs): {
    readonly text: string;
    readonly type: 'primary' | 'secondary';
    readonly onClick: () => void;
}[] {
    const isEscalate = ticketAction === TicketAction.Escalate;

    if (mode === AiResponseType.Public) {
        return [
            { text: 'Pass', type: 'primary', onClick: onPass },
            { text: 'Fail', type: 'secondary', onClick: isEscalate ? onOpenIntentDialog : onOpenFixDialog },
        ];
    }

    // Draft default actions
    return [
        { text: 'Send', type: 'primary', onClick: onPass },
        { text: 'Fix', type: 'secondary', onClick: onOpenFixDialog },
    ];
}

function getActionsTitle(ticketAction: TicketAiReview['action'], mode: AiResponseType): string {
    if (ticketAction === TicketAction.Close || ticketAction === TicketAction.Escalate) return 'Review intent';
    if (mode === AiResponseType.Draft) return 'Review draft';

    return 'Review response';
}

export const Actions: React.FC<React.PropsWithChildren<unknown>> = () => {
    const isMutating = Boolean(useIsMutating());
    const [isForcingReview, setIsForcingReview] = useState(false);
    const {
        state: { ticketData, isLoadingTicket },
        actions: { loadNextTicket, onReviewPass, skipTicket },
    } = useSupervisorContext();

    const { open: onLoadPass, close: onUnloadPass, isOpen: isLoadingPass } = useToggle(false);
    const {
        open: onOpenPromptDetailsDialog,
        close: onClosePromptDetailsDialog,
        isOpen: isOpenPromptDetailsDialog,
    } = useToggle(false);
    const { open: onOpenFixDialog, close: onCloseFixDialog, isOpen: idOpenFixDialog } = useToggle(false);
    const { open: onOpenSkipDialog, close: onCloseSkipDialog, isOpen: isOpenSkipDialog } = useToggle(false);
    const { open: onOpenRemoveDialog, close: onCloseRemoveDialog, isOpen: isOpenRemoveDialog } = useToggle(false);
    const { open: onOpenIntentDialog, close: onCloseIntentDialog, isOpen: isOpenIntentDialog } = useToggle(false);

    if (ticketData == null) {
        return null;
    }

    const {
        actions: { interveneAvailable, reviewAvailable, skipAvailable },
        usedPrompts,
        action,
        toolInvoked,
        type: mode,
    } = ticketData;

    const isPublic = mode === AiResponseType.Public;
    const isDraft = mode === AiResponseType.Draft;

    const isReviewDisabled = (): boolean => {
        if (isMutating || Boolean(isLoadingTicket) || interveneAvailable) return true;

        return !(isForcingReview || reviewAvailable);
    };

    const menuActions: ActionsMenuItem[] = [
        {
            disabled: isDraft ? !skipAvailable : false,
            label: 'Skip Ticket',
            onSelect: onSkip,
        },
        {
            disabled: isPublic,
            label: 'Release Ticket',
            onSelect: onOpenRemoveDialog,
        },
        {
            label: 'Add Review',
            onSelect: () => setIsForcingReview(true),
            disabled: reviewAvailable && !interveneAvailable,
        },
        {
            label: 'Show Prompt',
            onSelect: onOpenPromptDetailsDialog,
            disabled: usedPrompts == null || Object.keys(usedPrompts).length === 0,
        },
    ];

    async function onPass(): Promise<void> {
        onLoadPass();
        try {
            setIsForcingReview(false);
            await onReviewPass();
            await loadNextTicket();
        } finally {
            onUnloadPass();
        }
    }

    function handleCloseFixDialog(): void {
        setIsForcingReview(false);
        onCloseFixDialog();
    }

    function onSkip(): void {
        if (interveneAvailable) {
            onOpenSkipDialog();
        } else {
            void skipTicket();
        }
    }

    const actionButtons = getActionButtonsConfig({
        mode,
        ticketAction: action,
        onOpenFixDialog,
        onOpenIntentDialog,
        onPass: () => void onPass(),
    }).map(({ text, type, onClick }) => {
        const isLoading = type === 'primary' && isLoadingPass;

        return (
            <Button
                disabled={isReviewDisabled()}
                key={text}
                label={text}
                loading={isLoading}
                onClick={onClick}
                size="lg"
                variant={type === 'primary' ? 'primary' : 'secondary'}
            />
        );
    });

    return (
        <>
            <Card>
                <Flex alignItems="center" justifyContent="space-between" mb={spacing.s24}>
                    <Text type="h5">{getActionsTitle(action, mode)}</Text>
                    <ActionsMenu items={menuActions} />
                </Flex>

                {/* Actions */}
                <Flex flexDirection="column" gap="12px">
                    {actionButtons}
                </Flex>
            </Card>

            {/* Dialogs */}
            {idOpenFixDialog && <FixFormDialog onClose={handleCloseFixDialog} />}
            {isOpenSkipDialog && <SkipDialog onClose={onCloseSkipDialog} />}
            {isOpenPromptDetailsDialog && usedPrompts != null && (
                <PromptDetailsDialog
                    onClose={onClosePromptDetailsDialog}
                    toolInvoked={toolInvoked}
                    usedPrompts={usedPrompts}
                />
            )}
            {isOpenRemoveDialog && <RemoveDialog onClose={onCloseRemoveDialog} />}
            {isOpenIntentDialog && <FixIntentDialog onClose={onCloseIntentDialog} />}
        </>
    );
};
