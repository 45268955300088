import type { ActionOption } from '@lemonade-hq/bluis';
import { Input, TextArea } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import styled, { css } from 'styled-components';
import type { CoverageRule } from 'models/LoCo/Insurance/CoverageRule';
import type { CoverageInstance, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';

export const StyledActionsMenuWrapper = styled(Flex)`
    position: relative;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
`;

const sharedTextStyles = css`
    width: 300px;
    float: right;
`;

export const StyledTextArea = styled(TextArea)`
    ${sharedTextStyles}
`;

export const StyledInput = styled(Input)`
    ${sharedTextStyles}
`;

export enum DialogType {
    Add = 'add',
    Edit = 'edit',
    Remove = 'remove',
    AddRule = 'addRule',
    EditRule = 'editRule',
    RemoveRule = 'removeRule',
    View = 'view',
}

type ActionDataBase<TEntity> =
    | {
          readonly type: DialogType.Add;
          readonly data: TEntity[]; // existing instances (for filtering out in UI)
      }
    | {
          readonly type: DialogType.AddRule;
          readonly data: TEntity;
      }
    | {
          readonly type: DialogType.Edit;
          readonly data: TEntity;
      }
    | {
          readonly type: DialogType.EditRule;
          readonly data: CoverageRule;
      }
    | {
          readonly type: DialogType.Remove;
          readonly data: TEntity;
      };

export type SettingActionData = ActionDataBase<SettingInstance> & { readonly editionCoverages: CoverageInstance[] };

export type CoverageActionData = ActionDataBase<CoverageInstance>;

export const menuItems: ActionOption[] = [
    {
        label: 'Edit',
        value: 'edit',
    },
    {
        label: 'Remove',
        value: 'remove',
    },
];

export const generateGUID = (): string => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
};
