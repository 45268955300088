import type { Locale } from '@lemonade-hq/lemonation';
import { ServiceName } from '../../../config';
import { getAxiosClient } from '../../../utils';
import type { MacrosBoardGroup, MacrosCard, MacrosSupportedEntity, MacrosSupportedProductType } from '../types';

const blenderGeneral = getAxiosClient(ServiceName.BlenderGeneral);
const URL = '/api/v1/macros';

export async function getCard(cardId: string): Promise<MacrosCard> {
  return await blenderGeneral.get<{ data: MacrosCard }>(`${URL}/cards/${cardId}`).then(res => res.data.data);
}

export async function getBoardGroup({
  entityType,
  locale,
  productType,
}: {
  readonly entityType: MacrosSupportedEntity;
  readonly locale: Locale;
  readonly productType: MacrosSupportedProductType;
}): Promise<MacrosBoardGroup> {
  return await blenderGeneral
    .get<{
      data: MacrosBoardGroup;
    }>(`${URL}/metadata?entityType=${entityType}&locale=${locale}&productType=${encodeURIComponent(productType)}`)
    .then(res => res.data.data);
}
