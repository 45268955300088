import type { FC } from 'react';
import { NonExpandableChange } from '../NonExpandableChange';
import { findSetting } from './common';
import { getSettingTypeDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import type { ReferenceIdEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';

interface SettingRemovedProps {
    readonly change: ReferenceIdEntry;
    readonly baseCoveragesEdition: CoveragesEdition;
}

export const SettingRemoved: FC<SettingRemovedProps> = ({ change, baseCoveragesEdition }) => {
    const removedSetting = findSetting(baseCoveragesEdition, change.metadata.referenceId);

    return (
        <NonExpandableChange
            title={`${getSettingTypeDisplayName(removedSetting.type)} removed - ${removedSetting.name}`}
        />
    );
};
