import { Spinner } from '@lemonade-hq/bluis';
import { font } from '@lemonade-hq/boutique';
import { Flex } from '@lemonade-hq/cdk';
import React from 'react';
import { useIsFetching } from '@tanstack/react-query';
import styled from 'styled-components';
import { ThemedColors } from './Colors';

const FetchingWrapper = styled(Flex)`
    height: 18px;
    ${font('main', { fontSize: '14px' })};
    color: ${ThemedColors.primaryText};
`;

const StyledSpinner = styled(Spinner)`
    margin-right: 8px;
    margin-top: 0;
`;

const GlobalFetchingIndicator: React.FC<React.PropsWithChildren<{ readonly className?: string }>> = ({ className }) => {
    const isFetching = useIsFetching();

    if (!isFetching) return null;

    return (
        <FetchingWrapper alignItems="center" className={className}>
            <StyledSpinner size={18} />
            <span>Fetching...</span>
        </FetchingWrapper>
    );
};

export default GlobalFetchingIndicator;
