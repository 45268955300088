import { Box, Flex } from '@lemonade-hq/cdk';
import React from 'react';
import pluralize from 'pluralize';
import { Tooltip } from '@lemonade-hq/boutique';
import styled from 'styled-components';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import { Tag } from '../../../shared/components/Tag';
import { useSupervisorContext } from '../Context';

const Item = styled.div`
    text-transform: capitalize;
`;

function listAsTooltipContent(list: string[]) {
    return (
        <>
            {list.map(item => (
                <Item key={item}>{snakeCaseToReadable(item)}</Item>
            ))}
        </>
    );
}

const LabelWithTooltip: React.FC<{ name: string; selectedItems: string[] }> = ({ name, selectedItems }) => {
    if (!selectedItems.length) return null;

    const label = pluralize(name, selectedItems.length).replace('S', 's'); // LOBS -> LOBs

    return (
        <Tooltip alignment="bottom" content={listAsTooltipContent(selectedItems)}>
            <Box>
                <Tag>{`${selectedItems.length} ${label}`}</Tag>
            </Box>
        </Tooltip>
    );
};

export const FiltersInfo: React.FC = () => {
    const {
        state: {
            filters: { intents, products, mediums, languages },
        },
    } = useSupervisorContext();

    const filters = [
        { name: 'LOB', selectedItems: products },
        { name: 'Intent', selectedItems: intents },
        { name: 'Medium', selectedItems: mediums },
        { name: 'Language', selectedItems: languages },
    ];

    if (filters.every(filter => filter.selectedItems.length === 0)) return null;

    return (
        <Flex alignItems="center" gap="6px">
            <span>Filters: </span>
            {filters.map(LabelWithTooltip)}
        </Flex>
    );
};
