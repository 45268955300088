import { getUrlResolver, ServiceNames } from '@lemonade-hq/bluiza';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import axios from 'axios';

export interface AssignTicketToEntityParams {
    readonly ticketExternalId: string;
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly ticketPublicId: string;
}

const fallbackMap: Set<string> = new Set<string>();

export const blenderGeneralUrlResolver = getUrlResolver({
    service: ServiceNames.BlenderGeneral,
    fallbackMap,
});

export async function assignTicketToEntity({
    ticketExternalId,
    entityType,
    entityPublicId,
    ticketPublicId,
}: AssignTicketToEntityParams): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/emails/zendesk_tickets/${ticketExternalId}/related_entity`);

    return await axios.post(url, { entityType, entityPublicId, ticketPublicId });
}
