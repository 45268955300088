import { clsx } from 'clsx';
import type { ComponentProps, ElementType, PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import type { Simplify } from 'type-fest';
import { Layout } from '../Layout/Layout';
import type { LayoutSprinklesProps } from '../Layout/Layout.css';
import { text } from './Text.css';
import type { TextVariants } from './Text.css';

const DEFAULT_ELEMENT = 'span';

const componentTypeMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  'text-lg': 'p',
  'text-md': 'p',
  'text-sm': 'p',
  'label-md': 'label',
  'label-sm': 'label',
  'label-xs': 'label',
  li: 'li',
} as const satisfies Record<keyof NonNullable<TextVariants>['type'], keyof JSX.IntrinsicElements>;

export type SupportedIntrinsicElements =
  | (typeof componentTypeMap)[keyof typeof componentTypeMap]
  | typeof DEFAULT_ELEMENT;

export type TextProps<TElementType extends SupportedIntrinsicElements> = Simplify<
  LayoutSprinklesProps &
    Omit<JSX.IntrinsicElements[TElementType], keyof TextVariants> &
    TextVariants & { readonly as?: TElementType; readonly htmlFor?: string }
>;

const getComponentType = (type: ComponentProps<typeof Text>['type']): SupportedIntrinsicElements => {
  if (type === undefined || !(type in componentTypeMap)) return DEFAULT_ELEMENT;

  return componentTypeMap[type];
};

export const Text = forwardRef<HTMLElement, PropsWithChildren<TextProps<SupportedIntrinsicElements>>>(
  (
    {
      children,
      as,
      type,
      textTransform,
      textAlign,
      color = 'primary' as const,
      fontWeight,
      className: externalClassName,
      ...props
    },
    ref,
  ): JSX.Element => {
    const asComponent = (as ?? getComponentType(type)) as ElementType;
    const className = clsx(text({ type, textTransform, textAlign, color, fontWeight }), externalClassName);

    return (
      <Layout as={asComponent} className={className} {...(props as Record<string, unknown>)} ref={ref}>
        {children}
      </Layout>
    );
  },
);
