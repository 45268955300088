import type { DialogAction, FormElementConfigurableProps, FormState } from '@lemonade-hq/bluis';
import { createFormRow, Dialog, FormBuilder, formBuilderCreator, InputAndLabel } from '@lemonade-hq/bluis';
import type { FormBuilderProps } from '@lemonade-hq/cdk';
import { createForm } from '@lemonade-hq/cdk';
import { required } from '@lemonade-hq/ts-helpers';
import type { SchemaData } from '@lemonade-hq/ts-helpers';
import { useIsMutating } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSupervisorContext } from '../Context';
import { FixReason, Tag } from 'components/Llmnd/shared/types';
const { input } = formBuilderCreator;

const FIELD_WIDTH = 250;

const FormDialogWrapper = styled.div`
    [role='main'] {
        flex: none;
        height: 80px;
        width: 250px;
    }

    ${InputAndLabel}:has([for='additionalInfo']) {
        align-items: baseline;
    }
`;

interface FixIntentDialogProps {
    readonly onClose: () => void;
}

// TODO: replace with the new form dialog once it's available
export const FixIntentDialog: React.FC<FixIntentDialogProps> = ({ onClose }) => {
    const {
        actions: { reviewAiResponse },
        state: {
            ticketData,
            isLoadingTicket,
            metadata: {
                intents: { nonTools, tools },
            },
        },
        actions: { loadNextTicket },
    } = useSupervisorContext();
    const [formState, setFormState] = useState<FormState<SchemaData>>();
    const isMutating = Boolean(useIsMutating());

    const intentsOptions = useMemo(() => {
        const mapOptions = (options: Record<string, string>): { id: string; label: string; value: string }[] =>
            Object.entries(options).map(([value, label]) => ({
                id: value,
                label,
                value,
            }));

        return [...mapOptions(nonTools), ...mapOptions(tools)];
    }, [nonTools, tools]);

    if (ticketData == null) return null;

    const initialValues = { intent: ticketData.toolInvoked };

    const formConfig = (): FormBuilderProps<FormElementConfigurableProps> =>
        createForm({
            content: [
                createFormRow({
                    elements: [
                        input.autocomplete({
                            attribute: 'intent',
                            label: 'Intent',
                            options: intentsOptions,
                            width: FIELD_WIDTH,
                            required: true,
                        }),
                    ],
                }),

                createFormRow({
                    elements: [
                        input.textArea({
                            attribute: 'additionalInfo',
                            label: 'Additional info',
                            placeholder: "What's wrong? How can we do better?",
                            required: true,
                        }),
                    ],
                }),
            ],
        });

    async function onSubmit(): Promise<void> {
        if (formState == null) return;

        await reviewAiResponse({
            reason: FixReason.IncorrectParameters, // TODO: verify with backend that this is the forced reason
            additionalInfo: formState.answers.additionalInfo as string,
            ticketId: ticketData?.publicId ?? '',
            tag: Tag.Fail,
            updatedParams: {
                api: formState.answers.intent as string,
            },
        });

        if (ticketData?.actions.skipAfterReview) {
            await loadNextTicket();
        }

        onClose();
    }

    const intentNotChanged = formState?.answers.intent === '' || formState?.answers.intent === initialValues.intent;

    const formActions: DialogAction[] = [
        {
            type: 'submit',
            text: 'Confirm',
            onClick: onSubmit,
            disabled: Boolean(formState?.submitDisabled) || intentNotChanged || isMutating || isLoadingTicket,
        },
    ];

    return (
        <Dialog
            actions={formActions}
            closeOnOutsideClick
            loading={isMutating || isLoadingTicket}
            onClose={onClose}
            textAlign="left"
            title="Fix Classified Intent"
        >
            <FormDialogWrapper>
                <FormBuilder
                    form={formConfig}
                    initialValues={initialValues}
                    onFormStateChange={setFormState}
                    validationSchema={{ additionalInfo: [required()] }}
                />
            </FormDialogWrapper>
        </Dialog>
    );
};
