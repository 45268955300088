import { SearchIcon } from '@lemonade-hq/bluis';
import { Flex, font, themedColor } from '@lemonade-hq/cdk';
import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import ClearButton from 'components/Bluis/ClearButton';

const SearchWrapper = styled(Flex)`
    max-width: 300px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${themedColor('separator')};
`;

const StyledSearchInput = styled.input`
    border: 0;
    outline: 0;
    margin-left: 11px;
    width: 80%;
    background-color: transparent;
    color: ${themedColor('primaryText')};
    &::placeholder {
        color: ${themedColor('secondaryText')};
    }
    ${font('main', { fontSize: '13px', lineHeight: '16px' })}
`;

interface SearchComponentProps {
    readonly initialSearchTerm?: string;
    readonly onChange: (value: string) => void;
    readonly placeholder?: string;
    readonly disabled?: boolean;
}

export const SearchInput: React.FC<SearchComponentProps> = ({ onChange, initialSearchTerm, placeholder, disabled }) => {
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedNotify = useCallback(
        debounce(value => onChange(value), 300),
        []
    );

    return (
        <SearchWrapper>
            <SearchIcon />
            <StyledSearchInput
                disabled={disabled}
                onChange={event => {
                    setSearchTerm(event.target.value);
                    debouncedNotify(event.target.value);
                }}
                placeholder={placeholder ?? 'Search Names'}
                value={searchTerm}
            />
            {searchTerm.length > 0 && (
                <ClearButton
                    onClick={() => {
                        setSearchTerm('');
                        debouncedNotify('');
                    }}
                />
            )}
        </SearchWrapper>
    );
};
