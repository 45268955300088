import type { UseSuspenseQueryResult } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import { useSuspenseQueryWithErrorHandling } from 'components/LoCo/common/hooks/useSuspenseQueryWithErrorHandling';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import type { RatingMetadata } from 'models/LoCo/Insurance/Rating';
import type { SchemaResponse } from 'models/LoCo/Insurance/Schema';

const BASE_PATH = '/api/v1/rating-editions';

export enum RatingEditionQueryKey {
    GetRatingEdition = 'GET_RATING_EDITION',
    GetRatingEditionSchema = 'GET_RATING_EDITION_SCHEMA',
    GetRatingEditionMetadata = 'GET_RATING_EDITION_METADATA',
}

export async function getRatingEdition(editionCode: string): Promise<Edition> {
    const response = await insuranceBlender.get<{ data: Edition }>(`${BASE_PATH}/${editionCode}`);

    return response.data.data;
}

export function useSuspenseGetRatingEdition(
    editionCode: string,
    onSuccess?: (edition: Edition) => void
): UseSuspenseQueryResult<Edition> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [RatingEditionQueryKey.GetRatingEdition, editionCode],
        queryFn: async () => {
            const result = await getRatingEdition(editionCode);
            onSuccess?.(result);
            return result;
        },
    });
}

async function getRatingEditionSchema(editionCode: string): Promise<SchemaResponse> {
    const response = await insuranceBlender.get<{ data: SchemaResponse }>(`${BASE_PATH}/${editionCode}/schema`);

    return response.data.data;
}

export function useSuspenseGetRatingEditionSchema(editionCode: string): UseSuspenseQueryResult<SchemaResponse> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [RatingEditionQueryKey.GetRatingEditionSchema, editionCode],
        queryFn: async () => await getRatingEditionSchema(editionCode),
    });
}

async function getRatingEditionMetadata(editionCode: string): Promise<RatingMetadata> {
    const response = await insuranceBlender.get<{ data: RatingMetadata }>(`${BASE_PATH}/${editionCode}/metadata`);

    return response.data.data;
}

export function useSuspenseGetRatingEditionMetadata(editionCode: string): UseSuspenseQueryResult<RatingMetadata> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [RatingEditionQueryKey.GetRatingEditionMetadata, editionCode],
        queryFn: async () => await getRatingEditionMetadata(editionCode),
    });
}
