import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { Text } from '@lemonade-hq/cdk';
import React, { useCallback, useMemo } from 'react';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import type { CoverageInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import { useRemoveEditionCoverage } from 'queries/LoCo/Insurance/CoveragesEditionQueries';

interface RemoveCoverageDialogProps {
    readonly editionCode: string;
    readonly coverage: CoverageInstance & { readonly name: string };
    readonly onClose: () => void;
}

export const RemoveCoverageDialog: React.FC<RemoveCoverageDialogProps> = ({ editionCode, coverage, onClose }) => {
    const {
        mutateAsync: removeEditionSettingInstance,
        isPending: isLoading,
        isError,
    } = useRemoveEditionCoverage(editionCode);

    const submit = useCallback(async () => {
        await removeEditionSettingInstance({
            editionCode,
            coverageTemplateCodes: [coverage.templateCode],
        });
        onClose();
    }, [coverage, editionCode, onClose, removeEditionSettingInstance]);

    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Remove',
                type: 'submit',
                onClick: submit,
                disabled: isLoading,
            },
        ],
        [isLoading, onClose, submit]
    );

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={isError ? GENERAL_ERROR_MSG : undefined}
            loading={isLoading}
            onClose={onClose}
            title="Remove Coverage"
        >
            <Text variant="body-md">
                You are about to remove coverage <b>{coverage.name}</b>
            </Text>
        </Dialog>
    );
};
