/* eslint-disable no-param-reassign */

import { Editor, Range, Transforms } from 'slate';
import type { CustomEditor, LinkElement, ListElement } from '../types';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];
export const defaultElement = 'div';

export function isMarkActive(editor: CustomEditor, format: string): boolean {
    const marks = Editor.marks(editor);

    return marks?.[format];
}

export function copyText(editor: CustomEditor): void {
    const oldSelection = editor.selection;

    Transforms.select(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
    });

    document.execCommand('copy');

    if (oldSelection) {
        Transforms.select(editor, oldSelection);
    } else {
        Transforms.deselect(editor);
    }
}

export function toggleMark(editor: CustomEditor, format: string): void {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
}

export function isBlockActive(editor: CustomEditor, format: string): boolean {
    const [match] = Array.from(
        Editor.nodes(editor, {
            // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
            match: n => n.type === format,
        })
    );

    return Boolean(match);
}

export function toggleBlock(editor: CustomEditor, format: string): void {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
        match: n => LIST_TYPES.includes(n.type as string),
        split: true,
    });

    Transforms.setNodes(editor, {
        // eslint-disable-next-line
        // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
        type: isActive ? defaultElement : isList ? 'list-item' : format,
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };

        Transforms.wrapNodes(editor, block as ListElement);
    }
}

export const isLinkActive = (editor: CustomEditor): boolean => {
    // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
    const [link] = Array.from(Editor.nodes(editor, { match: n => n.type === 'link' }));

    return Boolean(link);
};

export const unwrapLink = (editor: CustomEditor, selection?: Range): void => {
    // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
    Transforms.unwrapNodes(editor, { match: n => n.type === 'link', at: selection });
};

export const wrapLink = (editor: CustomEditor, selection: Range | undefined, url: string): void => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    const protocols = ['http://', 'https://'];

    if (!protocols.some(p => url.startsWith(p))) {
        url = `http://${url}`;
    }

    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        url,
        type: 'link',
        children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
        // @ts-expect-error -- After moving Bluiza to blender-frontend, there are type collisions with the new richTextEditor. Since the old richTextEditor is already deprecated, we can safely ignore these type errors.
        Transforms.insertNodes(editor, link, { at: selection });
    } else {
        Transforms.wrapNodes(editor, link as LinkElement, { split: true, at: selection });
        Transforms.collapse(editor, { edge: 'end' });
    }
};

export const withLinks = (editor: CustomEditor): CustomEditor => {
    const { isInline } = editor;

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element);
    };

    return editor;
};

export const insertEmoji = (editor: Editor, selection: Range, emoji: string): void => {
    Transforms.insertText(editor, emoji, { at: selection });
};

export const emojis = [
    '😀',
    '😁',
    '😂',
    '😃',
    '😉',
    '😋',
    '😎',
    '😍',
    '😗',
    '🤗',
    '🤔',
    '😣',
    '😫',
    '😴',
    '😌',
    '🤓',
    '😛',
    '😜',
    '😠',
    '😇',
    '😷',
    '😈',
    '👻',
    '😺',
    '😸',
    '😹',
    '😻',
    '😼',
    '😽',
    '🙀',
    '🙈',
    '🙉',
    '🙊',
    '👼',
    '👮',
    '🕵',
    '💂',
    '👳',
    '🎅',
    '👸',
    '👰',
    '👲',
    '🙍',
    '🙇',
    '🚶',
    '🏃',
    '💃',
    '⛷',
    '🏂',
    '🏌',
    '🏄',
    '🚣',
    '🏊',
    '⛹',
    '🏋',
    '🚴',
    '👫',
    '💪',
    '👈',
    '👉',
    '👆',
    '🖕',
    '👇',
    '🖖',
    '🤘',
    '🖐',
    '👌',
    '👍',
    '👎',
    '✊',
    '👊',
    '👏',
    '🙌',
    '🙏',
    '🐵',
    '🐶',
    '🐇',
    '🐥',
    '🐸',
    '🐌',
    '🐛',
    '🐜',
    '🐝',
    '🍉',
    '🍄',
    '🍔',
    '🍤',
    '🍨',
    '🍪',
    '🎂',
    '🍰',
    '🍾',
    '🍷',
    '🍸',
    '🍺',
    '🌍',
    '🚑',
    '⏰',
    '🌙',
    '🌝',
    '🌞',
    '⭐',
    '🌟',
    '🌠',
    '🌨',
    '🌩',
    '⛄',
    '🔥',
    '🎄',
    '🎈',
    '🎉',
    '🎊',
    '🎁',
    '🎗',
    '🏀',
    '🏈',
    '🎲',
    '🔇',
    '🔈',
    '📣',
    '🔔',
    '🎵',
    '🎷',
    '💰',
    '🖊',
    '📅',
    '✅',
    '❎',
    '💯',
    '❤',
    '🌱',
    '👑',
    '🏡',
    '🇺🇸',
    '💕',
    '🦠',
    '💦',
    '💨',
    '💥',
    '🌪',
    '⚡',
    '❄',
];
