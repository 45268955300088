import { ProductTypes } from '@lemonade-hq/bluiza';
import { SearchResults } from 'components/Header/SearchBar/helpers';
import { blenderGeneral } from '../apiClients';

const EMPTY_UNIFIED_RESPONSE: SearchResults = {
    claims: [],
    policies: [],
    quotes: [],
    users: [],
};

export interface TextSearchOptions {
    limit?: number;
    offset?: string;
    sort?: string;
    order?: 'asc' | 'desc';
}

export interface SearchResultEntity {
    product: ProductTypes;
    external_id: string;
}

export async function textSearch(term: string, options?: Partial<TextSearchOptions>): Promise<SearchResults> {
    const normalizedOptions = {
        ...(options ?? {}),
        term,
    };

    return blenderGeneral
        .post<{ data: SearchResults }>('/api/v1/search/text', normalizedOptions)
        .then(response => response.data.data);
}

export async function unifiedTextSearch(term: string, options?: Partial<TextSearchOptions>): Promise<SearchResults> {
    const payload = {
        ...(options ?? {}),
        term,
    };

    return blenderGeneral
        .post<{ data: SearchResults }>('/api/v1/search/text', payload)
        .then(response => ({ ...EMPTY_UNIFIED_RESPONSE, ...response.data.data }));
}
