import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import * as reducers from '../reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunk), sentryReduxEnhancer)(createStore);
const reducer = combineReducers({
    ...reducers,
});

export default initialState => createStoreWithMiddleware(reducer, initialState);
