import { EnvName, getEnvId, serviceUrls } from '@lemonade-hq/bluiza';
import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';

const URL_SERVICE_ENV_ID_REGEX = new RegExp(`^https://(.*)-${getEnvId()}`);

const fallbackMap = new Set<string>();

export function getBlenderDomain(envName: EnvName): string {
    const currentHost = window.location.hostname;

    switch (envName) {
        case EnvName.Production:
            return /(-agents)\.lemonade\.com$/.exec(currentHost)?.[1] ?? '';
        case EnvName.Staging:
            return /(\.public)\.lmndstaging\.com$/.exec(currentHost)?.[1] ?? '';
        case EnvName.Development:
            return /(\.public)\.lmnddev\.com$/.exec(currentHost)?.[1] ?? '';
        default:
            return '';
    }
}

export async function fallback(error: AxiosError): Promise<AxiosResponse<unknown>> {
    const { config } = error;

    if (config.url == null || !URL_SERVICE_ENV_ID_REGEX.test(config.url)) {
        throw new Error('failed falling back to master');
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [, service] = config.url.match(URL_SERVICE_ENV_ID_REGEX)!;

    fallbackMap.add(service);

    config.url = changeEnvToMaster(config.url);

    console.log(`****** using ${service}'s master ******`);

    return await axios.request(config);
}

function changeEnvToMaster(url: string) {
    const currentEnv = getEnvId();

    if (currentEnv == null) return url;

    return url.replace(currentEnv, 'master');
}

/**
 * @deprecated use getUrlResolver from `@lemonade-hq/bluiza` instead
 */
export function getUrlResolver(
    service:
        | 'blender-general'
        | 'blender'
        | 'car-blender'
        | 'home-blender'
        | 'home-eu-blender'
        | 'life-blender'
        | 'monolith'
        | 'pet-blender'
        | 'telematics',
    basePath: Path | '' = ''
) {
    let baseUrl: string | undefined = serviceUrls(import.meta.env.VITE_ENV_NAME)[service];

    if (baseUrl == null) {
        throw new Error(`missing a url for service: ${service}`);
    }

    baseUrl = `${baseUrl}${basePath}`;

    return function getEnvUrl(path: Path = '') {
        const envName = import.meta.env.VITE_ENV_NAME as EnvName;
        const url = `${baseUrl}${path}`.replace('{{BLENDER_DOMAIN}}', getBlenderDomain(envName));

        if (import.meta.env.VITE_ENV_NAME !== 'staging' && import.meta.env.VITE_IS_DEV_KUBERNETES !== 'true')
            return url;

        const currentEnv = getEnvId();

        if (currentEnv == null) return url;

        const resolvedUrl = url.replace('{{ENV_ID}}', currentEnv);

        if (!URL_SERVICE_ENV_ID_REGEX.test(resolvedUrl)) return resolvedUrl;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const [, service] = resolvedUrl.match(URL_SERVICE_ENV_ID_REGEX)!;

        if (fallbackMap.has(service)) {
            console.log(`****** using ${service}'s master ******`);

            return changeEnvToMaster(resolvedUrl);
        }

        return resolvedUrl;
    };
}

type Path<T extends string = string> = '' | `?${T}` | `/${T}`;
