import { useCallback } from 'react';
import type { FC } from 'react';
import { RemoveRuleDialog } from 'components/LoCo/common/components/RemoveRuleDialog';
import type { UWRuleType } from 'components/LoCo/common/display-texts/underwriting-rules';
import { useRemoveUnderwritingFiltersEditionRule } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

interface RemoveRuleProps {
    readonly onClose: () => void;
    readonly ruleId: string;
    readonly ruleType: UWRuleType;
    readonly editionCode: string;
    readonly variantName?: string;
}

export const RemoveRule: FC<RemoveRuleProps> = ({ onClose, editionCode, ruleId, ruleType, variantName }) => {
    const { mutateAsync, isPending } = useRemoveUnderwritingFiltersEditionRule(editionCode);
    const onSubmit = useCallback(async () => {
        await mutateAsync({ editionCode, ruleId, ruleType, variants: variantName != null ? [variantName] : undefined });
        onClose();
    }, [editionCode, mutateAsync, onClose, ruleId, ruleType, variantName]);

    return <RemoveRuleDialog isLoading={isPending} onClose={onClose} onSubmit={onSubmit} />;
};
