import React from 'react';
import { ArchiveEditionDialogDraft } from 'components/LoCo/products/ProductMissionControl/dialogs/ArchiveEditionDialogDraft';
import { CreateDraftEditionDialog } from 'components/LoCo/products/ProductMissionControl/dialogs/CreateDraftEditionDialog';
import type { EditionType } from 'models/LoCo/Insurance/BaseEdition';

export enum DialogType {
    CreateDraftEdition = 'create_draft_edition',
    ArchiveDraftEdition = 'archive_draft_edition',
}

export type MissionControlActionData =
    | {
          readonly type: DialogType.ArchiveDraftEdition;
          readonly editionCode: string;
          readonly editionType: EditionType;
          readonly editionFriendlyName: string;
      }
    | {
          readonly type: DialogType.CreateDraftEdition;
      };

interface DialogsProps {
    readonly productCode: string;
    readonly dialogData: MissionControlActionData;
    readonly onClose: () => void;
}

export const MissionControlDialogs: React.FC<DialogsProps> = ({ productCode, dialogData, onClose }) => {
    switch (dialogData.type) {
        case DialogType.CreateDraftEdition:
            return <CreateDraftEditionDialog onClose={onClose} productCode={productCode} />;
        case DialogType.ArchiveDraftEdition:
            return (
                <ArchiveEditionDialogDraft
                    editionCode={dialogData.editionCode}
                    editionFriendlyName={dialogData.editionFriendlyName}
                    editionType={dialogData.editionType}
                    onClose={onClose}
                />
            );
        default:
            return null;
    }
};
