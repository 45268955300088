import { ActionsMenu, EmptySection } from '@lemonade-hq/bluis';
import { capitalize } from '@lemonade-hq/ts-helpers';
import { useMemo } from 'react';
import type { Writable } from 'type-fest';
import type { RuleActionData } from '../dialogs/UnderwritingRulesDialogs';
import { UnderwritingDialogType } from '../UnderwritingFiltersShared';
import { ruleExpressionDisplay } from 'components/LoCo/common/components/CoverageRules/CoverageRulesShared';
import type { Column, Columns, ColumnsToRow } from 'components/LoCo/common/components/GridTable/GridTable';
import { GridTable } from 'components/LoCo/common/components/GridTable/GridTable';
import { Arrows } from 'components/LoCo/common/components/Table/Arrows';
import type { Direction } from 'components/LoCo/common/components/Table/Arrows';
import { getOutcomeText } from 'components/LoCo/common/display-texts/underwriting-rules';
import type { UWRuleType } from 'components/LoCo/common/display-texts/underwriting-rules';
import { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type {
    UnderwritingDecisionLifecycleContext,
    UnderwritingDeclineRule,
    UnderwritingFlagRule,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

const orderColumn = { key: 'order', title: '#', width: '100px' } satisfies Column<'order'>;
const segment = { key: 'segment', title: 'Segment' } satisfies Column<'segment'>;
const outcomeDeclineColumn = { key: 'outcome', title: 'Decline reason' } satisfies Column<'outcome'>;
const outcomeNonRenewalColumn = { key: 'outcome', title: 'Non-Renewal reason' } satisfies Column<'outcome'>;
const actionsColumn = { key: 'actions', title: '', width: '20px' } satisfies Column<'actions'>;
const reorder = { key: 'reorder', title: '', width: '60px' } satisfies Column<'reorder'>;

const declineRulesColumns = [orderColumn, segment, outcomeDeclineColumn, actionsColumn] as const;
const nonRenewalRulesColumns = [orderColumn, segment, outcomeNonRenewalColumn, actionsColumn] as const;
const flagRulesColumns = [segment, actionsColumn] as const;

type DeclineRulesColumns<TRuleType extends UWRuleType> = TRuleType extends 'flag'
    ? typeof flagRulesColumns
    : typeof declineRulesColumns;

type UWRuleByRuleType<TRuleType extends UWRuleType> = TRuleType extends 'flag'
    ? UnderwritingFlagRule
    : UnderwritingDeclineRule;

type GetOrderedRuleRowProps<TRuleType extends UWRuleType> = {
    readonly type: TRuleType;
    readonly rule: UWRuleByRuleType<TRuleType>;
    readonly order: number;
    readonly hideActions: boolean;
    readonly editionCode: string;
    readonly onAction: (actionData: RuleActionData) => void;

    readonly onReorderClick: (direction: Direction) => void;
    readonly isReorderMode: boolean;
    readonly disabledDirections: Direction[];
    readonly disableCursor: boolean;
    readonly variantName?: string;
};

function getOrderedRuleRow<TRuleType extends UWRuleType>({
    type,
    rule,
    order,
    onAction,
    hideActions,
    editionCode,
    isReorderMode,
    disableCursor,
    onReorderClick,
    disabledDirections,
    variantName,
}: GetOrderedRuleRowProps<TRuleType>): ColumnsToRow<DeclineRulesColumns<TRuleType>> {
    const editAction = (
        type === 'decline'
            ? {
                  type: UnderwritingDialogType.EditDeclineRule,
                  data: rule as UWRuleByRuleType<'decline'>,
              }
            : {
                  type: UnderwritingDialogType.EditFlagRule,
                  data: { ...(rule as UWRuleByRuleType<'flag'>), variantName },
              }
    ) satisfies RuleActionData;

    const removeAction = {
        type: type === 'decline' ? UnderwritingDialogType.RemoveDeclineRule : UnderwritingDialogType.RemoveFlagRule,
        data: { ruleId: rule.publicId, editionCode, ...(type === 'flag' ? { variantName } : undefined) },
    } satisfies RuleActionData;

    const onChange = (value: string): void => onAction(value === 'edit' ? editAction : removeAction);

    const flagRow = {
        segment: { value: ruleExpressionDisplay(rule.expression, true) },
        actions: {
            value: hideActions ? (
                ''
            ) : (
                <ActionsMenu
                    actions={[
                        {
                            label: 'Edit Rule',
                            value: 'edit',
                        },
                        {
                            label: 'Remove Rule',
                            value: 'remove',
                        },
                    ]}
                    onChange={onChange}
                    type="dots"
                />
            ),
        },
    } satisfies ColumnsToRow<DeclineRulesColumns<'flag'>>;

    if (isReorderMode) {
        const reorderRow = flagRow as unknown as Writable<ColumnsToRow<Columns>>;

        reorderRow.reorder = {
            value: (
                <Arrows
                    disableCursor={disableCursor}
                    disabledDirections={disabledDirections}
                    onClick={onReorderClick}
                />
            ),
        };
    }

    if (type === 'flag') return flagRow as ColumnsToRow<DeclineRulesColumns<TRuleType>>;

    const declineRow = { ...flagRow, order: { value: order.toString() } } as Writable<
        ColumnsToRow<DeclineRulesColumns<'decline'>>
    >;
    const declineRule = rule as UnderwritingDeclineRule;

    declineRow.outcome = {
        value: capitalize(`${getOutcomeText(declineRule.lifecycleContext)} with reason: ${declineRule.reasonDisplay}`),
    };

    return declineRow as ColumnsToRow<DeclineRulesColumns<TRuleType>>;
}

interface RulesGroupProps<TRuleType extends UWRuleType> {
    readonly className?: string;
    readonly type: TRuleType;
    readonly rules: UWRuleByRuleType<TRuleType>[];
    readonly hideActions: boolean;
    readonly editionCode: string;
    readonly onAction: (actionData: RuleActionData) => void;
    readonly isReorderMode: boolean;
    readonly getReorderedRowClassName: (item: string) => string | undefined;
    readonly handleReorder: (item: string, direction: Direction, index: number) => Promise<void>;
    readonly isLoading: boolean;
    readonly lifecycle?: UnderwritingDecisionLifecycleContext;
    readonly variantName?: string;
}

export const RulesTable = <TRuleType extends UWRuleType>({
    className,
    rules,
    type,
    onAction,
    isLoading,
    hideActions,
    editionCode,
    isReorderMode,
    lifecycle,
    variantName,
    handleReorder,
    getReorderedRowClassName,
}: RulesGroupProps<TRuleType>): JSX.Element => {
    const columns = useMemo(() => {
        const returnValue = structuredClone(
            type === 'decline'
                ? lifecycle === RuleLifecycleContext.NewBusiness
                    ? declineRulesColumns
                    : nonRenewalRulesColumns
                : flagRulesColumns
        ) as unknown as Writable<Columns>;

        if (isReorderMode) {
            returnValue.splice(1, 0, reorder);
        }

        return returnValue;
    }, [isReorderMode, lifecycle, type]);

    const rows = rules.map((rule, index) => ({
        values: getOrderedRuleRow({
            type,
            rule,
            onAction,
            hideActions,
            editionCode,
            order: index + 1,
            isReorderMode,
            disableCursor: isLoading,
            variantName,
            onReorderClick: direction => void handleReorder(rule.publicId, direction, index),
            disabledDirections: index === 0 ? ['Up'] : index === rules.length - 1 ? ['Down'] : [],
        }) as ColumnsToRow<Columns>,
        className: getReorderedRowClassName(rule.publicId),
    }));

    if (rules.length === 0) {
        return <EmptySection>No Rules Configured</EmptySection>;
    }

    return <GridTable className={className} columns={columns} rows={rows} />;
};
