import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@lemonade-hq/cdk';
import { font } from '@lemonade-hq/boutique';
import { getCountryFlag } from '@lemonade-hq/lemonation';
import { CoreEntityType, ProductTypes } from '@lemonade-hq/bluiza';
import { Image } from '@lemonade-hq/bluis';
import { ThemedColors } from 'bluis/Colors';
import { formatPhoneNumber } from 'commons/PhoneNumberUtils';
import { Container, LeftSection, MainInfo, SecondaryInfo, SecondaryInfoUpperCase } from './shared';
import BoldedResult from './BoldedResult';
import { SearchItemUser } from './helpers';
import ConditionalLink from './ConditionalLink';

import { formatAddress } from '../../../Commons/AddressUtils';

const Country = styled(Box)`
    font-size: 12px;
`;

export const Email = styled(Box)<{ capitalize?: boolean }>`
    ${font('main', { fontSize: '12px', lineHeight: 'normal', fontWeight: 400 })}
    color: ${ThemedColors.secondaryText};
`;

const ResultUser: FC<
    React.PropsWithChildren<{
        term: string;
        user: SearchItemUser;
        onSearchResultClick: (params: { url: string; entity: CoreEntityType; product: ProductTypes; index: number }) => void;
        index: number;
    }>
> = ({ term, user, onSearchResultClick, index }) => {
    const {
        name,
        email,
        external_id: externalId,
        phone_number: phoneNumber,
        url,
        country,
        address_components: addressComponents,
    } = user;
    const state = addressComponents != null ? addressComponents.state : '';
    const formattedPhoneNumber = phoneNumber && formatPhoneNumber(phoneNumber);
    const formattedAddress = formatAddress(addressComponents);

    return (
        <>
            <ConditionalLink
                onClick={() =>
                    onSearchResultClick({ url, entity: CoreEntityType.User, product: ProductTypes.User, index })
                }
                url={url}
            >
                <Container>
                    <LeftSection>
                        <MainInfo>
                            <BoldedResult term={term} value={name} />
                        </MainInfo>
                        <SecondaryInfoUpperCase mb="2px">
                            <BoldedResult term={term} value={externalId} />
                        </SecondaryInfoUpperCase>
                        <SecondaryInfo mb="2px">{formattedAddress}</SecondaryInfo>
                        {email && (
                            <Box mb="2px">
                                <Flex alignItems="center">
                                    <Image
                                        img={{
                                            lightSrc: 'email-icon-gray.svg',
                                            darkSrc: 'email-icon-gray-dark.svg',
                                            width: 12,
                                            height: 12,
                                        }}
                                        classNames="icon-search-preview"
                                    />
                                    <Email ml="3px">{email}</Email>
                                </Flex>
                            </Box>
                        )}
                        {formattedPhoneNumber && (
                            <SecondaryInfo mb="2px">
                                <Flex alignItems="center">
                                    <Image
                                        img={{
                                            lightSrc: 'email-gray-icon.svg',
                                            darkSrc: 'email-gray-icon-dark.svg',
                                            width: 12,
                                            height: 12,
                                        }}
                                        classNames="icon-search-preview"
                                    />
                                    <Box ml="3px">{formattedPhoneNumber}</Box>
                                </Flex>
                            </SecondaryInfo>
                        )}
                    </LeftSection>
                    <Flex alignItems="center">
                        <Box mr="2px">{getCountryFlag(country)}</Box>
                        <Country mb="2px">{state}</Country>
                    </Flex>
                </Container>
            </ConditionalLink>
        </>
    );
};

export default ResultUser;
