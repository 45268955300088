import type { Maybe } from '@lemonade-hq/ts-helpers';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import { useSendSimulationMessage } from './simulation.queries';
import { InitiatingSide, LogActionType, TimelineItemType } from 'components/Llmnd/shared/types';
import type { Message, TimelineEvent, TimelineItem } from 'components/Llmnd/shared/types';

// helpers

function generateNewMessage(value: string): Message {
    return {
        type: TimelineItemType.Message,
        htmlBody: value.split('\n').join('<br>'),
        plainTextBody: value,
        publicId: Date.now().toString(),
        from: { side: InitiatingSide.NonLemonade, name: 'MC' },
    };
}

function generateErrorLog(errorMessage: string): TimelineEvent {
    return {
        type: TimelineItemType.Log,
        title: errorMessage,
        action: LogActionType.Error,
        publicId: Date.now().toString(),
    };
}

// interfaces

interface PlaygroundSimulationProps {
    readonly ticketId: string;
    readonly toolsRevisionPublicId: string;
}

interface PlaygroundSimulationResult {
    readonly addSimulationMessage: (message: string) => Promise<void>;
    readonly resetSimulation: () => void;
    readonly simulationTimeline: TimelineItem[];
    readonly isLoading: boolean;
    readonly wasMessageSentInThisTimeline: boolean;
}

// hook

export function usePlaygroundSimulation({
    toolsRevisionPublicId,
    ticketId,
}: PlaygroundSimulationProps): PlaygroundSimulationResult {
    const [simulationTimeline, setSimulationTimeline] = useState<TimelineItem[]>([]);
    const [wasMessageSentInThisTimeline, setMessageSentInThisTimeline] = useState<boolean>(false);
    const { mutateAsync: sendMessage, isPending: isLoading } = useSendSimulationMessage();

    const addSimulationMessage = useCallback(
        async (message: Maybe<string>): Promise<void> => {
            if (isEmpty(message)) return;

            const newMessage = generateNewMessage(message!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
            const newTimeline: TimelineItem[] = [...simulationTimeline, newMessage];
            setSimulationTimeline(newTimeline);

            try {
                const responseMessages = await sendMessage({
                    timeline: newTimeline,
                    ticketId,
                    toolsRevisionPublicId,
                });

                setMessageSentInThisTimeline(true);
                setSimulationTimeline([...newTimeline, ...responseMessages]);
            } catch (error: unknown) {
                const errorLogEvent = generateErrorLog(
                    (error as { message?: string }).message ??
                        'Something went wrong. Please check network error message'
                );
                setSimulationTimeline([...newTimeline, errorLogEvent]);
            }
        },
        [sendMessage, toolsRevisionPublicId, ticketId, simulationTimeline]
    );

    const resetSimulation = useCallback(() => {
        setSimulationTimeline([]);
        setMessageSentInThisTimeline(false);
    }, []);

    return { addSimulationMessage, resetSimulation, isLoading, simulationTimeline, wasMessageSentInThisTimeline };
}
