import { Flex, font } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import { Tag } from '../../../shared/components/Tag';

export interface RecipientTagsProps {
    readonly to: string;
}

const To = styled.span`
    ${font('main', { fontSize: '16px' })};
`;

export const RecipientTags: React.FC<React.PropsWithChildren<RecipientTagsProps>> = ({ to, ...restProps }) => {
    return (
        <Flex alignItems="center" gap="12px" {...restProps}>
            <To>To</To>
            <Tag>{to}</Tag>
        </Flex>
    );
};
