import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import type { colors } from './colors.css';
import { darkThemeClass, lightThemeClass } from './colors.css';

export const VIEW_MODE_KEY = 'view-mode';

export enum ViewMode {
  Dark = 'dark',
  Light = 'light',
}

export type ThemeColor = keyof typeof colors;

type ViewModeToggle = () => void;

export function handleThemeBodyClass(mode: ViewMode): void {
  document.body.classList.remove(lightThemeClass, darkThemeClass);
  document.body.classList.add(mode === ViewMode.Dark ? darkThemeClass : lightThemeClass);
}

const ThemeContext = createContext<{ readonly viewMode: ViewMode; readonly toggle: ViewModeToggle } | null>(null);

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const currentVM = localStorage.getItem(VIEW_MODE_KEY);
  const [viewMode, setViewMode] = useState(
    currentVM?.replaceAll('"', '') === ViewMode.Dark ? ViewMode.Dark : ViewMode.Light,
  );

  const toggle = useCallback(() => {
    setViewMode(vm => {
      const newViewMode = vm === ViewMode.Light ? ViewMode.Dark : ViewMode.Light;
      // TODO - uncomment this line when we don't use boutique ThemeProvider anymore
      // localStorage.setItem(VIEW_MODE_KEY, newViewMode);
      handleThemeBodyClass(newViewMode);
      return newViewMode;
    });
  }, []);

  useLayoutEffect(() => {
    handleThemeBodyClass(viewMode);
  }, [viewMode]);

  const viewModeValue = useMemo(() => ({ viewMode, toggle }), [toggle, viewMode]);

  return (
    <ThemeContext.Provider value={viewModeValue}>
      <div id="wrapper">{children}</div>
    </ThemeContext.Provider>
  );
};

export function useViewMode(): { readonly viewMode: ViewMode; readonly toggle: ViewModeToggle } {
  const viewMode = useContext(ThemeContext);

  if (viewMode === null) {
    throw new Error('useViewMode cannot be used outside of a ThemeProvider.');
  }

  return viewMode;
}
