import { NonExpandableChange } from '../NonExpandableChange';
import { findFlag } from './common';
import type { UnderwritingFiltersEdition } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

interface FlagRemovedProps {
    readonly flagId: string;
    readonly baseUnderwritingFiltersEdition: UnderwritingFiltersEdition;
}

export const FlagRemoved: React.FC<FlagRemovedProps> = ({ baseUnderwritingFiltersEdition, flagId }) => {
    return (
        <NonExpandableChange title={`Flag removed - ${findFlag(baseUnderwritingFiltersEdition, flagId).flagName}`} />
    );
};
