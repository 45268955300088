import React from 'react';
import loadable from '@loadable/component';
import { Routes, Route } from 'react-router-dom';
import { FederatedModule, LoadingSection } from '@lemonade-hq/bluis';
import * as Sentry from '@sentry/react';
import { NotesProvider } from 'bluis/Notes';
import { HomeQuoteQueryKeys, getQuoteDetails } from 'queries/HomeQuoteQueries';
import { HomePolicyQueryKeys, getHomePolicySummary } from 'queries/HomePolicyQueries';
import { queryClient } from '../../queryClient';

const AsyncHomeClaimsList = loadable(async () => import(/* webpackChunkName: "HomeClaimsList" */ '../HomeClaimsList'), {
    fallback: <LoadingSection />,
});

const AsyncHomeEditions = loadable(async () => import(/* webpackChunkName: "Editions" */ '../Editions'), {
    fallback: <LoadingSection />,
    resolveComponent: module => module.Editions,
});

const AsyncHomePoliciesList = loadable(
    async () => import(/* webpackChunkName: "HomePoliciesList" */ '../HomePoliciesList'),
    {
        fallback: <LoadingSection />,
    }
);

const AsyncHomePolicy = loadable(async () => import(/* webpackChunkName: "HomePolicy" */ './HomePolicy'), {
    fallback: <LoadingSection />,
});

const AsyncHomeQuotesList = loadable(async () => import(/* webpackChunkName: "HomeQuotesList" */ '../HomeQuotesList'), {
    fallback: <LoadingSection />,
});

const AsyncClaim = loadable(async () => import(/* webpackChunkName: "Claim" */ '../Claim'), {
    fallback: <LoadingSection />,
});

const AsyncQuote = loadable(async () => import(/* webpackChunkName: "HomeQuote" */ './HomeQuote'), {
    fallback: <LoadingSection />,
});

const AsyncVendors = React.lazy(async () =>
    import(/* webpackChunkName: "HomeVendors" */ './Vendors').then(module => {
        return {
            default: module.Vendors,
        };
    })
);

const AsyncInstantClaimsSettings = React.lazy(async () =>
    import(/* webpackChunkName: "InstantClaimsSettings" */ './InstantClaimsSettings').then(module => ({
        default: module.InstantClaimsSettings,
    }))
);

const AsyncSwitchRequests = React.lazy(async () =>
    import(/* webpackChunkName: "SwitchRequests" */ './SwitchRequests').then(module => ({
        default: module.SwitchRequests,
    }))
);

const Rerater = React.lazy(async () =>
    import(/* webpackChunkName: "Rerater" */ './Rerater').then(module => {
        return {
            default: module.Rerater,
            fallback: <LoadingSection />,
        };
    })
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const homeQuoteLoader = (queryClient: QueryClient) => async ({ params }) => {
    const queryKey = [HomeQuoteQueryKeys.GetQuoteDetails, params.quotePublicId];

    return (
        queryClient.getQueryData(queryKey) ??
        queryClient.fetchQuery({ queryKey, queryFn: async () => getQuoteDetails(params.quotePublicId) })
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const homePolicyLoader = (queryClient: QueryClient) => async ({ params }) => {
    const queryKey = [HomePolicyQueryKeys.GetPolicySummary, params.policyId];

    return (
        queryClient.getQueryData(queryKey) ??
        queryClient.fetchQuery({ queryKey, queryFn: async () => getHomePolicySummary(params.policyId) })
    );
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const HomeRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <NotesProvider
            analyticsParams={{
                notesIconClicked: {
                    name: 'workflows.note.icon.clicked',
                },
                notesAccordionOpened: {
                    name: 'workflows.note.add_note.clicked',
                },
                notesAccordionClosed: {
                    name: 'workflows.note.close_editor.clicked',
                },
                noteSubmitted: {
                    name: 'workflows.note.submitted',
                },
            }}
        >
            <SentryRoutes>
                <Route element={<AsyncHomeClaimsList />} path="claims" />
                <Route element={<AsyncHomeEditions overrideProduct="home_us" />} path="editions" />
                <Route element={<AsyncClaim />} path="claims/:claimId/*" />
                <Route element={<AsyncHomeQuotesList />} path="quotes" />
                <Route element={<AsyncQuote />} path="quotes/:quoteId/*" loader={homeQuoteLoader(queryClient)} />
                <Route element={<AsyncHomePoliciesList />} path="policies" />
                <Route
                    element={<AsyncHomePolicy />}
                    path="policies/:policyId/*"
                    loader={homePolicyLoader(queryClient)}
                />
                <Route
                    element={
                        <FederatedModule>
                            <AsyncInstantClaimsSettings />
                        </FederatedModule>
                    }
                    path="instant-claims/settings"
                />
                <Route
                    element={
                        <FederatedModule>
                            <AsyncSwitchRequests />
                        </FederatedModule>
                    }
                    path="switch-requests"
                />
                <Route
                    element={
                        <FederatedModule>
                            <AsyncVendors />
                        </FederatedModule>
                    }
                    path="vendors"
                />
                <Route
                    element={
                        <FederatedModule>
                            <Rerater />
                        </FederatedModule>
                    }
                    path="rate-simulation"
                />
            </SentryRoutes>
        </NotesProvider>
    );
};

export default HomeRoutes;
