import { spacing } from '@lemonade-hq/blender-ui';
import { ChevronButton, SlimRow, SlimTable, themedColor } from '@lemonade-hq/bluis';
import { useTheme } from '@lemonade-hq/boutique';
import { useAccordion } from '@lemonade-hq/cdk';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 10px;
    background: ${themedColor('lightBackground')};
`;

const StyledSlimTable = styled(SlimTable)`
    background: ${themedColor('componentBackground')};
`;

const StyledSlimRow = styled(SlimRow)<{ readonly isColumnRow?: boolean }>`
    text-align: left;

    > td {
        padding: ${spacing.s16} ${spacing.s10};
    }

    > :first-child {
        width: 200px;
        color: ${themedColor('secondaryText')};
    }
`;

type Rows = React.ComponentProps<typeof SlimRow>['row'][];

interface ExpendedDetailsTableProps {
    readonly rows: Rows;
}

export const ExpendedDetailsTable: React.FC<ExpendedDetailsTableProps> = ({ rows }) => {
    return (
        <Wrapper>
            <StyledSlimTable>
                {rows.map((row, i) => (
                    <StyledSlimRow key={i} row={row} />
                ))}
            </StyledSlimTable>
        </Wrapper>
    );
};

const StyledChevronButton = styled(ChevronButton)<{ readonly isOpen: boolean }>`
    border: none;
    color: ${themedColor('secondaryText')};
    transition: transform 0.4s ease-in-out;
    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
    height: 20px;
    width: 20px;
    min-width: 20px;

    &:hover {
        border: none;
        background-color: transparent;
    }
`;

export const ExpendButton: React.FC = () => {
    const theme = useTheme();
    const { isOpen, toggle } = useAccordion();

    return (
        <StyledChevronButton
            color={theme.colors.secondarySpinnerBackground}
            isOpen={isOpen}
            onClick={() => toggle?.()}
        />
    );
};
