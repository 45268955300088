import type { TableHeaderCellProps } from '@lemonade-hq/bluis';
import {
    EmptySection,
    ErrorSection,
    LoadingSection,
    Table,
    TableHeader,
    TableRow,
    TableTitle,
} from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import { useState } from 'react';
import { StyledSectionHeader } from '../../LoCoPagesSharedStyles';
import { EditionsListLinks, friendlyNameRenderer } from './EditionsListLinks';
import { SchemasLinks } from './SchemasLinks';
import { linksWrapper } from 'components/LoCo/common/components/LinksWrapper.css';
import { Paginator } from 'components/LoCo/common/components/Pagination/Paginator';
import { getFormattedDate } from 'components/LoCo/common/helpers/dateHelpers';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { PublishedEditionSet } from 'models/LoCo/Insurance/EditionSets';
import { useGetProductPublishedEditionSets } from 'queries/LoCo/Insurance/ProductQueries';

interface TableProps {
    readonly productCode: string;
}

interface RowProps {
    readonly editionSet: PublishedEditionSet;
    readonly productCode: string;
}
const headerFields: TableHeaderCellProps[] = [
    { key: 'editionSet', value: 'Edition Set' },
    { key: 'newBusiness', value: 'New Business' },
    { key: 'renewal', value: 'Renewal' },
    { key: 'editions', value: 'Editions' },
    { key: 'released', value: 'Released' },
];

const EditionSetToRow: React.FC<RowProps> = ({ editionSet, productCode }) => {
    return (
        <TableRow
            key={editionSet.code}
            row={[
                { key: 'editionSet', value: editionSet.version },
                { key: 'newBusiness', value: getFormattedDate(editionSet.newBusinessEffectiveAt) },
                { key: 'renewal', value: getFormattedDate(editionSet.renewalEffectiveAt) },
                {
                    key: 'editions',
                    value: (
                        <Flex className={linksWrapper}>
                            <EditionsListLinks
                                editionLabelRenderer={friendlyNameRenderer}
                                editions={{
                                    [EditionType.Coverages]: editionSet.coveragesEdition,
                                    [EditionType.DigitalAgent]: editionSet.digitalAgentEdition,
                                    [EditionType.Rating]: editionSet.ratingEdition,
                                    [EditionType.UnderwritingFilters]: editionSet.underwritingFiltersEdition,
                                }}
                                openLinkInNewTab
                                productCode={productCode}
                            />
                            <SchemasLinks
                                openLinkInNewTab
                                productCode={productCode}
                                schemaRevisions={{
                                    platform: editionSet.platformSchemaRevision,
                                    product: editionSet.productSchemaRevision,
                                }}
                            />
                        </Flex>
                    ),
                },
                { key: 'released', value: getFormattedDate(editionSet.publishedAt) },
            ]}
        />
    );
};

export const PublishedEditionSetsTable: React.FC<TableProps> = ({ productCode }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const { data, isError, isLoading } = useGetProductPublishedEditionSets(productCode, { page: pageNumber, size: 10 });
    const onPageClick = (nextPage: number) => () => {
        setPageNumber(nextPage);
    };

    if (isLoading) {
        return <LoadingSection />;
    }

    if (!data || isError) {
        return <ErrorSection title="Failed fetching published edition sets :(" />;
    }

    const { data: editionSets, stats } = data;

    return (
        <>
            <StyledSectionHeader>
                <TableTitle title="Published Edition Sets" />
            </StyledSectionHeader>
            {editionSets.length === 0 ? (
                <EmptySection>No published edition sets for this product</EmptySection>
            ) : (
                <>
                    <Table>
                        <TableHeader headers={headerFields} />
                        {editionSets.map(edition => (
                            <EditionSetToRow editionSet={edition} key={edition.code} productCode={productCode} />
                        ))}
                    </Table>
                    <Paginator currentPage={pageNumber} onPageClick={onPageClick} totalPages={stats.totalPages} />
                </>
            )}
        </>
    );
};
