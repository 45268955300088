import { TabPanels as HeadlessTabPanels } from '@headlessui/react';
import { Children, cloneElement, isValidElement, memo } from 'react';
import { Layout } from '../../base/Layout/Layout';

interface TabPanelsProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly children: React.ReactNode;
}

const TabPanelsComponent: React.FC<React.PropsWithChildren<TabPanelsProps>> = ({ children, ...props }) => {
  return (
    <HeadlessTabPanels>
      <Layout {...props}>
        {Children.map(children, (child: React.ReactNode, index: number) => {
          if (!isValidElement(child)) return child;

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return cloneElement(child, {
            index,
            ...child.props,
          });
        })}
      </Layout>
    </HeadlessTabPanels>
  );
};

export const TabPanels = memo(TabPanelsComponent);
