/* eslint-disable @lemonade-hq/frontend/jsx-no-explicit-props-spread */
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { wrap } from '@lemonade-hq/ts-helpers';
import { clsx } from 'clsx';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import type { ReactNode } from 'react';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { input as inputStyles } from '../../theme/input.css';
import * as listStyles from '../../theme/select.css';
import type { SelectionMode } from '../../theme/selection';
import { spacing } from '../../theme/spacing.css';
import { useQuickFuzzyFilter } from '../../utils/fuzzy';
import type { Variants as CheckboxVariants } from '../Checkbox/Checkbox';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/types';
import { IconButton } from '../IconButton/IconButton';
import { ListItem } from '../ListItem/ListItem';
import * as listItemStyles from '../ListItem/ListItem.css';
import { SelectChips } from '../Select/SelectChips';
import { Tooltip } from '../Tooltip/Tooltip';
import * as styles from './ComboBox.css';

export interface ComboBoxItem {
  readonly value: string;
  readonly label: string;
  readonly icon?: IconName;
  readonly render?: (item: ComboBoxItem) => React.ReactNode;
  readonly disabled?: boolean;
}

export interface ComboBoxProps<TMode extends SelectionMode = 'single', TItem extends ComboBoxItem = ComboBoxItem> {
  readonly items: TItem[];
  readonly disabled?: boolean;
  readonly onSelectionChange: (
    item: (NoInfer<TMode> extends 'single' ? TItem : TItem[]) | null,
    newlyGenerated?: boolean,
  ) => void;
  readonly defaultValue?: NoInfer<TMode> extends 'single' ? TItem['value'] : TItem['value'][];
  readonly variant?: CheckboxVariants;
  readonly hasError?: boolean;
  readonly placeholder?: string;
  readonly className?: string;
  readonly showIcon?: boolean;
  readonly allowCustomValue?: boolean;
  readonly cancelable?: boolean;
  readonly mode?: TMode;
  readonly maxAmountOfChips?: number;
}

/**
 * Internal interface to extend ComboBoxItem with generatedByTyping flag
 * to distinguish between items generated by typing a new value into the input box
 * and items selected from the list of options.
 */
interface InternalComboBoxItem extends ComboBoxItem {
  readonly generatedByTyping?: boolean;
}

function getItemLabel<TItem extends ComboBoxItem>(item: TItem): string {
  return item.label;
}
const ComboBoxListItem = <TItem extends ComboBoxItem>({
  item,
  mode,
  checked,
  variant,
  // we need to pass the rest of the props to the ComboboxOption
  // because additional props are passed for virtualization
  ...props
}: {
  readonly item: TItem;
  readonly mode: SelectionMode;
  readonly checked: boolean;
  readonly variant?: CheckboxVariants;
}): ReactNode => {
  return (
    <ComboboxOption
      as={ListItem}
      checked={mode === 'multiple' ? checked : undefined}
      icon={item.icon}
      id={item.value}
      key={item.value}
      label={item.label}
      value={item}
      variant={variant}
      {...props}
    />
  );
};

export const ComboBox = <TMode extends SelectionMode = 'single', TItem extends ComboBoxItem = ComboBoxItem>(
  props: ComboBoxProps<TMode, TItem>,
): ReactNode => {
  const {
    items,
    onSelectionChange,
    defaultValue,
    placeholder,
    disabled,
    hasError,
    className: externalClassName,
    showIcon = true,
    allowCustomValue = false,
    cancelable = false,
    mode = 'single',
    variant = 'neutral',
    maxAmountOfChips,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  type TValue = NoInfer<TMode> extends 'single' ? TItem : TItem[];

  const [selectedItemsIds, setSelectedItemIds] = useState<Set<TItem['value']>>(
    defaultValue != null ? new Set<TItem['value']>(wrap(defaultValue)) : new Set<TItem['value']>([]),
  );
  const [customItems, setCustomItems] = useState<TItem[]>(
    defaultValue == null || defaultValue.length === 0
      ? []
      : wrap(defaultValue)
          .filter(dv => !items.map(i => i.value).includes(dv))
          .map(i => ({ label: i, value: i }) as TItem),
  );

  const [query, setQuery] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onSelection = useCallback(
    (item: TValue | null) => {
      setSelectedItemIds(item == null ? new Set() : new Set(wrap(item).map(i => i.value)));
      setCustomItems(val =>
        uniqBy(
          [...val, ...(isEmpty(item) ? [] : wrap(item).filter(i => !items.map(it => it.value).includes(i.value)))],
          'value',
        ),
      );
      onSelectionChange(item, (item as InternalComboBoxItem | null)?.generatedByTyping);
      setQuery('');
    },
    [onSelectionChange, items],
  );

  const selectedItems = useMemo(
    () => [...items, ...customItems].filter(item => selectedItemsIds.has(item.value)),
    [items, customItems, selectedItemsIds],
  );
  const firstSelectItemForSingleMode = selectedItems[0] as TItem | undefined;

  const mergedItems = useMemo(
    () => (allowCustomValue ? [...customItems, ...items] : items),
    [allowCustomValue, customItems, items],
  );
  const filteredItems = useQuickFuzzyFilter(query, mergedItems, getItemLabel);

  const showCustomValueOption = useMemo(
    () => allowCustomValue && query.length > 0 && !items.some(item => item.label.toLowerCase() === query.toLowerCase()),
    [allowCustomValue, items, query],
  );

  useEffect(() => {
    const inputElement = inputRef.current;
    if (!inputElement) return;

    const myObserver = new MutationObserver(() => {
      setIsOverflown(inputElement.scrollWidth > inputElement.clientWidth);
    });
    myObserver.observe(inputElement, { attributes: true, attributeFilter: ['value'] });
  }, []);

  const showSelectedIcon = mode === 'single' && firstSelectItemForSingleMode?.icon && showIcon;
  const virtualize = !allowCustomValue && process.env.NODE_ENV !== 'test'; // virtualization doesn't work in jsdom

  return (
    <Combobox<TItem | null>
      disabled={disabled}
      immediate
      // @ts-expect-error: due to hard inference problem with headless-ui, since all our values here are dynamic
      multiple={mode === 'multiple'}
      onChange={onSelection as (value: NoInfer<TItem | null> | null) => void}
      value={(mode === 'multiple' ? selectedItems : firstSelectItemForSingleMode) as TItem}
      virtual={virtualize ? { options: filteredItems } : undefined}
    >
      <Flex
        flexDirection="column"
        position="relative"
        {...{ [styles.MODE_DATA_ATTRIBUTE]: mode, [styles.SELECTED_ITEMS_DATA_ATTRIBUTE]: selectedItems.length }}
      >
        <div
          className={clsx(listStyles.selectTrigger, inputStyles(), styles.inputBox, externalClassName)}
          data-disabled={disabled === true ? 'true' : undefined}
          data-has-error={Boolean(hasError) || undefined}
          data-testid="container"
          ref={wrapperRef}
          role="search"
        >
          {showSelectedIcon && (
            <Icon className={listItemStyles.listItemIcon} name={firstSelectItemForSingleMode.icon} size="sm" />
          )}
          <Tooltip
            content={firstSelectItemForSingleMode ? firstSelectItemForSingleMode.label : ''}
            disabled={mode === 'multiple' || !isOverflown}
            side="top"
          >
            <ComboboxInput<TItem | null>
              autoComplete="off"
              className={styles.inputContainer}
              displayValue={item => item?.label ?? ''}
              onChange={event => setQuery(event.target.value)}
              placeholder={placeholder}
              ref={inputRef}
            />
          </Tooltip>
          {mode === 'multiple' && (
            <Flex gap={spacing.s04}>
              <SelectChips
                disabled={disabled}
                maxAmountOfChips={maxAmountOfChips}
                onDeselect={value => {
                  onSelection(selectedItems.filter(item => item.value !== value) as TValue);
                }}
                options={selectedItems}
              />
            </Flex>
          )}

          <Flex gap={spacing.s04}>
            {cancelable && firstSelectItemForSingleMode && (
              <IconButton
                color="neutral7"
                icon="x"
                iconSize="sm"
                onClick={() => onSelection(null)}
                size="sm"
                variant="inline"
              />
            )}
            <ComboboxButton as={Fragment}>
              <IconButton
                className={styles.arrowIcon}
                color="neutral7"
                icon="arrow-drop-down-solid"
                iconSize="xs"
                size="sm"
                variant="inline"
              />
            </ComboboxButton>
          </Flex>
        </div>
        <ComboboxOptions
          className={clsx(listStyles.listBox, listStyles.popover)}
          {...{ [listStyles.EMPTY_MESSAGE_DATA_ATTRIBUTE]: 'No results found' }}
        >
          {virtualize ? (
            // virtualize options only when custom options are not allowed
            ({ option: item }: { option: TItem }) => (
              <ComboBoxListItem checked={selectedItemsIds.has(item.value)} item={item} mode={mode} variant={variant} />
            )
          ) : (
            <>
              {showCustomValueOption && (
                <ComboboxOption<'div', TItem>
                  className={listItemStyles.listItem}
                  value={{ value: query, label: query, generatedByTyping: true } as InternalComboBoxItem as TItem}
                >
                  Create <span className="font-bold">{query}</span>
                </ComboboxOption>
              )}
              {filteredItems.map(item => (
                <ComboBoxListItem
                  checked={selectedItemsIds.has(item.value)}
                  item={item}
                  key={item.value}
                  mode={mode}
                  variant={variant}
                />
              ))}
            </>
          )}
        </ComboboxOptions>
      </Flex>
    </Combobox>
  );
};
