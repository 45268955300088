import styled, { css } from 'styled-components';
import COLORS, { ThemedColors } from 'bluis/Colors';

/**
 * @deprecated
 * use StatusDot from '@lemonade-hq/bluis' instead
 */
export const StatusDot = styled.span<{ readonly status: string; readonly size?: number }>`
    position: relative;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: auto 5px auto 0px;

    ${({ size }) => css`
        width: ${size != null ? size : 7}px;
        height: ${size != null ? size : 7}px;
    `}

    ${({ status }) => {
        switch (status) {
            case 'open': {
                return css`
                    background: ${COLORS.pink};
                `;
            }

            case 'active':
            case 'paid': {
                return css`
                    background: ${COLORS.mediumSea};
                `;
            }

            case 'canceled':
            case 'deleted':
            case 'error':
            case 'uw_declined':
            case 'declined':
            case 'closed':
            case 'expired':
            case 'ineligible':
            case 'canceled_renewal': {
                return css`
                    background: ${COLORS.scarlet};
                `;
            }

            case 'pending':
            case 'stubbed':
            case 'bindable': {
                return css`
                    background: ${COLORS.orangePill};
                `;
            }

            case 'renewal':
            case 'future': {
                return css`
                    background: ${COLORS.blueSky};
                `;
            }

            case 'renewed':
            case 'dismissed':
            case 'archived': {
                return css`
                    background: ${COLORS.metal};
                `;
            }

            case 'done': {
                return css`
                    background: ${ThemedColors.successColor};
                `;
            }

            default: {
                return null;
            }
        }
    }}
`;

export default StatusDot;
