import type { Tool, ToolsRevision } from '@lemonade-hq/persisted-tools';
import { useCallback, useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import type { PlaygroundURLParams } from '../shared/routing.consts';
import { PLAYGROUND_FULL_PATH_PREFIX, PLAYGROUND_PATH_WITH_TOOL, PlaygroundURLParam } from '../shared/routing.consts';
import { sortToolsBySections } from '../shared/tool.helpers';

export function useSelectedTool(
    toolsRevision: ToolsRevision | undefined
): [Tool | undefined, (toolName: string) => void] {
    const navigate = useNavigate();
    const { toolName: selectedToolName = undefined, toolsRevisionPublicId } = useParams<PlaygroundURLParams>();
    const selectedToolId = useMemo(
        () => toolsRevision?.tools.find(t => t.name === selectedToolName)?.publicId,
        [toolsRevision, selectedToolName]
    );

    const setSelectedToolName = useCallback(
        (toolName: string) => {
            const path = generatePath(`${PLAYGROUND_FULL_PATH_PREFIX}${PLAYGROUND_PATH_WITH_TOOL}`, {
                [PlaygroundURLParam.ToolName]: toolName,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                [PlaygroundURLParam.ToolsRevisionPublicId]: toolsRevisionPublicId!,
            });
            navigate(path);
        },
        [navigate, toolsRevisionPublicId]
    );

    useEffect(() => {
        if (selectedToolId == null && toolsRevision != null) {
            const sortedPseudoTools = sortToolsBySections(toolsRevision).flatMap(s => s.options);
            if (sortedPseudoTools.length > 0) {
                setSelectedToolName(sortedPseudoTools[0].label);
            }
        }
    }, [toolsRevision, setSelectedToolName, selectedToolId]);

    const selectedTool = useMemo(
        () =>
            toolsRevision?.tools.find(tool => tool.publicId === selectedToolId) ?? toolsRevision?.tools[0] ?? undefined,
        [toolsRevision, selectedToolId]
    );

    return [selectedTool, setSelectedToolName];
}
