import React, { FC, useState, useEffect, useRef, useCallback, ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Flex, useClickOutside } from '@lemonade-hq/cdk';
import { Tabs, TabList, TabPanel, TabPanels, MainButton, InverseButton } from '@lemonade-hq/bluis';
import { font, themedColor, useAnalytics } from '@lemonade-hq/boutique';
import { CoreEntityType, ProductTypes } from '@lemonade-hq/bluiza';
import pluralize from 'pluralize';
import { generalSearchEventName, getResultsTrackingData } from 'components/Search/searchTrackingHelpers';
import { getTabState, SearchTab, TabState } from 'components/Search/helpers';
import { SearchResults } from './helpers';
import User from './ResultUser';
import Quote from './ResultQuote';
import Policy from './ResultPolicy';
import Claim from './ResultClaim';

const MAX_RESULTS_IN_TAB = 10;

enum SearchPreviewTabs {
    None = -1,
    User,
    Quote,
    Policy,
    Claim,
}

const MainButtonStyled = styled(MainButton)`
    font-size: 12px;
    padding: 7px 16px;
    height: auto;
    margin-left: 8px;
`;

const InverseButtonStyled = styled(InverseButton)`
    font-size: 12px;
    padding: 7px 16px;
    height: auto;
`;

const ResultsContainer = styled.div`
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background: ${themedColor('componentBackground')};
    border: 1px solid ${themedColor('separator')};
    border-radius: 5px;
    margin-top: -1px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
`;

const TabsContainer = styled.div<{ resultsFound: boolean }>`
    width: 100%;
    max-height: 303px;
    overflow: auto;

    ${({ resultsFound }) =>
        css`
            min-height: ${resultsFound ? 228 : 80}px !important;
        `}
`;

const TabListStyled = styled(TabList)`
    top: 0;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid ${themedColor('separator')};
    padding: 0 10px;
    height: 40px;
    border-radius: 5px 5px 0 0;
`;

const TabStyled = styled(SearchTab)`
    padding: 13px 13px 11px;
    font-size: 12px;
    font-weight: 700;
`;

const BottomBar = styled(Flex)`
    justify-content: flex-end;
    align-items: center;
    position: relative;
    padding: 8px;
    border-top: 1px solid ${themedColor('separator')};
`;

const NoResultsContainer = styled(Flex)`
    height: 95px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: ${themedColor('secondaryText')};
    ${font('main', { fontSize: '14px', lineHeight: 'normal', fontWeight: 400 })};
    padding: 18px 0 28px 0;
`;

const EmojiNotFound = styled.span`
    font-size: 18px;
`;

function getCurrentTab(selectedTab: number, options: SearchResults): number {
    const { users, quotes, policies, claims } = options;

    const fallbackTab = (): number => {
        if (users && users.length > 0) {
            return SearchPreviewTabs.User;
        }
        if (quotes.length > 0) {
            return SearchPreviewTabs.Quote;
        }
        if (policies.length > 0) {
            return SearchPreviewTabs.Policy;
        }
        if (claims && claims.length > 0) {
            return SearchPreviewTabs.Claim;
        }
        return SearchPreviewTabs.None;
    };

    const invalidTab = (): boolean => {
        switch (selectedTab) {
            case SearchPreviewTabs.User:
                return users?.length === 0;
            case SearchPreviewTabs.Quote:
                return quotes.length === 0;
            case SearchPreviewTabs.Policy:
                return policies.length === 0;
            case SearchPreviewTabs.Claim:
                return claims?.length === 0;
            default:
                return true;
        }
    };

    if (invalidTab()) {
        return fallbackTab();
    }
    return selectedTab;
}

type SearchItem = Exclude<SearchResults['claims' | 'users' | 'quotes' | 'policies'], undefined>[0];

type SearchTabPanelProps<Item extends SearchItem> = Omit<ComponentProps<typeof TabPanel>, 'children'> & {
    items: Item[] | undefined;
    children: (item: Item, index: number) => JSX.Element;
};

function SearchTabPanel<Item extends SearchItem>({
    items,
    children,
    ...props
}: SearchTabPanelProps<Item>): JSX.Element | null {
    if (!items) return null;
    return (
        <TabPanel {...props}>{items.slice(0, MAX_RESULTS_IN_TAB).map((item, index) => children(item, index))}</TabPanel>
    );
}

const SearchPreview: FC<
    React.PropsWithChildren<{
        term: string;
        options: SearchResults;
        value: string;
        addressSearchEnabled?: boolean;
        onSearchResultClick: () => void;
        onClose: () => void;
    }>
> = ({ term, options, value, addressSearchEnabled = false, onSearchResultClick, onClose }) => {
    const [selectedTab, setSelectedTab] = useState<SearchPreviewTabs>(getCurrentTab(SearchPreviewTabs.None, options));

    const containerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { trackEvent } = useAnalytics();

    useClickOutside(containerRef, true, onClose);

    const { users, quotes, policies, claims } = options;

    const usersState = getTabState(users);
    const quotesState = getTabState(quotes);
    const policiesState = getTabState(policies);
    const claimsState = getTabState(claims);

    const resultsFound =
        usersState === TabState.Enabled ||
        quotesState === TabState.Enabled ||
        policiesState === TabState.Enabled ||
        claimsState === TabState.Enabled;

    useEffect(() => {
        const updatedTab = getCurrentTab(selectedTab, options);

        setSelectedTab(updatedTab);
    }, [options, selectedTab]);

    function navigateToURL(url: string) {
        navigate(url);
    }

    function searchAddress(value: string): void {
        onClose();
        const term = encodeURIComponent(value);
        const url = `/backoffice/addresses?term=${term}`;

        navigateToURL(url);
    }

    function goToAllResults(value: string): void {
        onClose();

        const url = `/backoffice/search?term=${encodeURIComponent(value)}`;

        navigateToURL(url);
    }

    const onResultClick = useCallback(
        ({ url, entity, product, index }: {url: string; entity: CoreEntityType; product: ProductTypes; index: number}) => {
            const trackingData = getResultsTrackingData({
                url,
                entity,
                product,
                total: Object.values(options).flat().length,
                entityTotal: options[pluralize(entity)].length,
                index,
            });

            onSearchResultClick();
            trackEvent(`${generalSearchEventName}.preview_results.clicked`, { ...trackingData, term });
        },
        [onSearchResultClick, options, term, trackEvent]
    );

    return (
        <ResultsContainer ref={containerRef}>
            <TabsContainer resultsFound={resultsFound}>
                <Tabs startAtTabIndex={selectedTab} uuid="search-">
                    <TabListStyled>
                        <TabStyled
                            state={usersState}
                            data-event={`${generalSearchEventName}.preview_results_entity_tab.clicked`}
                            data-event-Param-Entity={CoreEntityType.User}
                        >
                            Users
                        </TabStyled>

                        <TabStyled
                            state={quotesState}
                            data-event={`${generalSearchEventName}.preview_results_entity_tab.clicked`}
                            data-event-Param-Entity={CoreEntityType.Quote}
                        >
                            Quotes
                        </TabStyled>
                        <TabStyled
                            state={policiesState}
                            data-event={`${generalSearchEventName}.preview_results_entity_tab.clicked`}
                            data-event-Param-Entity={CoreEntityType.Policy}
                        >
                            Policies
                        </TabStyled>
                        <TabStyled
                            state={claimsState}
                            data-event={`${generalSearchEventName}.preview_results_entity_tab.clicked`}
                            data-event-Param-Entity={CoreEntityType.Claim}
                        >
                            Claims
                        </TabStyled>
                    </TabListStyled>
                    <TabPanels>
                        <SearchTabPanel items={users}>
                            {(user, index) => (
                                <User
                                    key={user.external_id}
                                    term={term}
                                    user={users![0]}
                                    onSearchResultClick={onResultClick}
                                    index={index}
                                />
                            )}
                        </SearchTabPanel>
                        <SearchTabPanel items={quotes}>
                            {(quote, index) => (
                                <Quote
                                    key={quote.external_id}
                                    term={term}
                                    quote={quote}
                                    onSearchResultClick={onResultClick}
                                    index={index}
                                />
                            )}
                        </SearchTabPanel>
                        <SearchTabPanel items={policies}>
                            {(policy, index) => (
                                <Policy
                                    key={policy.external_id}
                                    term={term}
                                    policy={policy}
                                    onSearchResultClick={onResultClick}
                                    index={index}
                                />
                            )}
                        </SearchTabPanel>
                        <SearchTabPanel items={claims}>
                            {(claim, index) => (
                                <Claim
                                    key={claim.external_id}
                                    term={term}
                                    claim={claim}
                                    onSearchResultClick={onResultClick}
                                    index={index}
                                />
                            )}
                        </SearchTabPanel>
                    </TabPanels>
                </Tabs>
                {!resultsFound && (
                    <NoResultsContainer>
                        <EmojiNotFound>🤷‍♀️</EmojiNotFound>
                        <span>We know nothing about that</span>
                    </NoResultsContainer>
                )}
            </TabsContainer>
            <BottomBar>
                {addressSearchEnabled && (
                    <InverseButtonStyled
                        data-event={`${generalSearchEventName}.search_address.clicked`}
                        onClick={() => searchAddress(value)}
                    >
                        Search An Address
                    </InverseButtonStyled>
                )}
                {resultsFound && (
                    <MainButtonStyled
                        data-event={`${generalSearchEventName}.show_all_results.clicked`}
                        onClick={() => goToAllResults(value)}
                    >
                        Show all results
                    </MainButtonStyled>
                )}
            </BottomBar>
        </ResultsContainer>
    );
};

export default SearchPreview;
