/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { StatusDotMode } from '@lemonade-hq/bluis';
import type { SnakeCaseToCamelCase } from '@lemonade-hq/ts-helpers';
import type {
  ListElement,
  ReferenceTypeEnum,
  TagEnum,
  Task,
  TaskElement,
  TaskTypesEnum,
  WorkflowElement,
} from './types';
import { TaskStatus } from './types';

export function findFirstTask<
  TaskType extends TaskTypesEnum,
  TagType extends TagEnum,
  ReferenceType extends ReferenceTypeEnum,
>(elements: WorkflowElement<TaskType, TagType, ReferenceType>[]): string | null {
  const [element] = elements;

  if (element == null) return null;
  if (element.type === 'task') return element.task.publicId;

  return findFirstTask(element.elements);
}

export function getListsElementsCount<
  TaskType extends TaskTypesEnum,
  TagType extends TagEnum,
  ReferenceType extends ReferenceTypeEnum,
>(list: ListElement<TaskType, TagType, ReferenceType>): { readonly count: number; readonly listCount: number } {
  let count = 0;
  let listCount = 0;
  const queue: WorkflowElement<TaskType, TagType, ReferenceType>[] = [...list.elements];

  while (queue.length > 0) {
    count++;
    const element = queue.shift();

    if (element == null) break;
    if (element.type === 'list') {
      listCount++;
      queue.push(...element.elements);
    }
  }

  return { count, listCount };
}

export function someTasks<
  TaskType extends TaskTypesEnum,
  TagType extends TagEnum,
  ReferenceType extends ReferenceTypeEnum,
>(elements: WorkflowElement<TaskType, TagType, ReferenceType>[], some: (task: Task<TaskType>) => boolean): boolean {
  const queue: WorkflowElement<TaskType, TagType, ReferenceType>[] = [...elements];

  while (queue.length > 0) {
    const element = queue.shift();

    if (element == null) break;
    if (element.type === 'list') {
      queue.push(...element.elements);
    } else if (some(element.task)) {
      return true;
    }
  }

  return false;
}

type ElementWithReferences<
  TaskType extends TaskTypesEnum,
  TagType extends TagEnum,
  ReferenceType extends ReferenceTypeEnum,
> = WorkflowElement<TaskType, TagType, ReferenceType> & {
  readonly references?: Record<ReferenceType[keyof ReferenceType], string>;
};

type CamelCasedElementWithReferences<
  TaskType extends TaskTypesEnum,
  TagType extends TagEnum,
  ReferenceType extends ReferenceTypeEnum,
> = SnakeCaseToCamelCase<WorkflowElement<TaskType, TagType, ReferenceType>> & {
  readonly references?: Record<ReferenceType[keyof ReferenceType], string>;
};

export function findTask<
  TaskType extends TaskTypesEnum,
  TagType extends TagEnum,
  ReferenceType extends ReferenceTypeEnum,
>(
  elements: WorkflowElement<TaskType, TagType, ReferenceType>[],
  find: (task: Task<TaskType>) => boolean,
):
  | {
      readonly task: TaskElement<TaskType>;
      readonly references: { readonly type: ReferenceType[keyof ReferenceType]; readonly id?: string }[];
    }
  | undefined {
  const queue: ElementWithReferences<TaskType, TagType, ReferenceType>[] = [...elements];

  while (queue.length > 0) {
    const element = queue.shift();

    if (element == null) break;
    if (element.type === 'list') {
      const references = (
        element.referenceType != null
          ? { ...element.references, [element.referenceType]: element.referenceId }
          : element.references
      ) as Record<ReferenceType[keyof ReferenceType], string> | undefined;

      queue.push(
        ...element.elements.map((el: WorkflowElement<TaskType, TagType, ReferenceType>) => ({ ...el, references })),
      );
    } else if (find(element.task)) {
      return {
        task: element,
        references: Object.entries(element.references ?? {}).map(
          ([type, id]) => ({ type, id }) as { type: ReferenceType[keyof ReferenceType]; id?: string },
        ),
      };
    }
  }

  return undefined;
}

export function isCurrentTaskCurry<TaskType extends TaskTypesEnum>(
  currentTaskId: string,
): (task: Task<TaskType>) => boolean {
  return (task: Task<TaskType>) => task.publicId === currentTaskId;
}

export function findNextTask<
  TaskType extends TaskTypesEnum,
  TagType extends TagEnum,
  ReferenceType extends ReferenceTypeEnum,
>(elements: WorkflowElement<TaskType, TagType, ReferenceType>[], currentTaskId: string): Task<TaskType> | undefined {
  const flatten = flat(elements);

  const currentTaskIndex = flatten.findIndex(task => task.publicId === currentTaskId);

  if (currentTaskIndex === -1 || currentTaskIndex === flatten.length - 1) return;

  return flatten[currentTaskIndex + 1];
}

export function findNextOpenTask<
  TaskType extends TaskTypesEnum,
  TagType extends TagEnum,
  ReferenceType extends ReferenceTypeEnum,
>(elements: WorkflowElement<TaskType, TagType, ReferenceType>[], currentTaskId: string): Task<TaskType> | undefined {
  const flatten = flat(elements);

  const currentTaskIndex = flatten.findIndex(task => task.publicId === currentTaskId);

  if (currentTaskIndex === -1 || currentTaskIndex === flatten.length - 1) return;

  const nextTasks = flatten.slice(currentTaskIndex + 1);

  return nextTasks.find(task => !task.status || task.status === TaskStatus.Open);
}

export function hasNextTask<
  TaskType extends TaskTypesEnum,
  TagType extends TagEnum,
  ReferenceType extends ReferenceTypeEnum,
>(elements: WorkflowElement<TaskType, TagType, ReferenceType>[], currentTaskId: string): boolean {
  const flatten = flat(elements);

  const currentTaskIndex = flatten.findIndex(task => task.publicId === currentTaskId);

  return currentTaskIndex < flatten.length - 1;
}

export function flat<TaskType extends TaskTypesEnum, TagType extends TagEnum, ReferenceType extends ReferenceTypeEnum>(
  elements: WorkflowElement<TaskType, TagType, ReferenceType>[],
): Task<TaskType>[] {
  const queue: WorkflowElement<TaskType, TagType, ReferenceType>[] = [...elements];
  const tasks: Task<TaskType>[] = [];

  while (queue.length > 0) {
    const element = queue.shift();

    if (element == null) break;
    if (element.type === 'list') {
      queue.push(...element.elements);
    } else {
      tasks.push(element.task);
    }
  }

  return tasks;
}

export function findCamelCasedTask<
  TaskType extends TaskTypesEnum,
  TagType extends TagEnum,
  ReferenceType extends ReferenceTypeEnum,
>(
  elements: SnakeCaseToCamelCase<WorkflowElement<TaskType, TagType, ReferenceType>>[],
  find: (task: SnakeCaseToCamelCase<Task<TaskType>>) => boolean,
):
  | {
      readonly task: SnakeCaseToCamelCase<TaskElement<TaskType>>;
      readonly references: { readonly type: ReferenceType[keyof ReferenceType]; readonly id?: string }[];
    }
  | undefined {
  const queue: CamelCasedElementWithReferences<TaskType, TagType, ReferenceType>[] = [...elements];

  while (queue.length > 0) {
    const element = queue.shift();

    if (element == null) break;
    if (element.type === 'list') {
      const references =
        element.referenceType != null
          ? { ...element.references, [element.referenceType]: element.referenceId }
          : element.references;

      queue.push(
        ...element.elements.map((el: WorkflowElement<TaskType, TagType, ReferenceType>) => ({
          ...el,
          references: references as Record<ReferenceType[keyof ReferenceType], string> | undefined,
        })),
      );
    } else if (find(element.task)) {
      return {
        task: element,
        references: Object.entries(element.references ?? {}).map(
          ([type, id]) => ({ type, id }) as { type: ReferenceType[keyof ReferenceType]; id?: string },
        ),
      };
    }
  }

  return undefined;
}

export const mapTaskStatusToDot = (status: TaskStatus): StatusDotMode => {
  const statusMap: Record<TaskStatus, StatusDotMode> = {
    [TaskStatus.Open]: StatusDotMode.Alert,
    [TaskStatus.Pending]: StatusDotMode.Pending,
    [TaskStatus.Active]: StatusDotMode.Active,
    [TaskStatus.Done]: StatusDotMode.Success,
    [TaskStatus.Dismissed]: StatusDotMode.Disabled,
  };

  return statusMap[status];
};
