import { Flex } from '@lemonade-hq/blender-ui';
import {
    EditionsListLinks,
    friendlyNameRenderer,
} from 'components/LoCo/products/ProductMissionControl/EditionsListLinks';
import { SchemasLinks } from 'components/LoCo/products/ProductMissionControl/SchemasLinks';
import type { Release } from 'models/LoCo/Insurance/Release';

interface EditionsAndSchemasLinksProps {
    readonly productCode: string;
    readonly release: Release;
}

export const EditionsAndSchemasLinks: React.FC<EditionsAndSchemasLinksProps> = ({ productCode, release }) => {
    return (
        <Flex flexDirection="column">
            <EditionsListLinks
                editionLabelRenderer={friendlyNameRenderer}
                editions={release.editions}
                openLinkInNewTab
                productCode={productCode}
            />
            <SchemasLinks
                openLinkInNewTab
                productCode={productCode}
                schemaRevisions={{
                    platform: release.platformSchemaRevision,
                    product: release.productSchemaRevision,
                }}
            />
        </Flex>
    );
};
