import { ChangeAccordion } from '../ChangeAccordion';
import { findCoverage } from './common';
import { getIsBenefit } from './coverage-attributes';
import type { AttributesUpdatedEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';

interface CoverageAttributesUpdatedProps {
    readonly baseCoverageEdition: CoveragesEdition;
    readonly coverageEdition: CoveragesEdition;
    readonly change: AttributesUpdatedEntry;
}

export const CoverageAttributesUpdated: React.FC<CoverageAttributesUpdatedProps> = ({
    coverageEdition,
    baseCoverageEdition,
    change,
}) => {
    const coverageInstance = findCoverage(coverageEdition, change.metadata.referenceId);
    const baseCoverageInstance = findCoverage(baseCoverageEdition, change.metadata.referenceId);

    const newIsBenefit = getIsBenefit(coverageInstance);
    const oldIsBenefit = getIsBenefit(baseCoverageInstance);

    return (
        <ChangeAccordion
            generalTabData={[
                { label: `New ${newIsBenefit.label}`, value: newIsBenefit.value },
                { label: `Old ${oldIsBenefit.label}`, value: oldIsBenefit.value },
            ]}
            originalRules={null}
            ruleType="coverage"
            title={`Coverage attributes updated - ${coverageInstance.name}`}
            updatedRules={null}
        />
    );
};
