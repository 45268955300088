import {
    Alert,
    AlertMode,
    GENERAL_ERROR_MSG,
    GroupedAutocompletePaper,
    MainButton,
    StyledInput,
    toast,
} from '@lemonade-hq/bluis';
import type { MacrosCard, MacrosInitProps, RichTextEditorProps } from '@lemonade-hq/bluiza';
import { MacrosParam, MacrosSupportedEntity, MacrosSupportedProductType, RichTextEditor } from '@lemonade-hq/bluiza';
import {
    editorValueIsEmpty,
    Flex,
    font,
    htmlFromSlate,
    themedColor,
    useRichTextEditor,
    useRichTextEditorState,
    useToggle,
} from '@lemonade-hq/cdk';
import { getLocale } from '@lemonade-hq/lemonation';
import type { Region } from '@lemonade-hq/lemonation';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Card, CARD_BORDER_RADIUS } from '../../shared/components/Card';
import { HtmlBody } from '../../shared/components/HtmlBody';
import { AiResponseType, InterveneType } from '../../shared/types';
import type { TicketAiReview } from '../../shared/types';
import { convertNewLinesToHtmlBreaks, slateFromAdjustedHtml } from '../supervisor.utils';
import { useSupervisorContext } from './Context';
import { AiResponseDraft } from './ResponseSection/AiResponseDraft';
import { RecipientTags } from './ResponseSection/RecipientTags';

const RichTextEditorWrapper = styled.div<{ readonly type?: AiResponseType }>`
    ${StyledInput} {
        width: 250px !important;
    }

    ${GroupedAutocompletePaper} {
        width: 400px;
        ${font('main', { fontSize: '14px' })};
    }

    /* RichTextEditor root element. Using nth-child because it can't be selected differently */
    > div:nth-child(1) {
        border-radius: 0 0 ${CARD_BORDER_RADIUS} ${CARD_BORDER_RADIUS};
    }

    /* MacrosWidget */
    div[role='toolbar'] {
        ${({ type }) =>
            type === AiResponseType.Draft &&
            css`
                padding: 0;
                padding-top: 24px;
            `}

        ${({ type }) =>
            type === AiResponseType.Public &&
            css`
                border-top: 1px solid ${themedColor('separator')};
                padding-block: 16px;
            `}
    }
`;

const StyledRichTextEditor = styled(RichTextEditor)<{ readonly type?: AiResponseType }>`
    ${font('main', { fontSize: '16px', lineHeight: 1.45 })}

    ${({ type }) =>
        type === AiResponseType.Draft &&
        css`
            padding: 0;
        `}

  ${({ type }) =>
        type === AiResponseType.Public &&
        css`
            padding: 16px 24px;
        `}
`;

const StyledRecipientTags = styled(RecipientTags)`
    padding-inline: 24px;
    padding-block: 16px 0;
`;

type ResponseSectionProps = Pick<TicketAiReview, 'actions' | 'htmlSuggestedResponse' | 'type'> & {
    readonly email: TicketAiReview['interaction']['email'];
    readonly userFirstName: TicketAiReview['interaction']['firstName'];
    readonly name: string;
};
const generateMacrosInitProps = (userName: string, region: Region): MacrosInitProps => ({
    entityType: MacrosSupportedEntity.Cx,
    productType: MacrosSupportedProductType.All,
    locale: getLocale(region),
    params: {
        [MacrosParam.TicketRequesterFirstName]: userName,
        [MacrosParam.CurrentUserFirstName]: 'Maya',
        [MacrosParam.TicketAssigneeFirstName]: 'Maya',
    },
    config: {
        forcedAdditionalSpacing: false,
        searchHierarchy: true,
    },
});

export const ResponseSection: React.FC<React.PropsWithChildren<ResponseSectionProps>> = ({
    actions,
    email,
    htmlSuggestedResponse,
    type,
    userFirstName = '',
    name,
}) => {
    const { ticketId = '' } = useParams<{ ticketId: string }>();
    const { open: onLoadSubmit, close: onUnloadSubmit, isOpen: isLoadingSubmit } = useToggle(false);
    const sectionRef = useRef<HTMLDivElement>(null);

    const {
        state: {
            macroTags,
            filters: { regions },
        },
        actions: { loadNextTicket, interveneAiResponse, setMacroTags, expireTicket },
    } = useSupervisorContext();

    const queryClient = useQueryClient();
    const isMutating = Boolean(queryClient.isMutating());

    const { interveneAvailable, noInterventionReason, reviewAvailable } = actions;

    const isPublic = type === AiResponseType.Public;
    const isDraft = type === AiResponseType.Draft;
    const showAISuggestedResponse = isDraft && htmlSuggestedResponse != null;
    const showEmptyResponseEditor = isPublic && interveneAvailable;
    const isBlockedFromActions = !interveneAvailable && !reviewAvailable;

    // Scroll to response when editing
    useEffect(() => {
        if (interveneAvailable && sectionRef.current) {
            const section = sectionRef.current;

            window.scrollTo({ top: section.scrollHeight, behavior: 'smooth' });
        }
    }, [interveneAvailable]);

    const editor = useRichTextEditor();
    const defaultState = useMemo(
        () => (htmlSuggestedResponse != null ? slateFromAdjustedHtml(htmlSuggestedResponse) : undefined),
        [htmlSuggestedResponse]
    );
    const [editorValue, setEditorValue] = useRichTextEditorState({
        editor,
        defaultState: isDraft ? defaultState : undefined,
    });

    const { editorValueHTML, disableSendButton } = useMemo(() => {
        const innerEditorValueHTML = convertNewLinesToHtmlBreaks(htmlFromSlate(editorValue));

        const isContainsPlaceHolderEmojis = innerEditorValueHTML.includes('👈') || innerEditorValueHTML.includes('👉');
        const isEmpty = editorValueIsEmpty(editorValue);

        const innerDisableSendButton = !interveneAvailable || isEmpty || isContainsPlaceHolderEmojis || isMutating;

        return { editorValueHTML: innerEditorValueHTML, disableSendButton: innerDisableSendButton };
    }, [editorValue, interveneAvailable, isMutating]);

    function onSelectMacro(macro: MacrosCard): void {
        setMacroTags(macro.tags);
    }

    async function onSubmitEdit(): Promise<void> {
        onLoadSubmit();

        try {
            await interveneAiResponse({
                intervenedResponse: editorValueHTML,
                ticketId,
                tags: macroTags,
                type: isPublic ? InterveneType.AdditionalResponse : InterveneType.Edit,
            });
            await loadNextTicket();
        } catch (error: unknown) {
            toast.error(GENERAL_ERROR_MSG);
        } finally {
            onUnloadSubmit();
        }
    }

    const macrosInitProps = generateMacrosInitProps(userFirstName, regions[0]);
    const richTextEditorProps: RichTextEditorProps = {
        disabled: !interveneAvailable,
        bordered: false,
        editor,
        macrosInitProps,
        onChange: setEditorValue,
        onSelectMacro,
        value: editorValue,
        toolbarEndComponent: (
            <MainButton disabled={disableSendButton} loading={isLoadingSubmit} onClick={() => void onSubmitEdit()}>
                Send
            </MainButton>
        ),
    };

    const blockedFromActionsAlert = (
        <Alert
            actions={[
                {
                    type: 'button',
                    label: 'Load Next Ticket',
                    onClick: () => void expireTicket(),
                    buttonType: 'inverse',
                    disabled: isMutating,
                },
            ]}
            mode={AlertMode.Attention}
            title={
                <span>
                    <b>Intervention is not available.</b> {noInterventionReason ?? 'Carry on without making any change'}
                </span>
            }
        />
    );

    return (
        <Flex flexDirection="column" gap="20px" mt={16} ref={sectionRef}>
            {showAISuggestedResponse && (
                <AiResponseDraft contactMethod={email ?? 'phone'} isEdit={interveneAvailable}>
                    {interveneAvailable ? (
                        <RichTextEditorWrapper type={type}>
                            <StyledRichTextEditor type={type} {...richTextEditorProps} />
                        </RichTextEditorWrapper>
                    ) : (
                        <HtmlBody html={htmlSuggestedResponse} />
                    )}
                </AiResponseDraft>
            )}

            {showEmptyResponseEditor && (
                <Card
                    alertProps={{
                        mode: AlertMode.Attention,
                        title: (
                            <div>
                                <b>Response already sent.</b> Please send a follow up message to the user clarifying the
                                situation.
                            </div>
                        ),
                    }}
                    isDashed
                    isWithPadding={false}
                >
                    <StyledRecipientTags to={name} />
                    <RichTextEditorWrapper type={type}>
                        <StyledRichTextEditor type={type} {...richTextEditorProps} />
                    </RichTextEditorWrapper>
                </Card>
            )}

            {isBlockedFromActions && blockedFromActionsAlert}
        </Flex>
    );
};
