import { ServiceName } from '@lemonade-hq/bluiza';
import { CurrentPrincipalProvider, Operator, Principal, PrincipalType } from '@lemonade-hq/boutique';
import React from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { blenderGeneral } from '../apiClients';

/**
 * This component ensures that the current principal is available in the context.
 * principal is the user logged in, type "workforce" means it's a Lemonade employee.
 * operator is the details of the Lemonade employee logged in.
 */

interface EnsurePrincipalProps {
    children: React.ReactElement;
}

function useCurrentPrincipalData(): UseQueryResult<Principal> {
    const url = '/api/v1/operators/me';

    return useQuery({
        queryKey: [ServiceName.BlenderGeneral, url],
        queryFn: async () => blenderGeneral.get<{ readonly data: Principal }>(url).then(res => res.data.data),
        staleTime: Number.POSITIVE_INFINITY,
    });
}

function useCurrentOperatorData(enabled: boolean): UseQueryResult<Operator> {
    const url = '/api/v1/operators/legacy_me';

    return useQuery({
        queryKey: [ServiceName.BlenderGeneral, url],
        queryFn: async () => blenderGeneral.get<{ readonly data: Operator }>(url).then(res => res.data.data),
        staleTime: Number.POSITIVE_INFINITY,
        enabled,
    });
}

export default function EnsurePrincipal({ children }: EnsurePrincipalProps) {
    const { data: principal, isError: isPrincipalError, isPending: isPrincipalLoading } = useCurrentPrincipalData();
    const { data: operator, isError: isOperatorError, isPending: isOperatorLoading } = useCurrentOperatorData(
        principal?.type === PrincipalType.Workforce
    );

    if (isPrincipalError || isPrincipalLoading || !principal) {
        return null;
    }

    if (principal.type === PrincipalType.Workforce && (isOperatorError || isOperatorLoading || !operator)) {
        return null;
    }

    return (
        <CurrentPrincipalProvider principal={principal} operator={operator}>
            {children}
        </CurrentPrincipalProvider>
    );
}
