import type { Descendant, EntityTypes as EntityType } from '@lemonade-hq/bluiza';

interface AnalyticsEvent {
    readonly name: string;
    readonly params?: Record<string, string>;
}

export type DraftValue = Descendant[];

export enum NoteType {
    Workflow = 'workflow',
    Communication = 'communication',
    ClaimReviewSummaryNote = 'claim_review_summary_note',
    ClaimInitialReviewSummaryNote = 'claim_initial_review_summary_note',
    ClaimClosureReviewSummaryNote = 'claim_closure_review_summary_note',
    TaskGroupNote = 'task_group_note',
    TaskNote = 'task_note',
}

export interface AdditionalEntity {
    readonly entityType: EntityType;
    readonly entityPublicId: string;
}

export interface NoteEntity {
    readonly publicId: string;
    readonly type: NoteType;
    readonly entities: AdditionalEntity[];
    readonly operatorId: string;
    readonly operatorName: string;
    readonly operatorImageUrl: string;
    readonly content: string;
    readonly adjusterRole: string | undefined;
    readonly isHtmlContent: boolean;
    readonly timestamp: string;
    readonly subject?: string;
}

export interface AddNoteBodyArgs {
    readonly subject?: string;
    readonly entities: AdditionalEntity[];
    readonly content: string;
}

export interface MentionUsersBodyArgs {
    readonly mentionedOperatorsPublicIds: string[];
    readonly entityLink: string;
    readonly entityPublicId: string;
    readonly entityType: string;
}
export interface NotesAnalyticsParams {
    readonly notesIconClicked?: AnalyticsEvent;
    readonly notesAccordionOpened?: AnalyticsEvent;
    readonly notesAccordionClosed?: AnalyticsEvent;
    readonly noteSubmitted?: AnalyticsEvent;
}
