import type { FC } from 'react';
import { NonExpandableChange } from '../NonExpandableChange';
import type { CoupledSettingsEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';

interface SettingDecoupledProps {
    readonly change: CoupledSettingsEntry;
    readonly coveragesEdition: CoveragesEdition;
}

export const SettingDecoupled: FC<SettingDecoupledProps> = ({ change, coveragesEdition }) => {
    const [settingAName, settingBName] = getSettingsNames(change, coveragesEdition);

    return <NonExpandableChange title={`${settingAName} and ${settingBName} decoupled`} />;
};

export function getSettingsNames(change: CoupledSettingsEntry, coveragesEdition: CoveragesEdition): [string, string] {
    const {
        metadata: {
            coupledSettingCodes: [settingATemplateCode, settingBTemplateCode],
        },
    } = change;

    const settingAName = coveragesEdition.settings.find(
        ({ templateCode }) => templateCode === settingATemplateCode
    )?.name;

    const settingBName = coveragesEdition.settings.find(
        ({ templateCode }) => templateCode === settingBTemplateCode
    )?.name;

    if (settingAName === undefined || settingBName === undefined) {
        throw new Error(
            `Setting ${settingATemplateCode} or ${settingBTemplateCode} not found in coverage edition ${coveragesEdition.code}`
        );
    }

    return [settingAName, settingBName];
}
