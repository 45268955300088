import { ChevronIcon, InlineButton } from '@lemonade-hq/bluis';
import { Flex, themedColor } from '@lemonade-hq/cdk';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSupervisorContext } from '../Context';

const ButtonWithIcon = styled(InlineButton)`
    color: ${themedColor('secondaryText')};
    #chevron {
        path: {
            fill: ${themedColor('secondaryText')};
        }
    }

    &:hover {
        color: ${themedColor('linkHover')};
        #chevron {
            path {
                stroke: ${themedColor('linkHover')};
            }
        }
    }
`;

export const BackToSupervisorHomepage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const {
        actions: { unassignTicket },
    } = useSupervisorContext();
    const navigate = useNavigate();

    function onClick(): void {
        void unassignTicket();
        navigate('/backoffice/supervisor');
    }

    return (
        <Flex>
            <ButtonWithIcon onClick={onClick}>
                <Flex alignItems="center" gap="9px">
                    <ChevronIcon direction="left" height={16} id="chevron" width={16} />
                    <span>Supervisor Homepage</span>
                </Flex>
            </ButtonWithIcon>
        </Flex>
    );
};
