import {
    Avatar,
    Card,
    MainButton,
    Multiselect,
    MultiSwitch,
    MultiSwitchContainer,
    SecondaryButton,
    Select,
} from '@lemonade-hq/bluis';
import type { MultiselectOption, SelectOptionBase } from '@lemonade-hq/cdk';
import { fadeInAnimation, Flex, font, themedColor } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import { AiResponseType } from '../../shared/types';
import { aiModeTypes, defaultFilters } from '../supervisor.const';
import { generateIntentsOptions, generateMultiSelectOptions, generateSelectOptions } from '../supervisor.utils';
import { useSupervisorContext } from './Context';

const TicketsAmount = styled.span`
    ${font('main', { fontSize: '16px', fontWeight: 700 })};
`;

const Label = styled.div`
    opacity: 0;
    animation: ${fadeInAnimation()} 0.5s forwards;
`;

const StyledButton = styled(MainButton)<{ readonly disabled?: boolean }>`
    box-shadow: ${({ disabled }) => (disabled ? 'none' : '0px 6px 36px -8px rgba(255, 0, 131, 0.64)')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Content = styled(Flex)`
    padding: 0 0 0 40px;
    flex-direction: column;
    ${font('main', { fontSize: '16px' })};
    height: 50px;
`;

const Title = styled.span`
    ${font('main', { fontSize: '20px', fontWeight: 700 })};
    color: ${themedColor('secondaryText')};
`;

const Container = styled(Flex)`
    flex-direction: column;
    gap: 105px;

    align-items: center;
    justify-content: center;
    margin: auto;
    height: calc(100vh - 70px);

    ${MultiSwitchContainer} {
        height: 32px;
    }
`;

const StyledCard = styled(Card)`
    width: 620px;
    border-radius: 16px;
    background-color: ${themedColor('componentBackground')};
    padding: 24px;
    flex-direction: column;
    gap: 10px;
`;

const FiltersContainer = styled(Flex)`
    gap: 20px;
    align-items: end;
    opacity: 0;
    animation: ${fadeInAnimation()} 0.5s forwards;
`;

const FiltersContainerWrapper = styled.div`
    min-height: 80px;
    z-index: 2;
`;

const FormInputWrapper = styled(Flex)`
    flex-direction: column;
    gap: 8px;
`;

const FiltersLabel = styled.label`
    ${font('main', { fontSize: '12px', fontWeight: 400 })};
    text-transform: uppercase;
    color: ${themedColor('secondaryText')};
`;

const WelcomeCardContainer = styled(Flex)`
    flex-direction: column;
    gap: 40px;
    align-items: center;
`;

function generateWelcomeParagraph(mode: AiResponseType, count?: number): JSX.Element | null {
    if (count == null || count < 0) return null;

    const modeLabel = mode === AiResponseType.Draft ? 'Draft' : 'Public';

    const motivation = count === 0 ? 'You are all done! 🎉' : `Let's get to it! 👏`;
    const suffix = mode === AiResponseType.Public ? '.' : ` in the queue. ${motivation}`;

    return (
        <Label>
            Currently, there are&nbsp;
            <TicketsAmount>
                {count} {modeLabel} tickets
            </TicketsAmount>
            {suffix}
        </Label>
    );
}

export const WelcomePage: React.FC = () => {
    const { state, actions } = useSupervisorContext();
    const { setMode, loadNextTicket, updateFilters } = actions;
    const {
        mode,
        isLoadingTicket,
        filters: selectedFilters,
        metadata: {
            intents: { tools },
            products,
            pendingTicketsCount,
            mediums,
            languages,
            regions,
        },
    } = state;

    const { public: publicCount, draft } = pendingTicketsCount;

    const count = AiResponseType.Public === mode ? publicCount : draft;
    const selectedModeIndex = aiModeTypes.findIndex(option => option.value === mode);

    function handleSwitch(optionValue: string): void {
        setMode(optionValue as AiResponseType);
    }

    function handleClearFilters(): void {
        updateFilters(defaultFilters);
    }

    function handleOptionsSelect(selectedOptions: MultiselectOption[] | SelectOptionBase[], filterName: string): void {
        const updatedFilters = {
            ...selectedFilters,
            [filterName]: selectedOptions.map(option => option.id),
        };

        updateFilters(updatedFilters);
    }

    const isClearButtonDisabled = Object.values(selectedFilters).every((filter: unknown[]) => filter.length === 0);

    const isLoadedMetadata = Object.keys(tools).length > 0;

    return (
        <Container>
            <FiltersContainerWrapper>
                {isLoadedMetadata && (
                    <FiltersContainer>
                        <FormInputWrapper>
                            <FiltersLabel>LOBs</FiltersLabel>
                            <Multiselect
                                options={generateMultiSelectOptions(products)}
                                placeholder="Select"
                                selectedIds={selectedFilters.products}
                                setSelectedOptions={p => handleOptionsSelect(p, 'products')}
                                width={180}
                            />
                        </FormInputWrapper>
                        <FormInputWrapper>
                            <FiltersLabel>Intents</FiltersLabel>
                            <Multiselect
                                options={generateIntentsOptions(tools)}
                                placeholder="Select"
                                selectedIds={selectedFilters.intents}
                                setSelectedOptions={intents => handleOptionsSelect(intents, 'intents')}
                                width={300}
                            />
                        </FormInputWrapper>
                        <FormInputWrapper>
                            <FiltersLabel>Mediums</FiltersLabel>
                            <Multiselect
                                options={generateMultiSelectOptions(mediums)}
                                placeholder="Select"
                                selectedIds={selectedFilters.mediums}
                                setSelectedOptions={innerMediums => handleOptionsSelect(innerMediums, 'mediums')}
                                width={180}
                            />
                        </FormInputWrapper>
                        <FormInputWrapper>
                            <FiltersLabel>Languages</FiltersLabel>
                            <Multiselect
                                options={generateMultiSelectOptions(languages)}
                                placeholder="Select"
                                selectedIds={selectedFilters.languages}
                                setSelectedOptions={langs => handleOptionsSelect(langs, 'languages')}
                                width={180}
                            />
                        </FormInputWrapper>
                        <FormInputWrapper>
                            <FiltersLabel>Region</FiltersLabel>
                            <Select
                                onOptionSelected={innerRegions => handleOptionsSelect([innerRegions], 'regions')}
                                options={generateSelectOptions(regions)}
                                placeholder="Select"
                                value={selectedFilters.regions[0] ?? regions[0]}
                                width={120}
                            />
                        </FormInputWrapper>
                        <SecondaryButton disabled={isClearButtonDisabled} onClick={handleClearFilters}>
                            Clear
                        </SecondaryButton>
                    </FiltersContainer>
                )}
            </FiltersContainerWrapper>
            <WelcomeCardContainer>
                <StyledCard>
                    <Flex flexDirection="column" gap="10px">
                        <Flex alignItems="center" justifyContent="space-between">
                            <Flex alignItems="center" gap="10px">
                                <Avatar name="A I" />
                                <Title>Lemonade&apos;s AI</Title>
                            </Flex>
                            <MultiSwitch
                                name="switch-mode"
                                onSwitch={e => handleSwitch(e?.target.value ?? AiResponseType.Public)}
                                options={aiModeTypes}
                                selected={selectedModeIndex}
                            />
                        </Flex>
                        <Content>
                            <Label>Welcome Supervisor!</Label>
                            {generateWelcomeParagraph(mode, count)}
                        </Content>
                    </Flex>
                </StyledCard>
                <Flex alignItems="center" flexDirection="column" gap="10px">
                    <StyledButton disabled={count <= 0} loading={isLoadingTicket} onClick={() => void loadNextTicket()}>
                        Load Next Ticket
                    </StyledButton>
                </Flex>
            </WelcomeCardContainer>
        </Container>
    );
};
