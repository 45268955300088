import { themedColor } from '@lemonade-hq/boutique';
import { Popover } from '@lemonade-hq/cdk';
import type { Options as PopperOptions } from '@popperjs/core';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { Dialog } from './Dialog';

interface ButtonProps {
    readonly open: boolean;
}

const Button = styled.button<ButtonProps>`
    display: block;
    width: 28px;
    height: 28px;
    margin: 0 16px 0 0;
    padding: 0;
    background: ${props => themedColor(props.open ? 'lightBackground' : 'componentBackground')};
    border: 1px solid ${themedColor('secondaryButtonBorder')};
    border-radius: 4px;
    font-size: 18px;
    transition: background-color 0.2s ease-out;
`;

const popoverOptions: PopperOptions = {
    modifiers: [{ name: 'offset', options: { offset: [10, 6] } }],
    placement: 'bottom-end',
    strategy: 'fixed',
};

export const ImpersonationToggle: React.FC<React.PropsWithChildren<unknown>> = () => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleClick = useCallback(() => {
        setOpen(o => !o);
    }, []);

    return (
        <>
            <Button onClick={handleClick} open={open} ref={anchorRef} type="button">
                🥸
            </Button>
            <Popover
                anchorEl={anchorRef}
                isOpen={open}
                onBackdropClick={handleClick}
                options={popoverOptions}
                zIndex={1}
            >
                <Dialog />
            </Popover>
        </>
    );
};
