import { isDefined } from '@lemonade-hq/ts-helpers';
import type { UseSuspenseQueryResult } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';

export function useSuspenseQueryWithErrorHandling<TData, TError = unknown>(
    options: Parameters<typeof useSuspenseQuery>[0]
): UseSuspenseQueryResult<TData, TError> {
    const result = useSuspenseQuery(options);

    if (isDefined(result.error) && !result.isFetching) {
        throw result.error;
    }

    return result as UseSuspenseQueryResult<TData, TError>;
}
