import { Flex, Layout, spacing } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { shimmering, shimmeringMockBlock } from '../Attachments.css';

const mockArray = Array.from(new Array(5).keys());

export const TableLoader: FC = () => {
    return (
        <Flex flexDirection="column" gap={spacing.s12} padding={spacing.s12}>
            <Layout className={shimmering}>
                <Layout className={shimmeringMockBlock} />
            </Layout>
            {mockArray.map(it => (
                <Layout
                    className={shimmering}
                    display="grid"
                    gap={spacing.s08}
                    gridTemplateColumns="40px repeat(4, 1fr) 30px"
                    key={it}
                    style={{ animationDelay: `${it * 0.3}s` }}
                >
                    <Layout className={shimmeringMockBlock} />
                    <Layout className={shimmeringMockBlock} />
                    <Layout className={shimmeringMockBlock} />
                    <Layout className={shimmeringMockBlock} />
                    <Layout className={shimmeringMockBlock} />
                    <Layout className={shimmeringMockBlock} />
                </Layout>
            ))}
        </Flex>
    );
};
