import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { blenderGeneral } from '../apiClients';

export enum ClientSettingsQueryKey {
    GetClientSettings = 'get_client_settings',
    GetHomePage = 'get_home_page',
}

export interface LinkItem {
    readonly name: string;
    readonly link: string;
}

export interface SubMenuItem {
    readonly name: string;
    readonly subMenu: SideMenuItems;
}

export type SideMenuItem = LinkItem | SubMenuItem;
export type SideMenuItems = SideMenuItem[];
export enum HomePage {
    Agent = 'agent',
    QuoteList = 'quoteList',
    Workforce = 'workforce',
}

export interface ClientSettings {
    readonly menu: SideMenuItems;
    readonly addressSearchEnabled: boolean;
    readonly canCreateQuote: boolean;
    readonly policyList?: {
        readonly userPageEnabled: boolean;
    };
    readonly quoteList?: {
        readonly userPageEnabled: boolean;
    };
    readonly homepage?: {
        readonly homepage: HomePage;
    };
    readonly realtime?: {
        readonly pubnubSubscribeKey?: string;
    };
}

export async function getClientSettings(): Promise<ClientSettings> {
    const url =
        '/api/v1/operators/client_settings?include=menu,canCreateQuote,addressSearchEnabled,policyList,quoteList,realtime';

    return await blenderGeneral.get<{ data: { data: ClientSettings } }>(url).then(res => res.data.data.data);
}

export function useClientSettings(): UseQueryResult<ClientSettings> {
    return useQuery({ queryKey: [ClientSettingsQueryKey.GetClientSettings], queryFn: getClientSettings });
}

export async function getHomePage(): Promise<HomePage> {
    const url = '/api/v1/operators/client_settings?include=homepage';

    const res = await blenderGeneral.get<{ data: { data: { homepage: { homepage: HomePage } } } }>(url);

    return res.data.data.data.homepage.homepage;
}

export function useHomePage(): UseQueryResult<HomePage> {
    return useQuery({ queryKey: [ClientSettingsQueryKey.GetHomePage], queryFn: getHomePage });
}
