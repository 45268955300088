import type { IconName, ThemeColor } from '@lemonade-hq/blender-ui';
import { Flex, Icon, Text } from '@lemonade-hq/blender-ui';
import { isDefined } from '@lemonade-hq/ts-helpers';
import type { FC, PropsWithChildren } from 'react';
import { iconWithLabelWrapper, iconWrapper, textWrapper } from './IconWithLabel.css';

interface InputWrapperProps {
    readonly label: string;
    readonly iconName: IconName;
    readonly iconColor?: ThemeColor;
    readonly onClick?: () => void;
}

export const IconWithLabel: FC<PropsWithChildren<InputWrapperProps>> = ({ label, iconName, onClick, iconColor }) => {
    return (
        <Flex className={iconWithLabelWrapper}>
            <Icon
                className={iconWrapper}
                color={iconColor}
                name={iconName}
                onClick={() => isDefined(onClick) && onClick()}
            />
            <Text className={textWrapper}>{label}</Text>
        </Flex>
    );
};
