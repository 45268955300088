import { Flex } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import styled from 'styled-components';
import type {
    UnderwritingDeclineRule,
    UnderwritingFlagRule,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

export type UnderwritingFilterRule = UnderwritingDeclineRule | UnderwritingFlagRule;

export const StyledRulesTitle = styled(Flex)`
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
`;

export const StyledRulesTitleContainer = styled(Flex)`
    gap: 8px;
    align-items: center;
`;

export function isDeclineRule(rule: UnderwritingFilterRule): rule is UnderwritingDeclineRule {
    return isDefined((rule as UnderwritingDeclineRule).reasonCode);
}

export enum ManageRuleStep {
    Segment = 'segment',
    Outcome = 'outcome',
}

export const ORDERED_RULE_STEPS: ManageRuleStep[] = [ManageRuleStep.Segment, ManageRuleStep.Outcome];

export enum UnderwritingDialogType {
    AddDeclineRule = 'addDeclineRule',
    AddFlagRule = 'addFlagRule',
    EditDeclineRule = 'editDeclineRule',
    EditFlagRule = 'editFlagRule',
    RemoveFlagRule = 'removeFlagRule',
    RemoveDeclineRule = 'removeDeclineRule',
}
