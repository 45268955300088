import * as types from '../actions/actionTypes';
import { PolicyActions } from '../actions/policyActionsTypes';

// TODO: this will be replaced by the Entity provider in Monolu/bluiza

const countries = {
    US: {
        date: {
            format: 'MM/DD/YYYY',
            short: 'MMM DD, YYYY',
            dayFirst: false,
        },
        currency: {
            unit: '$',
            delimiter: ',',
            separator: '.',
            format: '%u%n',
            rightSign: false,
            currency: 'USD',
        },
        quoteSections: [
            'ScheduledItems',
            'AddOns',
            'ZeroDeductible',
            'Deductible',
            'CoveredPerils',
            'SamplePolicy',
            'NotCoveredPerils',
            'OpenPolicy',
            'PolicyActivation',
            'AnyQuestions',
        ],
    },
    DE: {
        date: {
            format: 'DD.MM.YYYY',
            short: 'DD. MMM YYYY',
            dayFirst: true,
        },
        currency: {
            unit: '€',
            delimiter: '.',
            separator: ',',
            format: '%n %u',
            rightSign: true,
            currency: 'EUR',
        },
        quoteSections: [
            'ScheduledItems',
            'AddOns',
            'CoveredPerils',
            'Deductible',
            'NotCoveredPerils',
            'OpenPolicy',
            'PolicyActivation',
            'AnyQuestions',
            'ZeroDeductible',
            'SamplePolicy',
        ],
    },
    NL: {
        date: {
            format: 'DD-MM-YYYY',
            short: 'DD MMM, YYYY',
            dayFirst: true,
        },
        currency: {
            unit: '€',
            delimiter: '.',
            separator: ',',
            format: '%u %n',
            rightSign: false,
            currency: 'EUR',
        },
        quoteSections: [
            'ScheduledItems',
            'AddOns',
            'CoveredPerils',
            'Deductible',
            'NotCoveredPerils',
            'OpenPolicy',
            'PolicyActivation',
            'AnyQuestions',
            'ZeroDeductible',
            'SamplePolicy',
        ],
    },
    FR: {
        date: {
            format: 'DD-MM-YYYY',
            short: 'DD MMM, YYYY',
            dayFirst: true,
        },
        currency: {
            unit: '€',
            delimiter: '.',
            separator: ',',
            format: '%u %n',
            rightSign: false,
            currency: 'EUR',
        },
        quoteSections: [
            'ScheduledItems',
            'AddOns',
            'CoveredPerils',
            'Deductible',
            'NotCoveredPerils',
            'OpenPolicy',
            'PolicyActivation',
            'AnyQuestions',
            'ZeroDeductible',
            'SamplePolicy',
        ],
    },
    GB: {
        date: {
            format: 'DD-MM-YYYY',
            short: 'DD MMM, YYYY',
            dayFirst: true,
        },
        currency: {
            unit: '£',
            delimiter: ',',
            separator: '.',
            format: '%u%n',
            rightSign: false,
            currency: 'GBP',
        },
        quoteSections: [
            'ScheduledItems',
            'AddOns',
            'Deductible',
            'CoveredPerils',
            'NotCoveredPerils',
            'PolicyActivation',
            'AnyQuestions',
        ],
        isEU: true,
    },
};

const initialState = {
    currencySettings: countries.US,
};

export default function currency(state = initialState, action = {}) {
    const { type, data } = action;
    const nameSpace = {
        [types.SET_USER]: 'user',
    };

    switch (type) {
        case types.SET_CLAIM_DATA:
        case types.SET_QUOTE:
        case PolicyActions.SET_POLICY:
        case types.SET_USER:
        case types.SET_CURRENCY_LOCALE: {
            const entity = nameSpace[type] ? data[nameSpace[type]] : data;
            const [, country] = entity.locale.split('-');
            const currSettings = countries[country] || initialState.currencySettings;

            return {
                ...state,
                currencySettings: currSettings.currency,
            };
        }
        default: {
            const currencySettings =
                state.currencySettings.unit == null ? initialState.currencySettings.currency : state.currencySettings;

            return {
                ...state,
                currencySettings,
            };
        }
    }
}
