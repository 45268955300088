import { ServiceName } from '@lemonade-hq/bluiza';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { blenderGeneral } from '../../../../apiClients';

interface DataLoaderResponse<T> {
    readonly data: T;
}

export enum AdjusterAvailability {
    Available = 'available',
    Unavailable = 'not_available',
}

export enum AdjusterStatus {
    Active = 'active',
    Inactive = 'inactive',
}

interface Adjuster {
    readonly availability: AdjusterAvailability;
    readonly publicId: string;
    readonly status: AdjusterStatus;
}

type AdjustersResponse = DataLoaderResponse<Adjuster[]>;

export function useAdjuster(enabled: boolean, id?: number | string): UseQueryResult<Adjuster | undefined> {
    return useQuery({
        queryKey: [ServiceName.BlenderGeneral, 'ADJUSTER', id],

        queryFn: async () =>
            await blenderGeneral
                .get<AdjustersResponse>(`/api/v1/adjusters?operatorPublicId=${id}`)
                .then(({ data }) => data.data[0]),

        enabled,
    });
}
