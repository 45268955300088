import { Alert, AlertMode } from '@lemonade-hq/bluis';
import type { FC } from 'react';
import * as styles from './toolEditorAlert.css';

// TODO: Alert should be replaced with the new Zest Banner component
export const InexistentToolAlert: FC = () => (
    <Alert
        className={styles.toolEditorAlert}
        mode={AlertMode.Info}
        title="This tool did not exist yet in this tools revision"
    />
);
