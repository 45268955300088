import type { SelectOption } from '@lemonade-hq/bluis';
import { FormInputWrapper, Select } from '@lemonade-hq/bluis';
import type { FormProps, SelectOptionBase } from '@lemonade-hq/cdk';
import { basicRequiredValidation, Flex, useForm } from '@lemonade-hq/cdk';
import { ALL_REGIONS, ALL_US_STATES } from '@lemonade-hq/lemonation';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import styled from 'styled-components';
import type { SpecificExplanationItem } from '../underwritingUtils';
import { compareExplanationsCountryAndState, isCountryUS } from '../underwritingUtils';
import { RemoveButton } from 'components/LoCo/common/components/RemoveButton';

export const StyledRowWrapper = styled(FormInputWrapper)<{ readonly width?: string }>`
    position: relative;

    label {
        display: none; // the label creates a gap
    }

    > div {
        align-items: flex-start;
        justify-content: space-between;
    }
`;

const StyledLocation = styled(Flex)`
    gap: 10px;
`;

interface SpecificExplanationProps {
    readonly specificExplanationItem: SpecificExplanationItem;
    readonly allItems: SpecificExplanationItem[];
    readonly availableExplanations: SelectOptionBase[];
    readonly defaultExplanationCode?: string;
    readonly showErrors: boolean;
    readonly isLoading: boolean;
    readonly disabled: boolean;
    readonly onChange: (item: SpecificExplanationItem) => void;
    readonly onRemove: () => void;
}

export const SpecificExplanation: React.FC<SpecificExplanationProps> = ({
    specificExplanationItem,
    allItems,
    availableExplanations: explanationOptions,
    isLoading,
    defaultExplanationCode,
    disabled,
    onChange,
    onRemove,
}) => {
    const otherItems = allItems.filter(item => !compareExplanationsCountryAndState(item, specificExplanationItem));

    const countryOptions: SelectOption[] = useMemo(() => {
        return ALL_REGIONS.filter(
            region => (isCountryUS(region) ? true : !otherItems.some(otherItem => otherItem.country === region)) // for non US, filter out those who exist in other items
        )
            .sort((a, b) => a.localeCompare(b))
            .map(country => ({
                value: country,
                id: country,
                label: country,
            }));
    }, [otherItems]);

    const stateOptions: SelectOption[] = useMemo(
        () =>
            ALL_US_STATES.filter(state => !otherItems.some(otherItem => otherItem.state === state))
                .sort((a, b) => a.localeCompare(b))
                .map(state => ({ value: state, id: state, label: state })),
        [otherItems]
    );

    const form = {
        fields: {
            country: {
                startValue: specificExplanationItem.country,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            state: {
                startValue: specificExplanationItem.state ?? '',
                validations: {
                    required: {
                        errorMessage: '',
                        test: (value: string) => value.length > 0,
                        skipCondition: () => !isCountryUS(specificExplanationItem.country),
                    },
                },
            },
            code: {
                startValue: specificExplanationItem.code || '',
                validations: { required: basicRequiredValidation },
            },
            name: {
                startValue: specificExplanationItem.name || '',
                validations: { required: basicRequiredValidation },
            },
        },
    } as const satisfies FormProps;

    const { values, setValue, errors } = useForm(form);

    // clear in similar to default explanation
    if (defaultExplanationCode === values.code) {
        onValuesChange({
            code: '',
            name: '',
        });
    }

    function onValuesChange<T extends keyof typeof form.fields, K extends (typeof form.fields)[T]['startValue']>(
        changedFields: Record<T, K>
    ): void {
        Object.entries(changedFields).forEach(([key, value]) => setValue(key as T, value as K));

        onChange({
            ...{
                country: values.country,
                state: values.state.length > 0 ? values.state : undefined,
                code: values.code,
                name: values.name,
            },
            ...changedFields, // override changed value
            isValid: null, // will be set by parent
        });
    }

    return (
        <StyledRowWrapper
            key={`item_${specificExplanationItem.country}_${specificExplanationItem.state}`}
            // eslint-disable-next-line react/jsx-no-useless-fragment
            label={<></>}
            showErrors={!isEmpty(Object.values(errors))}
        >
            <>
                {!disabled && <RemoveButton onRemoveClick={onRemove} right="-10px" top="6px" />}
                <StyledLocation>
                    <Select
                        disabled={isLoading || disabled}
                        onOptionSelected={selectedOption => {
                            onValuesChange({ country: selectedOption.value });
                            if (!isCountryUS(selectedOption.value) && values.state) {
                                onValuesChange({ state: '' });
                            }
                        }}
                        options={countryOptions}
                        placeholder=""
                        value={values.country}
                        width={70}
                    />
                    <Select
                        disabled={isLoading || disabled || !isCountryUS(values.country)}
                        onOptionSelected={selectedOption => onValuesChange({ state: selectedOption.value })}
                        options={stateOptions}
                        placeholder=""
                        value={values.state}
                        width={70}
                    />
                </StyledLocation>
                <Select
                    disabled={isLoading || disabled || explanationOptions.length === 0}
                    onOptionSelected={selectedOption =>
                        onValuesChange({
                            code: selectedOption.value,
                            name: selectedOption.label?.toString() ?? '',
                        })
                    }
                    options={explanationOptions}
                    placeholder="Select"
                    value={values.code}
                    width={250}
                />
            </>
        </StyledRowWrapper>
    );
};
