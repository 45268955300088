import type { BLuisTheme } from '@lemonade-hq/bluis-atomic';
import { font, themedColor } from '@lemonade-hq/boutique';
import styled, { css } from 'styled-components';

export const BSelectContainer = styled.div<{ readonly isOpen: boolean }>`
    min-width: 120px;
    position: relative;
    box-sizing: border-box;
    user-select: none;
    z-index: ${props => (props.isOpen ? '100' : '')};
`;
export const BSelectTitle = styled.div`
    font-size: 12px;
    color: ${themedColor('primaryText')};
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 3px;
`;

export const SelectContainer = styled.div<{ readonly isDisabled?: boolean }>`
    min-width: 120px;
    height: 39px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    pointer-events: ${props => (props.isDisabled ? 'none' : '')};
    opacity: ${props => (props.isDisabled ? '0.5' : '1')};
    user-select: none;
`;

export const Select = styled.div<{ readonly isOpen: boolean; readonly above: boolean; readonly theme?: BLuisTheme }>`
    padding-left: 10px;
    width: 100%;
    height: 100%;
    background: ${themedColor('componentBackground')};
    border: 1px solid;
    border-color: ${props => (props.isOpen ? themedColor('primaryText') : themedColor('inputBorder'))};
    border-radius: 5px;
    font:
        400 14px/36px 'Lato',
        'Helvetica Neue',
        Arial,
        sans-serif;
    white-space: nowrap;

    &::after {
        width: 7px;
        height: 5px;
        content: '';
        position: absolute;
        right: 12px;
        top: 18px;
        background-image: url('${__assets_url}blender_assets/backoffice/actions_dropdown_arrow.png');
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(${props => (props.isOpen ? '180deg' : '0')});
        transition: transform 0.3s linear;
    }
`;

export const SelectTitle = styled.div<{
    readonly withIcons?: boolean;
    readonly multiple?: boolean;
    readonly showPlaceholder?: boolean;
}>`
    display: inline-block;
    vertical-align: middle;
    width: ${props => (props.withIcons && !props.multiple ? 'calc(100% - 50px)' : 'calc(100% - 20px)')};
    color: ${themedColor('primaryText')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ showPlaceholder }) =>
        showPlaceholder &&
        css`
            color: ${themedColor('secondaryText')};
        `}
`;

export const FilterInput = styled.input`
    border: 0;
    height: 37px;
    width: calc(100% - 20px);
    background-color: ${themedColor('componentBackground')};
    color: ${themedColor('primaryText')};

    &:focus {
        outline: 0;
    }
`;

export const ActionsContainer = styled.div`
    background-color: ${themedColor('componentBackground')};
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: ${themedColor('separator')};
    }
`;

export const ActionButton = styled.button.attrs({ type: 'button' })`
    width: 100%;
    color: ${themedColor('attention')};
    background-color: ${themedColor('componentBackground')};
    border: none;
    padding: 10px;
    cursor: pointer;
    text-align: unset;
    ${font('main', { fontSize: '1.4rem' })}

    &:hover {
        background-color: ${themedColor('attentionBackground')};
    }
`;
