import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Value = string[] | boolean | number | string | null;

export type PersistStateType = Record<string, Value>;

type Config<T> = {
    readonly urlParams?: (keyof T)[];
    readonly localStorageKeys?: (keyof T)[];
};

interface UsePersistStateReturn<T extends PersistStateType> {
    readonly state: T;
    readonly updateState: (newState: Partial<T>) => void;
}

function isEmptyValue(value: Value): boolean {
    return value == null || value === '' || (Array.isArray(value) && value.length === 0);
}

function setStateByLocalStorage<T>(initialState: T, config?: Config<T>): T {
    config?.localStorageKeys?.forEach(key => {
        const storedValue = localStorage.getItem(String(key));
        if (storedValue != null && storedValue !== '') {
            initialState[key] = JSON.parse(storedValue) as unknown as T[keyof T];
        }
    });

    return initialState;
}

export function usePersistState<T extends PersistStateType>(
    initialState: T,
    config?: Config<T>
): UsePersistStateReturn<T> {
    const [state, setState] = useState<T>(setStateByLocalStorage(initialState, config));
    const navigate = useNavigate();
    const { search } = useLocation();

    const setQueryParams = useCallback(
        (newState: Partial<T>) => {
            if (config?.urlParams && config.urlParams.length > 0) {
                const updatedState = { ...state, ...newState };
                const params = new URLSearchParams(search);
                config.urlParams.forEach(key => {
                    const value = updatedState[key];
                    if (!isEmptyValue(value)) {
                        params.set(String(key), String(value));
                    } else {
                        params.delete(String(key));
                    }
                });
                navigate({ search: params.toString() });
            }
        },
        [config?.urlParams, navigate, search, state]
    );

    const setLocalStorage = useCallback(
        (newState: Partial<T>) => {
            if (config?.localStorageKeys && config.localStorageKeys.length > 0) {
                const updatedState = { ...state, ...newState };

                config.localStorageKeys.forEach(key => {
                    const value = updatedState[key];
                    if (!isEmptyValue(value)) {
                        localStorage.setItem(String(key), JSON.stringify(value));
                    } else {
                        localStorage.removeItem(String(key));
                    }
                });
            }
        },
        [config?.localStorageKeys, state]
    );

    const updateState = useCallback(
        (newState: Partial<T>) => {
            setState(val => ({ ...val, ...newState }));

            if (config?.urlParams) {
                setQueryParams(newState);
            }

            if (config?.localStorageKeys) {
                setLocalStorage(newState);
            }
        },
        [config?.urlParams, config?.localStorageKeys, setQueryParams, setLocalStorage]
    );

    const handleNavigationChange = useCallback(() => {
        const initialUrlState = { ...initialState };

        if (config?.urlParams) {
            const params = new URLSearchParams(search);
            config.urlParams.forEach(key => {
                const value = params.get(String(key));
                if (!isEmptyValue(value)) {
                    initialUrlState[key] = value as unknown as T[keyof T];
                }
            });
        }

        setState(val => ({ ...val, ...initialUrlState }));
    }, [config?.urlParams, initialState, search]);

    // set state by query params
    useEffect(() => {
        handleNavigationChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return { state: state, updateState: updateState };
}
