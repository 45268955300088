import { Tab as HeadlessTab } from '@headlessui/react';
import { clsx } from 'clsx';
import type { ForwardRefRenderFunction, MouseEvent, ReactNode } from 'react';
import { forwardRef, Fragment, useCallback } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import type { LayoutSprinklesProps } from '../../base/Layout/Layout.css';
import type { TextProps } from '../../base/Text/Text';
import { Text } from '../../base/Text/Text';
import type { TextVariants } from '../../base/Text/Text.css';
import { borderRadius } from '../../theme/radius.css';
import { spacing } from '../../theme/spacing.css';
import { useTabs } from './context';
import { counter, tab } from './Tabs.css';
import type { TabsSize, TabsVariant } from './types';

const getTabTextTypeBySize = (tabSize: TabsSize): NonNullable<TextVariants>['type'] => {
  switch (tabSize) {
    case 'sm':
      return 'text-sm';
    case 'md':
    default:
      return 'text-md';
  }
};

const getTabFontWeightByVariant = (variant: TabsVariant, isSelected: boolean): 'bold' | 'regular' => {
  switch (variant) {
    case 'inline':
      return isSelected ? 'bold' : 'regular';
    default:
      return 'regular';
  }
};

const getCountTextType = (variant: TabsVariant, size: TabsSize): NonNullable<TextVariants>['type'] => {
  switch (variant) {
    case 'fill':
    case 'segmented':
      return 'text-sm';
    default:
      return getTabTextTypeBySize(size);
  }
};

const getCounterBgColor = (variant: TabsVariant, isSelected: boolean): LayoutSprinklesProps['backgroundColor'] => {
  switch (variant) {
    case 'fill':
      return isSelected ? '$backgroundPrimary' : '$backgroundQuaternary';
    case 'segmented':
      return '$backgroundQuaternary';
    default:
      return '$backgroundPrimary';
  }
};

const getTabTextColor = (isSelected: boolean, disabled: boolean): TextProps<'span'>['color'] =>
  isSelected ? 'primary' : disabled ? 'disabled' : 'secondary';

interface TabProps {
  readonly children: ReactNode;
  readonly index?: number;
  readonly onClick?: ({
    event,
    index,
  }: {
    readonly event: MouseEvent<HTMLButtonElement>;
    readonly index: number;
  }) => void;
  readonly disabled?: boolean;
  readonly count?: number;
  readonly className?: string;
}

const TabComponent: ForwardRefRenderFunction<HTMLButtonElement, TabProps> = (
  { children, index, onClick, disabled = false, count, className, ...props },
  ref,
) => {
  const { uuid, size, variant } = useTabs();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, selected: boolean) => {
      if (disabled || selected) return;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onClick?.({ event, index: index! });
    },
    [disabled, index, onClick],
  );

  return (
    <HeadlessTab as={Fragment}>
      {({ selected }) => (
        <button
          aria-controls={`${uuid}--panel--${index}`}
          aria-selected={selected}
          className={clsx(
            tab({ variant, size, isSelected: String(selected) }),
            borderRadius({ borderRadius: variant === 'inline' ? undefined : 'sm' }),
            className,
          )}
          disabled={disabled}
          id={`${uuid}--tab--${index}`}
          onClick={e => handleClick(e, selected)}
          ref={ref}
          role="tab"
          tabIndex={selected ? 0 : -1}
          type="button"
          {...props}
        >
          <Text
            as="span"
            color={getTabTextColor(selected, disabled)}
            fontWeight={getTabFontWeightByVariant(variant, selected)}
            padding={`0 ${spacing.s04}`}
            type={getTabTextTypeBySize(size)}
          >
            {children}
          </Text>
          {count != null && (
            <Flex
              alignItems="center"
              backgroundColor={getCounterBgColor(variant, selected)}
              className={clsx(counter({ variant }), borderRadius({ borderRadius: 'xs' }))}
              justifyContent="center"
              padding={`0 ${spacing.s02}`}
            >
              <Text
                as="span"
                color={variant === 'inline' ? getTabTextColor(selected, disabled) : 'secondary'}
                type={getCountTextType(variant, size)}
              >
                {variant === 'inline' ? (
                  <Layout as="span" padding={`0 ${spacing.s04} 0 0`}>
                    ·
                  </Layout>
                ) : (
                  ''
                )}
                {count}
              </Text>
            </Flex>
          )}
        </button>
      )}
    </HeadlessTab>
  );
};

export const Tab = forwardRef(TabComponent);
