import { spinnerStyles } from './Spinner.css';

type SpinnerSize = 'lg' | 'md' | 'sm' | 'xl';

type SpinnerColor = 'grey' | 'pink' | 'white';

export interface SpinnerProps {
  readonly size: SpinnerSize;
  readonly color?: SpinnerColor;
}

export const Spinner: React.FC<SpinnerProps> = ({ size, color = 'pink' }) => {
  return <div className={spinnerStyles({ size, color })} role="progressbar" />;
};
