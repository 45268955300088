import { Flex } from '@lemonade-hq/cdk';
import type { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { CopyableText } from './CopyButton';

const StyledComponentWrapper = styled(Flex)<{ readonly width?: string }>`
    width: ${({ width }) => `${width}` || '100%'};
    position: relative;
`;

const StyledCopyableText = styled(Flex)<{ readonly top: string; readonly right: string }>`
    position: absolute;
    right: ${({ right }) => `${right}`};
    top: ${({ top }) => `${top}`};
`;

interface CopyableComponentProps {
    readonly textToCopy: string;
    readonly width?: string;
    readonly buttonLocation?: {
        readonly top?: string;
        readonly right?: string;
    };
}

export const CopyableComponent: React.FC<PropsWithChildren<CopyableComponentProps>> = ({
    textToCopy,
    children,
    width,
    buttonLocation,
}) => {
    return (
        <StyledComponentWrapper width={width}>
            {children}
            <StyledCopyableText right={buttonLocation?.right ?? '-25px'} top={buttonLocation?.top ?? '5px'}>
                <CopyableText textToCopy={textToCopy} textToDisplay={''} />
            </StyledCopyableText>
        </StyledComponentWrapper>
    );
};
