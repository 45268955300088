import type { SnakeCaseToCamelCase } from '@lemonade-hq/ts-helpers';
import { capitalize } from 'commons/StringUtils';
import type { Address } from 'models/Address';

export type AddressComponents = Partial<Address> | Partial<SnakeCaseToCamelCase<Address>>;

export function formatAddress(addressComponents?: Partial<SnakeCaseToCamelCase<Address>>): string;
export function formatAddress(addressComponents?: Partial<Address>): string;
export function formatAddress(addressComponents?: AddressComponents): string;
export function formatAddress(addressComponents?: AddressComponents | string): string;
export function formatAddress(addressComponents?: AddressComponents | string): string {
    if (typeof addressComponents === 'string') {
        return addressComponents;
    }

    if (addressComponents == null) {
        return '';
    }

    const { street = '', city = '', state = '', unit } = addressComponents;

    const streetNumber =
        'street_number' in addressComponents
            ? addressComponents.street_number
            : 'streetNumber' in addressComponents
              ? addressComponents.streetNumber
              : '';

    const postalCode =
        'postal_code' in addressComponents
            ? addressComponents.postal_code
            : 'postalCode' in addressComponents
              ? addressComponents.postalCode
              : '';

    const formattedStreet = [streetNumber, street].filter(c => c != null && c.length > 0).join(' ');
    const formattedUnit = unit ? `Apt. ${unit}` : null;
    const formattedStateAndPostal = [state, postalCode].filter(c => c != null && c.length > 0).join(' ');

    return (
        [formattedStreet, formattedUnit, city, formattedStateAndPostal]
            .filter(c => c != null && c.length > 0)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(c => capitalize(c))
            .join(', ')
    );
}

export function isSameAddress(a: AddressComponents, b: AddressComponents): boolean {
    const comparableKeys = ['country', 'state', 'city', 'street', 'street_number', 'unit'];
    const aToCompare = Object.fromEntries(Object.entries(a).filter(([key]) => !comparableKeys.includes(key)));
    const bToCompare = Object.fromEntries(Object.entries(b).filter(([key]) => !comparableKeys.includes(key)));

    return formatAddress(aToCompare) === formatAddress(bToCompare);
}
