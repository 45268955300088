import { useEffect, useState } from 'react';

export function isOverflowing(el: HTMLElement | null | undefined): boolean {
  if (!el) return false;

  return el.scrollWidth > el.clientWidth;
}

export function useIsOverflowing(el: HTMLElement | null | undefined): boolean {
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    if (el == null) return;

    setIsOverflown(isOverflowing(el));
    const ro = new ResizeObserver(() => {
      setIsOverflown(isOverflowing(el));
    });
    ro.observe(el);

    return () => {
      ro.unobserve(el);
    };
  }, [el]);

  return isOverflown;
}
