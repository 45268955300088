import type { FC } from 'react';
import { NonExpandableChange } from '../NonExpandableChange';
import { findCoverage } from './common';
import type { ReferenceIdEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';

interface CoverageRemovedProps {
    readonly baseCoveragesEdition: CoveragesEdition;
    readonly change: ReferenceIdEntry;
}

export const CoverageRemoved: FC<CoverageRemovedProps> = ({ baseCoveragesEdition, change }) => {
    const removedCoverage = findCoverage(baseCoveragesEdition, change.metadata.referenceId);

    return <NonExpandableChange title={`Coverage removed - ${removedCoverage.name}`} />;
};
