import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { HeaderContext } from './Context';
import { Context } from './Context';

export function useHeaderContext(): HeaderContext {
    const [context, setContext] = useState<Pick<HeaderContext, 'height' | 'portal'>>({ height: 0 });

    const setHeight = useCallback((height: number) => {
        setContext(ctx => (height === ctx.height ? ctx : { ...ctx, height }));
    }, []);

    const setPortal = useCallback((portal: HTMLDivElement) => {
        setContext(ctx => (portal === ctx.portal ? ctx : { ...ctx, portal }));
    }, []);

    return useMemo(() => ({ ...context, setHeight, setPortal }), [context, setHeight, setPortal]);
}

export function useHeader(): HeaderContext {
    const ctx = useContext(Context);

    if (ctx == null) {
        throw new Error('useHeader can only be used within a HeaderProvider.');
    }

    return ctx;
}

export function useHeaderHeight(): HeaderContext['height'] {
    const ctx = useContext(Context);

    if (ctx == null) {
        throw new Error('useHeaderHeight can only be used within a HeaderProvider.');
    }

    return ctx.height;
}

export function useObservedHeight(element: HTMLDivElement | null): number {
    const [height, setHeight] = useState(0);

    const observer = useMemo(
        () =>
            new ResizeObserver(([entry]) => {
                setHeight(Math.ceil(entry.contentRect.height));
            }),
        []
    );

    useEffect(() => {
        if (element == null) return;

        observer.observe(element);
        return () => observer.unobserve(element);
    }, [element, observer]);

    return height;
}
