import { Button, ComboBox, Flex, spacing, Spinner, Text } from '@lemonade-hq/blender-ui';
import { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import { useMemo, useState } from 'react';
import type { FC } from 'react';
import { AttachmentDialogBase } from 'components/Attachments/AttachmentHub/Dialogs/AttachmentDialogBase';
import type { AttachmentDTO } from 'components/Attachments/types';
import { getAttachmentAnalyticsParam } from 'components/Attachments/utils';
import { ClaimItemType } from 'models/HomeClaim';
import { useGetClaimItems, useUpdateAttachmentV2 } from 'queries/HomeClaimQueries';

// since the old update attachment endpoint is used, we need to send to it the old monolith attachment id
// The old update endpoint supports only single attachment and not bulk update
export const AssignAttachmentDialog: FC<{
    readonly onClose: () => void;
    readonly attachments: AttachmentDTO[];
    readonly entityPublicId: string;
}> = ({ onClose, attachments, entityPublicId: claimPublicId }) => {
    const { data, isPending, isError: isClaimItemsError } = useGetClaimItems(claimPublicId);
    const {
        mutateAsync: updateAttachment,
        isPending: isSubmitPending,
        isError: isSubmitError,
    } = useUpdateAttachmentV2(claimPublicId);
    // TODO: set here the assigned items from the attachment
    const [selectedClaimItemIds, setSelectedClaimItemIds] = useState<string[]>([]);

    // TODO: the update endpoint supports only single attachment and not bulk update
    const attachment = attachments[0];

    const lossFeature = useMemo(() => data?.table.find(feature => feature.type === ClaimItemType.Loss), [data?.table]);

    const claimItemsOptions = useMemo(
        () =>
            lossFeature?.items.map(item => ({
                label: item.title,
                value: item.publicId,
            })),
        [lossFeature?.items]
    );

    const params = getAttachmentAnalyticsParam({
        attachment: attachments[0],
        entityType: EntityTypes.HomeClaim,
        entityId: claimPublicId,
    });

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.gallery.clicked', {
            ...(attachments.length === 1 && { ...params }),
            name: 'assign',
            source: 'button',
            ...(attachments.length > 1 && { is_bulk: 'true' }),
        });

        const attachmentData = {
            type: attachment.type ?? '',
            claimItemIds: selectedClaimItemIds,
        };

        await updateAttachment({ attachmentId: attachment.legacyId ?? '', details: attachmentData });
        onClose();
    };

    const onChange = (item: { value: string; label: string } | null): void => {
        if (item == null) return;

        setSelectedClaimItemIds(prev => [...prev, item.value]);
    };

    return (
        <AttachmentDialogBase
            attachments={[attachment]}
            error={
                isClaimItemsError
                    ? 'Error loading claim items'
                    : isSubmitError
                      ? 'Error assigning attachment'
                      : undefined
            }
            isLoading={isPending || isSubmitPending}
            isSubmitDisabled={selectedClaimItemIds.length === 0}
            onClose={onClose}
            onSubmit={onSubmit}
            submitLabel="Assign"
            title="Assign attachments"
        >
            <Flex flexDirection="column" gap={spacing.s06}>
                <Text color="primary" type="label-sm">
                    Assign to
                    <Text as="span" color="error">
                        &nbsp;*
                    </Text>
                </Text>
                {claimItemsOptions != null && claimItemsOptions.length > 0 ? (
                    <ComboBox items={claimItemsOptions} onSelectionChange={onChange} />
                ) : (
                    <Text>No loss items to assign</Text>
                )}
            </Flex>
        </AttachmentDialogBase>
    );
};

// A form to show on Attachment Preview component
export const PreviewAssignAttachment: FC<{
    readonly attachments: AttachmentDTO[];
    readonly entityPublicId: string;
}> = ({ attachments, entityPublicId: claimPublicId }) => {
    const { data, isPending, isError: isClaimItemsError } = useGetClaimItems(claimPublicId);
    const {
        mutateAsync: updateAttachment,
        isPending: isSubmitPending,
        isError: isSubmitError,
    } = useUpdateAttachmentV2(claimPublicId);
    // TODO: set here the assigned items from the attachment
    const [selectedClaimItemIds, setSelectedClaimItemIds] = useState<string[]>([]);

    // TODO: the update endpoint supports only single attachment and not bulk update
    const attachment = attachments[0];

    const lossFeature = useMemo(() => data?.table.find(feature => feature.type === ClaimItemType.Loss), [data?.table]);

    const claimItemsOptions = useMemo(
        () =>
            lossFeature?.items.map(item => ({
                label: item.title,
                value: item.publicId,
            })),
        [lossFeature?.items]
    );

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.gallery.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment: attachments[0],
                entityType: EntityTypes.HomeClaim,
                entityId: claimPublicId,
            }),
            name: 'assign',
            source: 'preview',
        });
        const attachmentData = {
            type: attachment.type ?? '',
            claimItemIds: selectedClaimItemIds,
        };
        await updateAttachment({ attachmentId: attachment.legacyId ?? '', details: attachmentData });
    };

    const onChange = (item: { value: string; label: string } | null): void => {
        if (item == null) return;

        setSelectedClaimItemIds(prev => [...prev, item.value]);
    };

    const error = isClaimItemsError
        ? 'Error loading claim items'
        : isSubmitError
          ? 'Error assigning attachment'
          : undefined;

    return (
        <Flex flexDirection="column" gap={spacing.s06}>
            <Text color="primary" type="label-sm">
                Assign to
                <Text as="span" color="error">
                    &nbsp;*
                </Text>
            </Text>
            {claimItemsOptions != null && claimItemsOptions.length > 0 ? (
                <ComboBox
                    disabled={isPending || isSubmitPending}
                    items={claimItemsOptions}
                    onSelectionChange={onChange}
                />
            ) : isPending ? (
                <Flex>
                    <Spinner size="sm" />
                </Flex>
            ) : (
                <Text>No loss items to assign</Text>
            )}
            {error && (
                <Text color="error" type="label-sm">
                    {error}
                </Text>
            )}
            <Button
                disabled={selectedClaimItemIds.length === 0}
                label="Assign"
                loading={isSubmitPending}
                onClick={onSubmit}
                size="sm"
                variant="primary"
            />
        </Flex>
    );
};
