import type { Status } from '@lemonade-hq/blender-ui';
import {
    Accordion,
    Button,
    Flex,
    IconButton,
    spacing,
    SummarySection,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@lemonade-hq/blender-ui';
import { Alert, AlertMode, EmptySection } from '@lemonade-hq/bluis';
import { capitalize } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { DialogType } from '../../common/components/ABTestDialogs/utils';
import { ABTestDialogs } from './ABTestDialogs';
import {
    CoveragesGeneralDetailsSection,
    NoInstanceGeneralDetailsSection,
    SettingsGeneralDetailsSection,
} from './GeneralDetailsSection';
import * as styles from './rulesTable.css';
import {
    ruleExpressionDisplay,
    RulesActionMenu,
} from 'components/LoCo/common/components/CoverageRules/CoverageRulesShared';
import { useOrderableTable } from 'components/LoCo/common/components/Table/useOrderableTable';
import { formatLifeCycleContext } from 'components/LoCo/common/display-texts/common';
import type { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRuleDisplay, CoverageRuleType, RuleEntityType } from 'models/LoCo/Insurance/CoverageRule';
import type { CoverageInstance, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import { isSettingsInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import {
    AlertLevel,
    CoverageRuleGroupType,
    CoverageRuleGroupTypeMap,
    isCoverageRuleGroupABTest,
} from 'models/LoCo/Insurance/DigitalAgentEdition';
import type { CoverageRulesGroup, DigitalAgentPreviewEntity } from 'models/LoCo/Insurance/DigitalAgentEdition';
import { useReorderDigitalAgentEditionRules } from 'queries/LoCo/Insurance/DigitalAgentEditionQueries';

type HandleReorder = ReturnType<typeof useOrderableTable>['handleReorder'];

interface OrderCellProps {
    readonly index: number;
    readonly isReorderMode: boolean;
    readonly handleReorder: HandleReorder;
    readonly rulePublicId: string;
    readonly lastIndex: number;
    readonly isLoading?: boolean;
}

const OrderCell: FC<OrderCellProps> = ({ index, isReorderMode, handleReorder, rulePublicId, lastIndex, isLoading }) => {
    return (
        <Flex alignItems="center" gap={spacing.s08} justifyContent="space-between" paddingInlineEnd={'16px'}>
            <Text>{index + 1}</Text>
            {isReorderMode && (
                <Flex flexDirection="column" gap={spacing.s04}>
                    <IconButton
                        disabled={index === 0 || isLoading}
                        icon="chevron-up"
                        onClick={async () => await handleReorder(rulePublicId, 'Up', index)}
                        size="sm"
                        variant="secondary"
                    />
                    <IconButton
                        disabled={index === lastIndex || isLoading}
                        icon="chevron-down"
                        onClick={async () => await handleReorder(rulePublicId, 'Down', index)}
                        size="sm"
                        variant="secondary"
                    />
                </Flex>
            )}
        </Flex>
    );
};

interface RulesTableProps {
    readonly filedRules: CoverageRuleDisplay[];
    readonly nonFiledRules: CoverageRuleDisplay[];

    readonly handleReorder: HandleReorder;
    readonly isReorderMode: boolean;
    readonly readonly: boolean;
    readonly editionCode: string;
    readonly groupedRulesType: CoverageRuleGroupType;
    readonly isLoadingRulesReorder?: boolean;
    readonly variantName?: string;
}

const RulesTable: FC<RulesTableProps> = ({
    filedRules,
    nonFiledRules,
    handleReorder,
    isReorderMode,
    readonly,
    editionCode,
    groupedRulesType,
    isLoadingRulesReorder,
    variantName,
}) => {
    const showContext = groupedRulesType === CoverageRuleGroupType.Restriction;

    return (
        <div
            className={styles.wrapper({
                variant: showContext ? 'withContext' : undefined,
            })}
        >
            <div className={styles.headerRow}>
                <Text color="tertiary">#</Text>
                {showContext && <Text color="tertiary">CONTEXT</Text>}
                <Text color="tertiary">SEGMENT</Text>
                <Text color="tertiary">OUTCOME</Text>
            </div>
            <div className={styles.rules}>
                {filedRules.map(rule => (
                    <div className={styles.row} key={rule.publicId}>
                        <Text color="tertiary">Filed</Text>
                        <Text color="tertiary">{ruleExpressionDisplay(rule.expression, true)}</Text>
                        <Text color="tertiary">{capitalize(rule.outcomeDisplay)}</Text>
                    </div>
                ))}
                {nonFiledRules.map((rule, index) => (
                    <div className={styles.row} key={rule.publicId}>
                        <OrderCell
                            handleReorder={handleReorder}
                            index={index}
                            isLoading={isLoadingRulesReorder}
                            isReorderMode={isReorderMode}
                            lastIndex={nonFiledRules.length - 1}
                            rulePublicId={rule.publicId}
                        />
                        {showContext && <Text>{rule.lifecycleContexts.map(formatLifeCycleContext).join(', ')}</Text>}
                        <Text>{ruleExpressionDisplay(rule.expression, true)}</Text>
                        <Text>{capitalize(rule.outcomeDisplay)}</Text>
                        {!readonly && (
                            <RulesActionMenu
                                editionCode={editionCode}
                                editionType={EditionType.DigitalAgent}
                                rule={rule}
                                variantName={variantName}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const EmptyRulesTable: FC<{
    readonly readonly: boolean;
    readonly addRule: () => void;
}> = ({ readonly, addRule }) => {
    return (
        <>
            {!readonly && (
                <Flex justifyContent="flex-end">
                    <Button label="Add rule" onClick={addRule} variant="secondary" />
                </Flex>
            )}
            <EmptySection>No rules configured.</EmptySection>
        </>
    );
};

function alertLevelToVariant(level: AlertLevel): Status {
    // eslint-disable-next-line default-case
    switch (level) {
        case AlertLevel.Warning:
            return 'negative';
        case AlertLevel.Info:
            return 'info';
    }
}

interface RulesCardProps {
    readonly editionCode: string;
    readonly readonly: boolean;
    readonly addRule: (variantName?: string) => void;
    readonly groupedRulesType: CoverageRuleGroupType;
    readonly rulesGroup: CoverageRulesGroup;
    readonly type: RuleEntityType;
    readonly entityCode: string;
}

const RulesCard: FC<RulesCardProps> = ({
    editionCode,
    readonly,
    addRule,
    groupedRulesType,
    rulesGroup,
    type,
    entityCode,
}) => {
    const { alerts, groupDisplayName } = rulesGroup;

    const sortedVariants = useMemo(() => {
        if (!isCoverageRuleGroupABTest(rulesGroup)) {
            return undefined;
        }

        return rulesGroup.variants.sort((a, _) => (a.variantType === 'control' ? -1 : 1));
    }, [rulesGroup]);

    const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);

    const variantName = sortedVariants?.[selectedVariantIndex].variantName;
    const rules = useMemo(() => {
        if (!isCoverageRuleGroupABTest(rulesGroup)) {
            return rulesGroup.rules;
        }

        return sortedVariants?.[selectedVariantIndex].rules ?? [];
    }, [rulesGroup, selectedVariantIndex, sortedVariants]);

    const [dialogType, setDialogType] = useState<DialogType>();

    const { mutateAsync: reorderRule } = useReorderDigitalAgentEditionRules(editionCode);

    const items = useMemo(() => rules.map(rule => rule.publicId), [rules]);

    const onReorder = useCallback(
        async (item: string, order: number) => {
            await reorderRule({
                publicId: item,
                destination: order,
                variant: variantName,
            });
        },
        [reorderRule, variantName]
    );
    const { reorderButton, handleReorder, isReorderMode } = useOrderableTable(items, onReorder);

    const filedRules = rules.filter(rule => rule.isFiled);
    const nonFiledRules = rules.filter(rule => !rule.isFiled);

    const badges = useMemo(() => {
        const experimentName = isCoverageRuleGroupABTest(rulesGroup) ? rulesGroup.experimentName : undefined;
        const test =
            experimentName != null
                ? [
                      {
                          label: 'A/B Test',
                          tooltipProps: { content: `Experiment name: ${experimentName}` },
                          variant: 'info' as Status,
                          stroke: true,
                      },
                  ]
                : [];
        const alertItems = alerts.map(alert => ({
            label: alert.title,
            variant: alertLevelToVariant(alert.level),
            stroke: true,
            tooltipProps: { content: alert.message },
        }));

        return [...test, ...alertItems];
    }, [alerts, rulesGroup]);

    const groupActions = useMemo(() => {
        if (!readonly) {
            if (isCoverageRuleGroupABTest(rulesGroup)) {
                const actions = [{ label: 'Add test groups', onClick: () => setDialogType(DialogType.AddTestGroups) }];

                if (sortedVariants != null && sortedVariants.length > 2) {
                    actions.push({
                        label: 'Remove test groups',
                        onClick: () => setDialogType(DialogType.RemoveTestGroups),
                    });
                }

                actions.push({
                    label: 'Edit test identifiers',
                    onClick: () => setDialogType(DialogType.RenameVariants),
                });
                actions.push({ label: 'Remove A/B test', onClick: () => setDialogType(DialogType.RemoveABTest) });

                return actions;
            } else {
                return [{ label: 'Add A/B Test', onClick: () => setDialogType(DialogType.AddABTest) }];
            }
        }
    }, [readonly, rulesGroup, sortedVariants]);

    return (
        <>
            <SummarySection actions={groupActions} badges={badges} title={groupDisplayName}>
                <Flex flexDirection="column" gap={spacing.s08} width="100%">
                    {isCoverageRuleGroupABTest(rulesGroup) && !readonly && (
                        <Alert
                            mode={AlertMode.Info}
                            title="Adding, editing, removing, or reordering rules must be done separately for each variant"
                        />
                    )}
                    <Flex flexDirection="column" gap={spacing.s08} style={{ position: 'relative' }} width="100%">
                        {isCoverageRuleGroupABTest(rulesGroup) ? (
                            <Tabs variant="inline">
                                <TabList>
                                    {sortedVariants?.map((variant, index) => (
                                        <Tab
                                            key={variant.variantName}
                                            onClick={() => {
                                                setSelectedVariantIndex(index);
                                            }}
                                        >
                                            {variant.variantName}
                                        </Tab>
                                    ))}
                                </TabList>
                                <TabPanels>
                                    {sortedVariants?.map(variant => (
                                        <TabPanel key={variant.variantName}>
                                            {rules.length === 0 ? (
                                                <Flex mt={spacing.s08}>
                                                    <EmptySection>No rules configured.</EmptySection>
                                                </Flex>
                                            ) : (
                                                <RulesTable
                                                    editionCode={editionCode}
                                                    filedRules={filedRules}
                                                    groupedRulesType={groupedRulesType}
                                                    handleReorder={handleReorder}
                                                    isReorderMode={isReorderMode}
                                                    nonFiledRules={nonFiledRules}
                                                    readonly={readonly}
                                                    variantName={variant.variantName}
                                                />
                                            )}
                                        </TabPanel>
                                    ))}
                                </TabPanels>
                            </Tabs>
                        ) : rules.length === 0 ? (
                            <EmptyRulesTable addRule={() => addRule()} readonly={readonly} />
                        ) : (
                            <RulesTable
                                editionCode={editionCode}
                                filedRules={filedRules}
                                groupedRulesType={groupedRulesType}
                                handleReorder={handleReorder}
                                isReorderMode={isReorderMode}
                                nonFiledRules={nonFiledRules}
                                readonly={readonly}
                            />
                        )}

                        {!readonly && (
                            <Flex gap={spacing.s12} style={{ position: 'absolute', right: 0 }}>
                                {nonFiledRules.length > 1 && reorderButton}
                                <Button label="Add rule" onClick={() => addRule(variantName)} variant="secondary" />
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </SummarySection>
            {dialogType != null && (
                <ABTestDialogs
                    dialogType={dialogType}
                    editionCode={editionCode}
                    entityCode={entityCode}
                    groupedRulesType={groupedRulesType}
                    onClose={() => setDialogType(undefined)}
                    rulesGroup={rulesGroup}
                    type={type}
                />
            )}
        </>
    );
};

interface PreviewEntityAccordionProps {
    readonly previewEntity: DigitalAgentPreviewEntity<CoverageInstance | SettingInstance>;
    readonly editionCode: string;
    readonly readonly: boolean;
    readonly type: RuleEntityType;

    readonly addRule: (
        entity: { readonly type: RuleEntityType; readonly code: string },
        lifecycle?: RuleLifecycleContext,
        ruleType?: CoverageRuleType,
        variantName?: string
    ) => void;
}

export const PreviewEntityAccordion: React.FC<PreviewEntityAccordionProps> = ({
    previewEntity,
    editionCode,
    readonly,
    type,
    addRule,
}) => {
    const { instance, name, alerts, code } = previewEntity;

    const badges = useMemo(() => {
        const experiments = Object.values(previewEntity.groupsByType)
            .filter(isCoverageRuleGroupABTest)
            .map(group => group.experimentName);

        const badgesToReturn = alerts.map(alert => ({
            label: alert.title,
            variant: alertLevelToVariant(alert.level),
            stroke: true,
            tooltip: alert.message,
        }));

        if (experiments.length > 0) {
            badgesToReturn.push({
                label: 'A/B Tests',
                variant: 'info',
                tooltip: experiments.map(exp => `Experiment Name: ${exp}`).join('\n'),
                stroke: true,
            });
        }

        return badgesToReturn;
    }, [alerts, previewEntity.groupsByType]);

    const required = instance != null && !isSettingsInstance(instance) && instance.required;

    return (
        <Accordion badges={badges} hasAsteriskMark={required} title={name}>
            <Flex flexDirection="column" gap={spacing.s08} padding="1.2rem" width="100%">
                {instance == null ? (
                    <NoInstanceGeneralDetailsSection description={previewEntity.description} />
                ) : isSettingsInstance(instance) ? (
                    <SettingsGeneralDetailsSection instance={instance} />
                ) : (
                    <CoveragesGeneralDetailsSection instance={instance} />
                )}

                {(Object.entries(previewEntity.groupsByType) as [CoverageRuleGroupType, CoverageRulesGroup][]).map(
                    ([ruleKey, rulesGroup]) => (
                        <RulesCard
                            addRule={variantName => {
                                const { lifecycleContext, ruleType } = CoverageRuleGroupTypeMap[ruleKey];
                                const entity = {
                                    type,
                                    code: previewEntity.code,
                                };
                                addRule(entity, lifecycleContext, ruleType, variantName);
                            }}
                            editionCode={editionCode}
                            entityCode={code}
                            groupedRulesType={ruleKey}
                            key={ruleKey}
                            readonly={readonly}
                            rulesGroup={rulesGroup}
                            type={type}
                        />
                    )
                )}
            </Flex>
        </Accordion>
    );
};
