import type { DialogProps, SelectOption } from '@lemonade-hq/bluis';
import { Dialog, FormInputWrapper, Multiselect, Select } from '@lemonade-hq/bluis';
import type { MultiselectOption } from '@lemonade-hq/cdk';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import type { Currency, Locale } from '@lemonade-hq/lemonation';
import { ALL_LOCALES, ALL_REGIONS, ALL_US_STATES } from '@lemonade-hq/lemonation';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrencyOptions } from '../../../common/helpers/currencyHelpers';
import { StyledInput, StyledTextArea } from '../../SharedTableConfig';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { getProductUrl } from 'components/LoCo/common/urlBuilders';
import type { ProductLine } from 'models/LoCo/Insurance/ProductLine';
import { useCreateProduct } from 'queries/LoCo/Insurance/ProductQueries';

interface AddProductDialogProps {
    readonly onClose: () => void;
    readonly productLines: ProductLine[];
}

enum Region {
    EU = 'EU',
    US = 'US',
}

export const CreateProductDialog: React.FC<React.PropsWithChildren<AddProductDialogProps>> = ({
    productLines,
    onClose,
}) => {
    const { mutateAsync: createProduct, isError, isPending: isLoading } = useCreateProduct();
    const navigate = useNavigate();

    const { errors, values, setValue, valid } = useForm({
        fields: {
            name: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            state: {
                startValue: '',
                validations: {},
            },
            country: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            region: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            productLineCode: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            description: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            currency: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            locales: {
                startValue: [''],
                validations: {
                    required: {
                        errorMessage: '',
                        test: (value: string[]) => value[0]?.length > 0,
                    },
                },
            },
        },
    });

    const onSubmit = async (): Promise<void> => {
        const product = await createProduct({
            ...values,
            state: isEmpty(values.state) ? undefined : values.state,
            name: values.name.trim(),
            description: values.description.trim(),
            currency: values.currency as Currency,
            locales: values.locales as Locale[],
        });

        navigate(getProductUrl(product.code));
    };

    const dialogProps: DialogProps = {
        title: 'Create Product',
        onClose,
        loading: isLoading,
        error: isError ? GENERAL_ERROR_MSG : undefined,
        actions: [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Create',
                type: 'submit',
                onClick: onSubmit,
                disabled: isLoading || isError || !valid,
            },
        ],
        closeOnOutsideClick: true,
    };

    const regionOptions: SelectOption[] = [
        { value: Region.US, id: Region.US, label: Region.US },
        { value: Region.EU, id: Region.EU, label: Region.EU },
    ];

    const localeOptions: MultiselectOption[] = useMemo(() => {
        const isCountrySelected = values.country.length > 0;
        return (
            isCountrySelected ? ALL_LOCALES.filter(locale => locale.split('-')[1] === values.country) : ALL_LOCALES
        ).map(locale => ({
            label: locale,
            id: locale,
        }));
    }, [values.country]);

    const countryOptions: SelectOption[] = useMemo(
        () =>
            ALL_REGIONS.filter(country => values.region === 'US' || country !== 'US').map(country => ({
                value: country,
                id: country,
                label: country,
            })),
        [values.region]
    );

    const stateOptions: SelectOption[] = useMemo(
        () => ALL_US_STATES.map(state => ({ value: state, id: state, label: state })),
        []
    );

    const productLineOptions: SelectOption[] = useMemo(
        () =>
            productLines.map(productLine => ({
                value: productLine.code,
                id: productLine.code,
                label: productLine.name,
            })),
        [productLines]
    );

    const isRegionUS = useMemo(() => (values.region as Region) === Region.US, [values.region]);

    function selectRegion(selectedOption: SelectOption): void {
        setValue('region', selectedOption.value);
        if ((selectedOption.value as Region) === Region.US) {
            setValue('country', 'US');
        } else if ((selectedOption.value as Region) === Region.EU) {
            if (values.country === 'US') {
                setValue('country', '');
            }

            setValue('state', '');
        }
    }

    function selectCountry(selectedOption: SelectOption): void {
        setValue('country', selectedOption.value);
    }

    function selectLocale(selectedOptions: MultiselectOption[]): void {
        setValue(
            'locales',
            selectedOptions.map(option => option.id.toString())
        );
    }

    function selectState(selectedOption: SelectOption): void {
        setValue('state', selectedOption.value);
    }

    function selectProductLine(selectedOption: SelectOption): void {
        setValue('productLineCode', selectedOption.value);
    }

    const countryDisabled = isLoading || isRegionUS;

    return (
        <Dialog {...dialogProps}>
            <FormInputWrapper label="Product line" showErrors={!isEmpty(errors.productLineCode)}>
                <Select
                    disabled={isLoading}
                    onOptionSelected={selectProductLine}
                    options={productLineOptions}
                    placeholder="Select"
                    value={values.productLineCode}
                />
            </FormInputWrapper>
            <FormInputWrapper label="Region" showErrors={!isEmpty(errors.region)}>
                <Select
                    disabled={isLoading}
                    onOptionSelected={selectRegion}
                    options={regionOptions}
                    placeholder="Select"
                    value={values.region}
                />
            </FormInputWrapper>
            <FormInputWrapper label="Country" showErrors={!isEmpty(errors.country)}>
                <Select
                    disabled={countryDisabled}
                    onOptionSelected={selectCountry}
                    options={countryOptions}
                    placeholder="Select"
                    value={values.country}
                />
            </FormInputWrapper>
            <FormInputWrapper label="State" showErrors={!isEmpty(errors.state)}>
                <Select
                    disabled={isLoading || !isRegionUS}
                    onOptionSelected={selectState}
                    options={stateOptions}
                    placeholder="Select"
                    value={values.state}
                />
            </FormInputWrapper>
            <FormInputWrapper label="Currency" showErrors={!isEmpty(errors.currency)}>
                <Select
                    disabled={isLoading}
                    onOptionSelected={option => setValue('currency', option.value)}
                    options={getCurrencyOptions()}
                    placeholder="Select"
                    value={values.currency}
                />
            </FormInputWrapper>
            <FormInputWrapper label="Locales" showErrors={!isEmpty(errors.locales)}>
                <Multiselect
                    options={localeOptions}
                    placeholder="Select"
                    selectedIds={values.locales}
                    setSelectedOptions={selectLocale}
                    title="Locales"
                />
            </FormInputWrapper>
            <FormInputWrapper label="Name" showErrors={!isEmpty(errors.name)}>
                <StyledInput
                    onChange={event => setValue('name', event.target.value)}
                    placeholder="Renters US NY"
                    value={values.name}
                />
            </FormInputWrapper>

            <FormInputWrapper label="Description" showErrors={!isEmpty(errors.description)}>
                <StyledTextArea
                    onChange={event => setValue('description', event.target.value)}
                    value={values.description}
                />
            </FormInputWrapper>
        </Dialog>
    );
};
