import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import type { FlexProps } from '../../base/Flex/Flex';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';
import type { ActionsMenuItem } from '../ActionsMenu/ActionsMenu';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import type { BadgeProps } from '../Badge/Badge';
import { Badge } from '../Badge/Badge';
import type { TooltipProps } from '../Tooltip/Tooltip';
import { Tooltip } from '../Tooltip/Tooltip';
import * as styles from './SummarySection.css';

const SummarySectionTitle = forwardRef<HTMLDivElement, FlexProps>(({ children, ...props }, ref) => {
  return (
    <Flex ref={ref} {...props} className={styles.summarySectionTitle}>
      {children}
    </Flex>
  );
});

export const SummarySection = forwardRef<
  HTMLDivElement,
  {
    readonly title: string;
    readonly badges?: (BadgeProps & { readonly tooltipProps?: Omit<TooltipProps, 'children'> })[];
    readonly actions?: ActionsMenuItem[];
    readonly highlight?: boolean;
    readonly children: ReactNode;
  }
>(({ children, title, badges, actions, highlight = false, ...props }, ref) => {
  return (
    <Flex ref={ref} role="article" {...props} className={styles.summarySection({ highlight })}>
      <SummarySectionTitle>
        <Flex flexDirection="column" gap={spacing.s08} width="100%">
          <Flex justifyContent="space-between">
            <Text type="h5">{title}</Text>
            {actions != null && (
              <Flex px={spacing.s06}>
                <ActionsMenu items={actions} />
              </Flex>
            )}
          </Flex>
          {badges?.map(badge => (
            <Tooltip
              {...badge.tooltipProps}
              content={badge.tooltipProps?.content}
              disabled={badge.tooltipProps == null}
              key={[String(badge.label), badge.variant].join('-')}
            >
              <Flex>
                <Badge {...badge} />
              </Flex>
            </Tooltip>
          ))}
        </Flex>
      </SummarySectionTitle>
      {children}
    </Flex>
  );
});
