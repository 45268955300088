import { Button, Card, Flex, IconButton, LabelValueTable, spacing, Spinner, Text } from '@lemonade-hq/blender-ui';
// eslint-disable-next-line @typescript-eslint/naming-convention
import { LinkComp } from '@lemonade-hq/bluiza';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Dialog from '@radix-ui/react-dialog';
import type { FC, PropsWithChildren } from 'react';
import { Suspense, useState } from 'react';
import * as dialogStyles from './dialog.css';
import * as releaseHistoryStyles from './releaseHistory.css';
import { EditionsAndSchemasLinks } from 'components/LoCo/common/components/EditionsAndSchemasLinks';
import { Paginator } from 'components/LoCo/common/components/Pagination/Paginator';
import { getVersionTypeDisplayName } from 'components/LoCo/common/display-texts/common';
import { getFormattedDate } from 'components/LoCo/common/helpers/dateHelpers';
import { getReleaseUrl } from 'components/LoCo/common/urlBuilders';
import { EditionSetsChangesSummaryContent } from 'components/LoCo/releases/ChangesSummary/EditionSetsChangesSummary';
import type { Release } from 'models/LoCo/Insurance/Release';
import { ReleaseStatus } from 'models/LoCo/Insurance/Release';
import { useSuspenseGetReleases } from 'queries/LoCo/Insurance/ReleasesQueries';

interface ReleasesHistoryProps {
    readonly productCode: string;
    readonly productName: string;
}

interface BUIDialogProps {
    readonly title: string;
    readonly description: string;
}

// This will be exported to a general usage component in a follow up PR
export const BUIDialog: FC<PropsWithChildren<BUIDialogProps>> = ({ children, description, title }) => {
    return (
        <Dialog.Portal>
            <Dialog.Overlay className={dialogStyles.overlay} />
            <Dialog.Content className={dialogStyles.content}>
                <Flex flexDirection="column" padding="2.4rem 2.4rem 0 2.4rem">
                    <Flex justifyContent="space-between">
                        <Dialog.Title asChild className="DialogTitle">
                            <Text type="h4">{title}</Text>
                        </Dialog.Title>
                        <Dialog.Close asChild>
                            <IconButton aria-label="Close" icon="x" variant="inline" />
                        </Dialog.Close>
                    </Flex>
                    <Dialog.Description className="DialogDescription">
                        <Text>{description}</Text>
                    </Dialog.Description>
                </Flex>
                <hr className={dialogStyles.separator} />
                <Flex flex={1} flexDirection="column" overflow="auto" padding={spacing.s24}>
                    {children}
                </Flex>
                <div className={dialogStyles.footer}>
                    <Dialog.Close asChild>
                        <Button label={'Close'} variant={'inline'} />
                    </Dialog.Close>
                </div>
            </Dialog.Content>
        </Dialog.Portal>
    );
};

interface ReleaseHistoryDetailsProps {
    readonly release: Release;
    readonly productCode: string;
    readonly productName: string;
}

const ReleaseHistoryDetails: FC<ReleaseHistoryDetailsProps> = ({ release, productCode, productName }) => {
    assertDate(release.publishedAt, 'Publish date', release.publicId);

    assertNote(release.note, release.publicId);
    const formattedDate = getFormattedDate(release.publishedAt);

    return (
        <Card key={release.publicId}>
            <Flex alignItems="center" justifyContent="space-between" width="100%">
                <Text type="h5">{formattedDate}</Text>
                <Dialog.Root>
                    <Dialog.Trigger asChild>
                        <Button label={'Changes summary'} variant={'secondary'} />
                    </Dialog.Trigger>
                    <BUIDialog description={`${productName} Release`} title={`${formattedDate} Changes summary`}>
                        <Suspense fallback={<Spinner size={'xl'} />}>
                            <EditionSetsChangesSummaryContent release={release} />
                        </Suspense>
                    </BUIDialog>
                </Dialog.Root>
            </Flex>
            <LabelValueTable
                columnCount={1}
                data={[
                    {
                        label: 'Rollout Type',
                        value: getVersionTypeDisplayName(release.rolloutStrategy),
                    },
                    {
                        label: 'Published By',
                        value: release.publishedByName ?? '',
                    },
                    {
                        label: 'Editions in Release',
                        value: <EditionsAndSchemasLinks productCode={productCode} release={release} />,
                    },
                    ...(release.newBusinessEffectiveAt != null
                        ? [
                              {
                                  label: 'Effective (New Business)',
                                  value: getFormattedDate(release.newBusinessEffectiveAt),
                              },
                          ]
                        : []),
                    ...(release.renewalEffectiveAt != null
                        ? [
                              {
                                  label: 'Effective (Renewals)',
                                  value: getFormattedDate(release.renewalEffectiveAt),
                              },
                          ]
                        : []),
                    {
                        label: 'Editions Sets',
                        value: Object.values(release.editionSetVersions)
                            .map(v => `${v.versionMajor}.${v.versionMinor}`)
                            .join(', '),
                    },
                    { label: 'Release Notes', value: release.note },
                    {
                        label: '',
                        value: <LinkComp url={getReleaseUrl(productCode, release.publicId)}>View Release</LinkComp>,
                    },
                ]}
            />
        </Card>
    );
};

export const ReleasesHistory: FC<ReleasesHistoryProps> = ({ productCode, productName }) => {
    const [pageNumber, setPageNumber] = useState(1);

    const { data: releasesData } = useSuspenseGetReleases(productCode, ReleaseStatus.Published, { page: pageNumber });

    return (
        <Card className={releaseHistoryStyles.wrapper}>
            <Flex flexDirection="column" gap={spacing.s16}>
                {releasesData.data.map(release => (
                    <ReleaseHistoryDetails
                        key={release.publicId}
                        productCode={productCode}
                        productName={productName}
                        release={release}
                    />
                ))}
            </Flex>
            <Paginator
                currentPage={pageNumber}
                onPageClick={p => () => setPageNumber(p)}
                totalPages={releasesData.stats.totalPages}
            />
        </Card>
    );
};

function assertDate(date: string | null | undefined, label: string, publicId: string): asserts date {
    if (date === null) {
        throw new Error(`${label} must not be null for a published release (${publicId})`);
    }
}

function assertNote(note: string | undefined, publicId: string): asserts note {
    if (note === undefined) {
        throw new Error(`Note must not be null for a published release (${publicId})`);
    }
}
