import { Select } from '@lemonade-hq/bluis';
import { InputWrapper } from '../../../InputWrapper';
import { useManageRuleDialogContext } from '../../ManageRuleDialogContext';
import { Outcome } from './Outcome';
import { SettingAdditionalOptions } from './SettingAdditionalOptions';
import { RuleOutcomeType, Treatment } from 'models/LoCo/Insurance/CoverageRule';

export const SettingRestriction: React.FC<{ readonly settingName: string }> = ({ settingName }) => {
    const {
        values: { outcome, entityType },
        dispatch,
    } = useManageRuleDialogContext();

    if (entityType == null) throw new Error('Entity type is required');

    return (
        <Outcome coverageOrSettingName={settingName} outcomeTitle={'Restriction'} ruleEntityType={entityType}>
            <InputWrapper label="Restriction Type">
                <Select
                    onOptionSelected={option => {
                        if (option.value === RuleOutcomeType.SettingRangeValueOutcome) {
                            dispatch({
                                type: 'outcome',
                                value: {
                                    type: RuleOutcomeType.SettingRangeValueOutcome,
                                },
                            });
                        } else {
                            dispatch({
                                type: 'outcome',
                                value: {
                                    type: RuleOutcomeType.SettingExactValuesOutcome,
                                    values: [],
                                    treatment: option.value,
                                },
                            });
                        }
                    }}
                    options={
                        [
                            {
                                id: 'require',
                                label: 'Require one of (values)',
                                value: Treatment.Require,
                            },
                            {
                                id: 'forbid',
                                label: 'Forbid any of (values)',
                                value: Treatment.Forbid,
                            },
                            {
                                id: 'range',
                                label: 'Range (min / max)',
                                value: RuleOutcomeType.SettingRangeValueOutcome,
                            },
                        ] as const
                    }
                    value={
                        outcome?.type === RuleOutcomeType.SettingRangeValueOutcome
                            ? outcome.type
                            : outcome?.type === RuleOutcomeType.SettingExactValuesOutcome
                              ? outcome.treatment
                              : null
                    }
                />
            </InputWrapper>
            <SettingAdditionalOptions />
        </Outcome>
    );
};
