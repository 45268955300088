import axios from 'axios';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

export function search(term, options = {}) {
    const url = `/backoffice/search`;
    const body = {
        term,
        ...options,
    };

    return axios.post(url, body).then(response => response.data);
}

export function searchAddress(address, searchFilter, unified = false) {
    const { country, zip, state, street, street_number, city, county } = address;
    const url = `/backoffice/address_search`;

    const params = {
        country_code: country,
        state,
        county,
        street,
        city,
        street_number,
        postal_code: zip,
        type: searchFilter,
        unified,
    };
    const body = pickBy(params, identity);

    return axios.post(url, body).then(response => response.data);
}

export function searchAddressByComponents(addressComponent, searchFilter, unified = false) {
    const {
        componentCountry = {},
        componentPostalCode = {},
        componentState = {},
        componentCounty = {},
        componentStreet = {},
        componentStreetNumber = {},
        componentCity = {},
    } = addressComponent;
    const url = `/backoffice/address_search`;

    const params = {
        country_code: componentCountry.short_name,
        state: componentState.short_name,
        county: componentCounty.short_name,
        street: componentStreet.short_name,
        city: componentCity.long_name,
        street_number: componentStreetNumber.short_name,
        postal_code: componentPostalCode.short_name,
        type: searchFilter,
        unified,
    };
    const body = pickBy(params, identity);

    return axios.post(url, body).then(response => response.data);
}

export function updateAddress(addressComponent) {
    const { id } = addressComponent;
    const url = `/backoffice/addresses/${id}`;
    const body = { ...addressComponent };

    return axios.put(url, body).then(response => response.data);
}
