import filter from 'lodash/filter';
import map from 'lodash/map';
import sum from 'lodash/sum';
import includes from 'lodash/includes';
import * as types from '../actions/actionTypes';

const initialState = {
    claim: false,
    comments: [],
    firstResponse: null,
    notifications: false,
    loadFailed: false,
    isClosed: false,
    openFeaturesCount: false,
    activeItemsCount: false,
    authorizationError: null,
};

export default function claim(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_CLAIM_LOAD_FAILED: {
            return {
                ...state,
                loadFailed: true,
            };
        }
        case types.SET_CLAIM_DATA: {
            const {
                features,
                claim_info: { status = '' },
                item_categories: itemCategories,
                possible_recipients: possibleRecipients,
            } = action.data;

            const openFeaturesCount = filter(features, feature => {
                const isFeatureOpen = feature.status.toLowerCase() === 'open';

                if (!isFeatureOpen) return false;

                const hasActiveItems = feature.claim_items.some(item => item.status.toLowerCase() === 'active');

                return hasActiveItems;
            }).length;

            const activeItemsCount = sum(
                map(
                    features,
                    feature => filter(feature.claim_items, item => item.status.toLowerCase() === 'active').length
                )
            );
            const isClosed = includes(['closed', 'canceled', 'pending'], status.toLowerCase());

            return {
                ...state,
                claim: action.data,
                isClosed,
                activeItemsCount,
                openFeaturesCount,
                itemCategories,
                possibleRecipients,
                loadFailed: false,
            };
        }
        case types.CLAIM_UPDATE_ITEM: {
            const updatedItem = action.data;
            const updatedFeatures = state.claim.features.map(feature => {
                const claim_items = feature.claim_items.map(item => {
                    if (item.id === updatedItem.id) {
                        return updatedItem;
                    }

                    return item;
                });

                return {
                    ...feature,
                    claim_items,
                };
            });

            return {
                ...state,
                claim: {
                    ...state.claim,
                    features: updatedFeatures,
                },
            };
        }
        case types.CLAIM_ADD_PAYEE: {
            const isAlreadyAssigned = includes(state.claim.payees.assigned, { id: action.data.id });
            const newPayees = isAlreadyAssigned
                ? [...state.claim.payees.assigned]
                : [...state.claim.payees.assigned, action.data];

            return {
                ...state,
                claim: {
                    ...state.claim,
                    payees: {
                        ...state.claim.payees,
                        assigned: newPayees,
                    },
                },
            };
        }
        case types.CLAIM_UPDATE_SUBRO: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    subrogation: action.data,
                },
            };
        }
        case types.CLAIM_UPDATE_ATTACHMENT: {
            const { attachments } = state.claim;

            return {
                ...state,
                claim: {
                    ...state.claim,
                    attachments: attachments.map(attachment =>
                        attachment.id === action.data.id ? action.data : attachment
                    ),
                },
            };
        }
        case types.SET_CLAIM_NOTIFICATIONS: {
            const { data: notificationsData } = action;
            const notifications = Object.keys(notificationsData).map(key => notificationsData[key]);

            return {
                ...state,
                notifications,
            };
        }

        case types.SET_CLAIM_AUTHORIZATION_ERROR: {
            return {
                ...state,
                loadFailed: true,
                authorizationError: action.data,
            };
        }

        default:
            return state;
    }
}
