// eslint-disable-next-line @lemonade-hq/base/favor-singular-enums -- breaking change
export enum ProductTypes {
  Home = 'home',
  Pet = 'pet',
  Car = 'car',
  Life = 'life',
  Crypto = 'crypto',
  User = 'user',
  Supervisor = 'supervisor',
}

// eslint-disable-next-line @lemonade-hq/base/favor-singular-enums -- breaking change
export enum EntityTypes {
  WorkflowTask = 'workflow_task',
  StatusDescription = 'status_description',
  CarClaim = 'car_claim',
  CarPolicy = 'car_policy',
  CarQuote = 'car_quote',
  HomeClaim = 'home_claim',
  HomeEuClaim = 'home_eu_claim',
  HomePolicy = 'home_policy',
  HomeQuote = 'home_quote',
  PetClaim = 'pet_claim',
  PetPreapproval = 'pet_preapproval',
  PetPolicy = 'pet_policy',
  UnderwritingReview = 'uw_review',
  User = 'user',
  SiuLogWorkflowTask = 'siu_log_workflow_task',
}

// eslint-disable-next-line @lemonade-hq/base/favor-singular-enums -- breaking change
export enum BundledProductTypes {
  Home = 'home',
  Renters = 'renters',
  Pet = 'pet',
  Car = 'car',
  Life = 'life',
}

export enum CoreEntityType {
  Policy = 'policy',
  Quote = 'quote',
  Claim = 'claim',
  User = 'user',
}

const PET_CLAIM_PREFIX = 'LPC';
const CAR_CLAIM_PREFIX = 'LCC';
const HOME_CLAIM_PREFIX = 'LC';

export function getClaimProductType(claimPublicId: string): ProductTypes | null {
  if (claimPublicId.startsWith(PET_CLAIM_PREFIX)) {
    return ProductTypes.Pet;
  }

  if (claimPublicId.startsWith(CAR_CLAIM_PREFIX)) {
    return ProductTypes.Car;
  }

  if (claimPublicId.startsWith(HOME_CLAIM_PREFIX)) {
    return ProductTypes.Home;
  }

  // eslint-disable-next-line no-console
  console.error(`Unknown product for claim ${claimPublicId}`);

  return null;
}
