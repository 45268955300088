import { errorMessageResolver, MainButton, SecondaryButton } from '@lemonade-hq/bluis';
import classNames from 'classnames';
import React, { cloneElement, useCallback } from 'react';
import styled, { css } from 'styled-components';
import type { LightBoxAction, LightBoxActionType } from '..';
import { LightboxActionsWrapper } from './style';

interface ActionsProps {
    readonly actions: LightBoxAction[];
    readonly embedded?: boolean;
    readonly onClose: () => void;
    readonly error?: JSX.Element | string | null;
    readonly withError: boolean;
    readonly loading?: boolean;
    readonly keepActionsVisibleOnServerError?: boolean;
    readonly onActionError: (error: string) => void;
}

const StyledSecondaryButton = styled(SecondaryButton)<{
    readonly withError: boolean;
    readonly actionType?: LightBoxActionType;
}>`
    opacity: 1;
    ${({ loading, withError, actionType }) =>
        (loading || (withError && actionType === 'close')) &&
        css`
            opacity: 0;
            pointer-events: none;
        `}
`;

const StyledMainButton = styled(MainButton)`
    max-width: 400px;
    transition: all 0.5s ease;
    ${({ loading }) =>
        loading &&
        css`
            pointer-events: none;
            max-width: 50px;
        `}
`;

const Actions: React.FC<React.PropsWithChildren<ActionsProps>> = ({
    actions,
    onClose,
    error,
    embedded,
    withError,
    loading,
    keepActionsVisibleOnServerError,
    onActionError,
}) => {
    const errorHandler = useCallback(
        async (onClick?: () => void) => {
            try {
                onActionError('');
                await onClick?.();
            } catch (error: unknown) {
                const errorMessage = errorMessageResolver(error);

                if (errorMessage == null) throw error;

                onActionError(errorMessage);
            }
        },
        [onActionError]
    );

    const actionsList = actions.map((action, key) => {
        const { className, isDisabled, text, onClick } = action;
        const customClassName = typeof className === 'function' ? className() : className;
        const disabled = typeof isDisabled === 'function' ? isDisabled() : Boolean(isDisabled);
        const actionText = typeof text === 'function' ? text() : text;

        const button =
            action.type === 'submit' && (!withError || keepActionsVisibleOnServerError) ? (
                <StyledMainButton form={action.onClick === undefined ? action.form : undefined} />
            ) : (
                <StyledSecondaryButton withError={withError && !keepActionsVisibleOnServerError} />
            );

        const onClickAction = (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (action.onClick === undefined && action.form) {
                return;
            }

            e.preventDefault();

            if (disabled) {
                return;
            }

            if (typeof onClick === 'function') {
                errorHandler(onClick);
            }

            if (action.type === 'close') {
                onClose();
            }
        };

        const actionClassName = classNames('bo-lightbox-action', {
            disabled,
        });

        const dataType =
            keepActionsVisibleOnServerError && action.type === 'close' ? `visible-${action.type}` : action.type;
        const buttonWithProps = cloneElement(
            button,
            { className: customClassName, disabled, loading, type: action.type, onClick: onClickAction },
            actionText
        );

        return (
            <div className={actionClassName} data-type={dataType} key={key}>
                {buttonWithProps}
            </div>
        );
    });

    const containerClassNames = classNames('bo-lightbox-actions-container-bluis', {
        embedded,
    });

    let errorElement = <span className="bo-lightbox-error" />;

    if (withError) {
        if (typeof error === 'string') {
            errorElement = <span className="bo-lightbox-error">{error}</span>;
        } else {
            errorElement = error!;
        }
    }

    return (
        <LightboxActionsWrapper>
            <div className={containerClassNames}>
                {loading ? <div /> : errorElement}
                <div className="bo-lightbox-actions">{actionsList}</div>
            </div>
        </LightboxActionsWrapper>
    );
};

export default Actions;
