import { Card, spacing } from '@lemonade-hq/blender-ui';
import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import type { FC } from 'react';
import * as styles from '../../../shared/debugLogs.css';

interface CustomerCardDialogProps {
    readonly onClose: () => void;
    readonly content?: string;
}

export const CustomerCardDialog: FC<CustomerCardDialogProps> = ({ onClose, content }) => {
    const actions: DialogAction[] = [
        {
            type: 'close',
            text: 'close',
        },
    ];

    return (
        <Dialog actions={actions} closeOnOutsideClick onClose={onClose} size="large" title="Customer Card">
            <Card className={styles.debugLogContainer} padding={spacing.s12}>
                {content ?? 'Customer card data is not available'}
            </Card>
        </Dialog>
    );
};
