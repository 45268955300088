import { Flex, Table } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import * as styles from './ChangesSummary.css';
import type { CoverageRuleDisplayLog } from './types';
import { formatLifeCycleContext, formatRuleType } from 'components/LoCo/common/display-texts/common';

export const CoveragesRulesTable: FC<{ readonly rules: CoverageRuleDisplayLog[] }> = ({ rules }) => {
    return (
        <Table
            className={styles.table}
            columns={[
                { key: 'type', name: 'type', width: 230 },
                { key: 'context', name: 'context', width: 170 },
                { key: 'segment', name: 'segment' },
                { key: 'outcome', name: 'outcome' },
            ]}
            data={rules.map(rule => ({
                type: { value: `${rule.filed ? 'Filed' : 'Non-Filed'} ${formatRuleType(rule.ruleType)}` },
                context: {
                    value: (
                        <Flex flexDirection="column">
                            {rule.lifecycleContexts.map(lifecycleContext => (
                                <div key={lifecycleContext}>{formatLifeCycleContext(lifecycleContext)}</div>
                            ))}
                        </Flex>
                    ),
                },
                segment: { value: rule.expression !== '' ? rule.expression : 'All' },
                outcome: { value: rule.outcomeDisplay },
            }))}
        />
    );
};
