/* eslint-disable @typescript-eslint/naming-convention */
import type {
  ArrayShape,
  GenericRecordData,
  Infer,
  RecordLikeShape,
  RecordPath,
  Shape,
} from '@lemonade-hq/maschema-schema';
import { findMetConditionalValidationsWithValidations, getShapesFromRecordPath } from '@lemonade-hq/maschema-schema';
import type { IsValidation, OneOfValidation, Validation } from '@lemonade-hq/maschema-validations';
import uniq from 'lodash/uniq';
import type { SelectionMode } from '../../theme/selection';

const OPTIONS_VALIDATIONS = ['oneOf', 'is'] as OptionsValidation['type'][];

type OptionsValidation = IsValidation | OneOfValidation;

function extractRelevantValidations(validations: Validation[] | undefined): OptionsValidation[] {
  return (
    validations?.filter((v): v is OptionsValidation =>
      OPTIONS_VALIDATIONS.includes(v.type as (typeof OPTIONS_VALIDATIONS)[number]),
    ) ?? []
  );
}

function getOptionsValidationsFromShape(shape: Shape, data: GenericRecordData): OptionsValidation[] {
  const conditionalMetValidations =
    findMetConditionalValidationsWithValidations(shape, null, OPTIONS_VALIDATIONS, data)?.validations ?? [];
  const relevantConditionalMetValidations = extractRelevantValidations(conditionalMetValidations);

  // conditional validations always "win", since they most likely narrow the number of options
  if (relevantConditionalMetValidations.length > 0) {
    return relevantConditionalMetValidations;
  }

  return extractRelevantValidations(shape.validations as Validation[]);
}

export function getOptionsFromSchema<TOptionType, TSchema extends RecordLikeShape = RecordLikeShape>(
  schema: TSchema,
  schemaKey: RecordPath<TSchema>,
  dataContext: Partial<Infer<TSchema, { readonly mergeUnions: true }>> = {},
  mode: SelectionMode = 'single',
): TOptionType[] {
  const shapes = getShapesFromRecordPath(schema, schemaKey);
  if (shapes.length === 0) return [];

  let isAndOneOfValidations: OptionsValidation[];
  if (mode === 'single') {
    isAndOneOfValidations = shapes.flatMap(shape => getOptionsValidationsFromShape(shape, dataContext)).filter(Boolean);
  } else {
    if (shapes.some(s => s.type !== 'array')) {
      return [];
    }

    isAndOneOfValidations = (shapes as ArrayShape[])
      .flatMap(shape => getOptionsValidationsFromShape(shape.shape, dataContext))
      .filter(Boolean);
  }

  if (isAndOneOfValidations.length === 0) return [];

  return uniq(
    isAndOneOfValidations.flatMap(validation => (validation.type === 'is' ? validation.value : validation.values)),
  ) as TOptionType[];
}

export function isNotNullable<T>(value: T | null | undefined): value is T {
  return Boolean(value);
}
