import { Button, Card, Flex, spacing, Tab, TabList, TabPanel, TabPanels, Tabs } from '@lemonade-hq/blender-ui';
import { EmptySection, LoadingSection, PageWrapper } from '@lemonade-hq/bluis';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CoveragesEditionPreviewSelector } from './CoveragesEditionPreviewSelector';
import { DigitalAgentEditionDialogs } from './DigitalAgentEditionDialogs';
import type { ActionData } from './DigitalAgentShared';
import { DialogType } from './DigitalAgentShared';
import { PreviewEntityAccordion } from './PreviewEntityAccordion';
import type { HighlightedEntities } from 'components/LoCo/common/components/CoverageRules/Steps/EntitySelection';
import { EditionHeader } from 'components/LoCo/common/editions/EditionHeader';
import { isEditionApproved } from 'components/LoCo/common/editions/editionHelpers';
import type { EditionActionData } from 'components/LoCo/common/editions/editionSharedActions';
import { EditionActionsDialogs } from 'components/LoCo/common/editions/editionSharedActions';
import { EditionSummarySection } from 'components/LoCo/common/editions/EditionSummarySection';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import type { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRuleType } from 'models/LoCo/Insurance/CoverageRule';
import { RuleEntityType } from 'models/LoCo/Insurance/CoverageRule';
import type { CoverageRuleGroupType } from 'models/LoCo/Insurance/DigitalAgentEdition';
import { isCoverageRuleGroupABTest } from 'models/LoCo/Insurance/DigitalAgentEdition';
import {
    useGetDigitalAgentEditionExtendedPreview,
    useSuspenseGetDigitalAgentEdition,
    useUpdateDigitalAgentViewPreference,
} from 'queries/LoCo/Insurance/DigitalAgentEditionQueries';

export const DigitalAgentEdition: React.FC = () => {
    const product = useGetProductData();
    const [dialogData, setDialogData] = useState<EditionActionData | null>(null);
    const [dialog, setDialog] = useState<ActionData | null>(null);

    const { digitalAgentEditionCode = '' } = useParams<{ digitalAgentEditionCode: string }>();
    const { data: digitalAgentEditionData } = useSuspenseGetDigitalAgentEdition(digitalAgentEditionCode);
    const { mutateAsync: updateDigitalAgentViewPreference, isPending } =
        useUpdateDigitalAgentViewPreference(digitalAgentEditionCode);

    const readonly = isEditionApproved(digitalAgentEditionData);

    const { data: digitalAgentEditionExtendedPreview, isLoading: isExtendedPreviewLoading } =
        useGetDigitalAgentEditionExtendedPreview(digitalAgentEditionCode);
    const { settings, coverages } = digitalAgentEditionExtendedPreview ?? {};

    const highlightedEntities: HighlightedEntities = useMemo(() => {
        const coverageEntities: string[] = [];
        const settingEntities: string[] = [];

        digitalAgentEditionExtendedPreview?.coverages.forEach(coverage => {
            if (coverage.instance) {
                coverageEntities.push(coverage.instance.templateCode);
            }
        });

        digitalAgentEditionExtendedPreview?.settings.forEach(setting => {
            if (setting.instance) {
                settingEntities.push(setting.instance.templateCode);
            }
        });

        return {
            coverage: coverageEntities,
            setting: settingEntities,
        };
    }, [digitalAgentEditionExtendedPreview]);

    const addRule = (
        entity: { readonly type: RuleEntityType; readonly code?: string },
        lifecycle?: RuleLifecycleContext,
        ruleType?: CoverageRuleType,
        variantName?: string
    ): void => {
        setDialog({
            type: DialogType.AddRule,
            entity,
            lifecycleContexts: lifecycle ? [lifecycle] : undefined,
            ruleType,
            variantName: variantName,
        });
    };

    const allVariants = useMemo((): Record<string, Record<string, string[]>> => {
        const variantsMap: Record<string, Record<string, string[]>> = {};
        if (digitalAgentEditionExtendedPreview) {
            [...digitalAgentEditionExtendedPreview.coverages, ...digitalAgentEditionExtendedPreview.settings].forEach(
                coverage => {
                    Object.keys(coverage.groupsByType).forEach(key => {
                        const groupKey = key as CoverageRuleGroupType;
                        const group = coverage.groupsByType[groupKey];

                        if (isCoverageRuleGroupABTest(group)) {
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            if (variantsMap[coverage.code] == null) {
                                variantsMap[coverage.code] = {};
                            }

                            variantsMap[coverage.code][groupKey] = group.variants.map(v => v.variantName);
                        }
                    });
                }
            );
        }

        return variantsMap;
    }, [digitalAgentEditionExtendedPreview]);

    return (
        <>
            <EditionHeader
                edition={digitalAgentEditionData}
                product={product}
                setDialogData={setDialogData}
                type={EditionType.DigitalAgent}
            />

            <PageWrapper>
                <EditionSummarySection
                    edition={digitalAgentEditionData}
                    product={product}
                    type={EditionType.DigitalAgent}
                />
                <Card mt={spacing.s24}>
                    <Flex flexDirection="column">
                        <Flex alignItems="center" justifyContent="space-between" mb={spacing.s16}>
                            <CoveragesEditionPreviewSelector
                                digitalAgentEditionCode={digitalAgentEditionCode}
                                isPending={isPending}
                                productCode={product.code}
                                updateDigitalAgentViewPreference={updateDigitalAgentViewPreference}
                            />
                        </Flex>
                        <Tabs variant="inline">
                            <TabList>
                                <Tab key="coverages">Coverages</Tab>
                                <Tab key="settings">Settings</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel style={{ position: 'relative' }}>
                                    {!readonly && (
                                        <Button
                                            label={'Add coverage rule'}
                                            onClick={() =>
                                                setDialog({
                                                    type: DialogType.AddRule,
                                                    entity: {
                                                        type: RuleEntityType.Coverage,
                                                    },
                                                    allVariants,
                                                })
                                            }
                                            style={{ position: 'absolute', right: '0', top: '-50px' }}
                                            variant="secondary"
                                        />
                                    )}
                                    <Flex
                                        flexDirection="column"
                                        gap={spacing.s12}
                                        margin={`${spacing.s16} 0`}
                                        width="100%"
                                    >
                                        {isExtendedPreviewLoading || isPending ? (
                                            <LoadingSection />
                                        ) : coverages == null || coverages.length === 0 ? (
                                            <EmptySection>No Coverages</EmptySection>
                                        ) : (
                                            coverages.map(c => (
                                                <PreviewEntityAccordion
                                                    addRule={addRule}
                                                    editionCode={digitalAgentEditionCode}
                                                    key={c.code}
                                                    previewEntity={c}
                                                    readonly={readonly}
                                                    type={RuleEntityType.Coverage}
                                                />
                                            ))
                                        )}
                                    </Flex>
                                </TabPanel>
                                <TabPanel style={{ position: 'relative' }}>
                                    {!readonly && (
                                        <Button
                                            label={'Add setting rule'}
                                            onClick={() =>
                                                setDialog({
                                                    type: DialogType.AddRule,
                                                    entity: {
                                                        type: RuleEntityType.Setting,
                                                    },
                                                    allVariants,
                                                })
                                            }
                                            style={{ position: 'absolute', right: '0', top: '-50px' }}
                                            variant="secondary"
                                        />
                                    )}
                                    <Flex
                                        flexDirection="column"
                                        gap={spacing.s12}
                                        margin={`${spacing.s16} 0`}
                                        width="100%"
                                    >
                                        {isExtendedPreviewLoading || isPending ? (
                                            <LoadingSection />
                                        ) : settings == null || settings.length === 0 ? (
                                            <EmptySection>No Settings</EmptySection>
                                        ) : (
                                            settings.map(s => (
                                                <PreviewEntityAccordion
                                                    addRule={addRule}
                                                    editionCode={digitalAgentEditionCode}
                                                    key={s.code}
                                                    previewEntity={s}
                                                    readonly={readonly}
                                                    type={RuleEntityType.Setting}
                                                />
                                            ))
                                        )}
                                    </Flex>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Flex>
                </Card>
                {dialogData != null && (
                    <EditionActionsDialogs
                        dialogData={dialogData}
                        onClose={() => setDialogData(null)}
                        productCode={product.code}
                    />
                )}
                {dialog && (
                    <DigitalAgentEditionDialogs
                        dialog={dialog}
                        editionCode={digitalAgentEditionCode}
                        highlightedEntities={highlightedEntities}
                        onClose={() => setDialog(null)}
                    />
                )}
            </PageWrapper>
        </>
    );
};
