// A hook to manage archiving a doc - in dialog, preview or gallery

import type { ComboBoxItem } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useArchiveAttachment, useAttachmentArchiveReasons, useDismissArchiveSuggestion } from '../AttachmentsQueries';
import type { AttachmentDTO } from '../types';

interface ReturnType {
    readonly reasons: ComboBoxItem[];
    readonly reason: string;
    readonly otherReason: string;
    readonly isReasonsLoading: boolean;
    readonly isReasonsError: boolean;
    readonly isArchivePending: boolean;
    readonly isArchiveError: boolean;
    readonly setReason: Dispatch<SetStateAction<string>>;
    readonly setOtherReason: Dispatch<SetStateAction<string>>;
    readonly handleSubmit: () => Promise<void>;
    readonly dismissArchive: ({ attachmentPublicId }: { readonly attachmentPublicId: string }) => Promise<null>;
}

export const useArchive = ({
    entityPublicId,
    entityType,
    attachments,
    onClose,
    archivingReason,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly attachments: AttachmentDTO[];
    readonly onClose?: () => void;
    readonly archivingReason?: string | null;
}): ReturnType => {
    const [reason, setReason] = useState<string>(archivingReason ?? '');
    const [otherReason, setOtherReason] = useState<string>('');

    const { data, isLoading: isReasonsLoading, isError: isReasonsError } = useAttachmentArchiveReasons();
    const {
        mutateAsync: archive,
        isPending: isArchivePending,
        isError: isArchiveError,
    } = useArchiveAttachment({
        entityPublicId,
        entityType,
    });

    const reasons = useMemo(
        () =>
            data?.map(value => ({
                value,
                label: snakeCaseToReadable(value),
            })) ?? [],
        [data]
    );

    const handleSubmit = useCallback(async () => {
        await archive(
            attachments.map(attachment => ({
                reason: reason === 'other' ? otherReason : reason,
                attachmentPublicId: attachment.publicId,
            }))
        );

        onClose?.();
    }, [archive, attachments, onClose, otherReason, reason]);

    const { mutateAsync: dismissArchive } = useDismissArchiveSuggestion({
        entityPublicId: entityPublicId,
        entityType,
    });

    return {
        reasons,
        reason,
        otherReason,
        isReasonsLoading,
        isReasonsError,
        isArchivePending,
        isArchiveError,
        setReason,
        setOtherReason,
        handleSubmit,
        dismissArchive,
    };
};
