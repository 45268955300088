/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import type { RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import type { ReactElement } from 'react';
import type { ActionsMenuItem } from '../../../ActionsMenu/ActionsMenu';
import { ActionsMenu } from '../../../ActionsMenu/ActionsMenu';
import { useForm } from '../../FormContext';
import { useDynamicListContext } from './DynamicListContext';
import { Flex } from 'libs/blender-ui/src/base/Flex/Flex';

interface DynamicListItemActionMenuProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TItem,
> {
  readonly schemaKey: TSchemaKey;
  readonly items: TItem[];
  readonly index: number;
  readonly editable: boolean;
  readonly canAddAbove: boolean;
}

export const DynamicListItemActionMenu = <
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TItem,
>({
  index,
  schemaKey,
  editable,
  canAddAbove,
}: DynamicListItemActionMenuProps<TSchema, TSchemaKey, TItem>): ReactElement => {
  const {
    startEditingItem,
    addItem,
    deleteItem,
    disableRemovingItems,
    disableAddingItem,
    disableEditingItems,
    disableAddingAbove,
  } = useDynamicListContext();

  const { config } = useForm();
  const disableRemovingItem = config.schemaKeysRules?.[`${schemaKey}[${index}]`]?.disableRemovingItem;
  const disableEditingItem = config.schemaKeysRules?.[`${schemaKey}[${index}]`]?.disableEditingItem;
  const items: ActionsMenuItem[] = [];

  if (editable) {
    items.push({
      label: 'Edit',
      id: 'edit',
      onSelect: () => startEditingItem(index),
      disabled: disableEditingItems || disableEditingItem,
    });
  }

  if (canAddAbove) {
    items.push({
      label: 'Add above',
      id: 'add-above',
      onSelect: () => addItem(index),
      disabled: disableAddingItem || disableAddingAbove,
    });
  }

  items.push({
    label: 'Remove',
    disabled: disableRemovingItems || disableRemovingItem,
    id: 'remove',
    onSelect: () => deleteItem(index),
  });

  if (items.every(item => item.disabled)) {
    return <Flex height="24px" width="24px" />;
  }

  return <ActionsMenu items={items} />;
};
