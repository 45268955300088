import { Alert, AlertMode } from '@lemonade-hq/bluis';
import type { FC } from 'react';
import * as styles from './toolEditorAlert.css';

// TODO: Alert should be replaced with the new Zest Banner component
export const UndoDeleteAlert: FC<{ readonly onUndoDelete: () => void }> = ({ onUndoDelete }) => (
    <Alert
        actions={[
            {
                type: 'button',
                buttonType: 'inverse',
                label: 'Undo',
                onClick: onUndoDelete,
            },
        ]}
        className={styles.toolEditorAlert}
        mode={AlertMode.Attention}
        title="This tool will be deleted when you publish your changes"
    />
);
