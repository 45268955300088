import type { EntityTypes as EntityType, MentionUser } from '@lemonade-hq/bluiza';
import type { UseBaseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { blenderGeneral } from '../../../apiClients';
import type { AddNoteBodyArgs, MentionUsersBodyArgs, NoteEntity } from './types';
import { NoteType } from './types';
import { usePessimisticMutation } from 'queries/MutationHooks';

enum NoteQueryKey {
    GetNotes = 'getNotes',
    AddNotes = 'addNotes',
    GetMentions = 'getMentions',
    MentionUsers = 'mentionUsers',
}

const addNote = async ({ content, entities, subject }: AddNoteBodyArgs): Promise<NoteEntity> =>
    await blenderGeneral
        .post<NoteEntity>('/api/v1/notes', {
            type: NoteType.TaskNote,
            entities,
            subject,
            isHtmlContent: true,
            content,
        })
        .then((res: AxiosResponse<NoteEntity>) => res.data);

const mentionUsers = async ({
    mentionedOperatorsPublicIds,
    entityType,
    entityPublicId,
    entityLink,
}: MentionUsersBodyArgs): Promise<void> =>
    await blenderGeneral.post('/api/v1/mentions', {
        entityLink,
        mentionedOperatorsPublicIds,
        entityType,
        entityPublicId,
    });

const getMentionedUsers = async (mentions: string[]): Promise<MentionUser[] | undefined> => {
    return await blenderGeneral
        .get<UseQueryResult<MentionUser[]>>(`api/v1/employees?publicId=${mentions.join(',')}`)
        .then(res => res.data.data);
};

export const useAddNote = (): UseBaseMutationResult<NoteEntity, unknown, AddNoteBodyArgs> => {
    return usePessimisticMutation({
        mutationFn: addNote,
        invalidateKeys: [[NoteQueryKey.GetNotes]],
    });
};

export const useMentionUsers = (): UseBaseMutationResult<void, unknown, MentionUsersBodyArgs> => {
    return usePessimisticMutation({
        mutationFn: mentionUsers,
        invalidateKeys: [[NoteQueryKey.MentionUsers]],
    });
};

export function useGetNotes(
    entityId: string,
    entityType: EntityType
): UseQueryResult<{ readonly notes: NoteEntity[] }> {
    return useQuery({
        queryKey: [NoteQueryKey.GetNotes, entityId],
        queryFn: async () =>
            await blenderGeneral
                .get<
                    UseQueryResult<{ notes: NoteEntity[] }>
                >(`/api/v1/notes?entityPublicId=${entityId}&entityType=${entityType}`)
                .then(res => res.data.data),
    });
}

export function useGetMentionedUsers(mentions: string[]): UseQueryResult<MentionUser[]> {
    return useQuery({
        queryKey: [NoteQueryKey.GetMentions, mentions],
        queryFn: async () =>
            await blenderGeneral
                .get<UseQueryResult<MentionUser[]>>(`/api/v1/employees?publicId=${mentions.join(',')}`)
                .then(res => res.data.data),
    });
}

export const useGetMentionedUsersMutation = () => {
    return usePessimisticMutation({
        mutationFn: getMentionedUsers,
        invalidateKeys: [[NoteQueryKey.GetMentions]],
    });
};
