import { ActionsMenu, ContentSection, EmptySection, MainButton, TableTitle } from '@lemonade-hq/bluis';
import { capitalize } from '@lemonade-hq/ts-helpers';
import { useMemo, useState } from 'react';
import { StyledSectionHeader } from '../../../LoCoPagesSharedStyles';
import { CoverageInstancesDialogs } from './Dialogs/CoverageInstancesDialogs';
import { RuleRow, rulesCountDisplay } from 'components/LoCo/common/components/CoverageRules/CoverageRulesShared';
import { ExpendButton, ExpendedDetailsTable } from 'components/LoCo/common/components/GridTable/ExpandedDetailsTable';
import type { Columns, ColumnsToRow } from 'components/LoCo/common/components/GridTable/GridTable';
import { GridTable } from 'components/LoCo/common/components/GridTable/GridTable';
import type { CoverageActionData } from 'components/LoCo/products/SharedTableConfig';
import { DialogType, menuItems, StyledActionsMenuWrapper } from 'components/LoCo/products/SharedTableConfig';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageInstance, CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';

const coveragesTableColumns = [
    { key: 'name', title: 'Coverage Name', width: '1fr' },
    { key: 'required', title: 'Required', width: '1fr' },
    { key: 'filedRules', title: 'Filed Rules', width: '120px' },
    { key: 'actions', title: '', width: '40px' },
] satisfies Columns;

type Actions = DialogType.AddRule | DialogType.Edit | DialogType.Remove;

const CoveragesInstanceActionMenu: React.FC<{
    readonly coverageInstance: CoverageInstance;
    readonly hideActions: boolean;
    readonly onActionRequested: (dialogType: Actions) => void;
}> = ({ coverageInstance, hideActions, onActionRequested }) => {
    const menu = useMemo(() => {
        const items = [...menuItems];

        if (!coverageInstance.required) {
            items.push({
                label: 'Add Filed Rule',
                value: 'addRule',
            });
        }

        return items;
    }, [coverageInstance.required]);

    return hideActions ? (
        <ExpendButton />
    ) : (
        <StyledActionsMenuWrapper>
            <ExpendButton />
            <ActionsMenu actions={menu} onChange={value => onActionRequested(value as Actions)} type="dots" />
        </StyledActionsMenuWrapper>
    );
};

function getCoverageRow(
    coverageInstance: CoverageInstance,
    hideActions: boolean,
    onActionRequested: (dialogType: Actions) => void
): ColumnsToRow<typeof coveragesTableColumns> {
    return {
        name: { value: coverageInstance.isBenefit ? `${coverageInstance.name} (Benefit)` : coverageInstance.name },
        required: { value: capitalize(coverageInstance.required.toString()) },
        filedRules: { value: rulesCountDisplay(coverageInstance.relatedRules) },
        actions: {
            value: (
                <CoveragesInstanceActionMenu
                    coverageInstance={coverageInstance}
                    hideActions={hideActions}
                    onActionRequested={onActionRequested}
                />
            ),
        },
    };
}
const CoverageExpendedDetails: React.FC<{
    readonly coverageInstance: CoverageInstance;
    readonly editionCode: string;
    readonly hideActions: boolean;
}> = ({ coverageInstance, editionCode, hideActions }) => {
    const relatedSettings =
        coverageInstance.relatedSettings.length > 0
            ? coverageInstance.relatedSettings.map(r => r.name).join(', ')
            : '-';

    return (
        <ExpendedDetailsTable
            rows={[
                [
                    { key: 'title', value: 'DESCRIPTION' },
                    { key: 'value', value: coverageInstance.description },
                ],
                [
                    { key: 'title', value: 'RELATED SETTINGS' },
                    { key: 'value', value: relatedSettings },
                ],
                ...coverageInstance.relatedRules.map(rule => [
                    { key: 'title', value: 'RULE' },
                    {
                        key: 'value',
                        value: (
                            <RuleRow
                                editionCode={editionCode}
                                editionType={EditionType.Coverages}
                                hideActions={hideActions}
                                rule={rule}
                            />
                        ),
                    },
                ]),
            ]}
        />
    );
};

export interface CoverageInstancesTableProps {
    readonly coveragesEdition: CoveragesEdition;
    readonly hideActions: boolean;
}

export const CoverageInstancesTable: React.FC<CoverageInstancesTableProps> = ({ coveragesEdition, hideActions }) => {
    const [dialog, setDialog] = useState<CoverageActionData | null>(null);

    return (
        <ContentSection>
            <StyledSectionHeader>
                <TableTitle title="Coverages" />
                {!hideActions && (
                    <MainButton onClick={() => setDialog({ type: DialogType.Add, data: coveragesEdition.coverages })}>
                        Add
                    </MainButton>
                )}
            </StyledSectionHeader>
            {coveragesEdition.coverages.length === 0 ? (
                <EmptySection>No coverages configured</EmptySection>
            ) : (
                <GridTable
                    columns={coveragesTableColumns}
                    rows={coveragesEdition.coverages
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(coverageInstance => ({
                            values: getCoverageRow(coverageInstance, hideActions, dialogType =>
                                setDialog({
                                    type: dialogType,
                                    data: coverageInstance,
                                })
                            ),
                            expended: (
                                <CoverageExpendedDetails
                                    coverageInstance={coverageInstance}
                                    editionCode={coveragesEdition.code}
                                    hideActions={hideActions}
                                />
                            ),
                        }))}
                />
            )}
            {dialog != null && (
                <CoverageInstancesDialogs
                    dialog={dialog}
                    editionCode={coveragesEdition.code}
                    onClose={() => setDialog(null)}
                />
            )}
        </ContentSection>
    );
};
