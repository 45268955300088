import axios from 'axios';

const LENDERS_PATH = '/backoffice/lenders';

export function getLenders(status) {
    const url = `${LENDERS_PATH}/list/?status=${status || 'verified'}&brief=false`;

    return axios.get(url).then(response => response.data);
}

export function getLender(id) {
    const url = `${LENDERS_PATH}/${id}/data`;

    return axios.get(url).then(response => response.data);
}

export function addLender(data) {
    const { name, clause, isaoa, poBox, address, contactMethod, email, fax_number, phone, website } = data;

    const body = {
        name: name.value,
        clause: clause.value,
        isaoa: isaoa.value,
        po_box: poBox.value,
        address: address.value,
        primary_contact_method: contactMethod.value,
        email: email.value,
        fax_number: fax_number.value,
        phone: phone.value,
        website_url: website.value,
        status: 'pending_verification',
    };

    return axios.post(LENDERS_PATH, body).then(response => response.data);
}

export function editLender(id, data, isVerified) {
    const url = `${LENDERS_PATH}/${id}`;
    const { name, clause, isaoa, poBox, address, contactMethod, email, fax_number, phone, website } = data;

    const body = {
        name: name.value,
        clause: clause.value,
        isaoa: isaoa.value,
        po_box: poBox.value,
        address: address.value,
        primary_contact_method: contactMethod.value,
        email: email.value,
        fax_number: fax_number.value,
        phone: phone.value,
        website_url: website.value,
        status: isVerified ? 'verified' : 'pending_verification',
    };

    return axios.put(url, body).then(response => response.data);
}

export function verifyLender(id) {
    const url = `${LENDERS_PATH}/${id}/verify`;

    return axios.put(url).then(response => response.data);
}

export function addLenderNote(id, note) {
    const url = `${LENDERS_PATH}/${id}/comment`;

    return axios.post(url, note).then(response => response.data);
}

export function getLenderNotes(id) {
    const url = `${LENDERS_PATH}/${id}/comments`;

    return axios.get(url).then(response => response.data);
}

export function deleteLender(id) {
    const url = `${LENDERS_PATH}/${id}`;

    return axios.delete(url).then(response => response.data);
}
