import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';

interface SectionTitleProps {
  readonly title?: string;
  readonly subtitle?: string;
}

export const SectionHeader: React.FC<SectionTitleProps> = ({ title, subtitle }) => {
  if (title == null && subtitle == null) return null;

  return (
    <Flex mb={spacing.s08}>
      <Layout>
        {title != null && <Text type="h4">{title}</Text>}
        {subtitle != null && (
          <Text mt={spacing.s04} type="text-md">
            {subtitle}
          </Text>
        )}
      </Layout>
    </Flex>
  );
};
