import { UseQueryResult, useQueries } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import {
    EntityType,
    InferDataSchemaFromEntityType,
    InferSerializersFromEntityType,
    serializersStaleTime,
} from './config';
import { dataAggClient } from './dataAggClient';
import { SnakeCaseToCamelCase, serializeSerializers, typeSafeSnakeCaseToCamelCase } from './utils';

type DataReturn<TData, TSerializer extends keyof TData> = Partial<
    {
        [K in TSerializer as SnakeCaseToCamelCase<string & K>]: TData[K];
    }
>;

type QueryResultsType<TData, TSerializer extends keyof TData> = Record<
    TSerializer,
    UseQueryResult<Partial<Pick<TData, TSerializer>>, unknown>
>;

export interface UseDataAggQueryReturn<TData, TSerializer extends keyof TData> {
    isFetching: boolean;
    isFetched: boolean | null;
    isError: boolean;
    isLoading: boolean;
    data: DataReturn<TData, TSerializer>;
    refetch: () => Promise<DataReturn<TData, TSerializer>>;
    queryResults: QueryResultsType<TData, TSerializer>;
}

export interface UseDataAggOptions<TData, TSerializer extends keyof TData> {
    enabled?: boolean;
    /**
     * onSuccess will be called once per serializer
     */
    onSuccess?: (data: TData[TSerializer]) => void;
}

export const useDataAggQuery = <
    TEntity extends EntityType,
    TData extends InferDataSchemaFromEntityType<TEntity>,
    TSerializer extends InferSerializersFromEntityType<TEntity>
>(
    entityType: TEntity,
    entityId: string,
    serializers: TSerializer[],
    options: UseDataAggOptions<TData, TSerializer>
): UseDataAggQueryReturn<TData, TSerializer> => {
    // converting the serializers array to a string so we can recalculate the queries
    // only when the serializers change, and not when the reference change
    // (when calling the hook, the serializers array will usually have a new reference: useDataAggQuery(..., [serializer])
    const serializedSerializers = useMemo(() => serializeSerializers(serializers), [serializers]);

    const queries = useMemo(
        () =>
            serializers.map(serializer => ({
                // when invalidating the query, `entityType` is not mandatory
                // react-query will invalidate all that match the first elements of the queryKey
                // here so if by any chance we want to call different data aggregators
                // with the same entity id and serializer, we need to add the entity type to the query key
                queryKey: [serializer, entityId, entityType],
                staleTime: serializersStaleTime[entityType][serializer],
                enabled: options.enabled,
                // The dataAggClient will wait for 10ms before calling the server.
                // This is to aggregate multiple calls across the application into one call,
                queryFn: async () => {
                    const data = await dataAggClient.fetch({ entityType, entityId, serializer });

                    return data[serializer as string];
                },
                onSuccess: options.onSuccess,
            })),
        // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to recalculate the queries only when the hashed serializers change
        [serializedSerializers, entityId, options.enabled, entityType]
    );

    const queriesResults = useQueries({
        queries,
    });

    const refetch = useCallback(async () => {
        const promises = queriesResults.map(async result => result.refetch());
        const responses = await Promise.all(promises);

        return responses.reduce(
            (acc, curr, index) => ({
                ...acc,
                [typeSafeSnakeCaseToCamelCase(serializers[index] as string)]: curr.data,
            }),
            {} as DataReturn<TData, TSerializer>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to recalculate the queries only when the hashed serializers change
    }, [queriesResults, serializedSerializers]);

    // Remapping the useQueries results to an object similar to the useQuery result
    return useMemo(() => {
        return queriesResults.reduce(
            (acc, curr, index) => {
                const currSerializer = typeSafeSnakeCaseToCamelCase(serializers[index] as string);

                return {
                    isFetching: acc.isFetching || curr.isFetching,
                    isFetched: acc.isFetched == null ? curr.isFetched : acc.isFetched && curr.isFetched,
                    isError: acc.isError || curr.isError,
                    isLoading: acc.isLoading || curr.isLoading,
                    data: Object.assign(acc.data, { [currSerializer]: curr.data }),
                    queryResults: Object.assign(acc.queryResults, { [currSerializer]: curr }),
                    refetch,
                };
            },
            ({ data: {}, queryResults: {} } as unknown) as UseDataAggQueryReturn<TData, TSerializer>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to recalculate the queries only when the hashed serializers change
    }, [queriesResults, serializedSerializers, refetch]);
};

export const createUseDataAggQuery = <TEntity extends EntityType>(entityType: TEntity) => <
    TData extends InferDataSchemaFromEntityType<TEntity>,
    TSerializer extends InferSerializersFromEntityType<TEntity>
>(
    entityId: string | null | undefined,
    serializers: TSerializer[],
    options: UseDataAggOptions<TData, TSerializer> = { enabled: true }
) =>
    useDataAggQuery(entityType, entityId ?? '', serializers, {
        ...options,
        enabled: options.enabled && !!entityId,
    });
