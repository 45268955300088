import { Flex, Text } from '@lemonade-hq/blender-ui';
import type { FC, PropsWithChildren } from 'react';
import { inputWrapper, inputWrapperLabel } from './CoverageRules/Steps/Outcomes/outcomes.css';

interface InputWrapperProps {
    readonly label: string;
}

export const InputWrapper: FC<PropsWithChildren<InputWrapperProps>> = ({ children, label }) => {
    return (
        <Flex className={inputWrapper}>
            <Text className={inputWrapperLabel}>{label}</Text>
            {children}
        </Flex>
    );
};
