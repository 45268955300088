import { Icon } from '@lemonade-hq/blender-ui';
import { Avatar } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import { Card } from '../../../shared/components/Card';
import { RecipientTags } from './RecipientTags';
import { mayaAvatarImg } from 'components/Llmnd/shared/components/MessageLayout';

interface AiResponseDraftProps {
    readonly isEdit: boolean;
    readonly contactMethod: string;
}

const Layout = styled(Flex)`
    padding: 8px 8px 8px 0;
`;

const Content = styled(Flex)`
    width: 100%;
`;

export const AiResponseDraft: React.FC<React.PropsWithChildren<AiResponseDraftProps>> = ({
    children,
    isEdit,
    contactMethod,
}) => {
    return (
        <Card isDashed={isEdit} isGlow={!isEdit}>
            <Layout gap="20px">
                <div>
                    <Avatar size="large" src={mayaAvatarImg} />
                </div>
                <Content flexDirection="column" gap="20px">
                    <Flex justifyContent="space-between">
                        <RecipientTags to={contactMethod} />
                        <div>
                            <Icon color="brand1" name="sparkles" size="lg" />
                        </div>
                    </Flex>
                    {children}
                </Content>
            </Layout>
        </Card>
    );
};
