import type { Task } from 'models/Workflows';

// eslint-disable-next-line @lemonade-hq/base/favor-singular-enums
export enum WorkflowsActions {
    SetActiveTask = 'SET_ACTIVE_TASK',
    SetActiveSlug = 'SET_ACTIVE_SLUG',
}

interface SetActiveTaskAction {
    readonly type: WorkflowsActions.SetActiveTask;
    readonly payload: { readonly task: Task | null };
}

interface SetActiveSlugAction {
    readonly type: WorkflowsActions.SetActiveSlug;
    readonly payload: { readonly slug: string };
}

export type WorkflowsActionTypes = SetActiveSlugAction | SetActiveTaskAction;
