import { MentionTag } from '@lemonade-hq/bluis';
import type { MentionElement, MentionUser } from '@lemonade-hq/cdk';
import type { Element } from 'html-react-parser';
import parse from 'html-react-parser';

export const replaceHTMLContentWithMentions = (
  plainHTML: string,
  mentionedUsers: MentionUser[] = [],
): JSX.Element | JSX.Element[] | string =>
  parse(plainHTML, {
    replace: domNode => {
      const node = domNode as Partial<Element>;
      if (node.attribs?.['data-mention-id'] != null) {
        const mentionId = node.attribs['data-mention-id'];
        const mentionedUser = mentionedUsers.find(user => user.publicId === mentionId);
        if (mentionedUser) {
          const { firstName, lastName, attributes, publicId, imageUrl } = mentionedUser;
          const role = attributes != null ? `${attributes.department[0]} | ${attributes.jobTitle[0]}` : null;
          let element: MentionElement = {
            type: 'mention',
            name: `${firstName} ${lastName}`,
            publicId,
            imageUrl,
            children: [],
          };
          if (role != null) {
            element = { ...element, role };
          }

          return (
            <MentionTag attributes={{}} element={element}>
              @{firstName} {lastName}
            </MentionTag>
          );
        }
      }

      return node;
    },
  });
