import {
  BoldButton,
  BulletedListButton,
  CopyIcon,
  EmojiButton,
  ItalicButton,
  LinkButton,
  NumberedListButton,
} from '@lemonade-hq/bluis';
import { Box, Flex, themedColor, useEditorTextLength } from '@lemonade-hq/cdk';
import { NOOP } from '@lemonade-hq/ts-helpers';
import styled from 'styled-components';
import type { MacrosCard, MacrosInitProps } from '../Macros';
import { MacrosWidget } from '../Macros';
import type { ToolbarEditButtons } from './types';

const CharsCounterLabel = styled.span.attrs({ role: 'status' })<{ readonly hasReachedMaxLength: boolean }>`
  margin-left: auto;
  color: ${({ hasReachedMaxLength }) => (hasReachedMaxLength ? themedColor('error') : themedColor('secondaryText'))};
`;

const ToolbarPaper = styled(Flex)<{ readonly hasMacros: boolean }>`
  padding: 8px 16px;
  gap: 3px;
  align-items: center;
`;

const RightSection = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const StyledCopyIcon = styled(CopyIcon)`
  cursor: pointer;

  :active {
    transform: scale(0.9);
  }
`;

export interface ToolbarProps {
  readonly className?: string;
  readonly disabled?: boolean;
  readonly macrosInitProps?: MacrosInitProps;
  readonly onAttachmentTag?: () => void;
  readonly onMacrosError?: (errorMessage: string | undefined) => void;
  readonly onSelectMacro?: (card: MacrosCard) => void;
  readonly maxLength?: number;
  readonly customEndComponent?: React.ReactNode;
  readonly copyToClipboard?: () => void;
  readonly toolbarButtons?: ToolbarEditButtons[];
}

export const Toolbar: React.FC<ToolbarProps> = ({
  disabled,
  className,
  macrosInitProps,
  onMacrosError,
  onAttachmentTag,
  onSelectMacro,
  maxLength,
  customEndComponent,
  copyToClipboard,
  toolbarButtons,
}) => {
  const { textLength, hasReachedMaxLength } = useEditorTextLength({ maxLength });

  const renderRightSection = maxLength != null || customEndComponent != null || copyToClipboard != null;

  const editButtons =
    toolbarButtons != null ? (
      toolbarButtons.map(button => {
        switch (button) {
          case 'bold':
            return <BoldButton disabled={disabled} />;
          case 'italic':
            return <ItalicButton disabled={disabled} />;
          case 'link':
            return <LinkButton disabled={disabled} />;
          case 'numberedList':
            return <NumberedListButton disabled={disabled} />;
          case 'bulletedList':
            return <BulletedListButton disabled={disabled} />;
          case 'emoji':
            return <EmojiButton disabled={disabled} />;
          default:
            return null;
        }
      })
    ) : (
      <>
        <BoldButton disabled={disabled} />
        <ItalicButton disabled={disabled} />
        <LinkButton disabled={disabled} />
        <NumberedListButton disabled={disabled} />
        <BulletedListButton disabled={disabled} />
        <EmojiButton disabled={disabled} />
      </>
    );

  return (
    <ToolbarPaper className={className} hasMacros={macrosInitProps != null} role="toolbar">
      {macrosInitProps && (
        <Box pr="12px">
          <MacrosWidget
            macrosInitProps={macrosInitProps}
            maxSearchResults={10}
            onAttachmentTag={onAttachmentTag}
            onError={onMacrosError ?? NOOP}
            onSelect={onSelectMacro}
            width={200}
          />
        </Box>
      )}
      {editButtons}
      {renderRightSection && (
        <RightSection role="group">
          {copyToClipboard && <StyledCopyIcon onClick={copyToClipboard} title="Copy to clipboard" />}
          {maxLength != null && (
            <CharsCounterLabel hasReachedMaxLength={hasReachedMaxLength}>
              {textLength}/{maxLength}
            </CharsCounterLabel>
          )}
          {customEndComponent}
        </RightSection>
      )}
    </ToolbarPaper>
  );
};
