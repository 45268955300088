export function toReadable(str?: string | null, defaultString = '-', delimiter = ' '): string {
    return capitalize(str ? str.replace(/_/g, delimiter) : defaultString);
}

export function camelCaseToReadable(str?: string | null, defaultString = '-', delimiter = ' '): string {
    return capitalize(str ? str.replace(/([A-Z])/g, `${delimiter}$1`.toLocaleLowerCase()) : defaultString);
}

export function capitalize(str?: string | null): string {
    if (str == null || str.length === 0) return '';

    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function titlize(str: string) {
    const splitStr = str.toLowerCase().split(' ');

    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
}

export function isOnlyAlphabeticalString(str: string) {
    return /^[a-z]+$/i.test(str);
}

export function vowel(str?: string): string {
    const pattern = /^([aeiou])/i;

    if (!str) {
        return '';
    }

    return pattern.test(str) ? 'an' : 'a';
}

export function titlizeStringsToOneTitle(strings: string[]): string {
    return strings.map(str => titlize(str)).join(' ');
}
