import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type { DialogAction, DialogNoticeType } from '@lemonade-hq/bluis';
import { AlertMode, Dialog } from '@lemonade-hq/bluis';
import { Text } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useMemo } from 'react';
import { BulletListSection } from '../SharedStyles';
import { getDecisionTypeText, validateExplanationDraftForPublishing } from '../underwritingUtils';
import { tryExtractingResponseError } from 'components/LoCo/common/helpers/apiHelpers';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type { ExplanationVersion } from 'models/LoCo/Insurance/UnderwritingRegistry';
import {
    usePublishExplanationDraft,
    useSuspenseGetExplanationImpactedResources,
} from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

interface PublishExplanationDraftDialogProps {
    readonly onClose: () => void;
    readonly decisionType: UnderwritingDecisionLifecycleContext;
    readonly explanationVersion: ExplanationVersion;
}

export const PublishExplanationDraftDialog: React.FC<PublishExplanationDraftDialogProps> = ({
    onClose,
    decisionType,
    explanationVersion,
}) => {
    const {
        mutateAsync: publishExplanationDraft,
        isPending: isLoadingPublishExplanationDraft,
        error: errorPublishExplanationDraft,
    } = usePublishExplanationDraft();

    const { data: impactedResources } = useSuspenseGetExplanationImpactedResources(explanationVersion.publicId);

    const publishValidationResult = validateExplanationDraftForPublishing(explanationVersion);

    const onPublishDraft = async (): Promise<void> => {
        await publishExplanationDraft(explanationVersion.publicId);

        onClose();
    };

    const actions: DialogAction[] = [
        {
            text: 'Cancel',
            type: 'close',
            onClick: onClose,
        },
        {
            text: 'Publish',
            type: 'submit',
            onClick: onPublishDraft,
            disabled: isLoadingPublishExplanationDraft || !publishValidationResult.isValid,
        },
    ];

    const errorMessage = isDefined(errorPublishExplanationDraft)
        ? tryExtractingResponseError<{ message: string }>(errorPublishExplanationDraft)?.message
        : undefined;

    const impactedReasons = useMemo<DialogNoticeType | undefined>(() => {
        if (isDefined(impactedResources) && impactedResources.reasonNames.length > 0) {
            return [
                {
                    title: (
                        <BulletListSection
                            align="start"
                            bullets={impactedResources.reasonNames.map(reasonName => `'${reasonName}'`)}
                            title={
                                <div>
                                    <b>Please note:</b>
                                    Publishing this explanation will impact the following decline reasons:
                                </div>
                            }
                        />
                    ),
                    mode: AlertMode.Info,
                },
            ];
        }
    }, [impactedResources]);

    const impactedProducts = useMemo(() => {
        if (isDefined(impactedResources) && impactedResources.productNames.length > 0) {
            return (
                <Flex flexDirection="column">
                    <b>Upon publishing, changes will be visible in production for the following products:</b>
                    <BulletListSection bullets={impactedResources.productNames} />
                </Flex>
            );
        }
    }, [impactedResources]);

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={errorMessage}
            loading={isLoadingPublishExplanationDraft}
            notice={impactedReasons}
            onClose={onClose}
            title={`Publish ${getDecisionTypeText(decisionType)} Explanation`}
        >
            {publishValidationResult.isValid ? (
                <Flex flexDirection="column" gap={spacing.s08}>
                    <Text variant="body-md">Are you sure you want to publish this draft?</Text>
                    {impactedProducts}
                </Flex>
            ) : (
                <BulletListSection
                    bullets={publishValidationResult.errorListItems}
                    content={publishValidationResult.errorMessage}
                />
            )}
        </Dialog>
    );
};
