import { useOptimisticMutation } from '@lemonade-hq/blender-ui';
import { getUrlResolver, ServiceNames } from '@lemonade-hq/bluiza';
import { useQuery } from '@tanstack/react-query';
import type { QueryKey, UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { preCloseFeatureSubrogation } from 'apis/SubrogationAPI';
import { anyAttachmentToAttachment } from 'commons/AttachmentsUtils';
import type { QuestionsInputValues } from 'components/Bluis/Workflows/Question';
import type { LiablePartySubroData } from 'models/LiableParties';
import type { ProductTypes } from 'models/Products';
import type {
    FeatureSubrogation,
    GeneratePdf,
    PreCloseData,
    SubrogationActivity,
    SubrogationLog,
} from 'models/Subrogation';
import { usePessimisticMutation } from 'queries/MutationHooks';

const subrogationWorkflowsUrlResolver = getUrlResolver({
    service: ServiceNames.BlenderGeneral,
    fallbackMap: new Set(),
    basePath: '/api/v1/subrogation_workflows',
});

const featureSubrogationsBaseUrl = getUrlResolver({
    service: ServiceNames.BlenderGeneral,
    fallbackMap: new Set(),
    basePath: '/api/v1/feature_subrogations',
});

const subrogationActivitiesBaseUrl = getUrlResolver({
    service: ServiceNames.BlenderGeneral,
    fallbackMap: new Set(),
    basePath: '/api/v1/subrogation_activities',
});

const featureSubrogationLogsBaseUrl = getUrlResolver({
    service: ServiceNames.BlenderGeneral,
    fallbackMap: new Set(),
    basePath: '/api/v1/feature_subrogation_logs',
});

export enum SubrogationQueriesQueryKey {
    GetSubrogationDetection = 'GET_SUBROGATION_DETECTION',
    GetSubrogationReferral = 'GET_SUBROGATION_REFERRAL',
    GetSubroReviewOutcome = 'GET_SUBRO_REVIEW_OUTCOME',
    GetFeatureSubrogations = 'GET_FEATURE_SUBROGATIONS',
    GetFeatureSubrogationManage = 'GET_FEATURE_SUBROGATION_MANAGE',
    GetFeatureSubrogationLogs = 'GET_FEATURE_SUBROGATION_LOGS',
    PreClose = 'PRE_CLOSE',
    Vendors = 'VENDORS',
    DispatchVendorConfig = 'DISPATCH_VENDOR_CONFIG',
}

export interface SubrogationDetectionAnswers extends QuestionsInputValues {
    readonly anotherLiable?: boolean;
    readonly liableIdentified?: boolean;
}

export interface SubrogationDetectionData {
    readonly publicId: string;
    readonly claimPublicId: string;
    readonly claimProduct: string;
    readonly questionnairesType: string;
    readonly featureSubrogationIds?: string[];
    readonly data: SubrogationDetectionAnswers;
}

export interface SubrogationPostDetectionData {
    readonly publicId: string;
    readonly workflowTaskPublicId: string;
}

export interface SubrogationPatchDetectionData {
    readonly publicId: string;
}

export function usePostDetection({
    publicId,
    workflowTaskPublicId,
}: SubrogationPostDetectionData): UseMutationResult<void, unknown, SubrogationDetectionAnswers, null> {
    return usePessimisticMutation({
        mutationFn: async answers => {
            await axios.post<SubrogationDetectionAnswers>(subrogationWorkflowsUrlResolver(`/detect`), {
                publicId,
                workflowTaskPublicId,
                data: answers,
            });
        },
        invalidateKeys: [],
    });
}

export function usePatchDetection(
    publicId: string
): UseMutationResult<null, unknown, SubrogationDetectionAnswers, SubrogationDetectionData> {
    return useOptimisticMutation({
        mutationFn: async answers => {
            return await axios.patch(subrogationWorkflowsUrlResolver(`/detect`), {
                publicId,
                data: answers,
            });
        },
        mutate: ({ variables, data }) => {
            return {
                ...data,
                data: variables,
            };
        },
        invalidateKeys: [[SubrogationQueriesQueryKey.GetSubrogationDetection, publicId]],
        mutateKey: [[SubrogationQueriesQueryKey.GetSubrogationDetection, publicId]],
    });
}

export function useGetDetection(publicId: string): UseQueryResult<SubrogationDetectionData> {
    return useQuery({
        queryKey: [SubrogationQueriesQueryKey.GetSubrogationDetection, publicId],
        queryFn: async () => {
            const response = await axios.get<{ data: SubrogationDetectionData }>(
                subrogationWorkflowsUrlResolver(`/detect/${publicId}`)
            );

            return response.data.data;
        },
    });
}

export interface SubrogationReferralData {
    readonly claimProduct: string;
    readonly claimPublicId: string;
    readonly featureSubrogationIds: string[];
    readonly liableParties: LiablePartySubroData[];
    readonly questionnairesType: string;
    readonly data: QuestionsInputValues;
}

export function useGetReferral(referenceId: string): UseQueryResult<SubrogationReferralData> {
    return useQuery({
        queryKey: [SubrogationQueriesQueryKey.GetSubrogationReferral, referenceId],
        queryFn: async () => {
            const response = await axios.get<{ data: SubrogationReferralData }>(
                subrogationWorkflowsUrlResolver(`/referral/${referenceId}`)
            );

            return response.data.data;
        },
    });
}

export function usePatchSubroReferral(
    referenceId: string,
    invalidateKeys?: QueryKey[]
): UseMutationResult<null, unknown, QuestionsInputValues, SubrogationReferralData> {
    return useOptimisticMutation({
        mutationFn: async (answers: QuestionsInputValues) => {
            return await axios.patch(subrogationWorkflowsUrlResolver('/referral'), {
                publicId: referenceId,
                data: answers,
            });
        },
        mutate: ({ data, variables }) => {
            return {
                ...data,
                data: {
                    ...data.data,
                    ...variables,
                },
            };
        },
        invalidateKeys: [[SubrogationQueriesQueryKey.GetSubrogationReferral, referenceId], ...(invalidateKeys ?? [])],
        mutateKey: [SubrogationQueriesQueryKey.GetSubrogationReferral, referenceId] as unknown as QueryKey[],
    });
}

export function useSubmitReferral(
    referenceId: string,
    taskId: string
): UseMutationResult<void, unknown, QuestionsInputValues, null> {
    return usePessimisticMutation({
        mutationFn: async answers => {
            await axios.post<QuestionsInputValues>(subrogationWorkflowsUrlResolver(`/referral`), {
                publicId: referenceId,
                data: answers,
                workflowTaskPublicId: taskId,
            });
        },
        invalidateKeys: [[SubrogationQueriesQueryKey.GetSubrogationReferral, referenceId]],
    });
}

/**
 * Review outcome
 */

export interface SubroReviewOutcomeAnswers extends QuestionsInputValues {
    readonly pursueSubrogation?: boolean;
}

export interface SubroReviewOutcomeFeatureSubrogation {
    readonly publicId: string;
    readonly estimatedRecovery?: number | null;
    readonly totalClaimed: number;
    readonly featureType?: string;
}

export interface SubroReviewOutcomeData {
    readonly claimPublicId: string;
    readonly featureSubrogations: SubroReviewOutcomeFeatureSubrogation[];
    readonly liableParties: LiablePartySubroData[];
    readonly closingReason?: string; // (one of notPursuingReasons, and if not is considered as 'other')
    readonly notPursuingReasons: string[];

    readonly data: SubroReviewOutcomeAnswers;
}

export function useGetReviewOutcome(publicId: string): UseQueryResult<SubroReviewOutcomeData> {
    return useQuery({
        queryKey: [SubrogationQueriesQueryKey.GetSubroReviewOutcome, publicId],
        queryFn: async () => {
            const response = await axios.get<{ data: SubroReviewOutcomeData }>(
                subrogationWorkflowsUrlResolver(`/review/${publicId}`)
            );

            return response.data.data;
        },
    });
}

export function usePatchReviewOutcomeSubroReferral(
    referenceId: string
): UseMutationResult<null, unknown, SubroReviewOutcomeAnswers, SubroReviewOutcomeData> {
    return useOptimisticMutation({
        mutationFn: async (answers: SubroReviewOutcomeAnswers) => {
            return await axios.patch(subrogationWorkflowsUrlResolver('/referral'), {
                publicId: referenceId,
                data: answers,
            });
        },
        mutate: ({ data, variables }) => ({
            ...data,
            data: {
                ...data.data,
                ...variables,
            },
        }),
        invalidateKeys: [[SubrogationQueriesQueryKey.GetSubroReviewOutcome, referenceId]],
        mutateKey: [[SubrogationQueriesQueryKey.GetSubroReviewOutcome, referenceId]],
    });
}

interface PatchFeatureSubrogations {
    readonly publicIds: string[];
    readonly closingReason?: string | null;
    readonly estimatedRecovery?: number;
    readonly totalClaimed?: number;
    readonly outcome?: string;
    readonly outcomeAdditionalInfo?: string;
}

export function useGetFeatureSubrogations(claimPublicId: string): UseQueryResult<FeatureSubrogation[]> {
    return useQuery({
        queryKey: [SubrogationQueriesQueryKey.GetFeatureSubrogations, claimPublicId],
        queryFn: async () => {
            const response = await axios.get<{ data: FeatureSubrogation[] }>(featureSubrogationsBaseUrl(''), {
                params: { claimPublicId },
            });

            return response.data.data;
        },
    });
}

export function usePatchFeatureSubrogations(
    publicId: string
): UseMutationResult<null, unknown, PatchFeatureSubrogations, PatchFeatureSubrogations> {
    return useOptimisticMutation({
        mutationFn: async patchData => {
            return await axios.patch(featureSubrogationsBaseUrl(''), patchData);
        },
        mutate: ({ variables, data }) => ({
            ...data,
            closingReason: variables.closingReason,
        }),
        invalidateKeys: [
            [SubrogationQueriesQueryKey.GetSubroReviewOutcome, publicId],
            [SubrogationQueriesQueryKey.GetFeatureSubrogationManage, publicId],
        ],
        mutateKey: [SubrogationQueriesQueryKey.GetSubroReviewOutcome, publicId] as unknown as QueryKey[],
    });
}

export interface SubrogationPostReviewOutcomeData {
    readonly publicId: string;
    readonly workflowTaskPublicId: string;
    readonly claimPublicId: string;
}

export function usePostReviewOutcome({
    publicId,
    workflowTaskPublicId,
    claimPublicId,
}: SubrogationPostReviewOutcomeData): UseMutationResult<void, unknown, unknown, null> {
    return usePessimisticMutation({
        mutationFn: async () => {
            await axios.post(subrogationWorkflowsUrlResolver(`/review`), {
                claimPublicId,
                publicId,
                workflowTaskPublicId,
            });
        },
        invalidateKeys: [],
    });
}

export interface FeatureSubrogationManageResponse {
    readonly featureSubrogations: FeatureSubrogation[];
    readonly total: {
        readonly totalClaimed: number;
        readonly estimatedRecovery: number;
        readonly recoveredFunds: number;
        readonly recoveryRemaining: number;
    };
    readonly appliedDeductible: number;
    readonly reimbursedDeductible?: number;
    readonly activities: SubrogationActivity[];
    readonly tag: string;
    readonly waiveDeductibleReimbursementReason: string | null;
}

async function getFeatureSubrogationManage({
    claimPublicId,
    entityPublicId,
}: {
    readonly claimPublicId: string;
    readonly entityPublicId: string;
}): Promise<FeatureSubrogationManageResponse> {
    return await axios
        .get<{
            data: FeatureSubrogationManageResponse;
        }>(subrogationWorkflowsUrlResolver('/manage'), { params: { claimPublicId, entityPublicId } })
        .then(res => ({
            ...res.data.data,
            activities: res.data.data.activities.map(activity => ({
                ...activity,
                attachments: ((activity.attachments ?? []) as unknown as Record<string, string>[]).map(
                    anyAttachmentToAttachment
                ),
            })),
        }));
}

export function useGetFeatureSubrogationManage(
    claimPublicId: string,
    entityPublicId: string
): UseQueryResult<FeatureSubrogationManageResponse> {
    return useQuery({
        queryKey: [SubrogationQueriesQueryKey.GetFeatureSubrogationManage, entityPublicId],
        queryFn: async () => await getFeatureSubrogationManage({ claimPublicId, entityPublicId }),
    });
}

interface PostFeatureSubrogationManageParams {
    readonly workflowTaskPublicId: string;
    readonly featureSubrogationPublicIds: string[];
    readonly waiveDeductibleReimbursementReason?: string;
}

export interface PostFeatureSubrogationManageResponse {
    readonly payeeId: string;
    readonly itemId: string;
}

export function usePostFeatureSubrogationManage(
    entityPublicId: string
): UseMutationResult<PostFeatureSubrogationManageResponse, unknown, PostFeatureSubrogationManageParams, null> {
    return usePessimisticMutation({
        mutationFn: async params => {
            return await axios
                .post<PostFeatureSubrogationManageResponse>(subrogationWorkflowsUrlResolver('/manage'), params)
                .then(res => res.data);
        },
        invalidateKeys: [[SubrogationQueriesQueryKey.GetFeatureSubrogationManage, entityPublicId]],
    });
}

export function usePreCloseSubrogation({
    featureSubrogationId,
}: {
    readonly featureSubrogationId: string;
}): UseQueryResult<PreCloseData> {
    return useQuery({
        queryKey: [SubrogationQueriesQueryKey.PreClose, featureSubrogationId],
        queryFn: async () => await preCloseFeatureSubrogation({ featureSubrogationId }),
    });
}

interface Add3rdPartyActivityParams {
    readonly entityPublicId: string;
    readonly entityType: string;
    readonly type: string;
    readonly platform: string;
    readonly sendAt?: string;
    readonly files?: {
        readonly url: string;
        readonly type: string;
    }[];
}

export function useAdd3rdPartyActivity(
    entityPublicId: string,
    invalidateOn3rdPartyActivity: QueryKey[]
): UseMutationResult<void, unknown, Add3rdPartyActivityParams, null> {
    return usePessimisticMutation({
        mutationFn: async (activity: Add3rdPartyActivityParams) => {
            await axios.post(subrogationActivitiesBaseUrl(''), activity);
        },
        invalidateKeys: [
            [SubrogationQueriesQueryKey.GetFeatureSubrogationManage, entityPublicId],
            ...invalidateOn3rdPartyActivity,
        ],
    });
}

type GenerateSubroPdfParams =
    | {
          readonly entityPublicId: string;
          readonly files: {
              readonly url: string;
              readonly type: string;
          }[];
      }
    | {
          readonly entityPublicId: string;
          readonly subrogationVendorId: string;
          readonly attachmentPublicIds: string[];
          readonly liablePartiesPublicIds: string[];
      };

export function useGenerateSubroPdf(): UseMutationResult<GeneratePdf, unknown, GenerateSubroPdfParams, null> {
    return usePessimisticMutation({
        mutationFn: async (body: GenerateSubroPdfParams) => {
            return await axios.post(subrogationActivitiesBaseUrl(`/pdf`), body).then(res => res.data.data);
        },
        invalidateKeys: [],
    });
}

interface SendDemandLetterParams {
    readonly entityPublicId: string;
    readonly entityType: string;
    readonly content: string;
    readonly subject: string;
    readonly liablePartyName: string;
    readonly liablePartyEmail: string;
    readonly liablePartyId: string;
    readonly attachmentPublicId: string;
    readonly ccEmails?: string[];
    readonly claimPublicId: string;
}

export function useSendDemandLetter(
    entityPublicId: string,
    invalidateOnSendDemandLetter: QueryKey[]
): UseMutationResult<void, unknown, SendDemandLetterParams, null> {
    return usePessimisticMutation({
        mutationFn: async params => {
            await axios.post(subrogationActivitiesBaseUrl(`/demand_letter`), params);
        },
        invalidateKeys: [
            [SubrogationQueriesQueryKey.GetFeatureSubrogationManage, entityPublicId],
            ...invalidateOnSendDemandLetter,
        ],
    });
}

interface DispatchVendorParams {
    readonly entityPublicId: string;
    readonly entityType: string;
    readonly subrogationVendorId: string;
    readonly subrogationVendorName: string;
    readonly subrogationVendorEmail: string;
    readonly attachmentPublicIds: string[];
    readonly liablePartiesPublicIds: string[];
    readonly subject: string;
    readonly content: string;
}

export function useDispatchVendor(
    entityPublicId: string,
    additionalKeysToInvalidate: QueryKey[]
): UseMutationResult<void, unknown, DispatchVendorParams, null> {
    return usePessimisticMutation({
        mutationFn: async params => {
            await axios.post(subrogationActivitiesBaseUrl(`/dispatch_vendor`), params);
        },
        invalidateKeys: [
            [SubrogationQueriesQueryKey.GetFeatureSubrogationManage, entityPublicId],
            ...additionalKeysToInvalidate,
        ],
    });
}

interface SubrogationVendor {
    readonly name: string;
    readonly email?: string;
    readonly vendorId?: string;
}

export function useVendors(product: ProductTypes, region?: string): UseQueryResult<SubrogationVendor[]> {
    return useQuery({
        queryKey: [SubrogationQueriesQueryKey.Vendors, product],
        queryFn: async () => {
            const response = await axios.get<{ data: SubrogationVendor[] }>(
                subrogationWorkflowsUrlResolver(`/vendors`),
                { params: { product, region } }
            );

            return response.data.data;
        },
    });
}

interface LogActivityParams {
    readonly entityPublicId: string;
    readonly entityType: string;
    readonly type: string;
    readonly platform: string;
    readonly sendAt: string;
    readonly attachmentIds: string[];
    readonly status: string;
    readonly sendTo: string;
}

export function useLogActivity(
    entityPublicId: string,
    activityPublicId: string
): UseMutationResult<void, unknown, LogActivityParams, null> {
    return usePessimisticMutation({
        mutationFn: async activityLog => {
            await axios.put(subrogationActivitiesBaseUrl(`/${activityPublicId}`), activityLog);
        },
        invalidateKeys: [[SubrogationQueriesQueryKey.GetFeatureSubrogationManage, entityPublicId]],
    });
}

interface UpdateActivityParams {
    readonly sendAt: string;
    readonly attachmentIds: string[];
    readonly sendTo: string;
    readonly publicId: string;
}

export function useUpdateActivity(
    entityPublicId: string
): UseMutationResult<void, unknown, UpdateActivityParams, null> {
    return usePessimisticMutation({
        mutationFn: async ({ publicId, ...activityLog }) => {
            await axios.put(subrogationActivitiesBaseUrl(`/${publicId}`), activityLog);
        },
        invalidateKeys: [[SubrogationQueriesQueryKey.GetFeatureSubrogationManage, entityPublicId]],
    });
}

export interface DispatchVendorConfig {
    readonly subject: string;
    readonly content: string;
}

export function useDispatchVendorConfig(claimPublicId: string): UseQueryResult<DispatchVendorConfig> {
    return useQuery({
        queryKey: [SubrogationQueriesQueryKey.DispatchVendorConfig, claimPublicId],
        queryFn: async () => {
            const response = await axios.get<{ data: DispatchVendorConfig }>(
                subrogationWorkflowsUrlResolver(`/dispatch_vendor_config`),
                { params: { claimPublicId } }
            );

            return response.data.data;
        },
    });
}

export function useGetFeatureSubrogationLog(claimPublicId: string): UseQueryResult<SubrogationLog[]> {
    return useQuery({
        queryKey: [SubrogationQueriesQueryKey.GetFeatureSubrogationLogs, claimPublicId],
        queryFn: async () => {
            const response = await axios.get<{ data: FeatureSubrogationManageResponse }>(
                featureSubrogationLogsBaseUrl(''),
                { params: { claimPublicId } }
            );

            return response.data.data;
        },
    });
}
