import { Avatar, Card, CardTypes } from '@lemonade-hq/bluis';
import { replaceHTMLContentWithMentions } from '@lemonade-hq/bluiza';
import { themedColor, useTheme } from '@lemonade-hq/boutique';
import { extractMentionsFromHtml, Flex } from '@lemonade-hq/cdk';
import { DATE_FNS_FORMATS } from '@lemonade-hq/lemonation';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import { format, utcToZonedTime } from 'date-fns-tz';
import { rgba } from 'polished';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import type { Keyframes } from 'styled-components';
import styled, { css, keyframes } from 'styled-components';
import { useGetMentionedUsers } from '../../queries';
import type { NoteEntity } from '../../types';

const fadeOutBorder = (color: string): Keyframes => keyframes`
  0% {
    border-color: ${color};
  }
  50% {
    border-color: ${rgba(color, 0.5)};
  }
  75% {
    border-color: ${rgba(color, 0.25)};
  }
  95% {
    border-color: ${rgba(color, 0.1)};
  }
  100% {
    border-color: transparent;
  }
`;

const StyledCard = styled(Card)<{ readonly hasAnimatedBorder?: boolean; readonly borderColor: string }>`
    padding: 20px 24px;
    margin-top: 12px;
    &:before {
        width: 0;
    }

    ${({ hasAnimatedBorder, borderColor }) =>
        hasAnimatedBorder &&
        css`
            border: 4px solid ${borderColor};
            animation: ${fadeOutBorder(borderColor)} 2s ease-in-out;
        `};
`;
const DateLabel = styled.p`
    color: ${themedColor('disabledText')};
    margin: 0 0 0 auto;
`;

const Name = styled.p`
    margin: 0;
    font-weight: 700;
`;

const ContentContainer = styled(Flex).attrs({ flexDirection: 'column', gap: '16px' })`
    margin-left: 44px;
`;

const NoteContent = styled.div`
    overflow-wrap: break-word;
`;

export interface NoteProps {
    readonly avatarUrl: string;
    readonly adjusterRole: string | undefined;
    readonly content: string;
    readonly name: string;
    readonly note: NoteEntity;
    readonly timestamp: string;
    readonly timezone: string;
    readonly focusOptions?: {
        readonly hasAnimatedBorder?: boolean;
        readonly shouldFocus?: boolean;
    };
    readonly renderFooter?: (note: NoteEntity) => JSX.Element | null;
}

const formatDate = (date: Date, timezone: string): string => {
    const now = new Date();

    const diff = now.getTime() - date.getTime();

    const minutes = Math.floor(diff / 1000 / 60);

    if (minutes < 5) {
        return 'Just now';
    }

    return format(utcToZonedTime(date, timezone), DATE_FNS_FORMATS.iso8601UtcHumanized, {
        timeZone: timezone,
    });
};

export const Note: FC<React.PropsWithChildren<NoteProps>> = ({
    name,
    avatarUrl,
    content,
    note,
    timestamp,
    adjusterRole,
    focusOptions,
    timezone,
    renderFooter,
}) => {
    const noteRef = useRef<HTMLDivElement>(null);
    const [hasAnimatedBorder, setHasAnimatedBorder] = useState(focusOptions?.hasAnimatedBorder ?? false);
    const theme = useTheme();
    const mentionIds = extractMentionsFromHtml(content);
    const { data: mentionedUsers } = useGetMentionedUsers(mentionIds);

    useEffect(() => {
        if (focusOptions?.shouldFocus && noteRef.current != null) {
            noteRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusOptions?.shouldFocus, noteRef.current]);

    useEffect(() => {
        if (focusOptions?.hasAnimatedBorder) {
            setHasAnimatedBorder(true);
            const timeoutId = setTimeout(() => setHasAnimatedBorder(false), 2000);

            return () => {
                clearTimeout(timeoutId);
            };
        }

        return () => {};
    }, [focusOptions?.hasAnimatedBorder]);
    return (
        <StyledCard
            borderColor={theme.colors.attentionBackground}
            hasAnimatedBorder={hasAnimatedBorder}
            ref={noteRef}
            type={CardTypes.Message}
        >
            <Flex alignItems="center" gap="12px" justifyContent="space-between">
                <div data-tooltip={snakeCaseToReadable(adjusterRole ?? '')} data-tooltip-alignment="top-center">
                    <Avatar name={name} size="large" src={avatarUrl} />
                </div>
                <Name>{name}</Name>
                <DateLabel>{formatDate(new Date(timestamp), timezone)}</DateLabel>
            </Flex>
            <ContentContainer>
                <NoteContent>{replaceHTMLContentWithMentions(content, mentionedUsers)}</NoteContent>
                {renderFooter?.(note)}
            </ContentContainer>
        </StyledCard>
    );
};
