import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';

const homeBlenderUrlResolver = getUrlResolver('home-blender');

export interface ScheduledItemsRejectedReasonsDTO {
    readonly value: string;
    readonly label: string;
}

export async function getValuableItemsRejectionReasons(): Promise<ScheduledItemsRejectedReasonsDTO[]> {
    const url = homeBlenderUrlResolver('/api/v1/scheduled_items/rejection_reasons');

    return axios.get(url).then(response => response.data);
}

export async function rejectValuableItem(itemId: string, reason: string, selectedOption: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/scheduled_items/${itemId}/reject`);

    return axios.post(url, { reason, reasonType: selectedOption });
}
