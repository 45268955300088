/* eslint-disable react/no-array-index-key */
import type { FC } from 'react';
import { useMemo } from 'react';
import type { ParsedPrompt } from '../types';

function createOrderedList(listItems: JSX.Element[]): JSX.Element {
    return (
        <ol key={`ol-${listItems[0].key}`} start={Number(listItems[0].key)}>
            {listItems}
        </ol>
    );
}

function replaceCodeInLine(line: string): JSX.Element[] {
    const pattern = /(\{[^}]*\})/g;
    const parts = line.split(pattern);
    return parts.map(part => {
        if (part.startsWith('{') && part.endsWith('}')) {
            const content = part.slice(1, -1);
            return <code key={content}>{`{ ${content.trim()} }`}</code>;
        } else {
            return <span key={part}>{part}</span>;
        }
    });
}

/**
 * input:
 * line A
 * line B
 * 1. line C
 * 2. line D
 *
 * output
 * <p>line A</p>
 * <p>line B</p>
 * <ol>
 *  <li>line C</li>
 *  <li>line D</li>
 * </ol>
 */
export function renderPromptContent(content: string): JSX.Element[] {
    const lines = content.split('\n');
    const elements = [];
    let listItems: JSX.Element[] = [];
    const numberedLinePrefix = /^\s*(\d+\.\s)/;
    lines.forEach((line, i) => {
        const isNumberedListItem = numberedLinePrefix.exec(line);
        if (isNumberedListItem) {
            const lineWithoutNumberPrefix = line.replace(numberedLinePrefix, '');
            listItems.push(
                <li key={parseInt(isNumberedListItem[1], 10)}>{replaceCodeInLine(lineWithoutNumberPrefix)}</li>
            );
        } else {
            if (listItems.length > 0) {
                elements.push(createOrderedList(listItems));
                listItems = [];
            }

            const lineToAdd = line.startsWith('API: ') ? <u>{line}</u> : replaceCodeInLine(line);
            if (line.length > 0) {
                elements.push(<p key={line}>{lineToAdd}</p>);
            } else {
                elements.push(<br key={`empty-${i}`} />);
            }
        }
    });

    if (listItems.length > 0) {
        elements.push(createOrderedList(listItems));
    }

    return elements;
}

export const PromptRenderer: FC<{ readonly prompt: ParsedPrompt }> = ({ prompt }) => {
    const renderedContent = useMemo(
        () => (prompt.mainSection != null ? renderPromptContent(prompt.mainSection) : null),
        [prompt]
    );

    return (
        <div>
            <h3>{prompt.title}</h3>
            {renderedContent}
            {prompt.subsections?.map(section => (
                <>
                    <h4>{section.title}</h4>
                    {renderPromptContent(section.text)}
                </>
            ))}
        </div>
    );
};
