import axios from 'axios';

export interface UpdateAttachmentDataParams {
    readonly description?: string;
    readonly type?: string;
    readonly claimItemIds?: string[];
    readonly attachmentPublicId?: string;
}

export async function updateAttachmentData(
    attachmentId: string,
    { description, type, claimItemIds, attachmentPublicId }: UpdateAttachmentDataParams
): Promise<void> {
    const url = `/backoffice/attachments/${attachmentId}`;
    const body = {
        ...(description && { description }),
        ...(type && { type }),
        ...(claimItemIds && { claim_item_ids: claimItemIds }),
        ...(attachmentPublicId && { attachment_public_id: attachmentPublicId }),
    };

    await axios.patch(url, body);
}
