/* eslint-disable @typescript-eslint/naming-convention */
import type { IconType } from '@lemonade-hq/bluis';
import {
    AddressIcon,
    CarIcon,
    ComplianceIcon,
    CryptoIcon,
    GrowthGraphIcon,
    HomeIcon,
    InsuredIcon,
    IntegrationsIcon,
    LoveIcon,
    MapIcon,
    PetIcon,
    SupportIcon,
    TestIcon,
    TieIcon,
    WindowsIcon,
} from '@lemonade-hq/bluis';
import { NOOP } from '@lemonade-hq/ts-helpers';
import React, { useContext } from 'react';
import type { SideMenuItem, SideMenuItems } from './types';

interface SideMenuContextType {
    readonly activeItem?: SideMenuItem;
    readonly handleItemLinkClick: (name?: string) => void;
}

export const SideMenuContext = React.createContext<SideMenuContextType>({
    handleItemLinkClick: NOOP,
});

export const useSideMenuContext = (): SideMenuContextType => useContext(SideMenuContext);

export function isActiveLink(item: SideMenuItem, pathname: string): boolean {
    if ('link' in item) {
        if (item.link.includes('home/instant-claims/settings') && pathname.includes('home/instant-claims/settings')) {
            return true;
        }

        return item.link === pathname || pathname.includes(item.link);
    }

    return false;
}

export function saveLastOpenedGroup(groupName: string): void {
    localStorage.setItem('SideMenuLastOpenedGroup', groupName);
}

export const SideMenuIconsDictionary: Record<string, IconType> = {
    Users: InsuredIcon,
    Home: HomeIcon,
    Pet: PetIcon,
    Car: CarIcon,
    Life: LoveIcon,
    Crypto: CryptoIcon,
    Addresses: AddressIcon,
    Partners: TieIcon,
    Areas: MapIcon,
    Compliance: ComplianceIcon,
    LandingPages: WindowsIcon,
    Integrations: IntegrationsIcon,
    Operators: SupportIcon,
    Growth: GrowthGraphIcon,
    LoCo: LoveIcon,
    'Generative AI': TestIcon,
};

export function findCurrentActiveItem(schema?: SideMenuItems): SideMenuItem | undefined {
    if (!schema) {
        return;
    }

    let activeItem: SideMenuItem | undefined;
    const { pathname } = window.location;

    schema.forEach(item => {
        if ('link' in item && item.link === pathname) activeItem = item;
        if ('subMenu' in item) {
            item.subMenu.forEach(subItem => {
                if (isActiveLink(subItem, pathname)) activeItem = subItem;
                if ('subMenu' in subItem) {
                    subItem.subMenu.forEach(subSubItem => {
                        if (isActiveLink(subSubItem, pathname)) activeItem = subSubItem;
                    });
                }
            });
        }
    });

    return activeItem;
}
