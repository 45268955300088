import { createContext, useContext } from 'react';
import { AiResponseType } from '../../shared/types';
import { defaultFilters, defaultMetadata } from '../supervisor.const';
import type { SupervisorActions, SupervisorState } from './useSupervisor';

export const SupervisorContext = createContext<{
    readonly actions: SupervisorActions;
    readonly state: SupervisorState;
}>({
    state: {
        mode: AiResponseType.Public,
        macroTags: [],
        filters: defaultFilters,
        metadata: defaultMetadata,
    },
    actions: {
        setMode: () => {},
        setTicketData: () => {},
        setMacroTags: () => {},
        loadNextTicket: async () => {},
        interveneAiResponse: async () => {},
        skipTicket: async () => {},
        unassignTicket: async () => {},
        setLoadingTicket: () => {},
        setIsSkippingTicket: () => {},
        reviewAiResponse: async () => {},
        reset: () => {},
        onReviewPass: async () => {},
        onResetReview: async () => {},
        releaseToZendesk: async () => {},
        updateFilters: () => {},
        expireTicket: async () => {},
    },
});

export const useSupervisorContext = (): {
    readonly actions: SupervisorActions;
    readonly state: SupervisorState;
} => useContext(SupervisorContext);
