import type { AccordionProps } from '@lemonade-hq/blender-ui';
import { Accordion, Card, FormLayout } from '@lemonade-hq/blender-ui';
import type { FC, PropsWithChildren } from 'react';

type ToolEditorFormSectionProps = Pick<AccordionProps, 'badges' | 'className' | 'title'>;

export const ToolEditorFormSection: FC<PropsWithChildren<ToolEditorFormSectionProps>> = ({
    children,
    title,
    className,
    badges,
}) => {
    return (
        <Accordion badges={badges} className={className} isOpenByDefault title={title}>
            <Card borderRadius="sm" padding="1.2rem" variant="primary">
                <FormLayout>{children}</FormLayout>
            </Card>
        </Accordion>
    );
};
