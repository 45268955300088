import { Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import { Dialog } from '@lemonade-hq/bluis';
import { useCallback } from 'react';
import { VersionType } from 'models/LoCo/Insurance/BaseEdition';
import { usePublishRelease } from 'queries/LoCo/Insurance/ReleasesQueries';

interface PublishDialogProps {
    readonly releasePublicId: string;
    readonly onClose: () => void;
    readonly rolloutStrategy: VersionType;
}

export const PublishDialog: React.FC<PublishDialogProps> = ({ releasePublicId, onClose, rolloutStrategy }) => {
    const { mutateAsync, isPending, error } = usePublishRelease(releasePublicId);

    const publish = useCallback(async () => {
        await mutateAsync(releasePublicId);
        onClose();
    }, [mutateAsync, onClose, releasePublicId]);

    return (
        <Dialog
            actions={[
                { type: 'close', text: 'Cancel', onClick: onClose },
                { type: 'submit', text: 'Publish release', onClick: publish },
            ]}
            error={error === null ? undefined : (error as Error).message}
            loading={isPending}
            title="Publish release to production"
        >
            <Flex flexDirection="column" gap={spacing.s08}>
                <Text fontWeight="bold">Are you sure you are ready to publish?</Text>
                {rolloutStrategy === VersionType.Major && (
                    <>
                        <Text>
                            Once published, the changes will be applied for new quotes and renewal policies with the
                            relevant effective dates.
                        </Text>
                        <Text>
                            This will not impact existing quotes (unless the effective date is updated) or policies
                            until their next renewal.
                        </Text>
                        <Text>Upcoming Renewal Policies which have already been created will not be impacted.</Text>
                    </>
                )}
                {rolloutStrategy === VersionType.Minor && (
                    <>
                        <Text>
                            Once published, it will be available for existing quotes/policies (if they make any edits)
                            starting on the relevant effective dates.
                        </Text>
                        <Text>No changes will be made automatically to any quotes or policies. WIP </Text>
                    </>
                )}
            </Flex>
        </Dialog>
    );
};
