import type { DialogAction } from '@lemonade-hq/bluis';
import {
    Alert,
    AlertMode,
    DialogActionButtons,
    ErrorSection,
    FormInputWrapper,
    InlineButton,
    Input,
    Multiselect,
    TableTitle,
} from '@lemonade-hq/bluis';
import { basicRequiredValidation, Flex, useForm } from '@lemonade-hq/cdk';
import type { Locale } from '@lemonade-hq/lemonation';
import { isDefined } from '@lemonade-hq/ts-helpers';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormTextArea } from '../SharedStyles';
import type { DialogTypes } from '../UnderwritingDialogs';
import type { ActionType, ExplanationEntityType, LocalizedExplanationItem } from '../underwritingUtils';
import {
    getDecisionTypeText,
    getManageEntityDescriptionLabel,
    isDraftExplanationVersion,
    MIN_EXPLANATION_CHARS,
    MIN_INTERNAL_NOTE_CHARS,
} from '../underwritingUtils';
import { LocalizedExplanation } from './LocalizedExplanation';
import { isEmptyString, validateMinChars } from 'components/LoCo/common/helpers/inputHelpers';
import { ProductLineErrorMessage } from 'components/LoCo/global-registry/coverages-registry/CoveragesAndSettingsRegistry';
import { StyledFormInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import { DialogType } from 'components/LoCo/products/SharedTableConfig';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type {
    Explanation,
    ExplanationContent,
    ExplanationVersion,
    ProductLine,
} from 'models/LoCo/Insurance/UnderwritingRegistry';
import { useGetProductLines } from 'queries/LoCo/Insurance/ProductLineQueries';
import {
    useCloneExplanation,
    useCreateExplanation,
    useUpdateExplanation,
} from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

const StyledFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 30px;
`;

const StyledBaseFieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 850px;
    margin-top: 24px;
`;

const StyledLocalizedExplanations = styled(Flex)`
    flex-direction: column;
    gap: 36px;
    margin: 24px 0;
`;

const StyledInlineButton = styled(InlineButton)`
    align-self: flex-start;
`;

const StyledFormRow = styled(StyledFormInputWrapper)`
    > div {
        align-items: flex-start;
        > label {
            padding-top: 10px;
        }
    }
`;

const StyledFormDropdownWrapper = styled(FormInputWrapper)`
    width: 100%;
    max-width: 550px;
`;

function validateExplanation(updatedValues: LocalizedExplanationItem): boolean {
    // manually validate since useForm is not updated yet
    const texts = [updatedValues.chat, updatedValues.email, updatedValues.snail];
    const isValidTexts =
        texts.every(text => validateMinChars(text, MIN_EXPLANATION_CHARS, true)) &&
        texts.some(text => validateMinChars(text, MIN_EXPLANATION_CHARS, false));

    return isValidTexts && validateMinChars(updatedValues.locale, 2, false);
}

type ExplanationFormFields = {
    readonly name: string;
    readonly note: string;
    readonly productLines: ProductLine[];
    readonly localizedExplanationDetails: Partial<Record<Locale, ExplanationContent>>;
};

function transformExplanationToFormFields(
    dialogType: DialogType,
    explanation: Explanation,
    explanationVersion: ExplanationVersion
): ExplanationFormFields {
    return {
        note:
            isDraftExplanationVersion(explanationVersion) || dialogType === DialogType.View
                ? explanationVersion.note
                : '', // keep note when editing a draft, empty when cloning a version
        name: explanation.name,
        productLines: explanation.productLines,
        localizedExplanationDetails: explanationVersion.explanationDetails,
    };
}

// a consolidated state, based on useForm and a dynamic array, since useForm doesn't support arrays
// disabled return type - long and cumbersome return type...
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useFormWithArray(editedEntity: ExplanationFormFields | null) {
    const [localizedExplanationDetails, setLocalizedExplanationDetails] = useState<LocalizedExplanationItem[]>(
        isDefined(editedEntity?.localizedExplanationDetails)
            ? Object.entries(editedEntity.localizedExplanationDetails).map(([locale, content]) => ({
                  locale: locale as Locale,
                  ...content,
                  isValid: true,
              }))
            : [
                  {
                      isValid: false,
                  },
              ]
    );

    const { values, setValue, valid, errors } = useForm({
        fields: {
            name: {
                startValue: editedEntity?.name ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            internalNote: {
                startValue: editedEntity?.note ?? '',
                validations: {
                    required: basicRequiredValidation,
                    charMinLength: {
                        errorMessage: `Enter at least ${MIN_INTERNAL_NOTE_CHARS} characters`,
                        test: (value: string) => {
                            return value.trim().length >= MIN_INTERNAL_NOTE_CHARS;
                        },
                        skipCondition: formValues => isEmptyString(formValues.internalNote),
                    },
                },
            },
            productLines: {
                startValue: editedEntity?.productLines.map(productLine => productLine.code) ?? [],
                validations: {
                    required: {
                        errorMessage: '',
                        test: (value: string[]) => value.length > 0,
                        skipCondition: () => isDefined(editedEntity),
                    },
                },
            },
        },
    });

    const mergedValues = {
        ...values,
        localizedExplanationDetails,
    };

    return {
        values: mergedValues,
        setValue,
        valid: valid && localizedExplanationDetails.every(s => s.isValid),
        setArray: setLocalizedExplanationDetails,
        errors,
    };
}

interface ManageExplanationVersionFormProps {
    readonly onClose: () => void;
    readonly originalEntity:
        | Extract<DialogTypes<ExplanationEntityType>, { readonly type: ActionType.CreateOrEditDraft }>['originalEntity']
        | null;
    readonly decisionType: UnderwritingDecisionLifecycleContext;
}

export const ManageExplanationVersionForm: React.FC<ManageExplanationVersionFormProps> = ({
    onClose,
    originalEntity,
    decisionType,
}) => {
    const dialogType: DialogType = !isDefined(originalEntity)
        ? DialogType.Add
        : originalEntity.isEditable
          ? DialogType.Edit
          : DialogType.View;

    const { values, setValue, valid, setArray, errors } = useFormWithArray(
        isDefined(originalEntity)
            ? transformExplanationToFormFields(dialogType, originalEntity.entity, originalEntity.entityVersion)
            : null
    );

    const nonRemovableLocales = useMemo(() => {
        if (isDefined(originalEntity)) {
            return new Set<Locale>(Object.keys(originalEntity.entityVersion.explanationDetails) as Locale[]);
        }

        return new Set<Locale>();
    }, [originalEntity]);

    const {
        error: errorCreate,
        mutateAsync: createExplanation,
        isPending: isCreatingExplanation,
    } = useCreateExplanation();

    const {
        error: errorUpdate,
        mutateAsync: updateExplanation,
        isPending: isUpdatingExplanation,
    } = useUpdateExplanation(originalEntity?.entityVersion.publicId ?? '');

    const {
        error: errorClone,
        mutateAsync: CloneExplanation,
        isPending: isCloningExplanation,
    } = useCloneExplanation(originalEntity?.entityVersion.publicId ?? '');

    const showProductLines = dialogType !== DialogType.View && !isDefined(originalEntity); // show only in case of brand new explanation

    const {
        isError: productLinesError,
        isLoading: isLoadingProductLines,
        data: productLines,
    } = useGetProductLines(showProductLines);

    const handleSave = useCallback(async () => {
        const explanationDetails = values.localizedExplanationDetails.reduce(
            (acc, arg) => {
                if (isDefined(arg.locale)) {
                    acc[arg.locale] = arg;
                }

                return acc;
            },
            {} as Record<Locale, ExplanationContent>
        );

        if (isDefined(originalEntity)) {
            if (isDraftExplanationVersion(originalEntity.entityVersion)) {
                await updateExplanation({
                    note: values.internalNote,
                    explanationDetails,
                });
            } else {
                await CloneExplanation({
                    note: values.internalNote,
                    explanationDetails,
                });
            }
        } else {
            await createExplanation({
                name: values.name,
                note: values.internalNote,
                productLineCodes: values.productLines,
                explanationDetails,
                type: decisionType,
            });
        }

        onClose();
    }, [
        CloneExplanation,
        createExplanation,
        decisionType,
        onClose,
        originalEntity,
        updateExplanation,
        values.internalNote,
        values.localizedExplanationDetails,
        values.name,
        values.productLines,
    ]);

    const actions = useMemo<DialogAction[]>(() => {
        const actionsArray: DialogAction[] = [
            {
                text: dialogType === DialogType.View ? 'Close' : 'Cancel',
                type: 'close',
                onClick: onClose,
            },
        ];

        if (!isDefined(originalEntity) || originalEntity.isEditable) {
            actionsArray.push({
                text: 'Save draft',
                type: 'submit',
                disabled:
                    !valid ||
                    isUpdatingExplanation ||
                    isCreatingExplanation ||
                    isLoadingProductLines ||
                    isCloningExplanation,
                onClick: handleSave,
            });
        }

        return actionsArray;
    }, [
        dialogType,
        onClose,
        originalEntity,
        valid,
        isUpdatingExplanation,
        isCreatingExplanation,
        isLoadingProductLines,
        isCloningExplanation,
        handleSave,
    ]);

    const productLineOptions = useMemo(
        () =>
            isDefined(productLines)
                ? productLines
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(productLine => ({
                          id: productLine.code,
                          value: productLine.code,
                          label: productLine.name,
                      }))
                : [],
        [productLines]
    );

    const errorMessage = productLinesError
        ? ProductLineErrorMessage
        : errorCreate?.toString() ?? errorUpdate?.toString() ?? errorClone?.toString() ?? undefined;
    const title = `${dialogType === DialogType.Add ? 'Add ' : dialogType === DialogType.Edit ? 'Edit ' : ''} ${getDecisionTypeText(decisionType)} Explanation`;

    const descriptionLabel = useMemo(() => {
        return getManageEntityDescriptionLabel(dialogType, originalEntity?.entityVersion.version ?? undefined);
    }, [dialogType, originalEntity]);

    return (
        <StyledFormWrapper>
            <TableTitle title={title} />
            <StyledBaseFieldsWrapper>
                <StyledFormInputWrapper inputWidth="500px" label={`Internal Name`}>
                    <Input
                        disabled={dialogType !== DialogType.Add}
                        onChange={event => setValue('name', event.target.value)}
                        value={values.name}
                    />
                </StyledFormInputWrapper>
                <StyledFormRow
                    errorMessages={[errors.internalNote?.charMinLength]}
                    label={descriptionLabel}
                    showErrors={!isEmpty(errors.internalNote?.charMinLength ?? '')}
                >
                    <FormTextArea
                        disabled={dialogType === DialogType.View}
                        onChange={value => setValue('internalNote', value)}
                        value={values.internalNote}
                        width="500px"
                    />
                </StyledFormRow>
                {showProductLines && (
                    <StyledFormDropdownWrapper label="Product Lines">
                        <Multiselect
                            disabled={isLoadingProductLines}
                            options={productLineOptions}
                            placeholder="Select"
                            selectedIds={values.productLines}
                            setSelectedOptions={productLineOption =>
                                setValue(
                                    'productLines',
                                    productLineOption.map(option => option.value)
                                )
                            }
                            width={200}
                        />
                    </StyledFormDropdownWrapper>
                )}
            </StyledBaseFieldsWrapper>
            <StyledLocalizedExplanations>
                {values.localizedExplanationDetails.map(localizedExplanationDetailItem => (
                    <LocalizedExplanation
                        allItems={values.localizedExplanationDetails}
                        decisionType={decisionType}
                        disabled={dialogType === DialogType.View}
                        editable={
                            !localizedExplanationDetailItem.locale ||
                            !nonRemovableLocales.has(localizedExplanationDetailItem.locale)
                        }
                        isLoading={isLoadingProductLines}
                        key={localizedExplanationDetailItem.locale}
                        localizedExplanationItem={localizedExplanationDetailItem}
                        onChange={item => {
                            setArray(
                                values.localizedExplanationDetails.map(explanation =>
                                    localizedExplanationDetailItem.locale === explanation.locale
                                        ? ({ ...item, isValid: validateExplanation(item) } as LocalizedExplanationItem)
                                        : explanation
                                )
                            );
                        }}
                        onRemove={() =>
                            setArray(
                                values.localizedExplanationDetails.filter(
                                    explanation => localizedExplanationDetailItem.locale !== explanation.locale
                                )
                            )
                        }
                        showErrors
                    />
                ))}
                {dialogType !== DialogType.View && (
                    <StyledInlineButton
                        disabled={values.localizedExplanationDetails.some(s => !s.isValid)}
                        onClick={() =>
                            setArray([
                                ...values.localizedExplanationDetails,
                                {
                                    isValid: false,
                                },
                            ])
                        }
                    >
                        Add explanation in another locale
                    </StyledInlineButton>
                )}
            </StyledLocalizedExplanations>

            {errorMessage != null && <ErrorSection title={errorMessage} />}
            {dialogType !== DialogType.View && (
                <Alert
                    mode={AlertMode.Info}
                    title="After you save changes as a draft, they won't be visible in production until the version is published."
                />
            )}

            <DialogActionButtons actions={actions} loading={false} onActionError={() => {}} />
        </StyledFormWrapper>
    );
};
