/* eslint-disable @typescript-eslint/no-dynamic-delete */
import type * as avro from 'avsc';
import { produce } from 'immer';
import type { CoreExpression } from 'jsep';
import jsep from 'jsep';
import keyBy from 'lodash/keyBy';
import type { MutableRefObject, PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useReducer } from 'react';
import { getInputType, randomExpressionId } from './ExpressionSimpleEditorShared';
import { jsepToExpressionNodes, validateExpression } from './expressionTransformers';
import type {
    BinaryExpressionNode,
    CallExpressionNode,
    ExpressionNode,
    ExpressionTree,
    LiteralNode,
    LogicalExpressionNode,
    LogicalOperator,
} from './expressionTypes';
import { ExpressionType, nodeHasChildren } from './expressionTypes';
import type { InputFunction } from './operators';
import { COMPARE_OPERATORS, getLogicalOperator, INPUT_OPERATORS } from './operators';
import type { FieldEntry } from 'models/LoCo/Insurance/Schema';

export enum ExpressionActionType {
    SetValueType = 'SetValueType',
    AddLogicalOperator = 'AddLogicalOperator',
    RemoveLogicalOperator = 'RemoveLogicalOperator',
    ChangeOperator = 'ChangeOperator',
    ConvertToInput = 'ConvertToInput',
    AddArrayElement = 'AddArrayElement',
    RemoveArrayElement = 'RemoveArrayElement',
    ResetOperatorAndRightOperand = 'ResetOperatorAndRightOperand',
}

export interface ExpressionState {
    readonly root: string;
    readonly expressionTree: ExpressionTree;
}

export type SetValueAction<T extends ExpressionNode> = {
    readonly type: ExpressionActionType.SetValueType;
    readonly payload: { readonly id: string; readonly newValue: Partial<T>; readonly skipReset?: boolean };
};

export type AddLogicalOperator = {
    readonly type: ExpressionActionType.AddLogicalOperator;
};

export type RemoveLogicalOperator = {
    readonly type: ExpressionActionType.RemoveLogicalOperator;
    readonly payload: { readonly id: string; readonly isRootDelete?: boolean };
};

export type ChangeOperator = {
    readonly type: ExpressionActionType.ChangeOperator;
    readonly payload: {
        readonly id: string;
        readonly operator: string;
    };
};

export type ConvertToInput = {
    readonly type: ExpressionActionType.ConvertToInput;
    readonly payload: { readonly id: string; readonly function: string; readonly value: string };
};

export type AddArrayElement = {
    readonly type: ExpressionActionType.AddArrayElement;
    readonly payload: { readonly id: string };
};

export type RemoveArrayElement = {
    readonly type: ExpressionActionType.RemoveArrayElement;
    readonly payload: { readonly id: string };
};

export type ResetOperatorAndRightOperand = {
    readonly type: ExpressionActionType.ResetOperatorAndRightOperand;
    readonly payload: { readonly id: string; readonly caller: string };
};

export type ExpressionActionContext = {
    readonly productSchema: Record<string, FieldEntry>;
    readonly functions: Record<string, InputFunction>;
};

export type ExpressionAction<T extends ExpressionNode = ExpressionNode> =
    | AddArrayElement
    | AddLogicalOperator
    | ChangeOperator
    | ConvertToInput
    | RemoveArrayElement
    | RemoveLogicalOperator
    | ResetOperatorAndRightOperand
    | SetValueAction<T>;

export type ExpressionActionWithContext<T extends ExpressionNode = ExpressionNode> = ExpressionAction<T> & {
    readonly context: ExpressionActionContext;
};

export interface ExpressionSimpleEditorContextType {
    readonly state: ExpressionState | null;
    readonly dispatch: React.Dispatch<ExpressionAction> | null;
    readonly productSchema: Record<string, FieldEntry> | null;
    readonly functions: Record<string, InputFunction> | null;
    readonly allowEmpty?: boolean;
}

export const ExpressionSimpleEditorContext = createContext<ExpressionSimpleEditorContextType>({
    state: null,
    dispatch: null,
    productSchema: null,
    functions: null,
    allowEmpty: true,
});

export const buildEmptyBinaryExpression = (id: string, parent?: string): ExpressionTree => {
    const leftId = randomExpressionId();
    const rightId = randomExpressionId();

    return {
        [id]: {
            type: ExpressionType.BinaryExpression,
            operator: '',
            children: [leftId, rightId],
            parent,
        },
        [leftId]: {
            type: ExpressionType.Literal,
            value: '',
            raw: '',
            parent: id,
        },
        [rightId]: {
            type: ExpressionType.Literal,
            value: '',
            raw: '',
            parent: id,
        },
    };
};

const deleteChildren = (state: ExpressionState, id: string): ExpressionState => {
    const node = state.expressionTree[id];

    if (!nodeHasChildren(node)) return state;

    node.children.forEach(child => {
        deleteChildren(state, child);
        delete state.expressionTree[child];
    });

    return state;
};

export const expressionReducer: React.Reducer<ExpressionState, ExpressionActionWithContext> = (state, action) => {
    if (state.root === '') {
        const id = randomExpressionId();

        return {
            expressionTree: buildEmptyBinaryExpression(id),
            root: id,
        };
    }

    switch (action.type) {
        case ExpressionActionType.SetValueType: {
            const node = state.expressionTree[action.payload.id];

            let currState = state;

            if (node.parent !== undefined && !action.payload.skipReset) {
                const parent = state.expressionTree[node.parent] as BinaryExpressionNode | CallExpressionNode;

                if ([ExpressionType.BinaryExpression, ExpressionType.CallExpression].includes(parent.type)) {
                    currState = expressionReducer(state, {
                        type: ExpressionActionType.ResetOperatorAndRightOperand,
                        payload: { id: node.parent, caller: action.payload.id },
                        context: action.context,
                    });
                }
            }

            return produce(currState, draft => {
                draft.expressionTree[action.payload.id] = {
                    ...node,
                    ...(action.payload.newValue as ExpressionNode),
                };
            });
        }

        case ExpressionActionType.ChangeOperator: {
            const node = state.expressionTree[action.payload.id] as
                | BinaryExpressionNode
                | CallExpressionNode
                | LogicalExpressionNode;

            if (!nodeHasChildren(node)) {
                throw new Error('Cannot change operator of a non binary or call expression');
            }

            if (node.type === ExpressionType.LogicalExpression) {
                return produce(state, draft => {
                    Object.keys(draft.expressionTree).forEach((key: string) => {
                        const child = draft.expressionTree[key];

                        if (child.type === ExpressionType.LogicalExpression) {
                            draft.expressionTree[key] = {
                                ...child,
                                operator: action.payload.operator as LogicalOperator,
                            };
                        }
                    });
                });
            }

            const { type } = getInputType(
                state.expressionTree,
                action.payload.id,
                action.context.productSchema,
                action.context.functions
            );

            const operator = COMPARE_OPERATORS[type][action.payload.operator];

            const newChildren: ExpressionTree = {};
            const directChildren: string[] = [];

            operator.arguments.forEach(arg => {
                const argNodeId = randomExpressionId();

                directChildren.push(argNodeId);
                if (arg.array === true) {
                    const child = randomExpressionId();

                    newChildren[argNodeId] = {
                        type: ExpressionType.ArrayExpression,
                        children: [child],
                        parent: action.payload.id,
                    };
                    newChildren[child] = {
                        type: ExpressionType.Literal,
                        value: '',
                        raw: '',
                        parent: argNodeId,
                    };
                } else {
                    newChildren[argNodeId] = {
                        type: ExpressionType.Literal,
                        value: '',
                        raw: '',
                        placeholder: arg.placeholder,
                        parent: action.payload.id,
                    };
                }
            });

            const nodeExtraArgs = operator.isCallable
                ? {
                      type: ExpressionType.CallExpression,
                      callee: action.payload.operator,
                  }
                : {
                      type: ExpressionType.BinaryExpression,
                      operator: action.payload.operator,
                  };

            return produce(state, draft => {
                node.children.slice(1).forEach(child => {
                    deleteChildren(draft, child);
                    delete draft.expressionTree[child];
                });

                draft.expressionTree[action.payload.id] = {
                    ...node,
                    ...nodeExtraArgs,
                    children: [node.children[0], ...directChildren],
                } as ExpressionNode;

                Object.assign(draft.expressionTree, newChildren);
            });
        }

        case ExpressionActionType.AddLogicalOperator: {
            const newLogicalNodeId = randomExpressionId();
            const newRightId = randomExpressionId();

            const operator = getLogicalOperator(state.expressionTree[state.root]);

            let deepestRightNodeId = state.root;
            let node = state.expressionTree[state.root];

            while (node.type === ExpressionType.LogicalExpression) {
                [, deepestRightNodeId] = node.children;
                node = state.expressionTree[deepestRightNodeId];
            }

            const deepestRightNode = state.expressionTree[deepestRightNodeId] as LogicalExpressionNode;

            if (deepestRightNodeId === state.root) {
                return produce(state, draft => {
                    draft.root = newLogicalNodeId;

                    draft.expressionTree = {
                        ...state.expressionTree,
                        [newLogicalNodeId]: {
                            type: ExpressionType.LogicalExpression,
                            operator,
                            children: [state.root, newRightId],
                        },
                        [state.root]: {
                            ...state.expressionTree[state.root],
                            parent: newLogicalNodeId,
                        },
                        ...buildEmptyBinaryExpression(newRightId, newLogicalNodeId),
                    };
                });
            } else {
                const parent = deepestRightNode.parent ?? '';
                const parentNode = state.expressionTree[parent] as LogicalExpressionNode;

                return produce(state, draft => {
                    draft.expressionTree = {
                        ...state.expressionTree,
                        [newLogicalNodeId]: {
                            type: ExpressionType.LogicalExpression,
                            operator,
                            children: [deepestRightNodeId, newRightId],
                            parent: deepestRightNode.parent,
                        },
                        [parent]: {
                            ...parentNode,
                            children: [parentNode.children[0], newLogicalNodeId],
                        },
                        [deepestRightNodeId]: {
                            ...deepestRightNode,
                            parent: newLogicalNodeId,
                        },
                        ...buildEmptyBinaryExpression(newRightId, newLogicalNodeId),
                    };
                });
            }
        }

        case ExpressionActionType.RemoveLogicalOperator: {
            const node = state.expressionTree[action.payload.id] as LogicalExpressionNode;
            const rightChild = state.expressionTree[node.children[1]];

            if (action.payload.isRootDelete) {
                return produce(state, draft => {
                    draft.root = node.children[1];

                    delete draft.expressionTree[action.payload.id];
                    delete draft.expressionTree[node.children[0]];
                });
            }

            if (rightChild.type === ExpressionType.LogicalExpression) {
                return produce(state, draft => {
                    draft.expressionTree[action.payload.id] = {
                        ...node,
                        children: [node.children[0], rightChild.children[1]],
                    };

                    draft.expressionTree[node.children[1]] = {
                        ...rightChild,
                        parent: action.payload.id,
                    };

                    draft.expressionTree[rightChild.children[1]] = {
                        ...state.expressionTree[rightChild.children[1]],
                        parent: action.payload.id,
                    };

                    deleteChildren(draft, rightChild.children[0]);
                    delete draft.expressionTree[rightChild.children[0]];
                    delete draft.expressionTree[node.children[1]];
                });
            } else if (state.root === action.payload.id) {
                return produce(state, draft => {
                    deleteChildren(draft, node.children[1]);
                    delete draft.expressionTree[node.children[1]];
                    delete draft.expressionTree[action.payload.id];
                    [draft.root] = node.children;

                    draft.expressionTree[node.children[0]] = {
                        ...state.expressionTree[node.children[0]],
                        parent: undefined,
                    };
                });
            } else {
                const parentName = node.parent ?? '';
                const parent = state.expressionTree[parentName] as LogicalExpressionNode;

                return produce(state, draft => {
                    draft.expressionTree[parentName] = {
                        ...parent,
                        children: [parent.children[0], node.children[0]],
                    };

                    draft.expressionTree[node.children[0]] = {
                        ...state.expressionTree[node.children[0]],
                        parent: node.parent,
                    };

                    deleteChildren(draft, node.children[1]);
                    delete draft.expressionTree[node.children[1]];
                    delete draft.expressionTree[action.payload.id];
                });
            }
        }

        case ExpressionActionType.ConvertToInput: {
            const node = state.expressionTree[action.payload.id] as LiteralNode;

            let currState = state;

            if (node.parent != null) {
                const parent = state.expressionTree[node.parent] as BinaryExpressionNode | CallExpressionNode;

                if ([ExpressionType.BinaryExpression, ExpressionType.CallExpression].includes(parent.type)) {
                    currState = expressionReducer(state, {
                        type: ExpressionActionType.ResetOperatorAndRightOperand,
                        payload: { id: node.parent, caller: action.payload.id },
                        context: action.context,
                    });
                }
            }

            return produce(currState, draft => {
                draft.expressionTree[action.payload.id] = {
                    type: ExpressionType.InputExpression,
                    callee: action.payload.function,
                    value: action.payload.value,
                    parent: node.parent,
                };
            });
        }

        case ExpressionActionType.AddArrayElement: {
            const node = state.expressionTree[action.payload.id];
            const newId = randomExpressionId();

            if (node.type !== ExpressionType.ArrayExpression) return state;

            return produce(state, draft => {
                draft.expressionTree[action.payload.id] = {
                    ...node,
                    children: [...node.children, newId],
                };
                draft.expressionTree[newId] = {
                    type: ExpressionType.Literal,
                    value: '',
                    raw: '',
                    parent: action.payload.id,
                };
            });
        }

        case ExpressionActionType.RemoveArrayElement: {
            const node = state.expressionTree[action.payload.id];
            const parentName = node.parent ?? '';
            const parent = state.expressionTree[parentName];

            if (parent.type !== ExpressionType.ArrayExpression) return state;
            if (parent.children.length === 1) return state;

            return produce(state, draft => {
                delete draft.expressionTree[action.payload.id];
                draft.expressionTree[parentName] = {
                    ...parent,
                    children: parent.children.filter(child => child !== action.payload.id),
                };
            });
        }

        case ExpressionActionType.ResetOperatorAndRightOperand: {
            const node = state.expressionTree[action.payload.id] as BinaryExpressionNode | CallExpressionNode;

            const newRightChildId = randomExpressionId();

            if (node.children[0] !== action.payload.caller) return state;

            return produce(state, draft => {
                draft.expressionTree[action.payload.id] = {
                    type: ExpressionType.BinaryExpression,
                    operator: '',
                    children: [node.children[0], newRightChildId],
                    parent: node.parent,
                };

                draft.expressionTree[newRightChildId] = {
                    type: ExpressionType.Literal,
                    value: '',
                    raw: '',
                    parent: action.payload.id,
                };

                node.children.slice(1).forEach(child => {
                    deleteChildren(draft, child);
                    delete draft.expressionTree[child];
                });
            });
        }

        default:
            return state;
    }
};

export interface ExpressionSimpleEditorProps {
    readonly expression: string;
    readonly productSchema: Record<string, FieldEntry>;
    readonly ruleBuilderRef: MutableRefObject<ExpressionState | null>;
    readonly onValidation?: (valid: boolean) => void;
    readonly additionalFunctions?: Record<string, InputFunction>;
    readonly allowEmpty?: boolean;
}

function getInit(expression: string, additionalFunctions: Record<string, InputFunction>): ExpressionState {
    if (expression.length > 0) {
        const root = randomExpressionId();
        const expressionTree = jsepToExpressionNodes(
            jsep(expression) as CoreExpression,
            root,
            Object.keys(additionalFunctions)
        );

        return {
            expressionTree,
            root,
        };
    }

    return {
        root: '',
        expressionTree: {},
    };
}

export const ExpressionSimpleEditorProvider: React.FC<PropsWithChildren<ExpressionSimpleEditorProps>> = ({
    expression,
    productSchema,
    onValidation,
    children,
    ruleBuilderRef,
    additionalFunctions = {},
    allowEmpty = true,
}) => {
    const init = getInit(expression, additionalFunctions);

    const reducerWrapper = (oldState: ExpressionState, action: ExpressionAction<ExpressionNode>): ExpressionState => {
        const context = { productSchema, functions: additionalFunctions };
        const newState = expressionReducer(oldState, { ...action, context });
        onValidation?.(validateExpression(newState.expressionTree, newState.root));

        return newState;
    };

    const [state, dispatch] = useReducer(reducerWrapper, init);

    const providerValue = useMemo(() => {
        const groupedProductSchema = keyBy(productSchema, 'name') as unknown as Record<string, avro.schema.RecordType>;

        return {
            state,
            dispatch,
            productSchema: groupedProductSchema,
            functions: { ...additionalFunctions, ...INPUT_OPERATORS },
            allowEmpty,
        };
    }, [state, productSchema, additionalFunctions, allowEmpty]);

    ruleBuilderRef.current = state;

    return (
        <ExpressionSimpleEditorContext.Provider value={providerValue}>
            {children}
        </ExpressionSimpleEditorContext.Provider>
    );
};

export const useExpressionSimpleEditorContext = (): {
    readonly [key in keyof ExpressionSimpleEditorContextType]: NonNullable<ExpressionSimpleEditorContextType[key]>;
} => {
    const context = useContext(ExpressionSimpleEditorContext);

    if (context.state == null || context.dispatch == null || context.productSchema == null) {
        throw new Error('RuleBuilderContext is not initialized');
    }

    return context as {
        [key in keyof ExpressionSimpleEditorContextType]: NonNullable<ExpressionSimpleEditorContextType[key]>;
    };
};
