import { Tag } from '@lemonade-hq/bluis';
import { Flex, font, LineClamp } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import type { TicketParam } from '../../../shared/types';

const Root = styled(Flex)`
    flex-wrap: wrap;
    gap: 12px;
    flex-direction: column;
`;

const StyledTag = styled(Tag)`
    text-transform: unset;
`;

const Title = styled.h3`
    ${font('main', { fontSize: '14px', fontWeight: 700 })}
    margin: 0;
`;

interface TicketParamsProps {
    readonly params: TicketParam[];
}

export const TicketParams: React.FC<React.PropsWithChildren<TicketParamsProps>> = ({ params }) => {
    return (
        <Root>
            <Title>Ticket Parameters</Title>
            <Flex alignItems="flex-start" flexDirection="column" gap="8px">
                {params.map(({ id, mode, text }) => (
                    <StyledTag key={id} light mode={mode} withStroke={false}>
                        <LineClamp lines={1}>{text}</LineClamp>
                    </StyledTag>
                ))}
            </Flex>
        </Root>
    );
};
