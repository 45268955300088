/* eslint-disable default-case */
import { Button, Flex, flexGrow, fuzzyMatch, Input, ListItemMenu, spacing } from '@lemonade-hq/blender-ui';
import type { SerializableToolsRevision as ToolsRevision } from '@lemonade-hq/persisted-tools';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { sortToolsBySections } from '../shared/tool.helpers';

interface PlaygroundToolEditorSidebarProps {
    readonly isReadonly?: boolean;
    readonly selectedToolName: string | undefined;
    readonly onChangeToolName?: (toolName: string) => void;
    readonly onCreateNewTool?: () => void;
    readonly toolsRevision?: ToolsRevision;
}

export const PlaygroundToolEditorSidebar: FC<PlaygroundToolEditorSidebarProps> = ({
    selectedToolName,
    isReadonly,
    onChangeToolName: handleChangeToolName,
    onCreateNewTool: handleCreateNewTool,
    toolsRevision,
}) => {
    const [toolNameSearchValue, setToolNameSearchValue] = useState('');

    const filteredSections = useMemo(() => {
        const sections = sortToolsBySections(toolsRevision);
        return sections.map(section => ({
            ...section,
            options: section.options.filter(
                option =>
                    isEmpty(toolNameSearchValue) ||
                    (typeof option.label === 'string' && fuzzyMatch(toolNameSearchValue, option.label))
            ),
        }));
    }, [toolNameSearchValue, toolsRevision]);

    return (
        <Flex flexDirection="column" gap={spacing.s20} height="100%">
            <Input
                icon="search-solid"
                maxLength={100}
                onChange={e => setToolNameSearchValue(e.target.value)}
                placeholder="Search"
                type="search"
                value={toolNameSearchValue}
            />
            <ListItemMenu
                className={flexGrow}
                onSelect={val => handleChangeToolName?.(val)}
                preventDeselection
                sections={filteredSections}
                selection={selectedToolName}
            />
            {!isReadonly && (
                <Button label="Add Tool" onClick={handleCreateNewTool} startIcon="plus-solid" variant="secondary" />
            )}
        </Flex>
    );
};
