import { Flex, themedColor } from '@lemonade-hq/cdk';
import styled, { css } from 'styled-components';

export interface HeaderSectionProps {
  readonly noBorder?: boolean;
}

export const HeaderSection = styled(Flex)<HeaderSectionProps>`
  height: 100%;

  ${({ noBorder = false }) =>
    !noBorder &&
    css`
      padding-left: 20px;
      border-left: 1px solid ${themedColor('separator')};
    `}
`;
