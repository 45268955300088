import { darkThemeClass, Flex, Spinner } from '@lemonade-hq/blender-ui';
import { RootErrorBoundary } from '@lemonade-hq/bluiza';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { clsx } from 'clsx';
import type { FC } from 'react';
import type { HubFilters } from '../AttachmentHub/Filters';
import { useGetAttachments } from '../AttachmentsQueries';
import { AttachmentsProvider, useAttachmentsData } from '../context';
import type { AttachmentDTO, AttachmentStats } from '../types';
import { AttachmentCarousel } from './Carousel/AttachmentCarousel';
import { CarouselProvider } from './Carousel/CarouselProvider';
import { GalleryDialog } from './Gallery.css';
import { InfoSidebar } from './InfoSidebar';

interface AttachmentGalleryProps {
    readonly onClose: () => void;
    readonly index?: number;
}

const AttachmentGalleryContent: FC<AttachmentGalleryProps> = ({ onClose, index }) => {
    const { attachments } = useAttachmentsData();

    return (
        <CarouselProvider index={index} onClose={onClose} totalSlides={attachments.length}>
            <div className={clsx(GalleryDialog, darkThemeClass)}>
                <InfoSidebar onClose={onClose} />
                <AttachmentCarousel />
            </div>
        </CarouselProvider>
    );
};

export const AttachmentGallery: FC<
    AttachmentGalleryProps & {
        readonly index?: number;
        readonly attachments?: AttachmentDTO[];
        readonly stats?: AttachmentStats;
        readonly entityType: EntityTypes;
        readonly entityPublicId: string;
        readonly filters?: HubFilters;
    }
> = ({ onClose, index = 0, attachments, stats, entityType, entityPublicId, filters }) => {
    // get all attachments if there are more than one pages
    const hasMorePages = stats != null && stats.totalPages > 1;
    const { data, isLoading } = useGetAttachments({
        entityType,
        entityPublicId,
        params: { ...filters, page: 1 },
        pageSize: stats?.totalRecords,
        enabled: hasMorePages,
    });
    // calc the correct index of a file in the list when getting all attachments
    const selectedIndex = hasMorePages ? stats.pageSize * (stats.currentPage - 1) + index : index;

    if (isLoading)
        return (
            <Flex alignItems={'center'} height={'100%'} justifyContent={'center'} width={'100%'}>
                <Spinner size={'lg'} />
            </Flex>
        );

    return (
        <RootErrorBoundary>
            <AttachmentsProvider
                attachments={data?.data ?? attachments}
                entityPublicId={entityPublicId}
                entityType={entityType}
            >
                <AttachmentGalleryContent index={selectedIndex} onClose={onClose} />
            </AttachmentsProvider>
        </RootErrorBoundary>
    );
};
