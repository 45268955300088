import type { ProductTypes } from '@lemonade-hq/bluiza';
import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';
import type { TaskIdsToReassign } from 'components/Bluis/Assign/AssignAdjusterToggleBox';
import type { Adjuster } from 'models/Adjuster';
import type { WitnessDetails } from 'models/HomeClaim';
import type { CommunicationMethods } from 'models/Interaction';
import type { TasksToReassignClaimData } from 'models/Workflows';
const homeBlenderUrlResolver = getUrlResolver('home-blender');
const blenderGeneralUrlResolver = getUrlResolver('blender-general');

export interface SquadListParams {
    readonly productType: ProductTypes;
    readonly claimId: string;
}
export interface SquadListItem {
    readonly title: string;
    readonly value: string;
}
export interface AssignSquadParams {
    readonly squad: string;
    readonly productType: ProductTypes;
    readonly claimId: string;
    readonly taskPublicIds?: string[];
}
export interface TasksToReassignClaimParams {
    readonly operatorPublicId: number;
    readonly squad: string | null;
    readonly role: string;
    readonly claimPublicId: string;
    readonly unAssignClaim: boolean;
}

export interface useTasksToReassignClaimParams {
    readonly operatorPublicId: number;
    readonly squad: string | null;
    readonly role: string;
    readonly claimPublicId: string;
    readonly unAssignClaim: boolean;
    readonly setTasksByGroupTitle: React.Dispatch<
        React.SetStateAction<{
            readonly [groupTitle: string]: TaskIdsToReassign;
        }>
    >;
}

export enum AssignmentEntityType {
    Claim = 'claim',
    Preapproval = 'preapproval',
}

export async function assignAdjuster({
    claimId,
    adjuster,
    productType,
    taskPublicIds,
}: {
    readonly claimId: string;
    readonly adjuster: Adjuster | null;
    readonly productType: ProductTypes;
    readonly taskPublicIds?: string[];
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/assignments`);

    const data = {
        claimPublicId: claimId,
        productType,
        adjusterPublicId: adjuster?.public_id ?? null,
        taskPublicIds,
    };

    return await axios.post(url, data).then(response => response.data.data);
}

export async function assignAdjusterV2({
    entityPublicId,
    adjusterPublicId,
    entityType,
    productType,

    taskPublicIds,
}: {
    readonly entityPublicId: string;
    readonly adjusterPublicId: string | null;
    readonly entityType: AssignmentEntityType;
    readonly productType: ProductTypes;
    readonly taskPublicIds?: string[];
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v2/assignments`);

    const data = {
        entityPublicId,
        entityType,
        productType,
        adjusterPublicId,
        taskPublicIds,
    };

    return await axios.post(url, data).then(response => response.data.data);
}

export async function uploadFile(file: File): Promise<{ readonly url: string }> {
    const url = '/backoffice/claims/upload';
    const body = new FormData();

    body.append('upload', file);

    return await axios.post(url, body).then(({ data }) => data);
}

export async function createRefund({
    claim_id,
    payee_id,
    feature_id,
    amount_in_cents,
    type,
    date,
    reference_photo_file_id,
}: {
    readonly claim_id: string;
    readonly payee_id: string;
    readonly feature_id: string;
    readonly amount_in_cents: number;
    readonly type: string;
    readonly date: string;
    readonly reference_photo_file_id: string;
}) {
    return await axios
        .post(`/backoffice/claim_items/manual_refund`, {
            claim_id,
            payee_id,
            feature_id,
            amount_in_cents,
            type,
            date,
            reference_photo_file_id,
        })
        .then(({ data }) => data);
}

export async function assignSquad({ squad, productType, claimId, taskPublicIds }: AssignSquadParams): Promise<void> {
    const url = blenderGeneralUrlResolver(`/api/v2/assignments`);

    const data = {
        forcedSquad: squad,
        productType,
        entityPublicId: claimId,
        entityType: AssignmentEntityType.Claim,
        autoAssign: true,
        forceAutoAssign: true,
        taskPublicIds,
    };

    return await axios.post(url, data).then(response => response.data);
}

export async function getSquadList({ productType, claimId }: SquadListParams): Promise<SquadListItem[]> {
    const url = '/backoffice/claim_assignments/list_squads';
    const params = {
        product_type: productType,
        claim_id: claimId,
    };

    return await axios.get(url, { params }).then(response => response.data.data);
}

export async function getTasksToReassignClaim({
    operatorPublicId,
    squad,
    role,
    claimPublicId,
    unAssignClaim,
}: TasksToReassignClaimParams): Promise<TasksToReassignClaimData> {
    const url = blenderGeneralUrlResolver(`/api/v1/tasks/tasks_to_reassign_claim`);

    const params = {
        operatorPublicId,
        squad,
        role,
        claimPublicId,
        unAssignClaim,
    };

    return await axios.get(url, { params }).then(response => response.data.data);
}

export async function sendUpdateLink({
    failedPayoutId,
    methods,
}: {
    readonly failedPayoutId: string;
    readonly methods: CommunicationMethods;
}) {
    const communication_channels = [];

    if (methods.email) communication_channels.push('email');
    if (methods.sms) communication_channels.push('sms');

    return await axios
        .post(`/backoffice/failed_payouts/${failedPayoutId}/send_update_link`, { communication_channels })
        .then(({ data }) => data);
}

export interface EditReviewSummaryPayload {
    readonly liabilityReferralRequired?: boolean | null;
    readonly liabilitySummary?: string | null;
    readonly summary: string;
}

export async function reviewSummary({
    payload,
    publicClaimId,
}: {
    readonly payload: EditReviewSummaryPayload;
    readonly publicClaimId: string;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${publicClaimId}/review_summary`);

    return await axios.put(url, payload).then(response => response.data);
}

export async function submitIncidentWitness({
    claimPublicId,
    details,
}: {
    readonly claimPublicId: string;
    readonly details: WitnessDetails;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/incident_witnesses`);

    return await axios.post(url, details).then(response => response.data);
}
